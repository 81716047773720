import FomTitle from '../components/helpers/FormTitle';
import Param from '../components/parametrages/Param';
import { TYPE_PARAM } from '../utils/constants';

export const ParametrageRegie = () => {
    return (
        <>
            <FomTitle title="Paramétrage régie" />
            <Param 
                route='contrat-type-regies'
                type={TYPE_PARAM.REGIE}
            />
        </>
    );
}

export default ParametrageRegie;