import { _ACTIONS, _HTTP } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import _ from "lodash";

const requestHelper = new RequestHelper();

/**
 * Fetches data for a contract.
 *
 * @param {object} params - The parameters for the request.
 * @param {function} actions - The function to set the fetched data.
 * @param {string} errroMessage - The error message to display if the request fails.
 * @param {string} route - The route for the request.
 */
export const getDataContrat = (params, actions, errroMessage, route) => {
    requestHelper
        .doRequest(_ACTIONS.GET, `${route}`, params)
        .then((response) => {
            if (_HTTP.ok !== response.status) {
                throw new Error(errroMessage);
            }
            if(actions){
            _.isUndefined(response.data["hydra:member"]) ? actions(response.data) : actions(response.data["hydra:member"]);
            }
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};

/**
 * Updates the contract data.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string} errroMessage - The error message to throw if the response status is not ok.
 * @param {string} route - The route for the request.
 * @param {Function} actions - Optional callback function to execute after successful update.
 * @returns {Promise} - A promise that resolves with the response data.
 */
export const putDataContrat = (params, errroMessage, route, actions) => {
    return requestHelper
            .doRequest(_ACTIONS.PUT, `${route}`, params)
            .then((response) => {
                if (_HTTP.ok !== response.status) {
                    throw new Error(errroMessage);
                }
                createToast("success", "Le contrat a été mis à jour avec succès");
                if(actions){
                    actions(response.data);
                }   
            })
            .catch((error) => {
                createToast("error", error.message);
            });
};

/**
 * Saves a contract.
 * 
 * @param {object} values - The values to be saved.
 * @param {string} errroMessage - The error message to be thrown if the request fails.
 * @param {string} route - The route to send the request to.
 * @param {function} actions - Optional callback function to be executed after successful request.
 */
export const saveDataContrat = (values, errorMessage, route, actions) => {
    requestHelper
        .doRequest(_ACTIONS.POST, `${route}`, values)
        .then((response) => {
            if (_HTTP.created !== response.status) {
                throw new Error(errorMessage);
            }
            createToast("success", "Le contrat a été créé avec succès");
            if(actions){
                actions(response.data);
            }
        })
        .catch((error) => {
            createToast("error", error.message);
        }); 
};

