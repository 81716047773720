import { DashboardGeneralComponent } from "../../components/tableau-general/DashboardGeneralComponent";
import DashboardGeneralContextProvider from "../../contexts/DashboardGeneralContext";

export function DashboardGeneral() {
  return (
    <DashboardGeneralContextProvider>
      <DashboardGeneralComponent />
    </DashboardGeneralContextProvider>
  );
}
