
/**
 * Renders a link component with optional icon and target attributes.
 *
 * @param {Object} props - The component props.
 * @param {string} props.link - The link URL.
 * @param {string} [props.target='_blank'] - The target attribute for the link.
 * @param {ReactNode} [props.icon=null] - The optional icon to display alongside the link.
 * @param {boolean} [props.http=false] - Whether the link is an HTTP link.
 * @returns {JSX.Element|null} The rendered link component.
 */
export const LinkTo = ({link, target = '_blank', icon = null, http = false}) => {
    if(link === null) return null;

    const href = http ? String(link).substring(0,4) === 'http' ? link : 'https://'+link 
                      : link;
    
    return <a href={href} target={target}>{icon === null ? link : icon} </a>;
}

export default LinkTo;