import React, { useEffect, useState } from "react";
import { _ACTIONS, _HTTP } from "../../utils/constants";
import { useUpdateParamContrat } from "../../hooks/useUpdateParamContrat";
import { createToast } from "../../utils/toast";
import { RequestHelper } from "../../utils/requestHelper";
import ParamTemplate from "./ParamTemplate";
import { ToggleOff, ToggleOnOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TYPE_PARAM } from "../../utils/constants";

export default function Param({route, type}) {
  
  const columnNom = {
    field: "nom",
    headerName: "Nom",
    width: 180,
    type: "string",
    editable: true,
  };

  const columnActif = {
    field: "actif",
    headerName: "Actif",
    type: "boolean",
    width: 250,
    editable: false,
    renderCell: (params) => {
      return (
          <IconButton  onClick={() => handleChangeActif(params.row.id, params.row.actif)}>
          { params.row.actif === true ? (
            <ToggleOnOutlined color="success" />
          ) : (
            <ToggleOff color="warning" />
          )}
        </IconButton>
      );
    }
  };

  const columnPrice = {
    field: "price",
    headerName: "Prix HT",
    width: 80,
    type: "number",
    editable: true,
    renderCell: (params) => {
      return (
        <div>
          {params.value} €
        </div>
      );
    }
  };

  const customColumns = type === TYPE_PARAM.REGIE ? [columnNom, columnPrice, columnActif] : [columnNom, columnActif];

 
  const requestHelper = new RequestHelper();
  const [data, setData] = useState([]);
  const [processRowUpdate, handleProcessRowUpdateError] = useUpdateParamContrat(route);
  const [change, setChange] = useState(false);
  
  /**
   * Handles the change of actif status for a given id.
   *
   * @param {number} id - The id of the item.
   * @param {boolean} actif - The current actif status.
   */
  const handleChangeActif = (id, actif) => {
    requestHelper.doRequest(_ACTIONS.PUT, `${route}/${id}`, {
        actif: !actif,
    })
    .then((response) => {
        if (_HTTP.ok !== response.status) {
            throw new Error(
                "Une erreur est survenue lors du changement de statut"
            );
        }
        setData((data) =>
            data.map((item) =>
              item.id === id ? { ...item, actif: false } : item
            )
          );
        setChange(!change);
    })
    .catch((error) => {
        createToast("error", error.message);
    });
  };
  
  useEffect(() => {
    getDatas();
  }, [change]);

 
  /**
   * Fetches data from the server and sets it in the component state.
   * @returns {void}
   */
  function getDatas() {
    try{
      requestHelper.doRequest(
        _ACTIONS.GET,
        `/${route}`, 
      ).then((response) => {
        if (200 !== response.status) {
            throw new Error(
                "Une erreur est survenue lors de la récupération des paramètres"
            );
        };
        setData(response.data["hydra:member"]);
      });
    } catch (error) {
        createToast("error", error.message);
    };
  };

  return (
    <>
      <ParamTemplate 
        columns={customColumns} 
        data={data}
        change={change}
        setChange={setChange}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        route={route}
      />
    </>
  );
};


