import { isNull } from "lodash";
import { Typography } from "@mui/material";

export const telephonieColumns = () => {
  return [
    {
      accessorKey: "societeProduitServiceAsterisk.user",
      header: "Identifiant",
      size: 50,
      grow: true,
      Cell: ({ row }) => {
        const user = row.original.societeProduitServiceAsterisk?.user;

        return <Typography>{isNull(user) ? "" : user}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceAsterisk.password",
      header: "Mot de passe",
      enableSorting: false,
      Cell: ({ row }) => {
        const password = row.original.societeProduitServiceAsterisk?.password;

        return <Typography>{isNull(password) ? "" : password}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceOvh.billingAccount",
      header: "Compte de facturation",
      Cell: ({ row }) => {
        const billingAccount =
          row.original.societeProduitServiceOvh?.billingAccount;

        return (
          <Typography>
            {isNull(billingAccount) ? "" : billingAccount}
          </Typography>
        );
      },
    },
  ];
};
