import { set } from "lodash";
import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const GlobalContext = createContext({});

/**
 * @param {*} param0
 * @returns <GlobalContext.Provider/>
 * @returns <GlobalContext.Consumer/>
 *
 */
function GlobalContextProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({});
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsNavOpen(true);
    }
  }, [open]);

  const value = {
    open,
    setOpen,
    openSubMenu,
    setOpenSubMenu,
    isNavOpen,
    setIsNavOpen,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
