
import { PatternFormat } from 'react-number-format'; 

/**
 * Renders a formatted phone number.
 *
 * @param {Object} props - The component props.
 * @param {string} props.phone - The phone number to be formatted.
 * @param {string} [props.format="## ## ## ##"] - The format pattern for the phone number.
 * @returns {JSX.Element} The formatted phone number component.
 */
function PhoneNumber({ phone, format="## ## ## ## ##"}) {

  return <div>
    <PatternFormat value={phone} format={format} displayType="text"/>
  </div>
};

export default PhoneNumber;
