import {
  TextFormatter,
  instantDelete,
  instantEdit,
} from "../../../../utils/muiTableHelper";
import { Box, IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const listContactsColumnBuilder = ({ list, setList }) => {
  return [
    {
      accessorKey: "firstName",
      header: "Nom et Prénom",
      enableEditing: false,
      Cell: ({ row }) => {
        return (
          <TextFormatter
            text={row.original.firstName + " " + row.original.lastName}
          />
        );
      },
    },
    {
      accessorKey: "email",
      header: "Adresse email",
      Cell: ({ row }) => {
        return <TextFormatter text={row.original.email} />;
      },
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        onBlur: (event) => {
          instantEdit(event, row, "email");
        },
      }),
    },
    {
      accessorKey: "phonePro",
      header: "Téléphone mobile",
      Cell: ({ row }) => {
        return <TextFormatter text={row.original.phonePro} />;
      },
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        onBlur: (event) => {
          instantEdit(event, row, "phonePro");
        },
      }),
    },
    {
      accessorKey: "phoneFix",
      header: "Téléphone fixe",
      Cell: ({ row }) => {
        return <TextFormatter text={row.original.phoneFix} />;
      },
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        onBlur: (event) => {
          instantEdit(event, row, "phoneFix");
        },
      }),
    },
    {
      accessorKey: "poste",
      header: "Poste/Fonction",
      Cell: ({ row }) => {
        return <TextFormatter text={row.original.poste} />;
      },
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        onBlur: (event) => {
          instantEdit(event, row, "poste");
        },
      }),
    },
    {
      accessorKey: "actions",
      id: "actions",
      header: "Actions",
      enableEditing: false,
      Cell: ({ row }) => {
        return (
          <Box>
            <IconButton
              color="error"
              onClick={() => instantDelete(row, list, setList)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
};
