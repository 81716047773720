import React, { useContext } from "react";
import { DECOMPTE_TEMPS, _ACTIONS, drawerWidth } from "../../utils/constants";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, useTheme } from "@mui/material";
import { UserContext } from "../../contexts/UserContext";

const TableRecapDT = ({ resultsFilter, isExterne }) => {
  const theme = useTheme();
  const { getUser } = useContext(UserContext);
  const user = getUser();
  const columns = [
    {
      field: "produitService.id",
      headerName: "Produit | Service",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <strong>
            <span>
              {params.row.produitService
                ? params.row.produitService.name
                : "N/A"}
            </span>
          </strong>
        );
      },
    },
    {
      field: "1",
      headerName: "Janvier",
      width: 90,
    },
    { field: "2", headerName: "Février", width: 90 },
    { field: "3", headerName: "Mars", width: 90 },
    { field: "4", headerName: "Avril", width: 90 },
    { field: "5", headerName: "Mai", width: 90 },
    { field: "6", headerName: "Juin", width: 90 },
    { field: "7", headerName: "Juillet", width: 90 },
    { field: "8", headerName: "Août", width: 90 },
    { field: "9", headerName: "Septembre", width: 90 },
    { field: "10", headerName: "Octobre", width: 90 },
    { field: "11", headerName: "Novembre", width: 90 },
    { field: "12", headerName: "Décembre", width: 90 },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      renderCell: (params) => {
        return (
          <strong>
            <span>{params.row.total ? params.row.total : "N/A"}</span>
          </strong>
        );
      },
    },
  ];

  function transformDataForGrid(data) {
    const groupedData = {};
    let totalByColumn = {};

    data.forEach((item) => {
      const id = item.produitService.id;
      if (!groupedData[id]) {
        groupedData[id] = { ...item, timesTotal: {}, total: 0 };
      }
      if (!groupedData[id].timesTotal[item.month]) {
        /*vérifie si timesTotal a déjà été initialisé pour ce mois 
      Sinon, cela signifie qu'aucune donnée n'a été encore comptabilisée pour ce mois et ce produit/service. */
        groupedData[id].timesTotal[item.month] = 0;
      }
      const total = item.timesTotal;
      groupedData[id].timesTotal[item.month] += total;
      groupedData[id].total += total;

      // Calcul du total par colonne
      totalByColumn[item.month] = (totalByColumn[item.month] || 0) + total;
    });

    const rows = Object.values(groupedData).map((item, index) => ({
      id: index,
      produitService: item.produitService,
      ...item.timesTotal,
      total: item.total,
    }));
    const totalRow = {
      id: "total",
      produitService: { name: "TOTAL" },
      total: 0,
    };
    for (let month = 1; month <= 12; month++) {
      totalRow[month] = totalByColumn[month] || 0;
      totalRow.total += totalRow[month];
    }
    rows.push(totalRow);

    return rows;
  }

  const row = transformDataForGrid(resultsFilter);

  return (
    <>
      <Box sx={{ border: `1px solid ${theme.palette.color.orangeBox}` }}>
        <Box bgcolor={theme.palette.color.orangeBox}>
          {isExterne && user ? (
            <Typography variant="h6" color={"white"} padding={1}>
              Décompte en temps pour la société :{" "}
              <span style={{ textDecoration: "underline" }}>
                {user.societeName}
              </span>
            </Typography>
          ) : (
            <Typography variant="h6" color={"white"} padding={1}>
              {DECOMPTE_TEMPS.titleDataGrid}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            "& .totalRow": {
              backgroundColor: "#fff9cd",
              color: "#1a3e72",
            },
          }}
        >
          <DataGrid
            rows={row}
            sx={{ backgroundColor: "white" }}
            columns={columns}
            getCellClassName={(params) => {
              if (params.field === "total") {
                return "totalRow";
              }
              if (params.id === "total") {
                return "totalRow";
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default TableRecapDT;
