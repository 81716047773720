import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { _ACTIONS } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import _ from "lodash";

const TableAlso = ({ setCatalogueAlsoSelected, refreshCounter }) => {
  const columnsAlso = [
    {
      field: "DisplayName",
      headerName: "Nom",
      width: 300,
      renderCell: (params) => <strong>{params.row?.DisplayName}</strong>,
    },
    {
      field: "ProductName",
      headerName: "Identifiant Also",
      width: 300,
      renderCell: (params) => <span>{params.row?.ProductName}</span>,
    },
  ];
  const rq = new RequestHelper();
  const [catalogueAlso, setCatalogueAlso] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading || refreshCounter > 0) {
      rq.doRequest(_ACTIONS.POST, "get-market-places").then((res) => {
        const tmp = Object.values(res.data);
        const newData = tmp.map((item) => ({
          ...item,
          id: item.ProductName,
        }));
        setOriginalData(newData);
        setCatalogueAlso(newData);
        setLoading(false);
      });
    }
  }, [refreshCounter]);

  useEffect(() => {
    // Reset Original Data from API
    if (_.isEmpty(valueSearch)) {
      setCatalogueAlso(originalData);
    } else {
      // Set filtered Data from search
      let data = catalogueAlso;
      const filteredData = data.filter((item) =>
        item.ProductName.toLowerCase().includes(valueSearch.toLowerCase())
      );
      setCatalogueAlso(filteredData);
    }
  }, [valueSearch]);

  const handleSearchChange = (event) => {
    setValueSearch(event.target.value);
  };

  return (
    <>
      <Box sx={{ height: 800, width: "60%" }}>
        <DataGrid
          rows={catalogueAlso}
          columns={columnsAlso}
          loading={loading}
          slotProps={{
            toolbar: {
              value: valueSearch,
              onChange: handleSearchChange,

              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          getRowId={(row) => row.ProductName}
          slots={{ toolbar: GridToolbar }}
          pageSizeOptions={[5, 10, 20]}
          onRowClick={(params) => setCatalogueAlsoSelected(params.row)}
        />
      </Box>
    </>
  );
};

export default TableAlso;
