import { Box } from "@mui/material";
import React from "react";

function NoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <span>Aucune réservation n'est actuellement disponible.</span>
    </Box>
  );
}

export default NoRowsOverlay;
