import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { CONTACTS, _ACTIONS, _COLORS, _DOLIBARR } from "../utils/constants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormTitle from "../components/helpers/FormTitle";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RequestHelper } from "../utils/requestHelper";
import { _ROUTER_DOM_URL, _ROUTES } from "../utils/_routes_constants";
import ColonnesContact from "../components/admin-contact/ColonnesContact";
import { createToast } from "../utils/toast";
import DataGridBuilder from "../utils/DataGridBuilder";
import { sortingParams } from "../utils/function";

function TabsDataGrid(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabsDataGrid.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Contact = () => {
  const [value, setValue] = useState(0);
  let Navigate = useNavigate();
  const [rows, setRows] = useState({ contacts: [], fournisseurs: [] });
  let rq = new RequestHelper();
  const [valueSearch, setValueSearch] = useState("");
  const [pageState, setPageState] = useState({
    totalRows: 0,
    page: 1,
    pageSize: 20,
  });
  const [sortParams, setSortParams] = useState({});

  /**
   * Fetches data from the specified route with optional parameters.
   *
   * @param {string} route - The route to fetch data from.
   * @param {string} [params=""] - Optional parameters to include in the request.
   * @returns {Promise<Array>} - A promise that resolves to an array of fetched data.
   * @throws {Error} - If the response status is not 200.
   */
  const fetchData = async (route) => {
    return rq
      .doRequest(
        _ACTIONS.GET,
        `${route}?page=${pageState.page}&itemsPerPage=${pageState.pageSize}`,
        {
          commonSearchFilter: valueSearch,
          order: { [sortParams.field]: sortParams.sort },
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Erreur lors de la récupération des données");
        }
        const data = response.data["hydra:member"];
        const length = response.data["hydra:totalItems"];
        setPageState((old) => ({ ...old, totalRows: length }));
        return data;
      });
  };

  useEffect(() => {
    const route = value === 0 ? _ROUTES.CONTACT : _ROUTES.CONTACT_FOURNISSEUR;
    fetchData(route).then((data) =>
      setRows(
        value === 0
          ? { ...rows, contacts: data }
          : { ...rows, fournisseurs: data }
      )
    );
  }, [value, pageState.page, pageState.pageSize, sortParams]);

  /**
   * Handles the change event of the search input in slotbar DataGrid.
   * @param {Event} event - The change event object.
   */
  function handleSearchChange(event) {
    const searchValue = event.target.value;
    setValueSearch(searchValue);
    const route =
      value === 0 ? `${_ROUTES.CONTACT}` : `${_ROUTES.CONTACT_FOURNISSEUR}`;
    fetchData(route)
      .then((data) => {
        setRows(
          value === 0
            ? { ...rows, contacts: data }
            : { ...rows, fournisseurs: data }
        );
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  }

  /**
   * Handles the change event for the value.
   *
   * @param {any} newValue - The new value.
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Sorts the column based on the provided parameters.
   *
   * @param {any} params - The parameters used for sorting.
   */
  const sortColumn = (params) => {
    const sortParams = sortingParams(params);
    setSortParams(sortParams);
  };

  const contactColumns = ColonnesContact(rows, setRows, value);

  return (
    <>
      <FormTitle title={CONTACTS.h1} />
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#4fc1ef",
          borderBlockStartColor: "2px solid #3c8dbc",
          color: "white",
          width: "100%",
          alignItems: "center",
          height: "2rem",
          borderRadius: 1,
          marginTop: 1,
          paddingLeft: 2,
        }}
      >
        <InfoOutlinedIcon sx={{ marginRight: 1, color: "white" }} />
        <strong>{_DOLIBARR.m1}</strong>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          size="small"
          title={value === 0 ? "Ajouter un client" : "Ajouter un fournisseur"}
          startIcon={<MdOutlineAddToPhotos />}
          color="success"
          onClick={() => {
            if (value === 0) {
              Navigate(_ROUTER_DOM_URL.CONTACT_CREATE);
            } else {
              Navigate(_ROUTER_DOM_URL.CONTACT_FOURNISSEUR_CREATE);
            }
          }}
        >
          {value === 0 ? "Nouveau client" : "Nouveau fournisseur"}
        </Button>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="tabs">
            <Tab label="Client" {...a11yProps(0)} />
            <Tab label="Fournisseur" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGridBuilder
            rows={value === 0 ? rows.contacts : rows.fournisseurs}
            columns={contactColumns}
            pageInfo={pageState}
            sortColumn={sortColumn}
            onSearchChange={{
              value: valueSearch,
              onChange: handleSearchChange,
            }}
            onPaginationChange={({ page, pageSize }) => {
              setPageState((oldState) => ({
                ...oldState,
                page,
                pageSize,
              }));
            }}
          />
        </Box>
      </Box>
    </>
  );
};
