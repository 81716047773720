import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { FiEdit } from "react-icons/fi";
import { _COLORS } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { _ACTIONS } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { ColonnesTiers } from "./ColonnesTiers";
import BasicModal from "../helpers/BasicModal";
import { IconButton } from "@mui/material";
import InfoTableClient from "./InfoTableClient";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";
import { getMuiTableConfig } from "../../utils/muiTableBuilder";
import _ from "lodash";
import { tiers_columnsVisibilities } from "../../utils/tiers_constants";
import MuiTableContainer from "../utils/MuiTableContainer";
import RefreshIcon from "@mui/icons-material/Refresh";

/**
 * Renders the Tiers Table (include societe & fournisseur) component.
 *
 * @param {string} props.typeOfTiers - true = societe, false = fournisseur
 */
const TiersClient = ({ typeOfTiers = true }) => {
  /* States */
  const [societes, setSocietes] = useState([]); // societes | fournisseurs data
  const [rowCount, setRowCount] = useState(0); // total items
  const [showModal, setShowModal] = useState(false); // modal
  const [refreshData, setRefreshData] = useState(0); // force refresh data
  const [isLoading, setIsLoading] = useState(true); // loading
  const [isRefetching, setIsRefetching] = useState(false); // refetching when action change
  const [sorting, setSorting] = useState([]); // sorting
  const [columnFilters, setColumnFilters] = useState([]);
  const [showModalContent, setShowModalContent] = useState({
    title: "", // modal title
    content: "", // modal content
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [globalFilter, setGlobalFilter] = useState(""); // global filterin muiTable
  /* Context */
  /* Variables */
  const navigate = useNavigate();
  const params = useParams().societe;
  const requestHelper = new RequestHelper();
  /* determine which entity class to use between Fournisseur | Societe */
  const entityClass = typeOfTiers ? _ROUTES.SOCIETE : _ROUTES.FOURNISSEUR;

  // Initialize the global filter with params if available
  useEffect(() => {
    if (params) {
      setGlobalFilter(params);
    }
  }, [params]);
  const queryParams = (pagination, sorting, globalFilter) => {
    let queryData = {};
    /* Pagination */
    queryData = {
      ...queryData,
      page: pagination.pageIndex + 1,
      itemsPerPage: pagination.pageSize,
    };

    /* Sorting */
    if (sorting) {
      sorting.forEach((sort) => {
        const key = "order[" + sort.id + "]";
        queryData[key] = sort.desc ? "desc" : "asc";
      });
    }

    /* Global filter */
    if (globalFilter || params) {
      queryData = {
        ...queryData,
        tiersSearchFilter: globalFilter || params,
      };
    }

    return queryData;
  };

  const societesList = (isHardRefresh = false) => {
    // start the loader table
    setIsLoading(true);
    const params = isHardRefresh
      ? {}
      : queryParams(pagination, sorting, globalFilter);
    requestHelper
      .doRequest(_ACTIONS.GET, entityClass, params)
      .then((res) => {
        setSocietes(res.data["hydra:member"]);
        setRowCount(res.data["hydra:totalItems"]);
        // stop the loader table
        setIsLoading(false);
      })
      .catch((err) => {
        createToast(
          "error",
          "Une erreur est survenue lors de la récupération des données"
        );
      });
  };
  useEffect(() => {
    societesList();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    refreshData,
    globalFilter,
    sorting,
  ]);

  const handleChangeStatus = (id, isEnabled) => {
    // start the progress bar
    setIsRefetching(true);
    requestHelper
      .doRequest(_ACTIONS.PUT, `${entityClass}/${id}`, {
        isEnabled: !isEnabled,
      })
      .then(() => {
        setSocietes((prevData) =>
          prevData.map((item) =>
            item.id === id ? { ...item, isEnabled: !isEnabled } : item
          )
        );
        // stop it
        setIsRefetching(false);
      })
      .catch(() => {
        createToast(
          "error",
          "Une erreur est survenue lors de la mise à jour du statut"
        );
      });
  };

  const columns = ColonnesTiers({
    typeOfTiers,
    handleChangeStatus,
    setShowModal,
    setShowModalContent,
    setRefreshData,
  });

  let d = getMuiTableConfig(
    {
      enableColumnOrdering: false,
      enableColumnFilters: false,
    },
    {
      rowCount,
      isLoaded: null,
      isError: null,
      pagination,
      setPagination,
      columnFilters,
      setColumnFilters,
      globalFilter,
      setGlobalFilter,
      sorting,
      setSorting,
      isLoading,
    }
  );

  const table = useMaterialReactTable({
    ...d,
    data: societes,
    columns,
    state: {
      isLoading,
      showProgressBars: isRefetching,
      pagination: pagination,
      sorting: sorting,
    },
    initialState: {
      columnVisibility: tiers_columnsVisibilities,
      columnOrder: Object.keys(tiers_columnsVisibilities),
      globalFilter: globalFilter,
    },
    // adapt the table to the screen size
    layoutMode: "grow",
    enableColumnResizing: true,
    muiPaginationProps: {
      rowsPerPageOptions: [15, 20, 50],
    },
    renderTopToolbarCustomActions: () => {
      return (
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display="flex" gap={1}>
            <InfoTableClient
              bgColor={_COLORS.defaultBlue}
              title={"Nombre de contact(s)"}
            />
            {typeOfTiers && (
              <>
                <InfoTableClient
                  bgColor={_COLORS.defaultGreen}
                  title={"Nombre d'utilisateur(s)"}
                />
              </>
            )}
          </Box>
          <IconButton title="Rafraichir la liste">
            <RefreshIcon
              onClick={() => {
                societesList(true);
              }}
            />
          </IconButton>
        </Box>
      );
    },
    renderRowActions: ({ row }) => {
      const id = row.original.id;

      return (
        <IconButton
          onClick={() => navigate(`${_ROUTER_DOM_URL.TIERS_EDIT}/${id}/client`)}
        >
          <FiEdit size={20} />
        </IconButton>
      );
    },
  });
  return (
    <>
      <BasicModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={showModalContent.title}
        content={showModalContent.content}
      />
      <MuiTableContainer
        marginTop={2}
        sx={{ boxShadow: `0px 0px 2px ${_COLORS.grey} ` }}
      >
        {!_.isEmpty(table) && (
          <>
            <Box>
              <MaterialReactTable table={table} />
            </Box>
          </>
        )}
      </MuiTableContainer>
    </>
  );
};

export default TiersClient;
