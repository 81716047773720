import { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem'; 
import Alert from '@mui/material/Alert';
import InfoAntivirus from '../../components/antivirus/InfoAntivirus';
import ClientName from './ClientName';
import { getDataBitdefender } from '../../repository/antivirus/Bitdefender';
import { _ROUTES } from '../../utils/_routes_constants';
import _ from 'lodash';

export const Antivirus = () => {
    const textDebut = "Pour commencer, merci de bien vouloir choisir un client...";
    const [clientAntivirus, setClientAntivirus] = useState([]);
    const [client, setClient] = useState(0);
    const [isAlert, setIsAlert] = useState(true);
    const [isLoaded, setIsLoaded] = useState(true);
    
    const handleChange = (event) => {
        if(event.target.value !== 0) {
            setIsLoaded(false);
            setIsAlert(false)
        } else {
            setIsLoaded(true);
            setIsAlert(true);
        } 
        setClient(event.target.value);
    };

    useEffect(() => {
        if(clientAntivirus?.length !== 0) return;
        getDataBitdefender(
            {}, 
            setClientAntivirus, 
            "Une erreur est survenue lors de la récupération des clients",
            _ROUTES.SOCIETE_BITDEFENDER
        );
    }, []);

    return (
        <>
            <Typography variant="h4">Récapitulatif bitdefender</Typography>
            <Box sx={{marginTop:"20px"}}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="client-antivirus-label">Client</InputLabel>
                    <Select
                        labelId="client-antivirus-label"
                        id="client-antivirus"
                        value={client}
                        onChange={handleChange}
                    >
                        <MenuItem value={0}>Veuillez choisir un client </MenuItem>
                        {clientAntivirus?.map((client) => (
                                <MenuItem key={client.compagnies} value={client}><ClientName client={client} /></MenuItem>
                            )   
                        )}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{marginTop:"20px"}}>
                <>
                    {isAlert 
                        ? <Alert severity="info">{textDebut}</Alert>
                        : <InfoAntivirus client={client} setIsLoaded={setIsLoaded} isLoaded={isLoaded} />
                    }
                </>
            </Box>
        </>
    );
}

export default Antivirus;