import React from "react";
import { Typography } from "@mui/material";
import { isNull } from "lodash";
import { sizeColumnMuiTable } from "../../../../utils/actif-config/actif_configuration_constants";

const { tinyText } = sizeColumnMuiTable;

export const hebergementColumns = () => {
  return [
    {
      accessorKey: "societeProduitServiceHebergement.domaine",
      header: "Domaine",
      Cell: ({ row }) => {
        const domaine = row.original.societeProduitServiceHebergement?.domaine;

        return <Typography>{isNull(domaine) ? "" : domaine}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.dbName",
      header: "Database",
      Cell: ({ row }) => {
        const dbName = row.original.societeProduitServiceHebergement?.dbName;

        return <Typography>{isNull(dbName) ? "" : dbName}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.dbUser",
      header: "User dataBase",
      Cell: ({ row }) => {
        const dbUser = row.original.societeProduitServiceHebergement?.dbUser;

        return <Typography>{isNull(dbUser) ? "" : dbUser}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.dbPassword",
      header: "Pwd dataBase",
      enableSorting: false,
      Cell: ({ row }) => {
        const dbPassword =
          row.original.societeProduitServiceHebergement?.dbPassword;

        return <Typography>{isNull(dbPassword) ? "" : dbPassword}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.ftpUser",
      header: "User FTP",
      Cell: ({ row }) => {
        const ftpUser = row.original.societeProduitServiceHebergement?.ftpUser;

        return <Typography>{isNull(ftpUser) ? "" : ftpUser}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.ftpPassword",
      header: "Pwd FTP",
      enableSorting: false,
      Cell: ({ row }) => {
        const ftpPassword =
          row.original.societeProduitServiceHebergement?.ftpPassword;

        return (
          <Typography>{isNull(ftpPassword) ? "" : ftpPassword}</Typography>
        );
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.cms",
      header: "CMS",
      size: tinyText,
      enableSorting: false,
      Cell: ({ row }) => {
        const cms = row.original.societeProduitServiceHebergement?.cms;

        return <Typography>{isNull(cms) ? "" : cms}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.version",
      header: "Version",
      size: tinyText,
      enableSorting: false,
      Cell: ({ row }) => {
        const version = row.original.societeProduitServiceHebergement?.version;

        return <Typography>{isNull(version) ? "" : version}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.serveurWeb",
      header: "Serveur Web",
      size: tinyText,
      enableSorting: false,
      Cell: ({ row }) => {
        const serveurWeb =
          row.original.societeProduitServiceHebergement?.serveurWeb;

        return <Typography>{isNull(serveurWeb) ? "" : serveurWeb}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.webmaster",
      header: "Webmaster",
      Cell: ({ row }) => {
        const webmaster =
          row.original.societeProduitServiceHebergement?.webmaster;

        return <Typography>{isNull(webmaster) ? "" : webmaster}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceHebergement.docGen",
      header: "Doc General",
      size: tinyText,
      enableSorting: false,
      Cell: ({ row }) => {
        const docGeneral =
          row.original.societeProduitServiceHebergement?.docGen;

        return <Typography>{isNull(docGeneral) ? "" : docGeneral}</Typography>;
      },
    },
  ];
};
