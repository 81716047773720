import { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import _ from "lodash";
import { getDataSocieteProduitService } from "../../../repository/SocieteProduiService";
import { getDataProduitService } from "../../../repository/ProduitService";
import { getClientById } from "./telePhonieOvh";
import { _ROUTES } from "../../../utils/_routes_constants";

const TelephonieOvhClients = ({client, setClient, setNbGroup, setSelectsGroup, setIsAlert, getNbGroupOvh, setGroup, setLine}) => {
    const [produitServiceOvh, setProduitServiceOvh] = useState(null);
    const [clientOvh, setClientOvh] = useState([]);

    /**
    * Retrieves the OVH product/service from the given results array.
    * @param {Array} results - The array of results to search through.
    */
    function getProduitServiceOvh(results) {
        results.map((result) => {
        if(result.name === "OVH")
            setProduitServiceOvh(result);
        });
    }

    useEffect(() => {
        getDataProduitService(
            {name: "OVH", pagination: false}, 
            getProduitServiceOvh, 
            "Une erreur est survenue lors de la récupération des clients",
            _ROUTES.PRODUIT_SERVICE
        );
      }, []);

    useEffect(() => {
        if(produitServiceOvh?.id){
            getDataSocieteProduitService(
                {produitServiceCategorie: produitServiceOvh?.id}, 
                setClientOvh, 
                "Une erreur est survenue lors de la récupération des clients",
                _ROUTES.SOCIETE_PRODUIT_SERVICE
            );
    }
    }, [produitServiceOvh]);

    /**
     * Handles the change event of the input field.
     *
     * @param {Event} event - The change event object.
     */
    const handleChange = (event) => {
        setIsAlert(true);
        const id = event.target.value;
        if(id !== 0){
            const c = getClientById(id, clientOvh);
            setClient(c);
            setSelectsGroup( c.societeProduitServiceOvh?.billingAccount);
            if(!_.isUndefined(setNbGroup)) setNbGroup(c.societeProduitServiceOvh?.billingAccount?.length);
        } else {
             setClient(0);
             if(!_.isUndefined(getNbGroupOvh)) getNbGroupOvh();
        } 
        if(!_.isUndefined(setLine)) setLine(0);
        if(!_.isUndefined(setGroup)) setGroup(0);
      };

    return (
        <>  {clientOvh?.length > 0 && (
            <FormControl key="fc-1" variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="client-ovh-label">Client</InputLabel>
              <Select
                  labelId="client-ovh-label"
                  id="client-ovh"
                  value={client?.id ?? 0}
                  onChange={handleChange}
                >
                <MenuItem key="a" value={0}>Veuillez choisir un client </MenuItem>
                  {clientOvh?.map((client) => (
                    <MenuItem key={client?.id} value={client?.id}>{client?.societe.name}</MenuItem>
                    )   
                )}
              </Select>
            </FormControl>)}
        </>
    );
};

export default TelephonieOvhClients;