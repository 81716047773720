import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Button } from "@mui/material";
import { useMemo, useEffect, useState, useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import useHasRoles from "../../../hooks/useHasRole";
import { _MOIS, _ROLES } from "../../../utils/constants";
import _ from "lodash";
import { getDataStat } from "../../../repository/Stats";   
import { _ROUTES } from "../../../utils/_routes_constants";
import Chart from "./Chart";
import { _STAT_EQUIPE_NB_HEURES } from "../../../utils/constants";
import { sumColumn } from "./function";
import { minutesInHours } from "../../../utils/function";
import { mkConfig, generateCsv, download } from 'export-to-csv'; 
import { exportDataYearEquipe } from './makeData';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const TableEquipeYear = ({annee}) => {
    const {getUser} = useContext(UserContext);
    const user = getUser();
    const canAccess = useHasRoles(user, [_ROLES.ADMIN]);
    const userId = canAccess ? null : user.id;
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        setDatas([]);
        if(!annee) return;
        getDataStat(
            {dateYear: annee, userId:userId},
            setDatas,
            "Une erreur est survenue lors de la récupération des données",
            _ROUTES.STAT_EQUIPE_YEAR
        );

    }, [annee]);

    const columns = useMemo(
        () => {
            const columns = [];
            _MOIS.map((element) => {
                columns.push(
                    {
                        accessorKey: element.key,
                        header: element.header,
                        Cell: ({row}) => (
                            (!_.isUndefined(row.original[element.id])) &&
                            <>
                                <Chart type={'DF'} data={row.original[element.id].facturable} max={_STAT_EQUIPE_NB_HEURES.month*60} />
                                <Chart type={'DR'} data={row.original[element.id].reel} max={_STAT_EQUIPE_NB_HEURES.month*60} />
                            </>
                        ),
                        Footer: () => (
                            <>
                                <Box>Total DF: {datas?.length > 0 ? minutesInHours(sumColumn(datas, "facturable", element.id), true) : ''}</Box>
                                <Box>Total DR: {datas?.length > 0 ? minutesInHours(sumColumn(datas, "reel", element.id), true) : ''}</Box>
                            </>
                        ),
                    }
                );
            },
            columns.push(
                {
                    accessorKey: 'total',
                    header: 'Total',
                    Cell: ({row}) => (
                        (!_.isUndefined(row.original.totalAnneeFacturable)) &&
                        <>
                            <Chart type={'TF'} data={row.original.totalAnneeFacturable} max={_STAT_EQUIPE_NB_HEURES.year*60} />
                            <Chart type={'TR'} data={row.original.totalAnneeReel} max={_STAT_EQUIPE_NB_HEURES.year*60} />
                        </>
                    ),
                    Footer: () => (
                        <>
                            <Box>Total DF: {datas?.length > 0 ? minutesInHours(sumColumn(datas, "totalAnneeFacturable", null), true) : ''}</Box>
                            <Box>Total DR: {datas?.length > 0  ? minutesInHours(sumColumn(datas, "totalAnneeReel", null), true) : ''}</Box>
                        </>
                      ),
                }
            )
        );
        return columns;
    }, [datas]);

    const columnsOrder = useMemo(
        () => {
            const columnsOrder = [];
            _MOIS.map((element) => {
                columnsOrder.push(element.key);
            });
            columnsOrder.push("total");
            return columnsOrder;
        },
    );

    const options = {
        columns: columns,
        data: datas ?? [],
        initialState: {
            columnOrder: columnsOrder,
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
              >
                Export All Data
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Page Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Selected Rows
              </Button>
            </Box>
          ),
    };

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
      }
    );

    const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(exportDataYearEquipe(rowData));
        download(csvConfig)(csv);
    };

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(exportDataYearEquipe(datas));
        download(csvConfig)(csv);
    };
    
    const table = useMaterialReactTable(options);

    return (
        <Box sx={{mt: 2}}>
            <MaterialReactTable table={table}  />
        </Box>
    );
};

export default TableEquipeYear;