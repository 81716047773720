import _ from "lodash";
import { createToast } from "./toast";
import moment from "moment";
import { RequestHelper } from "./requestHelper";
import { _ACTIONS, _ROLES, _SWAL_ICONS } from "./constants";
import { _TICKET_STATUS_ID } from "./_tickets_constants";

/**
 *
 * @param {any} value
 * @returns
 */
export function is(value) {
  return !_.isNull(value) && !_.isUndefined(value);
}

/**
 * Check if the value is not empty and return reservation
 * @param {any} value
 * @returns
 */
export function setReservation(item) {
  if (!item) {
    createToast(
      "error",
      "Erreur lors de la récupération des données de l'entité."
    );

    return null;
  }

  let user = !_.isUndefined(item.user)
    ? item.user["firstName"] + " " + item.user["lastName"]
    : null;
  let ticket = !_.isUndefined(item.ticket) ? item.ticket["subject"] : null;

  return {
    id: item.id,
    description: item.description.replace(/(<([^>]+)>)/gi, ""),
    resource: item.resource["brand"],
    startDate: moment(item.startDate).format("DD/MM/YYYY"),
    ticket: ticket,
    endDate: moment(item.endDate).format("DD/MM/YYYY"),
    user: user,
    quantity: item.quantity,
    status: item.status,
  };
}

export function getQueryParamsFromFormData(formData, mode) {
  const params = {};

  //Boucler sur l'objet formData
  //Si la valeur est vide ou null, on ne l'ajoute pas
  //Sinon on l'ajoute dans params
  for (let key in formData) {
    let value = formData[key];
    if (mode === "edit" && key.includes(".")) {
      continue;
    }
    if (key.includes("type") && value !== "" && value !== null) {
      params[key] = value;
      continue;
    }
    if (value !== "" && value !== null) {
      params[key] = value;
    }
  }

  return params;
}

export function transformDataForGrid(data) {
  const groupedData = {};

  data.forEach((item) => {
    const id = item.produitService.id;
    if (!groupedData[id]) {
      groupedData[id] = { ...item, timesTotal: {} };
    }
    if (!groupedData[id].timesTotal[item.month]) {
      groupedData[id].timesTotal[item.month] = 0;
    }
    groupedData[id].timesTotal[item.month] += item.timesTotal;
  });

  // Convertir l'objet en tableau et ajouter l'ID de la ligne pour DataGrid.
  return Object.values(groupedData).map((item, index) => ({
    id: index,
    produitService: item.produitService,
    ...item.timesTotal,
  }));
}

export function getDecompteBySociete(societeId, params) {
  const requestHelper = new RequestHelper();

  return requestHelper.doRequest(
    _ACTIONS.GET,
    `/decompte_temps_societes?societe=${societeId}`,
    params
  );
}

export function getDecompteTemps(params) {
  const requestHelper = new RequestHelper();

  return requestHelper.doRequest(
    _ACTIONS.GET,
    "/decompte_temps_societes",
    params
  );
}

// Fonction pour extraire l'ID à partir de l'IRI
export const getIdFromIRI = (iri) => {
  const match = iri.match(/\/(\d+)$/);

  return match ? parseInt(match[1], 10) : null;
};

/**
 * Returns a JSON object with roles as both keys and values.
 * @param {Array} roles - An array of roles.
 * @returns {Object} - A JSON object with roles as keys and values.
 */
export const getJsonObjectRole = (roles) => {
  let jsonObject = {};
  roles.forEach((role) => {
    jsonObject[role] = role;
  });

  return jsonObject;
};

/**
 * Determines the HTTP method based on the given entity.
 * If the entity is null or undefined, it returns the POST method.
 * Otherwise, it returns the PUT method.
 *
 * @param {any} entity - The entity to determine the HTTP method for.
 * @returns {Object} - The updated entity object with the determined method.
 */
export const determineMethodHttp = (entity) => {
  return {
    ...entity,
    method: entity == null || undefined ? _ACTIONS.POST : _ACTIONS.PUT,
  };
};

export const saveBoolAtrribute = (value) => {
  return value == "" ? false : value;
};

/**
 * Formats a phone number by adding a space after every two digits.
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number.
 */
export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";

  return phoneNumber.replace(/(\d{2})(?=\d)/g, "$1 ");
};

/**
 * Strips HTML tags from the given HTML string.
 *
 * @param {string} html - The HTML string to strip tags from.
 * @returns {string} The resulting string without HTML tags.
 */
export const stripHtml = (html) => {
  var doc = new DOMParser().parseFromString(html, "text/html");

  return doc.body.textContent || "";
};

/**
 * Checks if a string value is equal to "NULL".
 * @param {string} value - The string value to check.
 * @returns {boolean} - Returns true if the value is "NULL", otherwise returns false.
 */
export const checkNullInString = (value) => {
  return value === "NULL" ? true : false;
};

/**
 * Processes sort parameters and returns new sort parameters.
 *
 * @param {Array} params - The sort parameters to process.
 * @return {Object|null} - The new sort parameters.
 */
export const sortingParams = (params) => {
  if (params.length === 0) {
    return "";
  }
  const newSortParams = params[0];
  return { field: newSortParams.field, sort: newSortParams.sort };
};

/**
 * Replaces URLs and email addresses in a given text with clickable links.
 * @param {string} inputText - The text to be linkified.
 * @returns {string} - The linkified text.
 */
export function linkify(inputText) {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
}

/**
 * Envoie un requete directement au onChange d'un select pour modifier une valeur en BDD
 *
 * @param {*} value
 * @param {*} field
 * @param {*} url
 */
export async function editInstantRequest(value, url, field) {
  const requestHelper = new RequestHelper();
  if (!url) return;

  url = url.replace("/api", "", 1);

  return await requestHelper
    .doRequest(_ACTIONS.PUT, url, { [field]: value })
    .then((response) => {
      if (response.status == 200) {
        // @TODO Garder le toast pour plus tard peut etre
        // createToast(
        //   _SWAL_ICONS.success,
        //   "La modification du champ a été effectuée avec succès"
        // );

        return response.data;
      } else {
        createToast(
          _SWAL_ICONS.error,
          "Une erreur est survenue lors de la modification"
        );

        return response.data;
      }
    });
}

/**
 * Truncates a given text if it exceeds the specified limit.
 *
 * @param {string} text - The text to truncate.
 * @param {number} limit - The maximum length of the truncated text.
 * @returns {string} - The truncated text.
 */
export function ellipsis(text, limit) {
  return text.length > limit ? text.substring(0, limit) + "..." : text;
}

/**
 * Checks if a ticket is disabled.
 * @param {Object} ticket - The ticket object to check.
 * @returns {boolean} - Returns true if the ticket is disabled, false otherwise.
 */
export function isDisabledTicket(ticket) {
  return ticket ||
    (!_.isUndefined(ticket.isFactured) && ticket.isFactured === true) ||
    ticket.status.id === _TICKET_STATUS_ID.CLOSED_STATUS ||
    ticket.status.id === _TICKET_STATUS_ID.ARCHIVED_STATUS ||
    ticket.societe === null
    ? true
    : true;
}

/**
 * Checks if a string contains a number.
 *
 * @param {string} myString - The string to check.
 * @returns {boolean} - True if the string contains a number, false otherwise.
 */
export function hasNumber(myString) {
  return /\d/.test(myString);
}

/**
 * Returns the parsed role based on the given role value.
 *
 * @param {string} role - The role value to be parsed.
 * @returns {string} The parsed role.
 */
export const getParsedRole = (role) => {
  switch (role) {
    case _ROLES.ADMIN:
      return "Administrateur";
    case _ROLES.USER:
      return "Utilisateur";
    case _ROLES.TECHNICIEN:
      return "Technicien";
    case _ROLES.CLIENT:
      return "Client";
    case _ROLES.SUPER_ADMIN:
      return "Super Administrateur";
    default:
      return "";
  }
};

/**
 * Rounds a value to the specified number of decimal places.
 *
 * @param {number} value - The value to be rounded.
 * @param {number} decimals - The number of decimal places to round to.
 * @returns {number} - The rounded value.
 */
export function roundValue(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}



/**
 * Calculates the number of hours and minutes from the given minutes.
 *
 * @param {number} minutes - The total number of minutes.
 * @param {boolean} [formated=false] - Indicates whether the result should be formatted.
 * @returns {string} - The calculated hours and minutes.
 */
export const minutesInHours = (minutes, formated=false) => {
  if (!minutes || minutes === 0) return 0
  const hours = minutes / 60;
   if(Math.floor(hours) === 0) 
    return formated ? `${minutes}min` : `0:${minutes}`;
  else
    return formated 
    ? `${Math.floor(hours)}h${minutes % 60}` 
    : `${Math.floor(hours)}:${minutes % 60}` ;
}

/**
 * Calculates the start and end dates of the week for a given date.
 *
 * @param {Date} date - The date for which to calculate the week start and end dates.
 * @returns {Object} - An object containing the start and end dates of the week in the format { start: 'YYYY-MM-DD', end: 'YYYY-MM-DD' }.
 */
export const datesStartAndEndWeek = (date) => {
  const weekStart = date.clone().startOf("week").format("YYYY-MM-DD");
  const weekEnd = date.clone().endOf("week").format("YYYY-MM-DD");

  return { start: weekStart, end: weekEnd };
}