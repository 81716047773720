import { Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { _COLORS } from "../../../utils/constants";
import { BaseConnaissanceContext } from "../../../contexts/BaseConnaissanceContext";
import { useLocation, useParams } from "react-router-dom";
import { _ROUTES } from "../../../utils/_routes_constants";
import { UserContext } from "../../../contexts/UserContext";
import { ArticleTitle } from "./ArticleTitle";
import ArticleHeader from "./ArticleHeader";
import ArticleContent from "./ArticleContent";
import ArticleFooter from "./ArticleFooter";
import { getDataBaseConnaissance } from "../../../repository/BaseConnaissance";

export function BaseConnaissanceArticle() {
  const { article, setArticle } = useContext(BaseConnaissanceContext);
  const articleId = useParams().articleId ?? null;
  const { user } = useContext(UserContext);
  const urlActuelArticle = useLocation().pathname;
  const isTechnicien =
    user &&
    Object.values(user?.roles).includes("ROLE_TECHNICIEN" || "ROLE_ADMIN");

  useEffect(() => {
    if (articleId) {
      getDataBaseConnaissance(
        {}, setArticle, 
        "Erreur lors de la récupération de l'article", 
        _ROUTES.BASE_CONNAISSANCE_ARTICLES + "/" + articleId
      );
    }
  }, [articleId]);

  return (
    <>
      <Grid
        container
        width={"100%"}
        sx={{
          p: 2,
          mb: 2,
          display: "flex",
          boxShadow: `4px 4px 4px ${_COLORS.midGrey}`,
          border: `1px solid ${_COLORS.darkGrey}`,
          color: _COLORS.darkGrey,
        }}
      >
        {!article ? (
          <Typography variant="h6">
            Pour commencer, merci de bien vouloir choisir un article...
          </Typography>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sx={{
                w: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* INCLUDE DELETE-EDIT-SHARE ARTICLE */}
              <ArticleHeader
                article={article}
                isTechnicien={isTechnicien}
                urlActuelArticle={urlActuelArticle}
                setArticle={setArticle}
              />
            </Grid>
            <Grid item xs={12}>
              {/* INCLUDE KEYWORDS + TITLE */}
              <ArticleTitle article={article} />
            </Grid>
            <Grid item xs={12}>
              {/* INCLUDE CONTENT ARTICLE */}
              <ArticleContent article={article} />
            </Grid>
            <Divider />
            <Grid item xs={12}>
              {/* INCLUDE USER CREATED/UPDATED ARTICLE */}
              <ArticleFooter article={article} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
