import { Box, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import { _COLORS } from "../../../utils/constants";
import { __FORM_STYLES } from "../../../utils/_style_constants";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InstantEditSelect from "../../form/InstantEditSelect";
import { _ROUTES } from "../../../utils/_routes_constants";
import InstantEditAutocomplete from "../../form/InstantEditAutocomplete";
import { InstantEditMultipleSelect } from "../../form/InstantEditMultipleSelect";
import InstantDateTimePicker from "../../form/InstantDateTimePicker";
import BasicModal from "../../helpers/BasicModal";
import { ListContactsModal } from "./Modals/ListContactsModal";
import ListContactsFournisseursModal from "./Modals/ListContactsFournisseursModal";
import Loader from "../../utils/Loader";
import ContactClientOrFournisseurForm from "./Modals/ContactClientForm";
import { TicketEditInfoDataGetter } from "./TicketEditInfoDataGetter";
import { UserContext } from "../../../contexts/UserContext";

export const TicketEditInfo = ({
  ticket,
  setTicket,
  refresh,
  setRefresh,
  edit = true,
  listTechniciens,
  setListTechniciens,
  listProduitsServices,
  setListProduitsServices,
}) => {
  const [listContacts, setListContacts] = useState([]);
  const [listClients, setListClients] = useState([]);
  const [listFournisseurs, setListFournisseurs] = useState([]);
  const [listFournisseurContacts, setListFournisseurContacts] = useState([]);

  const [listPriorities, setListPriorities] = useState([]);
  const [listOrigin, setListOrigin] = useState([]);
  const [listInvoiceType, setListInvoiceType] = useState([]);
  const [listType, setListType] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  // MODALS
  const [openModalContact, setOpenModalContact] = useState(false);
  const [openModalContactCreate, setOpenModalContactCreate] = useState(false);
  const [openModalFournisseurContact, setOpenModalFournisseurContact] =
    useState(false);
  const [
    openModalFournisseurContactCreate,
    setOpenModalFournisseurContactCreate,
  ] = useState(false);
  const { getUser } = useContext(UserContext);
  const user = getUser();

  useEffect(() => {
    if(!edit){
      setTicket({
        ...ticket,
        user: user
      })
    }
    console.log("ticket", ticket);
  }, []);

  return (
    <>
      <Card>
        <TicketEditInfoDataGetter
          ticket={ticket}
          listTechniciens={listTechniciens}
          setListTechniciens={setListTechniciens}
          listClients={listClients}
          setListClients={setListClients}
          listFournisseurs={listFournisseurs}
          setListFournisseurs={setListFournisseurs}
          listProduitsServices={listProduitsServices}
          setListProduitsServices={setListProduitsServices}
          listPriorities={listPriorities}
          setListPriorities={setListPriorities}
          listOrigin={listOrigin}
          setListOrigin={setListOrigin}
          listInvoiceType={listInvoiceType}
          setListInvoiceType={setListInvoiceType}
          listType={listType}
          setListType={setListType}
          listStatus={listStatus}
          setListStatus={setListStatus}
          setListContacts={setListContacts}
          setListFournisseurContacts={
            setListFournisseurContacts
          }></TicketEditInfoDataGetter>
        <CardHeader
          title="Information de tickets"
          sx={{
            backgroundColor: _COLORS.lightGrey,
          }}
          titleTypographyProps={{
            sx: { fontSize: "1rem", fontWeight: "bold" },
          }}
        />
        {ticket ? (
          <CardContent>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditSelect
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Technicien"
                field="user"
                options={listTechniciens}
                optionLabel={["lastName", "firstName"]}
                optionValue="@id"
                relation={true}
                edit={edit}></InstantEditSelect>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditAutocomplete
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Client"
                uri={ticket ? ticket["@id"] : ""}
                field="societe"
                options={listClients}
                optionLabel="name"
                refresh={refresh}
                setRefresh={setRefresh}
                relation={true}
                edit={edit}></InstantEditAutocomplete>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditMultipleSelect
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Contact(s)"
                field="contacts"
                options={listContacts}
                optionValue="@id"
                optionLabel={["firstName", "lastName"]}
                relation={true}></InstantEditMultipleSelect>
              {/* LIST CONTACT CLIENT MODAL */}
              <BasicModal
                showModal={openModalContact}
                setShowModal={setOpenModalContact}
                content={
                  <ListContactsModal
                    listContacts={listContacts}
                    setListContacts={setListContacts}></ListContactsModal>
                }
                title={"Liste des contacts"}
                width={1400}></BasicModal>
              {/* ------------------------- */}
              {/* CREATE CONTACT MODAL */}
              <BasicModal
                showModal={openModalContactCreate}
                setShowModal={setOpenModalContactCreate}
                content={
                  <ContactClientOrFournisseurForm
                    setListContacts={setListContacts}
                    client={ticket.societe}
                    setShowModal={
                      setOpenModalContactCreate
                    }></ContactClientOrFournisseurForm>
                }
                title={"Créer un contact"}></BasicModal>
              {/* ------------------------- */}
              <IconButton
                aria-label="Add contact"
                size="small"
                onClick={() => setOpenModalContactCreate(true)}>
                <PersonAddIcon
                  sx={{ color: _COLORS.defaultGreen, cursor: "pointer" }}
                  //@TODO Add contact Client
                ></PersonAddIcon>
              </IconButton>
              <IconButton
                aria-label="list contact"
                size="small"
                onClick={() => setOpenModalContact(true)}>
                <RemoveRedEyeIcon
                  sx={{ color: _COLORS.defaultBlue, cursor: "pointer" }}
                  //@TODO View contact Client List
                ></RemoveRedEyeIcon>
              </IconButton>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditAutocomplete
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Fournisseur"
                uri={ticket ? ticket["@id"] : ""}
                field="fournisseur"
                options={listFournisseurs}
                optionLabel="name"
                refresh={refresh}
                setRefresh={setRefresh}
                relation={true}></InstantEditAutocomplete>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditMultipleSelect
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Contact Fournisseur"
                field="fournisseurContacts"
                options={listFournisseurContacts}
                optionValue="@id"
                optionLabel={[
                  "firstName",
                  "lastName",
                ]}></InstantEditMultipleSelect>
              {/* LIST CONTACT FOURNISSEUR MODAL */}
              <BasicModal
                showModal={openModalFournisseurContact}
                setShowModal={setOpenModalFournisseurContact}
                content={
                  <ListContactsFournisseursModal
                    listFournisseurContacts={listFournisseurContacts}
                    setListFournisseurContacts={
                      setListFournisseurContacts
                    }></ListContactsFournisseursModal>
                }
                title={"Liste des contacts fournisseur"}
                width={1400}></BasicModal>
              {/* ------------------------------ */}
              {/* CREATE CONTACT FOURNISSEUR MODAL */}
              <BasicModal
                showModal={openModalFournisseurContactCreate}
                setShowModal={setOpenModalFournisseurContactCreate}
                content={
                  <ContactClientOrFournisseurForm
                    setListFournisseurContacts={setListFournisseurContacts}
                    fournisseur={ticket.fournisseur}
                    setShowModal={
                      setOpenModalFournisseurContactCreate
                    }></ContactClientOrFournisseurForm>
                }
                title={"Créer un contact fournisseur"}></BasicModal>
              {/* ------------------------- */}
              <IconButton
                aria-label="Add contact fournisseur"
                size="small"
                onClick={() => setOpenModalFournisseurContactCreate(true)}>
                <PersonAddIcon
                  sx={{ color: _COLORS.defaultGreen, cursor: "pointer" }}
                  //@TODO Add contact Fournisseur
                ></PersonAddIcon>
              </IconButton>
              <IconButton
                aria-label="list contact"
                size="small"
                onClick={() => setOpenModalFournisseurContact(true)}>
                <RemoveRedEyeIcon
                  sx={{ color: _COLORS.defaultBlue, cursor: "pointer" }}
                  //@TODO View contact Fournisseur List
                ></RemoveRedEyeIcon>
              </IconButton>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditAutocomplete
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Produit | Service"
                field="produitService"
                options={listProduitsServices}
                optionLabel={["name"]}
                relation={true}></InstantEditAutocomplete>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditSelect
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Priorité *"
                field="priority"
                options={listPriorities}
                optionLabel="name"
                optionValue="@id"
                relation={true}
                defaultValue={"/api/ticket-priorities/1"}></InstantEditSelect>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditSelect
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Origine *"
                field="origin"
                options={listOrigin}
                optionLabel="name"
                optionValue="@id"
                relation={true}></InstantEditSelect>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditSelect
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Facturation"
                field="invoiceType"
                options={listInvoiceType}
                optionLabel="name"
                optionValue="@id"
                relation={true}></InstantEditSelect>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditSelect
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Type *"
                field="type"
                options={listType}
                optionLabel="name"
                optionValue="@id"
                relation={true}></InstantEditSelect>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantEditSelect
                entityValue={ticket}
                setEntityValue={setTicket}
                label="État"
                field="status"
                options={listStatus}
                optionLabel="name"
                optionValue="@id"
                relation={true}></InstantEditSelect>
            </Box>
            <Box sx={__FORM_STYLES.boxInputRow}>
              <InstantDateTimePicker
                entityValue={ticket}
                setEntityValue={setTicket}
                label="Date de résolution éstimée"
                field="estimedEndAt"></InstantDateTimePicker>
            </Box>
            {edit ? (
              <Box sx={__FORM_STYLES.boxInputRow}>
                <InstantDateTimePicker
                  entityValue={ticket}
                  setEntityValue={setTicket}
                  label="Date de cloture"
                  field="closedAt"
                  disabled={true}></InstantDateTimePicker>
              </Box>
            ) : (
              <Box sx={__FORM_STYLES.boxInputRow}>
                <InstantDateTimePicker
                  entityValue={ticket}
                  setEntityValue={setTicket}
                  label="Date de création"
                  field="createdAt"></InstantDateTimePicker>
              </Box>
            )}
          </CardContent>
        ) : (
          <CardContent>
            {/* LOADER */}
            <Loader height={100}></Loader>
          </CardContent>
        )}
      </Card>
    </>
  );
};

export default TicketEditInfo;
