import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import { _TIERS, _ACTIONS } from "../../../utils/constants";
import MailTo from "../../helpers/MailTo";
import PhoneFormated from "../../../utils/PhoneFormated";
import _ from "lodash";
import LinkTo from "../../helpers/LinkTo";
import { FaGoogleDrive, FaCubes } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useEffect, useState } from "react";
import { createToast } from "../../../utils/toast";
import { linkify } from "../../../utils/function";
import BasicModal from "../../helpers/BasicModal";
import FormModalTiers from "./FormModalEditTiers";
import { _ROUTES } from "../../../utils/_routes_constants";
import { RequestHelper } from "../../../utils/requestHelper";

export const TicketEditTiers = ({ ticket, setTicket }) => {
  const [showModal, setShowModal] = useState(false);
  const [produits, setProduits] = useState(null);
  const [users, setUsers] = useState(null);
  const requestHelper = new RequestHelper();
  const infos = [
    {
      label: _TIERS.refToolin,
      value:
        ticket?.societe?.referent?.firstName.toUpperCase() +
        " " +
        ticket?.societe?.referent?.lastName,
    },
    {
      label: _TIERS.refClient,
      value:
        ticket?.societe?.referentClient?.firstName.toUpperCase() +
        " " +
        ticket?.societe?.referentClient?.lastName,
    },
    {
      label: _TIERS.actifConfig,
      value:
        produits !== null
          ? linkTo(
              "/actifs_configuration/list/" + ticket?.societe?.id,
              <FaCubes />,
              false
            )
          : null,
    },
    {
      label: _TIERS.folderDrive,
      value: linkTo(ticket?.societe?.folderDrive, <FaGoogleDrive />, true),
    },
    { label: _TIERS.adresse, value: ticket?.societe?.adresse },
    { label: _TIERS.fixPro, value: PhoneFormated(ticket?.societe?.phone) },
    { label: _TIERS.adresseMail, value: mailTo(ticket?.societe?.email) },
    { label: _TIERS.domaine, value: domaines(ticket?.societe?.domains) },
  ];
  const _ERROR_ACTIF =
    "Une erreur est survenue lors de la récupération des actifs et configuration";
  const _ERROR_USER = "Erreur lors de la récupération des référents Toolin";

  useEffect(() => {
    if (_.isEmpty(ticket)) return;

    getProduits(ticket);
  }, []);

  useEffect(() => {
    getUsers();
  }, [ticket]);

  /**
   * Renders a MailTo component with the given email address.
   *
   * @param {string} email - The email address to render.
   * @returns {JSX.Element|null} - The rendered MailTo component or null if the email is null.
   */
  function mailTo(email) {
    if (email === null || _.isUndefined(email)) return null;

    return <MailTo email={email} />;
  }

  /**
   * Generates a link using the provided parameters.
   *
   * @param {string|null} link - The link URL.
   * @param {string|null} icon - The icon to display with the link.
   * @param {boolean} http - Whether the link is an HTTP link.
   * @returns {JSX.Element|null} The generated link component.
   */
  function linkTo(link, icon = null, http = false) {
    if (link === null || _.isUndefined(link)) return null;

    return (
      <div>
        <LinkTo link={link} icon={icon} http={http} />
      </div>
    );
  }

  /**
   * Converts a comma-separated string of domains into an array of links.
   *
   * @param {string} domains - The comma-separated string of domains.
   * @returns {Array} - An array of links generated from the domains.
   */
  function domaines(domains) {
    if (domains === null || domains === "" || domains === undefined)
      return null;
    const array = domains.split(",");
    return array.map((domain) => {
      return linkTo(domain.trim(), null, true);
    });
  }

  /**
   * Retrieves the products for a given ticket.
   *
   * @param {Object} ticket - The ticket object.
   * @returns {Promise<void>} - A promise that resolves when the products are retrieved.
   */
  function getProduits(ticket) {
    if (_.isEmpty(ticket?.societe)) return;
    try {
      requestHelper
        .doRequest(
          _ACTIONS.GET,
          `${_ROUTES.SOCIETE_PRODUIT_SERVICE}/${ticket?.societe?.id}`
        )
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            setProduits(response.data);
          } else {
            createToast("error", _ERROR_ACTIF);
          }

          setProduits(response.data);
        });
    } catch (error) {
      createToast("error", _ERROR_ACTIF);
    }
  }

  /**
   * Fetches the list of users from the server.
   * @returns {Promise<void>} A promise that resolves when the users are fetched successfully.
   */
  async function getUsers() {
    try {
      const response = await requestHelper.doRequest(
        _ACTIONS.GET,
        _ROUTES.USER,
        { pagination: "disabled" }
      );
      if ([200, 201].includes(response.status)) {
        setUsers(response.data["hydra:member"]);
      } else {
        createToast("error", _ERROR_USER);
      }
    } catch (error) {
      createToast("error", _ERROR_USER);
    }
  }

  return (
    <>
      <BasicModal
        showModal={showModal}
        modalWidth="800px"
        setShowModal={setShowModal}
        title="Modifier le tiers"
        width={1000}
        content={
          <FormModalTiers
            ticket={ticket}
            users={users}
            setShowModal={setShowModal}
            setTicket={setTicket}
          />
        }></BasicModal>
      <Box
        sx={{
          border: "1px dashed blue",
          borderRadius: "5px",
          padding: "5px 5px 5px 5px",
        }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: "black", color: "white" }}>
                <TableCell sx={{ color: "white", fontSize: "20px" }}>
                  Informations du tiers
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "20px" }}
                  align="right">
                  <span
                    title="Mettre à jour"
                    onClick={() => {
                      if (ticket.societe && ticket.societe.contacts) {
                        console.log("show modal");
                        setShowModal(true);
                      } else {
                        createToast("error", "Veuillez selectionner un client");
                      }
                    }}>
                    <FiEdit />
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infos.map((info, index) => {
                if (
                  info.value !== null &&
                  info.value !== "" &&
                  info.value !== undefined &&
                  info.value !== "undefined undefined"
                ) {
                  return (
                    <TableRow key={index}>
                      <TableCell width="35%" align="right">
                        {" "}
                        <b>{info.label}</b> :{" "}
                      </TableCell>
                      <TableCell>{info.value}</TableCell>
                    </TableRow>
                  );
                }
              })}
              <Schemas societe={ticket?.societe} />
            </TableBody>
          </Table>
        </TableContainer>
        <Commentaire commentaire={ticket?.societe?.commentaire} />
      </Box>
    </>
  );
};

/**
 * Renders a comment box with the provided comment.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.commentaire - The comment to be displayed.
 * @returns {JSX.Element|null} The rendered comment box or null if the comment is empty.
 */
const Commentaire = ({ commentaire }) => {
  if (commentaire === null || commentaire === "" || commentaire === undefined)
    return null;
  commentaire = linkify(String(commentaire));

  return (
    <Box
      sx={{
        border: "1px dashed black",
        borderRadius: "5px",
        padding: "5px 5px 5px 5px",
      }}>
      <div dangerouslySetInnerHTML={{ __html: commentaire }} />
    </Box>
  );
};

const Schemas = ({ societe }) => {
  if (
    societe?.societeSchemas === null ||
    societe?.societeSchemas === undefined ||
    societe?.societeSchemas === "" ||
    _.isEmpty(societe?.societeSchemas)
  )
    return null;

  const array = societe?.societeSchemas.map((schema, index) => {
    return (
      <TableRow key={index}>
        <TableCell></TableCell>
        <TableCell>
          <LinkTo link={`/files/_schemas/${societe.id}/${schema.images}`} />
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableRow>
        <TableCell width="35%" align="right">
          {" "}
          <b>Schéma</b> :{" "}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      {array}
    </>
  );
};

export default TicketEditTiers;
