import { Box, Typography } from "@mui/material";
import { ListToolBar } from "./ListToolBar/ListToolBar";
import { TicketList } from "./TicketList";
import { _TICKET_STYLES } from "../../../utils/_style_constants";

const ListContainer = () => {
  return (
    <>
      <Box sx={_TICKET_STYLES.TICKET_CONTAINER}>
        <Typography
          sx={{
            color: "#666",
          }}
          fontSize={12}
        >
          * Durée : Durée facturable (mn) | Durée réelle (mn)
        </Typography>

        <ListToolBar />

        <TicketList />
      </Box>
    </>
  );
};

export default ListContainer;
