import { Avatar, Box, Typography } from "@mui/material";
import { memo, useContext, useEffect, useState } from "react";
import { _COLORS } from "../../utils/constants";
import { UserContext } from "../../contexts/UserContext";
import { getParsedRole } from "../../utils/function";
import PersonIcon from "@mui/icons-material/Person";

export const Profile = memo(() => {
  const { user } = useContext(UserContext);
  const [image, setImage] = useState(null);
  const [currentRole, setCurrentRole] = useState(null);

  function role() {
    if (user) {
      const arrayRoles = Object.keys(user.roles);
      if (arrayRoles.length > 1) {
        return getParsedRole(arrayRoles[1]);
      }
    }

    return "";
  }

  useEffect(() => {
    if (user) {
      setCurrentRole(role());
    }
    if (user.avatar) {
      if (user.avatar.startsWith("/var/www/html/public/")) {
        setImage(user.avatar.replace("/var/www/html/public", "/"));
      } else {
        setImage(user.avatar);
      }
    }
  }, [user]);

  return (
    <Box
      sx={{
        w: "100%",
        border: `1px solid ${_COLORS.midGrey}`,
        backgroundColor: _COLORS.white,
      }}
    >
      <Box
        sx={{
          backgroundColor: _COLORS.defaultGreen,
          p: 2,
          display: "flex",
          alignItems: "center",
          gap: 2,
          borderRadius: 0.5,
        }}
      >
        <Box>
          {image ? (
            <Avatar
              alt={user?.firstname + " " + user?.lastname}
              src={process.env.REACT_APP_SYMFONY_URL + image}
              sx={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                border: "1px solid " + _COLORS.grey,
              }}
            />
          ) : (
            <Avatar
              sx={{
                width: 100,
                height: 100,
                borderRadius: "50%",
              }}
            >
              <PersonIcon />
            </Avatar>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              color: _COLORS.white,
            }}
          >
            {user?.firstname} {user?.lastname}
          </Typography>
          <Typography
            sx={{
              color: _COLORS.white,
              fontStyle: "italic",
            }}
            variant="body2"
          >
            {currentRole ?? ""}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderBottom: `1px solid ${_COLORS.midGrey}`,
          p: 1,
          display: "flex",
          gap: 1,
        }}
      >
        <Typography fontWeight={"bold"} variant="body2">
          Tiers :
        </Typography>
        <Typography variant="body">{user?.societeName ?? "N/A"}</Typography>
      </Box>
      <Box
        sx={{
          borderBottom: `1px solid ${_COLORS.midGrey}`,
          p: 1,
          display: "flex",
          gap: 1,
        }}
      >
        <Typography fontWeight={"bold"} variant="body2">
          Poste/Fonction :
        </Typography>
        <Typography variant="body">{user?.poste ?? "N/A"}</Typography>
      </Box>
      <Box
        sx={{
          borderBottom: `1px solid ${_COLORS.midGrey}`,
          p: 1,
          display: "flex",
          gap: 1,
        }}
      >
        <Typography fontWeight={"bold"} variant="body2">
          Adresse email:
        </Typography>
        <Typography variant="body">{user?.email ?? "N/A"}</Typography>
      </Box>
    </Box>
  );
});
