import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { useState } from "react";
import { _COLORS } from "../../../utils/constants";
import { FileType } from "../../form/FileType";
import { doCreateTicket, doCreateTicketValidation } from "./TicketCreateUtils";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const _DEFAULT_ACTION = {
  actionType: "",
  description: "",
  user: "",
  durationEstimated: 0,
  durationReal: 0,
  createdAt: new moment(),
  achievedAt: new moment(),
  files: [],
  isPrivate: false,
  purchases: [],
};

export const TicketCreateDescription = ({
  ticket,
  setTicket,
  actionsExpanded,
  setActionsExpanded,
  formData,
  setFormData,
}) => {
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
          marginBottom: "10px",
          border: "1px solid #3C8DBC",
          borderRadius: "5px",
          padding: "10px",
        }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "end",
            padding: "10px",
            width: "50%",
          }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label="Objet *"
              value={ticket.subject}
              onChange={(e) => {
                setTicket({ ...ticket, subject: e.target.value });
              }}></TextField>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={(e) => {
                setTicket({ ...ticket, description: ticket.objet });
                setDescription(ticket.objet);
              }}>
              <ContentCopyIcon sx={{ color: _COLORS.error }} fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography variant="h6">Description</Typography>
            <IconButton
              onClick={(e) => {
                setTicket({ ...ticket, objet: description });
              }}>
              <ContentCopyIcon sx={{ color: _COLORS.error }} fontSize="small" />
            </IconButton>
          </Box>
          <CKEditor
            sx={{
              marginTop: "10px",
            }}
            editor={Editor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
            onBlur={(event, editor) => {
              const data = editor.getData();
              setTicket({ ...ticket, description: data });
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <FileType
            setEntity={(files) => {
              setFormData((formData) => {
                return {
                  ...formData,
                  ticketFiles: files,
                };
              });
            }}
          />
        </Box>
        {/* ---------- */}
        {/* Footer Bar */}
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: "1px solid #F4F4F4",
              top: 0,
              paddingTop: "10px",
            }}>
            {/* start */}
            <Box
              sx={{
                fontSize: "0.7em",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "10px",
              }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  if (!actionsExpanded) {
                    setActionsExpanded(!actionsExpanded);
                    setTicket({
                      ...ticket,
                      actions: [_DEFAULT_ACTION],
                    });
                  } else {
                    setActionsExpanded(!actionsExpanded);
                    setTicket({
                      ...ticket,
                      actions: [],
                    });
                  }
                }}>
                {actionsExpanded ? "Supprimer l'action" : "Crée l'action"}
              </Button>
            </Box>
            {/* end */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "10px",
              }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  // Ticket Validation
                  if (doCreateTicketValidation(ticket) === true) {
                    doCreateTicket(formData, navigate);
                  }
                }}>
                Enregistrer
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TicketCreateDescription;
