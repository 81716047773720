import { Box, Button, Menu, MenuItem } from "@mui/material";
import { _BTN } from "../../../../utils/_style_constants";
import { useContext, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { _TICKET_OWNER } from "../../../../utils/_tickets_constants";
import { TicketsListContext } from "../../../../contexts/TicketsListContext";
import { useNavigate } from "react-router-dom";
import { _ROUTER_DOM_URL } from "../../../../utils/_routes_constants";

export const NewTicket = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setOwnerFilter } = useContext(TicketsListContext);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (owner) => {
    setAnchorEl(null);
    owner && setOwnerFilter(owner);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Button
          size="small"
          sx={_BTN.FILTER_BTN}
          variant="contained"
          color="default"
          onClick={() => {
            navigate(_ROUTER_DOM_URL.TICKET_CREATE);
          }}>
          <AddCircleOutlineIcon /> Nouveau Ticket
        </Button>
        <Box>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            size="small"
            variant="contained"
            color="default">
            ...{" "}
            {open ? (
              <ArrowDropUpIcon fontSize="small" />
            ) : (
              <ArrowDropDownIcon fontSize="small" />
            )}
          </Button>
          <Menu
            anchorEl={anchorEl}
            id="basic-menu"
            open={open}
            onClose={() => handleClose(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            <MenuItem onClick={() => handleClose(_TICKET_OWNER.PERSO)}>
              {_TICKET_OWNER.PERSO}
            </MenuItem>
            <MenuItem onClick={() => handleClose(_TICKET_OWNER.ALL)}>
              {_TICKET_OWNER.ALL}
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>
  );
};

export default NewTicket;
