import { Box, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import { AiTwotoneInfoCircle } from "react-icons/ai";
import { _BS_MESSAGES } from "../../../utils/base-connaissance/_constants_base_connaissance";
import { _COLORS } from "../../../utils/constants";

/**
 * Box affichant des infos sur le tableau de configuration de la base de connaissance.
 *
 * @component
 * @returns {JSX.Element} BoxInfoConfiguration component
 */
export const BoxInfoConfiguration = memo(() => {
  return (
    <>
      {Object.values(_BS_MESSAGES).map((message, index) => (
        <Grid item xs={12} key={index}>
          <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
            <AiTwotoneInfoCircle />
            <Typography
              variant="body2"
              sx={{
                fontSize: "13px",
                color: _COLORS.darkGrey,
              }}
            >
              <em>{message}</em>
            </Typography>
          </Box>
        </Grid>
      ))}
    </>
  );
});
