import { FaFileContract } from "react-icons/fa";
import { useEffect, useMemo, useState, useContext } from "react";
import { Box } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import {
    ACTIF,
    CONTRAT_HEBERGEMENT_COLUMNS_ACTIF,
    CONTRAT_HEBERGEMENT_COLUMNS_DESACTIVE,
    CONTRAT_MAINTENANCE_COLUMNS_ACTIF,
    CONTRAT_MAINTENANCE_COLUMNS_DESACTIVE,
    CONTRAT_AUTRE_COLUMNS_ACTIF,
    CONTRAT_AUTRE_COLUMNS_DESACTIVE,
    CONTRAT_TELESAUVEGARDE_COLUMNS_ACTIF,
    CONTRAT_TELESAUVEGARDE_COLUMNS_DESACTIVE,
    CONTRAT_REGIE_COLUMNS_ACTIF,
    CONTRAT_REGIE_COLUMNS_DESACTIVE,
    CONTRAT_LOCATION_COLUMNS_ACTIF,
    CONTRAT_LOCATION_COLUMNS_DESACTIVE,
    CONTRAT_BEEMO_COLUMNS_ACTIF,
    CONTRAT_BEEMO_COLUMNS_DESACTIVE,
    INFO_MAINTENANCE,
    INFO_HEBERGEMENT,
    INFO_AUTRE,
    INFO_REGIE,
    INFO_TELESAUVEGARDE,
    TYPE_CONTRATS
 } from "./ColumnsContrat";
import { getRoute } from "./FunctionsContrat";
import { forEach } from "lodash";
import { Dialog, Button } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";
import {
  getDataContrat,
  putDataContrat,
} from "../../repository/contrats/Contrat";
import { BEEMO_PRODUIT_DOLIBARS, LOCATION_PRODUIT_DOLIBARS } from "../../utils/constants";
import InfoContrat from "./InfoContrat";
import { _ROLES } from "../../utils/constants";
import useHasRoles from "../../hooks/useHasRole";
import { UserContext } from "../../contexts/UserContext";


export let handleChangeReconduite = null;
export let handleChangePaused = null;
export let handleEdit = null;
export let handleChangeActif = null;
export let totalPrice = null;
export let totalCreditContrat = null;
export let totalPriceAchat = null;
export let totalPriceTele = null;
export let totalPriceRegie = null;

const DataGridDetailContrats = ({
  title,
  typeContrat,
  status,
  setShowModal,
  setRow,
  row,
  reload,
  setReload,
}) => {
  const { getUser } = useContext(UserContext);
  const user = getUser();
  const canAccess = useHasRoles(user, [_ROLES.TECHNICIEN, _ROLES.ADMIN]);
  const chips = [];
  const [id, setId] = useState(null);
  const [isDeleted, setIsDeleted] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Retrieves information based on the type of contract.
   *
   * @param {string} typeContrat - The type of contract.
   * @returns {JSX.Element} - The JSX element containing the retrieved information.
   */
  const infos = (typeContrat) => {
    switch (typeContrat) {
      case TYPE_CONTRATS.CONTRAT_MAINTENANCE:
        addInfos(INFO_MAINTENANCE);
        break;
      case TYPE_CONTRATS.CONTRAT_HEBERGEMENT:
        addInfos(INFO_HEBERGEMENT);
        break;
      case TYPE_CONTRATS.CONTRAT_AUTRES:
        addInfos(INFO_AUTRE);
        break;
      case TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE:
        addInfos(INFO_TELESAUVEGARDE);
        break;
      case TYPE_CONTRATS.CONTRAT_REGIE:
        addInfos(INFO_REGIE);
        break;
      default:
        break;
    }

    return (
      <>
        {status === ACTIF && <Box sx={{ marginBottom: "10px" }}>{chips}</Box>}
      </>
    );
  };

  const columns = [];

  totalPrice = useMemo(() => {
        let prix = 0;
        const typeProduitsDolibars = typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO  ? BEEMO_PRODUIT_DOLIBARS : LOCATION_PRODUIT_DOLIBARS;
        data.map((item) => {
          if(!_.isUndefined(item[typeProduitsDolibars])){
            item[typeProduitsDolibars].map((produit) => {
                if( !_.isUndefined(produit.prix) && produit.prix !== null)
                    prix += produit.prix;
            });
          }
        });

        return prix;
  }, [data]);

  totalCreditContrat = useMemo(() => {
    return data
      .map((item) => parseFloat(item.creditContrat))
      .reduce((a, b) => a + b, 0);
  }, [data]);

  totalPriceAchat = useMemo(() => {
    return data
      .map((item) => parseFloat(item.costPrice))
      .reduce((a, b) => a + b, 0);
  }, [data]);

  totalPriceTele = useMemo(() => {
    if (typeContrat !== TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE) return;
    return data
      .map((item) => parseFloat(item.prixFacturation.vente))
      .reduce((a, b) => a + b, 0);
  }, [data]);

  totalPriceRegie = useMemo(() => {
    if (typeContrat !== TYPE_CONTRATS.CONTRAT_REGIE) return;
    let prix = 0;
    data.map((item) => {
      if (item.contratSocieteRegieContratTypeRegies.length > 0) {
        item.contratSocieteRegieContratTypeRegies.map((tarif) => {
          if (tarif.price !== null) {
            prix += parseFloat(tarif.price);
          } else {
            if (!_.isUndefined(tarif.contratTypeRegie.price))
              prix += tarif.contratTypeRegie?.price;
          }
        });
      }
    });

    return prix;
  }, [data]);


    switch (typeContrat) {
        case TYPE_CONTRATS.CONTRAT_MAINTENANCE:
            status === ACTIF
            ? addColumns(CONTRAT_MAINTENANCE_COLUMNS_ACTIF)
            : addColumns(CONTRAT_MAINTENANCE_COLUMNS_DESACTIVE);
            break;
        case TYPE_CONTRATS.CONTRAT_HEBERGEMENT:
            status === ACTIF
            ? addColumns(CONTRAT_HEBERGEMENT_COLUMNS_ACTIF)
            : addColumns(CONTRAT_HEBERGEMENT_COLUMNS_DESACTIVE);
            break;
        case TYPE_CONTRATS.CONTRAT_AUTRES:
            status === ACTIF
            ? addColumns(CONTRAT_AUTRE_COLUMNS_ACTIF)
            : addColumns(CONTRAT_AUTRE_COLUMNS_DESACTIVE);
            break;
        case TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE:
            status === ACTIF
            ? addColumns(CONTRAT_TELESAUVEGARDE_COLUMNS_ACTIF)
            : addColumns(CONTRAT_TELESAUVEGARDE_COLUMNS_DESACTIVE);
            break;
        case TYPE_CONTRATS.CONTRAT_REGIE:
            status === ACTIF
            ? addColumns(CONTRAT_REGIE_COLUMNS_ACTIF)
            : addColumns(CONTRAT_REGIE_COLUMNS_DESACTIVE);
            break;
        case TYPE_CONTRATS.CONTRAT_LOCATION:
            status === ACTIF
            ? addColumns(CONTRAT_LOCATION_COLUMNS_ACTIF)
            : addColumns(CONTRAT_LOCATION_COLUMNS_DESACTIVE);
            break;
            case TYPE_CONTRATS.CONTRAT_BEEMO:
                status === ACTIF
            ? addColumns(CONTRAT_BEEMO_COLUMNS_ACTIF)
            : addColumns(CONTRAT_BEEMO_COLUMNS_DESACTIVE);
            break;
        default:
            break;
    }

  /**
   * Adds columns to the existing columns array.
   *
   * @param {Array} columnsToAdd - The columns to add.
   */
  function addColumns(columsToAdd) {
    forEach(columsToAdd, (column) => {
      columns.push(column);
    });
  }

  /**
   * Adds the provided information to the chips array.
   *
   * @param {Array} infos - The information to be added.
   */
  function addInfos(infos) {
    forEach(infos, (info) => {
      chips.push(info);
    });
  }

  /**
   * Retrieves data for a given type of contract.
   *
   * @param {string} typeContrat - The type of contract.
   */
  function getData(typeContrat) {
    const ROUTE = getRoute(typeContrat);
    const isDeleted = status === ACTIF ? 0 : 1;

    if (ROUTE === null) return;

    getDataContrat(
      { isDeleted: isDeleted, isClotured: 0 },
      setData,
      "Une erreur est survenue lors de la récupération des contrats",
      ROUTE
    );
  }

  handleChangeReconduite = (id, isReconduite) => {
    const ROUTE = getRoute(typeContrat);

    if (ROUTE === null) return;

    const isUpdated = (results) => {
      setData((data) =>
        data.map((item) =>
          item.id === id ? { ...item, isReconduite: !isReconduite } : item
        )
      );
    };

    putDataContrat(
      { isReconduite: !isReconduite },
      "Une erreur est survenue lors du changement de statut",
      `${ROUTE}/${id}`,
      isUpdated
    );
  };

  handleChangePaused = (id, isPaused) => {
    const ROUTE = getRoute(typeContrat);

    if (ROUTE === null) return;

    const isUpdated = (results) => {
      setData((data) =>
        data.map((item) =>
          item.id === id ? { ...item, isPaused: !isPaused } : item
        )
      );
    };

        putDataContrat(
            {isPaused: !isPaused},
            "Une erreur est survenue lors du changement de statut",
            `${ROUTE}/${id}`,
            isUpdated
        )
    };

  handleEdit = (row) => {
    setRow(row);
    setShowModal(true);
  };

  handleChangeActif = (id, isDeleted) => {
    setId(id);
    setIsDeleted(!isDeleted);
    setOpen(true);
  };

  /**
   * Deactivates a contract.
   */
  function desactivationContrat() {
    const ROUTE = getRoute(typeContrat);
    setReload(false);

    if (ROUTE === null) return;

    const isUpdated = (results) => {
      setOpen(false);
      setReload(!reload);
    };

    putDataContrat(
      { isDeleted: isDeleted },
      "Une erreur est survenue lors de la désactivation du contrat",
      `${ROUTE}/${id}`,
      isUpdated
    );
  }

  const options = {
    columns,
    data,
    enableExpandAll: false,
    initialState: {
      columnOrder: [
        "mrt-row-expand",
        "fileName",
        "contratTypeLocation.nom",
        "devisDolibarr",
        "refContrat",
        "societe.name",
        "societe.referent",
        "prixLocation",
        "contratTypeRegie.nom",
        "facture",
        "creditContrat",
        "sauvegarde",
        "prixFacturation.quantite",
        "price",
        "costPrice",
        "prixFacturation.vente",
        "contratTypeRegie.price",
        "contratTypeOffre.nom",
        "lieu",
        "dateDebut",
        "dateFin",
        "dateAnniversaire",
        "dateEnvoi",
        "dateRetour",
        "isReconduite",
        "isPaused",
        "action",
      ],
      expanded: false,
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
      sx: {
        transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
        transition: "transform 0.2s",
      },
    }),
  };

  if(canAccess){
    options.renderDetailPanel  = ({ row }) => {
          return (
              <InfoContrat contrat={row.original} typeContrat={typeContrat} isTechnicien={canAccess} />
          );
    }
  }

  useEffect(() => {
    getData(typeContrat);
  }, [typeContrat, row, reload]);

  const table = useMaterialReactTable(options);

  return (
    <>
      <h3>
        <FaFileContract /> {title} {status}
      </h3>
      {infos(typeContrat)}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isDeleted
            ? "Êtes-vous sûr de vouloir désactiver le contrat ?"
            : "Êtes-vous sûr de vouloir réactiver le contrat ?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Non</Button>
          <Button
            onClick={() => desactivationContrat()}
            autoFocus
            style={{ color: "red" }}
          >
            Oui
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable table={table} />
    </>
  );
};

export default DataGridDetailContrats;