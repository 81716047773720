import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import { _ACTIONS } from "../../../utils/constants";
import { createToast } from "../../../utils/toast";
import { _ROUTES } from "../../../utils/_routes_constants";

const GlpiModal = ({ row, setRefreshData, glpis }) => {
  const [selectedGlpi, setSelectedGlpi] = useState();
  const rq = new RequestHelper();

  let notAssociedGlpis = glpis?.filter((item) => item.isAssocied === false);

  const associateGlpiId = (id) => {
    let currentTmpGlpis = glpis.find((glpi) => glpi.glpi === selectedGlpi);
    const data = {
      glpi: selectedGlpi,
      nameGlpi: currentTmpGlpis.nameGlpi,
    };
    rq.doRequest(_ACTIONS.PUT, `${_ROUTES.SOCIETE}/${id}`, data)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Erreur lors de l'association de l'entité Société");
        }
        return rq.doRequest(
          _ACTIONS.PUT,
          `${_ROUTES.TMP_GLPI}/${currentTmpGlpis.id}`,
          {
            isAssocied: true,
          }
        );
      })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Erreur lors de la mise à jour de l'entité GLPI");
        }
        setRefreshData((prev) => prev + 1);
        createToast("success", "Société ajoutée au drive");
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      gap={2}
    >
      <FormControl fullWidth>
        <InputLabel id="glpi" size="small">
          Associer un client Glpi
        </InputLabel>
        <Select
          name="glpi"
          value={selectedGlpi}
          onChange={(e) => setSelectedGlpi(e.target.value)}
          size="small"
        >
          {notAssociedGlpis?.map((glpi) => (
            <MenuItem key={glpi.id} value={glpi.glpi}>
              {glpi.nameGlpi}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={() => associateGlpiId(row.original.id)}
        sx={{ width: "fit-content" }}
        size="small"
      >
        Enregistrer
      </Button>
    </Box>
  );
};

export default GlpiModal;
