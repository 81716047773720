export const _STATE_FILTER = {
  UNASSOCIATED: {
    name: "Non associé",
    color: "grey",
    icon: "link_off",
  },
  UNAFFECTED: {
    name: "Non affecté",
    color: "error",
    icon: null,
  },
  AFFECTED: {
    name: "Affecté",
    color: "warning",
    icon: null,
  },
  IN_PROGRESS: {
    name: "En cours",
    color: "success",
    icon: null,
  },
  WAITING: {
    name: "En attente",
    color: "turquoiseBox",
    icon: null,
  },
  CLOSED: {
    name: "Clôturé",
    color: "grey",
    icon: null,
  },
  ARCHIVED: {
    name: "Archivé",
    color: "lightBlue",
    icon: null,
  },
  ALL: {
    name: "Tout état",
    color: "lightDanger",
    icon: null,
  },
};

export const _STATE_FILTER_OPT = [
  _STATE_FILTER.UNASSOCIATED.name,
  _STATE_FILTER.UNAFFECTED.name,
  _STATE_FILTER.AFFECTED.name,
  _STATE_FILTER.IN_PROGRESS.name,
  _STATE_FILTER.WAITING.name,
  _STATE_FILTER.CLOSED.name,
  _STATE_FILTER.ARCHIVED.name,
];

export const _TICKET_OWNER = {
  ALL: "Tous les tickets",
  PERSO: "Mes tickets",
};

export const _TICKET_PRIORITY = {
  LOW: "Basse",
  NORMAL: "Normal",
  URGENT: "Urgent",
};

export const _TICKET_PRIORITY_OPT = [
  _TICKET_PRIORITY.LOW,
  _TICKET_PRIORITY.NORMAL,
  _TICKET_PRIORITY.URGENT,
];

export const _TICKET_STATUS_ID = {
  NON_ASSOCIE_STATUS: 1,
  AFFECTE_STATUS: 2,
  EN_COURS_STATUS: 3,
  CLOSED_STATUS: 4,
  ARCHIVED_STATUS: 5,
  ANNULED_STATUS: 6,
  EN_ATTENTE_STATUS: 7,
};

export const _TICKET_TYPE_OPT = ["Tâche", "Demande", "Incident", "Achat"];
