import { useEffect, useState } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import { _ACTIONS, _HTTP } from "../../../utils/constants";
import { _ROUTES } from "../../../utils/_routes_constants";
import { createToast } from "../../../utils/toast";
import { Box, Card, CardContent, Chip } from "@mui/material";
import { FaRegFilePdf } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";

export const TicketCreateContratsBox = ({ ticket, setTicket }) => {
  const [listContrats, setListContrats] = useState([]);
  const rq = new RequestHelper();

  useEffect(() => {
    if (ticket.societe) {
      getAllContracts();
    }
  }, [ticket]);

  /**
   * Retrieves all contracts.
   */
  function getAllContracts() {
    rq.doRequest(_ACTIONS.GET, _ROUTES.ALL_CONTRACTS, {
      societeId: ticket?.societe?.id,
    })
      .then((res) => {
        if ([_HTTP.ok, _HTTP.created].includes(res.status)) {
          setListContrats(res.data);
        } else {
          createToast(
            "error",
            "Une erreur est survenue lors de la récupération des contrats."
          );
        }
      })
      .catch((err) => {
        createToast(
          "error",
          "Une erreur est survenue lors de la récupération des contrats." + err
        );
      });
  }

  const createLabel = (contrat) => {
    const ref = contrat.contractRef
      ? "( Réf: " + contrat.contractRef + " )"
      : null;

    let infoColor = null;
    if (contrat.contractType === "cmt") {
      infoColor =
        contrat.creditTime > 0 ? (
          <Chip label={contrat.creditTime} variant="outlined" color="success" />
        ) : (
          <Chip label={contrat.creditTime} variant="outlined" color="error" />
        );
    }

    let file = null;
    if (contrat.contractFile) {
      file = (
        <FaRegFilePdf
          size="20"
          style={{
            cursor: "pointer",
            float: "right",
            marginTop: "13px",
            marginRight: "30px",
            color: "green",
          }}
        />
      );
    }

    let commentaire = null;
    if (contrat.commentaire)
      commentaire = (
        <FcInfo
          title={contrat.commentaire}
          size="20"
          style={{
            cursor: "pointer",
            float: "right",
            marginTop: "13px",
            marginRight: "0px",
            color: "blue",
          }}
        />
      );

    return (
      <>
        <Box 
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
        {contrat.contractName} {ref} {infoColor} {commentaire} {file}
        </Box>
      </>
    );
  };

  return (
    <>
      <Box sx={{ marginTop: "10px", padding: "5px", width: "35%" }}>
        <Card variant="outlined">
          <CardContent>
            {!ticket.societe && (
             <>
              <label>Selectionner un client pour afficher ses contrats</label>
             </> 
            )}
            {listContrats.length === 0 && (
              <Box>
                <label>Aucun contrat trouvé pour ce client</label>
              </Box>
            )}
            {listContrats.map((contrat) => (
              <>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                  <input
                    type="checkbox"
                    checked={ticket?.contrat === contrat.id}
                    onChange={(e) =>
                        setTicket((prev) => ({
                        ...prev,
                        contrat: contrat.id,
                        contratType: contrat.contractType,
                      }))
                    }
                  />
                  <label> {createLabel(contrat)}</label>
                </Box>
              </>
            ))}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
