import { useState, useEffect, useContext } from "react";
import { DashboardGeneralContext } from "../../contexts/DashboardGeneralContext";
import { _ACTIONS, _MESSAGES } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { _ROUTES } from "../../utils/_routes_constants";

export function useCustomRequestDashboard() {
  const { alerts, setAlerts } = useContext(DashboardGeneralContext);
  const [loading, setLoading] = useState(true);
  const requestHelper = new RequestHelper();

  async function allAlerts() {
    try {
      const alerts = await requestHelper.doRequest(
        _ACTIONS.GET,
        _ROUTES.DASHBOARD_ALERTS
      );
      const data = alerts.data;
      setAlerts(data);
    } catch (error) {
      createToast("error", _MESSAGES.error2);
    }
  }

  useEffect(() => {
    allAlerts();
    setLoading(false);
  }, [setAlerts]);

  return { alerts, loading };
}
