import { Grid } from "@mui/material";
import Consommation from "./Consommation";
import InfoGenerales from "./InfoGenerales";
import { useState } from "react";
import Ordinateurs from "./Ordinateurs";

const InfoAntivirus = ({client, setIsLoaded, isLoaded}) => {
    const [infosLicence, setInfosLicence] = useState({});

    return (
        <> 
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <InfoGenerales client={client} setInfosLicence={setInfosLicence} infosLicence={infosLicence} />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Consommation client={client} infosLicence={infosLicence} />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Ordinateurs client={client} setIsLoaded={setIsLoaded} isLoaded={isLoaded} />
                </Grid>
            </Grid>
        </>
    );
};

export default InfoAntivirus;