import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import {
  _ACTIONS,
  _COLORS,
  _DATE_FORMAT,
  _HTTP,
} from "../../../utils/constants";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import { _ROUTES } from "../../../utils/_routes_constants";
import FileUploader from "../../utils/FileUploader";
import DownloadIcon from "@mui/icons-material/Download";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import AddIcon from "@mui/icons-material/Add";
import { isDisabledTicket } from "../../../utils/function";
import BasicModal from "../../helpers/BasicModal";
import { AddSellForm } from "./Modals/AddSellForm";
import withReactContent from "sweetalert2-react-content";
import AddActionForm from "./Modals/AddActionForm";
import { editDescription, sendEmail } from "../../../utils/actionUtils";
import { RequestHelper } from "../../../utils/requestHelper";
import { createToast } from "../../../utils/toast";

export const TicketEditAction = ({
  ticket,
  setTicket,
  action,
  index,
  actionsExpanded,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [openAddVente, setOpenAddVente] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const baseUrl = process.env.REACT_APP_SYMFONY_URL + "/_files/_tickets/";
  const MySwal = withReactContent(Swal);
  const rq = new RequestHelper();

  useEffect(() => {
    setExpanded(actionsExpanded);
    setDisabled(isDisabledTicket(ticket));
  }, [actionsExpanded]);

  const deleteAction = () => {
    MySwal.fire({
      title: "Êtes-vous sûr de vouloir supprimer cette action ?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: _COLORS.error,
      cancelButtonColor: _COLORS.warning,
      confirmButtonText: "Oui",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        rq.doRequest(_ACTIONS.DELETE, action["@id"]).then((res) => {
          if (res.status === _HTTP.deleted) {
            createToast("success", "Action supprimée avec succès");
            // Updating ticket state
            setTicket((prev) => {
              return {
                ...prev,
                actions: prev.actions.filter((a) => a["@id"] !== action["@id"]),
              };
            });
          }
        });
      }
    });
  };

  return (
    <Box
      sx={{
        marginTop: "10px",
      }}
    >
      <Accordion key={index} expanded={expanded} sx={{ cursor: "default" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon onClick={() => setExpanded((prev) => !prev)} />
          }
          aria-controls="panel-content"
          id={"panel-action-header" + index}
          sx={{
            backgroundColor: _COLORS.warning,
            fontSize: "0.8em",
            color: "white",
            height: "35px !important",
            minHeight: "35px !important",
            cursor: "default",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2em",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {action?.actionType?.name}
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Chip
              sx={{ backgroundColor: _COLORS.midGrey }}
              variant="outlined"
              label={
                action?.createdAt
                  ? moment(action.createdAt).format(_DATE_FORMAT.next_display)
                  : ""
              }
            ></Chip>

            <>
              {action.isPrivate == false && !_.isNull(action.user) && (
                <SendIcon
                  sx={{ color: _COLORS.defaultBlue }}
                  onClick={() => {
                    sendEmail(action);
                  }}
                />
              )}
              {!_.isNull(action.user) && (
                <EditIcon
                  sx={{ color: _COLORS.success }}
                  onClick={() => {
                    setOpenAction(true);
                  }}
                />
              )}
              <DeleteIcon
                sx={{ color: _COLORS.error }}
                onClick={() => {
                  deleteAction();
                }}
              />
            </>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              {/* @TODO get Avatar from user */}
              <Avatar alt="Remy Sharp" src="" />
            </Box>
            <Box
              sx={{
                marginLeft: "10px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography fontWeight={"bold"} variant="subtitle1">
                  {action?.actionType?.name}
                </Typography>
                <Typography sx={{ marginLeft: "2px" }} variant="subtitle1">
                  {action.isPrivate ? "(Privée)" : ""}
                </Typography>
                <Typography
                  sx={{ marginLeft: "2px", color: _COLORS.success }}
                  variant="subtitle1"
                >
                  {action.isTransport ? "(Déplacement facturable)" : ""}
                </Typography>
                <Typography sx={{ marginLeft: "2px" }} variant="subtitle1">
                  {action.user ? "" : "Action automatique"}
                </Typography>
              </Box>
              <Typography sx={{ color: _COLORS.grey }} variant="subtitle2">
                {action?.user?.firstName} {action?.user?.lastName}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              width: "100%",
              cursor: disabled ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              editDescription(action, setTicket);
            }}
          >
            <Box
              dangerouslySetInnerHTML={{ __html: action?.description }}
            ></Box>
          </Box>
          {/* Display Files */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
              width: "100%",
            }}
          >
            {action?.files.map((file, index) => (
              <Box key={index}>
                <Button
                  size="small"
                  variant="text"
                  onClick={(e) => {
                    window.open(
                      baseUrl +
                        ticket?.refTicket +
                        "/" +
                        action.id +
                        "/" +
                        file.fileName,
                      "_blank",
                      "noopener"
                    );
                  }}
                >
                  <DownloadIcon sx={{ marginRight: "5px" }}></DownloadIcon>
                  {file.fileName}
                </Button>
                {/* TODO delete file */}
                <IconButton sx={{ color: _COLORS.error }}>
                  <DeleteIcon></DeleteIcon>
                </IconButton>
              </Box>
            ))}
          </Box>
          {/* File uploader */}
          {action && (
            <FileUploader
              hiddenFields={{
                action: action?.id,
              }}
              url={_ROUTES.ADD_ACTION_FILE}
            ></FileUploader>
          )}
          {/* Footer Bar */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: "1px solid #e0e0e0",
            }}
          >
            {/* start */}
            <Box
              sx={{
                fontSize: "0.7em",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              Durée Fact (mn) : {action?.durationEstimated}mn | Durée Réelle
              (mn) :{action?.durationReal}mn
            </Box>
            {/* end */}
            <Box sx={{}}>
              <Button
                size="small"
                onClick={() => {
                  // @TODO Add vente
                  setOpenAddVente(true);
                }}
              >
                <VolunteerActivismIcon /> Ajouter une vente
              </Button>
              <Button size="small" onClick={() => {}}>
                <AddIcon /> Actions ({ticket?.actions?.length})
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* MODALS */}
      {/* Add Vente */}
      <BasicModal
        showModal={openAddVente}
        setShowModal={setOpenAddVente}
        title="Ajouter une vente"
        content={
          <AddSellForm
            ticket={ticket}
            setTicket={setTicket}
            action={action}
            setOpenAddVente={setOpenAddVente}
          />
        }
      ></BasicModal>
      {/* Edit Action */}
      <BasicModal
        showModal={openAction}
        setShowModal={setOpenAction}
        title="Modifier une action"
        content={
          <AddActionForm
            ticket={ticket}
            setTicket={setTicket}
            action={action}
            setOpenActionModal={setOpenAction}
          />
        }
      ></BasicModal>
    </Box>
  );
};

export default TicketEditAction;
