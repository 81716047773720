import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { TYPE_CONTRATS } from './ColumnsContrat';
import { useEffect, useState } from 'react';
import { _ROUTES } from "../../utils/_routes_constants";
import { MdOutlineModeComment } from "react-icons/md";
import { LuFolderSync } from "react-icons/lu";
import { LuFolderTree } from "react-icons/lu";
import moment from "moment";
import { _DATE_FORMAT } from "../../utils/constants";
import { FcBusinessContact } from "react-icons/fc";
import CircularProgress from '@mui/material/CircularProgress';
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import _ from "lodash";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import { RiArrowGoForwardLine } from "react-icons/ri";
import { RiArrowGoBackLine } from "react-icons/ri";
import { FcTodoList } from "react-icons/fc";
import { FaCheck } from "react-icons/fa6";
import { MdWebAsset } from "react-icons/md";
import { getDataContrat } from '../../repository/contrats/Contrat';
import { getDataDolibar } from '../../repository/Dolibar';


const InfoContrat = ({ contrat, typeContrat, isTechnicien }) => {
    const [oldContrats, setOldContrats] = useState([]);
    const [dolProduct, setDolProduct] = useState();
    const [dolDevis, setDolDevis] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [devisIsLoaded, setDevisIsLoaded] = useState(false);

    function getInfosContrat(){
        if(typeContrat === TYPE_CONTRATS.CONTRAT_MAINTENANCE){
            setOldContrats([]);
            setIsLoaded(true);
            getDataContrat(
                {refContrat: contrat.refContrat}, 
                setOldContrats, 
                "Une erreur est survenue lors de la récupération des anciens contrats", 
                _ROUTES.CONTRAT_MAINTENANCE
            );
            setIsLoaded(false);
        }
        if((typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES || typeContrat === TYPE_CONTRATS.CONTRAT_REGIE )&&  !_.isNull(contrat.dolProduct)){
            setDolProduct(null);
            setIsLoaded(true); 
            getDataDolibar(
                {dolProduct: contrat.dolProduct, choice: "label"}, 
                setDolProduct, 
                "Une erreur est survenue lors de la récupération des informations dolibar", 
                _ROUTES.DOL_PRODUCT
            );
            setIsLoaded(false);
        }
        if(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION  &&  !_.isNull(contrat.devisDolibarr)){
            setDolDevis(null);
            setDevisIsLoaded(true); 
            getDataDolibar(
                {id: contrat.devisDolibarr, choice: "ref"}, 
                setDolDevis, 
                "Une erreur est survenue lors de la récupération du devis", 
                _ROUTES.DOL_DEVIS
            );
            setDevisIsLoaded(false);
        }
    };

    useEffect(() => {
        getInfosContrat();
    }, [contrat]);

    const listeContrats = oldContrats.map((oldContrat) => {
        if(contrat.id !== oldContrat.id){
            let sold = (oldContrat.creditContrat - oldContrat.creditConsomme);
            const color = sold < 0 ? "red" : "green";
            return (
                <Box>
                    <Box key={oldContrat.id}>
                        <Box sx={{padding:"5px"}}>
                            <LuFolderSync /> 
                                <span> Du {moment(oldContrat.dateDebut).format( _DATE_FORMAT.date_display)} au {moment(oldContrat.dateFin).format( _DATE_FORMAT.date_display)} </span>
                                <span>- Crédit : {oldContrat.creditContrat} </span>
                                <span>- Consommé : {oldContrat.creditConsomme} </span>
                                <span>- Décompte temps : {oldContrat.decompteTemps} </span>
                                <span>- Solde :</span> <span style={{color:color}}> {sold} </span>
                        </Box>
                        <Divider component="li" />
                    </Box>
                </Box>
            );
        }
    });

    const apporteur = () => {
        return (
            <Box>
                <Divider variant="middle" component="li" />
                <Box sx={{padding:"5px"}}>
                <FcBusinessContact size="1.2em" /> Nom de l'apporteur : {contrat.apporteurName !== null ? contrat.apporteurName : "N/A" } - Montant : {contrat.apporteurMontant} €                   
                </Box>
                <Divider component="li" />
            </Box>
            );
    };

    const offre = () => {
        return (
            <Box>
                <Divider variant="middle" component="li" />
                <Box sx={{padding:"5px"}}>
                <MdOutlineLocalOffer size="1.2em" /> Offre : {contrat.contratTypeOffre.nom}                    
                </Box>
                <Divider component="li" />
            </Box>
            );
    };

    const lieu = () => {
        return (
            <Box>
                <Divider variant="middle" component="li" />
                <Box sx={{padding:"5px"}}>
                <FaMapLocationDot size="1.2em" /> Lieu : {contrat.lieu}                    
                </Box>
                <Divider component="li" />
            </Box>
            );
    };

    const dateEnvoi = () => {
        return (
            <Box>
                <Divider variant="middle" component="li" />
                <Box sx={{padding:"5px"}}>
                <RiArrowGoForwardLine size="1.2em" /> Date envoi : {moment(contrat.dateEnvoi).format( _DATE_FORMAT.date_display)}                    
                </Box>
                <Divider component="li" />
            </Box>
            );
    };

    const dateRetour = () => {
        return (
            <Box>
                <Divider variant="middle" component="li" />
                <Box sx={{padding:"5px"}}>
                <RiArrowGoBackLine size="1.2em" /> Date retour signé : {moment(contrat.dateEnvoi).format( _DATE_FORMAT.date_display)}                    
                </Box>
                <Divider component="li" />
            </Box>
            );
    };

    const devis = () => {
        return (
            <Box>
                {devisIsLoaded && (<Box><CircularProgress size="0.8em" /> Chargement...</Box>)}
                {!devisIsLoaded && (
                    <Box>
                        <Divider variant="middle" component="li" />
                        <Box sx={{padding:"5px"}}>
                            <MdOutlineMiscellaneousServices /> 
                                <span> Devis : {dolDevis} </span>
                        </Box>
                        <Divider component="li" />
                    </Box>)}
            </Box>
        );
    };

    const materiels = () => {
        return (
            <Box>
                <Divider variant="middle" component="li" />
                <Box sx={{padding:"5px"}}>
                <FcTodoList size="1.2em" /> Matériels :
                <Divider variant="middle" component="li" />
                    {contrat.locationProduitDolibars.map((produit) => {
                        return (
                                <Box sx={{marginLeft:"10px"}}><FaCheck color="blue" /> {produit.name + " :  quantité : " + produit.quantite + " - prix : " + produit.prix + "€"} </Box>
                            )
                        })
                    }        
                </Box>
                <Divider component="li" />
            </Box>
            );
    };

    const actif = () => {
        return (
            <Box>
                <Divider variant="middle" component="li" />
                <Box sx={{padding:"5px"}}>
                <MdWebAsset size="1.2em" /> Actif : {!_.isUndefined(contrat.societeProduitService.produitServiceCategorie) ? contrat.societeProduitService.produitServiceCategorie.name + " - "
                 + contrat.societeProduitService.societeProduitServiceHebergement.domaine : ''}                    
                </Box>
                <Divider component="li" />
            </Box>
            );
    }

    const modele = () => {
        return (
        <>
            <Divider variant="middle" component="li" />
            {contrat.beemoProduitDolibars.map((produit) => {
                return (
                        <Box sx={{padding:"5px", marginTop: 2}}>
                            <MdWebAsset style={{verticalAlign: 'text-bottom'}} size="1.2em" /> Modèle : {produit.name} 
                            <Divider variant="middle" component="li" />
                            <Box sx={{padding:"5px", marginLeft: 1}}>
                            Quantité : {produit.quantite} - Prix : {produit.prix} €
                            {produit.tailleExtern && 
                                <Box sx={{marginLeft: 1}}>
                                    Externalisation : Taille : {produit.tailleExtern} - Quantité : {produit.quantiteExtern} - Prix : {produit.prixExtern} €
                                </Box>
                            }
                            </Box>
                        </Box>
                    );
            })}
        </>)
    };

    return (
        <> 
            <List>
                {contrat.commentaire !== null && contrat.commentaire !== "" && (
                <Box>
                    <ListItem>
                        <MdOutlineModeComment size="1.5em" />  <ListItemText sx={{marginLeft:"5px"}} primary="Comentaire : " />
                    </ListItem>
                    <Divider variant="middle" component="li" />
                    <Box sx={{margin:"20px"}} >
                        <div  dangerouslySetInnerHTML={{__html: contrat.commentaire}} />
                    </Box>
                </Box>
                )}

                {isLoaded && (<Box><CircularProgress size="0.8em" /> Chargement...</Box>)}

                {(typeContrat === TYPE_CONTRATS.CONTRAT_MAINTENANCE && oldContrats.length > 0 && !isLoaded) && (
                <Box>
                    {isLoaded && (<Box><CircularProgress size="0.8em" /> Chargement...</Box>)}
                    <Box>
                        <ListItem>
                            <LuFolderTree size="1.5em" /> <ListItemText sx={{marginLeft:"5px"}} primary="Anciens contrats :" />
                        </ListItem>
                        <Divider variant="middle" component="li" />
                        {listeContrats}
                    </Box>
                </Box>
                )}

                {((typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES || typeContrat === TYPE_CONTRATS.CONTRAT_REGIE) && contrat.dolProduct && !isLoaded) && (
                    <Box>
                        <Divider variant="middle" component="li" />
                        <Box sx={{padding:"5px"}}>
                            <MdOutlineMiscellaneousServices /> 
                                <span> Produit | Service associer : {dolProduct} </span>
                        </Box>
                        <Divider component="li" />
                    </Box>
                )}

                {((typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES || typeContrat === TYPE_CONTRATS.CONTRAT_HEBERGEMENT ) && (contrat.apporteurName !== null || contrat.apporteurMontant > 0)) && isTechnicien && (
                    apporteur()
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION && contrat.devisDolibarr !== null) && (
                    devis()
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION && contrat.contratTypeOffre !== null) && (
                    offre()
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION && contrat.lieu !== null) && (
                    lieu()
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION && contrat.dateEnvoi !== null) && (
                    dateEnvoi()
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION && contrat.dateRetour !== null) && (
                    dateRetour()
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_HEBERGEMENT && contrat.societeProduitService !== null) && (
                    actif()
                )}
                
                {(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION && contrat.locationProduitDolibars && contrat.locationProduitDolibars.length > 0) && (
                    materiels()
                )}

                {(typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO && contrat.beemoProduitDolibars.length > 0) && (
                    modele()
                )}
            </List>
        </>
    );
}

export default InfoContrat;