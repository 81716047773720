import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useContext, useState } from "react";
import DetailPanelVadeSecure from "./DetailPanelVadeSecure";
import { ActifsConfigurationsContext } from "../../contexts/ActifsConfigurationsContext";
import { ColonnesActifConfiguration } from "./columns/ColonnesActifConfiguration";
import ColumnManager from "../helpers/ColumnManager";
import { columnsVisibility } from "../../utils/actif-config/actif_configuration_constants";
import { getMuiTableConfig } from "../../utils/muiTableBuilder";
import _ from "lodash";
import MuiTableContainer from "../utils/MuiTableContainer";
import BoxInfoTableActif from "./BoxInfoTableActif";

const ResponseListCatalogue = () => {
  /* state */
  const [columnVisibility, setColumnVisibility] = useState({
    ...columnsVisibility,
  });
  /*context */
  const {
    selectedProduitService,
    isLoading,
    responseActif,
    pagination,
    setPagination,
    rowCount,
    sorting,
    setSorting,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    isError,
    isLoaded,
    isRefetching,
  } = useContext(ActifsConfigurationsContext);

  const colonnes = ColonnesActifConfiguration({
    selectedProduitService,
  });

  const c = getMuiTableConfig(
    // Params
    {
      enableColumnOrdering: false,
      enableColumnFilters: false,
      enableExpanding: true,
      enableHiding: false,
    },
    // States & setStates (from context) Have to be the same as this
    {
      rowCount,
      isLoaded,
      isError,
      pagination,
      setPagination,
      columnFilters,
      setColumnFilters,
      globalFilter,
      setGlobalFilter,
      sorting,
      setSorting,
      isLoading,
    }
  );

  const table2 = useMaterialReactTable({
    ...c,
    data: responseActif,
    columns: colonnes,
    initialState: {
      columnVisibility,
      columnOrder: Object.keys(columnVisibility), // order of columns
    },
    state: {
      columnVisibility,
      isLoading,
      showProgressBars: isRefetching,
      pagination: pagination,
      sorting: sorting,
    },
    positionGlobalFilter: "right",
    muiPaginationProps: {
      rowsPerPageOptions: [15, 20, 50],
    },
    renderTopToolbarCustomActions: () => {
      return (
        <ColumnManager
          columns={colonnes}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
        />
      );
    },
    renderDetailPanel: ({ row }) => {
      if (row.original.produitServiceCategorie?.name === "VADE SECURE") {
        return <DetailPanelVadeSecure row={row} />;
      }
    },
  });

  return (
    <>
      <MuiTableContainer
        mt={4}
        sx={{
          border: "2px solid #d2d6de",
        }}
      >
        <BoxInfoTableActif selectedProduitService={selectedProduitService} />
        {/* Display table by id selected from FormFilerActifConfig.jsx */}
        {!_.isEmpty(table2) && selectedProduitService.id && (
          <MaterialReactTable table={table2} />
        )}
      </MuiTableContainer>
    </>
  );
};

export default ResponseListCatalogue;
