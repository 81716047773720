import { Box, Card, Typography, Divider } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { MdOutlineInfo } from "react-icons/md";
import { _DATE_FORMAT } from "../../../utils/constants";
import { SiMicrosoftexcel } from "react-icons/si";
import { ImFilePdf } from "react-icons/im";
import { getDataOvh } from "../../../repository/telephonies/Ovh";
import { _ROUTES } from "../../../utils/_routes_constants";

const Consomation = ({group}) => {
    const [comsumption, setComsumption] = useState([]);

    useEffect(() => {
        getDataOvh(
            {billingAccount: group?.billingAccount}, 
            setComsumption, 
            "Une erreur est survenue lors de la récupération des données de consommation",
            _ROUTES.GROUP_OVH_COMSUMPTION
        );
    }, [group]);

    function downloadFile(type, date, id){
        //todo download file
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'date',
                header: 'Date',
            },
            {
                accessorKey: 'montant',
                header: 'Montant',
            },
            {
                accessorKey: 'montant_h',
                header: 'Montant Hors Forfait',
            },
            {
                accessorKey: 'fichier',
                header: 'Fichiers',
                Cell: ({row}) => (
                    <>
                        <SiMicrosoftexcel 
                            color="green"
                            size="20" 
                            style={{marginLeft:"10px", cursor: "pointer"}}  
                            onClick={() => {downloadFile('excel', row.original.date, row.original.id)}} 
                        /> 
                        <ImFilePdf 
                            color="red"
                            size="20" 
                            style={{marginLeft:"20px", cursor: "pointer"}}
                            onClick={() => {downloadFile('pdf', row.original.date, row.original.id)}} 
                        />
                    </>
                ),
            },
        ],
    );

    const options = {
        columns,
        data: comsumption['rows'] ?? [],
    };

    const table = useMaterialReactTable(options);

    return(
        <>
            <Card sx={{borderTop: 3, borderTopColor: "success.main"}}>
                <Box>
                    <Typography sx={{ padding:"5px", fontSize: "1rem"}}>
                        <MdOutlineInfo size="20"style={{verticalAlign:"text-top", marginRight:"10px"}}/> 
                        <strong>Relevé de consommation</strong>
                    </Typography>
                </Box>
                <Divider sx={{background: "lightgreen"}} />
                <MaterialReactTable table={table} />
            </Card>
        </>
    )
};

export default Consomation;