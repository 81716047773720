import { Box, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import { _COLORS } from "../../../utils/constants";

/**
 * Renders the title and keywords of an article.
 *
 * @component
 * @param {Object} props.article - The article object containing the title and keywords.
 */
export const ArticleTitle = memo(({ article }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h6">{article?.title}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          gap: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
        }}
      >
        {article?.keywords.map((keyword, i) => (
          <Box
            key={i}
            sx={{
              p: 0.5,
              borderRadius: "5px",
              backgroundColor: _COLORS.turquoiseBox,
              color: "#fff",
            }}
          >
            <Typography fontSize={"0.7rem"} fontWeight={"bold"}>
              {keyword}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Box>
  );
});
