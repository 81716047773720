import React from "react";
import { MdOutlineToggleOff } from "react-icons/md";
import { MdOutlineToggleOn } from "react-icons/md";

/**
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.value - The value to determine the state of the toggle.
 * @param {boolean} props.disabled - The disabled state of the toggle.
 * @returns {JSX.Element} - The rendered toggle component.
 */
export const ToggleTransformer = ({ value, disabled = null }) => {
  // If the toggle is disabled, set the color to gray regardless of the value. Otherwise, set the color based on the value.
  const color = disabled ? "gray" : value ? "green" : "red";

  return value ? (
    <MdOutlineToggleOn color={color} />
  ) : (
    <MdOutlineToggleOff color={color} />
  );
};
