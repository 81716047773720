import { ImAttachment } from "react-icons/im";
import { IoIosInformationCircle } from "react-icons/io";
import { Chip, Box, IconButton } from "@mui/material";
import moment from "moment";
import { _DATE_FORMAT } from "../../utils/constants";
import { ToggleOff, ToggleOnOutlined } from "@mui/icons-material";
import { MdToggleOff } from "react-icons/md";
import { 
    handleChangeReconduite, 
    handleChangePaused, 
    handleEdit, handleChangeActif, 
    totalPrice, 
    totalCreditContrat,
    totalPriceAchat,
    totalPriceTele,
    totalPriceRegie
} from "./DataGridDetailContrats";
import { LiaEdit } from "react-icons/lia";
import { roundValue } from "../../utils/function";
import _ from "lodash";
import { calculPrixLocation } from "./FunctionsContrat";

export const ACTIF = 'actif';
export const DESACTIVE = 'désactivé';

export const TYPE_CONTRATS = {
  CONTRAT_MAINTENANCE: 1,
  CONTRAT_HEBERGEMENT: 2,
  CONTRAT_AUTRES: 3,
  CONTRAT_TELESAUVEGARDE: 4,
  CONTRAT_REGIE: 5,
  CONTRAT_LOCATION: 6,
  CONTRAT_BEEMO: 7,
}

const CONTRAT_FILE = {
    accessorKey: 'fileName',
    header: <ImAttachment />,
    size: 50,
    Cell: ({ row }) => (
        row.original.fileName 
        ? <ImAttachment title={row.original.fileName} size="1.5em" color="blue" />
        : ''
      ),
}

const CONTRAT_REF = {
    accessorKey: 'refContrat',
    header: 'Réf.',
    size: 150,
    Cell: ({ row }) => (
        <Chip label={row.original.refContrat} />
      ),
}

const CONTRAT_TIER = {
    accessorKey: 'societe.name',
    header: 'Tiers',
    size: 120,
}

const CONTRAT_REFERENT = {
    accessorKey: 'societe.referent',
    header: 'Référent Toolin',
    size: 100,
    Cell: ({ row }) => (
        (!_.isUndefined(row.original.societe.referent) && row.original.societe.referent && row.original.societe.referent!== null)
        ? row.original.societe.referent.firstName + ' ' + row.original.societe.referent.lastName
        : ''
      ),
}

const CONTRAT_FACTURE = {
    accessorKey: 'facture',
    header: 'Facture',
    size: 120,
    Cell: ({ row }) => (
        <Chip label={row.original.facturation} />
      ),
}

const CONTRAT_CREDIT = {
    accessorKey: 'creditContrat',
    header: 'CA/min',
    size: 150,
    Cell: ({ row }) => (
        <Box>
            <Box> {row.original.creditContrat + ' min'}</Box>  
            <Box>{'(' + roundValue((row.original.creditContrat/60), 2) + ' hrs)'}</Box>
        </Box>
      ),
      Footer: () => (
        <Box>
            <Box>
               Total: {totalCreditContrat + ' min'}
            </Box>
            <Box>
               {"(" + roundValue((totalCreditContrat/60), 2) + ' hrs)'}
            </Box>
        </Box>
      ),
}

const CONTRAT_PRIX = {
    accessorKey: 'price',
    header: '* Prix HT',
    size: 50,
    Cell: ({ row }) => (
        row.original.price 
        ? row.original.price + ' €'
        : ''
      ),
    Footer: () => (
        <Box>
          Total: {roundValue(totalPrice, 2) + ' €'}
        </Box>
      ),
}

const CONTRAT_PRIX_ACHAT = {
    accessorKey: 'costPrice',
    header: '* Prix Achat HT',
    size: 50,
    Cell: ({ row }) => (
        row.original.costPrice 
        ? row.original.costPrice + ' €'
        : ''
      ),
      Footer: () => (
        <Box>
          Total: {roundValue(totalPriceAchat, 2) + ' €'}
        </Box>
      ),
}

const CONTRAT_PRIX_TELE = {
    accessorKey: 'prixFacturation.vente',
    header: '* Prix HT',
    size: 50,
    Cell: ({ row }) => (
        row.original.prixFacturation 
        ? row.original.prixFacturation.vente + ' €'
        : ''
      ),
      Footer: () => (
        <Box>
          Total: {roundValue(totalPriceTele, 2) + ' €'}
        </Box>
      ),
}

const CONTRAT_PRIX_REGIE= {
  accessorKey: 'contratTypeRegie.price',
  header: '* Prix HT',
  size: 50,
  Cell: ({ row }) => {
    let prix = 0;
    if(!_.isUndefined(row.original.contratSocieteRegieContratTypeRegies) && row.original.contratSocieteRegieContratTypeRegies.length > 0) {
      row.original.contratSocieteRegieContratTypeRegies.forEach((tarif) => {
        if(tarif.price !== null) {
          prix += tarif.price;
        } else {
         if(!_.isUndefined(tarif.contratTypeRegie.price)) prix += tarif.contratTypeRegie.price;
        }
          
      });
    }
    return prix > 0
      ? prix + ' €'
      : ''
  },
    Footer: () => (
      <Box>
        Total: {roundValue(totalPriceRegie, 2) + ' €'}
      </Box>
    ),
}

const CONTRAT_ANNIVERSAIRE = {
    accessorKey: 'dateAnniversaire',
    header: 'Anniversaire',
    size: 100,
    Cell: ({ row }) => (
        row.original.dateAnniversaire 
        ?  moment(row.original.dateAnniversaire).format( _DATE_FORMAT.date_display)
        : ''
      ),
}

const CONTRAT_TYPE_REGIE = {
  accessorKey: 'contratTypeRegie.nom',
  header: 'Types Régies',
  size: 100,
  Cell: ({ row }) => {
    let typeRegies = [];
      if( !_.isUndefined(row.original.contratSocieteRegieContratTypeRegies) && row.original.contratSocieteRegieContratTypeRegies.length > 0) {
        row.original.contratSocieteRegieContratTypeRegies.forEach((regie) => {
           if(!_.isUndefined(regie.contratTypeRegie.nom)) typeRegies.push(regie.contratTypeRegie.nom);
        })
      }
      let text ='';
      typeRegies = typeRegies.map((type) => {
        text += '[' + type + '] ';
      });

      return text;
  }
}

const CONTRAT_RECONDUCTION = {
    accessorKey: 'isReconduite',
    header: 'Rec.',
    size: 50,
    Cell: ({ row }) =>(
        <IconButton
        label="Activer"
        color="inherit"
        onClick={() => handleChangeReconduite(row.original.id, row.original.isReconduite)}
      >
        { row.original.isReconduite=== true ? (
          <ToggleOnOutlined color="success" />
        ) : (
          <ToggleOff color="warning" />
        )}
      </IconButton>
    ),
}

const CONTRAT_PAUSE = {
    accessorKey: 'isPaused',
    header: '**Pause',
    size: 50,
    Cell: ({ row }) =>(
        <IconButton
        label="Activer"
        color="inherit"
        onClick={() => handleChangePaused(row.original.id, row.original.isPaused)}
      >
        { row.original.isPaused === true ? (
          <ToggleOnOutlined color="success" />
        ) : (
          <ToggleOff color="warning" />
        )}
      </IconButton>
    ),
}

const CONTRAT_ACTION = {
    accessorKey: 'action',
    header: 'Action',
    size: 50,
    Cell: ({ row }) =>(
        <Box>
            <IconButton
                title="Mettre à jour le contrat"
                label="Mettre à jour"
                color="inherit"
                onClick={() => handleEdit(row.original)}
            >
                <LiaEdit
                  style={{
                    cursor: "pointer",
                    color: "green",
                  }}
                />
            </IconButton>
            <IconButton
                title="Désactiver le contrat"
                label="Desactiver"
                color="inherit"
                onClick={() => handleChangeActif(row.original.id, row.original.isDeleted)}
            >
                <ToggleOnOutlined />
            </IconButton>
        </Box>
    ),
}

const CONTRAT_REACTIVER = {
  accessorKey: 'reactiver',
  header: 'Réactiver',
  size: 30,
  Cell: ({ row }) =>(
      <Box>
          <IconButton
              title="Réactiver le contrat"
              label="Réactiver"
              color="inherit"
              onClick={() => handleChangeActif(row.original.id, row.original.isDeleted)}
          >
              <MdToggleOff />
          </IconButton>
      </Box>
  ),
}

const CONTRAT_DEBUT = {
    accessorKey: 'dateDebut',
    header: 'Début',
    size: 100,
    Cell: ({ row }) => (
        row.original.dateDebut 
        ?  moment(row.original.dateDebut).format( _DATE_FORMAT.date_display)
        : ''
      ),
}

const CONTRAT_FIN = {
    accessorKey: 'dateFin',
    header: 'Fin',
    size: 100,
    Cell: ({ row }) => (
        row.original.dateFin 
        ?  moment(row.original.dateFin).format( _DATE_FORMAT.date_display)
        : ''
      ),
}

const CONTRAT_SAUVEGARDE = {
    accessorKey: 'sauvegarde',
    header: 'Sauvegarde associée',
    size: 120,
    Cell: ({ row }) => (
      (!_.isUndefined(row.original.societeProduitServiceSauvegarde) && row.original.societeProduitServiceSauvegarde !== null) 
      ?  "[" + row.original.societeProduitServiceSauvegarde.produitService?.produitServiceCategorie.name + "] " + row.original.societeProduitServiceSauvegarde?.produitService?.nomnas + " - " + row.original.societeProduitServiceSauvegarde.produitService?.nomtache
      : ''
    ),
}

const CONTRAT_QUOTA = {
    accessorKey: 'prixFacturation.quantite',
    header: 'Quota en GO (contratuel)',
    size: 120,
}

const CONTRAT_TYPE_LOCATION = {
  accessorKey: 'contratTypeLocation.nom',
  header: 'Type de location',
  size: 80,
  Cell: ({ row }) => {
    return <>{row.original.contratTypeLocation.nom}</>
  }
}

const CONTRAT_PRIX_LOCATION = {
  accessorKey: 'prixLocation',
  header: 'Prix',
  size: 50,
  Cell: ({ row }) => (
      calculPrixLocation(row.original.locationProduitDolibars)
    ),
    Footer: () => (
      <Box>
        Total: {roundValue(totalPrice, 2) + ' €'}
      </Box>
    ),
}

export const CONTRAT_MAINTENANCE_COLUMNS_ACTIF = {
    CONTRAT_FILE,
    CONTRAT_REF,
    CONTRAT_TIER,
    CONTRAT_REFERENT,
    CONTRAT_FACTURE,
    CONTRAT_CREDIT,
    CONTRAT_PRIX,
    CONTRAT_DEBUT,
    CONTRAT_ANNIVERSAIRE,
    CONTRAT_RECONDUCTION,
    CONTRAT_PAUSE,
    CONTRAT_ACTION,
}

export const CONTRAT_MAINTENANCE_COLUMNS_DESACTIVE = {
    CONTRAT_REF,
    CONTRAT_TIER,
    CONTRAT_REFERENT,
    CONTRAT_FACTURE,
    CONTRAT_CREDIT,
    CONTRAT_PRIX,
    CONTRAT_FIN,
    CONTRAT_REACTIVER
}

export const CONTRAT_HEBERGEMENT_COLUMNS_ACTIF = {
    CONTRAT_FILE,
    CONTRAT_REF,
    CONTRAT_TIER,
    CONTRAT_REFERENT,
    CONTRAT_PRIX,
    CONTRAT_DEBUT,
    CONTRAT_FIN,
    CONTRAT_ANNIVERSAIRE,
    CONTRAT_RECONDUCTION,
    CONTRAT_ACTION,
}

export const CONTRAT_HEBERGEMENT_COLUMNS_DESACTIVE = {
    CONTRAT_REF,
    CONTRAT_TIER,
    CONTRAT_REFERENT,
    CONTRAT_PRIX,
    CONTRAT_DEBUT,
    CONTRAT_FIN,
    CONTRAT_ANNIVERSAIRE,
    CONTRAT_REACTIVER
}

export const CONTRAT_AUTRE_COLUMNS_ACTIF = {
    CONTRAT_FILE,
    CONTRAT_REF,
    CONTRAT_TIER,
    CONTRAT_REFERENT,
    CONTRAT_PRIX,
    CONTRAT_PRIX_ACHAT,
    CONTRAT_DEBUT,
    CONTRAT_FIN,
    CONTRAT_ANNIVERSAIRE,
    CONTRAT_RECONDUCTION,
    CONTRAT_ACTION,
}

export const CONTRAT_AUTRE_COLUMNS_DESACTIVE = {
    CONTRAT_REF,
    CONTRAT_TIER,
    CONTRAT_REFERENT,
    CONTRAT_PRIX,
    CONTRAT_PRIX_ACHAT,
    CONTRAT_DEBUT,
    CONTRAT_FIN,
    CONTRAT_ANNIVERSAIRE,
    CONTRAT_REACTIVER
}

export const CONTRAT_TELESAUVEGARDE_COLUMNS_ACTIF = {
    CONTRAT_FILE,
    CONTRAT_REF,
    CONTRAT_TIER,
    CONTRAT_REFERENT,
    CONTRAT_SAUVEGARDE,
    CONTRAT_QUOTA,
    CONTRAT_PRIX_TELE,
    CONTRAT_DEBUT,
    CONTRAT_ANNIVERSAIRE,
    CONTRAT_RECONDUCTION,
    CONTRAT_ACTION,
}

export const CONTRAT_TELESAUVEGARDE_COLUMNS_DESACTIVE = {
    CONTRAT_REF,
    CONTRAT_TIER,
    CONTRAT_REFERENT,
    CONTRAT_SAUVEGARDE,
    CONTRAT_QUOTA,
    CONTRAT_PRIX_TELE,
    CONTRAT_FIN,
    CONTRAT_REACTIVER
}

export const CONTRAT_REGIE_COLUMNS_ACTIF = {
  CONTRAT_FILE,
  CONTRAT_REF,
  CONTRAT_TIER,
  CONTRAT_REFERENT,
  CONTRAT_TYPE_REGIE,
  CONTRAT_PRIX_REGIE,
  CONTRAT_DEBUT,
  CONTRAT_FIN,
  CONTRAT_ANNIVERSAIRE,
  CONTRAT_RECONDUCTION,
  CONTRAT_ACTION,
}

export const CONTRAT_REGIE_COLUMNS_DESACTIVE = {
  CONTRAT_REF,
  CONTRAT_TIER,
  CONTRAT_REFERENT,
  CONTRAT_TYPE_REGIE,
  CONTRAT_DEBUT,
  CONTRAT_FIN,
  CONTRAT_ANNIVERSAIRE,
  CONTRAT_REACTIVER
}

export const CONTRAT_LOCATION_COLUMNS_ACTIF = {
  CONTRAT_TYPE_LOCATION, 
  CONTRAT_FILE,
  CONTRAT_TIER,
  CONTRAT_REFERENT,
  CONTRAT_REF,
  CONTRAT_DEBUT,
  CONTRAT_FIN,
  CONTRAT_ANNIVERSAIRE,
  CONTRAT_RECONDUCTION,
  CONTRAT_ACTION,
  CONTRAT_PRIX_LOCATION
}

export const CONTRAT_LOCATION_COLUMNS_DESACTIVE = {
  CONTRAT_TYPE_LOCATION,
  CONTRAT_TIER,
  CONTRAT_REFERENT,
  CONTRAT_REF,
  CONTRAT_DEBUT,
  CONTRAT_FIN,
  CONTRAT_ANNIVERSAIRE,
  CONTRAT_REACTIVER
}

export const CONTRAT_BEEMO_COLUMNS_ACTIF = {
  CONTRAT_FILE,
  CONTRAT_TIER,
  CONTRAT_REFERENT,
  CONTRAT_REF,
  CONTRAT_DEBUT,
  CONTRAT_FIN,
  CONTRAT_ANNIVERSAIRE,
  CONTRAT_RECONDUCTION,
  CONTRAT_ACTION
}

export const CONTRAT_BEEMO_COLUMNS_DESACTIVE = {
  CONTRAT_TIER,
  CONTRAT_REFERENT,
  CONTRAT_REF,
  CONTRAT_DEBUT,
  CONTRAT_FIN,
  CONTRAT_ANNIVERSAIRE,
  CONTRAT_REACTIVER
}

const INFO_PRIX_HEURE =  <Chip key="1"  sx={{marginRight: "5px"}} label="* Prix HT Heure" />;
const INFO_PRIX_MENSUELLE = <Chip key="2" sx={{marginRight: "5px"}} label="* Prix HT Mensuelle" />;
const INFO_PRIX_ANNUEL =  <Chip key="3" sx={{marginRight: "5px"}} label="* Prix HT Annuel" />;
const INFO_PRIX_PASSAGE =  <Chip key="4" sx={{marginRight: "5px"}} label="* Prix HT par passage" />;
const INFO_PAUSE = <Chip key="5" sx={{marginRight: "5px"}} label="** Une pause de facturation à un moment donné, pour laisser le temps déjà facturé se consommer" />;
const INFO_RECONDUCTION = <Chip key="6" sx={{marginRight: "5px"}} label="Rec. : Reconduction automatique du contrat" />;
const INFO_CA_MIN = <Chip key="7" sx={{marginRight: "5px"}} label="CA/min : Crédit annuel (contractuel)" />;
const INFO_FACTURATION = <Chip key="8" icon={<IoIosInformationCircle size="1.5em" />} label="Facturation de contrat à date anniversaire" sx={{marginRight: "5px"}} />

export const INFO_MAINTENANCE = {
    INFO_PRIX_HEURE,
    INFO_PAUSE,
    INFO_RECONDUCTION,
    INFO_CA_MIN,
};

export const INFO_HEBERGEMENT = {
    INFO_PRIX_MENSUELLE,
};

export const INFO_AUTRE = {
    INFO_PRIX_ANNUEL,
    INFO_FACTURATION,
}

export const INFO_TELESAUVEGARDE = {
    INFO_PRIX_ANNUEL,
}

export const INFO_REGIE = {
  INFO_PRIX_PASSAGE
}