import _ from "lodash";
import { _STAT_POURCENTAGE_EQUPIPE } from "../../../utils/constants";
import { roundValue } from "../../../utils/function";

/**
 * Calculates the percentage of data relative to the maximum value.
 *
 * @param {number} data - The data value.
 * @param {number} max - The maximum value.
 * @returns {number} The calculated percentage.
 */
export const calculPourcentage = (data, max ) => {  
    return roundValue((data / max) * 100, 2);
};

/**
 * Determines the color based on the percentage value.
 * @param {number} pourcentage - The percentage value.
 * @returns {string} - The color corresponding to the percentage value.
 */
export const barColor = (pourcentage) => {
    const colors = [];
    _STAT_POURCENTAGE_EQUPIPE.forEach((item) => {
        colors.push(item.color);
    });

    if (pourcentage < 40) {
        return colors[0];
    } else if (pourcentage >= 40 && pourcentage < 50) {
        return colors[1];
    } else if (pourcentage >= 50 && pourcentage < 80) {
        return colors[2];
    } else if (pourcentage >= 80 && pourcentage <= 100) {
        return colors[3];
    } else {
        return colors[4];
    }
};

/**
 * Retrieves the percentage values and corresponding colors.
 * @returns {Array} An array of objects containing the text and color for each percentage value.
 */
export const getPourcentage = () => {
    const pourcentage = [];
    _STAT_POURCENTAGE_EQUPIPE.forEach((item) => {
        const values = item.value.split("-");
        const text = values.length === 1
            ? parseInt(values[0]) < 100 ? "< " + values[0] + "%" : "> " + values[0] + "%"
            : values[0] + "% - " + values[1] + "%";
        pourcentage.push({
            text: text,
            color: item.color
        });
    });
        
    return pourcentage;
}

/**
 * Retrieves the IDs of the specified type of invoices based on their names.
 *
 * @param {Array} typeFacturations - The array of typeFacturations objects.
 * @param {Array} names - The array of names to search for.
 * @returns {string} - The comma-separated string of IDs.
 */
export const getIdsTypeInvoice = (typeFacturations, names) => {
    const ids = [];
    names.forEach((name) => {
        const type = _.find(typeFacturations, { name: name });
        ids.push(type.id);
    });

    return ids.join(',');
}

/**
 * Calculates the sum of a specific column in an array of data objects.
 * 
 * @param {Array} data - The array of data objects.
 * @param {string} type - The type of the column to sum.
 * @param {string|null} column - The name of the column to sum. If null, the sum will be calculated based on the type directly.
 * @returns {number} - The sum of the specified column.
 */
export const sumColumn = (data, type, column) => {
    let sum = 0;
    data.forEach((item) => {
        if( !_.isNull(column) &&!_.isUndefined(item[column])) 
            sum += parseInt(item[column][type]);
        if( _.isNull(column))
            sum += parseInt(item[type]);
    });

    return sum;
}
