import React, { useEffect, useState } from "react";
import PrixFacturationTemplate from "./PrixFacturationTemplate";
import { _ACTIONS } from "../../utils/constants";
import { useUpdateTarifs } from "../../hooks/useUpdateTarifs";
import { createToast } from "../../utils/toast";
import { useGridApiRef } from '@mui/x-data-grid';
import { RequestHelper } from "../../utils/requestHelper";

export default function RenouvellementBitfender() {
  const customColumns = [
    {
      field: "utilisateur",
      headerName: "Nbr des utilisateur",
      width: 180,
      type: "number",
      editable: false,
    },
    {
      field: "achat",
      headerName: "Prix d'achat HT (à l'unité/annuel)",
      type: "number",
      width: 250,
      editable: true,
      renderCell: (params) => {
        return (
          <div>
            {params.value} €
          </div>
        );
      }
    },
   
    {
      field: "vente",
      headerName: "Prix de vente HT (à l'unité/annuel)",
      type: "number",
      width: 250,
      editable: true,
      renderCell: (params) => {
        return (
          <div>
            {params.value} €
          </div>
        );
      }
    }
  ];

  const apiRef = useGridApiRef();
  const requestHelper = new RequestHelper();
  const [dataPrix, setDataPrix] = useState([]);
  const [processRowUpdate, handleProcessRowUpdateError] = useUpdateTarifs();
  
  useEffect(() => {
    getTarifs();
  }, []);

  /**
   * Fetches tarifs from the server.
   * @returns {Promise<void>} A promise that resolves when the tarifs are fetched.
   */
  function getTarifs() {
    try{
      requestHelper.doRequest(
        _ACTIONS.GET,
        "/prix-facturations?produitService=3"
      ).then((response) => {
        setDataPrix(
          response.data["hydra:member"].map((tarif) => ({
            id: tarif.id,
            utilisateur: tarif.quantite,
            achat: tarif.achat,
            vente: tarif.vente
          }))
        );
      });
    } catch (error) {
      createToast("error", "Erreur lors de la récupération des tarifs");
    }
  } 

  return (
    <>
      <PrixFacturationTemplate 
        apiRef={apiRef}
        height={400}
        columns={customColumns} 
        row={dataPrix}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
    </>
  );
}
