import React from 'react';
import { Box, Accordion, AccordionSummary, List} from "@mui/material";
import Chip from '@mui/material/Chip';
import { _COLORS } from "../../../utils/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import { _ROUTES , _ROUTER_DOM_URL} from "../../../utils/_routes_constants";
import { _ACTIONS } from "../../../utils/constants";
import { RequestHelper } from "../../../utils/requestHelper";
import { createToast } from "../../../utils/toast";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IoTicketOutline } from "react-icons/io5";
import { _TICKET_STATUS_ID } from "../../../utils/_tickets_constants";
import _ from 'lodash';

export const TicketAutres = ({ ticket }) => {
    const [tickets, setTickets] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const requestHelper = new RequestHelper();
    const _ERROR = "Une erreur est survenue lors de la récupération des autres tickets";

    useEffect(() => {
        let queryData = {};
         queryData = {
            ...queryData,
            societe: ticket?.societe?.id,
            ticket: ticket?.id,
          };
        requestHelper
          .doRequest(_ACTIONS.GET, _ROUTES.TICKETS_OTHERS, queryData)
          .then((res) => {
            if([200, 201].includes(res.status)) {
                    setTickets(res.data);
                    setRowCount(res.data.length);
                } else {  
                    createToast("error", _ERROR);
                } 
          })
          .catch((err) => {
            createToast(
              "error",
              _ERROR
            );
          });
    }, [ticket]);

    const link = (ticket) => {
        return _ROUTER_DOM_URL.TICKET_EDIT + ticket.idTicket;
    };


    return <>
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            defaultExpanded
            sx={{
                backgroundColor: _COLORS.midGrey,
                fontSize: "1em",
                height: "35px !important",
                minHeight: "35px !important",
              }}
        >
            Autres tickets : {rowCount}
        </AccordionSummary>
        <Box>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {tickets.map((ticket) => (
                <ListItemButton sx={{width: '100%'}} component="a" href={link(ticket)}>
                    <ListItemIcon>
                        <IoTicketOutline sx={{fontSize: '50px'}}/>
                    </ListItemIcon>
                    <ListItemText 
                    primary={ticket.idTicket + ' - ' + ticket.subject} 
                    secondary={
                        <React.Fragment>
                            <Chip label={ticket.name} color={ticket.id === _TICKET_STATUS_ID.EN_COURS_STATUS ? 'success' : 'primary'} variant="outlined" size="small" />
                        </React.Fragment>
                    }
                    />
                </ListItemButton>
                ))}
            </List>
        </Box>
    </Accordion>
    </>;
};

export default TicketAutres;