import { _ACTIONS, _HTTP } from "../utils/constants";
import { RequestHelper } from "../utils/requestHelper";
import { createToast } from "../utils/toast";
import _ from "lodash";

const requestHelper = new RequestHelper();

export const getDataStat = (params, actions, errorMessage, route) => {
    requestHelper
        .doRequest(_ACTIONS.GET, `${route}`, params)
        .then((response) => {
            if (_HTTP.ok !== response.status) {
                throw new Error(errorMessage);
            }
            _.isUndefined(response.data["hydra:member"]) ? actions(response.data) : actions(response.data["hydra:member"]);
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};