import { useState } from "react";
import FormTitle from "../components/helpers/FormTitle";
import { Box, Button, Stack } from "@mui/material";
import { RequestHelper } from "../utils/requestHelper";
import { _ACTIONS, _COLORS } from "../utils/constants";
import { createToast } from "../utils/toast";
import TableAlso from "../components/parametrage-also/TableAlso";
import TableNext from "../components/parametrage-also/TableNext";
import { useNavigate } from "react-router-dom";
import BasicModal from "../components/helpers/BasicModal";
import { ParametrageClient } from "../components/parametrage-also/ParametrageClient";
import { _ROUTER_DOM_URL } from "../utils/_routes_constants";

export const ParametrageAlso = () => {
  const navigate = useNavigate();
  const rq = new RequestHelper();
  const [catalogueAlsoSelected, setCatalogueAlsoSelected] = useState([]);
  const [catalogueNextSelected, setCatalogueNextSelected] = useState();
  const [showModal, setShowModal] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const handleSubmit = (e) => {
    const { id, ...rest } = catalogueNextSelected.original;
    let data = {
      name: catalogueAlsoSelected.DisplayName,
      productNameAlso: catalogueAlsoSelected.ProductName,
      parent: id,
      isActive: true,
    };
    if (
      data.name === "" ||
      data.parent === "" ||
      data.parent === undefined ||
      data.productNameAlso === ""
    ) {
      createToast("error", "Veuillez remplir tous les champs");
      return;
    }
    rq.doRequest(_ACTIONS.POST, "save_also_service", data).then((res) => {
      if (res.status === 200) {
        createToast("success", "Service ajouté au catalogue avec succès");
        setRefreshCounter((prev) => prev + 1);
      }
    });
  };

  return (
    <>
      <BasicModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={"Fusionner un client"}
        content={<ParametrageClient />}
      />
      <FormTitle title="Paramétrage Also - Next" />
      <>
        <Stack spacing={2} marginTop={5}>
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
            <Button
              onClick={handleSubmit}
              disabled={
                catalogueAlsoSelected.length === 0 || !catalogueNextSelected
              }
              variant="contained"
              sx={{
                backgroundColor: _COLORS.defaultGreen,
                "&:hover": {
                  backgroundColor: _COLORS.hoverDefaultGreen,
                },
              }}>
              Ajouter au catalogue Next
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowModal(true)}>
              Fusionner un client
            </Button>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => navigate(_ROUTER_DOM_URL.RECAP_CLIENT_ALSO)}>
              Recapitulatif client also
            </Button>
          </Box>
          <Box display={"flex"} gap={3}>
            <TableAlso
              setCatalogueAlsoSelected={setCatalogueAlsoSelected}
              catalogueAlsoSelected={catalogueAlsoSelected}
              refreshCounter={refreshCounter}
            />
            <TableNext
              setCatalogueNextSelected={setCatalogueNextSelected}
              catalogueNextSelected={catalogueNextSelected}
              refreshCounter={refreshCounter}
            />
          </Box>
        </Stack>
      </>
    </>
  );
};
