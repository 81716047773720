import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export const ModalAlert = ({title, action, loan, setOpen, open, disagree, agree}) => {
    const handleClose = () => {
        setOpen(false);
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogActions>
            <Button 
                onClick={handleClose}
            >{disagree}</Button>
            <Button 
                onClick={() => action(loan)} 
                autoFocus
                style={{color: "red"}}
            >{agree}</Button>
        </DialogActions>
    </Dialog>
};

export default ModalAlert;