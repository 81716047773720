import {
  Box,
  Chip,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { _ACTIONS, _COLORS, _MESSAGES } from "../../utils/constants";
import DonutSmallRoundedIcon from "@mui/icons-material/DonutSmallRounded";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { _ANNUAIRE_STAT_BASES } from "../../utils/dashboard-general/constant";

export const Stats = memo(() => {
  const [stats, setStats] = useState(null);
  const requestHelper = new RequestHelper();
  const navigate = useNavigate();

  const fetchStats = async () => {
    try {
      const res = await requestHelper.doRequest(
        _ACTIONS.GET,
        "count-stat-bases"
      );
      setStats(res.data[0]);
    } catch (error) {
      createToast("error", _MESSAGES.error2);
    }
  };

  useEffect(() => {
    if (!stats) {
      fetchStats();
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          w: "100%",
          border: `2px solid ${_COLORS.turquoiseBox}`,
          backgroundColor: _COLORS.white,
        }}
      >
        <Box
          sx={{
            backgroundColor: _COLORS.turquoiseBox,
            display: "flex",
            gap: 2,
            alignItems: "center",
            p: 1,
          }}
        >
          <DonutSmallRoundedIcon sx={{ color: _COLORS.white }} />
          <Typography variant="h6" sx={{ color: _COLORS.white }}>
            Statistiques
          </Typography>
        </Box>
        {stats &&
          Object.entries(stats).map(([key, value], i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(_ANNUAIRE_STAT_BASES[key].path);
                }}
              >
                {_ANNUAIRE_STAT_BASES[key].icon}
                <ListItemText primary={key} sx={{ ml: 1.5 }} />
                <Chip label={value} />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
    </>
  );
});
