import { Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { TELEPHONIE_ASTERISK_FORM_FIELD } from "../../../utils/actif-config/actif_configuration_constants";

/*
  @TODO :
  - fix default values isSsl 
  */
export default function FormFieldAsterisk({
  formik,
  formStates,
  setFormStates,
}) {
  useEffect(() => {
    setFormStates({
      ...formStates,
      isTelephonieAsteriskForm: true,
    });
  }, []);

  return (
    <>
      <Grid container spacing={1} justifyContent="center" marginTop={2}>
        {TELEPHONIE_ASTERISK_FORM_FIELD.map((field) => (
          <Grid item xs={12} md={8} key={field.name}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body1" component="div">
                  <strong>{field.label}</strong>
                </Typography>
              </Grid>
              <Grid item xs={field.size}>
                <TextField
                  fullWidth
                  size="small"
                  label={field.label}
                  name={field.name}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors[field.name])}
                  helperText={formik.errors[field.name]}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
