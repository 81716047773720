import moment from 'moment';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { TextFormatter } from '../../../../utils/muiTableHelper';
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { Box } from "@mui/system";
import { _DATE_FORMAT } from '../../../../utils/constants';

export const LoansList = (loans) => {

  const columns = [
    {
        accessorKey: "resource",
        header: "Resource",
        enableEditing: false,
        Cell: ({ row }) => {
          let text = row.original.resource.type ? row.original.resource.type : "";
            text += row.original.brand ? " " + row.original.brand : "";
            text += row.original.reference ? " " + row.original.reference : "";
          return (
            <TextFormatter
              text={text}
            />
          );
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ row }) => {
          return row.original.description 
          ? <Box dangerouslySetInnerHTML={{__html: row.original.description}} /> 
          : null;
        },
      },
      {
        accessorKey: "quantity",
        header: "Quantité",
        Cell: ({ row }) => {
          return row.original.quantity 
          ? <TextFormatter text={row.original.quantity} /> 
          : null;
        },
      },
      {
        accessorKey: "dateDebut",
        header: "Date de début",
        Cell: ({ row }) => {
          return row.original.startDate 
          ? <TextFormatter text={moment(row.original.startDate).format(_DATE_FORMAT.date_display)} /> 
          : null;
        },
      },
      {
        accessorKey: "dateFin",
        header: "Date de fin",
        Cell: ({ row }) => {
          return row.original.endDate 
          ? <TextFormatter text= {moment(row.original.endDate).format(_DATE_FORMAT.date_display)} /> 
          : null;
        },
      },
  ];

  // Get the table
 // Get the table
 const table = useMaterialReactTable({
    columns: columns,
    data: loans,
    enableEditing: true,
    editDisplayMode: "cell",
    initialState: {
      columnOrder: [
        "resource",
        "description",
        "quantity",
        "dateDebut",
        "dateFin",
      ],
    },
    localization: MRT_Localization_FR,
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
}

export default LoansList;