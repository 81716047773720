import { Typography } from "@mui/material";
import RecapitulatifContrats from "../../components/gestion-exploitation/RecapitulatifContrats";

const ContratsExploitation = () => {
    return (
        <>
            <Typography variant="h5">Récapitulatif de contrat de maintenance</Typography>
            <RecapitulatifContrats />
        </>
    );
};

export default ContratsExploitation;