import React, { useEffect, useState } from "react";
import { DECOMPTE_TEMPS, _ACTIONS } from "../../utils/constants";
import DecompteTempsListe from "../../components/decompte-temps/DecompteTempsListe";
import DecompteTempsFormulaire from "../../components/decompte-temps/DecompteTempsFormulaire";
import { Box, Typography } from "@mui/material";
import { _ROUTES } from "../../utils/_routes_constants";
import { getDataProduitService } from "../../repository/ProduitService";
import { getDataDecompteTemps } from "../../repository/decompte-temps/DecompteTemps";

const DecompteTemps = () => {
  const [decompteTempsList, setDecompteTempsList] = useState([]);
  const [listProduitService, setListProduitService] = useState([]);

  useEffect(() => {
    getDataDecompteTemps(
      {personnalise: 0}, 
      setDecompteTempsList, 
      "Une erreur est survenue lors de la récupération des données", 
      _ROUTES.DECOMPTE_TEMPS
    );
  }, []);

  useEffect(() => {
    getDataProduitService(
      {exists: { parent: false }}, 
      setListProduitService, 
      "Une erreur est survenue lors de la récupération des données", 
      _ROUTES.PRODUIT_SERVICE
    );
   
  }, []);

  return (
    <>
      <Typography variant="h4">{DECOMPTE_TEMPS.h1}</Typography>
      <Box width={"100%"}>
        <DecompteTempsFormulaire
          setDecompteTempsList={setDecompteTempsList}
          listProduitService={listProduitService}
        />
        <DecompteTempsListe
          decompteTempsList={decompteTempsList}
          setDecompteTempsList={setDecompteTempsList}
          listProduitService={listProduitService}
        />
      </Box>
    </>
  );
};

export default DecompteTemps;
