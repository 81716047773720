import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 6;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * multi-select component using Material-UI.
 * !!IMPORTANT FOR GIVIN THE WELL CONTEXT TO THE FORMIK FORM
 * 1 - VALUE NEEDED TO BE THE SAME OBJECT AS IN THE RENDERINPUT PROPS!
 * 2 - INITIAL VALUE MUST [] (AT LEAST)
 *
 * @component
 * @param {string} name - The name of the multi-select field.
 * @param {string} id - The ID of the multi-select field.
 * @param {string} label - The label of the multi-select field.
 * @param {Array} value - Value targeted in Formik Context.
 * @param {function} onChange - The function to handle value changes.
 * @param {Array} options - The list of options.
 * @param {Object} formik - The Formik context object.
 * @param {boolean} isLoading - True = Data is loading.
 */
export default function MuiMultiSelect(props) {
  const { name, id, label, value, onChange, options, formik, isLoading } =
    props;

  /**
   * Returns the styles for a list item based on the selected values.
   *
   * @param {Object} list - The list item.
   * @param {Array} selectedValues - The selected values.
   * @returns {Object} - The styles object.
   */
  function getStyles(list, selectedValues) {
    const isSelected = selectedValues.includes(list.value);
    return {
      fontWeight: isSelected ? 700 : 400,
      backgroundColor: isSelected && "transparent",
    };
  }

  return (
    <FormControl
      sx={{ m: 1 }}
      error={Boolean(formik.touched[name] && Boolean(formik.errors[name]))}
      fullWidth
    >
      <InputLabel id={id} size="small">
        {label}
      </InputLabel>
      <Select
        labelId={id}
        id={id}
        multiple
        size="small"
        value={value}
        onChange={onChange}
        input={<OutlinedInput id={id} label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value.key} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {isLoading ? (
          <MenuItem value="" disabled>
            Chargement...
          </MenuItem>
        ) : (
          options.map((option, idx) => (
            <MenuItem
              key={option.value}
              value={option}
              style={getStyles(option, formik.values[name])}
            >
              {option.key}
            </MenuItem>
          ))
        )}
      </Select>
      {formik.touched[name] && Boolean(formik.errors[name]) && (
        <FormHelperText variant="caption" color="red">
          {formik.errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
}
