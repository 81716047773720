import { Box, Divider, Typography } from "@mui/material";
import React from "react";

/**
 * Template de titre de formulaire

    * @param {*} props  
    * @returns JSX.Element
    **/
const FormTitle = ({ title, children = null, icon = null, sx = null }) => {
  return (
    <Box width={"100%"} sx={sx}>
      <Box display={"flex"} alignItems={"center"} gap={2}>
        {icon}
        <Typography variant="h4">{title}</Typography>
        {children}
      </Box>
      <Divider />
    </Box>
  );
};

export default FormTitle;
