import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo, useState } from "react";
import NoRowsOverlay from "../../helpers/NoRowOverlay";
import { Box } from "@mui/system";
import { ColonnesBsConfigurationExpandedRow } from "./ColonnesBsConfigurationExpandedRow";

/**
 * Représentes les lignes étendues du tableau de configuration de la base de connaissance.
 *
 * @param {Array} sections - Tableau des sections dans BaseConnaissanceSection entity
 * @param {Function} setShowModal - La fonction pour afficher le modal.
 * @param {Object} sectionRow - L'objet de la ligne de section.
 * @param {Function} setSectionRow - La fonction pour définir la ligne de section.
 * @param {Function} setIsSectionForm - La fonction pour définir le formulaire de section.
 */
export function BsExpandedRows({
  sections,
  setShowModal,
  sectionRow,
  setSectionRow,
  setIsSectionForm,
}) {
  const [sectionRows, setSectionRows] = useState(null);

  const memoziedSetSectionRows = useMemo(() => {
    if (sections) {
      setSectionRows(sections);
    }
  }, [sections, sectionRow]);

  const columns = ColonnesBsConfigurationExpandedRow({
    setSectionRows,
    setShowModal,
    setSectionRow,
    setIsSectionForm,
  });

  return (
    <Box sx={{ height: 250, width: "100%" }}>
      <DataGrid
        density="compact"
        rows={sectionRows ?? []}
        columns={columns}
        disableRowSelectionOnClick
        pageSizeOptions={[5, 10, 20]}
        slots={{ noRowsOverlay: NoRowsOverlay }}
      />
    </Box>
  );
}
