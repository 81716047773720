import React, { useContext, useEffect, useState } from "react";
import FormTitle from "../../components/helpers/FormTitle";
import { MdOutlineAddBox } from "react-icons/md";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { _ACTIONS, _COLORS, _MESSAGES } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { RequestHelper } from "../../utils/requestHelper";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";
import { createToast } from "../../utils/toast";
import ToolTipInfo from "../../components/utils/ToolTipInfo";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { UserContext } from "../../contexts/UserContext";

export function BaseConnaissanceForm() {
  const id = useParams().id;
  const navigate = useNavigate();
  const requestHelper = new RequestHelper();
  const [sections, setSections] = useState([]);
  const { user } = useContext(UserContext);

  /**
   * Fetches the grouped sections (in BaseConnaissanceSections) grouped by BaseConnaissanceCategories
   */
  async function sectionsGroupedCategories() {
    const res = await requestHelper.doRequest(
      _ACTIONS.GET,
      _ROUTES.BASE_CONNAISSANCE_SECTIONS_GROUPED_CATEGORIES
    );
    const data = res.data;
    res.status === 200
      ? setSections(data)
      : createToast("error", "Impossible de récupérer les sections");
  }

  useEffect(() => {
    sectionsGroupedCategories();
  }, []);

  const validationSchema = Yup.object({
    section: Yup.string().required(_MESSAGES.field_required),
    isEnabled: Yup.number().required(_MESSAGES.field_required),
    keywords: Yup.string().nullable(),
    title: Yup.string().required(_MESSAGES.field_required),
    description: Yup.string().nullable(),
    isInterne: Yup.bool().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      section: "",
      isEnabled: "",
      keywords: [],
      title: "",
      description: "",
      isInterne: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const method = id ? _ACTIONS.PUT : _ACTIONS.POST;
      const url = id
        ? _ROUTES.BS_SAVE_ARTICLE + `/${id}`
        : _ROUTES.BS_SAVE_ARTICLE;

      const formData = {
        ...values,
        keywords: values.keywords.split(","),
        userId: user.id,
      };

      const res = await requestHelper.doRequest(method, url, formData);

      if (res.status === 200) {
        createToast("success", "Article ajouté avec succès");
        navigate(_ROUTER_DOM_URL.BASE_CONNAISSANCE);
      } else {
        createToast("error", "Impossible d'ajouter l'article");
      }
    },
  });

  // IF EDIT MODE DO FETCH ARTICLE
  useEffect(() => {
    if (id) {
      requestHelper
        .doRequest(_ACTIONS.GET, _ROUTES.BASE_CONNAISSANCE_ARTICLES + `/${id}`)
        .then((res) => {
          const data = res.data;
          if (res.status === 200) {
            formik.setValues({
              section: data.section.id,
              isEnabled: data.isEnabled,
              keywords: data.keywords.join(),
              title: data.title,
              description: data.description,
              isInterne: data.isInterne,
            });
          } else {
            createToast("error", "Impossible de récupérer l'article");
          }
        });
    }
  }, [sections]);

  return (
    <>
      <FormTitle title="Centre de la base de connaissances" />
      <Container
        component="main"
        maxWidth={false}
        sx={{
          backgroundColor: "white",
          padding: "1rem",
          pb: "2rem",
          borderTop: `3px solid ${_COLORS.lightBlue}`,
          boxShadow: `4px 4px 4px ${_COLORS.midGrey}`,
        }}
      >
        <Grid display={"flex"} gap={2} alignItems={"center"}>
          <MdOutlineAddBox size={20} />
          <Typography variant="h6" component="div">
            {id ? "Modifier un article" : "Ajouter un article"}
          </Typography>
        </Grid>
        <Divider />
        <Container component="main" maxWidth="lg">
          <Grid container spacing={1} justifyContent="center" marginTop={5}>
            <Grid
              item
              xs={12}
              md={8}
              display={"flex"}
              gap={1}
              flexDirection={"column"}
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body1" component="div">
                    <strong>Section *</strong>
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="section-select" size="small">
                      Section
                    </InputLabel>
                    <Select
                      defaultValue=""
                      id="section-select"
                      name="section"
                      label="Section"
                      fullWidth
                      size="small"
                      value={formik.values.section}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    >
                      <MenuItem value="">
                        <em>Veuillez choisir une section</em>
                      </MenuItem>
                      {sections?.flatMap((categorie) => [
                        <ListSubheader key={`cat-${categorie.categoryId}`}>
                          {categorie.categoryTitle}
                        </ListSubheader>,
                        ...categorie.sections.map((section) => (
                          <MenuItem
                            key={`section-${section.sectionId}`}
                            value={section.sectionId}
                          >
                            {section.sectionTitle}
                          </MenuItem>
                        )),
                      ])}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body1" component="div">
                    <strong>État *</strong>
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.isEnabled &&
                      Boolean(formik.errors.isEnabled)
                    }
                  >
                    <InputLabel size="small" id="isEnabled-label">
                      État
                    </InputLabel>
                    <Select
                      labelId="isEnabled-label"
                      id="isEnabled"
                      value={formik.values.isEnabled}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      name="isEnabled"
                      size="small"
                    >
                      <MenuItem value="">
                        {" "}
                        <em>Veuillez choisir un état</em>{" "}
                      </MenuItem>
                      <MenuItem value={0}>Obsolète</MenuItem>
                      <MenuItem value={1}>Actif</MenuItem>
                      <MenuItem value={-1}>Brouillon</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body1" component="div">
                    <strong>Mots-clés</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Mot-clés"
                    size="small"
                    type="text"
                    id="keywords"
                    name="keywords"
                    value={formik.values.keywords}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.keywords &&
                      !formik.values.keywords &&
                      Boolean(formik.errors.keywords)
                    }
                    helperText={
                      formik.touched.keywords && !formik.values.keywords
                        ? formik.errors.keywords
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <ToolTipInfo message="Mot-clés séparé par une virgule" />
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body1" component="div">
                    <strong>Titre *</strong>
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    label="Titre"
                    size="small"
                    type="text"
                    id="title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.title &&
                      !formik.values.title &&
                      Boolean(formik.errors.title)
                    }
                    helperText={
                      formik.touched.title && !formik.values.title
                        ? formik.errors.title
                        : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: _COLORS.grey,
                    fontSize: "13px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <em>Saisir un titre court...</em>
                </Typography>
              </Grid>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body1" component="div">
                    <strong>Description</strong>
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <CKEditor
                    editor={Editor}
                    name="description"
                    data={
                      formik.values.description ? formik.values.description : ""
                    }
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      formik.setFieldValue("description", data ? data : "");
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  onChange={(e) =>
                    formik.setFieldValue("isInterne", e.target.checked)
                  }
                  name="location"
                  color="primary"
                  checked={formik.values.isInterne}
                />
                <Typography variant="body2" component="div">
                  <em>
                    {" "}
                    Pour une utilisation en interne uniquement (masque la
                    section des clients)
                  </em>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => {
                    navigate(_ROUTER_DOM_URL.BASE_CONNAISSANCE);
                  }}
                >
                  Retour
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={formik.handleSubmit}
                >
                  {id ? "Modifier" : "Ajouter"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}
