import DataGridDetailContrats from "./DataGridDetailContrats";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { _COLORS } from "../../utils/constants";
import { ACTIF, DESACTIVE } from "./ColumnsContrat";
import BasicModal from "../helpers/BasicModal";
import { useState } from "react";
import FormModalAddContrat from "./FormModalAddContrat";
import { Button } from "@mui/material";
import { IoMdAdd } from "react-icons/io";

const ListContrats = ({typeContrat, nomContrat, reload, setReload}) => {
    nomContrat = nomContrat.toLowerCase();
    nomContrat = nomContrat.charAt(0).toUpperCase() + nomContrat.slice(1);
    const [showModal, setShowModal] = useState(false);
    const [row, setRow] = useState(null);

    const theme = createTheme({
        components: {
          MuiDataGrid: {
            styleOverrides: {
              footerContainer: {
                backgroundColor: _COLORS.lightGrey,
              },
              columnHeader: {
                backgroundColor: _COLORS.lightGrey,
                fontWeight: "bold",
              },
              columnHeaderTitle: {
                fontWeight: "bold",
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                borderTop: `0.5rem solid ${_COLORS.lightGrey}`,
              },
            },
          },
        },
        palette: {
          custom: {
            buttonGreen: "#57a85c",
            hoverGreen: "#74A677",
            chipCategorie: "#4fc1ef",
            buttonError: "#d43e2f",
            hoverButtonError: "973F37",
          },
          background: {
            default: _COLORS.lightGrey,
            lightGrey: "#f4f4f4",
            grey: "777a7f",
            lightBlue: "#3c8dbc",
            turquoiseBox: "#00c0ef",
          },
        },
      });

    return (
        <>
        <BasicModal
            showModal={showModal}
            modalWidth="800px"
            setShowModal={setShowModal}
            width={1000}
            content={<FormModalAddContrat setShowModal = {setShowModal} typeContrat={typeContrat} nomContrat={nomContrat} row={row} setRow={setRow} />}
        ></BasicModal>
         <ThemeProvider theme={theme}>
            <Button 
                title="Ajouter un contrat" 
                sx={{float: "right"}} 
                color="success" variant="outlined" 
                startIcon={<IoMdAdd />} 
                onClick={() => {setRow(null); setShowModal(true)}}>
                Ajouter
            </Button>
            <Box>
                <DataGridDetailContrats 
                  key="1" 
                  title={nomContrat} 
                  typeContrat={typeContrat} 
                  status={ACTIF} 
                  setShowModal={setShowModal} 
                  setRow={setRow}
                  row={row}
                  reload={reload}
                  setReload={setReload}
                /> 
            </Box>
            <Box>
                <DataGridDetailContrats 
                  key="2" 
                  title={nomContrat} 
                  typeContrat={typeContrat} 
                  status={DESACTIVE} 
                  setShowModal={setShowModal} 
                  setRow={setRow}
                  row={row}
                  reload={reload}
                  setReload={setReload}
                  />
            </Box>
         </ThemeProvider>
        </>
    );
}

export default ListContrats;