import { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Alert from '@mui/material/Alert';
import { TelephonieOvhGroups } from "../../components/ovh/telephonie/TelephonieOvhGroups";
import InformationGroup from "../../components/ovh/telephonie/InformationGroup";
import TelephonieOvhClients from "../../components/ovh/telephonie/TelephonieOvhClients";
import { getDataOvh } from "../../repository/telephonies/Ovh";
import { _ROUTES } from "../../utils/_routes_constants";

const TelephonieOvh = () => {
  const [isAlert, setIsAlert] = useState(true);
  const [client, setClient] = useState(0);
  const [group, setGroup] = useState(0);
  const [nbGroup, setNbGroup] = useState(0); 
  const [selectsGroup, setSelectsGroup] = useState(0);
  const [selectGroups, setSelectGroups] = useState([]);
  const textDebut = "Pour commencer, merci de bien vouloir choisir un group client...";

  useEffect(() => {
    getDataOvh(
      {}, 
      setNbGroup, 
      "Une erreur est survenue lors de la récupération du nombre de groupes",
      _ROUTES.NB_GROUP_OVH
    );
  }, []);

  /**
   * Handles the change event of the group selection.
   * @param {Object} event - The event object.
   */
  const handleChangeGroup = (event) => {
    const billingAccount = event.target.value;
    const group = getGroupById(billingAccount);
    setGroup(group);
    event.target.value !== 0 ? setIsAlert(false) : setIsAlert(true);
  };

  /**
   * Retrieves a group object by its billing account.
   *
   * @param {string} billingAccount - The billing account of the group.
   * @returns {object|undefined} - The group object if found, otherwise undefined.
   */
  function getGroupById(billingAccount) {
    return selectGroups.find(group => group.billingAccount === billingAccount);
  }

  return (
    <>
      <Typography variant="h4">Tableau de bord <span style={{color:"grey", fontSize:"20px"}}> Groupe(s) de téléphonie(s) ({nbGroup})</span></Typography>
      <Box sx={{marginTop:"20px"}}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TelephonieOvhClients  
              client={client} 
              setClient={setClient}
              setNbGroup ={setNbGroup}
              setSelectsGroup={setSelectsGroup}
              setIsAlert={setIsAlert}
              getNbGroupOvh={getDataOvh}
              setGroup={setGroup}
            />
          </Grid>
          {client !== 0 && (
          <Grid item xs={6}>
            {selectsGroup?.length > 0 && (<TelephonieOvhGroups 
                                            groups={selectsGroup} 
                                            handleChangeGroup={handleChangeGroup} 
                                            group={group} 
                                            client={client} 
                                            selectGroups={selectGroups}
                                            setSelectGroups={setSelectGroups}
                                          />)}
          </Grid>
          )}
        </Grid>
      </Box>
      
      <Box sx={{marginTop:"20px"}}>
          {isAlert && (<Alert severity="info">{textDebut}</Alert>)}
          {!isAlert && (<InformationGroup group={group} client={client} />)}
      </Box>
    </>
  );
};

export default TelephonieOvh;