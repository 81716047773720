import React, { useEffect, useState } from "react";
// MUI
import FormTitle from "../../components/helpers/FormTitle";
import { Box, Button, Container } from "@mui/material";
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// Utils
import {
  _ACTIFS_CONFIGURATION,
  _ACTIONS,
  _COLORS,
} from "../../utils/constants";
import FormStepOne from "../../components/actifs-configurations/form/FormStepOne";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";
import { createToast } from "../../utils/toast";
import { RequestHelper } from "../../utils/requestHelper";
import {
  getCataloguesProduitService,
  getSocietes,
} from "../../utils/actif-config/actif_configuration_functions";
import Loader from "../../components/utils/Loader";
import { useNavigate } from "react-router-dom";

/**
 * Formulaire de création d'un actif. (Étape 1)
 *
 * @returns {JSX.Element} The form component.
 */
export function FormActifConfigStepOne() {
  const [parentPS, setParentPS] = useState();
  const [societes, setSocietes] = useState([]);
  const [produitServiceCategories, setProduitServiceCategories] = useState();
  const [loadingStates, setLoadingStates] = useState({
    isParentPSLoaded: false,
    isProduitServiceCategoriesLoaded: false,
  });
  const requestHelper = new RequestHelper();
  const navigate = useNavigate();

  /**
   * Searches for categories related to a produit service.
   *
   * @param {number} id - The ID of the parent category.
   * @returns {void}
   */
  const searchCategoriesPS = (id) => {
    requestHelper
      .doRequest(_ACTIONS.GET, _ROUTES.PRODUIT_SERVICE, {
        parent: id,
      })
      .then((res) => {
        setProduitServiceCategories(res.data["hydra:member"]);
      })
      .catch((err) => {
        createToast("error", "Erreur de récupération des catégories");
      });
  };

  const formik = useFormik({
    initialValues: {
      societe: "",
      produitService: "",
      produitServiceCategorie: "",
      quantite: "",
      decompteTemps: false,
      description: "",
    },
    validationSchema: Yup.object({
      societe: Yup.string().required("Veuillez renseigner une société"),
      produitService: Yup.string().required(
        "Veuillez renseigner un produit ou un service"
      ),
      produitServiceCategorie: Yup.string().required(
        "Veuillez renseigner une catégorie"
      ),
      quantite: Yup.number().required("Veuillez renseigner une quantité"),
      decompteTemps: Yup.boolean().required(
        "Veuillez renseigner un décompte de temps"
      ),
      description: Yup.string(),
    }),
    onSubmit: async (values) => {
      const formData = {
        ...values,
        isActive: true,
      };

      const res = await requestHelper.doRequest(
        _ACTIONS.POST,
        _ROUTES.SOCIETE_PRODUIT_SERVICE,
        formData
      );

      if (res.status === 201) {
        navigate(`${_ROUTER_DOM_URL.ACTIF_CONFIGURATION_EDIT}/${res.data.id}`);
      } else {
        createToast("error", "Erreur de configuration de l'actif");
      }
    },
  });

  useEffect(() => {
    getSocietes(setSocietes);
    getCataloguesProduitService(setParentPS, setLoadingStates);
  }, []);

  if (!loadingStates.isParentPSLoaded) {
    return <Loader />;
  }

  return (
    <>
      <FormTitle title={_ACTIFS_CONFIGURATION.H2_CREATE} />
      <Container
        component="main"
        maxWidth={false}
        sx={{
          backgroundColor: "white",
          padding: "1rem",
          borderTop: `3px solid ${_COLORS.grey}`,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <FormStepOne
            formik={formik}
            parentPS={parentPS}
            options={societes}
            produitServiceCategories={produitServiceCategories}
            isEditForm={false}
            searchCategoriesPS={searchCategoriesPS}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mt: 3 }}
              onClick={() => {
                navigate(_ROUTER_DOM_URL.ACTIF_CONFIGURATION_LIST);
              }}
            >
              Retour à la liste des actifs
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ mt: 3 }}
            >
              Ajouter
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
}
