import * as React from "react";
import { TbFileSearch } from "react-icons/tb";
import { GoFileSymlinkFile } from "react-icons/go";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Loader from "../../utils/Loader";
import { useEffect, useState } from "react";
import moment from "moment";
import { ellipsis } from "../../../utils/function";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { RiDeleteBin6Line } from "react-icons/ri";
import { isDisabledTicket } from "../../../utils/function";
import Box from "@mui/material/Box";
import StyledBadge from "../../utils/StyleBadge";
import { _ROUTES } from "../../../utils/_routes_constants";
import { _ACTIONS, _HTTP } from "../../../utils/constants";
import { createToast } from "../../../utils/toast";
import { RequestHelper } from "../../../utils/requestHelper";

export const TicketEditFiles = ({ ticket, setTicket }) => {
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const requestHelper = new RequestHelper();

  useEffect(() => {
    setFiles(ticket?.files);
  }, [ticket]);

  /**
   * Deletes a file.
   *
   * @param {Object} file - The file to be deleted.
   * @returns {Promise<void>} - A promise that resolves when the file is deleted.
   */
  function deleteFile(file) {
    setOpen(false);
    try {
      requestHelper
        .doRequest(_ACTIONS.DELETE, `${_ROUTES.TICKET_FILE}/${file?.id}`)
        .then((response) => {
          if ([_HTTP.noContent].includes(response.status)) {
            createToast("success", "Fichier supprimé avec succès");
            setFiles(files.filter((element) => element.id !== file.id));
            setTicket((prev) => {
              return {
                ...prev,
                files: prev.files.filter((element) => element.id !== file.id),
              };
            });

            //TODO supprimer le fichier du serveur
            //file.deleteFile();
          } else {
            createToast("error", "Erreur lors de la suppression du Fichier");
          }
        });
    } catch (error) {
      createToast("error", "Erreur lors de la suppression du fichier");
    }
  }

  return (
    <>
      {ticket ? (
        <Card sx={{ mt: 2 }}>
          <Typography sx={{ m: 2, fontSize: "1rem" }} color="text.secondary">
            <strong>Liste des fichiers</strong>
            <StyledBadge
              title={"Détails des fichiers"}
              badgeContent={files?.length}
              color="primary"
            >
              <TbFileSearch />
            </StyledBadge>
          </Typography>
          {files?.map((file, index) => {
            return (
              <Typography
                key={index}
                title={"Fichier: " + file.fileName}
                sx={{ padding: 0.5 }}
              >
                {isDisabledTicket(ticket) && (
                  <Box>
                    <RiDeleteBin6Line
                      title="Supprimer le fichier"
                      size="20"
                      style={{
                        cursor: "pointer",
                        float: "right",
                        marginRight: "10px",
                        color: "red",
                      }}
                      onClick={handleClickOpen}
                    />
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Voulez-vous vraiment supprimer ce fichier ?"}
                      </DialogTitle>
                      <DialogActions>
                        <Button onClick={handleClose}>Non</Button>
                        <Button
                          onClick={() => deleteFile(file)}
                          autoFocus
                          style={{ color: "red" }}
                        >
                          Oui
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                )}
                <GoFileSymlinkFile /> {ellipsis(file.fileName, 20)}
                <Box style={{ float: "right", paddingRight: "15px" }}>
                  {moment(file.upLoadAt).format("DD/MM/YYYY")}
                </Box>
              </Typography>
            );
          })}
        </Card>
      ) : (
        <Card sx={{ mt: 2 }}>
          {/* LOADER */}
          <Loader height={100} />
        </Card>
      )}
    </>
  );
};

export default TicketEditFiles;
