import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import React from "react";
import { FormHelperText } from "@mui/material";
import { FormControl } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

/**
 *
 * @component
 * @param {Array} props.valueField - The array produit service configuration fields.
 * @returns {JSX.Element} Return the form fields for the given produit service configuration.
 */
const FormStepTwo = ({ valueField, formik }) => {
  return (
    <React.Fragment>
      {valueField &&
        valueField
          .filter((field) => field.value === true)
          .map((field, index) => {
            const fieldId = `field_${field.id}`;
            return (
              <React.Fragment key={index}>
                {field.typeField === "SELECT" ? (
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    marginTop={1.2}
                  >
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3.5}>
                          <Typography variant="body1" component="div">
                            <strong>{field.label}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={8.5}>
                          <FormControl
                            fullWidth
                            error={
                              formik.touched.valueField &&
                              Boolean(formik.errors.valueField)
                            }
                          >
                            <InputLabel id={field.label} size="small">
                              {field.label}
                            </InputLabel>
                            <Select
                              id={field.label}
                              name={fieldId}
                              value={formik.values[fieldId] || ""}
                              label={field.label}
                              onChange={formik.handleChange}
                              size="small"
                              sx={{ height: "30px" }}
                            >
                              {field.selectOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>

                            {formik.touched.valueField &&
                            formik.errors.valueField ? (
                              <FormHelperText>
                                {formik.errors.valueField}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    marginTop={1.2}
                  >
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3.5}>
                          <Typography variant="body1" component="div">
                            <strong>{field.label}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={8.5}>
                          <TextField
                            fullWidth
                            id={field.label}
                            label={field.label}
                            name={fieldId}
                            onChange={formik.handleChange}
                            value={formik.values[fieldId] || ""}
                            size="small"
                            variant="outlined"
                            error={
                              formik.touched.valueField &&
                              Boolean(formik.errors.valueField)
                            }
                            helperText={
                              formik.touched.valueField &&
                              formik.errors.valueField
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
    </React.Fragment>
  );
};

export default FormStepTwo;
