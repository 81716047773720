import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Icon,
  Typography,
} from "@mui/material";
import {
  _STATE_FILTER,
  _TICKET_OWNER,
} from "../../../../utils/_tickets_constants";
import _ from "lodash";
import { _BTN } from "../../../../utils/_style_constants";
import { useContext, useEffect, useState } from "react";
import { TicketsListContext } from "../../../../contexts/TicketsListContext";
import { RequestHelper } from "../../../../utils/requestHelper";
import { UserContext } from "../../../../contexts/UserContext";
import { _ROUTES } from "../../../../utils/_routes_constants";
import { useLocation } from "react-router-dom";
import { _ACTIONS } from "../../../../utils/constants";

export const FilterButtons = () => {
  // Contexts
  const {
    stateFilter,
    ownerFilter,
    setStateFilter,
    setOwnerFilter,
    setRefresh,
  } = useContext(TicketsListContext);
  const { getUser } = useContext(UserContext);
  const user = getUser();
  // States
  const [stateCount, setStateCount] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  // Helpers
  const requestHelper = new RequestHelper();
  const location = useLocation();
  const tStatusFilter = location.state || null;
  // setter cette fonction pour filtrer sur le bon type de ticket
  const handleFilter = (filter) => {
    let newFilter = [];
    setStateFilter((prev) => {
      if (prev.includes(filter)) {
        if (prev.length === 1) {
          return prev;
        } else {
          return prev.filter((f) => f !== filter);
        }
      } else {
        return [...prev, filter];
      }
    });
  };

  useEffect(() => {
    let queryData = {};

    if (ownerFilter == _TICKET_OWNER.PERSO) {
      queryData = {
        ...queryData,
        user: user.id,
      };
    }

    requestHelper
      .doRequest(_ACTIONS.GET, _ROUTES.TICKETS_STATES, queryData)
      .then((res) => {
        setStateCount(res.data);
        setTotalCount(res.data.reduce((acc, s) => acc + s.total, 0));
      });
  }, [ownerFilter]);

  useEffect(() => {
    if (tStatusFilter) {
      setStateFilter([tStatusFilter.ticketStatus]);
      // tickerOwner = 0 => MEANS TO SHOW ALL TICKET
      if (tStatusFilter.ticketOwner === _TICKET_OWNER.ALL_INT) {
        setOwnerFilter(_TICKET_OWNER.ALL);
      }
      setRefresh((prev) => prev + 1);
    }
  }, []);

  return (
    <>
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
      >
        {Object.keys(_STATE_FILTER).map((key) => {
          const btn = _STATE_FILTER[key];
          const count = stateCount.filter((s) => s.status === btn.name)[0];
          let countValue = !_.isUndefined(count) ? count.total : 0;
          if (btn.name === _STATE_FILTER.ALL.name) {
            countValue = totalCount;
          }

          return (
            <>
              <Button
                size="small"
                key={key}
                sx={_BTN.FILTER_BTN}
                variant="contained"
                color={btn.color}
                onClick={() => handleFilter(btn.name)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={stateFilter.includes(btn.name)}
                    sx={{ color: "white" }}
                  ></Checkbox>
                  {btn.icon && <Icon>link_off</Icon>}
                  <Typography sx={{ fontSize: "10px" }}>
                    {btn.name}({countValue})
                  </Typography>
                </Box>
              </Button>
            </>
          );
        })}
      </ButtonGroup>
    </>
  );
};

export default FilterButtons;
