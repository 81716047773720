import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Card, Divider, Typography, Alert, Chip } from "@mui/material";
import { getDataOvh } from "../../../repository/telephonies/Ovh";
import { _ROUTES } from "../../../utils/_routes_constants";

const FaxAssociesCompte = ({ nbFaxs, group }) => {
    const text = "aucun Fax associé à ce compte de facturation...";
    const [faxs, setFaxs] = useState([]);

    useEffect(() => {
        getDataOvh(
            {billingAccount: group?.billingAccount}, 
            setFaxs, 
            "Une erreur est survenue lors de la récupération des fax associés à ce compte de facturation",
            _ROUTES.GROUP_OVH_FAX_LIST
        );
    }, []);
    
    const columns = useMemo(
        () => [
            {
                accessorKey: 'numero',
                header: 'Numéro',
                Cell: ({row}) => (
                    <>
                        {row.original.fax_id} 
                        <Chip 
                            style={{marginLeft:"10px"}} 
                            variant="outlined" 
                            size="small" 
                            color="primary"  
                            label ={row.original.fax_description} 
                        />
                    </>
                ),
            },
        ],
    );

    const options = {
        columns,
        data: faxs['rows'] ?? [],
        enableFullScreenToggle: true,
    };

    const table = useMaterialReactTable(options);

    return (
        <>
            <Card sx={{borderTop: 3, borderTopColor: "orange"}}>
                <Box>
                    <Typography sx={{ padding:"5px", fontSize: "1rem", backgroundColor: "orange"}}>
                        <strong>Fax associés à ce compte de facturation ({nbFaxs})</strong>
                    </Typography>
                </Box>
                <Divider sx={{background: "orange"}} />
                {nbFaxs !== 0 
                    ? (<MaterialReactTable table={table} />)
                    :(<Alert severity="info">{text}</Alert>)
                }   
            </Card>
        </>
    );
}

export default FaxAssociesCompte;