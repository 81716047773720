import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { CIVILITY, ROLES } from "../../utils/constants";
import { MdOutlineAttachEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { CiPhone, CiUser } from "react-icons/ci";
import { _ROUTER_DOM_URL } from "../../utils/_routes_constants";
import { useNavigate } from "react-router-dom";

/**
 * FormComponentUtilisateur component.
 *
 * @param {Object} props.currentUser - The current user object.
 * @param {Object} props.formik - The formik object form.
 * @param {Array} props.tiersList - The list of tiers.
 * @param {boolean} props.isEdit - Indicates whether the form is in edit mode.
 * @param {Function} props.searchSociete - The function to search for a societe.
 */
export function FormComponentUtilisateur({
  formik,
  tiersList,
  isEdit,
  searchSociete,
}) {
  const navigate = useNavigate();
  return (
    <>
      {/* Avatar */}
      <form id="userForm" onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} justifyContent="center" marginTop={5}>
          <Grid
            item
            xs={12}
            md={8}
            display={"flex"}
            gap={1}
            flexDirection={"column"}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>Role *</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <FormControl
                  fullWidth
                  error={formik.touched.roles && Boolean(formik.errors.roles)}
                >
                  <InputLabel id="roles-label" size="small">
                    Roles
                  </InputLabel>
                  <Select
                    labelId="roles-label"
                    id="roles"
                    value={formik.values.roles}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    name="roles"
                    size="small"
                  >
                    {Object.entries(ROLES).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.roles && formik.errors.roles ? (
                    <FormHelperText>{formik.errors.roles}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>
            {tiersList && (
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={2} sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography variant="body1" component="div">
                    <strong>Tiers *</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    /* data */
                    options={tiersList ? tiersList : []}
                    /* display label */
                    getOptionLabel={(option) => option && option.name}
                    defaultValue={tiersList.find(
                      (option) => option.id === formik.values.societe
                    )}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    onChange={(e, value) => {
                      if (value === null) {
                        formik.setFieldValue("societe", null);
                      } else {
                        formik.setFieldValue("societe", value.id);
                      }
                    }}
                    /* search */
                    onInputChange={(e, value) => {
                      searchSociete(value);
                    }}
                    loading={tiersList.length === 0}
                    renderInput={(params) => (
                      <TextField
                        disabled={tiersList.length === 0}
                        label={
                          tiersList.length === 0
                            ? "Chargement..."
                            : "Sélectionner une société"
                        }
                        fullWidth
                        name="societe"
                        sx={{
                          marginTop: "0px",
                        }}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                          formik.touched.societe && formik.errors.societe
                        )}
                        helperText={
                          formik.touched.societe ? formik.errors.societe : ""
                        }
                        {...params}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>Email *</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  size="small"
                  label="Email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdOutlineAttachEmail />
                      </InputAdornment>
                    ),
                  }}
                  type="email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.email &&
                    !formik.values.email &&
                    Boolean(formik.errors.email)
                  }
                  helperText={
                    formik.touched.email && !formik.values.email
                      ? formik.errors.email
                      : ""
                  }
                />
              </Grid>
            </Grid>
            {!isEdit && (
              <>
                <Grid container alignItems="center" spacing={2}>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    <Typography variant="body1" component="div">
                      <strong>Mot de passe *</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Mot de passe"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RiLockPasswordLine />
                          </InputAdornment>
                        ),
                      }}
                      type="password"
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    <Typography variant="body1" component="div">
                      <strong>Confirmation mot de passe *</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Confirmation mot de passe"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RiLockPasswordLine />
                          </InputAdornment>
                        ),
                      }}
                      type="password"
                      id="passwordConfirm"
                      name="passwordConfirm"
                      value={formik.values.passwordConfirm}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.passwordConfirm &&
                        !formik.values.passwordConfirm &&
                        Boolean(formik.errors.passwordConfirm)
                      }
                      helperText={
                        formik.touched.passwordConfirm &&
                        !formik.values.passwordConfirm
                          ? formik.errors.passwordConfirm
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>Civilité</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.civility && Boolean(formik.errors.civility)
                  }
                >
                  <InputLabel id="roles-label" size="small">
                    Civilité
                  </InputLabel>
                  <Select
                    labelId="civlity-label"
                    id="civility"
                    value={formik.values.civility}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    name="civility"
                    size="small"
                  >
                    {Object.entries(CIVILITY).map(([key, value]) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>Nom *</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  label="Nom"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CiUser />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName &&
                    !formik.values.firstName &&
                    Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && !formik.values.firstName
                      ? formik.errors.firstName
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>Prénom *</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  label="Prénom"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CiUser />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName &&
                    !formik.values.lastName &&
                    Boolean(formik.errors.lastName)
                  }
                  helperText={
                    formik.touched.lastName && !formik.values.lastName
                      ? formik.errors.lastName
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>Poste/Fonction</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  label="Poste/Fonction"
                  size="small"
                  type="text"
                  id="poste"
                  name="poste"
                  value={formik.values.poste}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>Téléphone</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  label="Téléphone"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CiPhone />
                      </InputAdornment>
                    ),
                  }}
                  type="phone"
                  id="phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>Mode de DateTimePicker</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Select
                  fullWidth
                  labelId="dateTimePickerMode"
                  id="dateTimePickerMode"
                  value={formik.values.dateTimePickerMode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  name="dateTimePickerMode"
                  size="small"
                >
                  <MenuItem value="mobile">Mobile (A la souris)</MenuItem>
                  <MenuItem value="desktop">Desktop (Au clavier)</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"space-between"} marginTop={5}>
          <Button
            variant="contained"
            size="small"
            color="inherit"
            onClick={() => {
              navigate(_ROUTER_DOM_URL.USER);
            }}
          >
            Retour
          </Button>
          <Button
            size="small"
            form="userForm"
            type="submit"
            variant="contained"
            color="success"
          >
            Enregistrer
          </Button>
        </Box>
      </form>
    </>
  );
}
