import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormControl } from "@mui/material";
import moment from "moment";

export const SelectYear = ({setAnnee}) => {

    return (
        <FormControl sx={{ m: 1, width: 300, mt:2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker 
                    label={"Année"} 
                    openTo="year" 
                    views={['year']} 
                    defaultValue={moment()}
                    onChange={(newValue) => {
                        setAnnee(newValue.format('YYYY'))
                    }}
            />
            </LocalizationProvider>
        </FormControl>
    );
};

export default SelectYear;