import * as Yup from "yup";
import { _ACTIONS, _HTTP } from "../../../../utils/constants";
import { RequestHelper } from "../../../../utils/requestHelper";
import _ from "lodash";
import { createToast } from "../../../../utils/toast";

export const _SELL_FORMIK = (
  setTicket,
  setOpenAddVente,
  ticketAction,
  sell
) => {
  const rq = new RequestHelper();
  const validationSchema = Yup.object().shape({
    dolProduct: Yup.string().required("Produit requis"),
    price: Yup.number().required("Prix requis").min(0, "Prix invalide"),
    quantity: Yup.number()
      .required("Quantité requise")
      .min(1, "Veuillez entrer au moins 1 produit"),
  });

  return {
    initialValues: {
      dolProduct: sell?.dolProduct ? sell?.dolProduct : "",
      description: sell?.description ? sell?.description : "",
      price: sell?.price ? sell?.price : "",
      quantity: sell?.quantity ? sell?.quantity : 1,
      serialNumber: sell?.serialNumber ? sell?.serialNumber : "",
      costPrice: sell?.costPrice ? sell?.costPrice : "",
      tvaTx: sell?.tvaTx ? sell?.tvaTx : "",
      dolLine: 0,
      ticketAction: sell?.ticketAction
        ? sell.ticketAction["@id"]
        : ticketAction
        ? ticketAction["@id"]
        : "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log(values);
      const action =
        _.isNull(sell) || _.isUndefined(sell) ? _ACTIONS.POST : _ACTIONS.PUT;
      const message =
        _.isNull(sell) || _.isUndefined(sell)
          ? "Vente ajoutée avec succès"
          : "Vente modifiée avec succès";
      const url =
        _.isNull(sell) || _.isUndefined(sell)
          ? "ticket-purchases"
          : `ticket-purchases/${sell.id}`;

      rq.doRequest(action, url, values).then((res) => {
        if ([_HTTP.ok, _HTTP.created].includes(res.status)) {
          createToast("success", message);
          if (_.isNull(sell) || _.isUndefined(sell)) {
            // Updating ticket state
            setTicket((prev) => {
              return {
                ...prev,
                purchases: [...prev.purchases, res.data],
              };
            });
          } else {
            // Updating ticket state
            setTicket((prev) => {
              return {
                ...prev,
                purchases: prev.purchases.map((purchase) => {
                  if (purchase.id === res.data.id) {
                    return res.data;
                  }
                  return purchase;
                }),
              };
            });
          }
          setOpenAddVente(false);
        } else {
          createToast(
            "error",
            "Erreur lors de l'ajout/modification de la réservation"
          );
        }
      });
    },
  };
};
