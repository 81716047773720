import { Box, Button } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useEffect, useMemo, useState, useContext } from "react";
import Chart from "./Chart";
import { _ROUTES } from "../../../utils/_routes_constants";
import { getDataStat } from "../../../repository/Stats";
import { _STAT_EQUIPE_NB_HEURES } from "../../../utils/constants";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv'; 
import { exportDataDayEquipe } from './makeData';
import { UserContext } from "../../../contexts/UserContext";
import useHasRoles from "../../../hooks/useHasRole";
import { _ROLES } from "../../../utils/constants";

const TableEquipeDay = ({invoiceTypes}) => {
    const [datas, setDatas] = useState({});
    const {getUser} = useContext(UserContext);
    const user = getUser();
    const canAccess = useHasRoles(user, [_ROLES.ADMIN]);
    const userId = canAccess ? null : user.id;

    useEffect(() => {
            getDataStat(
                {invoiceTypes: invoiceTypes, userId: userId},
                setDatas,
                "Une erreur est survenue lors de la récupération des données",
                _ROUTES.STAT_EQUIPE_DAY 
            );
    }, [invoiceTypes]);

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
      }
    );

    const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(exportDataDayEquipe(rowData));
        download(csvConfig)(csv);
    };
    
    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(exportDataDayEquipe(datas));
        download(csvConfig)(csv);
    };
   
    const columns = useMemo(
        () => [
            {
                accessorKey: 'user',
                header: 'Équipe',
            },
            {
                accessorKey: 'hjour',
                header: 'H par jour (' + _STAT_EQUIPE_NB_HEURES.day +'hrs/jour)',
                Cell: ({row}) => (
                    (row.original.day === undefined) ? <></> :
                    <>
                        <Chart type={'DF'} data={row.original.day.facturable} max={_STAT_EQUIPE_NB_HEURES.day*60} />
                        <Chart type={'DR'} data={row.original.day.reef} max={_STAT_EQUIPE_NB_HEURES.day*60} />
                    </>
                ),
            },
            {
                accessorKey: 'hsemaine',
                header: 'H par semaine (' + _STAT_EQUIPE_NB_HEURES.week + 'hrs/semaine)',
                Cell: ({row}) => (
                    (row.original.week === undefined) ? <></> :
                    <>
                        <Chart type={'DF'} data={row?.original.week.facturable} max={_STAT_EQUIPE_NB_HEURES.week*60} />
                        <Chart type={'DR'} data={row.original.week.reel} max={_STAT_EQUIPE_NB_HEURES.week*60} />
                    </>
                ),
            },
            {
                accessorKey: 'hmois',
                header: 'H par mois (' + _STAT_EQUIPE_NB_HEURES.week + 'hrs/mois)',
                Cell: ({row}) => (
                    (row.original.month === undefined) ? <></> :
                    <>
                        <Chart type={'DF'} data={row.original.month.facturable}  max={_STAT_EQUIPE_NB_HEURES.week*60} />
                        <Chart type={'DR'} data={row.original.month.reel} max={_STAT_EQUIPE_NB_HEURES.week*60} />
                    </>
                ),
            },
            {
                accessorKey: 'han',
                header: 'H par an (' + _STAT_EQUIPE_NB_HEURES.year + 'hrs/an)',
                Cell: ({row}) => (
                    (row.original.year === undefined) ? <></> :
                    <>
                        <Chart type={'DF'} data={row.original.year.facturable} max={_STAT_EQUIPE_NB_HEURES.year*60} />
                        <Chart type={'DR'} data={row.original.year.reel} max={_STAT_EQUIPE_NB_HEURES.year*60} />
                    </>
                ),
            },
        ],
    );

    const options = {
        columns: columns,
        data: datas ?? [],
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
              >
                Export All Data
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Page Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Selected Rows
              </Button>
            </Box>
          ),
    };

    const table = useMaterialReactTable(options);

    return (
        <Box sx={{mt: 2}}>
            <MaterialReactTable table={table}  />
        </Box>
    );
};

export default TableEquipeDay;