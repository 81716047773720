import { Home } from "@mui/icons-material";
import {
  CheckBox,
  FileOpenOutlined,
  CalendarMonthOutlined,
  BugReportOutlined,
  CheckOutlined,
  AccessTimeOutlined,
  RingVolumeOutlined,
  PermDataSettingOutlined,
  SourceOutlined,
  Inventory2Outlined,
  MailOutlined,
  Euro,
  SettingsOutlined,
  PersonOutlined,
  EuroOutlined,
  StackedLineChartOutlined,
  PeopleOutline,
  PhoneOutlined,
  TravelExploreOutlined,
  PriorityHighOutlined,
} from "@mui/icons-material";
import { MdManageAccounts } from "react-icons/md";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { GrUserAdd } from "react-icons/gr";
import { GiChoice } from "react-icons/gi";


export const _MENU = [
  {
    title: "Tableau de bord",
    indexParent: 1,
    icon: <Home />,
    submenu: [
      {
        id: 1,
        title: "General",
        icon: <CheckOutlined />,
        path: "/dashboard-general",
      },
    ],
  },
  {
    title: "Gestion d'exploitation",
    indexParent: 2,
    icon: <CheckCircleOutlineIcon />,
    submenu: [
      {
        id: 2,
        title: "Antivirus",
        icon: <BugReportOutlined />,
        path: "/antivirus",
      },
      {
        id: 3,
        title: "Calendrier de réservation",
        icon: <CalendarMonthOutlined />,
        path: "/gestion-exploitation/gestion-reservation",
      },
      {
        id: 4,
        title: "Contrats",
        icon: <FileOpenOutlined />,
        path: "/gestion-exploitation/contrats",
      },
      {
        id: 5,
        title: "Décompte temps",
        icon: <AccessTimeOutlined />,
        path: "/decompte-temps/recap",
      },
      {
        id: 7,
        title: "Téléphonie OVH",
        icon: <RingVolumeOutlined />,
        path: "/telephonie-ovh"
      },
    ],
  },
  {
    title: "Gestion de transition",
    indexParent: 3,
    icon: <RepeatOutlinedIcon />,
    submenu: [
      {
        id: 8,
        title: "Actif et configurations",
        icon: <PermDataSettingOutlined />,
        path: "/actifs_configuration/list",
      },
      {
        title: "Base de connaissances",
        icon: <SourceOutlined />,
        path: "/base-connaissance/dashboard",
      },
    ],
  },
  {
    title: "Gestion de conception",
    indexParent: 4,
    icon: <ConstructionOutlinedIcon />,
    submenu: [
      {
        id: 9,
        title: "Base de connaissance",
        icon: <SourceOutlined />,
        path: "/base-connaissance/configuration/dashboard",
      },
      {
        id: 10,
        title: "Contrats",
        icon: <FileOpenOutlined />,
        path: "/contrats",
      },
      {
        id: 11,
        title: "Décompte temps",
        icon: <AccessTimeOutlined />,
        path: "/decompte-temps",
      },
      {
        id: 12,
        title: "Produits | Services",
        icon: <Inventory2Outlined />,
        path: "/produit-service/list",
      },
    ],
  },
  {
    title: "Gestion des ressources",
    indexParent: 5,
    icon: <InventoryOutlinedIcon />,
    submenu: [
      {
        id: 13,
        title: "Ressources",
        icon: <Inventory2Outlined />,
        path: "/resource-list",
      },
      {
        id: 14,
        title: "Reservations",
        icon: <CalendarMonthOutlined />,
        path: "/reservations",
      },
    ],
  },
  {
    title: "Gestion des tickets",
    indexParent: 6,
    icon: <FileOpenOutlined />,
    submenu: [
      {
        id: 15,
        title: "Tickets",
        icon: <FileOpenOutlined />,
        path: "/tickets/dashboard",
      },
      {
        id: 16,
        title: "Attribution de mails",
        icon: <MailOutlined />,
      },
    ],
  },
  {
    title: "Gestion de facturation",
    indexParent: 7,
    icon: <Euro />,
    submenu: [
      {
        id: 17,
        title: "Contrats",
        icon: <FileOpenOutlined />,
      },
      {
        id: 18,
        title: "Tickets",
        icon: <FileOpenOutlined />,
      },
      {
        id: 19,
        title: "Factures clients",
        icon: <FileOpenOutlined />,
      },
      {
        id: 20,
        title: "Facturation",
        icon: <FileOpenOutlined />,
      },
    ],
  },
  {
    title: "Administration",
    indexParent: 8,
    icon: <SupervisorAccountIcon />,
    submenu: [
      {
        id: 21,
        title: "Contacts",
        icon: <FileOpenOutlined />,
        path: "/admin-contact",
      },
      {
        id: 22,
        title: "Tiers",
        icon: <PersonOutlined />,
        path: "/societe-list",
      },
      {
        id: 23,
        title: "Utilisateur",
        icon: <PersonOutlined />,
        path: "/utilisateur",
      },
      {
        id: 24,
        title: "Réconciliation API",
        icon: <CheckBox />,
        path: "/reconciliation-api",
      },
    ],
  },
  {
    title: "Paramètre",
    indexParent: 9,
    icon: <SettingsOutlined />,
    submenu: [
      {
        id: 25,
        title: "Paramètre de prix",
        icon: <EuroOutlined />,
        path: "/prix-facturation",
      },
      {
        id: 25,
        title: "Paramétrage ALSO",
        icon: <EuroOutlined />,
        path: "/parametrage_also",
      },
      {
        id: 25,
        title: "Paramétrage Régie",
        icon: <MdManageAccounts size="1.5em" />,
        path: "/parametrage_regie",
      },
      {
        id: 25,
        title: "Paramétrage Location",
        icon: <GrUserAdd size="1.3em" />,
        path: "/parametrage_location",
      },
      {
        id: 25,
        title: "Paramétrage Offre",
        icon: <GiChoice size="1.3em" />,
        path: "/parametrage_offre",
      },
    ],
  },
  {
    title: "Statistiques",
    indexParent: 10,
    icon: <StackedLineChartOutlined />,
    submenu: [
      {
        id: 26,
        title: "Activité d'équipe",
        icon: <PeopleOutline />,
        path: "/stats/stat-equipe",
      },
      {
        id: 27,
        title: " Activité de téléphonie",
        icon: <PhoneOutlined />,
      },
      {
        id: 28,
        title: "Déplacement",
        icon: <TravelExploreOutlined />,
      },
      {
        id: 29,
        title: " Ticket par priorité",
        icon: <PriorityHighOutlined />,
      },
      {
        id: 30,
        title: " Ticket par type fact.",
        icon: <FileOpenOutlined />,
      },
      {
        id: 31,
        title: "Ticket par type",
        icon: <FileOpenOutlined />,
      },
      {
        id: 32,
        title: "Ticket par origine",
        icon: <FileOpenOutlined />,
      },
    ],
  },
];
