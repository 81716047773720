import { useFormik } from "formik";
import { RequestHelper } from "../../../../utils/requestHelper";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { useEffect, useState } from "react";
import { _LOAN_FORMIK } from "./AddLoanFormik";
import { _ROUTES } from "../../../../utils/_routes_constants";
import _ from "lodash";
import { _ACTIONS, _DATE_FORMAT } from "../../../../utils/constants";
import { __FORM_STYLES } from "../../../../utils/_style_constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export const AddLoanForm = ({ ticket, setTicket, setOpenLoanModal, loan = null }) => {
  const rq = new RequestHelper();
  const formik = useFormik(_LOAN_FORMIK(ticket, setTicket, setOpenLoanModal, loan));
  const [ressources, setRessources] = useState([]);

  useEffect(() => {
    rq.doRequest(_ACTIONS.GET, _ROUTES.RESSOURCES, {
      isAvaible: 1,
      isDeleted: 0,
      pagination: 0,
      "order[type]": "ASC",
    }).then((res) => {
      const data = res.data["hydra:member"];

      setRessources(data);
    });
  }, []);

  return (
    <>
      <Box> 
        <form onSubmit={formik.handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
            <Grid container sx={{ display: "flex" }}>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <Autocomplete
                    size="small"
                    name="resource"
                    label="Ressource"
                    options={ressources}
                    getOptionLabel={(option) => {
                      return option.type
                        ? option.type
                        : "" + " - " + option.brand
                        ? option.brand
                        : "" + " - " + option.reference
                        ? option.reference
                        : "" + " - " + option.serialNumber
                        ? option.serialNumber
                        : "";
                    }}
                    value={
                      ressources.find((ressource) => {
                        return ressource["@id"] === formik.values.resource;
                      }) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "resource",
                        newValue ? newValue["@id"] : ""
                      );
                    }}
                    onBlur={() => {}}
                    renderInput={(params) => (
                      // Render a TextField input with the provided label and params
                      <TextField
                        name="resource"
                        label="Ressource"
                        sx={{}}
                        error={Boolean(formik.errors.resource)}
                        {...params}
                      />
                    )}
                  ></Autocomplete>
                  {formik.errors.resource ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.resource}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <TextField
                    fullWidth
                    label="Quantité"
                    name="quantity"
                    size="small"
                    inputProps={{ type: "number" }}
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.quantity)}
                  />
                  {formik.errors.quantity ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.quantity}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <Box
                  sx={{
                    margin: "5px",
                  }}
                >
                  <Typography variant="h6">Description</Typography>
                  <CKEditor
                    editor={Editor}
                    config={{ resizeEnabled: true }}
                    data={formik.values.description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      formik.setFieldValue("description", data);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <DatePicker
                    size="small"
                    label="Date de début *"
                    name="startDate"
                    format={_DATE_FORMAT.date_display} 
                    value = {formik.values.startDate}
                    onChange={(value) => {
                      formik.setFieldValue("startDate", value);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: Boolean(formik.errors.startDate),
                      },
                    }}
                  />
                  {formik.errors.startDate ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.startDate}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <DatePicker
                    size="small"
                    label="Date de retour"
                    name="endDate"
                    format={_DATE_FORMAT.date_display} 
                    value={formik.values.endDate}
                    onChange={(value) => {
                      formik.setFieldValue("endDate", value);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  /> 
                </FormControl>
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid
                item
                xs={10}
                sx={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setOpenLoanModal(false);
                  }}
                  variant="contained"
                >
                  <Typography>Annuler</Typography>
                </Button>
                <Button type="submit" variant="contained">
                  <Typography>Valider</Typography>
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
      </Box>
    </>
  );
};

export default AddLoanForm;
