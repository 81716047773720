import { isNull } from "lodash";
import { Typography } from "@mui/material";
import { _DATE_FORMAT } from "../../../../utils/constants";
import moment from "moment";
import { DateFormatter } from "../../../../utils/muiTableHelper";

export const logicielColumns = () => {
  return [
    {
      accessorKey: "societeProduitServiceLogiciel.posteName",
      header: "Poste associé",
      Cell: ({ row }) => {
        const posteName = row.original.societeProduitServiceLogiciel?.posteName;

        return <Typography>{isNull(posteName) ? "" : posteName}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceLogiciel.licenseOfficiel",
      header: "License",
      Cell: ({ row }) => {
        const licenseOfficiel =
          row.original.societeProduitServiceLogiciel?.licenseOfficiel;

        return (
          <Typography>
            {isNull(licenseOfficiel) ? "" : licenseOfficiel}
          </Typography>
        );
      },
    },
    {
      accessorKey: "societeProduitServiceLogiciel.licenseEmail",
      header: "Licence autre",
      Cell: ({ row }) => {
        const licenseEmail =
          row.original.societeProduitServiceLogiciel?.licenseEmail;

        return (
          <Typography>{isNull(licenseEmail) ? "" : licenseEmail}</Typography>
        );
      },
    },
    {
      accessorKey: "societeProduitServiceLogiciel.emailAssocier",
      header: "Email associé",
      Cell: ({ row }) => {
        const emailAssocier =
          row.original.societeProduitServiceLogiciel?.emailAssocier;

        return (
          <Typography>{isNull(emailAssocier) ? "" : emailAssocier}</Typography>
        );
      },
    },
    {
      accessorKey: "societeProduitServiceLogiciel.dateDownload",
      header: "Date de téléchargement",
      enableSorting: false,
      Cell: ({ row }) => {
        const dateDownload =
          row.original.societeProduitServiceLogiciel?.dateDownload;
        if (!dateDownload) {
          return "";
        }
        const momentDate = moment(dateDownload, _DATE_FORMAT.datetime_exact);

        return <DateFormatter momentDate={momentDate} />;
      },
    },
    {
      accessorKey: "societeProduitServiceLogiciel.dateInstall",
      header: "Date d'installation",
      enableSorting: false,
      Cell: ({ row }) => {
        const dateInstall =
          row.original.societeProduitServiceLogiciel?.dateInstall;
        if (!dateInstall) {
          return "";
        }
        const momentDate = moment(dateInstall, _DATE_FORMAT.datetime_exact);

        return <DateFormatter momentDate={momentDate} />;
      },
    },
  ];
};
