import React, { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { ToggleOff, ToggleOnOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { _COLORS } from "../../utils/constants";
import FormTitle from "../../components/helpers/FormTitle";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";
import { dataGridTheme } from "../../utils/DataGridBuilder";
import { CustomToolbar } from "../../utils/CustomToolbarDataGrid";
import {
  getDataRessource,
  deleteDataRessource,
  putDataRessource,
} from "../../repository/Ressource";

/**
 *
 * @returns JSX.Element
 */
export function RessourceDashboard() {
  /**
   * Retrieves the current reservations from the given row.
   *
   * @param {Object} row - The row object containing reservations.
   * @returns {Array} - An array of current reservations.
   */
  const getCurrentReservation = useCallback((row) => {
    const allReservations = row.reservations;
    const currentReservation = [];
    const datetimeNow = new Date();

    allReservations?.forEach((reservation) => {
      const startDate = new Date(reservation.startDate);
      const endDate = new Date(reservation.endDate);

      if (endDate) {
        if (datetimeNow > startDate && datetimeNow < endDate) {
          currentReservation.push(reservation);
        }
      } else {
        if (datetimeNow > startDate) {
          currentReservation.push(reservation);
        }
      }
    });

    return currentReservation;
  }, []); // DEPENDS OF ANY EXTERNAL VARIABLE

  /**
   * Calculates the quantity used for a given row.
   *
   * @param {Object} row - The row object.
   * @returns {number} The quantity used.
   */
  const calculateQuantityUsed = (row) => {
    const currentReservations = getCurrentReservation(row);

    return currentReservations.length;
  };

  const columns = [
    {
      field: "Type",
      headerName: "Type",
      width: 350,
      editable: false,
      valueGetter: (params) => {
        return params.row.type;
      },
      renderCell: (params) => {
        return <Typography fontWeight={"bold"}>{params.value}</Typography>;
      },
    },
    {
      field: "brand",
      headerName: "Marque",
      width: 250,
      valueGetter: (params) => {
        return params.row.brand;
      },
      editable: false,
    },
    {
      field: "reference",
      headerName: "Référence",
      width: 250,
      valueGetter: (params) => {
        return params.row.reference;
      },
      editable: false,
    },
    {
      field: "serialNumber",
      headerName: "N° de série",
      type: "number",
      width: 150,
      valueGetter: (params) => {
        return params.row.serialNumber;
      },
      editable: false,
    },
    {
      field: "quantityUsed",
      headerName: "Quantité utilisée (aujourd'hui)",
      type: "number",
      width: 110,
      editable: false,
      renderCell: (params) => {
        const quantityUsed = calculateQuantityUsed(params.row);
        return quantityUsed;
      },
    },
    {
      field: "quantity",
      headerName: "Quantité totale",
      type: "number",
      width: 110,
      valueGetter: (params) => {
        return params.row.quantity;
      },
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const currentResource = dataResource.find(
          (resource) => resource.id === id
        );
        const isCurrentlyReserved =
          getCurrentReservation(currentResource).length > 0;

        return [
          <IconButton
            title="Editer"
            className="textPrimary"
            color="inherit"
            onClick={() => navigate(`${_ROUTER_DOM_URL.RESSOURCE_FORM}/${id}`)}
          >
            <EditIcon />
          </IconButton>,
          <IconButton
            icon={<DeleteIcon />}
            title="Supprimer"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
            disabled={isCurrentlyReserved}
          >
            <DeleteIcon />
          </IconButton>,

          <IconButton
            title="Activer"
            color="inherit"
            onClick={() => handleChangeStatus(id, currentResource?.isAvailable)}
          >
            {currentResource?.isAvailable === true ? (
              <ToggleOnOutlined color="success" />
            ) : (
              <ToggleOff color="warning" />
            )}
          </IconButton>,
        ];
      },
    },
  ];
  const [dataResource, setDataResource] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getDataRessource(
      { pagination: "disabled" },
      setDataResource,
      "Une erreur est survenue lors de la récupération des données",
      _ROUTES.RESSOURCES
    );
  }, []);

  const handleDeleteClick = async (id) => {
    const isDeleted = () => {
      setDataResource((prevDataResource) =>
        prevDataResource.filter((item) => item.id !== id)
      );
    };
    deleteDataRessource(
      "Une erreur est survenue lors de la suppression",
      `${_ROUTES.RESSOURCES}/${id}`,
      isDeleted
    );
  };

  const handleChangeStatus = (id, isAvailable) => {
    const isUpdated = () => {
      setDataResource((prevDataResource) =>
        prevDataResource.map((item) =>
          item.id === id ? { ...item, isAvailable: !isAvailable } : item
        )
      );
    };
    putDataRessource(
      { isAvailable: !isAvailable },
      "Une erreur est survenue lors de la mise à jour de la ressource",
      `${_ROUTES.RESSOURCES}/${id}`,
      isUpdated
    );
  };

  return (
    <ThemeProvider theme={dataGridTheme}>
      <FormTitle title="Gestion des ressources">
        <Typography variant="body2">
          (Matériel d'emprunt et de réservation)
        </Typography>
      </FormTitle>
      <div>
        <Button
          variant="contained"
          color="success"
          onClick={() => navigate(_ROUTER_DOM_URL.RESSOURCE_FORM)}
          style={{ margin: 10 }}
        >
          Ajouter
        </Button>
      </div>
      <Box height={650} sx={{ backgroundColor: _COLORS.white }}>
        <DataGrid
          rows={dataResource.map((item) => ({ ...item, key: item.id }))}
          columns={columns}
          loading={dataResource.length === 0}
          pageSizeOptions={[20, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </ThemeProvider>
  );
}
