import { Box } from "@mui/material";
import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

/**
 * A container component for displaying tables.
 *
 * @component
 * @param {ReactNode} props.children - The content to be rendered inside the container.
 * @param {Object} props.sx - The custom styles to be applied to the container.
 * @returns {JSX.Element} The rendered MuiTableContainer component.
 */
const MuiTableContainer = ({ children, sx, ...props }) => {
  const { open } = useContext(GlobalContext);
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: open ? window.innerWidth - 340 : window.innerWidth - 64,
        borderRadius: "1px",
        overflowX: "auto",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default MuiTableContainer;
