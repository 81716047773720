import React, { useEffect, useState } from "react";
import { HiOutlineUserGroup } from "react-icons/hi";
import FormTitle from "../../components/helpers/FormTitle";
import { Box, Button, Typography } from "@mui/material";
import { RequestHelper } from "../../utils/requestHelper";
import Loader from "../../components/utils/Loader";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { _ACTIONS } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { _ROUTES, _ROUTER_DOM_URL } from "../../utils/_routes_constants";
import { createToast } from "../../utils/toast";
import ColonnesUtilisateur from "../../components/admin-utilisateur/ColonnesUtilisateur";
import DataGridBuilder from "../../utils/DataGridBuilder";
import { sortingParams } from "../../utils/function";
import MuiTableContainer from "../../components/utils/MuiTableContainer";

export default function Utilisateur() {
  // States -
  const [users, setUsers] = useState(); // users data
  const [valueSearch, setValueSearch] = useState(""); // global search
  const [pageState, setPageState] = useState({
    totalRows: 0,
    page: 1,
    pageSize: 20,
  });
  const [sortParams, setSortParams] = useState({}); // sorting
  // Variables -
  const rq = new RequestHelper();
  let Navigate = useNavigate();

  const columnsUsers = ColonnesUtilisateur({
    users,
    setUsers,
  });

  useEffect(() => {
    rq.doRequest(_ACTIONS.GET, _ROUTES.USER, {
      page: pageState.page,
      order: { [sortParams.field]: sortParams.sort },
    })
      .then((response) => {
        if (200 !== response.status) {
          throw new Error(
            "Une erreur est survenue lors de la récupération des données de l'utilisateur"
          );
        }
        setUsers(response.data["hydra:member"]);
        setPageState((old) => ({
          ...old,
          totalRows: response.data["hydra:totalItems"],
        }));
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  }, [pageState.page, pageState.pageSize, sortParams]);

  if (!users) {
    return <Loader />;
  }

  /**
   * Updates the search value, makes a request to fetch user data based on the search value,
   * and updates the state with the fetched user data and total count.
   *
   * @param {Event} event - The change event object.
   */
  function handleSearchChange(event) {
    const searchValue = event.target.value;
    setValueSearch(searchValue);
    rq.doRequest(_ACTIONS.GET, _ROUTES.USER, {
      commonSearchFilter: searchValue,
      pagination: "disabled",
    })
      .then((response) => {
        if (200 !== response.status) {
          throw new Error(
            "Une erreur est survenue lors de la récupération des données de l'utilisateur"
          );
        }
        setUsers(response.data["hydra:member"]);
        setPageState((old) => ({
          ...old,
          total: response.data["hydra:totalItems"],
        }));
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  }

  /**
   * Sorts the column based on the provided parameters.
   *
   * @param {any} params - The parameters used for sorting.
   */
  const sortColumn = (params) => {
    const sortParams = sortingParams(params);
    setSortParams(sortParams);
  };

  return (
    <>
      <FormTitle title="Gestion des utilisateurs" />
      <Box
        width={"100%"}
        p={2}
        sx={{
          backgroundColor: "white",
        }}
      >
        <Box
          width={"100%"}
          display={"flex"}
          gap={1}
          alignItems={"baseline"}
          sx={{
            borderBottom: "1px solid #ccc",
          }}
        >
          <HiOutlineUserGroup />
          <Typography variant="h6" component="div">
            Liste des utilisateurs
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            size="small"
            title={"Nouveau utilisateur"}
            startIcon={<MdOutlineAddToPhotos />}
            color="success"
            onClick={() => {
              Navigate(_ROUTER_DOM_URL.USER_CREATE);
            }}
          >
            {"Nouveau utilisateur"}
          </Button>
        </Box>
        <MuiTableContainer marginTop={2}>
          <DataGridBuilder
            rows={users}
            columns={columnsUsers}
            pageInfo={pageState}
            sortColumn={sortColumn}
            onSearchChange={{
              value: valueSearch,
              onChange: handleSearchChange,
            }}
            onPaginationChange={({ page, pageSize }) => {
              setPageState((oldState) => ({
                ...oldState,
                page,
                pageSize,
              }));
            }}
          />
        </MuiTableContainer>
      </Box>
    </>
  );
}
