import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { createTheme } from "@mui/material";
import TiersClient from "../components/admin-tiers/TiersClient";
import { ThemeProvider } from "@emotion/react";
import { _DOLIBARR, _TIERS } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { _ROUTER_DOM_URL } from "../utils/_routes_constants";

export const tiersTheme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: "#3c8dbc",
      grey: {
        color: "#777a7f",
      },
      lightBlue: { backgroundColor: "#4fc1ef" },
    },
  },
});

/**
 * Renders a data grid component within a tab panel.
 *
 * @param {ReactNode} props.children - The content to be rendered within the tab panel.
 * @param {number} props.value - The currently selected tab index.
 * @param {number} props.index - The index of the tab panel.
 * @param {Object} props.other - Additional props to be spread onto the root div element.
 */
function TabsDataGrid(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabsDataGrid.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/**
 * Returns the accessibility props for a tab.
 *
 * @param {number} index - The index of the tab.
 * @returns {Object} - The accessibility props for the tab.
 */
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * Renders the Tiers screen. (Client and Fournisseur)
 *
 * @returns {JSX.Element} - The rendered Tiers screen.
 */
export default function Tiers() {
  const [value, setValue] = useState(0);
  const [typeOfTiers, setTypeOfTiers] = useState();
  const navigate = useNavigate();

  /**
   * Handles the tab change event.
   *
   * @param {Object} event - The event object.
   * @param {number} newValue - The new value of the tab.
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(_ROUTER_DOM_URL.SOCIETE);
  };

  return (
    <>
      <ThemeProvider theme={tiersTheme}>
        <Box display={"flex"} alignItems={"baseline"} gap={1}>
          <Typography variant="h5">{_TIERS.h1}</Typography>
          <Typography sx={{ color: tiersTheme.palette.primary.grey }}>
            {_TIERS.subh1}
          </Typography>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              backgroundColor: tiersTheme.palette.primary.lightBlue,
              borderBlockStartColor: "2px solid #3c8dbc",
              color: "white",
              width: "100%",
              alignItems: "center",
              height: "2rem",
              borderRadius: 1,
              marginTop: 1,
              paddingLeft: 2,
            }}
          >
            <InfoOutlinedIcon sx={{ marginRight: 1, color: "white" }} />
            <strong>{_DOLIBARR.m1}</strong>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="tabs">
              <Tab label=<strong>Client</strong> {...a11yProps(0)} />
              <Tab label=<strong>Fournisseur</strong> {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabsDataGrid value={value} index={0}>
            <TiersClient typeOfTiers={typeOfTiers} />
          </TabsDataGrid>
          <TabsDataGrid value={value} index={1}>
            <TiersClient typeOfTiers={false} />
          </TabsDataGrid>
        </Box>
      </ThemeProvider>
    </>
  );
}
