import React, { useEffect, useState } from "react";
import { RequestHelper } from "../../utils/requestHelper";
import { useNavigate, useParams } from "react-router-dom";
import { PRODUIT_SERVICE, _ACTIONS } from "../../utils/constants";
import { createToast } from "../../utils/toast";
import FormTitle from "../../components/helpers/FormTitle";
import { Grid, IconButton, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ConfigurationTable from "../../components/produit-service/ConfigurationTable";
import FormProduitServiceConfig from "../../components/produit-service/FormProduitServiceConfig";
import BasicModal from "../../components/helpers/BasicModal";
import { PiKeyReturnBold } from "react-icons/pi";
import ConfigurationApi from "../../components/produit-service/ConfigurationApi";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";

const ProduitServiceConfig = () => {
  const rq = new RequestHelper();
  const id = useParams().id;
  const [currentItem, setCurrentItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [newConfig, setNewConfig] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    rq.doRequest(_ACTIONS.GET, `${_ROUTES.PRODUIT_SERVICE}/${id}`).then(
      (res) => {
        if (res.status === 200) {
          setCurrentItem(res.data);
        } else {
          createToast(
            "error",
            "Une erreur est survenue lors de la récupération des données"
          );
        }
      }
    );
  }, []);

  return (
    <React.Fragment>
      <BasicModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={"Création d'une configuration"}
        content={<FormProduitServiceConfig setNewConfig={setNewConfig} />}
      />
      <FormTitle title={"Gestion des catalogues"}>
        <Typography variant="body2">{PRODUIT_SERVICE.subh1}</Typography>
      </FormTitle>
      <Grid container spacing={1} marginTop={3} width={"100%"}>
        <Grid item xs={12}>
          <IconButton
            title="Retour à la liste des produits | services"
            color="inherit"
            onClick={() => navigate(_ROUTER_DOM_URL.PRODUIT_SERVICE_LIST)}
          >
            <PiKeyReturnBold />
          </IconButton>
          <IconButton
            label="Ajouter une configuration"
            title="Ajouter une configuration"
            color="primary"
            onClick={() => setShowModal(true)}
          >
            <AddBoxIcon />
          </IconButton>
        </Grid>
        <Grid item display={"flex"} flexDirection={"row"} gap={4} xs={6}>
          <ConfigurationTable
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            newConfig={newConfig}
          />
        </Grid>
        <Grid item xs={6}>
          {currentItem && currentItem.hebergementApi === true && (
            <ConfigurationApi currentItem={currentItem} hebergement={true} />
          )}
          {currentItem && currentItem.bitDefenderApi === true && (
            <ConfigurationApi currentItem={currentItem} bitdefender={true} />
          )}
          {currentItem && currentItem.logicielApi === true && (
            <ConfigurationApi currentItem={currentItem} logiciel={true} />
          )}
          {currentItem && currentItem.firewallApi === true && (
            <ConfigurationApi currentItem={currentItem} firewall={true} />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={3} width={"50%"}></Grid>
    </React.Fragment>
  );
};

export default ProduitServiceConfig;
