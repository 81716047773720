import { Box, Typography } from "@mui/material";
import TicketEditInfo from "../edit/TicketEditInfo";
import TicketListContextProvider from "../../../contexts/TicketsListContext";
import { useEffect, useState } from "react";
import TicketCreateToolBar from "./TicketCreateToolBar";
import { _DEFAULT_TICKET } from "./TicketCreateUtils";
import TicketEditTiers from "../edit/TicketEditTiers";
import TicketAutres from "../edit/TicketAutres";
import TicketCreateDescription from "./TicketCreateDescription";
import { TicketCreateLoans } from "./TicketCreateLoans";
import { TicketCreateActions } from "./TicketCreateActions";
import { TicketCreateContratsBox } from "./TicketCreateContratsBox";

export const CreateTicket = () => {
  const [ticket, setTicket] = useState(_DEFAULT_TICKET);
  const [formData, setFormData] = useState({
    ticketFiles: [],
    actionFiles: [],
    ticket: ticket,
  });
  const [refresh, setRefresh] = useState(0);
  const [actionsExpanded, setActionsExpanded] = useState(false);
  const [listTechniciens, setListTechniciens] = useState([]);
  const [listProduitsServices, setListProduitsServices] = useState([]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ticket: ticket,
    }));
  }, [ticket]);

  return (
    <>
      <TicketListContextProvider>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <Typography variant="h5">Ouverture d'un ticket</Typography>
            <TicketCreateToolBar
              ticket={ticket}
              setTicket={setTicket}
              setActionsExpanded={setActionsExpanded}
            />
          </Box>
          <TicketCreateContratsBox ticket={ticket} setTicket={setTicket} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "25%",
              }}>
              <TicketEditInfo
                ticket={ticket}
                setTicket={setTicket}
                refresh={refresh}
                setRefresh={setRefresh}
                edit={false}
                listTechniciens={listTechniciens}
                setListTechniciens={setListTechniciens}
                listProduitsServices={listProduitsServices}
                setListProduitsServices={setListProduitsServices}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "75%",
                paddingLeft: "50px",
              }}>
              <TicketEditTiers ticket={ticket} setTicket={setTicket} />
              <TicketAutres ticket={ticket} />
              <TicketCreateDescription
                ticket={ticket}
                setTicket={setTicket}
                actionsExpanded={actionsExpanded}
                setActionsExpanded={setActionsExpanded}
                formData={formData}
                setFormData={setFormData}
              />
              <TicketCreateLoans ticket={ticket} setTicket={setTicket} />
              <TicketCreateActions
                ticket={ticket}
                setTicket={setTicket}
                actionsExpanded={actionsExpanded}
                setActionsExpanded={setActionsExpanded}
                listTechniciens={listTechniciens}
                listProduitsServices={listProduitsServices}
                setListProduitsServices={setListProduitsServices}
                formData={formData}
                setFormData={setFormData}
              />
            </Box>
          </Box>
        </Box>
      </TicketListContextProvider>
    </>
  );
};

export default CreateTicket;
