import { useFormik } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS } from "../../utils/constants";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { createToast } from "../../utils/toast";
import { _ROUTES } from "../../utils/_routes_constants";

export function ParametrageClient() {
  const [companies, setCompanies] = useState([]);
  const [mainCompagny, setMainCompagny] = useState("");
  const [compagnyMerged, setCompagnyMerged] = useState();
  const rq = new RequestHelper();

  useEffect(() => {
    rq.doRequest(_ACTIONS.GET, "${_ROUTES.TMP_ALSO}").then((res) => {
      let data = res.data["hydra:member"];
      setCompanies(data);
    });
  }, []);

  const filteredCompanies = async (value, id) => {
    let queryParameters = {};
    if (value) {
      queryParameters["nameCompagny"] = value;
    }
    rq.doRequest(_ACTIONS.GET, "${_ROUTES.TMP_ALSO}", queryParameters).then(
      (res) => {
        let data = res.data["hydra:member"];
        if (id === "main") {
          setMainCompagny(data);
        } else if (id === "merge") {
          setCompagnyMerged(data);
        }
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      firstCustomer: "",
      othersAccountIds: [],
    },
    validationSchema: Yup.object({
      firstCustomer: Yup.string().required("Séléctionner un client"),
      othersAccountIds: Yup.string().required("Séléctionner un client"),
    }),
    onSubmit: (values) => {
      let parentId = companies.find(
        (item) => item.compagny === values.firstCustomer
      ).id;
      let tmpArray = [];
      tmpArray.push(values.othersAccountIds);
      let parent = {
        parent: true,
        othersAccountIds: tmpArray,
      };
      rq.doRequest(
        _ACTIONS.PUT,
        `${_ROUTES.TMP_ALSO}/${parentId}`,
        parent
      ).then((res) => {
        if (res.status !== 200) {
          createToast("error", "Erreur lors de la fusion");
        }
        createToast("success", "Fusion effectuée avec succès");
      });
      let childrenId = companies.find(
        (item) => item.compagny === values.othersAccountIds
      ).id;
      rq.doRequest(_ACTIONS.PUT, `${_ROUTES.TMP_ALSO}/${childrenId}`, {
        parent: false,
      }).then((res) => {
        if (res.status !== 200) {
          createToast("error", "Erreur lors de la fusion");
        }
        createToast("success", "Fusion effectuée avec succès");
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} display={"flex"} flexDirection={"column"}>
          <Grid item xs={12}>
            <Autocomplete
              value={
                companies.find(
                  (c) => c.compagny === formik.values.firstCustomer
                ) || null
              }
              size="small"
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  "firstCustomer",
                  newValue ? newValue.compagny : ""
                );
              }}
              onInputChange={(event, newValue) => {
                filteredCompanies(newValue, "main");
              }}
              options={companies}
              getOptionLabel={(option) => option.nameCompagny || ""}
              id="firstCustomer"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client principal"
                  error={
                    formik.touched.firstCustomer &&
                    Boolean(formik.errors.firstCustomer)
                  }
                  helperText={
                    formik.touched.firstCustomer && formik.errors.firstCustomer
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={
                companies.find(
                  (c) => c.compagny === formik.values.othersAccountIds
                ) || null
              }
              size="small"
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  "othersAccountIds",
                  newValue ? newValue.compagny : ""
                );
              }}
              onInputChange={(event, newValue) => {
                filteredCompanies(newValue, "merge");
              }}
              options={companies}
              getOptionLabel={(option) => option.nameCompagny || ""}
              id="othersAccountIds"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client à fusionner"
                  error={
                    formik.touched.othersAccountIds &&
                    Boolean(formik.errors.othersAccountIds)
                  }
                  helperText={
                    formik.touched.othersAccountIds &&
                    formik.errors.othersAccountIds
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button type="submit" variant="contained" color="inherit">
              Fusionner
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
