import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { _STATE_FILTER, _TICKET_OWNER } from "../utils/_tickets_constants";
import { getFiltersListFromAPI } from "../utils/ticket_utils";
import _ from "lodash";

export const TicketsListContext = createContext({});

/**
 * The context provider for the Ticket List.
 * Example of us for Material UI React Table.
 *
 * @param {Object} children The children components.
 * @returns
 */
function TicketListContextProvider({ children }) {
  /** -------------------------------- */
  // STATES NEEDED FOR MUI REACT TABLE //
  /** -------------------------------- */
  const [filtersList, setFiltersList] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  /** -------------------------------- */
  //  STATES CUSTOM FOR TICKET FILTERS //
  /** -------------------------------- */
  const [stateFilter, setStateFilter] = useState([_STATE_FILTER.AFFECTED.name]);
  const [ownerFilter, setOwnerFilter] = useState(_TICKET_OWNER.PERSO);

  const value = {
    stateFilter,
    setStateFilter,
    ownerFilter,
    setOwnerFilter,
    isLoaded,
    setIsLoaded,
    data,
    setData,
    rowCount,
    setRowCount,
    columnFilters,
    setColumnFilters,
    pagination,
    setPagination,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    isRefetching,
    setIsRefetching,
    refresh,
    setRefresh,
    filtersList,
  };

  // Get the Columns filters list from the API or window.localStorage
  useEffect(() => {
    if (_.isEmpty(filtersList)) {
      if (!window.localStorage.getItem("ticketFilterList")) {
        getFiltersListFromAPI();
      }

      const filterListJson = JSON.parse(
        window.localStorage.getItem("ticketFilterList")
      );

      setFiltersList(filterListJson);
    }
  }, []);

  return (
    <TicketsListContext.Provider value={value}>
      {children}
    </TicketsListContext.Provider>
  );
}

export default TicketListContextProvider;
