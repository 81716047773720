import { _ACTIONS, _HTTP } from "../utils/constants";
import { RequestHelper } from "../utils/requestHelper";
import { createToast } from "../utils/toast";
import _ from "lodash";

const requestHelper = new RequestHelper();

/**
 * Retrieves data for SocieteProduitService.
 * 
 * @param {object} params - The parameters for the request.
 * @param {function} actions - The callback function to handle the response data.
 * @param {string} errroMessage - The error message to display in case of failure.
 * @param {string} route - The route to send the request to.
 */
export const getDataSocieteProduitService = (params, actions, errorMessage, route) => {
    requestHelper
        .doRequest(_ACTIONS.GET, `${route}`, params)
        .then((response) => {
            if (_HTTP.ok !== response.status) {
                throw new Error(errorMessage);
            }
            _.isUndefined(response.data["hydra:member"]) ? actions(response.data) : actions(response.data["hydra:member"]);
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};


/**
 * Updates data for SocieteProduitService.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string} errroMessage - The error message to throw if the response status is not ok.
 * @param {string} route - The route for the request.
 * @param {Function} actions - Optional callback function to execute with the response data.
 */
export const putDataSocieteProduitService = (params, errroMessage, route, actions) => {
    requestHelper
        .doRequest(_ACTIONS.PUT, `${route}`, params)
        .then((response) => {
            if (_HTTP.ok !== response.status) {
                throw new Error(errroMessage);
            }
            createToast("success", "La modification a été mise à jour avec succès");
            if(actions){
                actions(response.data);
            }   
        })
        .catch((error) => {
                createToast("error", error.message);
        });
};

/**
 * Deletes data using the specified route and performs additional actions if provided.
 * Displays success or error toast messages based on the response.
 *
 * @param {string} errroMessage - The error message to display if the response status is not 204.
 * @param {string} route - The route to send the delete request to.
 * @param {Function} actions - Optional additional actions to perform after successful deletion.
 */
export const deleteDataSocieteProduitService = (errroMessage, route, actions) => {
    requestHelper
        .doRequest(_ACTIONS.DELETE, `${route}`)
        .then((response) => {
            if (_HTTP.noContent !== response.status) {
                throw new Error(errroMessage);
            }
            if(actions) {
                actions();
            }
            createToast("success", "Supprimé avec succès");
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};