export const _PATTERN_RESOURCE_FORM_FIELDS = [
  {
    name: "type",
    type: "text",
    label: "Type",
    placeholder: "Type",
    required: true,
  },
  {
    name: "brand",
    type: "text",
    label: "Marque",
    placeholder: "Marque",
    required: true,
  },
  {
    name: "quantity",
    type: "text",
    label: "Quantité",
    placeholder: "Quantité",
    required: true,
  },
  {
    name: "reference",
    type: "text",
    label: "Référence",
    placeholder: "Référence",
    required: true,
  },
  {
    name: "serialNumber",
    type: "text",
    label: "Numéro de série",
    placeholder: "Numéro de série",
    required: false,
  },
];
