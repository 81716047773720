import { Chip, Box } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useEffect, useMemo, useState, useContext } from "react";
import { ImAttachment } from "react-icons/im";
import ContratNbTickets from "./ContratNbTickets";
import { creditContrat, creditConsomme, prorata, decompteTemps, chiffreAffaire} from "./contratMaintenances";
import { NumericFormat } from "react-number-format";
import OldContrat from "./OldContrat";
import { _DATE_FORMAT, TYPE_ROLES } from "../../utils/constants";
import moment from "moment";
import { UserContext } from "../../contexts/UserContext";
import { _ROUTES } from "../../utils/_routes_constants";
import { getDataContrat } from "../../repository/contrats/Contrat";

const RecapitulatifContrats = () => {
    const {getUser } = useContext(UserContext);
    const user = getUser();
    const isTechnicien = user.roles.includes(TYPE_ROLES.ROLE_TECHNICIEN);
    const isAdmin = user.roles.includes(TYPE_ROLES.ROLE_ADMIN);
    const [datas, setDatas] = useState([]);
    const infos = [
        '* Prorata temporis contrat année en cours',
        'CA/min : Crédit annuel (contratctuel)',
        'DT : Décompte temps réel'
    ];

    let columnVisibilities = {societeName: false, caAnnuel: false};
    if(isTechnicien)columnVisibilities = {societeName: true, caAnnuel: false}
    if(isAdmin)columnVisibilities = {societeName: true, caAnnuel: true}
    

    const columns = useMemo(
        () => [
            {
                accessorKey: 'fileName',
                header: <ImAttachment />,
                size: 'small',
                Cell: ({ row }) => (
                    row.original.fileName 
                    ? <ImAttachment title={row.original.fileName} size="1.2em" color="blue" />
                    : ''
                ),
            },
            {
                accessorKey: 'ref',
                header: 'Réf.',
                size: 'small',
                Cell: ({ row }) => (
                    <>
                        <Chip size="small" color="primary" variant="outlined" label={row.original.refContrat} />
                    </>
                ),
            },
            {
                accessorKey: 'societeName',
                header: 'Tiers',
                Cell: ({ row }) => (
                    <>
                        {row.original.societe.name}
                    </>
                ),
            },
            {
                accessorKey: 'nbTickets',
                header: 'Nb de tickets',
                size: 'small',
                Cell: ({ row }) => (
                    <>
                        <ContratNbTickets societe={row.original.societe} contratId={row.original.id}  />
                    </>
                ),
            },
            {
                accessorKey: 'credit',
                header: 'Crédit ' + moment().add(-1, 'year').format(_DATE_FORMAT.year)+ ' /min',
                size: 'small',
                Cell: ({ row }) => (
                    <>
                        <OldContrat row={row} type="credit" />
                    </>
                ),
            },
            {
                accessorKey: 'ca',
                header: 'CA /min',
                size: 'small',
                Cell: ({ row }) => (
                    <>
                        {creditContrat(row)}  
                    </>
                ),
            },
            {
                accessorKey: 'consomme',
                header: 'Consommé /min',
                size: 'small',
                Cell: ({ row }) => (
                    <>
                        {creditConsomme(row)}  
                    </>
                ),
            },
            {
                accessorKey: 'prorata',
                header: '* Prorata',
                size: 'small',
                Cell: ({ row }) => (
                    <>
                        {prorata(row)}  
                    </>
                ),
            },
            {
                accessorKey: 'dt',
                header: 'DT /min',
                size: 'small',
                Cell: ({ row }) => (
                    <>
                        {decompteTemps(row)}  
                    </>
                ),
            },
            {
                accessorKey: 'solde',
                header: 'Solde /min',
                size: 'small',
                Cell: ({ row }) => (
                    <>
                        <OldContrat row={row} type="solde" />
                    </>
                ),
            },
            {
                accessorKey: 'caAnnuel',
                header: 'CA /annuel',
                size: 'small',
                Cell: ({ row }) => (
                    <>
                    <Box sx={{textAlign:"right"}}>
                        <NumericFormat 
                            value={chiffreAffaire(row)} 
                            thousandSeparator="," 
                            decimalSeparator="." 
                            decimalScale={2} 
                            displayType="text" 
                            fixedDecimalScale 
                        /> €
                    </Box>
                    </>
                ),
            },
        ],
    );

    useEffect(() => {
        if(datas.length !== 0) return;
        getDataContrat(
            {isDeleted: 0, isClotured: 0}, 
            setDatas, 
            "Une erreur est survenue lors de la récupération des données",
            _ROUTES.CONTRAT_MAINTENANCE
        );
    }, []);

    const options = {
        columns,
        data: datas ?? [],
        enableFullScreenToggle: true,
        initialState: {columnVisibility: columnVisibilities},
    }

    const table = useMaterialReactTable(options);
    

    return (
        <> 
            <Box sx={{ marginTop: 5, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>                                         
                {infos.map((info, key) => (
                    <Chip key={key} label={info} size="small" />
                )
                )}
            </Box>
            <Box sx={{ marginTop: 5 }}>
                <MaterialReactTable table={table} />
            </Box>
        </>
    );
};

export default RecapitulatifContrats;