const muiTableContainerProps = {
  sx: {
    height: "65vh",
  },
};
const muiTableBodyRowProps = {
  sx: {
    height: "10px",
    backgroundColor: "#ffff",
  },
};
const muiTableBodyCellProps = {
  sx: {
    p: "2px 16px",
  },
};
const muiTopToolbarProps = {
  sx: {
    backgroundColor: "#ffff",
  },
};
const muiTableHeadCellProps = {
  sx: {
    backgroundColor: "#ffff",
  },
};
const muiBottomToolbarProps = {
  sx: {
    backgroundColor: "#ffff",
  },
};

export const stylesMuiTable = {
  muiTableContainerProps,
  muiTableBodyRowProps,
  muiTableBodyCellProps,
  muiTopToolbarProps,
  muiTableHeadCellProps,
  muiBottomToolbarProps,
};
