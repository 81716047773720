import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TabsDataGrid from "../../components/utils/Tabs";
import { _COLORS, _RESERVATIONS } from "../../utils/constants";
import { _ROUTER_DOM_URL } from "../../utils/_routes_constants";
import { RéservationMuiTable } from "../../components/reservations/RéservationMuiTable";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function ReservationDashboard() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box display={"flex"} alignItems={"baseline"} gap={2}>
        <Typography variant="h5">{_RESERVATIONS.h1}</Typography>
        <Typography variant="body2" sx={{ color: _COLORS.grey }}>
          {_RESERVATIONS.subh1}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="success"
          onClick={() => navigate(_ROUTER_DOM_URL.CREATE_RESERVATION_INTERNE)}
          style={{ margin: 10 }}
        >
          {_RESERVATIONS.buttonInterne}
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => navigate(_ROUTER_DOM_URL.CREATE_RESERVATION_EXTERNE)}
          style={{ margin: 10 }}
        >
          {_RESERVATIONS.buttonExterne}
        </Button>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="tabs">
            <Tab
              key={0}
              label="Liste de toutes les réservations"
              {...a11yProps(0)}
            />
            <Tab
              key={1}
              label="Liste des réservations internes"
              {...a11yProps(1)}
            />
            <Tab
              key={2}
              label="Liste des réservations externes"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabsDataGrid key={0} value={value} index={0}>
          <h3>{_RESERVATIONS.resaEnCour}</h3>
          <RéservationMuiTable typeRéservation={2} isOldRéservation={false} />
          <h3>{_RESERVATIONS.resaTerminé}</h3>
          <RéservationMuiTable typeRéservation={2} isOldRéservation={true} />
        </TabsDataGrid>
        <TabsDataGrid key={1} value={value} index={1}>
          <h3>{_RESERVATIONS.resaEnCour}</h3>
          <RéservationMuiTable typeRéservation={0} isOldRéservation={false} />
          <h3>{_RESERVATIONS.resaTerminé}</h3>
          <RéservationMuiTable typeRéservation={0} isOldRéservation={true} />
        </TabsDataGrid>
        <TabsDataGrid key={2} value={value} index={2}>
          <h3>{_RESERVATIONS.resaEnCour}</h3>
          <RéservationMuiTable typeRéservation={1} isOldRéservation={false} />
          <h3>{_RESERVATIONS.resaTerminé}</h3>
          <RéservationMuiTable typeRéservation={1} isOldRéservation={true} />
        </TabsDataGrid>
      </Box>
    </>
  );
}
