import { Box, Button } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { memo } from "react";
import { _COLORS } from "../../../utils/constants";

/**
 * Renders a button component for creating a new article.
 *
 * @component
 * @param {function} navigate - The function to navigate to the base-connaissance-form.
 */
export const CreateArticleBtn = memo(({ navigate }) => {
  return (
    <Box
      sx={{
        p: 1,
        mb: 2,
        display: "flex",
        justifyContent: "center",
        boxShadow: `4px 4px 4px ${_COLORS.midGrey}`,
        border: `1px solid ${_COLORS.lightBlue}`,
      }}
    >
      <Button
        variant="contained"
        size="small"
        startIcon={<AddBoxIcon />}
        onClick={() => {
          navigate(`/base-connaissance-form`);
        }}
      >
        Créer un nouvel article
      </Button>
    </Box>
  );
});
