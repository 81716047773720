import { useEffect, useState } from "react";
import { getResources } from "../../../repository/resources/Resource";
import { Autocomplete, FormControl, InputLabel, TextField } from "@mui/material";

const Resources = ({resources, setResources, selectedResource, setSelectedResource}) => {
    
    useEffect(() => {
        if(resources.length !== 0) return;
        setResources(getResources(
            {isAvailable: true, isDeleted: false},
            setResources,
            "Erreur lors de la récupération des ressources"
        ))
    }, []);

    return (
        <>
            <FormControl fullWidth>
                <Autocomplete 
                    id="resource-select"
                    options={resources?.length > 0 ? resources : []}
                    getOptionLabel={(option) =>
                        option
                            ? `${option.type} ${option.brand} ${option.reference}`
                            : null

                    }
                    onChange={(event, newValue) => {
                        setSelectedResource(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Ressource"/>}
                />
            </FormControl>
        </>
    );
};

export default Resources;