import { _ACTIONS, _HTTP } from "../utils/constants";
import { RequestHelper } from "../utils/requestHelper";
import { createToast } from "../utils/toast";
import _ from "lodash";

const requestHelper = new RequestHelper();

/**
 * Retrieves data from a resource by making a GET request.
 *
 * @param {Object} params - The parameters to be sent in the request.
 * @param {Function} actions - Callback function to be executed with the response data.
 * @param {string} errroMessage - The error message to be thrown if the response status is not ok.
 * @param {string} route - The route to send the GET request to.
 * @returns {Promise} A promise that resolves with the response data if the request is successful, or rejects with an error if the request fails.
 */
export const getDataRessource = (params, actions, errorMessage, route) => {
    requestHelper
        .doRequest(_ACTIONS.GET, `${route}`, params)
        .then((response) => {
            if (_HTTP.ok !== response.status) {
                throw new Error(errorMessage);
            }
            _.isUndefined(response.data["hydra:member"]) ? actions(response.data) : actions(response.data["hydra:member"]);
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};


/**
 * Updates a resource by making a PUT request.
 *
 * @param {Object} params - The parameters to be sent in the request.
 * @param {string} errroMessage - The error message to be thrown if the response status is not ok.
 * @param {string} route - The route to send the PUT request to.
 * @param {Function} actions - Optional callback function to be executed with the response data.
 */
export const putDataRessource = (params, errroMessage, route, actions) => {
    requestHelper
        .doRequest(_ACTIONS.PUT, `${route}`, params)
        .then((response) => {
            if (_HTTP.ok !== response.status) {
                    throw new Error(errroMessage);
            }
            createToast("success", "La ressource a été mise à jour avec succès");
            if(actions){
                actions(response.data);
            }   
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};

/**
 * Posts data to the server and handles the response.
 *
 * @param {Object} params - The data to be sent to the server.
 * @param {string} errroMessage - The error message to be thrown if the response status is not ok or created.
 * @param {string} route - The route to which the data should be posted.
 * @param {Function} actions - Optional callback function to be executed with the response data.
 */
export const postDataRessource = (params, errroMessage, route, actions) => {
    requestHelper
        .doRequest(_ACTIONS.POST, `${route}`, params)
        .then((response) => {
            if (![_HTTP.ok, _HTTP.created].includes(response.status)) {
                throw new Error(errroMessage);
            }
            createToast("success", "Ressource enregistrée avec succès.");
            if(actions){
                actions(response.data);
            }   
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};


/**
 * Deletes data from a resource.
 * 
 * @param {string} errroMessage - The error message to throw if the response status is not 204.
 * @param {string} route - The route to send the delete request to.
 * @param {Function} actions - Optional callback function to execute after successful deletion.
 */
export const deleteDataRessource = (errroMessage, route, actions) => {
    requestHelper
        .doRequest(_ACTIONS.DELETE, `${route}`)
        .then((response) => {
            if (_HTTP.noContent !== response.status) {
                throw new Error(errroMessage);
            }
            if(actions) {
                actions();
            }
            createToast("success", "La ressource a été supprimée avec succès");
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};