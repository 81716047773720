import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS } from "../../utils/constants";
import Loader from "../utils/Loader";

function createData(ServiceName, ServiceId) {
  return { ServiceName, ServiceId };
}
export const TableClientAlso = ({ row }) => {
  const rq = new RequestHelper();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (row) {
      findProductByClient(row);
    }
  }, [row]);

  const findProductByClient = async (row) => {
    setIsLoading(true);
    let allRows = [];
    try {
      const payload = {
        accountId: row.original.compagny,
        childrenAccountId: row.original.othersAccountIds?.length
          ? row.original.othersAccountIds
          : [],
      };
      const res = await rq.doRequest(
        _ACTIONS.POST,
        "get-latest-invoices-period",
        payload
      );
      let companies = res.data.data;
      companies.forEach((company) => {
        company.forEach((c) => {
          if (c.Charges && Array.isArray(c.Charges)) {
            c.Charges.forEach((charge) => {
              allRows.push(createData(charge.ServiceName, charge.ServiceId));
            });
          }
        });
      });
    } catch (error) {
      console.error("Error fetching data for accountId:", error.message);
    }
    setRows(allRows);
    setIsLoading(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Nom du produit</strong>
              </TableCell>
              <TableCell>
                <strong>Id Service</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <Loader height={"10vh"} />}
            {rows.map((row) => (
              <TableRow
                key={row.ServiceId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right">{row.ServiceName}</TableCell>
                <TableCell align="right">{row.ServiceId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
