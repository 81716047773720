import * as React from "react";
import { useFormik } from "formik";
import {
  TextField,
  InputAdornment,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { MdOutlineAttachEmail } from "react-icons/md";
import { HiLink } from "react-icons/hi";
import { SiAiohttp } from "react-icons/si";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { _ROUTES } from "../../../utils/_routes_constants";
import { _ACTIONS, _HTTP } from "../../../utils/constants";
import { createToast } from "../../../utils/toast";
import _ from "lodash";
import { NumericFormatCustom } from "../../helpers/FormatNumber";
import { RequestHelper } from "../../../utils/requestHelper";

const FormModalTiers = ({ ...props }) => {
  const users = props?.users;
  users?.sort((a, b) => a.firstName.localeCompare(b.firstName));
  const societe = props?.ticket?.societe;
  const requestHelper = new RequestHelper();
  const contacts = props?.ticket?.societe?.contacts;
  contacts?.sort((a, b) => a.firstName.localeCompare(b.firstName));

  /**
   * Formik instance for managing form state and handling form submission.
   *
   * @type {import("formik").Formik}
   */
  const formik = useFormik({
    initialValues: {
      referent: societe.referent !== null ? societe?.referent["@id"] : null,
      referentClient:
        societe.referentClient !== null ? societe?.referentClient["@id"] : null,
      domains: societe.domains,
      folderDrive: societe.folderDrive,
      email: societe.email,
      phone: societe.phone,
      commentaire: societe.commentaire,
    },
    onSubmit: (values) => {
      updateSociete(values);
    },
  });

  /**
   * Updates the societe with the provided ticket data.
   *
   * @param {Object} ticket - The ticket data to update the societe.
   * @returns {Promise<void>} - A promise that resolves when the societe is updated successfully.
   */
  function updateSociete(values) {
    try {
      requestHelper
        .doRequest(_ACTIONS.PUT, `${_ROUTES.SOCIETE}/${societe.id}`, values)
        .then((response) => {
          if ([_HTTP.ok, _HTTP.created].includes(response.status)) {
            createToast("success", "Tiers modifié avec succès");
            props.setTicket({ ...props.ticket, societe: response.data });
            props.setShowModal(false);
          } else {
            createToast("error", "Erreur lors de la modification du tiers");
          }
        });
    } catch (error) {
      createToast("error", "Erreurs lors de la modification du tiers");
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl fullWidth>
        <InputLabel id="referent-select-label">Référent Toolin</InputLabel>
        <Select
          labelId="referent-select-label"
          id="referent"
          label="Référent Toolin"
          name="referent"
          value={formik.values.referent}
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}>
          <MenuItem key={0} value={null}>
            Pas de référent Toolin
          </MenuItem>
          {users?.map(
            (user) =>
              (user.roles.ROLE_TECHNICIEN ||
                user.roles.ROLE_ADMIN ||
                user.roles.ROLE_SUPER_ADMIN) &&
              user.isEnabled && (
                <MenuItem key={user.id} value={user["@id"]}>
                  {user.firstName} {user.lastName}
                </MenuItem>
              )
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <InputLabel id="referentClient-select-label">
          Référent Client
        </InputLabel>
        <Select
          labelId="referentClient-select-label"
          id="referentClient"
          label="Référent Client "
          name="referentClient"
          fullWidth
          value={formik.values.referentClient}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}>
          <MenuItem key={0} value={null}>
            Pas de référent Client
          </MenuItem>
          {contacts?.map(
            (contact) =>
              !contact.isEnabled &&
              (_.isNull(contact.societeReferent) ||
                contact.id === societe?.referentClient?.id) && (
                <MenuItem key={contact.id} value={contact["@id"]}>
                  {contact.firstName} {contact.lastName}
                </MenuItem>
              )
          )}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        sx={{ marginTop: 2 }}
        name="domains"
        type="text"
        label="Domaine"
        value={formik.values.domains}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ fontSize: "24px" }}>
              <SiAiohttp />
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ fontSize: "12px", color: "grey", padding: "5px" }}>
        Liste des domaines utilisés par le client (séparés par des virgules)
      </Box>
      <TextField
        fullWidth
        sx={{ marginTop: 2 }}
        name="folderDrive"
        type="text"
        label="Lien drive"
        value={formik.values.folderDrive}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ fontSize: "24px" }}>
              <HiLink />
            </InputAdornment>
          ),
        }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Box sx={{ fontSize: "12px", color: "grey", padding: "5px" }}>
        URL du dossier client dans le Google Drive Toolin, ex :
        https://drive.google.com/drive/u/0/folders/0B2XKVy7m0vaPc0taSkxnNWx2VDQ
      </Box>
      <TextField
        fullWidth
        sx={{ marginTop: 2 }}
        name="email"
        type="email"
        label="Adresse e-mail"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ fontSize: "24px" }}>
              <MdOutlineAttachEmail />
            </InputAdornment>
          ),
        }}
      />
      <NumericFormatCustom formik={formik} />
      <Box sx={{ marginTop: 2 }}>
        <CKEditor
          editor={Editor}
          name="description"
          data={societe?.commentaire ? societe?.commentaire : ""}
          value={formik.values.commentaire}
          onChange={(event, editor) => {
            const data = editor.getData();
            formik.setFieldValue("commentaire", data);
          }}
          onBlur={(event, editor) => {
            const data = editor.getData();
            formik.setFieldValue("commentaire", data);
          }}
        />
      </Box>
      <Button
        type="submit"
        sx={{ marginTop: 2 }}
        color="success"
        variant="contained">
        Mettre à jour
      </Button>
    </form>
  );
};

export default FormModalTiers;
