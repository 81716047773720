import { Box } from "@mui/material";
import { _COLORS } from "../../utils/constants";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";

/**
 * Renders a boolean value as an icon with a title.
 *
 * @param {boolean} props.value - The boolean value to be rendered.
 * @returns {JSX.Element} - The rendered JSX element.
 */
export const BooleanFormatter = ({ value }) => {
  const Icon = value ? CiCircleCheck : CiCircleRemove;
  const title = value
    ? "Décompte personnalisé activé"
    : "Décompte personnalisé désactivé";
  const color = value ? _COLORS.defaultGreen : _COLORS.grey;

  return (
    <Box title={title} sx={{ color: color }}>
      <Icon size={30} />
    </Box>
  );
};
