import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { datesStartAndEndWeek } from "../../../utils/function";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";
import { useRef } from "react";

const SelectWeek = ({ setStartWeek, startWeek, setEndWeek, endWeek }) => {
  const now = moment();
  const datesStartEnd = datesStartAndEndWeek(now);
  const start = useRef(startWeek ?? datesStartEnd.start);
  const end = useRef(endWeek ?? datesStartEnd.end);
  if(_.isNull(startWeek)) setStartWeek(datesStartEnd.start);
  if(_.isNull(endWeek)) setEndWeek(datesStartEnd.end);

  const onChangeDate = (newValue) => {
    const datesStartEnd = datesStartAndEndWeek(moment(newValue));
    start.current = datesStartEnd.start;
    end.current = datesStartEnd.end;
    setStartWeek(datesStartEnd.start);
    setEndWeek(datesStartEnd.end);
  }

  return (
    <Box sx={{mt: 2 }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          sx={{ m: 1 }}
          label={"Début semaine"}
          value={moment(start.current)}
          format="DD/MM/YYYY"
            onChange={(newValue) => {
                onChangeDate(newValue);
            }}
        />
        <DatePicker
          sx={{ m: 1 }}
          label={"Fin semaine"}
          value={moment(end.current)}
          format="DD/MM/YYYY"
          disabled={true}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default SelectWeek;
