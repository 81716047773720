import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { _DATE_FORMAT } from "../../../utils/constants";
import { isNull } from "lodash";

/**
 * Renders the footer component for an article.
 * Includes information about the article's creation and last modification.
 *
 * @param {Object} props.article - The article object.
 * @returns {JSX.Element} The rendered footer component.
 */
export default function ArticleFooter({ article }) {
  return (
    <>
      <Box
        sx={{
          gap: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption">
          Créé le{" "}
          <strong>
            {moment(article?.createdAt).format(_DATE_FORMAT.date_display)}
          </strong>{" "}
          par{" "}
          {!isNull(article?.createdBy) ? (
            <strong>
              {article?.createdBy.firstName} {article?.createdBy.lastName}
            </strong>
          ) : (
            <strong>N/A</strong>
          )}
        </Typography>
        <Typography variant="caption">
          {" "}
          |  Modifié le{" "}
          <strong>
            {moment(article?.updatedAt).format(_DATE_FORMAT.date_display)}
          </strong>{" "}
          par{" "}
          {!isNull(article?.updatedBy) ? (
            <strong>
              {article?.updatedBy.firstName} {article?.updatedBy.lastName}
            </strong>
          ) : (
            <strong>N/A</strong>
          )}
        </Typography>
      </Box>
    </>
  );
}
