import { TextField, FormControl, InputLabel, Select, MenuItem, Chip, Box, OutlinedInput } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import { _MODE_FACTURATION } from "../../../utils/constants";
import InputAdornment from '@mui/material/InputAdornment';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import FormHelperText from '@mui/material/FormHelperText';
import _ from "lodash";
import { useEffect } from "react";

export const SocieteField = ({formik, societes, setSelectedSociete}) => {

    return (
    <>
        <FormControl fullWidth sx={{marginTop: "20px"}}>
            <Autocomplete
                fullWidth
                /* data */
                options={societes ? societes?.sort((a, b) => a.name.localeCompare(b.name)) : []}
                /* display label */
                getOptionLabel={(option) =>
                    option
                        ? option.name
                        : ""
                    }
                    value={
                        societes?.find(
                            (option) =>
                                option["@id"] === formik.values.societe
                            ) || ""
                    }
                    onChange={(e, value) => {
                        if (value === null) {
                                formik.setFieldValue("societe", null);
                        } else {
                                formik.setFieldValue(
                                    "societe",
                                    value["@id"]
                                );
                                setSelectedSociete(value);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            input="societe"
                            label="Tiers*"
                            fullWidth
                            name="societe"
                            sx={{
                                    marginTop: "0px",
                                }}
                            onBlur={formik.handleBlur}
                                    {...params}
                            error={
                                formik.touched.societe &&
                                !formik.values.societe &&
                                Boolean(formik.errors.societe)
                            }
                            helperText={
                                formik.touched.societe && !formik.values.societe
                                  ? formik.errors.societe
                                  : ""
                            }
                        />
                    )}
            />
        </FormControl>
    </>
    );
};

export const DateDebut = ({formik}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <DatePicker
                    label="Date de début*"
                    size="small"
                    format="DD/MM/YYYY"
                    name="dateDebut"
                    value={formik.values.dateDebut}
                    onChange={(newDate) => {
                        formik.setFieldValue(
                        "dateDebut",
                        moment(newDate)
                        );
                    }}
                    slots={{
                        openPickerIcon: EditCalendarRoundedIcon,
                    }}
                    slotProps={{
                        openPickerButton: { color: "success" },
                    }}
                />
            </FormControl>
        </>
    );
};

export const DateFin = ({formik}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <DatePicker
                    label="Date de fin*"
                    size="small"
                    format="DD/MM/YYYY"
                    name="dateFin"
                    value={formik.values.dateFin}
                    onChange={(newDate) => {
                        formik.setFieldValue(
                        "dateFin",
                        moment(newDate)
                        );
                    }}
                    slots={{
                        openPickerIcon: EditCalendarRoundedIcon,
                    }}
                    slotProps={{
                        openPickerButton: { color: "success" },
                    }}
                />
            </FormControl>
        </>
    );
};

export const DateAnniversaire = ({formik}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <DatePicker
                    label="Date d'anniversaire*"
                    size="small"
                    format="DD/MM/YYYY"
                    name="dateAnniversaire"
                    value={formik.values.dateAnniversaire}
                    onChange={(newDate) => {
                        formik.setFieldValue(
                        "dateAnniversaire",
                        moment(newDate)
                        );
                    }}
                    slots={{
                        openPickerIcon: EditCalendarRoundedIcon,
                    }}
                    slotProps={{
                        openPickerButton: { color: "success" },
                    }}
                />
            </FormControl>
        </>
    );
};

export const Facturation = ({formik}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <InputLabel id="facturation-select-label">Facturation*</InputLabel>
                <Select
                    labelId="facturation-select-label"
                    id="facturation"
                    label="Facturation*"
                    name="facturation"
                    value = {formik.values.facturation}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    error={
                        formik.touched.facturation &&
                        !formik.values.facturation &&
                        Boolean(formik.errors.facturation)
                    }
                >
                {_MODE_FACTURATION.map((facturation) => (
                    <MenuItem key={facturation.id} value={facturation.mode}>
                        {facturation.mode}
                    </MenuItem>
                ))}
                </Select>
                <FormHelperText sx={{color: 'red'}}>
                    {
                        formik.touched.facturation && !formik.values.facturation
                          ? formik.errors.facturation
                          : ""
                    }
                </FormHelperText>
            </FormControl>
        </>
    );
};

export const CreditContrat = ({formik}) => {

    return (
        <>
            <TextField 
                fullWidth
                sx={{ marginTop: 2 }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">min</InputAdornment>,
                }}
                name="creditContrat" 
                type="number"
                label="Crédit en min (Annuel)"
                value={formik.values.creditContrat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
        </>
    );
};

export const FacturationDeplacement = ({formik}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <InputLabel id="deplacement-select-label">Déplacement facturable*</InputLabel>
                <Select
                    labelId="deplacement-select-label"
                    id="isFacturedDeplacement"
                    label="Déplacement facturable*"
                    name="isFacturedDeplacement"
                    value = {formik.values.isFacturedDeplacement}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                    <MenuItem key={1} value={true}>Oui</MenuItem>
                    <MenuItem key={2} value={false}>Non</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};

export const Price = ({formik, labelPrice}) => {

    return (
        <>
            <TextField 
                fullWidth
                sx={{ marginTop: 2 }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                name="price" 
                type="number"
                label={labelPrice}
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
        </>
    );
};

export const CostPrice = ({formik}) => {

    return (
        <>
            <TextField 
                fullWidth
                sx={{ marginTop: 2 }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                name="costPrice" 
                type="number"
                label="Prix d'achat HT/annuel"
                value={formik.values.costPrice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
        </>
    );
};

export const Apporteur = ({formik, setRapporteur}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <InputLabel id="apporteur-select-label">Apporteur d'affaire*</InputLabel>
                <Select
                    labelId="apporteur-select-label"
                    id="apporteur"
                    label="Apporteur d'affaire*"
                    name="apporteur"
                    value = {formik.values.apporteur}
                    fullWidth
                    onChange={
                        (e) => {
                            formik.handleChange(e);
                            setRapporteur(e.target.value);
                        }
                    }
                >
                    <MenuItem key={1} value={true}>Oui</MenuItem>
                    <MenuItem key={2} value={false}>Non</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};

export const ApporteurMontant = ({formik}) => {

    return (
        <>
           <TextField 
                fullWidth
                sx={{ marginTop: 2 }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                name="apporteurMontant" 
                type="number"
                label="Montant de l'apporteur"
                value={formik.values.apporteurMontant}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            /> 
        </>
    );
};

export const ApporteurName = ({formik, societes, setIdApporteur, idApporteur}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <Autocomplete
                    fullWidth
                    /* data */
                    options={societes ? societes : []}
                    /* display label */
                    getOptionLabel={(option) =>
                        option
                            ? option.name
                            : ""
                    }
                    value={
                        societes.find(
                            (option) =>
                                option["@id"] === idApporteur
                        ) || "" 
                    }
                    onChange={(e, value) => {
                        if (value === null) {
                            setIdApporteur(null);
                            formik.setFieldValue("apporteurName", null);
                        } else {
                            setIdApporteur(value["@id"]);
                            formik.setFieldValue(
                                    "apporteurName",
                                    value["@id"]
                                );
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            label="Nom d'apporteur*"
                            fullWidth
                            name="apporteurName"
                            sx={{
                                marginTop: "0px",
                            }}
                            onBlur={formik.handleBlur}
                                {...params}
                            error={
                                formik.touched.apporteurName &&
                                !formik.values.apporteurName &&
                                Boolean(formik.errors.apporteurName)
                            }
                            helperText={
                                formik.touched.apporteurName && !formik.values.apporteurName
                                    ? formik.errors.apporteurName
                                    : ""
                            }
                        />
                    )}
                />
            </FormControl>
        </>
    );
};

export const Reconduit = ({formik}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <InputLabel id="reconduit-select-label">Tacite à date anniversaire*</InputLabel>
                    <Select
                        labelId="reconduit-select-label"
                        id="isReconduite"
                        label="Tacite à date anniversaire*"
                        name="isReconduite"
                        value = {formik.values.isReconduite}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.isReconduite &&
                            !formik.values.isReconduite &&
                            Boolean(formik.errors.isReconduite)
                        }
                        helperText={
                            formik.touched.isReconduite && !formik.values.isReconduite
                            ? formik.errors.isReconduite
                            : ""
                        }
                    >
                        <MenuItem key={1} value={true}>Oui</MenuItem>
                        <MenuItem key={2} value={false}>Non</MenuItem>
                    </Select>
                </FormControl>
        </>
    );
};

export const Commentaire = ({formik, row}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}> 
                <CKEditor
                    editor={Editor}
                    name="commentaire"
                    data={(row !== null && row?.commentaire !== null) ? row?.commentaire : ""}
                    value={formik.values.commentaire}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        formik.setFieldValue('commentaire', data);
                    }}
                    onBlur={(event, editor) => {
                        const data = editor.getData();
                        formik.setFieldValue('commentaire', data);
                    }}
                /> 
            </FormControl> 
        </>
    );
};

export const Field = ({handleFileChange}) => {

    return (
        <>
            <TextField
                fullWidth 
                sx={{marginTop: "20px"}}
                id="fileName"
                name="fileName"
                type="file"
                label="Joindre contrat(*.pdf)"
                onChange={
                    (e) => {handleFileChange(e);}
                }
            />
        </>
    );
};

export const ProduitsService = ({formik, produitsServices}) => {

    return (
    <>
        <FormControl fullWidth sx={{marginTop: "20px"}}>
            <Autocomplete
                fullWidth
                /* data */
                options={produitsServices ? produitsServices : []}
                /* display label */
                getOptionLabel={(option) =>
                    option
                        ? option.ref + " : " + option.name
                        : null
                    }
                    value={
                        produitsServices?.find(
                            (option) =>
                                option.id === formik.values.dolProduct
                            ) || null
                    }
                    onChange={(e, value) => {
                        if (value === "" || value === null) {
                                formik.setFieldValue("dolProduct", null);
                        } else {
                            formik.setFieldValue(
                                "dolProduct",
                                value.id
                            );
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            label="Produit | Service"
                            fullWidth
                            name="dolProduct"
                            sx={{
                                    marginTop: "0px",
                                }}
                            onBlur={formik.handleBlur}
                                    {...params}
                        />
                    )}
            />
        </FormControl>
    </>
    );
};

export const PrixFacturation = ({formik, prixFacturation}) => {

    return (
    <>
        <FormControl fullWidth sx={{marginTop: "20px"}}>
            <Autocomplete
                fullWidth
                /* data */
                options={prixFacturation ? prixFacturation : []}
                /* display label */
                getOptionLabel={(option) =>
                    option
                        ? option.quantite
                        : ""
                    }
                    value={
                        prixFacturation?.find(
                            (option) =>
                                option["@id"] === formik.values.prixFacturation
                            ) || ""
                    }
                    onChange={(e, value) => {
                        if (value === null) {
                                formik.setFieldValue("prixFacturation", null);
                        } else {
                                formik.setFieldValue(
                                    "prixFacturation",
                                    value["@id"]
                                );
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            input="prixFacturation"
                            label="Quota en GO (contractuel)*"
                            fullWidth
                            name="prixFacturation"
                            sx={{
                                    marginTop: "0px",
                                }}
                            onBlur={formik.handleBlur}
                                    {...params}
                            error={
                                formik.touched.prixFacturation &&
                                !formik.values.prixFacturation &&
                                Boolean(formik.errors.prixFacturation)
                            }
                            helperText={
                                formik.touched.prixFacturation && !formik.values.prixFacturation
                                  ? formik.errors.prixFacturation
                                  : ""
                            }
                        />
                    )}
            />
        </FormControl>
    </>
    );
};

export const SocieteProduitServiceSauvegarde = ({formik, sauvegardes}) => {


    return (
    <>
        <FormControl fullWidth sx={{marginTop: "20px"}}>
            <Autocomplete
                fullWidth
                /* data */
                options={sauvegardes ? sauvegardes : []}
                /* display label */
                getOptionLabel={(option) =>
                    option
                        ? "[" + option.produitService.produitServiceCategorie.name + "] " + option.produitService.nomnas + " - " + option.produitService.nomtache
                        : ""
                    }
                    value={
                        sauvegardes?.find(
                            (option) =>
                               (!_.isUndefined(option) && option['@id'] === formik.values.societeProduitServiceSauvegarde)
                            ) || ""
                    }
                    onChange={(e, value) => {
                        if (value === null) {
                                formik.setFieldValue("societeProduitServiceSauvegarde", null);
                        } else {
                                formik.setFieldValue(
                                    "societeProduitServiceSauvegarde",
                                    value['@id']
                                );
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            input="societeProduitServiceSauvegarde"
                            label="Sauvegarde associée"
                            fullWidth
                            name="societeProduitServiceSauvegarde"
                            sx={{
                                    marginTop: "0px",
                                }}
                            onBlur={formik.handleBlur}
                                    {...params}
                        />
                    )}
            />
        </FormControl>
    </>
    );
};


export const TypeRegie = ({formik, typeRegies, selects, setSelects}) => {
   
    /**
     * Retrieves the label for a given value from the typeRegies array.
     *
     * @param {string} c - The value to search for in the typeRegies array.
     * @param {Array} typeRegies - The array of typeRegies objects.
     * @returns {string|null} - The label corresponding to the given value, or null if not found.
     */
    function getLabel(c, typeRegies){
        const label = typeRegies?.find((typeRegie) =>  typeRegie['@id'] === c);
    
        return label?.nom;
    };
    
    /**
     * Generates an array of PrixRegie components based on the selected items and type of regies.
     *
     * @param {Array} selected - The selected items.
     * @param {Array} typeRegies - The type of regies.
     * @returns {Array} An array of PrixRegie components.
     */
    function prixRegie(selects, typeRegies){

        return selects?.map((c) => {
                    c = !_.isUndefined(c.contratTypeRegie) ? c.contratTypeRegie['@id'] : c;
                    const label = getLabel(c, typeRegies);
                    formik.values[c] = formik.values[c] ? formik.values[c] : null;
                    
                    return PrixRegie({formik, labelPrice: label, name:c});
                });
    };

    useEffect(() => {
        if(formik.values.contratSocieteRegieContratTypeRegies?.length > 0){ 
            formik.values.contratSocieteRegieContratTypeRegies?.map((regie) => {
            formik.values[regie?.contratTypeRegie['@id']] = regie?.price !== null ? regie.price  : regie.contratTypeRegie?.price;
            });
            const regies = [];
            formik.values.contratSocieteRegieContratTypeRegies?.map((regie) => {
               regies.push(regie?.contratTypeRegie['@id']);
            });
            formik.values.contratSocieteRegieContratTypeRegies = regies;
            prixRegie(selects, typeRegies)
        }
    }, []);
  
    return (
    <>
        <FormControl fullWidth sx={{marginTop: "20px"}}>
            <InputLabel id="typeRegieId-label">Types de régie*</InputLabel>
            <Select
                labelId="typeRegieId-label"
                id="contratSocieteRegieContratTypeRegies"
                multiple
                value={formik.values.contratSocieteRegieContratTypeRegies}
                onChange={(e) => {
                    formik.setFieldValue("contratSocieteRegieContratTypeRegies", e.target.value);
                    {setSelects( e.target.value)}
                }}
                name="contratSocieteRegieContratTypeRegies"
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (// Render the selected values as chips
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((c) => (
                            c = !_.isUndefined(c.contratTypeRegie) ? c.contratTypeRegie['@id'] : c,
                            <Chip key={c} label={getLabel(c, typeRegies)} />
                        ))}
                        {setSelects(selected)}
                    </Box>
                )}
            >
            {typeRegies &&
                typeRegies?.map((typeRegie) => (
                    <MenuItem key={typeRegie['@id']} value={typeRegie['@id']}>
                        {typeRegie.nom}
                    </MenuItem>
                    ))}
            </Select>
         </FormControl>
         {prixRegie(selects, typeRegies)}
    </>
    );
};

export const PrixRegie = ({formik, labelPrice, name}) => {

    return (
        <> 
            <Box key={name}>
                <TextField 
                    fullWidth
                    sx={{ marginTop: 2 }}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    name={name}
                    type="number"
                    label={"Prix " + labelPrice}
                    value={formik.values[name]}
                    onChange={formik.handleChange}
                />
                <Box sx={{fontSize:'12px', color:'grey', padding:'5px'}}>
                    Si non renseigné, le prix par défaut sera appliqué.
                </Box>
            </Box>
        </>
    );
};

export const ActifClient = ({formik, actifsClient}) => {
    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <Autocomplete
                    fullWidth
                    /* data */
                    options={actifsClient ? actifsClient : []}
                    /* display label */
                    getOptionLabel={(option) =>
                        option
                            ? option?.produitServiceCategorie?.name + " - " + option?.societeProduitServiceHebergement?.domaine
                            : null
                        }
                        value={
                            actifsClient?.find(
                                (option) =>
                                    option["@id"] === formik.values.societeProduitService
                                ) || null
                        }
                        onChange={(e, value) => {
                            if (value === "" || value === null) {
                                    formik.setFieldValue("societeProduitService", null);
                            } else {
                                formik.setFieldValue(
                                    "societeProduitService",
                                    value["@id"]
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                label="Actif" 
                                fullWidth
                                name="societeProduitService"
                                sx={{
                                        marginTop: "0px",
                                    }}
                                onBlur={formik.handleBlur}
                                    {...params}
                        />
                    )}
                />
            </FormControl>
        </>
    );
};  