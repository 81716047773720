import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { _PATTERN_FORM_RESERVATION } from "../../utils/_reservation_constants";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MuiDatePicker from "../../components/helpers/MuiDatePicker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { _ACTIONS, _COLORS, _MESSAGES } from "../../utils/constants";
import { LuUserSquare } from "react-icons/lu";
import FormTitle from "../../components/helpers/FormTitle";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { _ROUTER_DOM_URL } from "../../utils/_routes_constants";
import { getValueMoment } from "../../utils/actif-config/actif_configuration_functions";

export const FormReservation = () => {
  const [resources, setResources] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [societes, setSocietes] = useState([]);
  const [tickets, setTickets] = useState([]);
  // IF ID MEAN IS EDIT MODE
  const id = useParams().id ?? null;
  const isExterneRéservation = useParams().type === "externe";
  const requestHelper = new RequestHelper();
  const navigate = useNavigate();

  // GET ALL RESOURCES
  function allRessources() {
    requestHelper
      .doRequest(_ACTIONS.GET, "/resources", {
        isAvailable: 1,
        pagination: "disabled",
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(
            "Une erreur est survenue lors de la récupération des ressources"
          );
        }
        setResources(response.data["hydra:member"]);
      })
      .catch((error) => {
        createToast("error", _MESSAGES.error);
      });
  }
  // IF INTERNE RESERVATION GET ALL TECHNICIANS
  function allTechnicians() {
    requestHelper
      .doRequest(_ACTIONS.GET, "/referent_toolin")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(
            "Une erreur est survenue lors de la récupération des techniciens"
          );
        }
        setTechnicians(response.data);
      })
      .catch((error) => {
        createToast("error", _MESSAGES.error);
        return [];
      });
  }
  // IF EXTERNE RESERVATION GET ALL SOCIETES
  function allSocietes() {
    requestHelper
      .doRequest(_ACTIONS.GET, "/societes", {
        isEnabled: true,
        pagination: "disabled",
        order: { name: "ASC" },
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(
            "Une erreur est survenue lors de la récupération des sociétés"
          );
        }
        setSocietes(response.data["hydra:member"]);
      })
      .catch((error) => {
        createToast("error", _MESSAGES.error);
      });
  }
  // IF EXTERNE GET ALL TICKETS BY SOCIETE
  function allTicketsAvailableBySociete(societeId) {
    requestHelper
      .doRequest(_ACTIONS.GET, `tickets/societe/${societeId}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(
            "Une erreur est survenue lors de la récupération des tickets"
          );
        }
        setTickets(response.data);
      })
      .catch((error) => {
        createToast("error", _MESSAGES.error);
      });
  }

  useEffect(() => {
    allRessources();
    if (isExterneRéservation) {
      allSocietes();
    } else {
      allTechnicians();
    }
  }, []);
  // IF EDIT MODE WE GET THE CURRENT RESERVATION AND SET THE FORMIK VALUES
  useEffect(() => {
    if (id) {
      requestHelper
        .doRequest(_ACTIONS.GET, `/reservations/${id}`)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(
              "Une erreur est survenue lors de la récupération de la réservation"
            );
          }
          const data = response.data;
          formik.setValues({
            resource: data.resource["@id"],
            description: data.description,
            startDate: getValueMoment(data.startDate) || null,
            endDate: getValueMoment(data.endDate) || null,
            quantity: data.quantity || 0,
            ...(isExterneRéservation
              ? { societe: data.societe.id, ticket: data.ticket.id }
              : null),
            ...(!isExterneRéservation ? { user: data.user.id } : null),
            status: data.status,
          });
        })
        .catch((error) => {
          createToast("error", _MESSAGES.error);
        });
    }
  }, [id, societes]);

  const formik = useFormik({
    initialValues: {
      resource: "",
      description: "",
      startDate: null,
      endDate: null,
      ...(isExterneRéservation ? { societe: "", ticket: "" } : null),
      ...(!isExterneRéservation ? { user: "" } : null),
      status: "",
    },
    validationSchema: yup.object({
      description: yup.string().nullable(),
      startDate: yup.date().required("La date de début est obligatoire"),
      endDate: yup.date().required("La date de fin est obligatoire"),
      resource: yup.string().required("La ressource est obligatoire"),
      ...(isExterneRéservation
        ? {
            societe: yup.string().required("Le tiers est obligatoire"),
            ticket: yup.string().required("Le ticket est obligatoire"),
          }
        : null),
      ...(!isExterneRéservation
        ? { user: yup.string().required("Le technicien est obligatoire") }
        : null),
      status: yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      let method = id ? _ACTIONS.PUT : _ACTIONS.POST;
      let url = id ? `/reservations/${id}` : "/reservations";
      // Logique conditionnelle pour les champs createdAt et updatedAt
      let payload = {
        ...values,
        quantity: parseInt(values.quantity),
      };

      if (isExterneRéservation) {
        payload.societe = "/api/societes/" + values.societe;
        payload.ticket = "/api/tickets/" + values.ticket;
        payload.status = 1;
      } else {
        payload.user = "/api/users/" + values.user;
        payload.status = 0;
      }

      if (id) {
        payload.updatedAt = new Date().toISOString();
      } else {
        payload.createdAt = new Date().toISOString();
      }

      const res = await requestHelper.doRequest(method, url, payload);
      if (res.status !== 200 && res.status !== 201) {
        createToast("error", _MESSAGES.error);

        return;
      }
      createToast(
        "success",
        id
          ? "La réservation a été modifiée avec succès"
          : "La réservation a été créée avec succès"
      );
    },
  });
  // IF EDIT WE MUST GET THE TICKETS BY SOCIETE IN FORMIK VALUES
  useEffect(() => {
    if (formik.values.societe) {
      allTicketsAvailableBySociete(formik.values.societe);
    }
  }, [formik.values.societe]);

  return (
    <>
      <FormTitle title="Gestion des réservation" />
      <form onSubmit={formik.handleSubmit}>
        <Container
          component="main"
          maxWidth={false}
          sx={{
            backgroundColor: "white",
            padding: "1rem",
            pb: "2rem",
            borderTop: `3px solid ${_COLORS.grey}`,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <LuUserSquare size={20} />
            </Grid>
            <Grid item>
              <Typography variant="h6" component="div">
                {id ? "Modifier la réservation" : "Créer une réservation"}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Container component="main" maxWidth="md">
            <Grid container spacing={3} marginTop={2}>
              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="body1" component="div">
                      <strong>Ressource *</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl fullWidth>
                      <InputLabel id="resource-label" size="small">
                        Ressource
                      </InputLabel>
                      <Select
                        labelId="resource-label"
                        id="resource"
                        name="resource"
                        size="small"
                        value={formik.values.resource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value="">
                          <em>Veuilez choisir une ressource</em>
                        </MenuItem>
                        {resources.map((resource) => (
                          <MenuItem key={resource.id} value={resource["@id"]}>
                            {resource.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {isExterneRéservation ? (
                <>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={3}>
                        <Typography variant="body1" component="div">
                          <strong>Tiers *</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="societe-label"
                            size="small"
                            required={true}
                          >
                            Tiers
                          </InputLabel>
                          <Select
                            labelId="societe-label"
                            id="societe"
                            name="societe"
                            size="small"
                            value={formik.values.societe}
                            onChange={(event) => {
                              formik.handleChange(event);
                              allTicketsAvailableBySociete(event.target.value);
                            }}
                            onBlur={formik.handleBlur}
                          >
                            {societes.length === 0 && (
                              <MenuItem value="">
                                <strong>
                                  Chargement, veuillez patienter...
                                </strong>
                              </MenuItem>
                            )}
                            <MenuItem value="">
                              <em>Veuillez choisir un tier</em>
                            </MenuItem>
                            {societes?.map((societe) => (
                              <MenuItem key={societe.id} value={societe.id}>
                                {societe.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={3}>
                        <Typography variant="body1" component="div">
                          <strong>Ticket *</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <InputLabel id="ticket-label" size="small">
                            Ticket
                          </InputLabel>
                          <Select
                            labelId="ticket-label"
                            id="ticket"
                            name="ticket"
                            size="small"
                            value={formik.values.ticket}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <MenuItem value="">
                              <em>Veuillez choisir un ticket</em>
                            </MenuItem>
                            {tickets?.map((ticket) => (
                              <MenuItem
                                key={ticket.idTicket}
                                value={ticket.idTicket}
                              >
                                "Ref: " + {ticket.refTicket} + "Societe : " +{" "}
                                {ticket.societeName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="body1" component="div">
                        <strong>Technicien *</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl fullWidth>
                        <InputLabel id="user-label" size="small">
                          Technicien
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="user-label"
                          id="user"
                          name="user"
                          value={formik.values.user}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem value="">
                            <em>Veuillez choisir un technicien</em>
                          </MenuItem>
                          {technicians.map((technician) => (
                            <MenuItem key={technician.id} value={technician.id}>
                              {technician.firstName + " " + technician.lastName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {_PATTERN_FORM_RESERVATION.map((field) => (
                <Grid item xs={12} key={field.name}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="body1" component="div">
                        <strong>
                          {field.label} {field.required && "*"}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {field.type === "text" && (
                        <TextField
                          fullWidth
                          size="small"
                          label={field.label}
                          name={field.name}
                          value={formik.values[field.name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            formik.touched[field.name] &&
                              formik.errors[field.name]
                          )}
                          helperText={
                            formik.touched[field.name] &&
                            formik.errors[field.name]
                          }
                        />
                      )}
                      {/*  */}
                      {field.type === "ckEditor" && (
                        <FormControl fullWidth>
                          <CKEditor
                            editor={Editor}
                            name={field.name}
                            data={formik.values[field.name] || ""}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              formik.setFieldValue(field.name, data);
                            }}
                          />
                          {formik.touched[field.name] &&
                            formik.errors[field.name] && (
                              <FormHelperText>
                                {formik.errors[field.name]}
                              </FormHelperText>
                            )}
                        </FormControl>
                      )}
                      {field.type === "date" && (
                        <FormControl fullWidth>
                          <MuiDatePicker
                            label={field.label}
                            name={field.name}
                            value={formik.values[field.name]}
                            onChange={(newDate) =>
                              formik.setFieldValue(field.name, newDate)
                            }
                            error={
                              formik.touched[field.name] &&
                              Boolean(formik.errors[field.name])
                            }
                            helperText={
                              formik.touched[field.name] &&
                              formik.errors[field.name]
                            }
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={5}
                width={"100%"}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="inherit"
                  onClick={() => {
                    navigate(_ROUTER_DOM_URL.RESERVATION);
                  }}
                >
                  Retour
                </Button>
                <Button
                  size="small"
                  form="userForm"
                  type="submit"
                  variant="contained"
                  color="success"
                  onClick={formik.handleSubmit}
                >
                  Enregistrer
                </Button>
              </Box>
            </Grid>
          </Container>
        </Container>
      </form>
    </>
  );
};
