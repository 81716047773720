import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import FormTitle from "../../components/helpers/FormTitle";
import { LuUserSquare } from "react-icons/lu";
import Divider from "@mui/material/Divider";
import { _ROUTER_DOM_URL } from "../../utils/_routes_constants";
import { _PATTERN_RESOURCE_FORM_FIELDS } from "../../utils/resource/_constant_resource";
import { _COLORS } from "../../utils/constants";
import { getDataRessource, postDataRessource, putDataRessource } from "../../repository/Ressource";
import { _ROUTES } from "../../utils/_routes_constants";

/*
 * @param {*} props
 * @returns JSX.Element
 */
export default function FormRessource() {
  // IF ID MEAN EDIT MODE
  const id = useParams().id ?? null;
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    type: yup.string().required("Ce champs est obligatoire"),
    brand: yup.string().required("Marque obligatoire"),
    quantity: yup.number().required("Quantité obligatoire"),
    reference: yup.string().required("Référence obligatoire"),
    serialNumber: yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      type: "",
      brand: "",
      quantity: "",
      reference: "",
      serialNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if(id){
        putDataRessource(
          {...values, quantity: parseInt(values.quantity)}, 
          "Erreur lors de la mise à jour de la ressource.", 
          `${_ROUTES.RESSOURCES}/${id}`
        );
      } else {
        postDataRessource(
          {...values, quantity: parseInt(values.quantity)}, 
          "Erreur lors de l'enregistrement de la ressource.", 
          _ROUTES.RESSOURCES
        );
      }
    },
  });

  useEffect(() => {
    if (id) {
      getDataRessource(
        {}, 
        formik.setValues, 
        "Erreur lors de la récupération de la ressource.", 
        `${_ROUTES.RESSOURCES}/${id}`
      );
    }
  }, [id]);

  return (
    <>
      <FormTitle title="Gestion des ressources">
        <Typography variant="body2">
          (Matériel d'emprunt et de réservation)
        </Typography>
      </FormTitle>
      <form onSubmit={formik.handleSubmit}>
        <Container
          component="main"
          maxWidth={false}
          sx={{
            backgroundColor: "white",
            padding: "1rem",
            pb: "2rem",
            borderTop: `3px solid ${_COLORS.grey}`,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <LuUserSquare size={20} />
            </Grid>
            <Grid item>
              <Typography variant="h6" component="div">
                {id ? "Modifier la ressource" : "Créer une ressource"}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Container component="main" maxWidth="md" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {_PATTERN_RESOURCE_FORM_FIELDS.map((field) => (
                <Grid item xs={12} key={field.name}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="body1" component="div">
                        <strong>
                          {field.label} {field.required && "*"}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {field.type === "text" && (
                        <TextField
                          fullWidth
                          size="small"
                          label={field.label}
                          name={field.name}
                          value={formik.values[field.name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            formik.touched[field.name] &&
                              formik.errors[field.name]
                          )}
                          helperText={
                            formik.touched[field.name] &&
                            formik.errors[field.name]
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Container>
          <Divider sx={{ mt: 2 }} />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={2}
            width={"100%"}
          >
            <Button
              variant="contained"
              size="small"
              color="inherit"
              onClick={() => {
                navigate(_ROUTER_DOM_URL.RESSOURCES_LIST);
              }}
            >
              Retour
            </Button>
            <Button
              size="small"
              form="userForm"
              type="submit"
              variant="contained"
              color="success"
              onClick={formik.handleSubmit}
            >
              Enregistrer
            </Button>
          </Box>
        </Container>
      </form>
    </>
  );
}
