import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { ExpandedRows } from "./ExpandedRows";
import { _ACTIONS, _COLORS, _LANGUAGES_MUI_TABLE } from "../../utils/constants";
import { createToast } from "../../utils/toast";
import { RequestHelper } from "../../utils/requestHelper";
import FormProduitService from "./FormProduitService";
import BasicModal from "../helpers/BasicModal";
import Loader from "../utils/Loader";
import { stylesMuiTable } from "../../utils/muiTableStyle";
import { _ROUTES } from "../../utils/_routes_constants";
import { ColonnesProduitService } from "./ColonnesProduitService";
import { RowActionsProduitService } from "./RowActionsProduitService";
import { IoReloadCircleOutline } from "react-icons/io5";

const DataGridDetailPanel = () => {
  const requestHelper = new RequestHelper();
  const [parentPS, setParentPS] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [isCategorie, setIsCategorie] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [value, setValue] = useState("");
  const [refreshList, setRefreshList] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (!parentPS || isRefetching) {
        setIsLoading(true);
      }
      try {
        const pageToFetch = pagination.pageIndex + 1;
        const limit = pagination.pageSize;
        const res = await requestHelper.doRequest(
          _ACTIONS.GET,
          `${_ROUTES.PRODUIT_SERVICE}`,
          {
            "exists[parent]": false,
            name: value,
            page: pageToFetch,
            limit,
          }
        );
        let data = res.data["hydra:member"];
        setRowCount(res.data["hydra:totalItems"]);
        setParentPS(data);
      } catch (error) {
        createToast(
          "error",
          "Une erreur est survenue lors de la récupération des données"
        );
      } finally {
        setIsLoading(false);
        setIsRefetching(false);
      }
    };

    fetchData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    value,
    isRefetching,
    refreshList,
  ]);

  const handleOpenModal = () => {
    setIsEdit(false);
    setIsCategorie(false);
    setShowModal(true);
  };

  const handleOpenCategorie = (rowId) => {
    setIsCategorie(true);
    setIsEdit(false);
    setShowModal(true);
    setRowId(rowId);
  };

  const columns = ColonnesProduitService({
    requestHelper,
    _ACTIONS,
    _ROUTES,
    setParentPS,
  });

  const table = useMaterialReactTable({
    columns,
    data: parentPS || [],
    muiTableContainerProps: stylesMuiTable.muiTableContainerProps,
    muiTableBodyRowProps: stylesMuiTable.muiTableBodyRowProps,
    muiTableBodyCellProps: stylesMuiTable.muiTableBodyCellProps,
    muiTopToolbarProps: stylesMuiTable.muiTopToolbarProps,
    muiTableHeadCellProps: stylesMuiTable.muiTableHeadCellProps,
    muiBottomToolbarProps: stylesMuiTable.muiBottomToolbarProps,
    enableColumnFilters: false,
    /* pagination */
    paginationDisplayMode: "pages",
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount,
    /* filter */
    manualFiltering: true,
    onGlobalFilterChange: (value) => {
      if (value === undefined) {
        value = "";
      }
      setValue(value);
    },
    muiPaginationProps: {
      showRowsPerPage: false,
    },
    // STATE
    state: {
      isLoading,
      value,
      pagination,
      showProgressBars: isRefetching,
    },
    initialState: {
      columnVisibility: {
        categorie: true,
        name: true,
        description: false,
        isActive: true,
      },
    },
    // RENDER EXPANDED ROWS //
    renderDetailPanel: ({ row }) => {
      return (
        <ExpandedRows
          childsPS={row.original.childrens || []}
          setRefreshList={setRefreshList}
          setShowModal={setShowModal}
          setIsEdit={setIsEdit}
          setRowId={setRowId}
          setIsCategorie={setIsCategorie}
        />
      );
    },
    // HEADER CUSTOM TABLE //
    renderTopToolbarCustomActions: () => (
      <Box display="flex" flexDirection="column" width={"100%"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h6">Listes des produits, services</Typography>
          <IconButton
            title="Recharger"
            onClick={() => setRefreshList((prev) => prev + 1)}
          >
            <IoReloadCircleOutline />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          size="small"
          onClick={handleOpenModal}
          sx={{
            width: "fit-content",
            backgroundColor: _COLORS.buttonLightBlue,
          }}
        >
          Produit | Service
        </Button>
      </Box>
    ),
    // ROW ACTIONS //
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => {
      return (
        <RowActionsProduitService
          requestHelper={requestHelper}
          _ACTIONS={_ACTIONS}
          _ROUTES={_ROUTES}
          setParentPS={setParentPS}
          row={row}
          handleOpenCategorie={handleOpenCategorie}
          navigate={navigate}
          setShowModal={setShowModal}
          setIsEdit={setIsEdit}
          setRowId={setRowId}
        />
      );
    },
    // LANGUAGE //
    localization: _LANGUAGES_MUI_TABLE,
  });

  const formTitle = () => {
    if (isEdit) {
      return "Editer un produit | service";
    } else if (isCategorie) {
      return "Ajouter un catalogue";
    } else {
      return "Ajouter un produit | service";
    }
  };
  useEffect(() => {
    formTitle();
  }, [isEdit, isCategorie]);

  if (!parentPS) {
    return <Loader />;
  }

  return (
    <>
      {/* Modal */}
      <BasicModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={formTitle()}
        content={
          <FormProduitService
            isEdit={isEdit}
            id={rowId}
            isCategorie={isCategorie}
            setRefreshList={setRefreshList}
          />
        }
      />
      <MaterialReactTable table={table} />
    </>
  );
};

export default DataGridDetailPanel;
