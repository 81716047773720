import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
import _ from "lodash";
import { createToast } from "../../utils/toast";
import { getCookie } from "../../utils/cookieHelper";

export const SecurityHandler = () => {
  const { getUser, token } = useContext(UserContext);
  const user = getUser();

  useEffect(() => {
    // Si les données ne sont ni dans le contexte ni dans le cookie on redirige vers la page de connexion
    if (_.isEmpty(user) && window.location.pathname !== "/") {
      createToast("error", "Vous n'êtes pas connecté").then(() => {
        // J'utilise window.location.href pour forcer le rechargement de la page et la recompliation de React
        window.location.href = "/";
        window.localStorage.setItem("redirect", window.location.pathname);
      });
    }

    // Si le token n'est pas dans le contexte ni dans les cookies on redirige vers la page de connexion
    if (!token && !getCookie("token") && window.location.pathname !== "/") {
      createToast("error", "Vous n'êtes pas connecté").then(() => {
        window.location.href = "/";
        window.localStorage.setItem("redirect", window.location.pathname);
      });
    }
  }, []);

  return <></>;
};
