import { Box, Typography } from "@mui/material";
import { _TICKET_STYLES } from "../../../utils/_style_constants";
import ActionDisplay from "./ActionDisplay";

export const TicketItemDetail = ({ row }) => {
  const ticket = row.original;

  return (
    <>
      <Box sx={_TICKET_STYLES.DETAIL_CONTAINER}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Object : </Typography>{" "}
          <Typography> {ticket.subject}</Typography>
        </Box>
        <Typography sx={{ fontWeight: "bold" }}>Description : </Typography>
        {/* Render HTML content */}
        <Box dangerouslySetInnerHTML={{ __html: ticket.description }}></Box>
      </Box>
      {/* Actions Sections */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: 2,
        }}
      >
        {ticket.actions.map((action, index) => (
          <ActionDisplay key={index} action={action} />
        ))}
      </Box>
    </>
  );
};
