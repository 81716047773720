import { Box } from "@mui/material";
import DashboardTitle from "../../components/tickets/dashboard/DashboardTitle";
import TicketListContextProvider from "../../contexts/TicketsListContext";
import ListContainer from "../../components/tickets/dashboard/ListContainer";

const TicketsDashboard = () => {
  return (
    <>
      <TicketListContextProvider>
        <Box>
          <DashboardTitle></DashboardTitle>
          <ListContainer></ListContainer>
        </Box>
      </TicketListContextProvider>
    </>
  );
};

export default TicketsDashboard;
