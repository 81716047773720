import { Box,Chip } from '@mui/material';

const ChipInfos = () => {
    const infos = [
        {label: "DF", value: "Durée facturable"},
        {label: "DR", value: "Durée réelle"},
        {label: "TF", value: "Total facturable"},
        {label: "TR", value: "Total réel"},
    ]

    return (
        <Box sx={{mt: 1}}>
            {infos.map((info, index) => (
                <Chip
                key={index}
                label={info.label + " : " + info.value}
                style={{margin: "5px"}}
                size="small" 
            />
            ))}
        </Box>
    );
};

export default ChipInfos;