import { Button, TextField } from "@mui/material";

export function Input(props) {
  return (
    <Button
      type="submit"
      fullWidth
      variant={props.variant}
      sx={{ mt: 3, mb: 2 }}
    >
      {props.value}
    </Button>
  );
}

export function TextFieldForm(props) {
  return (
    <TextField
      margin={props.margin}
      required
      fullWidth
      name={props.name}
      label={props.label}
      type={props.type}
      id={props.id}
      autoComplete={props.autoComplete}
    />
  );
}
