import { _ACTIONS, _HTTP } from "../utils/constants";
import { RequestHelper } from "../utils/requestHelper";
import { createToast } from "../utils/toast";
import _ from "lodash";

const requestHelper = new RequestHelper();

/**
 * Retrieves data for a fournisseur.
 *
 * @param {object} params - The parameters for the request.
 * @param {function} actions - The callback function to handle the retrieved data.
 * @param {string} errroMessage - The error message to display if the request fails.
 * @param {string} route - The route for the request.
 */
export const getDataFournisseur = (params, actions, errorMessage, route) => {
    requestHelper
        .doRequest(_ACTIONS.GET, `${route}`, params)
        .then((response) => {
            if (_HTTP.ok !== response.status) {
                throw new Error(errorMessage);
            }
            _.isUndefined(response.data["hydra:member"]) ? actions(response.data) : actions(response.data["hydra:member"]);
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};