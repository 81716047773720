import React from "react";
import { FormControl, FormHelperText } from "@mui/material";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/fr";

/**
 * Custom date pickers w/ french pattern date (&language).
 *
 * @component
 * @param {string} props.label - The label for the date picker.
 * @param {string} props.name - The name of the date picker.
 * @param {Date} props.value - The selected date value.
 * @param {Function} props.onChange - The function to handle date change.
 * @param {Object} props.errors - The errors object for form validation.
 * @param {Object} props.touched - The touched object for form validation.
 * @param {...any} props.anyProps - Additional props to be spread on the DatePicker component.
 */
export default function MuiDatePicker(props) {
  const {
    label,
    name,
    value,
    onChange,
    errors,
    touched,
    format = null,
    ...anyProps
  } = props;

  moment.locale("fr", {
    week: {
      dow: 1, // Monday first day of week
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
      <FormControl error={Boolean(touched && Boolean(errors))}>
        <DatePicker
          {...anyProps}
          fullWidth
          label={label}
          size="small"
          format={format ?? "DD/MM/YYYY"}
          name={name}
          value={value}
          onChange={(newDate) => onChange(newDate)}
          slots={{
            openPickerIcon: EditCalendarRoundedIcon,
          }}
          slotProps={{
            textField: {
              size: "small",
            },
            openPickerButton: { color: "success" },
          }}
        />
        {Boolean(touched && errors) ? (
          <FormHelperText>{errors}</FormHelperText>
        ) : null}
      </FormControl>
    </LocalizationProvider>
  );
}
