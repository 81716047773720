import * as Yup from "yup";
import { RequestHelper } from "../../../../utils/requestHelper";
import { createToast } from "../../../../utils/toast";
import { _ACTIONS, _HTTP } from "../../../../utils/constants";
import { _ROUTES } from "../../../../utils/_routes_constants";
import { getIdFromUri } from "../../../../utils/Utils";

export const _ACTION_FORMIK = (
  ticket,
  setTicket,
  setOpenActionModal,
  action = null
) => {
  const rq = new RequestHelper();
  const validationSchema = Yup.object().shape({
    description: Yup.string().required(
      "Saissisez une description dans l'action"
    ),
    durationEstimated: Yup.number()
      .min(0)
      .required("La durée estimée est requise"),
    durationReal: Yup.number().min(0).required("La durée réelle est requise"),
    user: Yup.string().required("L'utilisateur est requis"),
    ticketActionType: Yup.string().required("Le type d'action est requis"),
    notifEmail: Yup.number().required("La notification par email est requise"),
  });

  return {
    initialValues: {
      ticket: ticket["@id"],
      description: action ? action.description : "",
      files: action ? action.files : [],
      durationEstimated: action ? action.durationEstimated : 0,
      durationReal: action ? action.durationReal : 0,
      user: action.user ? action.user["@id"] : "",
      ticketActionType: action
        ? action.ticketActionType
        : "/api/ticket-action-types/1",
      notifEmail: 0,
      isPrivate: false,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      let data = values;
      if (action) {
        const id = getIdFromUri(action["@id"]);
        data = { ...values, id: id };
      }

      rq.doRequest(
        _ACTIONS.POSTFILE,
        action ? _ROUTES.EDIT_TICKET_ACTION : _ROUTES.ADD_TICKET_ACTION,
        data
      ).then((res) => {
        if (res.status === _HTTP.created || res.status === _HTTP.ok) {
          createToast("success", "Réservation ajoutée avec succès");
          setOpenActionModal(false);
          // Updating ticket state
          setTicket((prev) => {
            return {
              ...prev,
              action: [...prev.actions, res.data],
            };
          });
        }
      });
    },
  };
};
