import { Box, Chip, Divider, Typography } from "@mui/material";
import React from "react";
import { _COLORS } from "../../utils/constants";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { useCustomRequestDashboard } from "./useCustomRequestDashboard";
import { useNavigate } from "react-router-dom";
import { _ROUTER_DOM_URL } from "../../utils/_routes_constants";

export const Alert = () => {
  const { alerts } = useCustomRequestDashboard();
  const navigate = useNavigate();

  const redirect = (name) => {
    navigate(`${_ROUTER_DOM_URL.SOCIETE}/${name}`);
  };

  return (
    <Box
      sx={{
        w: "100%",
        borderTop: `2px solid ${_COLORS.error}`,
        backgroundColor: _COLORS.white,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <InfoRoundedIcon sx={{ color: _COLORS.error }} />
          <Typography variant="body1" fontWeight={"bold"} fontStyle={"italic"}>
            {" "}
            Alerte(s)
          </Typography>
        </Box>
        <Chip label={Object.keys(alerts).length} color="error" size="small" />
      </Box>
      <Divider />
      {/* ACTIFS NOT ASSOCIED */}
      {alerts.actifConfigurationNotAssocied && (
        <>
          <Box>
            <Box
              onClick={() =>
                navigate("/actifs_configuration/list/", {
                  state: { name: "posteNotAssocied" },
                })
              }
              sx={{
                p: 1,
                cursor: "pointer",
                "&:hover": { backgroundColor: _COLORS.lightGrey },
              }}
            >
              {alerts.actifConfigurationNotAssocied.message}
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {/* TICKETS NOT ASSOCIED */}
      {alerts.ticketNotAssocied && (
        <>
          <Box>
            <Box
              sx={{
                p: 1,
                cursor: "pointer",
                "&:hover": { backgroundColor: _COLORS.lightGrey },
              }}
            >
              {alerts.ticketNotAssocied.message}
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {/* GLPI */}
      {alerts.glpiNotAssocied && (
        <>
          <Box>
            <Box
              sx={{
                p: 1,
              }}
            >
              {alerts.glpiNotAssocied.message}
            </Box>
            <Box sx={{ display: "flex", gap: 1, p: 1 }}>
              {alerts.glpiNotAssocied.nonassocies.map((item) => {
                const chipName = item.nameGlpi.replace("Root entity > ", "");
                return (
                  <Box key={item.id}>
                    <Chip
                      label={chipName}
                      onClick={() => redirect(chipName)}
                      color={"warning"}
                      size="small"
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {/* ALSO */}
      {alerts.alsoNotAssocied && (
        <>
          <Box>
            <Box
              sx={{
                p: 1,
                cursor: "pointer",
                "&:hover": { backgroundColor: _COLORS.lightGrey },
              }}
            >
              {alerts.alsoNotAssocied.message}
            </Box>
            <Box sx={{ display: "flex", gap: 1, p: 1 }}>
              {alerts.alsoNotAssocied.nonassocies.map((item) => {
                const chipName = item.nameCompagny;
                return (
                  <Box key={item.id}>
                    <Chip
                      label={chipName}
                      onClick={() => redirect(chipName)}
                      color={"warning"}
                      size="small"
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {/* ANTIVIRUS */}
      {alerts.antivirusExpired && (
        <>
          <Box>
            <Box
              onClick={() =>
                navigate("/actifs_configuration/list", {
                  state: {
                    name: "ANTIVIRUS / ANTISPAM",
                  },
                })
              }
              sx={{
                p: 1,
                cursor: "pointer",
                "&:hover": { backgroundColor: _COLORS.lightGrey },
              }}
            >
              {alerts.antivirusExpired.message}
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {/* TELESAUVEGARDE ALERTS */}
      {alerts.telesauvegardAlerts && (
        <>
          <Box>
            <Box
              sx={{
                p: 1,
                cursor: "pointer",
                "&:hover": { backgroundColor: _COLORS.lightGrey },
              }}
            >
              {alerts.telesauvegardAlerts.message}
            </Box>
          </Box>
          <Divider />
        </>
      )}
    </Box>
  );
};
