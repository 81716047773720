import { Box, Button, ButtonGroup, Checkbox } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import { _ROUTES } from "../../../utils/_routes_constants";
import { RequestHelper } from "../../../utils/requestHelper";
import { confirmExit } from "../edit/TicketEditToolBar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";

export const TicketCreateToolBar = ({
  ticket,
  setTicket,
  setActionsExpanded,
}) => {
  const navigate = useNavigate();
  const rq = new RequestHelper();
  const [supportChecked, setSupportChecked] = useState(false);
  const [infogeranceChecked, setInfogeranceChecked] = useState(false);

  const goToCreateTicket = () => {
    navigate(_ROUTES.TICKET_CREATE);
  };

  useEffect(() => {
    setTicket((prev) => ({
      ...prev,
      supportChecked: supportChecked,
      infogeranceChecked: infogeranceChecked,
    }));
  }, [supportChecked, infogeranceChecked]);

  return (
    <>
      <Box>
        <ButtonGroup>
          {ticket.societe && (
            <>
              <Button
                size="small"
                onClick={() => {
                  setSupportChecked(!supportChecked);
                  setInfogeranceChecked(false);
                }}>
                <Checkbox size="small" checked={supportChecked}></Checkbox>
                Notifications par e-mail(Support)
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setInfogeranceChecked(!infogeranceChecked);
                  setSupportChecked(false);
                }}>
                <Checkbox size="small" checked={infogeranceChecked}></Checkbox>
                Notifications par e-mail(Infogérance)
              </Button>
            </>
          )}
          <Button
            title="Tout développer"
            onClick={() => {
              setActionsExpanded(true);
            }}>
            <KeyboardArrowDownIcon />
          </Button>
          <Button
            title="Tout réduire"
            onClick={() => {
              setActionsExpanded(false);
            }}>
            <KeyboardArrowUpIcon />
          </Button>
          <Button title="Tableau de bord">
            <FormatListBulletedIcon
              onClick={(e) => {
                confirmExit(navigate);
              }}
            />
          </Button>
          <Button title="Aide">
            <HelpIcon />
          </Button>
        </ButtonGroup>
      </Box>
    </>
  );
};

export default TicketCreateToolBar;
