import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import { _ACTIONS } from "../../../utils/constants";
import { createToast } from "../../../utils/toast";
import { _ROUTES } from "../../../utils/_routes_constants";

const AlsoModal = ({ row, setRefreshData, alsos }) => {
  const [selectedAlso, setSelectedAlso] = useState();
  const rq = new RequestHelper();
  const notAssociedAlsos = alsos?.filter((item) => item.isAssocied === false);

  const associateAlsoId = (id) => {
    let currentTmpAlsos = alsos.find((glpi) => glpi.compagny === selectedAlso);

    rq.doRequest(_ACTIONS.PUT, `${_ROUTES.SOCIETE}/${id}`, {
      also: selectedAlso,
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Erreur lors de l'association de l'entité Société");
        }
        return rq.doRequest(
          _ACTIONS.PUT,
          `${_ROUTES.TMP_ALSO}/${currentTmpAlsos.id}`,
          {
            isAssocied: true,
          }
        );
      })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Erreur lors de la mise à jour de l'entité Also");
        }
        setRefreshData((prev) => prev + 1);
        createToast("success", "Société ajoutée au drive");
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      gap={2}
    >
      <FormControl fullWidth>
        <InputLabel id="glpi" size="small">
          Associer un client Alsos
        </InputLabel>
        <Select
          name="glpi"
          value={selectedAlso}
          onChange={(e) => setSelectedAlso(e.target.value)}
          size="small"
        >
          {notAssociedAlsos?.map((also) => (
            <MenuItem key={also.id} value={also.compagny}>
              {also.nameCompagny}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={() => associateAlsoId(row.original.id)}
        sx={{ width: "fit-content" }}
        size="small"
      >
        Enregistrer
      </Button>
    </Box>
  );
};

export default AlsoModal;
