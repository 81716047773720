import { Box, Checkbox, IconButton, Menu, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { useState } from "react";

/**
 * A component that manages the visibility of columns in a table.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.columns - The array of columns to be managed.
 * @param {Object} props.columnVisibility - The object representing the visibility of each column.
 * @param {Function} props.setColumnVisibility - The function to update the column visibility.
 * @returns {JSX.Element} The rendered ColumnManager component.
 */
const ColumnManager = ({ columns, columnVisibility, setColumnVisibility }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleColumn = (columnKey) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnKey]: !prevVisibility[columnKey],
    }));
  };

  return (
    <Tooltip title="Activé/désactivé colonnes" placement="top">
      <Box>
        <IconButton onClick={handleClick}>
          <ViewColumnIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: 70 * 4.5 + 8,
                width: "25ch",
              },
            },
          }}
        >
          {columns.map((column) => (
            <MenuItem
              key={column.accessorKey}
              onClick={() => toggleColumn(column.accessorKey)}
              sx={{
                fontSize: "1rem",
                cursor: "default",
              }}
            >
              <Checkbox
                edge="start"
                color="primary"
                size="small"
                checked={columnVisibility[column.accessorKey]}
                tabIndex={-1}
                disableRipple
              />
              {column.header}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Tooltip>
  );
};

export default ColumnManager;
