import { useState } from "react";
import { createContext } from "react";

export const ActifsConfigurationsContext = createContext({});

/**
 * Provides the context for managing actifs configurations.
 *
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactNode} The rendered component.
 */
function ActifsConfigurationsContextProvider({ children }) {
  /* current produit service in the cookie */
  const [selectedProduitService, setSelectedProduitService] = useState({
    id: null,
    name: "",
  });
  /* list of societes used in SearchSocieteInSocieteProduitService.jsx */
  const [societes, setSocietes] = useState([]);
  /* id of the selected societe in search field */
  const [societeId, setSocieteId] = useState(null);
  /* if > 0 refresh the table */
  const [refresh, setRefresh] = useState(0);
  /* if true loader on */
  const [isLoading, setIsLoading] = useState(false);
  /* when refetching with data */
  const [isRefetching, setIsRefetching] = useState(false);
  /* response api for Mui table */
  const [responseActif, setResponseActif] = useState([]);
  /* columns for Mui table */
  const [colonnes, setColonnes] = useState([]); /* columns for Mui table */
  /* pagination for Mui table */
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  /* number of rows for Mui table */
  const [rowCount, setRowCount] = useState(0);
  /* sorting for Mui table */
  const [sorting, setSorting] = useState([]);
  /* global filter for Mui table */
  const [globalFilter, setGlobalFilter] = useState("");
  /* column filters for Mui table */
  const [columnFilters, setColumnFilters] = useState([]);
  const [isError, setIsError] = useState(false);
  /* when data is loaded */
  const [isLoaded, setIsLoaded] = useState(false);
  /* produit service entity selected */
  const [produitService, setProduitService] = useState(null);

  const value = {
    selectedProduitService,
    setSelectedProduitService,
    societes,
    setSocietes,
    societeId,
    setSocieteId,
    refresh,
    setRefresh,
    isLoading,
    setIsLoading,
    isRefetching,
    setIsRefetching,
    responseActif,
    setResponseActif,
    colonnes,
    setColonnes,
    pagination,
    setPagination,
    rowCount,
    setRowCount,
    sorting,
    setSorting,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    isError,
    setIsError,
    isLoaded,
    setIsLoaded,
    produitService,
    setProduitService,
  };

  return (
    <ActifsConfigurationsContext.Provider value={value}>
      {children}
    </ActifsConfigurationsContext.Provider>
  );
}

export default ActifsConfigurationsContextProvider;
