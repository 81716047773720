import React, { useEffect, useState } from "react";
import FormTitle from "../../components/helpers/FormTitle";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Grid, Typography, Box, Divider } from "@mui/material";
import { LuUserSquare } from "react-icons/lu";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { getJsonObjectRole } from "../../utils/function";
import { _ROUTES } from "../../utils/_routes_constants";
import { FormComponentUtilisateur } from "../../components/admin-utilisateur/FormComponentUtilisateur";
import { FormResetPassword } from "../../components/admin-utilisateur/FormResetPassword";
import { _ACTIONS, _COLORS } from "../../utils/constants";
import AvatarUploader from "./AvatarUploader";
import Loader from "../../components/utils/Loader";

const FormUtilisateur = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [tiersList, setTiersList] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const id = useParams().id;
  const rq = new RequestHelper();

  function searchSociete(value) {
    rq.doRequest(_ACTIONS.GET, _ROUTES.SOCIETE, {
      name: !value ? "" : value,
      pagination: "disabled",
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(
            "Une erreur est survenue lors de la récupération des sociétés"
          );
        }
        setTiersList(response.data["hydra:member"]);
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  }

  useEffect(() => {
    searchSociete();
  }, []);

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      rq.doRequest(_ACTIONS.GET, `${_ROUTES.USER}/${id}`).then((res) => {
        setCurrentUser(res.data);
        const userData = res.data;
        formik.setValues({
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          email: userData.email || "",
          password: "",
          passwordConfirm: "",
          roles:
            Object.keys(userData.roles)
              .filter((key) => isNaN(key))
              .toString() || "",
          societe: userData.societe.id || "",
          poste: userData.poste || "",
          phone: userData.phone || "",
          civility: userData.civility || "",
          dateTimePickerMode: userData.dateTimePickerMode || "mobile",
        });
      });
    }
  }, [tiersList]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Le prénom est requis"),
    lastName: Yup.string().required("Le nom est requis"),
    email: Yup.string().email("L'adresse e-mail est invalide").nullable(),
    ...(!isEdit && {
      password: Yup.string()
        .min(8, "Le mot de passe doit contenir au moins 8 caractères")
        .matches(
          /(?=.*\d)/,
          "Le mot de passe doit contenir au moins un chiffre"
        )
        .matches(
          /(?=.*[!@#$%^&*])/,
          "Le mot de passe doit contenir au moins un caractère spécial"
        )
        .required("Le mot de passe est requis"),

      passwordConfirm: Yup.string()
        .oneOf(
          [Yup.ref("password"), null],
          "Les mots de passe doivent être identiques"
        )
        .required("La confirmation du mot de passe est requise"),
    }),
    roles: Yup.string().required("Le rôle est requis"),
    societe: Yup.string().required("La société est requise"),
    poste: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    civility: Yup.string().nullable(),
    dateTimePickerMode: Yup.string().required("Veuillez choisir un mode"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      ...(!isEdit && { password: "", passwordConfirm: "" }),
      roles: [],
      societe: "",
      poste: "",
      phone: "",
      civility: "",
      dateTimePickerMode: "mobile",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const data = {
        ...values,
        roles: getJsonObjectRole(values.roles.split(" ")),
        societe: isEdit ? `/api/societes/${values.societe}` : values.societe,
        updatedAt: isEdit ? new Date() : "",
      };
      if (isEdit) {
        delete data.password;
        delete data.passwordConfirm;
      }

      const endpoint = isEdit ? `${_ROUTES.USER}/${id}` : `/create_user`;
      const action = isEdit ? _ACTIONS.PUT : _ACTIONS.POST;

      rq.doRequest(action, endpoint, data)
        .then((res) => {
          if ([200, 201].includes(res.status)) {
            createToast(
              "success",
              isEdit
                ? "Utilisateur modifié avec succès"
                : "Utilisateur créé avec succès"
            );
          }
        })
        .catch(() => {
          createToast(
            "error",
            `Une erreur est survenue lors de la ${
              isEdit ? "modification" : "création"
            } de l'utilisateur`
          );
        });
    },
  });

  if (tiersList.length === 0) {
    return <Loader />;
  }

  const sxBox = (color) => ({
    border: "1.5px solid",
    borderTop: "3.5px solid",
    borderColor: "grey.500",
    borderTopColor: color,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  });

  return (
    <>
      <Grid>
        <FormTitle title="Gestion des utilisateurs" />
      </Grid>
      <Container
        component="main"
        maxWidth={false}
        sx={{
          backgroundColor: "white",
          padding: "1rem",
          pb: "2rem",
          borderTop: `3px solid ${_COLORS.grey}`,
        }}
      >
        <Grid display={"flex"} gap={2} alignItems={"center"}>
          <LuUserSquare size={20} />
          <Typography variant="h6" component="div">
            {isEdit ? "Modifier l'utilisateur" : "Créer un utilisateur"}
          </Typography>
        </Grid>
        <Divider />
        <Box
          display={"flex"}
          gap={2}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <Box sx={sxBox("#00a65a")} padding={2}>
              <FormComponentUtilisateur
                formik={formik}
                tiersList={tiersList}
                isEdit={isEdit}
                searchSociete={searchSociete}
              />
            </Box>
          </Box>
          <Box
            sx={{ width: { xs: "100%", md: "50%" } }}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Box sx={sxBox("#dd4b39")} padding={2}>
              <FormResetPassword currentUser={currentUser} />
            </Box>
            <Box sx={sxBox("#F39C12")}>
              {currentUser ? (
                <AvatarUploader currentUser={currentUser} />
              ) : (
                <Typography
                  variant="body"
                  component="div"
                  sx={{ p: 1, color: _COLORS.darkGrey }}
                >
                  <em>
                    {" "}
                    Pour ajouter un avatar, veuillez enregistrer l'utilisateur
                    au préalable...
                  </em>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default FormUtilisateur;
