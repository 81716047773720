import { _ROUTER_DOM_URL } from "../_routes_constants";
import { _COLORS } from "../constants";
import { FaUsers } from "react-icons/fa";
import { IoIosBusiness } from "react-icons/io";
import { BiSolidContact } from "react-icons/bi";
import { BsBoxes } from "react-icons/bs";

export const _MESSAGES_DASHBOARD_GENERAL = {
  h1: "Tableau de bord général",
  h1ModalAvis: "Que pensez-vous de votre outils ?",
  h2ModalAvis: "Suggestions ou optimisations",
  p: " : n'hésitez pas à nous ",
  pModalAvis:
    "Nous vous proposons de nous faire part de vos retours à travers ce formulaire, pour nous aider à l'améliorer. Ce formulaire ne collecte pas de données personnelles.",
  link: "partager votre avis ou / et vos suggestions.",
};

export const _COLORS_LINEAR_BAR = {
  "Non affecté": _COLORS.error,
  Affecté: "#f39c2b",
  "En cours": "#57a85c",
  "En attente": "#4fc1ef",
  Clôturé: "#222d32",
  Archivé: "#3c8dbc",
};

export const _ANNUAIRE_STAT_BASES = {
  "Utilisateur(s)": {
    icon: <FaUsers />,
    path: _ROUTER_DOM_URL.USER,
  },
  "Client(s)": {
    icon: <IoIosBusiness />,
    path: _ROUTER_DOM_URL.SOCIETE,
  },
  "Fournisseur(s)": {
    icon: <IoIosBusiness />,
    path: _ROUTER_DOM_URL.SOCIETE,
  },
  "Contact(s) client(s)": {
    icon: <BiSolidContact />,
    path: _ROUTER_DOM_URL.ADMIN_CONTACT,
  },
  "Actifs et configurations": {
    icon: <BsBoxes />,
    path: _ROUTER_DOM_URL.ACTIF_CONFIGURATION_LIST,
  },
};
