import { SUBSCRIPTION_TYPE_INVERSE } from "../../../../utils/constants";
import { Typography } from "@mui/material";
import { isNull } from "lodash";
import moment from "moment";
import { sizeColumnMuiTable } from "../../../../utils/actif-config/actif_configuration_constants";

const { tinyText, sizeMediumText, minSizeMediumText } = sizeColumnMuiTable;
/*
 * Columns for Messaging usefull in the ResponseListCatalogue.jsx
 * @returns Array
 * */
export const messagingColumns = () => [
  {
    accessorKey: "societeProduitServiceGsuite.customerDomain",
    header: "Domaine",
    size: sizeMediumText,
    Cell: ({ row }) => {
      const tmpCustomerDomain =
        row.original.societeProduitServiceGsuite?.customerDomain;

      return (
        <Typography>
          {!isNull(tmpCustomerDomain) ? tmpCustomerDomain : ""}
        </Typography>
      );
    },
  },
  {
    accessorKey: "societeProduitServiceGsuite.subscriptionType",
    header: "Type d'abonnement",
    size: sizeMediumText,
    enableSorting: false,
    Cell: ({ row }) => {
      const subscriptionType =
        row.original.societeProduitServiceGsuite?.subscribtionType;

      return (
        <Typography>
          {!isNull(subscriptionType)
            ? SUBSCRIPTION_TYPE_INVERSE[subscriptionType]
            : ""}
        </Typography>
      );
    },
  },

  {
    accessorKey: "societeProduitServiceGsuite.subscriptionDate",
    header: "Anniversaire",
    size: minSizeMediumText,
    enableSorting: false,
    Cell: ({ row }) => {
      let tmpSubscription =
        row.original.societeProduitServiceGsuite?.subscribtionDate;

      return (
        <Typography>
          {isNull(tmpSubscription)
            ? "00-00-0000"
            : moment(tmpSubscription).format("DD-MM-YYYY")}
        </Typography>
      );
    },
  },
  {
    accessorKey: "societeProduitServiceGsuite.licensedNumberOfSeats",
    header: "Licences",
    size: tinyText,
    enableSorting: false,
    Cell: ({ row }) => {
      let licensedNumberOfSeats =
        row.original.societeProduitServiceGsuite?.licensedNumberOfSeats;

      return (
        <Typography>
          {!isNull(licensedNumberOfSeats) ? licensedNumberOfSeats : ""}
        </Typography>
      );
    },
  },
];
