import { isNull } from "lodash";
import { Link, Typography } from "@mui/material";
import { Box, IconButton } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { _ROUTER_DOM_URL, _ROUTES } from "../../../../utils/_routes_constants";
import { _ACTIONS } from "../../../../utils/constants";
import { createToast } from "../../../../utils/toast";
import { RequestHelper } from "../../../../utils/requestHelper";
import {
  _POSTE_NOT_ASSOCIED,
  _GSUITE_NOT_ASSOCIED,
  sizeColumnMuiTable,
} from "../../../../utils/actif-config/actif_configuration_constants";
import { aliasColumnConfiguration } from "../../../../utils/actif-config/actif_configuration_constants";
const requestHelper = new RequestHelper();

const {
  actions,
  minSizeLongText,
  sizeMediumText,
  minSizeMediumText,
  tinyText,
} = sizeColumnMuiTable;

const deleteActif = (id, setIsRetching, setResponseActif) => {
  /* refetching progress bar */
  setIsRetching(true);
  requestHelper
    .doRequest(_ACTIONS.DELETE, `${_ROUTES.SOCIETE_PRODUIT_SERVICE}/${id}`)
    .then(() => {
      createToast("success", "Suppression effectuée avec succès");
      /* remove actif from the list */
      setResponseActif((prevState) => {
        return prevState.filter((actif) => actif.id !== id);
      });
      /* disable refetching progress bar */
      setIsRetching(false);
    })
    .catch((error) => {
      setIsRetching(false);
      createToast("error", "Une erreur est survenue lors de la suppression");
    });
};

// COLS WHICH DISPLAY P | S CONFIG FIXED IN DB //
/**
 * Generates an array of column configurations based on the provided `config` object.
 *
 * @param {Object} config - The configuration object.
 * @returns {Array} An array of column configurations.
 */
const defaultColsPsConfig = (config) => {
  let columns = [];
  if (!config) return [];

  // EXCLUDE SPECIAL COLUMNS FOR THE MAIN TREATMENT //
  const specialKeys = ["bridge", "dynhost", "lignesupport", "iplocal"];

  // PROCESS REGULAR COLUMN
  for (const [key, value] of Object.entries(config)) {
    if (value === true) {
      const parsedKey = key.replace("champs", "").toLowerCase().trim();
      if (specialKeys.includes(parsedKey)) {
        // IGNORE SPECIAL COLUMNS
        continue;
      }

      columns.push({
        accessorKey: parsedKey,
        header: aliasColumnConfiguration[key],
        minSize: minSizeMediumText,
        maxSize: sizeMediumText,
        enableSorting: true,
        Cell: ({ row }) => {
          return (
            <Typography>
              {isNull(row.original[key]) ? "" : row.original[parsedKey]}
            </Typography>
          );
        },
      });
    }
  }

  // MANNUALY ADD SPECIAL COLUMNS
  if (config.champsBridge) {
    columns.push({
      id: "bridge",
      accessorKey: "bridge",
      header: "Mode bridge",
      align: "center",
      size: tinyText,
      enableSorting: false,
      Cell: ({ row }) => {
        const color = row.original.bridge ? "green" : "red";

        return <Box sx={{ backgroundColor: color, width: 15, height: 15 }} />;
      },
    });
  }

  if (config.champsDynhost) {
    columns.push({
      id: "dynhost",
      accessorKey: "dynhost",
      header: "DynDNS",
      enableSorting: false,
      size: 50,
      Cell: ({ row }) => {
        return (
          <Link
            sx={{ cursor: "pointer" }}
            href={`http://${row.original.dynhost.join(".")}`}
            target="_blank"
          >
            {isNull(row.original.dynhost) ? "" : row.original.dynhost.join(".")}
          </Link>
        );
      },
    });
  }

  if (config.champsLigneSupport) {
    columns.push({
      id: "ligneSupport",
      accessorKey: "ligneSupport",
      header: "Ligne Support",
      enableSorting: true,
      size: minSizeMediumText,
      Cell: ({ row }) => {
        return (
          <Link sx={{ cursor: "pointer" }}>
            {isNull(row.original.ligneSupport) ? "" : row.original.ligneSupport}
          </Link>
        );
      },
    });
  }

  if (config.champsIpLocal) {
    columns.push({
      id: "ipLocal",
      accessorKey: "ipLocal",
      header: "IP LAN",
      enableSorting: true,
      size: 160,
      Cell: ({ row }) => {
        return (
          <Link sx={{ cursor: "pointer" }}>
            {isNull(row.original.ipLocal) ? "" : row.original.ipLocal}
          </Link>
        );
      },
    });
  }

  return columns;
};

/*
 * Common columns usefull in the ResponseListCatalogue.jsx
 * @returns Array
 * */
/**
 * Returns an array of common columns for the actifs configurations table.
 *
 * @param {Function} buildSwitchCell - Function to build the switch cell component.
 * @param {Function} navigate - Function to navigate to a specific URL.
 * @param {Function} setIsRetching - Function to set the fetching state.
 * @param {Function} setResponseActif - Function to set the actif response.
 * @param {Object} produitService - The produitService object.
 * @param {Object} selectedProduitService - The selected produitService object.
 * @returns {Array} - Array of common columns.
 */
export const commonColumns = (
  buildSwitchCell,
  navigate,
  setIsRetching,
  setResponseActif,
  produitService,
  selectedProduitService
) => {
  const commonCols = [
    {
      accessorKey: "actions",
      header: "Actions",
      grow: true,
      enableSorting: false,
      size: actions * 2,
      Cell: ({ row }) => {
        return (
          <Box display="flex" gap={1} alignItems={"center"}>
            <IconButton
              aria-label="edit"
              size="small"
              color="inherit"
              onClick={() =>
                navigate(
                  `${_ROUTER_DOM_URL.ACTIF_CONFIGURATION_EDIT}/${row.original.id}`
                )
              }
            >
              <FaRegEdit />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              color="inherit"
              onClick={() => {
                deleteActif(row.original.id, setIsRetching, setResponseActif);
              }}
            >
              <RiDeleteBin5Line />
            </IconButton>
          </Box>
        );
      },
    },
    {
      accessorKey: "societe.name",
      header: "Tiers",
      grow: true,
      minSize: minSizeLongText,
      Cell: ({ row }) => {
        return (
          <Typography>
            {isNull(row.original.societe?.name)
              ? "N/A"
              : row.original.societe?.name}
          </Typography>
        );
      },
    },
    {
      accessorKey: "produitServiceCategorie.name",
      header: "Catégorie",
      grow: true,
      size: sizeMediumText,
      minSize: minSizeMediumText,
      Cell: ({ row }) => {
        return (
          <Typography>
            {isNull(row.original.produitServiceCategorie?.name)
              ? "N/A"
              : row.original.produitServiceCategorie?.name}
          </Typography>
        );
      },
    },
    // @TODO: COLS ALREADY INCLUDED IN PRODUIT SERVICE CONFIG, CHECK IF KEEP ? //
    // {
    //   accessorKey: "societe.ville",
    //   header: "Lieu",
    //   grow: true,
    //   size: sizeMediumText,
    //   minSize: minSizeMediumText,
    //   Cell: ({ row }) => {
    //     return (
    //       <Typography>
    //         {isNull(row.original.societe.ville)
    //           ? ""
    //           : row.original.societe.ville}
    //       </Typography>
    //     );
    //   },
    // },
    {
      accessorKey: "decompteTemps",
      header: "Décompte temps",
      enableSorting: false,
      size: actions,
      Cell: ({ row, table }) => buildSwitchCell(row, "decompteTemps", table),
    },
    {
      accessorKey: "isActive",
      header: "Etat",
      enableSorting: false,
      size: actions,
      Cell: ({ row }) => buildSwitchCell(row, "isActive"),
    },
    ...defaultColsPsConfig(produitService?.produitServiceConfig),
  ];

  if (
    selectedProduitService.id === _POSTE_NOT_ASSOCIED ||
    selectedProduitService.id === _GSUITE_NOT_ASSOCIED
  ) {
    commonCols.splice(0, 1);
  }

  return commonCols;
};
