import { useContext, useMemo } from "react";
import { Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RequestHelper } from "../../../utils/requestHelper";
import { _ROUTES } from "../../../utils/_routes_constants";
import { _ACTIONS, _MESSAGES } from "../../../utils/constants";
import { createToast } from "../../../utils/toast";
import { ActifsConfigurationsContext } from "../../../contexts/ActifsConfigurationsContext";
import { commonColumns } from "./dynamicsColumns/commonColumns";
import { antivirusAntispamColumns } from "./dynamicsColumns/antivirusColumns";
import { messagingColumns } from "./dynamicsColumns/messagingColumns";
import { routerFirewallColumns } from "./dynamicsColumns/routerFirewallColumns";
import { vpnColumns } from "./dynamicsColumns/vpnColumns";
import { hebergementColumns } from "./dynamicsColumns/hebergementcolumns";
import { sauvegardeColumns } from "./dynamicsColumns/sauvegardeColumns";
import { logicielColumns } from "./dynamicsColumns/logicielcolumns";
import { telephonieColumns } from "./dynamicsColumns/telephonieColumns";
import { useEffect } from "react";
import { _POSTE_NOT_ASSOCIED } from "../../../utils/actif-config/actif_configuration_constants";

export const ColonnesActifConfiguration = ({ selectedProduitService }) => {
  const requestHelper = new RequestHelper();
  const {
    setResponseActif,
    responseActif,
    setIsRefetching,
    produitService,
    setProduitService,
  } = useContext(ActifsConfigurationsContext);
  const navigate = useNavigate();

  function getProduitService() {
    if (selectedProduitService.id === null) return;
    // IF CATEGORIE IS GSUITE => PRODUCT ID PARENT IS 9
    const id = selectedProduitService.id;
    requestHelper
      .doRequest(_ACTIONS.GET, `${_ROUTES.PRODUIT_SERVICE}/${id}`)
      .then((response) => {
        setProduitService(response.data);
      })
      .catch(() => {
        createToast("error", _MESSAGES.error);
      });
  }

  useEffect(() => {
    if (selectedProduitService.id !== _POSTE_NOT_ASSOCIED) {
      getProduitService();
    }
  }, [selectedProduitService]);

  const changeStatus = (id, field, checked) => {
    /* refetching progress bar */
    setIsRefetching(true);
    requestHelper
      .doRequest(_ACTIONS.PUT, `${_ROUTES.SOCIETE_PRODUIT_SERVICE}/${id}`, {
        [field]: !checked,
      })
      .then(() => {
        createToast("success", "Statut modifié avec succès");
        const updatedActifs = responseActif.map((actif) => {
          /* find actif and refresh the new status */
          if (actif.id === id) {
            return { ...actif, [field]: !checked };
          }

          return actif;
        });
        setResponseActif(updatedActifs);
        /* disable refetching progress bar */
        setIsRefetching(false);
      })
      .catch(() => {
        createToast(
          "error",
          "Une erreur est survenue lors de la mise à jour du statut"
        );
      });
  };

  // Méthode pour construire une cellule de type Switch avec gestion du changement de statut
  /**
   * Builds a switch cell for a given row and field.
   *
   * @param {Object} row - The row object.
   * @param {string} field - The field name.
   * @returns {JSX.Element} - The switch cell JSX element.
   */
  const buildSwitchCell = (row, field) => {
    return (
      <Switch
        checked={row.original[field]}
        color="success"
        name={field}
        onChange={() =>
          changeStatus(row.original.id, field, row.original[field])
        }
      />
    );
  };

  /**
   * Generates columns for the product service based on the provided name.
   *
   * @param {string} name - The name of the product service.
   */
  const generateColumnsForProductService = (name) => {
    const common = commonColumns(
      buildSwitchCell,
      navigate,
      setIsRefetching,
      setResponseActif,
      produitService,
      selectedProduitService
    );
    switch (name) {
      case "ACCÈS A INTERNET":
        return [...common];
      case "ANTIVIRUS / ANTISPAM":
        return [...common, ...antivirusAntispamColumns()];
      case "MESSAGERIES":
        return [...common, ...messagingColumns()];
      case "ROUTEUR / FIREWALL":
        return [...common, ...routerFirewallColumns];
      case "VPN":
        return [...common, ...vpnColumns()];
      case "HÉBERGEMENT":
        return [...common, ...hebergementColumns()];
      case "SAUVEGARDES":
        return [...common, ...sauvegardeColumns()];
      case "LOGICIELS":
        return [...common, ...logicielColumns()];
      case "TÉLÉPHONIE":
        return [...common, ...telephonieColumns()];
      default:
        return [...common];
    }
  };

  const columns = useMemo(
    () =>
      [...generateColumnsForProductService(selectedProduitService.name)].map(
        (col) => ({
          ...col,
        })
      ),
    [
      selectedProduitService.id,
      selectedProduitService.name,
      responseActif,
      produitService,
    ]
  );

  return columns;
};
