import { _ACTIONS, _HTTP } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import _ from "lodash";

const requestHelper = new RequestHelper();

/**
 * Fetches data from the server using the specified parameters and updates the state with the retrieved data.
 *
 * @param {Object} params - The parameters to be sent with the request.
 * @param {Function} actions - The function to update the state with the retrieved data.
 * @param {string} errroMessage - The error message to be displayed if the request fails.
 * @param {string} route - The route to send the request to.
 */
export const getDataOvh = (params, actions, errorMessage, route) => {
    requestHelper
        .doRequest(_ACTIONS.GET, `${route}`, params)
        .then((response) => {
            if (_HTTP.ok !== response.status) {
                throw new Error(errorMessage);
            }
            _.isUndefined(response.data["hydra:member"]) ? actions(response.data) : actions(response.data["hydra:member"]);
        })
        .catch((error) => {
            createToast("error", error.message);
        });
};

/**
 * Updates data in OVH.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string} errroMessage - The error message to throw if the response status is not ok.
 * @param {string} route - The route for the request.
 */
export const putDataOvh = (params, errroMessage, route) => {
    requestHelper
        .doRequest(_ACTIONS.PUT, `${route}`, params)
        .then((response) => {
            if (_HTTP.ok !== response.status) {
                throw new Error(errroMessage);
            }
            createToast("success", "Modification effectuée avec succès");
        })
        .catch((error) => {
            createToast("error", error.message);
        });
}