import _ from "lodash";
import { minutesInHours } from "../../../utils/function";
import { _JOURS_SEMAINE } from "../../../utils/constants";

/**
 * Export data for a specific day for a team.
 * 
 * @param {Array} datas - The data to be exported.
 * @returns {Array} - The exported data.
 */
export const exportDataDayEquipe = (datas) => {
    const exportDatas = [];
    datas.forEach((item) => {
        exportDatas.push({
            equipe: item.user,
            h_par_jour_df: item.day ? minutesInHours(item.day.facturable) : 0,
            h_par_jour_dr: item.day ? minutesInHours(item.day.reel) : 0,
            h_par_semaine_df: item.week ? minutesInHours(item.week.facturable) : 0,
            h_par_semaine_dr: item.week ? minutesInHours(item.week.reel) : 0,
            h_par_mois_df: item.month ? minutesInHours(item.month.facturable) : 0,
            h_par_mois_dr: item.month ? minutesInHours(item.month.reel) : 0,
            h_par_an_df: item.year ? minutesInHours(item.year.facturable) : 0,
            h_par_an_dr: item.year ? minutesInHours(item.year.reel) : 0,
        });
    });

    return exportDatas;
};

/**
 * Export data for a week for a team.
 * 
 * @param {Array} datas - The data to export.
 * @returns {Array} - The exported data.
 */
export const exportDataWeekEquipe = (datas) => {
    const exportDatas = [];
    datas.forEach((item) => {
        exportDatas.push({equipe: item.user});
        _JOURS_SEMAINE.forEach((element) => {
            exportDatas[exportDatas.length - 1][element + "_facturable"] = item[element] ? minutesInHours(item[element].facturable) : 0;
            exportDatas[exportDatas.length - 1][element + "_réel"] = item[element] ? minutesInHours(item[element].reel) : 0;
        });
        exportDatas[exportDatas.length - 1]["total_facturable"] = item.totalSemaineFacturable ? minutesInHours(item.totalSemaineFacturable) : 0;
        exportDatas[exportDatas.length - 1]["total_réel"] = item.totalSemaineReel ? minutesInHours(item.totalSemaineReel) : 0;
    });

    return exportDatas;
};

/**
 * Export data for a given month and equipe.
 * 
 * @param {Array} datas - The data to export.
 * @param {number} nbDays - The number of days in the month.
 * @returns {Array} - The exported data.
 */
export const exportDataMonthEquipe = (datas, nbDays) => {
    const exportDatas = [];
    datas.forEach((item) => {
        exportDatas.push({equipe: item.user});
        for (let i = 1; i <= nbDays; i++) {
            exportDatas[exportDatas.length - 1][i + "_facturable"] = item[i] ? minutesInHours(item[i].facturable) : 0;
            exportDatas[exportDatas.length - 1][i + "_réel"] = item[i] ? minutesInHours(item[i].reel) : 0;
        }
        exportDatas[exportDatas.length - 1]["total_facturable"] = item.totalMoisEstimated ? minutesInHours(item.totalMoisEstimated) : 0;
        exportDatas[exportDatas.length - 1]["total_réel"] = item.totalMoisReal ? minutesInHours(item.totalMoisReal) : 0;
    });

    return exportDatas;
}

/**
 * Export data for a specific year and equipe.
 * 
 * @param {Array} datas - The data to be exported.
 * @returns {Array} - The exported data.
 */
export const exportDataYearEquipe = (datas) => {
    const exportDatas = [];
    datas.forEach((item )=> {
        exportDatas.push({equipe: item.user});
        for (let i = 1; i <= 12; i++) {
            exportDatas[exportDatas.length - 1][i + "_facturable"] = item[i] ? minutesInHours(item[i].facturable) : 0;
            exportDatas[exportDatas.length - 1][i + "_réel"] = item[i] ? minutesInHours(item[i].reel) : 0;
        }
        exportDatas[exportDatas.length - 1]["total_facturable"] = item.totalAnneeFacturable? minutesInHours(item.totalAnneeFacturable) : 0;
        exportDatas[exportDatas.length - 1]["total_réel"] = item.totalAnneeReel ? minutesInHours(item.totalAnneeReel) : 0;
    });

    return exportDatas;
};



