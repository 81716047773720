import Swal from "sweetalert2";
import { _ROUTES } from "./_routes_constants";
import { _ACTIONS } from "./constants";
import { RequestHelper } from "./requestHelper";
import withReactContent from "sweetalert2-react-content";
import { editInstantRequest } from "./function";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";

export const sendEmail = (action) => {
  Swal.fire({
    title: "Envoyer un email",
    text: "Voulez-vous envoyer un email à " + action?.user?.email + " ?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Envoyer",
    cancelButtonText: "Annuler",
  }).then((result) => {
    if (result.isConfirmed) {
      // Send Email
      const rq = new RequestHelper();
      rq.doRequest(_ACTIONS.POST, _ROUTES.SEND_EMAIL, {
        action: action.id,
      }).then((res) => {
        Swal.fire(
          res.data.result ? "Email envoyé" : "Erreur",
          res.data.message,
          res.data.result ? "success" : "error"
        );
      });
    }
  });
};

export const editDescription = (action, setTicket) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: "Modifier la description de l'action",
    html: (
      <CKEditor
        editor={Editor}
        data={action?.description}
        onChange={(event, editor) => {
          const data = editor.getData();
          setTicket((prev) => {
            return {
              ...prev,
              actions: prev.actions.map((act) => {
                if (act.id === action.id) {
                  act.description = data;
                }

                return act;
              }),
            };
          });
        }}
      />
    ),
    showCancelButton: true,
    confirmButtonText: "Modifier",
    cancelButtonText: "Annuler",
  }).then((result) => {
    if (result.isConfirmed) {
      editInstantRequest(action?.description, action["@id"], "description");
    }
  });
};
