import { PatternFormat } from 'react-number-format'; 
import { TextField, InputAdornment } from "@mui/material";
import { MdLocalPhone } from "react-icons/md";
 
/**
 * NumericFormatCustom component formats the phone number based on the provided format.
 *
 * @param {object} formik - The formik object containing form values and functions.
 * @returns {JSX.Element} - The formatted phone number input field.
 */
 export const NumericFormatCustom = ({formik}) => {
    const phone = formik.values.phone; 
    let format = "+## (#) ## ## ## ##";
  
    if(phone !== null) {
    format = String(phone).substring(0,1) === "+" ? '+## # ## ## ## ##' : '## ## ## ## ##';
    }

    return (<PatternFormat
              value={phone}
              format={format} 
              customInput={TextField} 
              mask="_"
              allowEmptyFormatting
              fullWidth
              sx={{ marginTop: 2 }} 
              id="phone"
              label="Téléphone fixe"
              InputProps={{
                startAdornment: <InputAdornment position="start" sx={{fontSize: "24px"}}><MdLocalPhone /></InputAdornment>
              }}
              onChange={formik.handleChange}
            /> 
      );
  }