import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { FIXED_PRODUIT_SERVICE_CONFIG } from "../../../utils/actif-config/actif_configuration_constants";
import ToolTipInfo from "../../utils/ToolTipInfo";

export function FormBuilderProduitServiceConfig({
  formik,
  produitServiceConfig,
}) {
  // Filtration des champs à afficher selon la configuration
  const filterFields = (fieldType) => {
    if (!produitServiceConfig) return [];

    return FIXED_PRODUIT_SERVICE_CONFIG[fieldType].filter(
      (field) => produitServiceConfig[field.fieldConfig]
    );
  };

  const textFields = filterFields("textFields");
  const arrayFields = filterFields("arrayFields");
  const booleanFields = filterFields("booleanFields");

  return (
    <Grid container spacing={1} justifyContent="center" marginTop={1.2}>
      {[...textFields].map((field) => (
        <Grid item xs={12} md={8} key={field.name}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div" fontWeight={"bold"}>
                {field.label}
              </Typography>
            </Grid>
            <Grid item xs={field.size}>
              <TextField
                fullWidth
                id={field.name}
                label={field.label}
                name={field.name}
                onChange={formik.handleChange}
                value={formik.values[field.name] || ""}
                size="small"
                variant="outlined"
                error={
                  formik.touched[field.name] &&
                  Boolean(formik.errors[field.name])
                }
                helperText={
                  formik.touched[field.name] && formik.errors[field.name]
                }
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
      {[...arrayFields].map((field) => {
        return (
          <>
            <Grid item xs={12} md={8} key={field.name}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    component="div"
                    fontWeight={"bold"}
                  >
                    {field.label}
                  </Typography>
                </Grid>
                <Grid item xs={field.size}>
                  <TextField
                    fullWidth
                    id={field.name}
                    label={field.label}
                    name={field.name}
                    onChange={formik.handleChange}
                    value={formik.values[field.name]}
                    size="small"
                    variant="outlined"
                    error={
                      formik.touched[field.name] &&
                      Boolean(formik.errors[field.name])
                    }
                    helperText={
                      formik.touched[field.name] && formik.errors[field.name]
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <ToolTipInfo message="Exprimés en une liste d'une ou plusieurs adresses mac séparées par des virgules." />
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      })}
      {booleanFields.map((field) => (
        <Grid item xs={12} md={8} key={field.name}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div" fontWeight={"bold"}>
                {field.label}
              </Typography>
            </Grid>
            <Grid item xs={field.size}>
              <FormControl fullWidth size="small">
                <InputLabel id={`${field.name}-label`}>
                  {field.label}
                </InputLabel>
                <Select
                  labelId={`${field.name}-label`}
                  id={field.name}
                  name={field.name}
                  value={formik.values[field.name]}
                  label={field.label}
                  onChange={formik.handleChange}
                  error={
                    formik.touched[field.name] &&
                    Boolean(formik.errors[field.name])
                  }
                >
                  <MenuItem value={true}>Oui</MenuItem>
                  <MenuItem value={false}>Non</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
