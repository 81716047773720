import { Box } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { _COLORS } from "../../../utils/constants";
import { getMuiTableConfig } from "../../../utils/muiTableBuilder";
import { useContext, useEffect, useState } from "react";
import { ColumnsTicketList } from "./ColumnsTicketList";
import { DataTicketList } from "./DataTicketList";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import _ from "lodash";
import { TicketsListContext } from "../../../contexts/TicketsListContext";
import { TicketItemDetail } from "./TicketItemDetail";
import BasicModal from "../../helpers/BasicModal";
import { TicketHistory } from "../TicketHistory";
import { useNavigate } from "react-router-dom";

export const TicketList = () => {
  const { open } = useContext(GlobalContext);
  const [columns, setColumns] = useState([]);
  const [ticketLogs, setTicketLogs] = useState([]);
  const [openHistory, setOpenHistory] = useState(false);
  const navigate = useNavigate();
  const {
    rowCount,
    data,
    isLoaded,
    isError,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    isLoading,
    filtersList,
    refresh,
    setRefresh,
  } = useContext(TicketsListContext);

  // Get the table configuration
  const c = getMuiTableConfig(
    // Params
    { enableColumnFilters: true, enableExpanding: true },
    // States & setStates (from context) Have to be the same as this
    {
      rowCount,
      isLoaded,
      isError,
      pagination,
      setPagination,
      columnFilters,
      setColumnFilters,
      globalFilter,
      setGlobalFilter,
      sorting,
      setSorting,
      isLoading,
    }
  );

  useEffect(() => {
    // Set the columns for the table
    // After the filtersList is loaded from Context (API or window.localStorage)
    if (!_.isEmpty(filtersList)) {
      setColumns(
        ColumnsTicketList(
          filtersList,
          refresh,
          setRefresh,
          openHistory,
          setOpenHistory,
          setTicketLogs,
          navigate
        )
      );
    }
  }, [filtersList]);

  // Get the table
  const table = useMaterialReactTable({
    columns: columns,
    data: data,
    ...c,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    renderDetailPanel: ({ row }) => {
      // Create Component for the detail panel
      return <TicketItemDetail row={row} />;
    },
    initialState: {
      columnVisibility: {
        "produitService.name": false,
        "produitServiceCategorie.name": false,
        createdAt: false,
        closedAt: false,
        estimedEndAt: false,
        "technicien.name": false,
        "etat.name": false,
      },
      density: "compact",
    },
  });

  return (
    <>
      <DataTicketList />
      <BasicModal
        showModal={openHistory}
        setShowModal={setOpenHistory}
        title="Historique du ticket"
        content={<TicketHistory ticketLogs={ticketLogs}></TicketHistory>}
      ></BasicModal>
      <Box
        sx={{
          width: "100%",
          maxWidth: open ? window.innerWidth - 340 : window.innerWidth - 64,
          borderRadius: "1px",
          boxShadow: `0px 0px 2px ${_COLORS.grey} `,
          overflowX: "auto",
        }}
      >
        {/* Table Component is here */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {!_.isEmpty(table) && <MaterialReactTable table={table} />}
        </LocalizationProvider>
      </Box>
    </>
  );
};
