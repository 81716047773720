import { Grid, Divider, Box, Chip } from "@mui/material";
import InfoGenerales from "./InfoGenerales";
import Consommation from "./Consommation";
import LigneAssocieeCompte from "./LignesAssocieesCompte";
import NumerosAssociesCompte from "./NumerosAssociesCompte";
import FaxAssociesCompte from "./FaxAssociesCompte";
import { useEffect, useState } from "react";
import { getDataOvh } from "../../../repository/telephonies/Ovh";
import { _ROUTES } from "../../../utils/_routes_constants";

const InformationGroup = ({group, client}) => {
    const [nbLines, setNbLines] = useState(0);
    const [nbNumeros, setNbNumeros] = useState(0);
    const [nbFaxs, setNbFaxs] = useState(0);
    const [phonebook, setPhonebook] = useState([]);

    useEffect(() => {
        getDataOvh(
            {billingAccount: group?.billingAccount}, 
            setNbLines, 
            "Une erreur est survenue lors de la récupération des données nombre de lignes",
            _ROUTES.GROUP_OVH_NB_LIGNES
        );
        getDataOvh(
            {billingAccount: group?.billingAccount}, 
            setNbNumeros, 
            "Une erreur est survenue lors de la récupération des données nombre de numéros",
            _ROUTES.GROUP_OVH_NB_NUMEROS
        );
        getDataOvh(
            {billingAccount: group?.billingAccount}, 
            setNbFaxs, 
            "Une erreur est survenue lors de la récupération des données nombre de fax",
            _ROUTES.GROUP_OVH_NB_FAX
        );
    }, [group]);

    return(
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                   <InfoGenerales 
                        group={group} 
                        phonebook={phonebook} 
                        setPhonebook={setPhonebook} 
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Consommation group={group} />
                </Grid>
            </Grid>
            <Divider sx={{background: "orange", marginTop: "30px", height: "2px"}} /> 
            <Box sx={{marginTop: "20px"}}>
                <Chip 
                    label={"Nombre de lignes : " + nbLines}
                    variant="outlined" 
                    sx={{marginRight: "10px"}}
                /> 
                <Chip 
                    label={"Nombre de numéros : " + nbNumeros}
                    variant="outlined" 
                    sx={{marginRight: "10px"}}
                /> 
                <Chip 
                    label={"Nombre de faxs : " + nbFaxs}
                    variant="outlined" 
                    sx={{marginRight: "10px"}}
                />  
            </Box>    
            <Grid sx={{marginTop: "20px"}}  >
                <LigneAssocieeCompte group={group} nbLines={nbLines} phonebook={phonebook} />
            </Grid>
            <Grid sx={{marginTop: "50px"}} >
                <NumerosAssociesCompte group={group} nbNumeros={nbNumeros} client={client} />
            </Grid>
            <Grid sx={{marginTop: "50px"}} >
                <FaxAssociesCompte group={group} nbFaxs={nbFaxs} />
            </Grid>
        </>
    )
};

export default InformationGroup;