import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

const Loader = ({ height }) => {
  const [trigger, setTrigger] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count === 10) {
      setTrigger(true);
    }
  }, [count]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: height ? height : "100vh",
      }}
    >
      {trigger ? (
        // Secret Loader color swap gradient
        <>
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
          />
        </>
      ) : (
        // Default Loader color
        <CircularProgress onClick={() => setCount(count + 1)} color="success" />
      )}
    </div>
  );
};

export default Loader;
