import React from "react";
import FormTitle from "../../components/helpers/FormTitle";
import FormFilerActifConfig from "../../components/actifs-configurations/FormFilerActifConfig";
import ResponseListCatalogue from "../../components/actifs-configurations/ResponseListCatalogue";
import ActifsConfigurationsContextProvider from "../../contexts/ActifsConfigurationsContext";

const ActifConfigList = () => {
  return (
    <ActifsConfigurationsContextProvider>
      <FormTitle title="Gestion des actifs" />
      <FormFilerActifConfig />
      <ResponseListCatalogue />
    </ActifsConfigurationsContextProvider>
  );
};

export default ActifConfigList;
