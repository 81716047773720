import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Loader from "../../utils/Loader";
import { useEffect, useState } from "react";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { LiaFileUploadSolid } from "react-icons/lia";
import { _ROUTES } from "../../../utils/_routes_constants";
import { _ACTIONS, _HTTP } from "../../../utils/constants";
import { RequestHelper } from "../../../utils/requestHelper";
import { createToast } from "../../../utils/toast";
import BasicModal from "../../helpers/BasicModal";
import PurchasesList from "./Modals/PurchasesList";
import StyledBadge from "../../utils/StyleBadge";
import { RiDeleteBin6Line } from "react-icons/ri";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { LiaEdit } from "react-icons/lia";
import { isDisabledTicket } from "../../../utils/function";

export const TicketEditVentes = (ticket) => {
  const [purchases, setPurchases] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const requestHelper = new RequestHelper();
  const _ERROR =
    "Une erreur est survenue lors de la récupération du produit dans Dolibarr.";
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Retrieves the Dolibarr product for the given purchase.
   *
   * @param {Object} purchase - The purchase object.
   */
  const getDolibarrProduct = (purchase) => {
    let queryData = {};
         queryData = {
            ...queryData,
            dolProduct: purchase.dolProduct,
            choice: 'ref',
          };
    requestHelper
      .doRequest(_ACTIONS.GET, _ROUTES.DOL_PRODUCT, queryData)
      .then((res) => {
        if ([200, 201].includes(res.status)) {
          purchase.dolProduct = res.data;
          setPurchases((prev) => [...prev, purchase]);
        } else {
          createToast("error", _ERROR);
        }
      })
      .catch((err) => {
        createToast("error", _ERROR);
      });
  };

  useEffect(() => {
    setPurchases([]);
    const actions = ticket?.ticket?.actions;
    if (actions) {
      actions.forEach((action) => {
        if (action.purchases && action.purchases.length > 0) {
          action.purchases.forEach((purchase) => {
            getDolibarrProduct(purchase);
          });
        }
      });
    }
  }, [ticket]);

  function deletePurchase(purchase) {
    setOpen(false);
    try {
      requestHelper
        .doRequest(
          _ACTIONS.DELETE,
          `${_ROUTES.TICKET_PURCHASE}/${purchase?.id}`
        )
        .then((response) => {
          if ([_HTTP.noContent].includes(response.status)) {
            createToast("success", "Vente supprimée avec succès");
            setPurchases(
              purchases.filter((element) => element.id !== purchase.id)
            );
          } else {
            createToast("error", "Erreur lors de la suppression de la vente");
          }
        });
    } catch (error) {
      createToast("error", "Erreur lors de la suppression de la vente");
    }
  }

  return (
    <>
      <BasicModal
        showModal={showModal}
        modalWidth="800px"
        setShowModal={setShowModal}
        title="Liste des ventes"
        width={1500}
        content={PurchasesList(purchases)}
      ></BasicModal>
      {ticket?.ticket ? (
        <Card sx={{ mt: 2 }}>
          <Typography sx={{ m: 2, fontSize: "1rem" }} color="text.secondary">
            <strong>Liste des ventes</strong>
            <StyledBadge
              title={"Détails des ventes"}
              badgeContent={purchases.length}
              color="primary"
            >
              <ShoppingCartIcon
                onClick={() => setShowModal(true)}
                sx={{ cursor: "pointer" }}
              />
            </StyledBadge>
          </Typography>
          {purchases.map((purchase, index) => {
            return (
              <Typography
                key={index}
                title={"Prix: " + purchase.price + "€"}
                sx={{ padding: 0.5 }}
              >
                <Box>
                  <BiPurchaseTagAlt />{" "}
                  {purchase.dolLine > 0 && <LiaFileUploadSolid />}{" "}
                  {purchase.quantity} x {purchase.dolProduct}
                  {isDisabledTicket(ticket.ticket) && (
                    <span>
                      <RiDeleteBin6Line
                        title="Supprimer la vente"
                        size="20"
                        style={{
                          cursor: "pointer",
                          float: "right",
                          marginRight: "10px",
                          color: "red",
                        }}
                        onClick={handleClickOpen}
                      />
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Voulez-vous vraiment supprimer cette vente ?"}
                        </DialogTitle>
                        <DialogActions>
                          <Button onClick={handleClose}>Non</Button>
                          <Button
                            onClick={() => deletePurchase(purchase)}
                            autoFocus
                            style={{ color: "red" }}
                          >
                            Oui
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <LiaEdit
                        title="Modifier la vente"
                        size="20"
                        style={{
                          cursor: "pointer",
                          float: "right",
                          marginRight: "10px",
                          color: "green",
                        }}
                        align="right"
                        onClick={null}
                      />
                    </span>
                  )}
                </Box>
              </Typography>
            );
          })}
        </Card>
      ) : (
        <Card sx={{ mt: 2 }}>
          {/* LOADER */}
          <Loader height={100} />
        </Card>
      )}
    </>
  );
};

export default TicketEditVentes;
