import React, { useEffect, useState } from "react";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS, _HYDRAMEMBERS, _MESSAGES } from "../../utils/constants";
import { createToast } from "../../utils/toast";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { _ROUTES } from "../../utils/_routes_constants";
import Loader from "../utils/Loader";
import { _TABLE_PRODUIT_SERVICE_CONFIG_VALUES } from "../../utils/produit-service/_CONSTANTS";
import { TableRowConfig } from "./TableRowConfig";

/**
 * Component used to display the product service settings table
 *
 * @component
 * @param {Object} props.currentItem - The current item.
 * @param {Function} props.setCurrentItem - The function to set the current item.
 * @param {Object} props.newConfig - The new configuration.
 */
const ConfigurationTable = ({ currentItem, setCurrentItem, newConfig }) => {
  const rQ = new RequestHelper();
  const [newChamps, setNewChamps] = useState([]);
  const [currentValues, setCurrentValues] = useState(null);

  // FETCH ALL CONFIGURATIONS FIELD //
  useEffect(() => {
    rQ.doRequest(_ACTIONS.GET, `${_ROUTES.PRODUIT_SERVICE_CONFIGURATION}`).then(
      (res) => {
        if (res.status === 200) {
          setNewChamps(res.data[_HYDRAMEMBERS]);
        } else {
          createToast(
            "error",
            "Une erreur est survenue lors de la récupération des données"
          );
        }
      }
    );
  }, [newConfig]);

  // FETCH VALUES PER PRODUIT SERVICE CONFIG //
  useEffect(() => {
    if (currentItem) {
      rQ.doRequest(_ACTIONS.GET, `${_ROUTES.PRODUIT_SERVICE_CONFIG_VALUES}`, {
        produitService: currentItem.id,
      }).then((res) => {
        setCurrentValues(res.data[_HYDRAMEMBERS]);
        if (res.status !== 200) {
          createToast(
            "error",
            "Une erreur est survenue lors de la récupération des données"
          );
        }
      });
    }
  }, [newChamps, currentItem]);

  /**
   * Handles the checkbox change event for a specific configuration.
   *
   * @param {string} configName - The name of the configuration.
   */
  const handleCheckboxChange = (configName) => async (event) => {
    const checked = event.target.checked;
    const psConfigId = currentItem.produitServiceConfig.id;
    const fieldExists =
      _TABLE_PRODUIT_SERVICE_CONFIG_VALUES.hasOwnProperty(configName);
    const toastSuccessMessage = fieldExists
      ? "La configuration a bien été modifiée"
      : "La configuration a bien été ajoutée";
    const toastErrorMessage = _MESSAGES.error;

    try {
      if (fieldExists) {
        // UPDATE FIELD IN PRODUIT SERVICE CONFIG ENTITY (FIXED FIELD)//
        const res = await rQ.doRequest(
          _ACTIONS.PUT,
          `${_ROUTES.PRODUIT_SERVICE_CONFIG}/${psConfigId}`,
          { [configName]: checked }
        );

        if (res.status === 200) {
          setCurrentItem({
            ...currentItem,
            produitServiceConfig: {
              ...currentItem.produitServiceConfig,
              [configName]: checked,
            },
          });
          createToast("success", toastSuccessMessage);
        } else {
          throw new Error(toastErrorMessage);
        }
      } else {
        // INSERT FIELD IN PRODUIT SERVICE CONFIG VALUES ENTITY (DYNAMIC FIELD)//
        const data = {
          value: checked,
          produitServiceConfiguration: configName.id,
          produitService: currentItem.id,
        };
        const res = await rQ.doRequest(
          _ACTIONS.POST,
          `insert-ps-config-values`,
          data
        );

        if (res.status === 200) {
          newChamps.find((champ) => champ.id === configName.id).value = checked;
          setNewChamps([...newChamps]);
          createToast("success", toastSuccessMessage);
        } else {
          throw new Error(toastErrorMessage);
        }
      }
    } catch (error) {
      createToast("error", toastErrorMessage);
    }
  };

  (!currentItem?.produitServiceConfig || currentValues) && <Loader />;

  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={{ width: "90%" }}>
        <Table sx={{ width: "90%" }} aria-label="config table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "300px" }}>Configuration</TableCell>
              <TableCell align="center">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(_TABLE_PRODUIT_SERVICE_CONFIG_VALUES).map((item) => {
              return (
                <TableRowConfig
                  item={item}
                  label={_TABLE_PRODUIT_SERVICE_CONFIG_VALUES[item]}
                  currentObj={!!currentItem?.produitServiceConfig[item]}
                  handleCheckboxChange={handleCheckboxChange}
                  requestHelper={rQ}
                  key={item}
                />
              );
            })}
            {newChamps.map((item) => {
              return (
                <TableRowConfig
                  item={item}
                  label={item.label}
                  currentObj={
                    currentValues?.find(
                      (value) =>
                        value.produitServiceConfiguration.id === item.id
                    )?.value || false
                  }
                  handleCheckboxChange={handleCheckboxChange}
                  requestHelper={rQ}
                  setNewChamps={setNewChamps}
                  key={item.id}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ConfigurationTable;
