import _ from "lodash";
import { useEffect } from "react";
import { useContext } from "react";
import { _ACTIONS } from "../../../utils/constants";
import { _ROUTES } from "../../../utils/_routes_constants";
import { RequestHelper } from "../../../utils/requestHelper";
import { TicketsListContext } from "../../../contexts/TicketsListContext";

export const TicketEditInfoDataGetter = ({
  ticket,
  listTechniciens,
  setListTechniciens,
  listClients,
  setListClients,
  listFournisseurs,
  setListFournisseurs,
  listProduitsServices,
  setListProduitsServices,
  listPriorities,
  setListPriorities,
  listOrigin,
  setListOrigin,
  listInvoiceType,
  setListInvoiceType,
  listType,
  setListType,
  listStatus,
  setListStatus,
  setListContacts,
  setListFournisseurContacts,
}) => {
  const { filtersList } = useContext(TicketsListContext);
  const rq = new RequestHelper();

  function dataGetter(route, params, setter) {
    if (!_.isUndefined(setter)) {
      rq.doRequest(_ACTIONS.GET, route, params).then((res) => {
        setter(res.data["hydra:member"]);
      });
    }
  }

  useEffect(() => {
    if (_.isEmpty(listTechniciens)) {
      dataGetter(
        _ROUTES.USER,
        {
          "order[firstName]": "asc",
          "societe.id": 210, // @TODO: get the id from the ENV
          isEnabled: 1,
          pagination: 0,
        },
        setListTechniciens
      );
    }
    if (_.isEmpty(listClients)) {
      dataGetter(
        _ROUTES.SOCIETE,
        {
          "order[name]": "asc",
          pagination: 0,
          "groups[]": "societeSelect:get",
        },
        setListClients
      );
    }
    if (_.isEmpty(listFournisseurs)) {
      dataGetter(
        _ROUTES.FOURNISSEUR,
        {
          "order[name]": "asc",
          pagination: 0,
        },
        setListFournisseurs
      );
    }
    if (_.isEmpty(listProduitsServices)) {
      dataGetter(
        _ROUTES.PRODUIT_SERVICE,
        {
          "order[name]": "asc",
          isActive: 1,
          pagination: 0,
          "groups[]" : "produitServiceSelect:get"
        },
        setListProduitsServices
      );
    }
    if (_.isEmpty(listPriorities) && filtersList.priority) {
      setListPriorities(filtersList.priority);
    }
    if (_.isEmpty(listOrigin)) {
      dataGetter(
        _ROUTES.TICKETS_ORIGIN,
        {
          "order[name]": "asc",
          pagination: 0,
        },
        setListOrigin
      );
    }
    if (_.isEmpty(listInvoiceType) && filtersList.facturation) {
      setListInvoiceType(filtersList.facturation);
    }
    if (_.isEmpty(listType)) {
      dataGetter(
        _ROUTES.TICKETS_TYPE,
        {
          "order[name]": "asc",
          pagination: 0,
        },
        setListType
      );
    }
    if (_.isEmpty(listStatus)) {
      dataGetter(
        _ROUTES.TICKETS_STATUS,
        {
          "order[name]": "asc",
          pagination: 0,
          "groups[]": "statusSelect:get"
        },
        setListStatus
      );
    }

    setListContacts(ticket?.societe ? ticket?.societe?.contacts : []);
    dataGetter(
      _ROUTES.CONTACT_FOURNISSEUR,
      {
        "fournisseur.id": ticket?.fournisseur?.id,
        "order[firstName]": "asc",
      },
      setListFournisseurContacts
    );
  }, [ticket, filtersList]);

  return <></>;
};
