import React from "react";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { _COLORS } from "../../utils/constants";

const ButtonCatalogue = styled(Button)(({ theme, fontSize, color }) => ({
  color: color ? color : _COLORS.defaultGreen,
  borderColor: color ? color : _COLORS.defaultGreen,
  backgroundColor: _COLORS.lightGrey,
  ":hover": {
    backgroundColor: `${color ? color : _COLORS.hoverDefaultGreen} !important`,
    border: `1px ${color ? color : _COLORS.hoverDefaultGreen} !important`,
  },
  fontSize: fontSize ? fontSize : "0.75rem",
  display: "flex",
  flexDirection: "column",
}));

const CustomButtonCatalogue = ({
  title,
  fontSize,
  icon,
  size,
  onClick,
  color,
}) => {
  return (
    <ButtonCatalogue
      fontSize={fontSize}
      onClick={onClick}
      color={color ? color : "success"}
      variant="outlined"
      size={size ? size : "medium"}
      startIcon={icon}
      sx={{
        color: color ? color : _COLORS.highGrey,
      }}
    >
      {title}
    </ButtonCatalogue>
  );
};

export default CustomButtonCatalogue;
