import {
  _STATE_FILTER_OPT,
  _TICKET_PRIORITY,
} from "../../../utils/_tickets_constants";
import { _ACTIONS, _COLORS, _DATE_FORMAT } from "../../../utils/constants";
import {
  DateFormatter,
  TextFormatter,
  TooltipFormatter,
} from "../../../utils/muiTableHelper";
import { RefTicketFormatter } from "../../../utils/ticket_utils";
import { _ROUTER_DOM_URL, _ROUTES } from "../../../utils/_routes_constants";
import { RequestHelper } from "../../../utils/requestHelper";
import moment from "moment";
import { Box } from "@mui/material";

/**
 * Columns for the ticket list
 *
 * @param {*} filtersList (Columns Filters from the context)
 * @returns
 */
export const ColumnsTicketList = (
  filtersList,
  refresh,
  setRefresh,
  openHistory,
  setOpenHistory,
  setTicketLogs,
  navigate
) => {
  const rq = new RequestHelper();

  // Getting my columns filters from the context
  const priorityOptions = filtersList?.priority?.map((p) => {
    return p.name;
  });

  const typeOptions = filtersList?.type?.map((t) => {
    return t.name;
  });

  const produitServiceOptions = filtersList?.produitService?.map((p) => {
    return p.name;
  });

  const categorieOptions = filtersList?.category?.map((c) => {
    return c.name;
  });

  const technicienOptions = filtersList?.technicien?.map((t) => {
    return t.name;
  });

  const tiersOptions = filtersList?.tiers?.map((t) => {
    return t.name;
  });

  const facturationOptions = filtersList?.facturation?.map((f) => {
    return f.name;
  });

  const columns = [
    {
      header: "Ticket N°",
      accessorKey: "refTicket",
      enableColumnFilterModes: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },

      Cell: ({ row }) => {
        return (
          <RefTicketFormatter
            setOpenHistory={setOpenHistory}
            setTicketLogs={setTicketLogs}
            row={row}
            navigate={navigate}
          ></RefTicketFormatter>
        );
      },
    },
    {
      header: "Priorité",
      accessorKey: "priority.name",
      enableColumnFilterModes: false,
      filterable: true,
      filterVariant: "select",
      filterSelectOptions: priorityOptions,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ row }) => {
        const rowData = row.original;
        let color = "";

        switch (rowData.priority.name) {
          case _TICKET_PRIORITY.LOW:
            color = _COLORS.success;
            break;
          case _TICKET_PRIORITY.NORMAL:
            color = _COLORS.warning;
            break;
          case _TICKET_PRIORITY.URGENT:
            color = _COLORS.error;
            break;
        }

        return (
          <TooltipFormatter
            text={rowData?.priority.name}
            color={color}
            title={"Changer la priorité"}
            onClick={() => {
              const data = {
                priority:
                  rowData.priority.id < 3
                    ? "/api/ticket_priorities/" +
                      (parseInt(rowData.priority.id) + 1)
                    : "/api/ticket_priorities/1",
              };

              rq.doRequest(
                _ACTIONS.PUT,
                _ROUTES.TICKETS + "/" + rowData.id,
                data
              ).then((res) => {
                setRefresh(refresh++);
              });
            }}
          />
        );
      },
    },
    {
      header: "Type",
      accessorKey: "type.name",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        return (
          <TextFormatter
            text={row.original.type?.name}
            options={{ fontWeight: "bold" }}
          />
        );
      },
      filterVariant: "select",
      filterSelectOptions: typeOptions,
      // align title of column
      muiTableHeadCellProps: {
        align: "left",
      },
      // align content of column
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Produit | Service",
      accessorKey: "produitService.name",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        return (
          <TextFormatter
            text={row.original.produitService?.name}
            options={{ fontWeight: "bold" }}
          />
        );
      },
      filterVariant: "autocomplete",
      filterSelectOptions: produitServiceOptions,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Catégorie",
      accessorKey: "produitServiceCategorie.name",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        return (
          <TextFormatter
            text={row.original.produitServiceCategorie?.name}
            options={{ fontWeight: "bold" }}
          />
        );
      },
      filterVariant: "autocomplete",
      filterSelectOptions: produitServiceOptions,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Date de création",
      accessorKey: "createdAt",
      Cell: ({ row }) => {
        let momentDate = moment(
          row.original.createdAt,
          _DATE_FORMAT.datetime_exact
        );

        return momentDate ? <DateFormatter momentDate={momentDate} /> : "";
      },
      filterVariant: "date-range", //only allow these filter modes
      filterFn: "between",
      enableColumnFilterModes: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Date MàJ",
      accessorKey: "updatedAt",
      Cell: ({ row }) => {
        let momentDate = moment(
          row.original.updatedAt,
          _DATE_FORMAT.datetime_exact
        );

        return momentDate ? <DateFormatter momentDate={momentDate} /> : "";
      },
      filterVariant: "date-range", //only allow these filter modes
      filterFn: "between",
      enableColumnFilterModes: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Date clôture",
      accessorKey: "closedAt",
      Cell: ({ row }) => {
        let momentDate = moment(
          row.original.closedAt,
          _DATE_FORMAT.datetime_exact
        );

        return momentDate ? <DateFormatter momentDate={momentDate} /> : "";
      },
      filterVariant: "date-range", //only allow these filter modes
      filterFn: "between",
      enableColumnFilterModes: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Date de résolution estimée",
      accessorKey: "estimedEndAt",
      Cell: ({ row }) => {
        let momentDate = moment(
          row.original.estimedEndAt,
          _DATE_FORMAT.datetime_exact
        );

        return momentDate ? <DateFormatter momentDate={momentDate} /> : "";
      },
      filterVariant: "date-range", //only allow these filter modes
      filterFn: "between",
      enableColumnFilterModes: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Technicien",
      accessorKey: "user.firstName",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        let technicien = row.original?.user
          ? row.original?.user?.firstName + " " + row.original?.user?.lastName
          : "";

        return <TextFormatter text={technicien} />;
      },
      filterVariant: "select",
      filterSelectOptions: technicienOptions,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Tiers",
      accessorKey: "societe.name",
      Cell: ({ row }) => {
        return <TextFormatter text={row.original.societe?.name} />;
      },
      filterVariant: "autocomplete",
      filterSelectOptions: tiersOptions,
      enableColumnFilterModes: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Contact(s)",
      accessorFn: (row) => {
        const contact = row.societe?.referentClient
          ? row.societe?.referentClient?.lastName +
            " " +
            row.societe?.referentClient?.firstName
          : "";

        return <TextFormatter text={contact} />;
      },
      enableSorting: false,
      enableColumnFilter: false,
      enableColumnFilterModes: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Objet",
      accessorKey: "subject",
      sortable: false,
      enableColumnFilterModes: false,
      minSize: 300,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ row }) => {
        return (
          <Box
            onClick={() => {
              navigate(_ROUTER_DOM_URL.TICKET_EDIT + row.original.id);
            }}
            // Navigate to the ticket detail
          >
            <TextFormatter
              options={{
                color: _COLORS.link,
                cursor: "pointer",
              }}
              text={row.original.subject}
            />
          </Box>
        );
      },
    },
    {
      header: "Fact.",
      accessorKey: "invoiceType.name",
      Cell: ({ row }) => {
        return <TextFormatter text={row.original.invoiceType.name} />;
      },
      filterVariant: "select",
      filterSelectOptions: facturationOptions,
      enableColumnFilterModes: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Durée*",
      id: "duration",
      Cell: ({ row }) => {
        const actions = row.original?.actions;

        const sumReal =
          actions
            ?.map((action) => action.durationReal)
            .reduce((a, b) => a + b, 0) ?? 0;

        const sumEstimated =
          actions
            ?.map((action) => action.durationEstimated)
            .reduce((a, b) => a + b, 0) ?? 0;

        return (
          <TooltipFormatter
            text={sumEstimated + " | " + sumReal}
            color={_COLORS["midGrey"]}
          />
        );
      },
      filterable: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Actions",
      id: "actions",
      Cell: ({ row }) => {
        const actions = row.original?.actions;

        return (
          <TooltipFormatter
            text={actions?.length ?? 0}
            color={_COLORS["midGrey"]}
          />
        );
      },
      filterable: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "État",
      accessorKey: "status.name",
      Cell: ({ row }) => {
        return (
          <TextFormatter
            text={row.original.status.name}
            options={{ fontWeight: "bold" }}
          />
        );
      },
      filterVariant: "select",
      filterSelectOptions: _STATE_FILTER_OPT,
      enableColumnFilterModes: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
  ];

  return columns;
};
