import { CONTRATS } from "../../utils/constants";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import { _ROUTES } from "../../utils/_routes_constants";
import ListContrats from "../../components/contrats/ListContrats";
import { getDataContratConfig } from "../../repository/contrats/ContratConfig";
import { useLocation } from "react-router-dom";

const Contrats = () => {
  const textDebut =
    "Pour commencer, merci de bien vouloir choisir un type de contrat...";
  const [typeContrat, setTypeContrat] = useState(0);
  const [contratTypes, setContratTypes] = useState([]);
  const [isAlert, setIsAlert] = useState(true);
  const [nomContrat, setNomContrat] = useState();
  const [reload, setReload] = useState(false);
  const location = useLocation();
  const typeContratId = location?.state?.typeContratId;

  const handleChange = (event) => {
    setTypeContrat(event.target.value);
    event.target.value !== 0 ? setIsAlert(false) : setIsAlert(true);
    if (event.target.value !== 0)
      setNomContrat(
        contratTypes?.find(
          (contratType) => contratType.id === event.target.value
        ).name
      );
  };

  useEffect(() => {
    if (typeContratId && contratTypes.length > 0) {
      setTypeContrat(typeContratId);
      const contrat = contratTypes.find((ct) => ct.id === typeContratId);
      if (contrat) {
        setNomContrat(contrat.name);
        setIsAlert(false);
      }
    }
  }, [contratTypes]);

  useEffect(() => {
    if (contratTypes.length === 0)
      getDataContratConfig(
        {},
        setContratTypes,
        "Une erreur est survenue lors de la récupération des types de contrats",
        _ROUTES.CONTRAT_TYPES
      );
  }, []);

  return (
    <>
      <Typography variant="h4">{CONTRATS.h1}</Typography>
      <Box sx={{ marginTop: "20px" }}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel key="A" id="contrats-label">
            Type de contrat
          </InputLabel>
          <Select
            labelId="contrats-label"
            id="contrat-select"
            value={typeContrat}
            onChange={handleChange}
          >
            <MenuItem key="0" value={0}>
              Veuillez choisir un contrat
            </MenuItem>
            {contratTypes?.map((contratType) => (
              <MenuItem key={contratType?.id} value={contratType?.id}>
                {contratType?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        {isAlert ? (
          <Alert severity="info">{textDebut}</Alert>
        ) : (
          <ListContrats
            contratTypes={contratTypes}
            typeContrat={typeContrat}
            nomContrat={nomContrat}
            reload={reload}
            setReload={setReload}
          />
        )}
      </Box>
    </>
  );
};

export default Contrats;
