import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { HEBERGEMENT_FORM_FIELD } from "../../../utils/actif-config/actif_configuration_constants";

/*
@TODO :
- fix default values isSsl 
*/
export const FormFieldHebergement = ({ formik, formStates, setFormStates }) => {
  useEffect(() => {
    setFormStates({
      ...formStates,
      isHebergementForm: true,
    });
  }, []);

  return (
    <>
      <Grid container spacing={1} justifyContent="center" marginTop={2}>
        {HEBERGEMENT_FORM_FIELD.map((field) => (
          <Grid item xs={12} md={8} key={field.name}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body1" component="div">
                  <strong>{field.label}</strong>
                </Typography>
              </Grid>
              <Grid item xs={field.size}>
                <TextField
                  fullWidth
                  size="small"
                  label={field.label}
                  name={field.name}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors[field.name])}
                  helperText={formik.errors[field.name]}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>SSL *</strong>
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth error={Boolean(formik.errors.isSsl)}>
                <InputLabel id="isSsl-label" size="small">
                  SSL
                </InputLabel>
                <Select
                  labelId="isSsl-label"
                  id="isSsl"
                  value={formik.values.isSsl}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="isSsl"
                  size="small"
                >
                  <MenuItem value={null}>{""}</MenuItem>
                  <MenuItem value={true}>Oui</MenuItem>
                  <MenuItem value={false}>Non</MenuItem>
                </Select>
                {formik.errors.isSsl ? (
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {formik.errors.isSsl}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormFieldHebergement;
