import { useMemo } from "react";

/**
 * Hook qui vérifie si un utilisateur possède un ou plusieurs rôles spécifiques.
 * @param {Object} user - L'objet utilisateur, qui contient les informations sur les rôles.
 * @param {Array|string} requiredRoles - Les rôles requis, soit une chaîne de caractères pour un rôle unique, soit un tableau de rôles.
 * @returns {boolean} - Vrai si l'utilisateur a tous les rôles requis, faux sinon.
 */
const useHasRoles = (user, requiredRoles) => {
  const hasRoles = useMemo(() => {
    if (!user || !user.roles) return false;

    const userRoles = Object.values(user.roles);
    if (Array.isArray(requiredRoles)) {
      // Vérifie si au moins un des rôles requis est présent dans les rôles de l'utilisateur
      return requiredRoles.some((role) => userRoles.includes(role));
    }

    return userRoles.includes(requiredRoles);
  }, [user, requiredRoles]);

  return hasRoles;
};

export default useHasRoles;
