import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { MdOutlineInfo } from "react-icons/md";
import Typography from "@mui/material/Typography";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ConsommationMensuelle from "./ConsommationMensuelle";

const Consommation = ({client, infosLicence}) => {

    return (
        <Card>
            <Box sx={{backgroundColor: "#00c0ef"}}>
                <Typography sx={{ padding:"3px", fontSize: "1rem", color: "white"}} >
                    <MdOutlineInfo style={{verticalAlign: 'text-bottom', marginRight: '5px'}} size="20"/>
                    <strong>Relevé de consommation</strong>
                </Typography>
            </Box>
            <TableContainer>
                <Table  sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Licence utilisée</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ConsommationMensuelle idMonth={4} client={client} infosLicence={infosLicence} />
                    <ConsommationMensuelle idMonth={3} client={client} infosLicence={infosLicence} />
                    <ConsommationMensuelle idMonth={2} client={client} infosLicence={infosLicence} />
                    <ConsommationMensuelle idMonth={1} client={client} infosLicence={infosLicence} />
                    <ConsommationMensuelle idMonth={0} client={client} infosLicence={infosLicence} />
                </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default Consommation;