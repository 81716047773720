import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { __FORM_STYLES } from "../../../utils/_style_constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useState } from "react";
import Editor from "ckeditor5-custom-build";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { _COLORS } from "../../../utils/constants";

export const TicketLoan = ({ ticket, setTicket, ressources, loan, index }) => {
  const [description, setDescription] = useState(
    ticket.reservations[index].description
  );

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
          <Box
            sx={{
              margin: "10px 0",
              border: "1px solid #F39C12",
              borderRadius: "5px",
              width: "80%",
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              justifyContent: "center",
            }}>
            <Box sx={{ width: "100%" }}>
              <FormControl sx={__FORM_STYLES.basicInput} size="small">
                <Autocomplete
                  id="ressource"
                  size="small"
                  name="ressource"
                  options={ressources}
                  getOptionLabel={(option) => {
                    return option ? option.type : "Veuillez choisir une option";
                  }}
                  onChange={(e, value) => {
                    let newTicket = { ...ticket };
                    newTicket.reservations[index].resource = value;
                    setTicket(newTicket);
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      name="ressource"
                      size="small"
                      sx={{ marginTop: "0px" }}
                      {...params}
                    />
                  )}></Autocomplete>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={__FORM_STYLES.basicInput} size="small">
                <TextField
                  label="Quantité"
                  name="quantity"
                  value={ticket.reservations[index].quantity}
                  onChange={(e) => {
                    let newTicket = { ...ticket };
                    newTicket.reservations[index].quantity = e.target.value;
                    setTicket(newTicket);
                  }}
                  variant="standard"
                  size="small"
                  inputProps={{
                    min: 1,
                    type: "number",
                  }}></TextField>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={__FORM_STYLES.basicInput} size="small">
                <FormLabel>Description</FormLabel>
                <CKEditor
                  data={description}
                  editor={Editor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                  onBlur={(event, editor) => {
                    let newTicket = { ...ticket };
                    newTicket.reservations[index].description = description;
                    setTicket(newTicket);
                  }}></CKEditor>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={__FORM_STYLES.basicInput} size="small">
                <FormLabel>Date de début</FormLabel>
                <DatePicker
                  value={ticket.reservations[index].startDate}
                  onChange={(date) => {
                    let newTicket = { ...ticket };
                    newTicket.reservations[index].startDate = date;
                    setTicket(newTicket);
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}></DatePicker>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={__FORM_STYLES.basicInput} size="small">
                <FormLabel>Date de fin</FormLabel>
                <DatePicker
                  value={ticket.reservations[index].endDate}
                  onChange={(date) => {
                    let newTicket = { ...ticket };
                    newTicket.reservations[index].endDate = date;
                    setTicket(newTicket);
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}></DatePicker>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}>
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: _COLORS.error,
                  color: "white",
                  margin: "5px",
                }}
                onClick={() => {
                  let newTicket = { ...ticket };
                  newTicket.reservations.splice(index, 1);
                  setTicket(newTicket);
                }}>
                Supprimer
              </Button>
            </Box>
          </Box>
        </Box>
      </LocalizationProvider>
    </>
  );
};
