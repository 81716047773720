import { 
    TypeLocation,  
    DevisDolibarr, 
    TypeOffre, 
    Lieu, 
    Fournisseurs, 
    DateEnvoi, 
    DateRetour } from "../forms-field/FormFieldLocation";
import { FormFieldProduitDolibar } from "../forms-field/FormsFieldProduitDolibar";
import { SocieteField, DateDebut, DateFin, DateAnniversaire, Field, Commentaire } from "../forms-field/FormField";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import _ from "lodash";
import { getDataDolibar } from "../../../repository/Dolibar";
import { _ROUTES } from "../../../utils/_routes_constants";
import { getDataRessource } from "../../../repository/Ressource";
import { getDataContratTypeOffre } from "../../../repository/contrats/ContratTypeOffre";
import { getDataFournisseur } from "../../../repository/Fournisseur";

const FormContratLocation = (props) => {
    const { formik, typeLocations, row, societes, setSelectedSociete, selectedSociete, handleFileChange, produitsServices } = props;
    const [choice, setChoice] = useState(false);
    const [devisList, setDevisList] = useState([]);
    const [contratTypeOffres, setContratTypeOffres] = useState([]);
    const [selectedTypeLocation, setSelectedTypeLocation] = useState(null);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [nomTypeLocation, setNomTypeLocation] = useState(null);


    useEffect(() => {
        if(row !== null || selectedSociete !== null) {
            const societeId = row !== null ? row.societe.id : selectedSociete?.id;
            getDataDolibar(
                {id: societeId}, 
                setDevisList, 
                "Une erreur est survenue lors de la récupération des devis", 
                _ROUTES.DOL_DEVIS_SOCIETE
            );  
        }
    }, [selectedSociete]);

    useEffect(() => {
        if(contratTypeOffres.length === 0) {
            getDataContratTypeOffre(
                {}, 
                setContratTypeOffres, 
                "Une erreur est survenue lors de la récupération des offres", 
                _ROUTES.TYPE_OFFRE
            );
        }
        if(fournisseurs.length === 0) {
            getDataFournisseur(
                {}, 
                setFournisseurs, 
                "Une erreur est survenue lors de la récupération des fournisseurs", 
                _ROUTES.FOURNISSEUR
            );
        }
    }, []);

    return (
        <>  
            { _.isNull(row) && (
            <TypeLocation 
                formik={formik} 
                typeLocations={typeLocations} 
                choice={choice} 
                setChoice={setChoice} 
                setSelectedTypeLocation={setSelectedTypeLocation}
                setNomTypeLocation={setNomTypeLocation} 
            />
            )}
            {(choice || !_.isNull(row)) && (
                <Box>
                { _.isNull(row) && (
                    <SocieteField formik={formik} societes={societes} setSelectedSociete={setSelectedSociete} />
                )}
                    <DevisDolibarr formik={formik} devisList={devisList}/>
                    {(selectedTypeLocation && selectedTypeLocation === '/api/contrat-type-locations/1') && (
                        <TypeOffre formik={formik} contratTypeOffres={contratTypeOffres} />
                    )}

                    <DateDebut formik={formik} />
                    <DateFin formik={formik} />
                    <DateAnniversaire formik={formik} />

                    {((selectedTypeLocation && selectedTypeLocation === '/api/contrat-type-locations/1') 
                        || (!_.isNull(row)  && row.contratTypeLocation === '/api/contrat-type-locations/1')) && (
                        <Box>
                            <DateEnvoi formik={formik} />
                            <DateRetour formik={formik} />
                        </Box>
                    )}
                    {((selectedTypeLocation && selectedTypeLocation !== '/api/contrat-type-locations/1') 
                        || (!_.isNull(row)  && row.contratTypeLocation !== '/api/contrat-type-locations/1')) && (
                            <FormFieldProduitDolibar formik={formik} produitsServices={produitsServices}/>
                    )} 
                    <Lieu formik={formik} />
                    {((selectedTypeLocation && selectedTypeLocation === '/api/contrat-type-locations/1') 
                        || (!_.isNull(row)  && row.contratTypeLocation === '/api/contrat-type-locations/1')) && (
                            <Fournisseurs formik={formik} fournisseurs={fournisseurs} />
                    )}
                    <Commentaire formik={formik} row={row} />
                    <Field handleFileChange={handleFileChange}/>
                </Box>
            )}
        </>
    );
};

export default FormContratLocation;