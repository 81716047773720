import React from "react";
import { _ACTIONS } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import { useNavigate } from "react-router-dom";
import { LuUserSquare } from "react-icons/lu";
import { MdOutlineToggleOff } from "react-icons/md";
import { MdOutlineToggleOn } from "react-icons/md";
import { IconButton, Typography } from "@mui/material";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";
import { createToast } from "../../utils/toast";
import { toUpper } from "lodash";

/**
 * Renders the columns for the contact table in the Contact.jsx.
 *
 * @param {Array} props.rows - The rows data for the table.
 * @param {Function} props.setRows - The function to update the rows data.
 * @param {number} props.value - The value indicating the type of contact (0 for contacts, 1 for suppliers).
 * @returns {Array} - The contact columns configuration.
 */
const ColonnesContact = (rows, setRows, value) => {
  const rq = new RequestHelper();
  const Navigate = useNavigate();

  /**
   * Toggles the status of a user.
   *
   * @param {number} id - The ID of the user.
   * @param {boolean} currentStatus - The current status of the user.
   * @returns {void}
   */
  const toggleUserStatus = (id, currentStatus) => {
    const route =
      value === 0
        ? `${_ROUTES.CONTACT}/${id}`
        : `${_ROUTES.CONTACT_FOURNISSEUR}/${id}`;

    rq.doRequest(_ACTIONS.PUT, route, { isEnabled: !currentStatus })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("La requête n’a pas abouti avec un statut 200");
        }
        const key = value === 0 ? "contacts" : "fournisseurs";
        const updatedData = rows[key].map((item) =>
          item.id === id ? { ...item, isEnabled: !currentStatus } : item
        );
        setRows({ ...rows, [key]: updatedData });
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  };
  const contactColumns = [
    {
      field: value === 0 ? "societe.name" : "fournisseur.name",
      headerName: value === 0 ? "Société" : "Fournisseur",
      width: 200,
      renderCell: (params) => {
        const companyType = value === 0 ? "societe" : "fournisseur";
        const companyName = params.row[companyType]?.name;

        if (!companyName) {
          return <Typography> </Typography>;
        }

        return <Typography fontWeight="bold">{companyName}</Typography>;
      },
    },
    {
      field: "firstName",
      headerName: "Nom et Prénom",
      width: 200,
      renderCell: (params) => {
        return (
          <Typography>
            {toUpper(params.row?.firstName) || ""} {params.row?.lastName || ""}
          </Typography>
        );
      },
    },
    {
      field: "poste",
      headerName: "Poste",
      type: "number",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phonePro",
      headerName: "Telephone mobile",
      type: "number",
      width: 150,
      renderCell: (params) => {
        return <Typography> {params.row?.phonePro || ""} </Typography>;
      },
    },
    {
      field: "phoneFix",
      headerName: "Telephone fixe",
      type: "number",
      width: 150,
      renderCell: (params) => {
        return <Typography> {params.row?.phoneFix || ""}</Typography>;
      },
    },
    {
      field: "createdAt",
      headerName: "Date de création",
      type: "number",
      width: 150,
      /* @TODO: Add a date formatter */
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <IconButton
            label="Edit"
            title="Mettre à jour"
            color="inherit"
            onClick={() => {
              if (value === 0) {
                Navigate(`${_ROUTER_DOM_URL.CONTACT_UPDATE}/${row.id}`);
              } else {
                Navigate(
                  `${_ROUTER_DOM_URL.CONTACT_FOURNISSEUR_UPDATE}/${row.id}`
                );
              }
            }}
          >
            <LuUserSquare />
          </IconButton>,
          <IconButton
            label="Edit"
            title="Activer / Désactiver"
            color="inherit"
            onClick={() => toggleUserStatus(row.id, row.isEnabled)}
          >
            {row.isEnabled ? (
              <MdOutlineToggleOn style={{ color: "green" }} />
            ) : (
              <MdOutlineToggleOff style={{ color: "red" }} />
            )}
          </IconButton>,
        ];
      },
    },
  ];

  return contactColumns;
};

export default ColonnesContact;
