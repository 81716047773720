import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { _ROUTES } from "../../../utils/_routes_constants";
import { Chip } from "@mui/material";
import { TbPointFilled } from "react-icons/tb";
import { getDataOvh } from "../../../repository/telephonies/Ovh";

const RecapTelephonie = ({line, linesDetails, queue, setQueue, lineNumber}) => {
    const [detailsLine, setDetailsLine] = useState({});

    useEffect(() => {
        if(queue !== null && linesDetails?.fkBuillding){
            setQueue(null);
            getDataOvh(
                {billingAccount: linesDetails?.fkBuillding, ligne: (line?.number) ?? lineNumber, queue: queue}, 
                setDetailsLine, 
                "Une erreur est survenue lors de la récupération des détails de la ligne", 
                _ROUTES.RECAP_OVH_TELEPHONIE_LINE_DETAILS
            );
        }
    }, [queue, linesDetails]);

    /**
     * Renders the status component.
     * @param {string} status - The status string.
     * @returns {JSX.Element} - The rendered status component.
     */
    const status = (status) => {
        const statusArray = status.split(";");
        const text = statusArray[1] ? statusArray[1] : '';
        return (
            <> 
                <TbPointFilled style={{verticalAlign:"middle"}}  size="30" color={statusArray[0]} /> <span>{text}</span>
            </>
        );
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'position',
                header: '',
                size: 50,
                Cell: ({row}) => (
                    <>  
                        <Chip variant="outlined" size="small" color="primary"  label ={row.original.position.position} />
                    </>
                ),
            },
            {
                accessorKey: 'agent',
                header: 'Téléphone | User-agent',
                size: 200,
                Cell: ({row}) => (
                    <>
                        {row.original.agentNumber}
                        <Chip sx={{marginLeft:"10px"}}   variant="outlined" size="small" color="primary"  label ={row.original.agent} />
                    </>
                ),
            },
            {
                accessorKey: 'name',
                header: 'Nom et Prénom',
            },
            {
                accessorKey: 'time',
                header: 'Temps (min)',
                Cell: ({row}) => (
                    <>
                        {row.original.time ?? "-"}
                    </>
                ),
            },
            {
                accessorKey: 'status',
                header: 'État',
                Cell: ({row}) => (
                    status(row.original.status)
                ),
            },
        ],
        []
    )

    const options = {
        columns,
        data: detailsLine['rows'] ?? [],
        enableFullScreenToggle: true,
    }

    const table = useMaterialReactTable(options);

    return (
        <>
            <MaterialReactTable table={table} />
        </>
    )
};

export default RecapTelephonie;