import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import { _TICKET_STATUS_ID } from '../../../utils/_tickets_constants';

export const CheckBoxContrat = (props) => {
    const [isChecked, setIsChecked] = useState(props.checked);
    const checkHandler = () => {
      props.checkContrat(props.contrat, !isChecked, setIsChecked); 
    };
    const isDisabled = props.ticket?.status.id === _TICKET_STATUS_ID.ARCHIVED_STATUS ? true : false;

    return (
      (isDisabled) ?
        <Checkbox 
            checked={isChecked} 
            onChange={checkHandler} 
            disabled
        />
        :
        <Checkbox 
            checked={isChecked} 
            onChange={checkHandler} 
        />
  );
}