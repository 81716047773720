import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Card, Divider, Typography, Alert, Chip } from "@mui/material";
import { _ROUTES } from "../../../utils/_routes_constants";
import { IoDesktopSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { getDataOvh } from "../../../repository/telephonies/Ovh";

const NumerosAssociesCompte = ({ group, nbNumeros, client }) => {
    const text = "aucun numéro associé à ce compte de facturation..."; 
    const [detailsNumeros, setDetailsNumeros] = useState([]); 
    const featureTypes = ['contactCenterSolution', 'contactCenterSolutionExpert', 'easyHunting'];
    const navigate = useNavigate();

    useEffect(() => {
        getDataOvh(
            {billingAccount: group?.billingAccount}, 
            setDetailsNumeros, 
            "Une erreur est survenue lors de la récupération des détails des numéros associées à ce compte de facturation", 
            _ROUTES.GROUP_OVH_NUMEROS_LIST
        );
    }, [group]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'numero',
                header: 'Numéro',
                Cell: ({row}) => (
                    <>
                        {row.original.numero} 
                        <Chip 
                            sx={{marginLeft:"10px"}} 
                            variant="outlined" 
                            size="small" color="primary"  
                            label ={row.original.numeroDetails['description']} 
                        />
                    </>
                ),
            },
            {
                accessorKey: 'typeConfiguration',
                header: 'Type de configuration',
                Cell: ({row}) => (
                    <>
                        {row.original.featureType} 
                        {row.original.resultRedirect && (
                            <Chip 
                                variant="outlined" 
                                sx={{marginLeft:"10px"}} 
                                size="small" 
                                color="primary"  
                                label ={row.original.resultRedirect} />
                        )}
                    </>
                ),
            },
            {
                accessorKey: 'currentOutplan',
                header: 'Hors forfait',
            },
            {
                accessorKey: 'action',
                header: 'Actions',
                Cell: ({row}) => (
                    <> {featureTypes.includes(row.original.fkFeatureType) && (
                        <IoDesktopSharp
                            style={{cursor: "pointer"}}
                            size="20px"
                            color="blue" 
                            title="Visualiser"
                            onClick={() => {navigate(`/recap/telephonie-ovh/`, {state: {group: group, client: client, line: row.original.numero}})}}
                        />
                    )}
                    </>
                ),
            },
        ],
    );

    const options = {
        columns,
        data: detailsNumeros['rows'] ?? [],
        enableFullScreenToggle: true,
        enableExpanding: true,
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
              },
          }),
    };

    options.renderDetailPanel  = ({ row }) => {
        return (
            <>  
                <Box sx={{marginBottom:"5px"}}><MdKeyboardArrowRight style={{verticalAlign:"text-top"}} />  Rio : {row.original.rio}</Box>
            </>
        );
    }

    const table = useMaterialReactTable(options);

    return (
        <>
            <Card sx={{borderTop: 3, borderTopColor: "orange"}}>
                <Box>
                    <Typography sx={{ padding:"5px", fontSize: "1rem", backgroundColor: "orange"}}>
                        <strong>Numéros associés à ce compte de facturation ({nbNumeros})</strong>
                    </Typography>
                </Box>
                <Divider sx={{background: "orange"}} />
                {nbNumeros !== 0 
                    ? (<MaterialReactTable table={table} />)
                    :(<Alert severity="info">{text}</Alert>)
                }  
            </Card>
        </>
    );
}

export default NumerosAssociesCompte;