import { Chip, Box, Divider } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useMemo } from "react";
import { MdOutlineComputer } from "react-icons/md";
import { useEffect, useState } from "react";
import { GoAlertFill } from "react-icons/go";
import { GoThumbsup } from "react-icons/go";
import { _DATE_FORMAT, _LICENCED_STATUS, _COMPUTER_STATUS } from "../../utils/constants";
import { GoAlert } from "react-icons/go";
import { GoCheck } from "react-icons/go";
import { RiComputerFill } from "react-icons/ri";
import { GoCircleSlash } from "react-icons/go";
import moment from "moment";
import { getDataBitdefender } from "../../repository/antivirus/Bitdefender";
import Loader from "../utils/Loader";
import { _ROUTES } from "../../utils/_routes_constants";

const Ordinateurs = ({client, setIsLoaded, isLoaded}) => {
    const [data, setData] = useState({});
    const [endPointList, setEndPointList] = useState([]);
    
    useEffect(() => {
        getDataBitdefender(
            {companyId: client.compagnies}, 
            setEndPointList, 
            "Une erreur est survenue lors de la récupération des infos d'utilisation mensuelle",
            _ROUTES.SOCIETE_BITDEFENDER_ENDPOINT_LIST
        );
    }, [client]);

    useEffect(() => {
        setData(endPointList?.items);
        setIsLoaded(true);
    }, [endPointList]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Nom',
                size: 150,
                Cell: ({row}) => (
                    <> 
                        {row.original.details?.length !== 0 && 
                            <RiComputerFill 
                                style={{verticalAlign: 'top', marginRight: '5px'}}
                                title={_COMPUTER_STATUS[row.original.details.state]['title']} 
                                color={_COMPUTER_STATUS[row.original.details.state]['color']} size="20px" />
                        } 
                        {row.original.name}
                    </>
                )
            },
            {
                accessorKey: 'agentInstalle',
                header: 'Agent installé',
                size: 150,
                Cell: ({row}) => (
                    <>
                        {row.original.isManaged 
                            ? (<Chip variant="outlined" size="small" label={"Oui"}  color='success' />)
                            : (<Chip variant="outlined" size="small" label={"Non"} color='error' />)
                        }
                    </>
                )
            },
            {
                accessorKey: 'pmInstalle',
                header: 'PM installé',
                size: 150,
                Cell: ({row}) => (
                    <>
                        {(row.original.details?.length !== 0 &&  row.original.details?.modules) &&
                            <>
                            {row.original.details?.modules.patchManagement
                                ? (<Chip variant="outlined" size="small" label={"Oui"}  color='success' />)
                                : (<Chip variant="outlined" size="small" label={"Non"} color='error' />)
                            }
                            </>
                        }
                    </>
                )
            },
            {
                accessorKey: 'fde',
                header: 'FDE',
                size: 150,
                Cell: ({row}) => (
                    <>
                        {row.original.details.length !== 0 &&
                            <>
                                {row.original.details?.modules?.encryption
                                    ? (<Chip variant="outlined" size="small" label={"Oui"}  color='success' />)
                                    : (<Chip variant="outlined" size="small" label={"Non"} color='error' />)
                                }
                            </>
                        }
                    </>
                )
            },
            {
                accessorKey: 'etatLicence',
                header: 'État de la licence',
                size: 150,
                Cell: ({row}) => (
                    <>  
                        {row.original.details.length !== 0 &&
                            <>
                                {row.original.details.agent.licensed === 1 && (<GoCheck style={{verticalAlign: 'top', marginRight: '5px'}} color="green" size="20px" />)}
                                {row.original.details.agent.licensed === 2 && (<GoCircleSlash  style={{verticalAlign: 'top', marginRight: '5px'}}color="red" size="20px" />)}
                                {(row.original.details.agent.licensed === 0 || row.original.details.agent.licensed === 6) && 
                                    (<GoAlert style={{verticalAlign: 'top', marginRight: '5px'}} color="orange" size="20px" />)
                                }
                                {_LICENCED_STATUS[row.original.details.agent.licensed]}
                            </>
                        }
                    </>
                )
            },
            {
                accessorKey: 'politique',
                header: 'Politique',
                size: 150,
                Cell: ({row}) => (
                    <>
                        {row.original.policy.applied 
                        ? (<GoThumbsup style={{verticalAlign: 'top', marginRight: '5px'}} size="18px" color="green" title="Politique appliquée" />)
                        : (<GoAlertFill style={{verticalAlign: 'top', marginRight: '5px'}} size="18px" color="red" title="Politique non appliquée" />)
                        }
                        {row.original.policy.name} {row.original.policy.applied}
                    </>
                )
            },
            {
                accessorKey: 'dernierChangement',
                header: 'Dernier changement',
                size: 150,
                Cell: ({row}) => (
                    <>
                        {row.original.details.length !== 0 &&
                            <>
                                {moment(row.original.details.lastSeen).format(_DATE_FORMAT.date_and_time_display_ss_no_bar)}
                            </>
                        }
                    </>
                )
            },
        ],
        []
    );
    
    const options = {
        columns,
        data : data ?? [],
        enableExpanding: true,
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
              },
          }),
    }

    options.renderDetailPanel  = ({ row }) => {
        return (
            <>  
                <strong>Description : </strong><br />
                - Système d'exploitation : {row.original.details.operatingSystem}<br />
                - IP LAN : {row.original.details.ip} <br />
                - Adresse mac : {row.original.macs[0]}

            </>
        );
    }

    const table = useMaterialReactTable(options);

    return (
        <>
            <Divider sx={{marginTop: "20px"}}/>
                <Chip sx={{margin: "5px"}} icon={<MdOutlineComputer size="20px" />} variant="outlined" color="primary" label={"Nbr des ordinateurs : " + endPointList?.total} />
            <Divider />
            <Box>
                <Chip size="small" sx={{margin: "5px"}} label="PM: Patch managment" />
                <Chip size="small" sx={{margin: "5px"}} label="FDE: FUll Disk Encryption" />
            </Box>
            { !isLoaded 
                ? <Loader height={400} />
                : <MaterialReactTable table={table} />
            }
        </>
    );
};

export default Ordinateurs;