import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { _ROUTES } from "../../utils/_routes_constants";
import { ActifsConfigurationsContext } from "../../contexts/ActifsConfigurationsContext";
import { isNull } from "lodash";
import { getDataSociete } from "../../repository/Societe";

/**
 * Searching component using in FormFilerActifConfig.jsx to search for a societe in the societeProduitServices.
 */
const SearchSocieteInSocieteProduitService = () => {
  const { societes, setSocietes, setSocieteId } = useContext(
    ActifsConfigurationsContext
  );

  /**
   * Searches for a societe in the societeProduitServices.
   * @param {string} value - The value to search for.
   */
  useEffect(() => {
    searchSociete();
  }, []);

  const searchSociete = (value) => {
    const params = {
      exists: { societeProduitServices: true },
      pagination: "disabled",
    };
    /* if user types in the search field add the name to the params */
    if (value) params.name = value;
    const actions = (data) => {
      const allSocietesOption = [
        { id: "", name: "Afficher toutes les sociétés" },
      ];
      setSocietes([...allSocietesOption, ...data]);
    }
    getDataSociete(params, actions, "Erreur de récupération des actifs", _ROUTES.SOCIETE);
  };

  return (
    <>
      <Grid container display={"flex"} gap={2} alignItems={"center"}>
        <Grid item xs={12} />
        <Grid item xs={0.5}>
          <Typography variant="body1" component="div" fontWeight={"bold"}>
            Client
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {societes && (
            <Autocomplete
              id="societe"
              name="societe"
              fullWidth
              size="small"
              options={societes ? societes : []}
              getOptionLabel={(option) => option.name}
              loading={societes.length === 0}
              onChange={(e, value) => {
                isNull(value) ? setSocieteId("") : setSocieteId(value.id);
              }}
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  label="Rechercher une société"
                  name="societe"
                  id="societe"
                  fullWidth
                  {...params}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SearchSocieteInSocieteProduitService;
