import { Grid, TextField } from "@mui/material";
import React, { useContext } from "react";
import { _COLORS } from "../../../utils/constants";
import { BaseConnaissanceContext } from "../../../contexts/BaseConnaissanceContext";

/**
 * Renders a search input component for filtering articles in the base-connaissance dashboard
 *
 */
export function SearchArticles() {
  const { setFilter } = useContext(BaseConnaissanceContext);

  return (
    <Grid
      item
      xs={12}
      width={"100%"}
      sx={{
        p: 1,
        mb: 2,
        display: "flex",
        justifyContent: "center",
        boxShadow: `4px 4px 4px ${_COLORS.midGrey}`,
        border: `1px solid ${_COLORS.lightBlue}`,
      }}
    >
      <Grid item xs={6}>
        <TextField
          id="outlined-basic"
          label="Que recherchez-vous ? Saisir quelque chose..."
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => setFilter(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
