import { Avatar, Box, Button, FormControl, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { _ACTIONS, _COLORS } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import { _ROUTES } from "../../utils/_routes_constants";
import { useForm } from "react-hook-form";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { __FORM_STYLES } from "../../utils/_style_constants";

export const AvatarUploader = ({ currentUser }) => {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [image, setImage] = useState(null);
  const rq = new RequestHelper();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setValue("avatar", file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = (data) => {
    data["user"] = currentUser.id;

    rq.doRequest(_ACTIONS.POSTFILE, _ROUTES.UPLOAD_AVATAR, data).then(
      (res) => {}
    );
  };

  useEffect(() => {
    if (currentUser?.avatar) {
      setImage(currentUser.userAvatar?.filePath);
    }
  }, [currentUser]);

  return (
    <>
      <form id="avatar" onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {image ? (
              <Avatar
                alt={currentUser?.firstName + " " + currentUser?.lastName}
                src={image ? image : ""}
                sx={{
                  width: 125,
                  height: 125,
                  borderRadius: "50%",
                  border: "1px solid " + _COLORS.grey,
                }}
              ></Avatar>
            ) : (
              <Avatar
                sx={{
                  width: 125,
                  height: 125,
                  borderRadius: "50%",
                }}
              >
                <PersonIcon />
              </Avatar>
            )}
          </Box>
          <input
            type="hidden"
            {...register("user", { required: true })}
            value={currentUser?.id}
          />
          <Box
            sx={{
              marginTop: "25px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControl>
              <Button variant="text" size="small" component="label">
                Choisir une photo <DownloadIcon />
                <input
                  type="file"
                  name="avatar"
                  hidden
                  onChange={handleImageChange}
                />
              </Button>
              {errors.avatar && (
                <Typography variant="caption" sx={__FORM_STYLES.errorMessage}>
                  Veuillez joindre un document
                </Typography>
              )}
            </FormControl>
            <Button
              id="submitAvatar"
              type="submit"
              variant="text"
              size="small"
              sx={{ fontWeight: "bold" }}
            >
              Valider la photo <FileDownloadDoneIcon />
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default AvatarUploader;
