import { ThemeProvider } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { dataGridTheme } from "../../utils/DataGridBuilder";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import NoRowsOverlay from "../helpers/NoRowOverlay";
import { ColonnesReservation } from "./ColonnesReservation";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS, _COLORS } from "../../utils/constants";
import { _ROUTES } from "../../utils/_routes_constants";
import { createToast } from "../../utils/toast";
import { CustomToolbar } from "../../utils/CustomToolbarDataGrid";

/**
 * Renders a table component for displaying reservations dashboard '/reservations'.
 *
 * @component
 * @param {string} props.typeRéservation - The type of reservation (1= externe, 0= interne).
 * @param {boolean} props.isOldRéservation - Indicates if the reservations are old or future.
 * @returns {JSX.Element} The rendered table component.
 */
export function RéservationMuiTable({ typeRéservation, isOldRéservation }) {
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const requestHelper = new RequestHelper();

  /**
   * Deletes a reservation by its ID.
   *
   * @param {number} id - The ID of the reservation to delete.
   */
  const doDeleteReservation = (id) => {
    requestHelper
      .doRequest(_ACTIONS.DELETE, `${_ROUTES.RESERVATION}/${id}`)
      .then((res) => {
        if (res.status !== 204) {
          createToast(
            "error",
            "Erreur lors de la suppression de la réservation."
          );
        }
        createToast("success", "Réservation supprimée avec succès.");
        allReservationsByParams();
      });
  };

  const columns = ColonnesReservation({
    typeRéservation: typeRéservation,
    doDeleteReservation: doDeleteReservation,
  });

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return "";
      default:
        return "";
    }
  };

  const getParams = () => {
    let actualDate = new Date();
    let status = getStatus(typeRéservation);
    let query = {
      status: status,
      pagination: "disabled",
      "exists[endDate]": true,
    };

    if (isOldRéservation) {
      // SI OLD RESERVATION VAUT TRUE => Récupérer les réservations passées
      query["endDate[before]"] = actualDate;
    } else {
      // SI OLD RESERVATION VAUT FALSE => Récupérer les réservations futures
      query["endDate[after]"] = actualDate;
    }

    return query;
  };

  const allReservationsByParams = () => {
    setIsLoading(true);
    const params = getParams();
    requestHelper
      .doRequest(_ACTIONS.GET, `${_ROUTES.RESERVATION}`, params)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data["hydra:member"];
          setReservations(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          createToast(
            "error",
            "Erreur lors de la récupération des données de l'entité."
          );
        }
      });
  };

  useEffect(() => {
    allReservationsByParams();
  }, []);

  return (
    <ThemeProvider theme={dataGridTheme}>
      <Box sx={{ height: 400, width: "100%", backgroundColor: _COLORS.white }}>
        <DataGrid
          rows={reservations || []}
          getRowId={(row) => row.id}
          columns={columns}
          loading={isLoading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          slots={{ toolbar: CustomToolbar, noRowsOverlay: NoRowsOverlay }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 50]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </ThemeProvider>
  );
}
