import * as Yup from "yup";
import { RequestHelper } from "../../../../utils/requestHelper";
import { createToast } from "../../../../utils/toast";
import { _HTTP } from "../../../../utils/constants";
import moment from 'moment';
import _ from 'lodash';

export const _LOAN_FORMIK = (ticket, setTicket, setOpenLoanModal, loan) => {
  const rq = new RequestHelper();
  const validationSchema = Yup.object().shape({
    resource: Yup.string().required("Ressource requise"),
    quantity: Yup.number()
      .min(1, "Veuillez entrer au moins 1 ressource")
      .required("Quantité requise"),
    startDate: Yup.date().required("Date de début requise"),
  });

  return {
    initialValues: {
      resource: loan?.resource ? loan?.resource['@id'] : "",
      quantity: loan?.quantity ? loan?.quantity : 1,
      ticket: ticket["@id"],
      description: loan?.description ? loan?.description : "",
      startDate: loan?.startDate ? moment(loan?.startDate) : null,
      endDate: loan?.endDate ? moment(loan?.endDate) : null,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const action = (_.isNull(loan) || _.isUndefined(loan)) ? "POST" : "PUT";
      const message = (_.isNull(loan)  || _.isUndefined(loan)) ? "Réservation ajoutée avec succès" : "Réservation modifiée avec succès";
      const url = (_.isNull(loan)  || _.isUndefined(loan)) ? "reservations" : `reservations/${loan.id}`;
      rq.doRequest(action, url, values).then((res) => {
        if ([_HTTP.ok, _HTTP.created].includes(res.status)) {
          createToast("success", message);
          if((_.isNull(loan)  || _.isUndefined(loan))){
          // Updating ticket state
          setTicket((prev) => {
            return {
              ...prev,
              reservations: [...prev.reservations, res.data],
            };
          });
        } else {
          // Updating ticket state
          setTicket((prev) => {
            return {
              ...prev,
              reservations: prev.reservations.map((reservation) => {
                if (reservation.id === res.data.id) {
                  return res.data;
                }
                return reservation;
              }),
            };
          });
        }
        setOpenLoanModal(false);
        } else {
          createToast("error", "Erreur lors de l'ajout/modification de la réservation");
        }
      });
    },
  };
};
