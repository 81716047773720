import { useState } from "react";
import { createContext } from "react";

export const DashboardGeneralContext = createContext({});

function DashboardGeneralContextProvider({ children }) {
  const [showModal, setShowModal] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const value = {
    showModal,
    setShowModal,
    alerts,
    setAlerts,
  };

  return (
    <DashboardGeneralContext.Provider value={value}>
      {children}
    </DashboardGeneralContext.Provider>
  );
}

export default DashboardGeneralContextProvider;
