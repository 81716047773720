import { useMemo } from "react";
// MUI //
import { IconButton, Switch } from "@mui/material";
// REACT ICONS //
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
// OTHERS //
import { _ROUTES } from "../../../utils/_routes_constants";
import { stripHtml } from "../../../utils/function";
import { createConfirmToast, createToast } from "../../../utils/toast";
import { RequestHelper } from "../../../utils/requestHelper";
import { isNull } from "lodash";
import { _ACTIONS } from "../../../utils/constants";

/**
 * Représentes les columns du tableau contenu des les lignes étendues du tableau de configuration de la base de connaissance.
 *
 * @param {Function} setSectionRows - The function to set the section rows.
 * @param {Function} setShowModal - The function to set the show modal state.
 * @param {Function} setSectionRow - The function to set the section row.
 * @param {Function} setIsSectionForm - The function to set the is section form state.
 * @returns {Array} - The columns configuration for the expanded row.
 */
export const ColonnesBsConfigurationExpandedRow = ({
  setSectionRows,
  setShowModal,
  setSectionRow,
  setIsSectionForm,
}) => {
  const requestHelper = new RequestHelper();

  const doUpdateIsInterne = async (id, isInterne) => {
    const res = await requestHelper.doRequest(
      "PUT",
      `${_ROUTES.BASE_CONNAISSANCE_SECTION}/${id}`,
      {
        isInterne: isInterne,
      }
    );

    if (res.status === 200) {
      setSectionRows((prevData) =>
        prevData.map((item) => {
          if (item.id === id) {
            return { ...item, isInterne: isInterne };
          }

          return item;
        })
      );
      createToast("success", "Section modifiée avec succès");
    } else {
      createToast("error", "Erreur lors de la suppression de la section");
    }
  };

  const doDeleteBsSection = async (id) => {
    createConfirmToast(
      "warning",
      null,
      "Êtes-vous sûr de vouloir supprimer la section ?",
      "Oui",
      "Non"
    ).then((res) => {
      if (res) {
        requestHelper
          .doRequest(
            _ACTIONS.DELETE,
            `${_ROUTES.BASE_CONNAISSANCE_SECTION}/${id}`
          )
          .then(() => {
            setSectionRows((prevData) =>
              prevData.filter((item) => item.id !== id)
            );
            createToast("success", "Section supprimée avec succès");
          })
          .catch((error) => {
            createToast("error", "Erreur lors de la suppression de la section");
          });
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        field: "title",
        headerName: "Section",
        flex: 1,
        renderCell: (params) => {
          return !isNull(params.value) ? params.value : "";
        },
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        renderCell: (params) => {
          return !isNull(params.value) ? stripHtml(params.value) : "";
        },
      },
      {
        field: "isInterne",
        headerName: "Interne",
        flex: 1,
        renderCell: (params) => {
          const checked = params.value;

          return (
            <Switch
              checked={checked}
              onChange={() => {
                doUpdateIsInterne(params.row.id, !checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ row }) => {
          return [
            <IconButton
              label="Edit"
              title="Mettre à jour la section"
              color="inherit"
              size="small"
              onClick={() => {
                setShowModal(true);
                setSectionRow(row);
                setIsSectionForm(true);
              }}
            >
              <FaEdit />
            </IconButton>,
            <IconButton
              label="Supprimer"
              title="Supprimer la section"
              color="error"
              size="small"
              onClick={() => doDeleteBsSection(row.id)}
            >
              <RiDeleteBin5Line />
            </IconButton>,
          ];
        },
      },
    ],
    []
  );

  return columns;
};
