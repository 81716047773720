import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Card, CardContent, Typography } from "@mui/material";
import { RequestHelper } from "../../../utils/requestHelper";
import { _HTTP, _ACTIONS } from "../../../utils/constants";
import { _ROUTES } from "../../../utils/_routes_constants";
import { createToast } from "../../../utils/toast";
import { CheckBoxContrat } from "./CheckBoxContrat";
import Chip from "@mui/material/Chip";
import { FaRegFilePdf } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";

export const TicketContratsBox = (ticket) => {
  const [listContrats, setListContrats] = useState([]);
  const requestHelper = new RequestHelper();

  /**
   * Renders a card for a given contrat.
   * @param {Object} contrat - The contrat object.
   * @returns {JSX.Element} - The card component.
   */
  const card = (contrat) => {
    const checked =
      ticket?.ticket?.contrat === contrat.id &&
      ticket?.ticket?.contratType === contrat.contractType
        ? true
        : false;
    const ref = contrat.contractRef
      ? "( Réf: " + contrat.contractRef + " )"
      : null;

    let infoColor = null;
    if (contrat.contractType === "cmt") {
      infoColor =
        contrat.creditTime > 0 ? (
          <Chip label={contrat.creditTime} variant="outlined" color="success" />
        ) : (
          <Chip label={contrat.creditTime} variant="outlined" color="error" />
        );
    }

    let file = null;
    if (contrat.contractFile) {
      file = (
        <FaRegFilePdf
          size="20"
          style={{
            cursor: "pointer",
            float: "right",
            marginTop: "13px",
            marginRight: "30px",
            color: "green",
          }}
        />
      );
    }

    let commentaire = null;
    if (contrat.commentaire)
      commentaire = (
        <FcInfo
          title={contrat.commentaire}
          size="20"
          style={{
            cursor: "pointer",
            float: "right",
            marginTop: "13px",
            marginRight: "0px",
            color: "blue",
          }}
        />
      );

    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <Typography fontSize={14}>
            <CheckBoxContrat
              checked={checked}
              checkContrat={checkContrat}
              contrat={contrat}
              ticket={ticket?.ticket}
            />{" "}
            {contrat.contractName} {ref} {infoColor} {commentaire} {file}
          </Typography>
        </Box>
      </>
    );
  };

  /**
   * Retrieves all contracts.
   */
  function getAllContracts() {
    requestHelper
      .doRequest(_ACTIONS.GET, _ROUTES.ALL_CONTRACTS, {
        societeId: ticket?.ticket?.societe?.id,
      })
      .then((res) => {
        if ([_HTTP.ok, _HTTP.created].includes(res.status)) {
          setListContrats(res.data);
        } else {
          createToast(
            "error",
            "Une erreur est survenue lors de la récupération des contrats."
          );
        }
      })
      .catch((err) => {
        createToast(
          "error",
          "Une erreur est survenue lors de la récupération des contrats." + err
        );
      });
  }

  /**
   * Checks the contrat and updates the state accordingly.
   * @param {Object} contrat - The contrat object to check.
   * @param {boolean} checked - Indicates whether the contrat is checked or not.
   * @param {function} setIsChecked - The state setter function to update the isChecked state.
   */
  function checkContrat(contrat, checked, setIsChecked) {
    checked
      ? ticket?.ticket?.contrat !== 0
        ? createToast(
            "warning",
            "Vous ne pouvez pas sélectionner plusieurs contrats."
          )
        : updateContrat(contrat, checked, setIsChecked)
      : updateContrat(null, checked, setIsChecked);
  }

  /**
   * Updates the contrat of a ticket.
   *
   * @param {Object} contrat - The contrat object to update.
   * @param {boolean} checked - The checked state.
   * @param {function} setIsChecked - The function to set the checked state.
   */
  function updateContrat(contrat, checked, setIsChecked) {
    requestHelper
      .doRequest(_ACTIONS.PUT, `${_ROUTES.TICKETS}/${ticket.ticket.id}`, {
        contrat: contrat ? contrat.id : 0,
        contratType: contrat ? contrat.contractType : null,
      })
      .then((res) => {
        if ([_HTTP.ok, _HTTP.created].includes(res.status)) {
          createToast("success", "Le ticket a été modifié avec succès");
          ticket.setTicket((prev) => {
            return {
              ...prev,
              contrat: contrat ? contrat.id : 0,
              contratType: contrat ? contrat.contractType : null,
            };
          });
          setIsChecked(checked);
        } else {
          createToast(
            "error",
            "Une erreurs est survenue lors de la modification du ticket."
          );
        }
      })
      .catch((err) => {
        createToast(
          "error",
          "Une erreur est survenue lors de la modification du ticket" + err
        );
      });
  }

  useEffect(() => {
    if (ticket?.ticket?.societe) getAllContracts();
  }, [ticket]);

  return (
    <>
      <Box sx={{ marginTop: "10px", padding: "5px", width: "35%" }}>
        {ticket?.ticket?.societe && listContrats.length > 0 && (
          <Card variant="outlined">
            <CardContent>
              {listContrats.map((contrat) => card(contrat))}
            </CardContent>
          </Card>
        )}
      </Box>
    </>
  );
};

export default TicketContratsBox;
