import _ from "lodash";

/**
 * Retrieves all reservations based on the given resources and status.
 *
 * @param {Array} resources - The array of resources.
 * @param {Array} status - The array of reservation statuses to filter by.
 * @returns {Array} - The array of reservations that match the given status.
 */
export const getAllReservations = (resources, status) => {
    const reservations = [];
    if(resources.length === 0) return reservations;
    resources.forEach(resource => {
        if(!_.isUndefined(resource?.reservations) && resource?.reservations.length > 0) {
            resource.reservations.forEach(reservation => {
                if(status.includes(reservation.status)){
                    reservations.push({
                        title: `${resource.type} ${resource.brand} ${resource.reference}`,
                        start: new Date(reservation?.startDate),
                        end: new Date(reservation?.endDate),
                        status: reservation.status,
                        event: reservation.status === 0 ? "C9302C" : "1976D2", 
                        societe: reservation.societe ? reservation.societe.name : '',
                        user : reservation.user ? `${reservation.user.firstName} ${reservation.user.lastName}` : '',
                        quantity: reservation.quantity,
                        nbJours: reservation.endDate !== null 
                            ? `${_.round((new Date(reservation.endDate) - new Date(reservation.startDate)) / (1000 * 60 * 60 * 24))} jours`
                            : 'infini'
                    });
                }
            });
        }
    });

    return reservations;
};