// BASIC CONFIGURATION OPTIONS FOR PRODUCT-SERVICES //
export const _TABLE_PRODUIT_SERVICE_CONFIG_VALUES = {
  champsName: "Champs Name",
  champsIpLocal: "Champs Ip Local",
  champsDynhost: "Champs Dynhost",
  champsLieu: "Champs Lieu",
  champsOs: "Champs Os",
  champsFabricant: "Champs Fabricant",
  champsModel: "Champs Model",
  champsSeries: "Champs Serie",
  champsDomains: "Champs Domains",
  champsMarque: "Champs Marque",
  champsMac: "Champs Mac",
  champsProcesseur: "Champs Processeur",
  champsBridge: "Champs Bridge",
  champsFti: "Champs Fti",
  champsLigneSupport: "Ligne Support",
  champsUsager: "Champs Usager",
};
