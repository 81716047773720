import { Box, Button, ButtonGroup } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import HelpIcon from "@mui/icons-material/Help";
import HistoryIcon from "@mui/icons-material/History";
import { createConfirm } from "../../../utils/confirm";
import { useNavigate } from "react-router-dom";
import { _ROUTER_DOM_URL } from "../../../utils/_routes_constants";
import { RequestHelper } from "../../../utils/requestHelper";

export const confirmExit = (navigate) => {
  createConfirm(
    "Vous avez effectué des changements sur le ticket, choisissez parmi les choix suivants."
  )
    .fire({
      title: "Confirmation",
      text: "Voulez-vous vraiment quitter cette page ?",
      confirmButtonText: "Retour au tableau de bord",
      cancelButtonText: "Restez sur la page",
    })
    .then((result) => {
      if (result.isConfirmed) {
        navigate(_ROUTER_DOM_URL.DASHBOARD);
      }
    });
};

export const TicketEditToolBar = () => {
  const navigate = useNavigate();

  const goToCreateTicket = () => {
    navigate(_ROUTER_DOM_URL.TICKET_CREATE);
  };

  return (
    <>
      <Box>
        <ButtonGroup>
          <Button title="Tableau de bord">
            <FormatListBulletedIcon
              onClick={(e) => {
                confirmExit(navigate);
              }}
            />
          </Button>
          <Button title="Nouveau ticket">
            <LocalActivityIcon
              onClick={(e) => {
                goToCreateTicket();
              }}
            />
          </Button>
          <Button title="Duppliquer le ticket">
            <CopyAllIcon />
          </Button>
          <Button title="Aide">
            <HelpIcon />
          </Button>
          <Button title="Historique">
            <HistoryIcon />
          </Button>
        </ButtonGroup>
      </Box>
    </>
  );
};

export default TicketEditToolBar;
