import { Chip } from "@mui/material";
import React, { useMemo } from "react";
import { ChipTransformer } from "../utils/ChipTransformer";
import { isNull } from "lodash";
import { stripHtml } from "../../utils/function";

/**
 * Renders the columns configuration for the ProduitService component.
 *
 * @param {Object} props.requestHelper - The request helper object.
 * @param {Object} props._ACTIONS - The actions object.
 * @param {Object} props._ROUTES - The routes object.
 * @param {Object} props._COLORS - The colors object.
 * @param {Function} props.setParentPS - The function to set the parent PS.
 */
export const ColonnesProduitService = ({
  requestHelper,
  _ACTIONS,
  _ROUTES,
  setParentPS,
}) => {
  const handleChangeStatus = (id, isActive) => {
    requestHelper.doRequest(_ACTIONS.PUT, `${_ROUTES.PRODUIT_SERVICE}/${id}`, {
      isActive: !isActive,
    });
    setParentPS((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, isActive: !isActive } : item
      )
    );
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "categorie",
        header: "Catalogue",
        size: 100,
        Cell: ({ row }) => {
          const countCatégories = row.original.childrens?.length;

          return isNull(row.original.categorie) ? (
            ""
          ) : (
            <Chip
              label={`${countCatégories} catégories`}
              size="small"
              style={{
                backgroundColor: "#00c0ef",
                color: "white",
              }}
            />
          );
        },
      },
      {
        accessorKey: "name",
        header: "Produit/Service",
        size: 100,
        Cell: ({ row }) => {
          return isNull(row.original.name) ? "" : row.original.name;
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 100,
        Cell: ({ row }) => {
          return isNull(row.original.description)
            ? ""
            : stripHtml(row.original.description);
        },
      },
      {
        accessorKey: "isActive",
        header: "Etat",
        size: 100,
        Cell: ({ row }) => {
          const color = row.original.isActive ? "success" : "error";
          const label = row.original.isActive ? "Actif" : "Inactif";

          return (
            <ChipTransformer
              label={label}
              color={color}
              size="small"
              onClick={() =>
                handleChangeStatus(row.original.id, row.original.isActive)
              }
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};
