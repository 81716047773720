import { useEffect, useState } from "react";
import moment from "moment";
import { _DATE_FORMAT } from "../../utils/constants";
import { Box } from "@mui/material";
import { solde } from "./contratMaintenances";
import { _ROUTES } from "../../utils/_routes_constants";
import { getDataContrat } from "../../repository/contrats/Contrat";

const OldContrat = ({row, type}) => {
    const [oldContrats, setOldContrats] = useState([]);

    useEffect(() => {
        if(oldContrats.length > 0) return;
        getDataContrat(
            {
                isDeleted: 0, 
                isClotured: 1, 
                dateAnniversaire: moment(row.original.dateAnniversaire).add(-1, 'year').format(_DATE_FORMAT.input_date), 
                refContrat: row.original.refContrat
            }, 
            setOldContrats, 
            "Une erreur est survenue lors de la récupération des anciens contrats",
            _ROUTES.CONTRAT_MAINTENANCE
        );
    }, [row]);


    return (
        <>
            {type === "credit" && 
                <Box sx={{textAlign:"right"}}>
                    {(oldContrats?.length > 0 && oldContrats[0].totalSolde > 0) 
                        ? <span style={{color:"green", fontWeight:"450"}}>{"+" + oldContrats[0].totalSolde}</span>
                        : 0
                    }
                </Box>
            }
            {type === "solde" && 
                <Box sx={{textAlign:"right"}}>
                {
                   solde(row, oldContrats.length > 0 ? oldContrats[0].totalSolde : 0)
                }
            </Box>
            }
        </>
    );
};

export default OldContrat;