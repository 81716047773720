import React from "react";
import { Typography } from "@mui/material";
import { subscriptionTypeArray } from "../../../../utils/constants";
import { isNull } from "lodash";
import { sizeColumnMuiTable } from "../../../../utils/actif-config/actif_configuration_constants";

const { sizeMediumText, tinyText, minSizeShortText } = sizeColumnMuiTable;

/*
 * Columns for Antivirus usefull in the ResponseListCatalogue.jsx
 * @returns Array
 * */
export const antivirusAntispamColumns = () => [
  {
    accessorKey: "societeProduitServiceBitdefender.subscriptionType",
    header: "Type d'abonnement",
    size: sizeMediumText,
    enableSorting: false,
    Cell: ({ row }) => {
      const subscriptionType =
        row.original.societeProduitServiceBitdefender?.subscriptionType;
      const type = subscriptionTypeArray.find((t) => t.id === subscriptionType);

      return <Typography>{type?.name || ""}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceBitdefender.isExpiry",
    size: minSizeShortText,
    header: "Validité de licence",
    enableSorting: false,
    Cell: ({ row }) => {
      const isExpiry = row.original.societeProduitServiceBitdefender?.isExpiry;
      if (isNull(isExpiry)) return;

      return <Typography>{isExpiry === true ? "Jamais" : "Expiré"}</Typography>;
    },
  },
  {
    accessorKey: "quantite",
    size: tinyText,
    header: "LR",
    enableSorting: false,
    Cell: ({ row }) => {
      return (
        <Typography>
          {!isNull(row.original.quantite) ? row.original.quantite : ""}
        </Typography>
      );
    },
  },
  {
    accessorKey: "societeProduitServiceBitdefender.endpointMonthlyUsage",
    size: tinyText,
    header: "LU",
    enableSorting: false,
    Cell: ({ row }) => {
      const endpointMonthlyUsage =
        row.original.societeProduitServiceBitdefender?.endpointMonthlyUsage;

      return (
        <Typography>
          {!isNull(endpointMonthlyUsage) ? endpointMonthlyUsage : ""}
        </Typography>
      );
    },
  },
  {
    accessorKey: "societeProduitServiceBitdefender.patchManagementMonthlyUsage",
    size: tinyText,
    header: "PM",
    enableSorting: false,
    Cell: ({ row }) => {
      const patchMngmntMnthlUsage =
        row.original.societeProduitServiceBitdefender
          ?.patchManagementMonthlyUsage;

      return (
        <Typography>
          {!isNull(patchMngmntMnthlUsage) ? patchMngmntMnthlUsage : ""}
        </Typography>
      );
    },
  },
  {
    accessorKey: "societeProduitServiceBitdefender.encryptionMonthlyUsage",
    size: tinyText,
    header: "FDE",
    enableSorting: false,
    Cell: ({ row }) => {
      const encryptionMonthlyUsage =
        row.original.societeProduitServiceBitdefender?.encryptionMonthlyUsage;

      return (
        <Typography>
          {!isNull(encryptionMonthlyUsage) ? encryptionMonthlyUsage : ""}
        </Typography>
      );
    },
  },
  {
    accessorKey: "licenceVadeSecure",
    size: tinyText,
    header: "LR VadeSecure",
    enableSorting: true,
    Cell: ({ row }) => {
      const nbLicenses =
        row.original.societeProduitServiceVadeSecure?.nbLicence;

      return <Typography>{!isNull(nbLicenses) ? nbLicenses : null}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceVadeSecure.duration",
    size: minSizeShortText,
    header: "Validité VadeSecure",
    enableSorting: false,
    Cell: ({ row }) => {
      const duration = row.original.societeProduitServiceVadeSecure?.duration;
      if (isNull(duration)) return;

      return <Typography>{duration}</Typography>;
    },
  },
];
