import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { getIdFromIRI } from "../../../utils/function";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";

const FormStepOne = ({
  formik,
  parentPS,
  options,
  produitServiceCategories,
  isEditForm,
  searchCategoriesPS,
}) => {
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;

  return (
    <React.Fragment>
      <Grid container spacing={1} justifyContent="center" marginTop={5}>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Tiers *</strong>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                id="societe"
                fullWidth
                size="small"
                value={
                  formik.values.societe
                    ? options.find(
                        (option) => option["@id"] === formik.values.societe
                      )
                    : null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "societe",
                    newValue ? newValue["@id"] : ""
                  );
                }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option["@id"] === value["@id"]
                }
                getOptionLabel={(option) => option.name}
                options={options}
                loading={loading}
                disabled={isEditForm && true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Societes"
                    error={
                      formik.touched.societe && Boolean(formik.errors.societe)
                    }
                    helperText={
                      formik.touched.societe ? formik.errors.societe : ""
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Produit | Services *</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl
                fullWidth
                error={
                  formik.touched.produitService &&
                  Boolean(formik.errors.produitService)
                }
              >
                <InputLabel id="produitService-label" size="small">
                  Produit | Services
                </InputLabel>
                <Select
                  labelId="produitService-label"
                  id="produitService"
                  value={formik.values.produitService}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const iri = e.target.value;
                    const id = getIdFromIRI(iri);
                    searchCategoriesPS(id);
                    formik.setFieldValue("produitService", iri);
                  }}
                  name="produitService"
                  size="small"
                >
                  {parentPS &&
                    parentPS.map((s) => {
                      return (
                        <MenuItem key={s.id} value={s["@id"]}>
                          {s.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                {formik.touched.produitService &&
                formik.errors.produitService ? (
                  <FormHelperText>
                    {formik.errors.produitService}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Catégorie *</strong>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl
                fullWidth
                error={
                  formik.touched.produitServiceCategorie &&
                  Boolean(formik.errors.produitServiceCategorie)
                }
              >
                <InputLabel id="produitServiceCategorie-label" size="small">
                  Catégorie
                </InputLabel>
                <Select
                  labelId="produitServiceCategorie-label"
                  id="produitServiceCategorie"
                  value={formik.values.produitServiceCategorie}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="produitServiceCategorie"
                  size="small"
                >
                  {produitServiceCategories?.map((categorie) => (
                    <MenuItem
                      key={categorie.id}
                      value={categorie["@id"]}
                      disabled={isEditForm && true}
                    >
                      {categorie.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.produitServiceCategorie &&
                formik.errors.produitServiceCategorie ? (
                  <FormHelperText>
                    {formik.errors.produitServiceCategorie}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Quantité *</strong>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                size="small"
                label="Quantité"
                type="number"
                id="quantite"
                name="quantite"
                value={formik.values.quantite}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.quantite &&
                  !formik.values.quantite &&
                  Boolean(formik.errors.quantite)
                }
                helperText={
                  formik.touched.quantite && !formik.values.quantite
                    ? formik.errors.quantite
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Décompte Temps *</strong>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl
                fullWidth
                error={
                  formik.touched.decompteTemps &&
                  Boolean(formik.errors.decompteTemps)
                }
              >
                <InputLabel id="decompteTemps-label" size="small">
                  Décompte Temps
                </InputLabel>
                <Select
                  labelId="decompteTemps-label"
                  id="decompteTemps"
                  value={formik.values.decompteTemps}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="decompteTemps"
                  size="small"
                >
                  <MenuItem key={1} value={true}>
                    Oui
                  </MenuItem>
                  <MenuItem key={2} value={false}>
                    Non
                  </MenuItem>
                </Select>
                {formik.touched.decompteTemps && formik.errors.decompteTemps ? (
                  <FormHelperText>{formik.errors.decompteTemps}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Description *</strong>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <CKEditor
                editor={Editor}
                name="description"
                data={
                  formik.values.description ? formik.values.description : ""
                }
                onChange={(event, editor) => {
                  const data = editor.getData();
                  formik.setFieldValue("description", data ? data : "");
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FormStepOne;
