import { TYPE_CONTRATS } from "./ColumnsContrat";
import moment from "moment";
import * as yup from "yup";

export function getInitialValues(row, typeContrat, idApporteur){
    switch(typeContrat){
        case TYPE_CONTRATS.CONTRAT_MAINTENANCE:
            return {
                societe: (row && row.societe !== null) ?  row.societe['@id'] : "",
                dateDebut: (row && row.dateDebut !==null) ? moment(row.dateDebut) :  moment(new Date()),
                dateFin: (row && row.dateFin !== null) ? moment(row.dateFin) :  moment(new Date()).add(-1, 'days').add(1, 'years'),
                dateAnniversaire: (row && row.dateAnniversaire) ? moment(row.dateAnnniversaire) :  moment(new Date()).add(1, 'years'),
                facturation: (row && row.facturation !== null) ? row.facturation : "",
                creditContrat: (row && row.creditContrat !== null) ? row.creditContrat : 0,
                isFacturedDeplacement: (row && row.isFacturedDeplacement !== null ) ? row.isFacturedDeplacement : true,
                price: (row && row.price !== null) ? row.price : 0,
                isReconduite: (row && row.isReconduite !== null ) ? row.isReconduite : true,
                commentaire: (row && row.commentaire !== null) ? row.commentaire : '',
                fileName: (row && row.fileName !== null) ? row.fileName : '',
            };
        case TYPE_CONTRATS.CONTRAT_HEBERGEMENT:
            return {
                societe: (row && row.societe !== null) ?  row.societe['@id'] : "",
                dateDebut: (row && row.dateDebut !==null) ? moment(row.dateDebut) :  moment(new Date()),
                dateFin: (row && row.dateFin !== null) ? moment(row.dateFin) :  moment(new Date()).add(-1, 'days').add(1, 'years'),
                dateAnniversaire: (row && row.dateAnniversaire) ? moment(row.dateAnnniversaire) :  moment(new Date()).add(1, 'years'),
                price: (row && row.price !== null) ? row.price : 0,
                apporteur: (row && row.apporteurDolibarr !== 0) ? true : false,
                apporteurName: idApporteur,
                apporteurMontant: (row && row.apporteurMontant !== null) ? row.apporteurMontant : 0,
                isReconduite: (row && row.isReconduite !== null ) ? row.isReconduite : true,
                commentaire: (row && row.commentaire !== null) ? row.commentaire : '',
                fileName: (row && row.fileName !== null) ? row.fileName : '',
                societeProduitService: (row && row.societeProduitService !== null) ? row.societeProduitService['@id'] : null,
            };
        case TYPE_CONTRATS.CONTRAT_AUTRES:
            return {
                societe: (row && row.societe !== null) ?  row.societe['@id'] : "",
                dateDebut: (row && row.dateDebut !==null) ? moment(row.dateDebut) :  moment(new Date()),
                dateFin: (row && row.dateFin !== null) ? moment(row.dateFin) :  moment(new Date()).add(-1, 'days').add(1, 'years'),
                dateAnniversaire: (row && row.dateAnniversaire) ? moment(row.dateAnnniversaire) :  moment(new Date()).add(1, 'years'),
                price: (row && row.price !== null) ? row.price : 0,
                costPrice: (row && row.costPrice !== null) ? row.costPrice : 0,
                apporteur: (row && row.apporteurDolibarr !== 0) ? true : false,
                apporteurName: idApporteur,
                dolProduct: (row && row.dolProduct !== null) ? row.dolProduct : "",
                apporteurMontant: (row && row.apporteurMontant !== null) ? row.apporteurMontant : 0,
                isReconduite: (row && row.isReconduite !== null ) ? row.isReconduite : true,
                commentaire: (row && row.commentaire !== null) ? row.commentaire : '',
            };
        case TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE:
            return {
                societe: (row && row.societe !== null) ?  row.societe['@id'] : "",
                dateDebut: (row && row.dateDebut !==null) ? moment(row.dateDebut) :  moment(new Date()),
                dateFin: (row && row.dateFin !== null) ? moment(row.dateFin) :  moment(new Date()).add(-1, 'days').add(1, 'years'),
                dateAnniversaire: (row && row.dateAnniversaire) ? moment(row.dateAnnniversaire) :  moment(new Date()).add(1, 'years'),
                prixFacturation: (row && row.prixFacturation !== null) ? row.prixFacturation['@id'] : "",
                isReconduite: (row && row.isReconduite !== null ) ? row.isReconduite : true,
                commentaire: (row && row.commentaire !== null) ? row.commentaire : '',
                societeProduitServiceSauvegarde: (row && row.societeProduitServiceSauvegarde !== null) ? row.societeProduitServiceSauvegarde["@id"] : '',
                refContrat: (row && row.refContrat !== null) ? row.refContrat : '',
            };
        case TYPE_CONTRATS.CONTRAT_REGIE:
            return {
                societe: (row && row.societe !== null) ?  row.societe['@id'] : "",
                dolProduct: (row && row.dolProduct !== null) ? row.dolProduct : "",
                dateDebut: (row && row.dateDebut !==null) ? moment(row.dateDebut) :  moment(new Date()),
                dateFin: (row && row.dateFin !== null) ? moment(row.dateFin) :  moment(new Date()).add(-1, 'days').add(1, 'years'),
                dateAnniversaire: (row && row.dateAnniversaire) ? moment(row.dateAnnniversaire) :  moment(new Date()).add(1, 'years'),
                isReconduite: (row && row.isReconduite !== null ) ? row.isReconduite : true,
                commentaire: (row && row.commentaire !== null) ? row.commentaire : '',
                fileName: (row && row.fileName !== null) ? row.fileName : '',
                contratSocieteRegieContratTypeRegies: (row && row.contratSocieteRegieContratTypeRegies !== null) ? row.contratSocieteRegieContratTypeRegies : [],
                
            };
        case TYPE_CONTRATS.CONTRAT_LOCATION:
            return {
                contratTypeLocation: (row && row.contratTypeLocation !== null) ? row.contratTypeLocation : "",
                societe: (row && row.societe !== null) ?  row.societe['@id'] : "",
                contratTypeOffre: (row && row.contratTypeOffre !== null) ? row.contratTypeOffre['@id'] : "",
                devisDolibarr: (row && row.devisDolibarr !== null) ? row.devisDolibarr : "",
                dateDebut: (row && row.dateDebut !==null) ? moment(row.dateDebut) :  moment(new Date()),
                dateFin: (row && row.dateFin !== null) ? moment(row.dateFin) :  moment(new Date()).add(-1, 'days').add(3, 'years'),
                dateAnniversaire: (row && row.dateAnniversaire) ? moment(row.dateAnnniversaire) :  moment(new Date()).add(3, 'years'),
                dateEnvoi: (row && row.dateEnvoi !== null) ? moment(row.dateEnvoi) : null,
                dateRetour: (row && row.dateRetour !== null) ? moment(row.dateRetour) : null,
                nbMateriel: (row && row.bbMateriel !== null) ? row.nbMateriel : 0,
                fournisseur: (row && row.fournisseur !== null) ? row.fournisseur['@id'] : "",
                isReconduite: (row && row.isReconduite !== null ) ? row.isReconduite : true,
                commentaire: (row && row.commentaire !== null) ? row.commentaire : '',
                fileName: (row && row.fileName !== null) ? row.fileName : '',
                lieu: (row && row.lieu !== null) ? row.lieu : "",
                locationProduitDolibars: (row && row.locationProduitDolibars !== null) ? row.locationProduitDolibars : [],
            };
        case TYPE_CONTRATS.CONTRAT_BEEMO:
            return {
                societe: (row && row.societe !== null) ?  row.societe['@id'] : "",
                dateDebut: (row && row.dateDebut !==null) ? moment(row.dateDebut) :  moment(new Date()),
                dateFin: (row && row.dateFin !== null) ? moment(row.dateFin) :  moment(new Date()).add(-1, 'days').add(1, 'years'),
                dateAnniversaire: (row && row.dateAnniversaire) ? moment(row.dateAnnniversaire) :  moment(new Date()).add(1, 'years'),
                isReconduite: (row && row.isReconduite !== null ) ? row.isReconduite : true,
                commentaire: (row && row.commentaire !== null) ? row.commentaire : '',
                fileName: (row && row.fileName !== null) ? row.fileName : '',
                devisDolibarr: (row && row.devisDolibarr !== null) ? row.devisDolibarr : null,
                beemoProduitDolibars: (row && row.beemoProduitDolibars !== null) ? row.beemoProduitDolibars : [],
            };
        default:
            break;
    }
}

export function getValidationSchema(typeContrat, apporteur){
    
    switch(typeContrat){
        case TYPE_CONTRATS.CONTRAT_MAINTENANCE:
            return yup.object().shape({
                societe: yup.string().required('Le tier est obligatoire'),
                dateDebut: yup.date().required('La date de début est obligatoire'),
                dateFin: yup.date().required('La date de fin est obligatoire'),
                dateAnniversaire: yup.date().required('La date d\'anniversaire est obligatoire'),
                facturation: yup.string().required('Vous devez spécifier le type de facturation'),
              });
        case TYPE_CONTRATS.CONTRAT_HEBERGEMENT:
            return yup.object().shape({
                societe: yup.string().required('Le tier est obligatoire'),
                dateDebut: yup.date().required('La date de début est obligatoire'),
                dateFin: yup.date().required('La date de fin est obligatoire'),
                dateAnniversaire: yup.date().required('La date d\'anniversaire est obligatoire'),
                ...(apporteur 
                    ? {apporteurName: yup.string().required('Le nom de l\'apporteur est obligatoire')} 
                    : null
                ) 
            });
        case TYPE_CONTRATS.CONTRAT_AUTRES:
            return yup.object().shape({
                societe: yup.string().required('Le tier est obligatoire'),
                dateDebut: yup.date().required('La date de début est obligatoire'),
                dateFin: yup.date().required('La date de fin est obligatoire'),
                dateAnniversaire: yup.date().required('La date d\'anniversaire est obligatoire'),
                ...(apporteur 
                    ? {apporteurName: yup.string().required('Le nom de l\'apporteur est obligatoire')} 
                    : null
                )
              })
        case TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE:
            return yup.object().shape({
                societe: yup.string().required('Le tier est obligatoire'),
                dateDebut: yup.date().required('La date de début est obligatoire'),
                dateFin: yup.date().required('La date de fin est obligatoire'),
                dateAnniversaire: yup.date().required('La date d\'anniversaire est obligatoire'),
                prixFacturation: yup.string().required('Vous devez spécifier le quota'),
              });
        case TYPE_CONTRATS.CONTRAT_REGIE:
            return yup.object().shape({
                societe: yup.string().required('Le tier est obligatoire'),
                dateDebut: yup.date().required('La date de début est obligatoire'),
                dateFin: yup.date().required('La date de fin est obligatoire'),
                dateAnniversaire: yup.date().required('La date d\'anniversaire est obligatoire')
            });
        case TYPE_CONTRATS.CONTRAT_LOCATION:
            return yup.object().shape({
                societe: yup.string().required('Le tier est obligatoire'),
                dateDebut: yup.date().required('La date de début est obligatoire'),
                dateFin: yup.date().required('La date de fin est obligatoire'),
                dateAnniversaire: yup.date().required('La date d\'anniversaire est obligatoire'),
            });
        case TYPE_CONTRATS.CONTRAT_BEEMO:
            return yup.object().shape({
                societe: yup.string().required('Le tier est obligatoire'),
                dateDebut: yup.date().required('La date de début est obligatoire'),
                dateFin: yup.date().required('La date de fin est obligatoire'),
                dateAnniversaire: yup.date().required('La date d\'anniversaire est obligatoire'),
            });
        default:
            break;
    }
} 