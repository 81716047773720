import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import * as yup from "yup";
import { _COLORS } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import _ from "lodash";
import { createToast } from "../../utils/toast";
import { _ROUTES } from "../../utils/_routes_constants";

const StyledGrid = styled(Grid)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  backgroundColor: "white",
}));
const DecompteTempsFormulaire = ({
  setDecompteTempsList,
  listProduitService,
  isFournisseur = false,
  setShowList = null,
  isPersonnalise = null,
  currentSociete = null,
  currentSocieteIRI = null,
}) => {
  const requestHelper = new RequestHelper();
  const theme = useTheme();

  const validationSchema = yup.object().shape({
    produitService: yup.string().required("Produit service est requis"),
    times: yup.string().required("Temps est requis"),
    conditions: yup.string().required("Conditions est requis"),
    /* Si le décompte temps est personnalisé on rajoute la validation sur le champ société  */
    ...(isPersonnalise && {
      societe: yup.string().required("Société est requise"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      produitService: "",
      times: "",
      conditions: "",
      /* Si le décompte temps est personnalisé on rajoute le champ societe  */
      ...(isPersonnalise && { societe: "" }),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const errorMessage =
        "Une erreur est survenue lors de la création du décompte temps personnalisé";
      /* Si le décompte temps est personnalisé  */
      if (isPersonnalise) {
        /* Première requetes pour envoyer l'objet sur l'entité Décompte Temps afin de récupérer son IRI */
        const postDataDecompteTemps = {
          produitService: values.produitService,
          times: values.times,
          conditions: values.conditions,
          personnalise: true,
        };
        requestHelper
          .post(_ROUTES.DECOMPTE_TEMPS, postDataDecompteTemps)
          .then((response) => {
            if (response.status !== 201) {
              throw new Error(errorMessage);
            }
            const newItem = response.data;
            setDecompteTempsList((prevState) => [...prevState, newItem]);
            /* Récupération de l'IRI */
            const decompteTempsId = response.data["@id"];
            /* Deuxième requete pour envoyer l'objet sur l'entité Decompte Temps Societe Personnalise */
            const postDataSociete = {
              decompteTemps: decompteTempsId,
              /* Si c'est un fournisseur il faut envoyer sur la propriété fournisseur sinon sur la propriété societe */
              ...(isFournisseur
                ? { fournisseur: currentSocieteIRI }
                : { societe: currentSocieteIRI }),
            };
            return requestHelper
              .post(_ROUTES.DECOMPTE_TEMPS_PERSONNALISE, postDataSociete)
              .then((response) => {
                if (response.status !== 201) {
                  throw new Error(errorMessage);
                }
              });
          })
          .then(() => {
            createToast(
              "success",
              "Décompte temps personnalisé ajouté avec succès"
            );
          })
          .catch((error) => {
            createToast("error", error.message);
          });
      } else {
        /* Si isPersonnalise est false, poster uniquement vers decompte_temps (=> décompte temps standart) */
        requestHelper
          .post(_ROUTES.DECOMPTE_TEMPS, values)
          .then((response) => {
            if (201 !== response.status) {
              throw new Error(
                "Une erreur est survenue lors de la création du décompte temps"
              );
            }
            createToast("success", "Décompte temps ajouté avec succès");
            const newItem = response.data;
            setDecompteTempsList((prevState) => [...prevState, newItem]);
          })
          .catch((error) => {
            createToast("error", error.message);
          });
      }
    },
  });

  useEffect(() => {
    /* On force le le champs société à s'auto remplir avec la valeur de currentSociete */
    if (isPersonnalise) {
      formik.setFieldValue("societe", currentSociete);
    }
  }, [currentSociete]);

  const handleTimeChange = (event) => {
    let timeValue = event.target.value;
    let timeInt = _.parseInt(timeValue);
    if (isNaN(timeInt)) {
      timeInt = "";
    }
    formik.setFieldValue("times", timeInt);
  };

  const conditionsArray = [
    {
      id: 1,
      name: "PAR PERIPHERIE",
    },
    {
      id: 2,
      name: "PAR CLIENT",
    },
  ];

  return (
    <>
      <Grid container>
        {isPersonnalise && (
          <Typography variant="h6">
            Ajout d'un décompte temps pour{" "}
            <strong>{formik.values.societe}</strong>
          </Typography>
        )}
        <StyledGrid
          item
          xs={isPersonnalise ? 12 : 6}
          sx={{
            borderTop: !isPersonnalise
              ? `2px solid ${theme.palette.grey[400]}`
              : "none",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid item xs={12} width={"100%"}>
              {/* Si le décompte temps est personnalisé on affiche le champs société */}
              {isPersonnalise && currentSociete ? (
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  id={"societe"}
                  name={"societe"}
                  value={formik.values.societe}
                  onChange={handleTimeChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.societe && Boolean(formik.errors.societe)
                  }
                  helperText={formik.touched.societe && formik.errors.societe}
                />
              ) : (
                <Typography variant="h6">
                  <AddCircleRoundedIcon fontSize="small" />
                  Ajouter le catalogue concerné par le décompte temps
                </Typography>
              )}
            </Grid>
            <Divider />
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              alignItems={"center"}
              padding={1}
            >
              <Grid item xs={isPersonnalise ? 12 : 5} sx={{ width: "100%" }}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.produitService &&
                    Boolean(formik.errors.produitService)
                  }
                >
                  <InputLabel id="produit-service-label" size="small">
                    Produit service
                  </InputLabel>
                  <Select
                    labelId="produit-service-label"
                    id="produitService"
                    size="small"
                    value={formik.values.produitService}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="produitService"
                  >
                    {listProduitService.map((ps) => (
                      <MenuItem key={ps.id} value={ps["@id"]}>
                        {ps.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.produitService &&
                  formik.errors.produitService ? (
                    <FormHelperText>
                      {formik.errors.produitService}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={isPersonnalise ? 12 : 5} width={"100%"}>
                <TextField
                  fullWidth
                  id="times"
                  name="times"
                  size="small"
                  label="Temps (min)*"
                  value={formik.values.times}
                  onChange={handleTimeChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.times && Boolean(formik.errors.times)}
                  helperText={formik.touched.times && formik.errors.times}
                />
              </Grid>
              <Grid
                item
                xs={isPersonnalise ? 12 : 5}
                width={"100%"}
                sx={{ width: "100%" }}
              >
                <FormControl
                  fullWidth
                  error={
                    formik.touched.produitService &&
                    Boolean(formik.errors.produitService)
                  }
                >
                  <InputLabel id="conditions-label" size="small">
                    Conditions
                  </InputLabel>
                  <Select
                    labelId="conditions-label"
                    id="conditions"
                    size="small"
                    value={formik.values.conditions}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="conditions"
                  >
                    {conditionsArray.map((c) => (
                      <MenuItem key={c.id} value={c.name}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.conditions && formik.errors.conditions ? (
                    <FormHelperText>{formik.errors.conditions}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Box>
            <Divider />
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={isPersonnalise ? "space-between" : "flex-end"}
              padding={1}
            >
              {isPersonnalise && (
                <Button onClick={setShowList} size="small">
                  Voir DP pour ce tier
                </Button>
              )}
              <Button
                type="submit"
                size="small"
                variant="contained"
                sx={{ backgroundColor: _COLORS.buttonLightBlue }}
              >
                Enregistrer
              </Button>
            </Grid>
          </form>
        </StyledGrid>
        <Grid item xs={6} />
      </Grid>
    </>
  );
};

export default DecompteTempsFormulaire;
