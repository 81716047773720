import React, { memo, useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { FaFolder } from "react-icons/fa6";
import { FaFolderOpen } from "react-icons/fa6";
import { FaBoxArchive } from "react-icons/fa6";
import { FaFileArchive } from "react-icons/fa";
import { FaFileSignature } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Divider, Typography } from "@mui/material";
import { _COLORS } from "../../../utils/constants";
import { _ROUTER_DOM_URL, _ROUTES } from "../../../utils/_routes_constants";
import { BaseConnaissanceContext } from "../../../contexts/BaseConnaissanceContext";
import { getDataBaseConnaissance } from "../../../repository/BaseConnaissance";

/**
 * MemoizedTreeView component displays a tree view of base connaissances.
 *
 * @component
 * @param {Function} navigate - The function to navigate to a specific article.
 */
export const MemoizedTreeView = memo(({ navigate }) => {
  const [baseConnaisanceCatégories, setBaseConnaisanceCatégories] = useState(
    []
  );
  const { refresh, filter } = useContext(BaseConnaissanceContext);

  useEffect(() => {
    getDataBaseConnaissance(
      {filter: filter}, 
      setBaseConnaisanceCatégories, 
      "Erreur lors de la récupération des catégories", 
      _ROUTES.BASE_CONNAISSANCE_TREE_VIEW
    );
  }, [filter, refresh]);

  return (
    <Box
      sx={{
        minHeight: 352,
        minWidth: 200,
        border: `1px solid ${_COLORS.darkGrey}`,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          color: _COLORS.darkGrey,
          p: 1,
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <FaBoxArchive color="black" />
        <Typography variant="h6" sx={{ ml: 1 }}>
          Informations générales
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1, backgroundColor: _COLORS.lightGrey }}>
        {baseConnaisanceCatégories?.map((catégorie, index) => {
          return (
            <SimpleTreeView
              key={`index-${index}`}
              slots={{
                expandIcon: FaFolder,
                collapseIcon: FaFolderOpen,
              }}
            >
              <TreeItem
                itemId={`cat-${catégorie.categoryId}`}
                label={catégorie.categoryTitle + ` (${catégorie.sectionCount})`}
              >
                {catégorie.sections.map((section) => {
                  return (
                    <TreeItem
                      key={`section-${section.sectionId}`}
                      itemId={`section-${section.sectionId}`}
                      label={
                        section.sectionTitle + ` (${section.articleCount})`
                      }
                    >
                      {section.articles.map((article) => {
                        const icon =
                          article.isEnabled === -1 ? (
                            <FaFileSignature />
                          ) : article.isEnabled === 1 ? (
                            <FaFileAlt />
                          ) : (
                            <FaFileArchive />
                          );
                        return (
                          <TreeItem
                            key={`article-${article.articleId}`}
                            itemId={`article-${article.articleId}`}
                            onClick={() => {
                              navigate(
                                `${_ROUTER_DOM_URL.BASE_CONNAISSANCE}/${article.articleId}`
                              );
                            }}
                            label={
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {icon}{" "}
                                <span style={{ marginLeft: "5px" }}>
                                  {article.articleTitle}
                                </span>
                              </span>
                            }
                          />
                        );
                      })}
                    </TreeItem>
                  );
                })}
              </TreeItem>
            </SimpleTreeView>
          );
        })}
      </Box>
    </Box>
  );
});
