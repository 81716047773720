import { useEffect, useState } from 'react';
import { CircularProgress, Box, TableContainer, TableBody, TableRow, Table, TableCell, TableHead, Chip, Paper, Tooltip } from '@mui/material';
import { getDataStat } from '../../../repository/Stats';
import { _ROUTES } from '../../../utils/_routes_constants';
import { minutesInHours } from '../../../utils/function';

const InfoTicketWeek = ({datas, invoiceTypes, startWeek, endWeek }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [infos, setInfos] = useState([]);

    useEffect(() => {
        setIsLoaded(true);
        const actions = (datas) => {
            setInfos(datas);
            setIsLoaded(false);
        };
        getDataStat(
            {startWeek: startWeek, endWeek: endWeek, invoiceTypes: invoiceTypes, userId: datas.idUser},
            actions,
            "Une erreur est survenue lors de la récupération des détails des tickets",
            _ROUTES.STAT_EQUIE_WEEK_DETAILS
        );
    }, [datas]);

    return (
        <>
            {isLoaded && (<Box><CircularProgress size="0.8em" /> Chargement...</Box>)}
            <TableContainer sx={{width: 'auto'}}>
                <Table component={Paper} dense size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Jours</TableCell>
                            <TableCell>Détails</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(infos).map((info) => (
                            <TableRow hover>
                                <TableCell>{info.days}</TableCell>
                                <TableCell>
                                        {(info.details).map((detail) => (
                                            <TableRow hover >
                                                <TableCell color='red'>
                                                    <Tooltip title={detail.ref} placement="left">
                                                        {"- " + detail.societe} 
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <Chip 
                                                        style={{margin: "5px"}} 
                                                        size="small"  
                                                        color='turquoiseBox' 
                                                        label={'DF: ' + minutesInHours(detail.durationEstimated, true)} 
                                                    />
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <Chip 
                                                        style={{margin: "5px"}} 
                                                        size="small" 
                                                        color='turquoiseBox' 
                                                        label={'DR: ' + minutesInHours(detail.durationReal, true)} 
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default InfoTicketWeek;