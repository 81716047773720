import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { protocolesVPN, serverVpn, tunnels } from "../../../utils/constants";
import moment from "moment";
import ToolTipInfo from "../../utils/ToolTipInfo";
import MuiDatePicker from "../../helpers/MuiDatePicker";

const FormFieldsVpn = ({ formik, formStates, setFormStates }) => {
  useEffect(() => {
    setFormStates({
      ...formStates,
      isVPNForm: true,
    });
  }, []);
  return (
    <>
      <Grid container spacing={1} justifyContent="center" marginTop={2}>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Protocole VPN *</strong>
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <FormControl
                fullWidth
                error={
                  formik.touched.protocole && Boolean(formik.errors.protocole)
                }
              >
                <InputLabel id="protocole-label" size="small">
                  Protocole du VPN
                </InputLabel>
                <Select
                  labelId="protocole-label"
                  id="protocole"
                  value={formik.values.protocole}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="protocole"
                  size="small"
                >
                  {Object.keys(protocolesVPN).map((protocole) => (
                    <MenuItem key={protocole} value={protocolesVPN[protocole]}>
                      {protocolesVPN[protocole]}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.protocole && formik.errors.protocole ? (
                  <FormHelperText>{formik.errors.protocole}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Serveur VPN *</strong>
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <FormControl
                fullWidth
                error={
                  formik.touched.vpnServeur && Boolean(formik.errors.vpnServeur)
                }
              >
                <InputLabel id="vpnServeur-label" size="small">
                  Serveur VPN
                </InputLabel>
                <Select
                  labelId="vpnServeur-label"
                  id="vpnServeur"
                  value={formik.values.vpnServeur}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="vpnServeur"
                  size="small"
                >
                  {Object.keys(serverVpn).map((vpnServeur) => (
                    <MenuItem key={vpnServeur} value={serverVpn[vpnServeur]}>
                      {serverVpn[vpnServeur]}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.vpnServeur && formik.errors.vpnServeur ? (
                  <FormHelperText>{formik.errors.vpnServeur}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Adresse VPN</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Adresse VPN"
                name="adresseVpn"
                value={formik.values.adresseVpn}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.adresseVpn && Boolean(formik.errors.adresseVpn)
                }
                helperText={
                  formik.touched.adresseVpn && formik.errors.adresseVpn
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Ports</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Port"
                name="ports"
                value={formik.values.ports}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.ports && Boolean(formik.errors.ports)}
                helperText={formik.touched.ports && formik.errors.ports}
              />
            </Grid>
            <Grid item xs={2}>
              <ToolTipInfo message="Liste des ports séparés par des virgules" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Adresse Ip Virtuelle</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Adresse IP"
                name="adresseIp"
                value={formik.values.adresseIp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.adresseIp && Boolean(formik.errors.adresseIp)
                }
                helperText={formik.touched.adresseIp && formik.errors.adresseIp}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>DNS</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Dns"
                name="dns"
                value={formik.values.dns}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.dns && Boolean(formik.errors.dns)}
                helperText={formik.touched.dns && formik.errors.dns}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Gateway</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Gateway"
                name="gateway"
                value={formik.values.gateway}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.gateway && Boolean(formik.errors.gateway)}
                helperText={formik.touched.gateway && formik.errors.gateway}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Date Certificat</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <MuiDatePicker
                fullWidth
                label="Date de début"
                name="certificatDate"
                value={formik.values.certificatDate}
                onChange={(newDate) => {
                  formik.setFieldValue("certificatDate", moment(newDate));
                }}
                errors={formik.errors.certificatDate}
                touched={formik.touched.certificatDate}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Tunnel</strong>
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <FormControl
                fullWidth
                error={
                  formik.touched.fullTunnel && Boolean(formik.errors.fullTunnel)
                }
              >
                <InputLabel id="fullTunnel-label" size="small">
                  Tunnel
                </InputLabel>
                <Select
                  labelId="fullTunnel-label"
                  id="fullTunnel"
                  value={formik.values.fullTunnel}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="fullTunnel"
                  size="small"
                >
                  {Object.keys(tunnels).map((tunnel) => (
                    <MenuItem key={tunnel} value={tunnels[tunnel]}>
                      {tunnels[tunnel]}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.fullTunnel && formik.errors.fullTunnel ? (
                  <FormHelperText>{formik.errors.fullTunnel}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Lien Config</strong>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                size="small"
                label="Liens vers un fichier de configuration"
                name="configFile"
                value={formik.values.configFile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.configFile && Boolean(formik.errors.configFile)
                }
                helperText={
                  formik.touched.configFile && formik.errors.configFile
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormFieldsVpn;
