import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormControl } from "@mui/material";
import moment from "moment";
import { _DATE_FORMAT } from "../../../utils/constants";

export const SelectMonth = ({setMois}) => {

    return (
        <FormControl sx={{ m: 1, width: 300, mt:2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label={"Mois"}
                    openTo="month"
                    views={['month']}
                    defaultValue={moment()}
                    onChange={(newValue) => {setMois(newValue.format(_DATE_FORMAT.month))}}
                />
            </LocalizationProvider>
        </FormControl>
    );
};

export default SelectMonth;