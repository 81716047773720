import { Box, IconButton, Link, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";
import { PiGoogleDriveLogoFill } from "react-icons/pi";
import { MdAddToDrive } from "react-icons/md";
import { BsToggle2Off } from "react-icons/bs";
import { BsToggle2On } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import { PiPaperclipFill } from "react-icons/pi";
import { _ACTIONS, _COLORS, _DATE_FORMAT } from "../../utils/constants";
import DriveModal from "./modals/DriveModal";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import GlpiModal from "./modals/GlpiModal";
import AlsoModal from "./modals/AlsoModal";
import { boxStyle } from "../../utils/tiers_constants";
import { formatPhoneNumber } from "../../utils/function";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";
import { BooleanFormatter } from "../utils/muiTableHelper";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { isNull } from "lodash";
import { DateFormatter } from "../../utils/muiTableHelper";
import { sizeColumnMuiTable } from "../../utils/actif-config/actif_configuration_constants";

export const ColonnesTiers = ({
  typeOfTiers,
  handleChangeStatus,
  setShowModal = false,
  setShowModalContent = null,
  setRefreshData,
}) => {
  const [glpis, setGlpis] = useState();
  const [alsos, setAlsos] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    rq.doRequest(_ACTIONS.GET, `${_ROUTES.TMP_GLPI}`, {
      pagination: "disabled",
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Erreur lors de la récupération des clients GLPI");
        }
        let tmpData = res.data["hydra:member"];
        setGlpis(tmpData);
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  }, []);

  useEffect(() => {
    rq.doRequest(_ACTIONS.GET, `${_ROUTES.TMP_ALSO}`, {
      pagination: "disabled",
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Erreur lors de la récupération des clients ALSO");
        }
        let tmpData = res.data["hydra:member"];
        setAlsos(tmpData);
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  }, []);

  const openModal = (title, content) => {
    setShowModal(true);
    setShowModalContent({
      title,
      content,
    });
  };
  const rq = new RequestHelper();

  const deleteAssociation = async (row, type) => {
    const config = {
      glpi: {
        findKey: "glpi",
        updatePath: _ACTIONS.TMP_GLPI,
        updateSocieteData: { glpi: 0, nameGlpi: null },
        errorMessage:
          "Erreur lors de la suppression de l'entité Société associé à GLPI",
        tmpEntityError: "Erreur lors de la mise à jour de l'entité GLPI",
      },
      also: {
        findKey: "compagny",
        updatePath: _ACTIONS.TMP_ALSO,
        updateSocieteData: { also: 0 },
        errorMessage:
          "Erreur lors de la suppression de l'entité Société associé à ALSO",
        tmpEntityError: "Erreur lors de la mise à jour de l'entité ALSO",
      },
    }[type];

    const currentTmpEntity = (type === "glpi" ? glpis : alsos).find(
      (entity) => entity[config.findKey] === row.original[type]
    );

    try {
      const resSociete = await rq.doRequest(
        _ACTIONS.PUT,
        `${_ROUTES.SOCIETE}/${row.original.id}`,
        config.updateSocieteData
      );
      if (resSociete.status !== 200) {
        throw new Error(config.errorMessage);
      }
      const resTmpEntity = await rq.doRequest(
        _ACTIONS.PUT,
        `${config.updatePath}/${currentTmpEntity.id}`,
        {
          isAssocied: false,
        }
      );
      if (resTmpEntity.status !== 200) {
        throw new Error(config.tmpEntityError);
      }
      setRefreshData((prev) => prev + 1);
      createToast("success", "Association supprimée avec succès");
    } catch (error) {
      createToast("error", error.message);
    }
  };

  const { tinyText, sizeMediumText, minSizeMediumText } = sizeColumnMuiTable;

  const columns = useMemo(() => {
    const clientColumns = [
      {
        accessorKey: "referent.firstName",
        header: "Référent Toolin",
        size: sizeMediumText,
        Cell: ({ row }) => {
          return row.original.referent ? (
            <Typography>
              {row.original.referent?.lastName +
                " " +
                row.original.referent?.firstName}
            </Typography>
          ) : null;
        },
      },
      {
        accessorKey: "email",
        header: "Email",
        size: sizeMediumText,
        enableClickToCopy: true,
        Cell: ({ row }) => {
          const email = row.original.email;
          if (!email) {
            return "";
          }
          return (
            <Link href={`mailto:${row.original.email}`}>
              {row.original.email}
            </Link>
          );
        },
      },
      {
        accessorKey: "referentClient.firstName",
        header: "Référent Client",
        size: sizeMediumText,
        Cell: ({ row }) => {
          return row.original.referentClient ? (
            <Typography>
              {row.original.referentClient?.lastName +
                " " +
                row.original.referentClient?.firstName}
            </Typography>
          ) : null;
        },
      },
      {
        accessorKey: "GLPI",
        type: "actions",
        header: "GLPI",
        size: tinyText,
        enableSorting: false,
        Cell: ({ row }) => {
          if (row.original.glpi && row.original.nameGlpi) {
            return (
              <>
                <IconButton title={row.original.nameGlpi} color="success">
                  <FaGoogle size={15} color={_COLORS.blue} />
                </IconButton>
                <IconButton
                  onClick={() => deleteAssociation(row, "glpi")}
                  color="error"
                >
                  <RiDeleteBin5Line size={20} />
                </IconButton>
              </>
            );
          }
          return (
            <IconButton
              title="Association GLPI"
              onClick={() =>
                openModal(
                  "Ajouter une association GLPI",
                  <GlpiModal
                    row={row}
                    setRefreshData={setRefreshData}
                    glpis={glpis}
                  />
                )
              }
            >
              <IoAddCircleOutline size={20} />
            </IconButton>
          );
        },
      },
      /* @TODO: think about refactoring this part */
      {
        accessorKey: "ALSO",
        type: "actions",
        header: "ALSO",
        size: tinyText,
        enableSorting: false,
        Cell: ({ row }) => {
          if (row.original.also) {
            return (
              <>
                <IconButton title={row.original.also} color="success">
                  <PiPaperclipFill size={20} />
                </IconButton>
                <IconButton
                  onClick={() => deleteAssociation(row, "also")}
                  color="error"
                >
                  <RiDeleteBin5Line size={20} />
                </IconButton>
              </>
            );
          }
          return (
            <IconButton
              title="Association Also"
              onClick={() =>
                openModal(
                  "Ajouter une association Also",
                  <AlsoModal
                    row={row}
                    setRefreshData={setRefreshData}
                    alsos={alsos}
                  />
                )
              }
            >
              <IoAddCircleOutline size={20} />
            </IconButton>
          );
        },
      },
      {
        accessorKey: "folderDrive",
        header: "Drive",
        size: tinyText,
        enableSorting: false,
        Cell: ({ row }) => {
          return row.original.folderDrive ? (
            <IconButton
              onClick={() => window.open(row.original.folderDrive)}
              color="success"
            >
              <PiGoogleDriveLogoFill size={20} color={_COLORS.blue} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() =>
                openModal("Ajouter un dossier Drive", <DriveModal row={row} />)
              }
            >
              <MdAddToDrive size={20} />
            </IconButton>
          );
        },
      },
      {
        accessorKey: "aliasToolin",
        header: "Alias Toolin",
        size: sizeMediumText,
        Cell: ({ row }) => {
          const aliasToolin = row.original.aliasToolin;
          return (
            <Typography>{isNull(aliasToolin) ? "" : aliasToolin}</Typography>
          );
        },
      },
      {
        accessorKey: "lastAuditDate",
        header: "Dernière Audit",
        enableSorting: false,
        size: sizeMediumText,
        Cell: ({ row }) => {
          const lastAuditDate = row.original.lastAuditDate;
          if (!lastAuditDate) {
            return "";
          }
          const momentDate = moment(lastAuditDate, _DATE_FORMAT.datetime_exact);
          return <DateFormatter momentDate={momentDate} />;
        },
      },
      {
        accessorKey: "keeperVault",
        header: "Coffre Keeper",
        size: tinyText,
        enableSorting: false,
        Cell: ({ row }) => {
          const keeperVault = row.original.keeperVault;
          return (
            <Typography>{isNull(keeperVault) ? "" : keeperVault}</Typography>
          );
        },
      },
      {
        accessorKey: "holding",
        header: "Holding",
        size: sizeMediumText,
        enableSorting: false,
        Cell: ({ row }) => {
          return row.original.filiale && row.original.filialeBoolean ? (
            <Typography>{row.original.filiale.name}</Typography>
          ) : null;
        },
      },
      {
        accessorKey: "decompteTempsPersonnaliseBool",
        header: "Décompte personnalisé",
        enableSorting: false,
        size: tinyText,
        Cell: ({ row }) => {
          return (
            <BooleanFormatter
              value={row.original.decompteTempsPersonnaliseBool}
            />
          );
        },
      },
    ];

    const commonColumns = [
      {
        accessorKey: "name",
        header: "Tiers",
        size: sizeMediumText,
        Cell: ({ row }) => {
          const name = row.original.name;

          return <Typography>{isNull(name) ? "" : name}</Typography>;
        },
      },

      {
        accessorKey: "domains",
        header: "Domaines",
        size: sizeMediumText,
        Cell: ({ row }) => {
          const domains = row.original.domains;

          return <Typography>{isNull(domains) ? "" : domains}</Typography>;
        },
      },
      {
        accessorKey: "adresse",
        header: "Adresse",
        size: sizeMediumText,
        Cell: ({ row }) => {
          const adresse = row.original.adresse;

          return <Typography>{isNull(adresse) ? "" : adresse}</Typography>;
        },
      },
      {
        accessorKey: "codePostal",
        header: "Code Postal",
        size: tinyText,
        enableSorting: false,
        Cell: ({ row }) => {
          const codePostal = row.original.codePostal;

          return (
            <Typography>{isNull(codePostal) ? "" : codePostal}</Typography>
          );
        },
      },
      {
        accessorKey: "ville",
        header: "Ville",
        size: tinyText,
        Cell: ({ row }) => {
          const ville = row.original.ville;

          return <Typography>{isNull(ville) ? "" : ville}</Typography>;
        },
      },
      {
        accessorKey: "pays",
        header: "Pays",
        size: tinyText,
        Cell: ({ row }) => {
          const pays = row.original.pays;

          return <Typography>{isNull(pays) ? "" : pays}</Typography>;
        },
      },
      {
        accessorKey: "phone",
        header: "Téléphone",
        size: minSizeMediumText,
        enableClickToCopy: true,
        enableSorting: false,
        Cell: ({ row }) => {
          const phone = row.original.phone;
          if (!phone) {
            return "";
          }
          return (
            <Link href={`tel:${row.original.phone}`}>
              {formatPhoneNumber(row.original?.phone)}
            </Link>
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Date de création",
        enableSorting: false,
        size: sizeMediumText,
        Cell: ({ row }) => {
          const createdAt = row.original.createdAt;
          if (!createdAt) {
            return "";
          }
          const momentDate = moment(createdAt, _DATE_FORMAT.datetime_exact);

          return <DateFormatter momentDate={momentDate} />;
        },
      },
      {
        accessorKey: "isEnabled",
        header: "Etat",
        size: tinyText,
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.isEnabled ? (
            <IconButton
              onClick={() =>
                handleChangeStatus(row.original.id, row.original.isEnabled)
              }
              color="success"
            >
              <BsToggle2On size={30} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() =>
                handleChangeStatus(row.original.id, row.original.isEnabled)
              }
            >
              <BsToggle2Off size={30} />
            </IconButton>
          ),
      },
      {
        accessorKey: "totalContactsFournisseur",
        header: " ",
        size: tinyText,
        enableSorting: false,
        Cell: ({ row }) => {
          const totalContacts = row.original?.contacts?.length || 0;
          const totalUsers = row.original?.users?.length || 0;
          if (typeOfTiers === false) {
            return (
              <Box sx={{ ...boxStyle, backgroundColor: _COLORS.defaultBlue }}>
                <Typography fontSize={"0.7rem"}>
                  <strong>{totalContacts}</strong>
                </Typography>
              </Box>
            );
          }
          return (
            <>
              <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                <Box sx={{ ...boxStyle, backgroundColor: _COLORS.defaultBlue }}>
                  <Typography fontSize={"0.7rem"}>
                    <strong>{totalContacts}</strong>
                  </Typography>
                </Box>
                <Box
                  sx={{ ...boxStyle, backgroundColor: _COLORS.defaultGreen }}
                >
                  <Typography fontSize={"0.7rem"}>
                    <strong>{totalUsers}</strong>
                  </Typography>
                </Box>
              </Box>
            </>
          );
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        size: tinyText,
        Cell: ({ row }) => {
          const id = row.original.id;
          const url = typeOfTiers ? "client" : "fournisseurs";
          return (
            <IconButton
              onClick={() =>
                navigate(`${_ROUTER_DOM_URL.TIERS_EDIT}/${id}/${url}`)
              }
            >
              <FaRegEdit size={20} />
            </IconButton>
          );
        },
      },
    ];

    let finalColumns;
    if (typeOfTiers) {
      finalColumns = [...clientColumns, ...commonColumns];
    } else {
      finalColumns = [...commonColumns];
    }

    return finalColumns;
  }, [typeOfTiers, handleChangeStatus]);

  return columns;
};
