import PhoneNumber from '../components/helpers/PhoneNumber';
import _ from "lodash";

/**
* Formats the phone number.
*
* @param {string|null} phone - The phone number to be formatted.
* @returns {Array<JSX.Element>|null} - An array of JSX elements representing the formatted phone numbers, or null if the input is null.
*/
export const PhoneFormated =  (phone) => {
    if(phone === null || _.isUndefined(phone)) return null;
 
    let phones = String(phone);
    phones = phones.split('/');
 
    
    return phones.map((phone, index) => {
        const format = phone.substring(0,1) === "+" ? '+## # ## ## ## ##' : '## ## ## ## ##';
 
        return <PhoneNumber key={index} phone={phone} format={format}/>;
    });
  }

export default PhoneFormated;