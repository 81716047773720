import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { TicketEditToolBar } from "./TicketEditToolBar";
import TicketEditInfo from "./TicketEditInfo";
import TicketEditVentes from "./TicketEditVentes";
import TicketEditPrets from "./TicketEditPrets";
import TicketEditFiles from "./TicketEditFiles";
import TicketEditInfoDisplay from "./TicketEditInfoDisplay";
import TicketEditContent from "./TicketEditContent";
import TicketEditOtherTicket from "./TicketEditOtherTicket";
import TicketEditDescription from "./TicketEditDescription";
import TicketEditAction from "./TicketEditAction";
import { useEffect, useState } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import TicketEditTiers from "./TicketEditTiers";
import TicketListContextProvider from "../../../contexts/TicketsListContext";
import TicketAutres from "./TicketAutres";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { _MESSAGES } from "../../../utils/constants";
import TicketContratsBox from "./TicketContratsBox";
import Chip from "@mui/material/Chip";
import { FaCheck } from "react-icons/fa";

export const TicketEdit = () => {
  const rq = new RequestHelper();
  const ticketId = useParams().id;
  const [ticket, setTicket] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [isDevisMessage, setIsDevisMessage] = useState(false);
  const [actionsExpanded, setActionsExpanded] = useState(false);
  const [listTechniciens, setListTechniciens] = useState([]);
  const [listProduitsServices, setListProduitsServices] = useState([]);

  useEffect(() => {
    // Fetch ticket data
    rq.get(`tickets/${ticketId}`).then((res) => {
      setTicket(res.data);
    });
  }, [refresh]);

  useEffect(() => {
    if (ticket?.invoiceType?.name === "Devis") {
      setIsDevisMessage(true);
    }
  }, [ticket]);

  const badgeFactured = () => {
    if (ticket?.isFactured)
      return (
        <>
          <Chip icon={<FaCheck />} label="Facturé" color="success" />
        </>
      );
  };

  return (
    <>
      <TicketListContextProvider>
        <Typography variant="h5">
          {ticket?.societe?.name} Ticket N°{ticket?.refTicket} {badgeFactured()}
        </Typography>
        {isDevisMessage && (
          <Stack sx={{ width: "50%" }}>
            <Alert severity="info">{_MESSAGES.message_ticket_devis}</Alert>
          </Stack>
        )}
        <Box
          sx={{
            marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <TicketContratsBox ticket={ticket} setTicket={setTicket} />
          <TicketEditToolBar ticket={ticket} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "25%",
            }}
          >
            <TicketEditInfo
              ticket={ticket}
              setTicket={setTicket}
              refresh={refresh}
              setRefresh={setRefresh}
              setListTechniciens={setListTechniciens}
              listTechniciens={listTechniciens}
              listProduitsServices={listProduitsServices}
              setListProduitsServices={setListProduitsServices}
            />
            <TicketEditVentes ticket={ticket} setTicket={setTicket} />
            <TicketEditPrets ticket={ticket} setTicket={setTicket} />
            <TicketEditFiles ticket={ticket} setTicket={setTicket} />
            <TicketEditInfoDisplay ticket={ticket} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "75%",
              paddingLeft: "50px",
            }}
          >
            <TicketEditTiers ticket={ticket} setTicket={setTicket} />
            <TicketAutres ticket={ticket} />
            <TicketEditContent></TicketEditContent>
            <TicketEditOtherTicket></TicketEditOtherTicket>
            <TicketEditDescription
              ticket={ticket}
              setTicket={setTicket}
              actionsExpanded={actionsExpanded}
              setActionsExpanded={setActionsExpanded}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {ticket?.actions?.map((action, index) => (
                <TicketEditAction
                  key={index}
                  ticket={ticket}
                  setTicket={setTicket}
                  action={action}
                  index={index}
                  actionsExpanded={actionsExpanded}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </TicketListContextProvider>
    </>
  );
};

export default TicketEdit;
