import React, { useEffect, useMemo, useState } from "react";
import FormTitle from "../components/helpers/FormTitle";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  IconButton,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import { TbInfoSquareRounded } from "react-icons/tb";
import { IoMdGitMerge } from "react-icons/io";
import { RequestHelper } from "../utils/requestHelper";
import { _ACTIONS } from "../utils/constants";
import { TableClientAlso } from "../components/parametrage-also/TableClientAlso";
import { createToast } from "../utils/toast";
import { _ROUTES } from "../utils/_routes_constants";

const theme = createTheme({
  palette: {
    background: {
      default: "#ffffff",
    },
  },
});

export const RecapitulatifClientAlso = () => {
  const rq = new RequestHelper();
  const [globalFilter, setGlobalFilter] = useState("");
  const [alsoCompanies, setAlsoCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "compagny",
        header: "ID Client",
      },
      {
        accessorKey: "nameCompagny",
        header: "Client",
        size: 50,
      },
      {
        accessorKey: "action",
        header: "Action",
        Cell: ({ row }) => {
          if (row.original.othersAccountIds.length > 0) {
            return (
              <>
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title="Fusion de plusieurs clients"
                >
                  <IconButton sx={{ color: "black" }}>
                    <TbInfoSquareRounded />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title="Annuler la fusion"
                >
                  <Button
                    onClick={() => unmergeClient(row)}
                    sx={{ color: "black" }}
                  >
                    <IoMdGitMerge />
                  </Button>
                </Tooltip>
              </>
            );
          }
        },
      },
    ],
    []
  );

  useEffect(() => {
    const fetchAlsoCompanies = async () => {
      if (alsoCompanies.length === 0) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      try {
        const pageToFetch = pagination.pageIndex + 1;
        const res = await rq.doRequest(
          _ACTIONS.GET,
          `${_ROUTES.TMP_ALSO}?parent=true&page=${pageToFetch}&nameCompagny=${globalFilter}`
        );
        if (res.status === 200) {
          let data = res.data["hydra:member"];
          setAlsoCompanies(data);
          setRowCount(res.data["hydra:totalItems"]);
        } else {
          createToast("error", "Erreur lors de la fusion");
        }
      } catch (error) {
        createToast("error", "Erreur de réseau ou du serveur");
      } finally {
        setIsLoading(false);
        setIsRefetching(false);
      }
    };

    fetchAlsoCompanies();
  }, [pagination.pageIndex, pagination.pageSize, globalFilter]);

  const unmergeClient = async (row) => {
    const tmpAccountId = (await row.original.othersAccountIds)
      ? row.original.othersAccountIds.map((item) => item)[0]
      : [];
    const res = await rq.doRequest(_ACTIONS.PUT, `unmerge-client-also`, {
      id: row.original.id,
      accountId: tmpAccountId,
    });
    if (res.status !== 200) {
      createToast("error", "Erreur lors de l'annulation de la fusion");
    }
    createToast("success", "La fusion a été annulée");
  };

  const table = useMaterialReactTable({
    columns,
    data: alsoCompanies,
    /* styles */
    muiTableBodyRowProps: {
      sx: {
        backgroundColor: "#ffff",
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: "#ffff",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#ffff",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: "#ffff",
      },
    },
    /* pagination */
    paginationDisplayMode: "pages",
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount,
    /* global filter */
    manualFiltering: true,
    onGlobalFilterChange: (value) => {
      if (value === undefined) {
        value = "";
      }
      setGlobalFilter(value);
    },
    muiPaginationProps: {
      showRowsPerPage: false,
    },
    state: {
      isLoading,
      globalFilter,
      pagination,
      showProgressBars: isRefetching,
    },
    renderDetailPanel: ({ row }) => (
      <Box>
        <TableClientAlso row={row} />
      </Box>
    ),
  });

  return (
    <ThemeProvider theme={theme}>
      <FormTitle title="Récapitulatif client ALSO" />
      <Box
        width={"100%"}
        height={"50rem"}
        sx={{
          marginTop: "1rem",
        }}
      >
        <MaterialReactTable table={table} />
      </Box>
    </ThemeProvider>
  );
};
