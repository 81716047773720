import { Box, Button, FormControl, Typography } from "@mui/material";
import { __FORM_STYLES } from "../../utils/_style_constants";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS } from "../../utils/constants";

export const FileUploader = ({ url, hiddenFields = {} }) => {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Aucun fichier sélectionné");
  const rq = new RequestHelper();

  const handleFileChange = (e) => {
    const file = e.target?.files[0];
    if (!file) {
      setFileName("Aucun fichier sélectionné");
      setFile(null);
      setValue("file", null);
      return;
    }
    setValue("file", file);
    setFile(file);
    setFileName(file.name);
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = (data) => {
    if (file) {
      rq.doRequest(_ACTIONS.POSTFILE, url, data).then((res) => {
        //@TODO make response
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
        justifyContent: "center",
      }}
    >
      <Box>
        <Typography variant="subtitle2">{fileName}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5px",
          width: "100%",
        }}
      >
        <form id="doc" onSubmit={handleSubmit(onSubmit)}>
          {/* Hidden Inputs If necessary */}
          {Object.keys(hiddenFields).map((key, index) => {
            return (
              <input
                key={index}
                type="hidden"
                name={key}
                value={hiddenFields[key]}
                {...register(key)}
              />
            );
          })}
          {/* -------------------------- */}
          <FormControl>
            <Button variant="text" size="small" component="label">
              Sélectionner un fichier <DownloadIcon />
              <input
                type="file"
                name="doc"
                hidden
                onChange={handleFileChange}
              />
            </Button>
          </FormControl>
          <Button
            id="submitDoc"
            type="submit"
            variant="text"
            size="small"
            sx={{ fontWeight: "bold" }}
          >
            Valider le fichier <FileDownloadDoneIcon />
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default FileUploader;
