import React, { useEffect, useState, useContext } from "react";
import { DECOMPTE_TEMPS } from "../../utils/constants";
import FormFilterRecapDT from "../../components/decompte-temps/FormFilterRecapDT";
import { Grid, ThemeProvider, Typography, createTheme } from "@mui/material";
import TableRecapDT from "../../components/decompte-temps/TableRecapDT";
import { UserContext } from "../../contexts/UserContext";
import { _ROUTES } from "../../utils/_routes_constants";
import { getDataSociete } from "../../repository/Societe";

/* Ajouter rôles back */
const recapTheme = createTheme({
  typography: {
    fontFamily: "Poppins, light[300], sans-serif",
  },
  button: {
    fontFamily: "Poppins, light[300], sans-serif",
  },
  palette: {
    color: {
      turquoiseBox: "#00c0ef",
      lightBlue: "#3c8dbc",
      hoverLightBlue: "#98c6e1",
      orangeBox: "#f39c2b",
    },
    background: {
      orangeBox: "#f39c2b",
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            height: "30px",
            paddingTop: "0px",
            paddingBottom: "0px",
            "& input": {
              height: "30px",
              padding: "0px",
            },
          },
        },
      },
    },
  },
});

const DecompteTempsRecap = () => {
  const [societeList, setSocieteList] = useState([]);
  const [resultsFilter, setResultsFilter] = useState();
  const [isExterne, setIsExterne] = useState(false);
  const { getUser } = useContext(UserContext);
  const user = getUser();
 
  /* Société rendu au chargement de la page  */
  useEffect(() => {
    getDataSociete(
      {}, setSocieteList, 
      "Une erreur est survenue lors de la récupération des sociétés", 
      _ROUTES.SOCIETE
    );
  }, []);

  /* Si l'utilisateur est externe on affiche que le recap de sa société */
  useEffect(() => {
    if (user.societeName !== "TOOLIN ( SIÈGE SOCIAL )") {
      setIsExterne(true);
    } else {
      setIsExterne(false);
    }
  }, [user]);

  return (
    <>
      <ThemeProvider theme={recapTheme}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">{DECOMPTE_TEMPS.recap}</Typography>
            {societeList && (
              <FormFilterRecapDT
                societes={societeList}
                setResultFilter={setResultsFilter}
                isExterne={isExterne}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {resultsFilter && resultsFilter !== null && (
              <TableRecapDT
                resultsFilter={resultsFilter}
                isExterne={isExterne}
              />
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default DecompteTempsRecap;
