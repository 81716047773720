import { Button, FormHelperText, Grid, Typography } from "@mui/material";
import { _MESSAGES_DASHBOARD_GENERAL } from "../../utils/dashboard-general/constant";
import { _ACTIONS, _COLORS } from "../../utils/constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { RequestHelper } from "../../utils/requestHelper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createToast } from "../../utils/toast";

export function AvisModal() {
  const requestHelper = new RequestHelper();

  const formik = useFormik({
    initialValues: {
      suggestions: "",
    },
    validationSchema: Yup.object({
      suggestions: Yup.string()
        .min(10, "Votre avis doit contenir au moins 10 caractères")
        .required("Veuillez saisir votre avis"),
    }),
    onSubmit: (values) => {
      requestHelper
        .doRequest(_ACTIONS.POST, "send-avis", values)
        .then((response) => {
          createToast("success", "Votre avis a bien été envoyé");
          formik.resetForm();
        })
        .catch((error) => {
          createToast(
            "error",
            "Une erreur est survenue lors de l'envoi de votre avis"
          );
        });
    },
  });

  return (
    <>
      <Typography
        variant="body2"
        sx={{
          fontStyle: "italic",
          color: _COLORS.darkGrey,
        }}
      >
        {_MESSAGES_DASHBOARD_GENERAL.pModalAvis}
      </Typography>
      <Typography variant="h6" sx={{ mt: "1rem" }} fontWeight={"normal"}>
        {_MESSAGES_DASHBOARD_GENERAL.h2ModalAvis}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CKEditor
            editor={Editor}
            data={formik.values.suggestions}
            onChange={(event, editor) => {
              const data = editor.getData();
              formik.setFieldValue("suggestions", data);
            }}
          />
          {formik.touched.suggestions && formik.errors.suggestions ? (
            <FormHelperText
              sx={{
                color: _COLORS.error,
                fontSize: "1rem",
              }}
            >
              {formik.errors.suggestions}
            </FormHelperText>
          ) : null}
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: "1rem" }}
        type="submit"
        onClick={formik.handleSubmit}
      >
        Soumettre votre avis
      </Button>
    </>
  );
}
