import React, { useEffect } from "react";
import { Checkbox, Grid, TextField, Typography } from "@mui/material";
import moment from "moment";
import MuiDatePicker from "../../helpers/MuiDatePicker";

export const FormFieldsOpnSense = ({ formik, formStates, setFormStates }) => {
  useEffect(() => {
    setFormStates({
      ...formStates,
      isOpnSenseForm: true,
    });
  }, []);

  const toggleCheckbox = (e, field) => {
    formik.setFieldValue(field, e.target.checked);
  };

  return (
    <Grid container spacing={1} justifyContent="center" marginTop={1}>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Key</strong>
            </Typography>
          </Grid>
          <Grid item xs={8.5}>
            <TextField
              fullWidth
              id="apiKey"
              label="Key"
              name="apiKey"
              size="small"
              value={formik.values.apiKey}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.apiKey && Boolean(formik.errors.apiKey)}
              helperText={formik.touched.apiKey && formik.errors.apiKey}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Secret</strong>
            </Typography>
          </Grid>
          <Grid item xs={8.5}>
            <TextField
              fullWidth
              id="apiSecret"
              label="Code Secret"
              name="apiSecret"
              size="small"
              value={formik.values.apiSecret}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.apiSecret && Boolean(formik.errors.apiSecret)
              }
              helperText={formik.touched.apiSecret && formik.errors.apiSecret}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>MaJ</strong>
            </Typography>
          </Grid>
          <Grid item xs={8.5} xl={4}>
            <MuiDatePicker
              label="Date MaJ"
              name="dateMaj"
              value={formik.values.dateMaj}
              onChange={(newDate) => {
                formik.setFieldValue("dateMaj", moment(newDate));
              }}
              errors={formik.errors.dateMaj}
              touched={formik.touched.dateMaj}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* @TODO: TO DISPLAY WHEN CONTRAT DONE */}
      {/* <Grid item xs={12} md={8}> 
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Location</strong>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            
            <Checkbox
              onChange={(e) => toggleCheckbox(e, "location")}
              name="location"
              color="primary"
              checked={formik.values.location}
            />
          </Grid>
          <Grid item xs={6.5}>
            {formik.values.location && (
              <TextField
                fullWidth
                size="small"
                label="Contrat"
                id="Contrat"
                name="contrat"
                size="small"
                label="contrat"
                value={formik.values.contrat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contrat && Boolean(formik.errors.contrat)}
                helperText={formik.touched.contrat && formik.errors.contrat}
              />
            )}
          </Grid>
        </Grid>
      </Grid> */}
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Version</strong>
            </Typography>
          </Grid>
          <Grid item xs={4.25}>
            {/*  */}
            <TextField
              fullWidth
              id="Version"
              name="version"
              size="small"
              label="Version"
              value={formik.values.version}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.version && Boolean(formik.errors.version)}
              helperText={formik.touched.version && formik.errors.version}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={0} xl={3}></Grid>
          <Grid
            item
            xs={5.5}
            xl={4.25}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <Typography variant="body1" component="div">
              <strong>Supervision</strong>
            </Typography>
            <Checkbox
              onChange={(e) => toggleCheckbox(e, "supervision")}
              name="supervision"
              color="primary"
              checked={formik.values.supervision}
            />
          </Grid>
          <Grid
            item
            xs={5.5}
            xl={4.25}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <Typography variant="body1" component="div">
              <strong>Cluster</strong>
            </Typography>
            <Checkbox
              onChange={(e) => toggleCheckbox(e, "cluster")}
              name="cluster"
              color="primary"
              checked={formik.values.cluster}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={0} xl={3}></Grid>
          <Grid
            item
            xs={5.5}
            xl={4.25}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <Typography variant="body1" component="div">
              <strong>ZFS</strong>
            </Typography>
            <Checkbox
              onChange={(e) => toggleCheckbox(e, "zfs")}
              name="zfs"
              color="primary"
              checked={formik.values.zfs}
            />
          </Grid>
          <Grid
            item
            xs={5.5}
            xl={4.25}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <Typography variant="body1" component="div">
              <strong>Unbound</strong>
            </Typography>
            <Checkbox
              onChange={(e) => toggleCheckbox(e, "unbound")}
              name="unbound"
              color="primary"
              checked={formik.values.unbound}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Ip Tunnel</strong>
            </Typography>
          </Grid>
          <Grid item xs={8.5} xl={4}>
            <TextField
              fullWidth
              id="ipTunnel"
              name="ipTunnel"
              size="small"
              label="ip Tunnel"
              value={formik.values.ipTunnel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ipTunnel && Boolean(formik.errors.ipTunnel)}
              helperText={formik.touched.ipTunnel && formik.errors.ipTunnel}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Date Installation</strong>
            </Typography>
          </Grid>
          <Grid item xs={8.5} xl={4}>
            <MuiDatePicker
              label="Date installation"
              name="installationDate"
              value={formik.values.installationDate}
              onChange={(newDate) => {
                formik.setFieldValue("installationDate", moment(newDate));
              }}
              errors={formik.errors.installationDate}
              touched={formik.touched.installationDate}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Uptime</strong>
            </Typography>
          </Grid>
          <Grid item xs={8.5} xl={4}>
            <MuiDatePicker
              label="Uptime"
              name="uptime"
              format="DD/MM/YYYY HH:mm:ss"
              value={formik.values.uptime}
              onChange={(newDate) => {
                formik.setFieldValue("uptime", moment(newDate));
              }}
              errors={formik.errors.uptime}
              touched={formik.touched.uptime}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Fournisseur</strong>
            </Typography>
          </Grid>
          <Grid item xs={8.5} xl={4}>
            <TextField
              fullWidth
              id="fournisseur"
              name="fournisseur"
              size="small"
              label="Fournisseur"
              value={formik.values.fournisseur}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.fournisseur && Boolean(formik.errors.fournisseur)
              }
              helperText={
                formik.touched.fournisseur && formik.errors.fournisseur
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Nombre physique d'interface</strong>
            </Typography>
          </Grid>
          <Grid item xs={8.5} xl={4}>
            <TextField
              fullWidth
              id="numberPhysicalInterface"
              name="numberPhysicalInterface"
              size="small"
              type="number"
              label="Nombre physique d'interfaces"
              value={formik.values.numberPhysicalInterface}
              onChange={(e) => {
                formik.setFieldValue(
                  "numberPhysicalInterface",
                  String(e.target.value)
                );
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.numberPhysicalInterface &&
                Boolean(formik.errors.numberPhysicalInterface)
              }
              helperText={
                formik.touched.numberPhysicalInterface &&
                formik.errors.numberPhysicalInterface
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>url Backup</strong>
            </Typography>
          </Grid>
          <Grid item xs={8.5} xl={4}>
            <TextField
              fullWidth
              id="urlBackup"
              name="urlBackup"
              size="small"
              label="urlBackup"
              value={formik.values.urlBackup}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.urlBackup && Boolean(formik.errors.urlBackup)
              }
              helperText={formik.touched.urlBackup && formik.errors.urlBackup}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
