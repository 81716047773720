import React, { useContext } from "react";
// FORMIK //
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequestHelper } from "../../../../utils/requestHelper";
import { UserContext } from "../../../../contexts/UserContext";
import { _ACTIONS, _COLORS } from "../../../../utils/constants";
import { _ROUTES } from "../../../../utils/_routes_constants";
import { createToast } from "../../../../utils/toast";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { _BS_CONFIG_MESSAGES } from "../../../../utils/base-connaissance/_constants_base_connaissance";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";

/**
 * Renders a form modal for managing categories.
 *
 * @component
 * @param {Function} setShowModal - A function to control the visibility of the modal.
 * @param {Function} setRefresh - A function to trigger a refresh after a category is modified.
 * @param {Object} categoryRow - The category data to be edited, if available.
 */
export function FormModalCategorie({ setShowModal, setRefresh, categoryRow }) {
  const requestHelper = new RequestHelper();
  const { user } = useContext(UserContext);
  const formik = useFormik({
    initialValues: {
      // IF CATEGORY ROW IS TRUTHLY, THEN WE ARE IN EDIT MODE
      title: categoryRow ? categoryRow.title : "",
      description: categoryRow ? categoryRow.description : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Le titre est requis"),
      description: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      let method = categoryRow ? _ACTIONS.PUT : _ACTIONS.POST;
      let url = categoryRow
        ? `${_ROUTES.BASE_CONNAISSANCE_CATEGORIES}/${categoryRow.id}`
        : _ROUTES.BASE_CONNAISSANCE_CATEGORIES;

      const formData = {
        ...values,
      };

      if (!categoryRow) {
        formData.createdAt = new Date().toISOString();
        formData.createdBy = "api/users/" + user.id;
      } else {
        formData.updatedAt = new Date().toISOString();
        formData.updatedBy = "api/users/" + user.id;
      }

      const res = await requestHelper.doRequest(method, url, formData);
      if (res.status === 200 || res.status === 201) {
        createToast("success", "Catégorie modifiée avec succès");
        setRefresh((prev) => prev + 1);
        setShowModal(false);
      } else {
        createToast("error", "Erreur lors de la modification de la catégorie");
      }
    },
  });

  return (
    <>
      <>
        <Box sx={{ mb: 1 }}>
          <Typography sx={{ fontSize: "13px", color: _COLORS.darkGrey }}>
            <em>{_BS_CONFIG_MESSAGES._MODAL_ADD_SECTION_INFO}</em>
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Titre *"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <CKEditor
                editor={Editor}
                name="description"
                data={
                  formik.values.description ? formik.values.description : ""
                }
                onChange={(event, editor) => {
                  const data = editor.getData();
                  formik.setFieldValue("description", data ? data : "");
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={formik.handleSubmit}
              >
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </>
  );
}
