import { IconButton } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import moment from "moment";
import { _DATE_FORMAT } from "../../utils/constants";
import { TextFormatter } from "../../utils/muiTableHelper";
import { isNull } from "lodash";
import { stripHtml } from "../../utils/function";

export function ColonnesReservation({ typeRéservation, doDeleteReservation }) {
  const navigate = useNavigate();
  // URL TO REDIRECT TO REDIRECT TO THE RIGHT WAY OF RESERVATION FORM
  const rightUrl = typeRéservation === 1 ? "externe" : "interne";
  const cols = useMemo(() => {
    const commonColumns = [
      {
        field: "resource.type",
        headerName: "Resource",
        width: 200,
        editable: false,
        valueGetter: (params) => {
          return params.row.resource ? params.row.resource.type : "";
        },
        renderCell: (params) => {
          return (
            <span>{params.row.resource ? params.row.resource.type : ""}</span>
          );
        },
      },
      {
        field: "quantity",
        headerName: "Quantité",
        sortable: false,
        width: 150,
        editable: false,
        valueGetter: (params) => {
          return isNull(params.row.quantity) ? "" : params.row.quantity;
        },
        renderCell: (params) => {
          return isNull(params.row.quantity) ? "" : params.row.quantity;
        },
      },
      {
        field: "startDate",
        headerName: "Date de début",
        width: 150,
        editable: false,
        valueGetter: (params) => {
          return params.row.startDate
            ? moment(params.row.startDate).format(_DATE_FORMAT.date_display)
            : "";
        },
        renderCell: (params) => {
          return params.row.startDate ? (
            <TextFormatter
              text={moment(params.row.startDate).format(
                _DATE_FORMAT.date_display
              )}
              fontSize="1em"
            />
          ) : null;
        },
      },

      {
        field: "endDate",
        headerName: "Date de fin",
        width: 200,
        editable: false,
        valueGetter: (params) => {
          return params.row.endDate
            ? moment(params.row.endDate).format(_DATE_FORMAT.date_display)
            : "";
        },
        renderCell: (params) => {
          return params.row.endDate ? (
            <TextFormatter
              text={moment(params.row.endDate).format(
                _DATE_FORMAT.date_display
              )}
              fontSize="1em"
            />
          ) : null;
        },
      },
      {
        field: "description",
        headerName: "Description",
        width: 150,
        editable: false,
        valueGetter: (params) => {
          return isNull(params.row.description)
            ? ""
            : stripHtml(params.row.description);
        },
        renderCell: (params) => {
          return isNull(params.row.description)
            ? ""
            : stripHtml(params.row.description);
        },
      },
    ];
    const externalColumns = [
      {
        field: "societe.name",
        headerName: "Tiers",
        width: 200,
        editable: false,
        valueGetter: (params) => {
          return params.row.societe ? params.row.societe.name : "";
        },
        renderCell: (params) => (
          <span>{params.row.societe ? params.row.societe.name : ""}</span>
        ),
      },
      {
        field: "ticket.refTicket",
        headerName: "Ticket",
        type: "number",
        width: 200,
        editable: false,
        valueGetter: (params) => {
          return params.row.ticket ? params.row.ticket.refTicket : "";
        },
        renderCell: (params) => (
          <span>{params.row.ticket ? params.row.ticket.refTicket : ""}</span>
        ),
      },
    ];

    const internalColumns = [
      {
        field: "user.lastName",
        headerName: "Technicien",
        width: 200,
        editable: false,
        valueGetter: (params) => {
          return params.row.user
            ? params.row.user.lastName + params.row.user.firstName
            : "";
        },
        renderCell: (params) => {
          return (
            <span>
              {params.row.user
                ? params.row.user.lastName + " " + params.row.user.firstName
                : ""}
            </span>
          );
        },
      },
    ];

    const actionsColumns = [
      {
        field: "Actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "Actions",
        getActions: ({ id }) => {
          return [
            <IconButton
              key="edit"
              title="Edit"
              className="textPrimary"
              color="inherit"
              onClick={() => navigate(`/reservation-form/${rightUrl}/${id}`)}
            >
              <FaEdit style={{ fontSize: "18px" }} />
            </IconButton>,
            <IconButton
              key="delete"
              icon={<RiDeleteBin5Line style={{ fontSize: "18px" }} />}
              title="Delete"
              className="textPrimary"
              color="warning"
              onClick={() => doDeleteReservation(id)}
            >
              <RiDeleteBin5Line style={{ fontSize: "18px" }} />
            </IconButton>,
          ];
        },
      },
    ];

    let cols = [];
    switch (typeRéservation) {
      case 0:
        cols = [...commonColumns, ...internalColumns, ...actionsColumns];
        break;
      case 1:
        cols = [...commonColumns, ...externalColumns, ...actionsColumns];
        break;
      case 2:
        cols = [
          ...commonColumns,
          ...internalColumns,
          ...externalColumns,
          ...actionsColumns,
        ];
        break;
      default:
        cols = [];
    }

    return cols;
  }, [typeRéservation]);

  return cols;
}
