import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { _COLORS } from "../../utils/constants";

const CustomLabelForm = styled(Typography)(({ fontSize, color }) => ({
  color: "black",
  fontSize: fontSize ? fontSize : "0.75rem",
}));

const LabelForm = ({ title, variant, fontSize, color }) => {
  return (
    <CustomLabelForm
      variant={variant ? variant : "body1"}
      component="div"
      fontSize={fontSize}
      color={color}
    >
      <strong>{title}</strong>
    </CustomLabelForm>
  );
};

export default LabelForm;
