import {TYPE_CONTRATS} from "./ColumnsContrat";
import { createToast } from "../../utils/toast";
import { _ROUTES } from "../../utils/_routes_constants";
import { _ACTIONS, _HTTP } from "../../utils/constants";

/**
 * Retrieves the last number of the counter and reference for a given contract type.
 * @param {string} typeContrat - The type of contract.
 * @param {object} requestHelper - The request helper object.
 * @param {function} setId - The function to set the ID.
 * @param {function} setNb - The function to set the number of contracts.
 * @param {function} setStartRef - The function to set the starting reference.
 */
export async function getLastNumberCompteurAndRef(typeContrat, requestHelper){
    const contratConfig = getContratConfig(typeContrat);
    const ref = getRef(typeContrat);

    const result = await requestHelper
    .doRequest(_ACTIONS.GET, `${_ROUTES.COMPTEURS}`, {contratConfigId: contratConfig})
        .then((response) => {
            if (200 !== response.status) {
                throw new Error(
                    "Impossible de récupérer le dernier numéro de compteur" 
                );
            }
            const datas = response.data["hydra:member"][0];

            return [datas.nbContrats, ref, datas.id];

        })
        .catch((error) => {
            createToast("error", error.message);

            return null;
        });

    return result;
}

/**
 * Saves the last number of contracts.
 *
 * @param {Object} requestHelper - The request helper object.
 * @param {string} id - The ID of the contract.
 * @param {number} nb - The number of contracts.
 * @returns {null} - Returns null.
 */
export function saveLastNb(requestHelper, id, nb) {

    requestHelper
    .doRequest(_ACTIONS.PUT, `${_ROUTES.COMPTEURS}/${id}`, {nbContrats: nb})
        .then((response) => {
            if (200 !== response.status) {
                throw new Error(
                    "Impossible de sauvegarder le dernier numéro de compteur" 
                );
            }

            return nb;
        })
        .catch((error) => {
            createToast("error", error.message);

            return null;
        });
}

/**
 * Returns the configuration value for a given type of contract.
 *
 * @param {string} typeContrat - The type of contract.
 * @returns {number} The configuration value for the given type of contract.
 */
export function getContratConfig(typeContrat){
    switch(typeContrat){
        case TYPE_CONTRATS.CONTRAT_MAINTENANCE:
            return 1;
        case TYPE_CONTRATS.CONTRAT_HEBERGEMENT:
            return 2;
        case TYPE_CONTRATS.CONTRAT_AUTRES:
            return 3;
        case TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE:
            return 4;
        case TYPE_CONTRATS.CONTRAT_REGIE:
            return 5;
        case TYPE_CONTRATS.CONTRAT_LOCATION:
            return 6;
        case TYPE_CONTRATS.CONTRAT_BEEMO:
            return 7;
    }
}

/**
 * Returns the reference code based on the given type of contract.
 *
 * @param {string} typeContrat - The type of contract.
 * @returns {string} The reference code.
 */
export function getRef(typeContrat){
    switch(typeContrat){
        case TYPE_CONTRATS.CONTRAT_MAINTENANCE:
            return "MT-";
        case TYPE_CONTRATS.CONTRAT_HEBERGEMENT:
            return 'INFO-';
        case TYPE_CONTRATS.CONTRAT_AUTRES:
            return "AUT-";
        case TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE:
            return "SAUV-";
        case TYPE_CONTRATS.CONTRAT_REGIE:
            return "REG-";
        case TYPE_CONTRATS.CONTRAT_LOCATION:
            return "LOC-";
        case TYPE_CONTRATS.CONTRAT_BEEMO:
            return "BE-";    
    }
}