import { Box, IconButton } from "@mui/material";
import React from "react";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdOutlineSettings } from "react-icons/md";
import { _ROUTER_DOM_URL } from "../../utils/_routes_constants";
import { createConfirmToast } from "../../utils/toast";

export const RowActionsProduitService = ({
  requestHelper,
  _ACTIONS,
  _ROUTES,
  setParentPS,
  row,
  handleOpenCategorie,
  navigate,
  setShowModal,
  setIsEdit,
  setRowId,
}) => {
  const doConfirmDelete = async (id) => {
    createConfirmToast(
      "warning",
      null,
      "Êtes-vous sûr de vouloir supprimer le catalogue et toutes les informations qui en dépendent ?",
      "Oui",
      "Non"
    ).then((res) => {
      if (res) {
        requestHelper.doRequest(
          _ACTIONS.DELETE,
          `${_ROUTES.PRODUIT_SERVICE}/${row.original.id}`
        );
        setParentPS((prevData) =>
          prevData.filter((item) => item.id !== row.original.id)
        );
      }
    });
  };

  return (
    <Box display="flex" flexWrap={"nowrap"}>
      <IconButton
        key="1"
        size="small"
        color="inherit"
        title="Ajouter une catégorie"
        onClick={() => handleOpenCategorie(row.original["@id"])}
      >
        <MdOutlineLibraryAdd />
      </IconButton>
      <IconButton
        key="2"
        disabled={row.original.isDefault}
        size="small"
        title="Supprimer"
        color="error"
        onClick={() => {
          doConfirmDelete(row.original.id);
        }}
      >
        <RiDeleteBin5Line />
      </IconButton>
      <IconButton
        key="3"
        size="small"
        title="Editer"
        color="success"
        onClick={() => {
          setShowModal(true);
          setIsEdit(true);
          setRowId(row.original.id);
        }}
      >
        <FaEdit />
      </IconButton>
      <IconButton
        key="4"
        size="small"
        title="Configurer le produit service"
        color="inherit"
        onClick={() =>
          navigate(
            `${_ROUTER_DOM_URL.PRODUIT_SERVICE}/config/${row.original.id}`
          )
        }
      >
        <MdOutlineSettings />
      </IconButton>
    </Box>
  );
};
