import FomTitle from '../components/helpers/FormTitle';
import Param from '../components/parametrages/Param';
import { TYPE_PARAM } from '../utils/constants';

export const ParametrageLocation = () => {
    return (
        <>
        <FomTitle title="Paramétrage location" />
        <Param 
            route='contrat-type-locations'
            type={TYPE_PARAM.LOCATION}
        />
    </>
    );
};

export default ParametrageLocation;