import { useContext, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuGroup from "../../components/navigation/MenuGroup";
import { GlobalContext } from "../../contexts/GlobalContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestHelper } from "../../utils/requestHelper";
import { useNavigate } from "react-router-dom";
import { createToast } from "../../utils/toast";
import { _ACTIONS, _COLORS, _NAV, _SWAL_ICONS } from "../../utils/constants";
import { removeCookie } from "../../utils/cookieHelper";
import { Button, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";

export const drawerWidth = 270;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    marginTop: "64px",
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}));

export default function SidebarNav({ children, isActive = true }, props) {
  const theme = useTheme();
  const { open, setOpen } = useContext(GlobalContext);
  const { getUser, setUser, token, setToken } = useContext(UserContext);
  const user = getUser();
  const requestHelper = new RequestHelper();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    requestHelper
      .doRequest(_ACTIONS.GET, `${_ROUTES.LOGOUT}`)
      .then((response) => {
        if (response.status === 200) {
          setUser(null);
          setToken(null);
          delete sessionStorage.user;
          removeCookie("token");
          navigate(_ROUTER_DOM_URL.HOME);
        } else {
          createToast(_SWAL_ICONS.error, "Une erreur est survenue");
        }
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar open={open} sx={{ bgcolor: "#01a65a" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h6" component="div">
              {_NAV.title}
            </Typography>
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <Box>
                {user && (
                  <Typography>
                    <strong>
                      {user.firstname} {user.lastname}
                    </strong>
                  </Typography>
                )}
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  component="div"
                  onClick={handleLogout}
                  sx={{
                    color: "white",
                    borderColor: "white",
                    ":hover": { color: "white", borderColor: "white" },
                  }}
                >
                  {_NAV.logout}
                </Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          border: "none",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: _COLORS.drawerNav,
            border: "none",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          sx={{
            backgroundColor: "#01a65a",
            width: "100%",
            margin: "0",
          }}
        >
          <Box>
            <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon color="white" />
              ) : (
                <ChevronRightIcon color="white" />
              )}
            </IconButton>
          </Box>
        </DrawerHeader>
        <Divider />
        <MenuGroup {...props.onClick} />
        <Divider />
      </Drawer>
      <DrawerHeader />
      <Main open={open}>{children}</Main>
    </Box>
  );
}
