import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { IoIosTimer } from "react-icons/io";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { IoCreateOutline } from "react-icons/io5";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { RiMailCloseLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import moment from "moment";
import Loader from "../../utils/Loader";

export const TicketEditInfoDisplay = (ticket) => {
  const [totalTime, setTime] = useState(0);
  const [totalRealTime, setRealTime] = useState(0);
  const [dates, setDates] = useState({
    startAt: null,
    lastUpdate: null,
    closedAt: null,
  });
  const [users, setUsers] = useState({
    user: null,
    updatedBy: null,
    closedBy: null,
  });

  useEffect(() => {
    ticket?.ticket?.actions?.map((action) => {
      setRealTime(totalRealTime + action.durationReal);
      setTime(totalTime + action.durationEstimated);
    });
    setDates({
      startAt: ticket?.ticket?.createdAt,
      lastUpdate: ticket?.ticket?.updatedAt,
      closedAt: ticket?.ticket?.closedAt,
    });
    setUsers({
      createdBy:
        ticket?.ticket?.createdBy !== null
          ? ticket?.ticket?.createdBy?.firstName +
            " " +
            ticket?.ticket?.createdBy?.lastName
          : null,
      updatedBy:
        ticket?.ticket?.updatedBy !== null
          ? ticket?.ticket?.updatedBy?.firstName +
            " " +
            ticket?.ticket?.updatedBy?.lastName
          : null,
      closedBy: ticket?.ticket?.closedBy
        ? ticket?.ticket?.closedBy?.firstName +
          " " +
          ticket?.ticket?.closedBy?.lastName
        : null,
    });
  }, [ticket]);

  return (
    <>
      {ticket.ticket ? (
        <Card sx={{ mt: 2 }}>
          <Typography sx={{ m: 2, fontSize: "1rem" }} color="text.secondary">
            <strong>Informations</strong>
          </Typography>

          <Typography sx={{ m: 2 }} variant="body2">
            <IoIosTimer /> Temps total facturable: <b>{totalTime}</b> mn
          </Typography>
          <Typography sx={{ m: 2 }} variant="body2">
            <LiaBusinessTimeSolid /> Temps total réel: <b>{totalRealTime}</b> mn
          </Typography>
          {users.createdBy && (
            <Typography sx={{ m: 2 }} variant="body2">
              <MdOutlineCreateNewFolder /> Ouvert par <b>{users.createdBy}</b>{" "}
              le <b>{moment(dates.createdBy).format("DD/MM/YYYY")}</b> à{" "}
              <b>{moment(dates.startAt).format("HH:mm")}</b>
            </Typography>
          )}
          {users.updatedBy !== null && (
            <Typography sx={{ m: 2 }} variant="body2">
              <IoCreateOutline /> Dernière modification par{" "}
              <b>{users.updatedBy}</b> le{" "}
              <b>{moment(dates.lastUpdate).format("DD/MM/YYYY")}</b> à{" "}
              <b>{moment(dates.lastUpdate).format("HH:mm")}</b>
            </Typography>
          )}
          {dates.lastUpdate !== null && users.updatedBy === null && (
            <Typography sx={{ m: 2 }} variant="body2">
              <IoCreateOutline /> Dernière modification le{" "}
              <b>{moment(dates.lastUpdate).format("DD/MM/YYYY")}</b> à{" "}
              <b>{moment(dates.lastUpdate).format("HH:mm")}</b>
            </Typography>
          )}
          {users.closedBy !== null && (
            <Typography sx={{ m: 2 }} variant="body2">
              <RiMailCloseLine /> Clôturé par <b>{users.closedBy}</b> le{" "}
              <b>{moment(dates.closedAt).format("DD/MM/YYYY")}</b> à{" "}
              <b>{moment(dates.closedAt).format("HH:mm")}</b>
            </Typography>
          )}
          {dates.closedAt !== null && users.closedBy === null && (
            <Typography sx={{ m: 2 }} variant="body2">
              <RiMailCloseLine /> Clôturé le{" "}
              {moment(dates.closedAt).format("DD/MM/YYYY")} à{" "}
              {moment(dates.closedAt).format("HH:mm")}
            </Typography>
          )}
        </Card>
      ) : (
        <Card sx={{ mt: 2 }}>
          {/* LOADER */}
          <Loader height={100} />
        </Card>
      )}
    </>
  );
};

export default TicketEditInfoDisplay;
