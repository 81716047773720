import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { __FORM_STYLES } from "../../../utils/_style_constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { useEffect, useState } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import _ from "lodash";
import { _ACTIONS, _COLORS } from "../../../utils/constants";
import { _ROUTES } from "../../../utils/_routes_constants";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FileType } from "../../form/FileType";
import { TicketPurchase } from "./TicketPurchase";

export const TicketCreateActions = ({
  ticket,
  setTicket,
  actionsExpanded,
  listTechniciens,
  formData,
  setFormData,
}) => {
  const [description, setDescription] = useState("");
  const [actionsType, setActionsType] = useState([]);
  const [dolProducts, setDolProducts] = useState([]);

  useEffect(() => {
    if (_.isEmpty(actionsType)) {
      const rq = new RequestHelper();
      rq.doRequest(_ACTIONS.GET, _ROUTES.ACTION_TYPE).then((res) => {
        setActionsType(res.data["hydra:member"]);
      });
    }
    if (_.isEmpty(dolProducts)) {
      const rq = new RequestHelper();
      rq.doRequest(_ACTIONS.GET, _ROUTES.DOL_PRODUCTS_LIST).then((res) => {
        setDolProducts(res.data);
      });
    }
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* Action Container */}
        <Box
          sx={{
            display: actionsExpanded ? "flex" : "none",
            flexDirection: "column",
            marginTop: "10px",
            marginBottom: "10px",
            border: "1px solid " + _COLORS.warning,
            borderRadius: "5px",
            padding: "10px",
          }}>
          <Box>
            <Box>
              <FormControl sx={__FORM_STYLES.basicInput} size="small">
                <FormLabel>Description</FormLabel>
                <CKEditor
                  editor={Editor}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                  onBlur={(event, editor) => {
                    let newTicket = { ...ticket };
                    newTicket.actions[0].description = description;
                    setTicket(newTicket);
                  }}></CKEditor>
              </FormControl>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl size="small" sx={__FORM_STYLES.basicInput}>
                  <FormLabel>Type</FormLabel>
                  <Select
                    value={ticket.actions[0]?.actionType}
                    onChange={(e) => {
                      let newTicket = { ...ticket };
                      newTicket.actions[0].actionType = e.target.value;
                      setTicket(newTicket);
                    }}>
                    {actionsType.map((o, i) => (
                      <MenuItem key={o.id} value={o["@id"]}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" sx={__FORM_STYLES.basicInput}>
                  <FormLabel>Techincien</FormLabel>
                  <Select
                    value={ticket.actions[0]?.user}
                    onChange={(e) => {
                      let newTicket = { ...ticket };
                      newTicket.actions[0].user = e.target.value;
                      setTicket(newTicket);
                    }}>
                    {listTechniciens.map((o, i) => (
                      <MenuItem key={o.id} value={o["@id"]}>
                        {o.lastName + " " + o.firstName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <FormControl size="small" sx={__FORM_STYLES.basicInput}>
                  <FormLabel>Date d'action</FormLabel>
                  <DateTimePicker
                    size="small"
                    value={ticket.actions[0]?.achievedAt}
                    onChange={(e) => {
                      let newTicket = { ...ticket };
                      newTicket.actions[0].achievedAt = e;
                      setTicket(newTicket);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}></DateTimePicker>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" sx={__FORM_STYLES.basicInput}>
                  <FormLabel>Durée facturable (mn)</FormLabel>
                  <TextField
                    value={ticket.actions[0]?.durationEstimated}
                    onChange={(e) => {
                      let newTicket = { ...ticket };
                      newTicket.actions[0].durationEstimated = e.target.value;
                      newTicket.actions[0].durationReal = e.target.value;
                      setTicket(newTicket);
                    }}
                    size="small"
                    inputProps={{
                      type: "number",
                      min: 0,
                      step: 15,
                    }}></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" sx={__FORM_STYLES.basicInput}>
                  <FormLabel>Durée réelle (mn)</FormLabel>
                  <TextField
                    value={ticket.actions[0]?.durationReal}
                    onChange={(e) => {
                      let newTicket = { ...ticket };
                      newTicket.actions[0].durationReal = e.target.value;
                      setTicket(newTicket);
                    }}
                    size="small"
                    inputProps={{
                      type: "number",
                      min: 0,
                      step: 15,
                    }}></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  size="small"
                  sx={{
                    margin: "5px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Checkbox
                    checked={ticket.actions[0]?.isPrivate ? ticket.actions[0]?.isPrivate : false}
                    onChange={(e) => {
                      let newTicket = { ...ticket };
                      newTicket.actions[0].isPrivate = e.target.checked;
                      setTicket(newTicket);
                    }}
                  />
                  <FormLabel>Note Interne </FormLabel>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <FileType
                setEntity={(f) => {
                  setFormData({
                    ...formData,
                    actionFiles: f,
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
        {/* Purchases */}
        <Box
          sx={{
            display: actionsExpanded ? "flex" : "none",
            flexDirection: "column",
            marginTop: "10px",
            marginBottom: "10px",
            border: "1px solid " + _COLORS.success,
            borderRadius: "5px",
            padding: "10px",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Button
              variant="contained"
              size="small"
              sx={{ color: "white", backgroundColor: _COLORS.defaultBlue }}
              onClick={() => {
                let newPurchase = {
                  dolProducts : "",
                  description: "",
                  quantity: 1,
                  serialNumber: "",
                  price: 0,
                  createdAt: new Date(),
                };

                let newTicket = { ...ticket };
                newTicket.actions[0].purchases.push(newPurchase);
                setTicket(newTicket);
              }}>
              Ajouter une vente
            </Button>
          </Box>
          {/* Purchases Container */}
          <Box>
            {ticket.actions[0]?.purchases.map((p, i) => {
              return (
                <>
                  <TicketPurchase
                    purchase={p}
                    ticket={ticket}
                    setTicket={setTicket}
                    index={i}
                    dolProducts={dolProducts}
                    ></TicketPurchase>
                </>
              );
            })}
          </Box>
        </Box>
      </LocalizationProvider>
    </>
  );
};
