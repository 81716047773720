import { Box } from "@mui/material";
import React from "react";

/**
 * Renders the content of an article.
 * Include the article's description.
 *
 * @component
 * @param {Object} props.article - The article object containing the description.
 */
export default function ArticleContent({ article }) {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: article?.description }}></div>
    </Box>
  );
}
