import React from "react";
import { PRODUIT_SERVICE, _COLORS } from "../../utils/constants";
import { Box, ThemeProvider, Typography, createTheme } from "@mui/material";
import DataGridDetailPanel from "../../components/produit-service/DataGridDetailPanel";
import FormTitle from "../../components/helpers/FormTitle";

const ProduitService = () => {
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          footerContainer: {
            backgroundColor: _COLORS.lightGrey,
          },
          columnHeader: {
            backgroundColor: _COLORS.lightGrey,
            fontWeight: "bold",
          },
          columnHeaderTitle: {
            fontWeight: "bold",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderTop: `0.5rem solid ${_COLORS.lightGrey}`, // spécifiez la couleur que vous souhaitez
          },
        },
      },
    },
    palette: {
      custom: {
        buttonGreen: "#57a85c",
        hoverGreen: "#74A677",
        chipCategorie: "#4fc1ef",
        buttonError: "#d43e2f",
        hoverButtonError: "973F37",
      },
      background: {
        default: _COLORS.lightGrey,
        lightGrey: "#f4f4f4",
        grey: "777a7f",
        lightBlue: "#3c8dbc",
        turquoiseBox: "#00c0ef",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box display={"flex"} alignItems={"baseline"} gap={3}>
          <FormTitle title={PRODUIT_SERVICE.h1}>
            <Typography variant="body2">{PRODUIT_SERVICE.subh1}</Typography>
          </FormTitle>
        </Box>
        <DataGridDetailPanel />
      </ThemeProvider>
    </>
  );
};

export default ProduitService;
