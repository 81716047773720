import { Box } from "@mui/material";
import FilterButtons from "./FilterButtons";
import NewTicket from "./NewTicket";
import TableToolBox from "./TableToolBox";

export const ListToolBar = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        {/* Filters + New Tickets */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FilterButtons></FilterButtons>
          <NewTicket></NewTicket>
        </Box>

        {/* @TODO Mui Table Utils  */}
        <Box>
          <TableToolBox></TableToolBox>
        </Box>
      </Box>
    </>
  );
};
