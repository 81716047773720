import { Box } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { DateFormatter } from "../../utils/muiTableHelper";
import moment from "moment";

export const TicketHistory = (props) => {
  const table = useMaterialReactTable({
    columns: [
      {
        id: "createdAt",
        accessorKey: "createdAt",
        header: "Date",
        Cell: ({ row }) => {
          return (
            <DateFormatter
              momentDate={moment(row.original.createdAt)}
            ></DateFormatter>
          );
        },
      },
      { id: "message", accessorKey: "message", header: "Message", size: 300 },
    ],
    data: props.ticketLogs ? props.ticketLogs : [],
  });

  return (
    <>
      <Box>
        {props.ticketLogs.length > 0 && <MaterialReactTable table={table} />}
      </Box>
    </>
  );
};
