import { isNull } from "lodash";
import React, { useMemo } from "react";
import { _COLORS } from "../../../utils/constants";
import { Chip } from "@mui/material";
import { stripHtml } from "../../../utils/function";

/**
 * Returns an array of columns for the BsConfiguration component.
 *
 * @returns {Array} The array of columns.
 */
export function ColonnesBsConfiguration() {
  const columns = useMemo(
    () => [
      {
        accessorKey: "sectionCategorie",
        header: "",
        Cell: ({ row }) => {
          return isNull(row.original.sectionCategorie) ? (
            ""
          ) : (
            <Chip
              label={`${row.original.categorieBaseConnaissancesSection.length} sections`}
              size="small"
              sx={{
                backgroundColor: _COLORS.buttonLightBlue,
                color: "white",
              }}
            />
          );
        },
      },
      {
        accessorKey: "title",
        header: "Titre",
        Cell: ({ row }) => {
          return isNull(row.original.title) ? "" : row.original.title;
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ row }) => {
          return isNull(row.original.description)
            ? ""
            : stripHtml(row.original.description);
        },
      },
    ],
    []
  );

  return columns;
}
