import React, { useContext, useEffect, useState } from "react";
// MUI //
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
// FORMIK //
import { useFormik } from "formik";
import * as Yup from "yup";
// OTHERS //
import { _BS_CONFIG_MESSAGES } from "../../../../utils/base-connaissance/_constants_base_connaissance";
import { _COLORS } from "../../../../utils/constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { createToast } from "../../../../utils/toast";
import { RequestHelper } from "../../../../utils/requestHelper";
import { _ROUTES } from "../../../../utils/_routes_constants";
import { UserContext } from "../../../../contexts/UserContext";
import { getDataBaseConnaissance } from "../../../../repository/BaseConnaissance";

/**
 * Renders a form modal section component.
 *
 * @component
 * @param {Function} setShowModal - A function to control the visibility of the modal.
 * @param {Function} setRefresh - A function to trigger a refresh action.
 * @param {Object} sectionRow - CONTAINS OBJECT OF CURRENT ROW IN EXPANDED ROW SECTION (EDIT MODE).
 */
export function FormModalSection({ setShowModal, setRefresh, sectionRow }) {
  const requestHelper = new RequestHelper();
  const [categories, setCategories] = useState(null);
  const { user } = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      ...(!sectionRow && {
        categorie: "",
      }),
      // IF SECTION ROW IS TRUTHLY, THEN WE ARE IN EDIT MODE
      title: sectionRow ? sectionRow.title : "",
      description: sectionRow ? sectionRow.description : "",
      isInterne: sectionRow ? sectionRow.isInterne : false,
    },
    validationSchema: Yup.object({
      ...(!sectionRow && {
        categorie: Yup.string().required("La catégorie est requise"),
      }),
      title: Yup.string().required("Le titre est requis"),
      description: Yup.string().nullable(),
      isInterne: Yup.boolean().required("Le champ est requis"),
    }),
    onSubmit: async (values) => {
      let method = sectionRow ? "PUT" : "POST";
      let url = sectionRow
        ? `${_ROUTES.BASE_CONNAISSANCE_SECTION}/${sectionRow.id}`
        : _ROUTES.BASE_CONNAISSANCE_SECTION;

      const formData = {
        ...values,
      };

      if (!sectionRow) {
        formData.createdAt = new Date().toISOString();
        formData.createdBy = "api/users/" + user.id;
      } else {
        formData.updatedAt = new Date().toISOString();
        formData.updatedBy = "api/users/" + user.id;
      }

      const res = await requestHelper.doRequest(method, url, formData);
      if (res.status === 201 || res.status === 200) {
        createToast("success", "Section ajoutée avec succès");
        setRefresh((prev) => prev + 1);
        setShowModal(false);
      } else {
        createToast("error", "Erreur lors de l'ajout de la section");
      }
    },
  });

  useEffect(() => {
    getDataBaseConnaissance(
      {"order[title]": "ASC"}, 
      setCategories, 
      "Erreur lors de la récupération des catégories", 
      _ROUTES.BASE_CONNAISSANCE_CATEGORIES
    );
  }, []);

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Typography sx={{ fontSize: "13px", color: _COLORS.darkGrey }}>
          <em>{_BS_CONFIG_MESSAGES._MODAL_ADD_SECTION_INFO}</em>
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {/* IF SECTION ROW IS THUTHLY, THEN WE ARE IN EDIT AND WE CANNOT MODIFY THE CATEGORY*/}
          {!sectionRow && (
            <>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.categorie && Boolean(formik.errors.categorie)
                  }
                >
                  <InputLabel size="small" id="categorie-label">
                    Catégorie *
                  </InputLabel>
                  <Select
                    id="categorie"
                    name="categorie"
                    labelId="categorie-label"
                    value={formik.values.categorie}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    size="small"
                  >
                    <MenuItem value="">
                      <em>Veuillez choisir un catégorie</em>{" "}
                    </MenuItem>
                    {categories &&
                      categories.map((category) => (
                        <MenuItem key={category.id} value={category["@id"]}>
                          {category.title}
                        </MenuItem>
                      ))}
                  </Select>
                  {formik.touched.categorie && formik.errors.categorie ? (
                    <FormHelperText>{formik.errors.categorie}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="title"
              name="title"
              label="Titre *"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <CKEditor
              editor={Editor}
              name="description"
              data={formik.values.description ? formik.values.description : ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                formik.setFieldValue("description", data ? data : "");
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              onChange={(e) =>
                formik.setFieldValue("isInterne", e.target.checked)
              }
              name="location"
              color="primary"
              checked={formik.values.isInterne}
            />
            <Typography variant="body2" component="div">
              <em>
                {" "}
                Pour une utilisation en interne uniquement (masque la section
                des clients)
              </em>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={formik.handleSubmit}
            >
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
