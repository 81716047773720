export const boxStyle = {
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "1.2rem",
  height: "1.2rem",
};

export const tiers_columnsVisibilities = {
  totalContactsFournisseur: true,
  GLPI: true,
  ALSO: true,
  folderDrive: true,
  name: true,
  email: true,
  ville: false,
  adresse: false,
  codePostal: false,
  pays: false,
  phone: true,
  aliasToolin: false,
  "referent.firstName": false,
  "referentClient.firstName": false,
  domains: false,
  createdAt: false,
  lastAuditDate: false,
  keeperVault: false,
  decompteTempsPersonnaliseBool: false,
  holding: false,
  isEnabled: true,
  actions: true,
};
