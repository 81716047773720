import { Grid } from "@mui/material";
import { _COLORS } from "../../utils/constants";
import { StatRecapTicket } from "./StatRecapTicket";

export const EtatTicket = ({ canAccess, user }) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        w: "100%",
        p: 2,
        borderTop: `2px solid ${_COLORS.lightDanger}`,
        backgroundColor: _COLORS.white,
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Grid item xs={12} md={6}>
        <StatRecapTicket isUser={true} user={user} />
      </Grid>
      {/* Si utilisateur connecté n'a pas au minimum le role technicien, cache le bloc support */}
      {canAccess && (
        <Grid item xs={12} md={6}>
          <StatRecapTicket />
        </Grid>
      )}
    </Grid>
  );
};
