import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Box } from "@mui/material";
import 'react-big-calendar/lib/css/react-big-calendar.css';


const CalendarReservations = ({ reservations }) => {
    const localizer = momentLocalizer(moment);

    /**
     * French translation object for the calendar.
     * @type {Object}
     */
    const traductionFr = {
        next: "Suivant",
        previous: "Précédent",
        today: "Aujourd'hui",
        month: "Mois",
        week: "Semaine",
        day: "Jour"
    };

    /**
     * Returns the style object for the event based on the provided event color.
     *
     * @param {Object} params - The parameters object.
     * @param {string} params.event - The color of the event.
     * @returns {Object} The style object for the event.
     */
    const eventStyleGetter = ({event}) => {
        const backgroundColor = '#' + event;
        const style = {
            backgroundColor: backgroundColor,
            borderRadius: '10px',
            border: '0px',
            display: 'block',
            color: 'white',
            cursor: 'pointer'
        };

        return {
            style: style
        };
    };

  
    /**
     * Generates a tooltip text based on the provided parameters.
     *
     * @param {Object} options - The options for generating the tooltip.
     * @param {string} options.societe - The name of the company.
     * @param {string} options.user - The name of the user.
     * @param {string} options.title - The title of the resource.
     * @param {number} options.quantity - The quantity of the resource.
     * @param {number} options.nbJours - The number of days.
     * @returns {string} The generated tooltip text.
     */
    const tooltip = ({societe, user, title, quantity, nbJours }) => {
        const text = [];
        if(societe) text.push( 'Société : ' + societe);
        if(user) text.push('Utilisateur : ' + user);
        text.push('Ressource : ' + title);
        text.push('Quantité : ' + quantity);
        text.push('Temps : ' + nbJours);

        return text.join(' | ');
    };

    return (
        <Box sx={{marginTop: 2}}>
            <Calendar
                events={reservations?.length > 0 ? reservations : []}
                localizer={localizer}
                defaultDate={moment().toDate()}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                messages={traductionFr}
                eventPropGetter={eventStyleGetter}
                popup= {true}
                tooltipAccessor={tooltip}
             />
        </Box>
    );
}

export default CalendarReservations;