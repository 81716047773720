import _ from "lodash";
import moment from "moment/moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { _COLORS, _DATE_FORMAT } from "../../../utils/constants";

export const ActionDisplay = ({ action }) => {
  const panel = action?.isPrivate ? _COLORS.lightGreen : _COLORS.lightGrey;
  const userName = action.user
    ? action?.user?.firstName + " " + action?.user?.lastName
    : "Utilisateur inconnu";

  return (
    <>
      <Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: panel }}
          >
            {/* Top Bar Content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                fontWeight: 400,
                lineHeight: 1,
                color: "#777",
              }}
            >
              {/* Top left Content */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography fontSize="10px">
                  {userName} ( {action?.actionType?.name} ){" "}
                </Typography>
                {action.isPrivate ? (
                  <Box
                    sx={{
                      display: "flex",
                      padding: "5px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                      textAlign: "center",
                      justifyItems: "center",
                      backgroundColor: _COLORS.lightGrey,
                    }}
                  >
                    <Typography fontSize={"8px"}>INTERNE</Typography>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              {/* Top right Content */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={"10px"}>
                  <QueryBuilderIcon fontSize="inherit" /> Date :{" "}
                  {moment(action?.createdAt).format(
                    _DATE_FORMAT.date_and_time_display
                  )}{" "}
                  | <CalendarMonthIcon fontSize="inherit" /> Durée Fact (mn) :{" "}
                  {action?.durationEstimated}mn |{" "}
                  <DirectionsCarIcon fontSize="inherit" /> Dép. :{" "}
                  {action?.isTransport ? "1" : "0"} |{" "}
                  <ShoppingCartIcon fontSize="inherit" /> Ventes :{" "}
                  {action?.purshases?.length} |{" "}
                  <InsertDriveFileIcon fontSize="inherit" /> Docs :{" "}
                  {action?.files?.length}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          {/* Action Content */}
          <AccordionDetails>
            <Box dangerouslySetInnerHTML={{ __html: action.description }}></Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default ActionDisplay;
