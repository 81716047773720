import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import styled from "@emotion/styled";
import { MaterialReactTable } from "material-react-table";

/**
 * Template général des DataGrid
 */
export const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

/**
 * Template général des MaterialReactTable
 */
export const CustomMaterialReactTable = styled(MaterialReactTable)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      backgroundColor: "#FFFFFF",
    },
  })
);
