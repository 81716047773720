import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function PrixFacturationTemplate(props) {

  return (
    <div style={{ height: props.height, width: "100%" }}>
      <DataGrid
        apiRef={props.apiRef}
        rows={props.row}
        columns={props.columns}
        hideFooterPagination={true}
        processRowUpdate={props.processRowUpdate}
        onProcessRowUpdateError={props.onProcessRowUpdateError}
      />
    </div>
  );
}
