
/**
 * Finds a client by their ID in the given array of clients.
 *
 * @param {string} id - The ID of the client to find.
 * @param {Array} clients - The array of clients to search in.
 * @returns {Object|undefined} - The client object if found, otherwise undefined.
 */
export const getClientById = (id, clients) => {
    return clients.find(client => client.id === id);
}
