import { Button, FormControl, Typography } from "@mui/material";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";

export const FileType = ({ setEntity }) => {
  const [fileNames, setFileNames] = useState("Aucun fichier sélectionné");

  const handleFileChange = (e) => {
    const files = e.target.files;
    let fileNames = "";
    if (!files) {
      setFileNames("Aucun fichier sélectionné");
      return;
    }
    Array.from(files).forEach((file, i) => {
      fileNames += i === 0 ? file.name + " " : ", " + file.name + " ";
    });
    setFileNames(fileNames);
    setEntity(files);
  };

  return (
    <>
      <FormControl>
        <Typography variant="subtitle2">
          {fileNames ? fileNames : "Aucun fichier sélectionné"}
        </Typography>
        <Button variant="text" size="small" component="label">
          Sélectionner un fichier <DownloadIcon />
          <input
            type="file"
            name="files"
            hidden
            multiple
            onChange={handleFileChange}
          />
        </Button>
      </FormControl>
    </>
  );
};
