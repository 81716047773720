import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import { _ACTIONS, _COLORS, _DATE_FORMAT } from "../../../utils/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useEffect, useState } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import Swal from "sweetalert2";
import { editInstantRequest, isDisabledTicket } from "../../../utils/function";
import _ from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import withReactContent from "sweetalert2-react-content";
import FileUploader from "../../utils/FileUploader";
import { _ROUTES } from "../../../utils/_routes_constants";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import BasicModal from "../../helpers/BasicModal";
import { AddLoanForm } from "./Modals/AddLoanForm";
import Editor from "ckeditor5-custom-build";
import AddActionForm from "./Modals/AddActionForm";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

export const TicketEditDescription = ({
  ticket,
  setTicket,
  actionsExpanded,
  setActionsExpanded,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [user, setUser] = useState(null);
  const [files, setFiles] = useState([]);
  const [loans, setLoans] = useState([]);
  const [openLoanModal, setOpenLoanModal] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [actions, setActions] = useState([]);
  const baseUrl = process.env.REACT_APP_SYMFONY_URL + "/_files/_tickets/";
  const rq = new RequestHelper();
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (ticket && ticket?.user?.id) {
      // Fetch user data
      rq.doRequest(_ACTIONS.GET, "users/" + ticket?.user?.id).then((res) => {
        setUser(res.data);
      });
    }
    if (ticket && ticket?.files) {
      setFiles(ticket?.files);
    }
    if (ticket && ticket?.actions) {
      setActions(ticket?.actions);

      ticket?.actions.map((action) => {
        action.purchases.map((act) => {
          setPurchases((prev) => [...prev, act]);
        });
      });
    }
    if (ticket && ticket?.reservations) {
      setLoans(ticket?.reservations);
    }
    if (ticket) {
      setDisabled(isDisabledTicket(ticket));
    }
  }, [ticket]);

  const editDescription = () => {
    MySwal.fire({
      title: "Modifier la description du ticket",
      html: (
        <CKEditor
          editor={Editor}
          data={ticket?.description}
          onChange={(event, editor) => {
            const data = editor.getData();
            setTicket({
              ...ticket,
              description: data,
            });
          }}
        />
      ),
      showCancelButton: true,
      confirmButtonText: "Modifier",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        editInstantRequest(ticket?.description, ticket["@id"], "description");
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          marginTop: "10px",
        }}
      >
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon onClick={() => setExpanded((prev) => !prev)} />
            }
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              backgroundColor: _COLORS.lightBlue,
              fontSize: "0.8em",
              height: "35px !important",
              minHeight: "35px !important",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Chip
                sx={{ backgroundColor: _COLORS.midGrey }}
                variant="outlined"
                label={
                  ticket
                    ? moment(ticket.createdAt).format(_DATE_FORMAT.next_display)
                    : ""
                }
              ></Chip>
              {!disabled && <DeleteIcon sx={{ color: _COLORS.error }} />}
              <IconButton
                onClick={() => {
                  setActionsExpanded((prev) => !prev);
                }}
              >
                {actionsExpanded ? (
                  <KeyboardDoubleArrowUpIcon></KeyboardDoubleArrowUpIcon>
                ) : (
                  <KeyboardDoubleArrowDownIcon></KeyboardDoubleArrowDownIcon>
                )}
              </IconButton>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                {/* @TODO get Avatar from user */}
                <Avatar alt="Remy Sharp" src="" />
              </Box>
              <Box
                sx={{
                  marginLeft: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography
                  fontWeight={"bold"}
                  variant="subtitle1"
                  sx={{ cursor: disabled ? "not-allowed" : "pointer" }}
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    Swal.fire({
                      input: "textarea",
                      inputLabel: "Sujet du ticket",
                      inputValue: ticket?.subject,
                      showCancelButton: true,
                      confirmButtonText: "Modifier",
                      cancelButtonText: "Annuler",
                    }).then((result) => {
                      if (
                        result.isConfirmed &&
                        !_.isEqual(result.value, ticket?.subject)
                      ) {
                        setTicket({
                          ...ticket,
                          subject: result.value,
                        });
                        editInstantRequest(
                          result.value,
                          ticket["@id"],
                          "subject"
                        );
                      }
                    });
                  }}
                >
                  {ticket?.subject}
                </Typography>
                <Typography sx={{ color: _COLORS.grey }} variant="subtitle2">
                  {user?.firstName + " " + user?.lastName}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
                width: "100%",
                cursor: disabled ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (disabled) {
                  return;
                }
                editDescription();
              }}
            >
              <Box
                dangerouslySetInnerHTML={{ __html: ticket?.description }}
              ></Box>
            </Box>
            {/* Display Files */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "5px",
                width: "100%",
              }}
            >
              {files.map((file, index) => (
                <Box key={index}>
                  <Button
                    size="small"
                    variant="text"
                    onClick={(e) => {
                      window.open(
                        baseUrl + ticket?.refTicket + "/" + file.fileName,
                        "_blank",
                        "noopener"
                      );
                    }}
                  >
                    <DownloadIcon sx={{ marginRight: "5px" }}></DownloadIcon>
                    {file.fileName}
                  </Button>
                  {/* TODO delete file */}
                  <IconButton sx={{ color: _COLORS.error }}>
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                </Box>
              ))}
            </Box>
            {/* File uploader */}
            {ticket && !disabled && (
              <FileUploader
                hiddenFields={{
                  ticket: ticket?.id,
                }}
                url={_ROUTES.ADD_TICKET_FILE}
              ></FileUploader>
            )}
          </AccordionDetails>
          {/* Modals */}
          {/* ADD LOAN */}
          <BasicModal
            showModal={openLoanModal}
            setShowModal={setOpenLoanModal}
            title="Ajouter un prêt"
            icon={<AddIcon></AddIcon>}
            content={
              <AddLoanForm
                ticket={ticket}
                setTicket={setTicket}
                setOpenLoanModal={setOpenLoanModal}
              ></AddLoanForm>
            }
            width={700}
          ></BasicModal>

          {/* ADD ACTION */}
          <BasicModal
            showModal={openActionModal}
            setShowModal={setOpenActionModal}
            title="Ajouter une action"
            icon={<AddIcon></AddIcon>}
            content={
              <AddActionForm
                ticket={ticket}
                setTicket={setTicket}
                setOpenActionModal={setOpenActionModal}
              ></AddActionForm>
            }
            width={800}
          ></BasicModal>

          {/* Footer Bar */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: "1px solid #F4F4F4",
            }}
          >
            {/* start */}
            <Box
              sx={{
                fontSize: "0.7em",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <ShoppingCartIcon /> Ventes ({purchases.length}) |{" "}
              <VolunteerActivismIcon /> Prêt ({loans.length})
            </Box>
            {/* end */}
            <Box sx={{}}>
              <Button
                size="small"
                onClick={() => {
                  setOpenLoanModal(true);
                }}
                disabled={disabled}
              >
                <VolunteerActivismIcon /> Ajouter un prêt
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setOpenActionModal(true);
                }}
                disabled={disabled}
              >
                <AddIcon /> Actions ({actions.length})
              </Button>
            </Box>
          </Box>
        </Accordion>
      </Box>
    </>
  );
};

export default TicketEditDescription;
