import React, { useContext, useEffect } from "react";
import List from "@mui/material/List";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";
import { _COLORS } from "../../utils/constants";
import { _MENU } from "./_menu_contants";

export default function MenuGroup(props) {
  const { openSubMenu, setOpenSubMenu, open } = useContext(GlobalContext);
  const navigate = useNavigate();
  const arrayMenu = _MENU;

  useEffect(() => {
    if (!open) {
      setOpenSubMenu({});
    }
  }, [open]);

  const handleClick = (index) => {
    setOpenSubMenu((prevState) => {
      /*Ici on récupère la valeur avant que le state soit maj si il y'en un on le set a false*/
      if (prevState[index]) {
        return { ...prevState, [index]: false };
      }
      /*Sinon, fermez tous les sous-menus et ouvrez le sous-menu actuel.*/
      const newState = Object.keys(prevState).reduce((state, key) => {
        state[key] = false; // Initialise tous les sous-menus à false

        return state;
      }, {});

      newState[index] = true; // Ouvre le sous-menu actuel

      return newState;
    });
  };

  return (
    <>
      {arrayMenu.map((e, indexParent) => {
        const isOpen = openSubMenu[e.indexParent];

        return (
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: _COLORS.drawerNav,
              ".MuiListItemButton-root": {
                paddingTop: "4px",
                paddingBottom: "4px",
                backgroundColor: _COLORS.drawerNav,
              },
            }}
            component="div"
            aria-labelledby="nested-list-subheader"
            key={e.indexParent}
          >
            <ListItemButton
              onClick={() => handleClick(e.indexParent)}
              sx={{
                display: "flex",
                transition: "all 300ms ease-in-out",
                color: "white",
                textDecoration: isOpen ? "underline" : "none",
              }}
            >
              <ListItemIcon
                sx={{ color: openSubMenu[indexParent] ? "white" : "inherit" }}
              >
                {e.icon}
              </ListItemIcon>
              <ListItemText
                primary={e.title}
                primaryTypographyProps={{
                  fontSize: "15px",
                }}
              />
            </ListItemButton>
            {e.submenu.map((submenu, index) => {
              const key = `${e.indexParent}-${index}`;

              return (
                <Collapse
                  in={openSubMenu[e.indexParent]}
                  timeout="auto"
                  unmountOnExit
                  key={key}
                >
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: _COLORS.drawerNavCollapse,
                      ".MuiListItemButton-root": {
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        backgroundColor: _COLORS.drawerNavCollapse,
                        "&:hover": {
                          backgroundColor: _COLORS.drawerNavCollapseHover,
                        },
                      },
                      ".MuiCollapse-container .MuiListItemButton-root": {
                        backgroundColor: _COLORS.subMenuBackground,
                        "&:hover": {
                          backgroundColor: _COLORS.subMenuHover,
                        },
                      },
                    }}
                    aria-labelledby="nested-list-subheader"
                    key={indexParent}
                  >
                    {" "}
                    <ListItemButton
                      sx={{
                        pl: 4,
                        color: "white",
                        backgroundColor: _COLORS.drawerNavCollapse,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        minHeight: "32px",
                        transition: "all 300ms ease-in-out",
                        "&:not(:last-child)": {
                          mb: "2px",
                          bgcolor: _COLORS.drawerNavCollapse,
                        },
                      }}
                      onClick={() => navigate(submenu.path)}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        {submenu.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={submenu.title}
                        id={submenu.key}
                        primaryTypographyProps={{ fontSize: "15px" }}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              );
            })}
          </List>
        );
      })}
    </>
  );
}
