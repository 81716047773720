import { Box } from "@mui/system";
import { roundValue } from "../../utils/function";

/**
* Calculates the credit based on the provided row.
*
* @param {Object} row - The row object containing the data.
* @returns {number} The calculated credit.
*/
export const calculCredit = (row) => {
    let credit = null;
    if(row.original.contratSocieteMaintenanceTrimestres.length > 0){
        const contrats = row.original.contratSocieteMaintenanceTrimestres[0];
        credit = contrats.t1 + contrats.t2 + contrats.t3 + contrats.t4;
    } else {
        credit = row.original.creditContrat;
    }

    return credit;
};

/**
* Calculates the credit for a given row.
* @param {Object} row - The row object.
* @returns {JSX.Element} - The JSX element representing the credit.
*/
export const creditContrat = (row) =>{
    const  credit = calculCredit(row);
   
    return  <>   
                <Box sx={{textAlign:"right"}}>
                    <Box>{credit} min</Box>
                    <Box>({roundValue(credit/60, 2)} hrs)</Box>
                </Box>
            </>;
};

/**
* Calculates the consumed credit and returns it as JSX.
*
* @param {Object} row - The row object containing the creditConsomme property.
* @returns {JSX.Element} - The JSX element representing the consumed credit.
*/
export const creditConsomme = (row) => {
    const credit = row.original.creditConsomme;

    return  <>   
                <Box sx={{textAlign:"right"}}>
                    <Box>{credit} min</Box>
                    <Box>({roundValue(credit/60, 2)} hrs)</Box>
                </Box>
            </>;
};

/**
* Calculates the prorata value for a given row.
*
* @param {Object} row - The row object containing the prorata value.
* @returns {JSX.Element} - The JSX element representing the prorata value.
*/
export const prorata = (row) => {
    const prorata = row.original.prorata;

    return  <>   
                <Box sx={{textAlign:"right"}}>
                    <Box>{prorata} min</Box>
                    <Box>({roundValue(prorata/60, 2)} hrs)</Box>
                </Box>
            </>;
};

/**
* Calculates the decompte temps for a given row.
*
* @param {Object} row - The row object containing the decompte temps.
* @returns {JSX.Element} - The JSX element representing the decompte temps.
*/
export const decompteTemps = (row) => {
    const decompte = row.original.decompteTemps;

    return  <>   
                <Box sx={{textAlign:"right"}}>
                    <Box>{decompte} min</Box>
                    <Box>({roundValue(decompte/60, 2)} hrs)</Box>
                </Box>
            </>;
};

/**
 * Calculates the chiffre d'affaire for a given row.
 * 
 * @param {Object} row - The row object containing the necessary data.
 * @returns {JSX.Element} - The JSX element representing the chiffre d'affaire.
 */
export const chiffreAffaire = (row) => {
    const credit = calculCredit(row);

    return roundValue(((credit + row.original.prorata) / 60) * row.original.price, 2);
};

/**
 * Calculates the solde (balance) based on the given row and initial credit.
 * @param {Object} row - The row object containing the necessary data.
 * @param {number} creditInitial - The initial credit value.
 * @returns {number|JSX.Element} - The solde value or a JSX element representing the solde with appropriate styling.
 */
export const solde = (row, creditInitial) => {
    const prorata = row.original.prorata;
    const creditConsomme = row.original.creditConsomme;
    const decompteTemps = row.original.decompteTemps;
    const credit =  calculCredit(row);
    const solde = (((credit + prorata) - creditConsomme) + creditInitial) - decompteTemps;

    if(solde > 0){
        return <span style={{color:"green", fontWeight:"450"}}>{"+" + solde}</span>;
    } 
    if(solde < 0){
        return <span style={{color:"red", fontWeight:"450"}}>{solde}</span>;
    }

    return solde;
};