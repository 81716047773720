import { Box, Button } from "@mui/material";
import { TicketLoan } from "./TicketLoan";
import _ from "lodash";
import { createConfirm } from "../../../utils/confirm";
import { useEffect, useState } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import { _ROUTES } from "../../../utils/_routes_constants";
import { _ACTIONS } from "../../../utils/constants";

export const TicketCreateLoans = ({ ticket, setTicket }) => {
  const _DEFAULT_LOAN = {
    resource: null,
    quantity: 1,
    startDate: null,
    endDate: null,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  const rq = new RequestHelper();
  const [ressources, setRessources] = useState([]);

  useEffect(() => {
    // Fetch ressources
    if (_.isEmpty(ressources)) {
      rq.doRequest(_ACTIONS.GET, _ROUTES.RESSOURCES, {
        pagination: 0,
        "order[type]": "ASC",
        "groups[]": "resourceSelect:get",
      }).then((response) => {
        setRessources(response.data["hydra:member"]);
      });
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #00A65A",
          borderRadius: "5px",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            padding: "10px",
          }}>
          <Button
            size="small"
            variant="contained"
            sx={{
              backgroundColor: "#00C0EF",
              color: "white",
              margin: "5px",
            }}
            onClick={() => {
              createConfirm("Voulez vous ajouter un pret ?")
                .fire({})
                .then((result) => {
                  if (result.isConfirmed) {
                    // Traitement
                    setTicket({
                      ...ticket,
                      reservations: [...ticket.reservations, _DEFAULT_LOAN],
                    });
                  }
                });
            }}>
            Ajouter un pret
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}>
          {ticket?.reservations?.map((loan, index) => (
            <>
              <TicketLoan
                ticket={ticket}
                setTicket={setTicket}
                loan={loan}
                index={index}
                ressources={ressources}></TicketLoan>
            </>
          ))}
        </Box>
      </Box>
    </>
  );
};
