import "./App.css";
import CustomRouter from "./Router";
import { ThemeProvider } from "@emotion/react";
import UserContextProvider from "./contexts/UserContext";
import GlobalContextProvider from "./contexts/GlobalContext";
import { MUI_THEME } from "./style/MuiTheme";
import { SecurityHandler } from "./components/utils/SecurityHandler";
import moment from "moment";
import "moment/locale/fr";

function App() {
  const theme = MUI_THEME;
  moment.locale("fr");

  return (
    <UserContextProvider>
      <SecurityHandler></SecurityHandler>
      <GlobalContextProvider>
        <ThemeProvider theme={theme}>
          <CustomRouter />
        </ThemeProvider>
      </GlobalContextProvider>
    </UserContextProvider>
  );
}

export default App;
