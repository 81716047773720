import React, { useEffect, useState } from "react";
// MUI //
import { Box } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
// OTHERS //
import { ColonnesBsConfiguration } from "./ColonnesBsConfiguration";
import { _ROUTES } from "../../../utils/_routes_constants";
import { BsExpandedRows } from "./BsExpandedRows";
import { RowActionsBsConfiguration } from "./RowActionsBsConfiguration";
import BasicModal from "../../helpers/BasicModal";
import { _LANGUAGES_MUI_TABLE } from "../../../utils/constants";
import { stylesMuiTable } from "../../../utils/muiTableStyle";
import { _BS_CONFIG_MESSAGES } from "../../../utils/base-connaissance/_constants_base_connaissance";
import { FormModalSection } from "./modals/FormModalSection";
import { FormModalCategorie } from "./modals/FormModalCategorie";
import Loader from "../../utils/Loader";
import { getDataBaseConnaissance } from "../../../repository/BaseConnaissance";

/**
 * Représente le tableau de configuration de la B-C (gestion de conception)
 *
 * @component
 * @param {boolean} showModal - Indicates whether the modal is shown or hidden.
 * @param {function} setShowModal - Function to set the showModal state.
 * @param {Object} categoryRow - The category row object.
 * @param {function} setCategoryRow - Function to set the categoryRow state.
 * @param {boolean} isSectionForm - Indicates whether the form is for a section or category.
 * @param {function} setIsSectionForm - Function to set the isSectionForm state.
 */
export function BaseConnaissanceConfigurationTable({
  showModal,
  setShowModal,
  categoryRow,
  setCategoryRow,
  isSectionForm,
  setIsSectionForm,
}) {
  // STATES
  // ARRAY OF ALL CATEGORIES
  const [categories, setCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  // USED FOR STORE INFORMATIONS OF THE SECTION ROW CLICKED IN THE EXPANDED ROW
  const [sectionRow, setSectionRow] = useState(null);
  // OTHERS
  const columns = ColonnesBsConfiguration();

  const table = useMaterialReactTable({
    columns,
    data: categories ?? [],
    muiTableContainerProps: stylesMuiTable.muiTableContainerProps,
    muiTopToolbarProps: stylesMuiTable.muiTopToolbarProps,
    muiTableHeadCellProps: stylesMuiTable.muiTableHeadCellProps,
    muiBottomToolbarProps: stylesMuiTable.muiBottomToolbarProps,
    enableColumnFilters: false,
    // STATE
    state: {
      isLoading: isLoading,
    },
    initialState: {
      columnVisibility: {
        sectionCategorie: true,
        title: true,
        description: true,
      },
    },
    // RENDER EXPANDED ROWS //
    renderDetailPanel: ({ row }) => {
      const categorySections = row.original.categorieBaseConnaissancesSection;

      return (
        <BsExpandedRows
          sections={categorySections}
          setShowModal={setShowModal}
          sectionRow={sectionRow}
          setSectionRow={setSectionRow}
          setIsSectionForm={setIsSectionForm}
        />
      );
    },
    // ROW ACTIONS //
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => {
      return (
        <RowActionsBsConfiguration
          row={row}
          setShowModal={setShowModal}
          setIsSectionForm={setIsSectionForm}
          setCategoryRow={setCategoryRow}
          setCategories={setCategories}
          setSectionRow={setSectionRow}
        />
      );
    },
    // LANGUAGE //
    localization: _LANGUAGES_MUI_TABLE,
  });

  useEffect(() => {
    setIsLoading(true);
    getDataBaseConnaissance(
      {"orderBy[title]": "ASC"}, 
      setCategories, "Erreur lors de la récupération des catégories", 
      _ROUTES.BASE_CONNAISSANCE_CATEGORIES
    );
    setIsLoading(false);
  }, [refresh]);

  if (!categories) {
    return <Loader />;
  }

  return (
    <Box width={"100%"}>
      {/* Modal */}
      <BasicModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={
          isSectionForm
            ? _BS_CONFIG_MESSAGES._MODAL_ADD_SECTION
            : _BS_CONFIG_MESSAGES._MODAL_ADD_CATEGORY
        }
        content={
          isSectionForm ? (
            <FormModalSection
              setShowModal={setShowModal}
              setRefresh={setRefresh}
              sectionRow={sectionRow}
            />
          ) : (
            <FormModalCategorie
              setShowModal={setShowModal}
              setRefresh={setRefresh}
              categoryRow={categoryRow}
            />
          )
        }
      />
      <MaterialReactTable table={table} />
    </Box>
  );
}
