import FomTitle from '../components/helpers/FormTitle';
import Param from '../components/parametrages/Param';
import { TYPE_PARAM } from '../utils/constants';

export const ParametrageOffre = () => {
    return (
        <>
            <FomTitle title="Paramétrage offre" />
            <Param 
                route='contrat-type-offres'
                type={TYPE_PARAM.OFFRE}
            />
        </>
    );
};

export default ParametrageOffre;