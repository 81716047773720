import { _CONTRATS_TYPE_TEXT } from "../../utils/constants";
import { useEffect, useState } from "react";
import { Box, Chip } from "@mui/material";
import { getDataTicket } from "../../repository/tickets/Ticket";
import { _ROUTES } from "../../utils/_routes_constants";

const ContratNbTickets = ({societe, contratId}) => {
    const [nbTickets, setNbTickets] = useState([]);
    useEffect(() => {
        if(nbTickets.length !== 0) return;
        getDataTicket(
            {contrat: contratId, societe: societe, contratType:_CONTRATS_TYPE_TEXT.CMT_TXT}, 
            setNbTickets, "Une erreur est survenue lors de la récupération du nombre de tickets",
            _ROUTES.TICKETS
        );
    }, []);

    return (
        <>
            <Box sx={{textAlign:"center"}}>
                <Chip size="small" variant="outlined" label={nbTickets.length} />
            </Box>
        </>
    );
};

export default ContratNbTickets;