import React from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { RxTokens } from "react-icons/rx";
import { Box, IconButton, Typography } from "@mui/material";
import { _ROUTER_DOM_URL } from "../../utils/_routes_constants";
import { useNavigate } from "react-router-dom";
import { _TEXTS_ACTIFS_CONFIGS } from "../../utils/actif-config/actif_configuration_constants";
import { _COLORS } from "../../utils/constants";

export default function BoxInfoTableActif({ selectedProduitService }) {
  const navigate = useNavigate();
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{
          borderBottom: `1px solid ${_COLORS.lightGrey}`,
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <RxTokens />
          <Typography
            variant="body1"
            fontWeight={"bold"}
            color={_COLORS.darkGrey}
          >
            {_TEXTS_ACTIFS_CONFIGS.TITLE_BOX_INFO}
          </Typography>
        </Box>
        <Box>
          <IconButton
            label="Edit"
            title="Ajouter"
            color="primary"
            onClick={() => navigate(_ROUTER_DOM_URL.ACTIF_CONFIGURATION_CREATE)}
          >
            <AddBoxIcon />
          </IconButton>
        </Box>
      </Box>
      {!selectedProduitService.id && (
        <Box
          sx={{
            display: "flex",
            backgroundColor: _COLORS.lightGrey,
            border: `1px solid ${_COLORS.midGrey}`,
            m: 1,
          }}
        >
          <Typography variant="body1" color={"#534e48"} sx={{ p: 1 }}>
            {_TEXTS_ACTIFS_CONFIGS.LOADER_BOX_INFO}
          </Typography>
        </Box>
      )}
    </>
  );
}
