import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { _ACTIONS, _COLORS } from "../../utils/constants";
import { CustomDataGrid } from "../utils/CustomDataGrid";
import Swal from "sweetalert2";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { _ROUTES } from "../../utils/_routes_constants";

const DecompteTempsListe = ({
  decompteTempsList,
  backShowList,
  setDecompteTempsList,
  isPersonnalise = null,
}) => {
  const [gridKey, setGridKey] = useState(1);
  const rQ = new RequestHelper();

  useEffect(() => {
    setGridKey((prevKey) => prevKey + 1);
  }, [decompteTempsList]);

  const handleOpenModal = (params) => {
    Swal.fire({
      title: "Sélectionnez une conditions",
      input: "select",
      inputOptions: {
        "PAR PERIPHERIQUE": "Par peripherique",
        "PAR CLIENT": "Par client",
      },
      inputPlaceholder: "Sélectionnez une conditions",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            resolve();
          } else {
            resolve("Vous devez sélectionner une option");
          }
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        rQ.doRequest(_ACTIONS.PUT, `${_ROUTES.DECOMPTE_TEMPS}/${params.id}`, {
          conditions: result.value,
        }).then((res) => {
          if (res.status === 200) {
            setDecompteTempsList((prevData) =>
              prevData.map((item) =>
                item.id === params.id
                  ? { ...item, conditions: result.value }
                  : item
              )
            );
            createToast("success", "Conditions mises à jour avec succès");
          } else {
            createToast(
              "error",
              "Erreur lors de la mise à jour des conditions"
            );
          }
        });
      }
    });
  };

  const handleProcessRowUpdate = (params) => {
    const data = {
      produitService: params.produitService["@id"],
      times: parseInt(params.times),
      conditions: params.conditions,
    };

    rQ.doRequest(
      _ACTIONS.PUT,
      `${_ROUTES.DECOMPTE_TEMPS}/${params.id}`,
      data
    ).then((res) => {
      if (res.status === 200) {
        setDecompteTempsList((prevData) =>
          prevData.map((item) =>
            item.id === params.id
              ? {
                  ...item,
                  produitService: params.produitService,
                  times: parseInt(params.times),
                  conditions: params.conditions,
                }
              : item
          )
        );
        createToast("success", "Décompte temps mis à jour avec succès");
      } else {
        createToast("error", "Erreur lors de la mise à jour du décompte temps");
      }
    });
  };

  const columns = [
    {
      field: "produitService.id",
      headerName: "Produit | Service",
      width: 150,
      editable: isPersonnalise ? true : false,
      flex: 1,
      renderCell: (params) => {
        return (
          <strong>
            <span>
              {params.row.produitService
                ? params.row.produitService.name
                : "N/A"}
            </span>
          </strong>
        );
      },
    },
    {
      field: "times",
      headerName: "Temps (en minutes)",
      width: 150,
      flex: 1,
      editable: isPersonnalise ? true : false,
      renderCell: (params) => {
        return (
          <span>
            {params.row.times} <strong>min</strong>
          </span>
        );
      },
    },
    {
      field: "conditions",
      headerName: "Conditions (par mois)",
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleOpenModal(params)}>
              {params.row.conditions}
            </Button>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          icon={<DeleteForeverRoundedIcon />}
          label="Delete"
          onClick={() =>
            rQ
              .doRequest(
                _ACTIONS.DELETE,
                `${_ROUTES.DECOMPTE_TEMPS}/${params.id}`
              )
              .then((res) => {
                if (res.status === 204) {
                  setDecompteTempsList((prevData) =>
                    prevData.filter((item) => item.id !== params.id)
                  );
                  createToast("success", "Décompte temps supprimé avec succès");
                } else {
                  createToast("error", "Erreur lors de la suppression");
                }
              })
          }
          color="error"
        >
          <DeleteForeverRoundedIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%", height: "400px", marginTop: "1rem" }}>
        {isPersonnalise && (
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              marginBottom={1.5}
            >
              <Typography variant="h6">
                <strong>Liste des décompte temps</strong>
              </Typography>
              <Button
                variant="outlined"
                onClick={backShowList}
                sx={{ color: _COLORS.buttonLightBlue }}
              >
                Retour
              </Button>
            </Box>
          </>
        )}
        <CustomDataGrid
          key={gridKey + 1}
          rows={decompteTempsList ? decompteTempsList : []}
          columns={columns}
          processRowUpdate={handleProcessRowUpdate}
          getRowId={(row) => {
            return row.id;
          }}
        />
      </Box>
    </>
  );
};

export default DecompteTempsListe;
