import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Height } from "@mui/icons-material";

/**
 * Template de modale basique
 *
 *
 * @returns
 */
export default function BasicModal({
  showModal,
  setShowModal,
  title,
  content,
  width,
  height,
  icon = null,
}) {
  function handleClose() {
    setShowModal(false);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 6,
    overflow: "auto",
    height: "98%",
  };

  if (width) {
    style["width"] = width + "px !important";
  }
  if(height){
    style["height"] = height + "px !important";
  }

  return (
    <Box>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow: "auto"}}
      >
        <Box sx={style}>
          <CloseIcon
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "0",
              right: "0",
              mt: "10px",
              mr: "10px",
            }}
            onClick={handleClose}
          ></CloseIcon>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {icon ?? icon}
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: "8px !important" }}
          >
            {content}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
