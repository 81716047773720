import { DataGrid, frFR } from "@mui/x-data-grid";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { CustomToolbar } from "./CustomToolbarDataGrid";

export const dataGridTheme = createTheme(
  {},
  frFR /* French language for the whole grid */
);

/**
 * A custom data grid builder component for backend requests.
 *
 * @component
 * @param {Array} props.rows - The data rows to be displayed in the data grid.
 * @param {Function} props.getRowId - The function to get the unique ID for each row.
 * @param {Array} props.columns - The columns configuration for the data grid.
 * @param {number} props.page - The current page number (Based index API Platform).
 * @param {number} props.pageSize - The number of rows to be displayed per page.
 * @param {number} props.totalRows - The total number of rows in the data grid.
 * @param {Object} props.onSearchChange - The search change event handler.
 * @param {Object} props.onPaginationChange - The pagination change event handler.
 * @param {Object} props.sortColumn - The sort column event handler.
 * @param {Object} props.others - Additional props to be passed to the DataGrid component.
 * @returns {JSX.Element} The rendered DataGrid component.
 * 
 * @example Pagination =>
 * 
  const [pageState, setPageState] = useState({
    totalRows: 0,
    page: 1,
    pageSize: 20,
  });

 * 
 */
const DataGridBuilder = ({
  rows,
  columns,
  pageInfo,
  onSearchChange,
  onPaginationChange,
  sortColumn,
  ...others
}) => {
  const { page, pageSize, totalRows } = pageInfo;

  return (
    <ThemeProvider theme={dataGridTheme}>
      <DataGrid
        {...others}
        /* rows */
        rows={rows}
        getRowId={(row) => row.id}
        /* columns */
        columns={columns}
        /* pagination */
        paginationMode="server"
        onPaginationModelChange={(params) => {
          const { page, pageSize } = params;
          onPaginationChange({ page: page + 1, pageSize });
        }}
        paginationModel={{
          page: page - 1, // DataGrid must start at 0-based index
          pageSize,
        }}
        rowCount={totalRows}
        pageSizeOptions={[20, 50, 100]}
        /* toolbar / filter (& quick filter) */
        slots={{
          toolbar: CustomToolbar,
        }}
        filterMode="server"
        slotProps={{
          toolbar: {
            value: onSearchChange.value || "",
            onChange: onSearchChange.onChange,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize,
            },
          },
        }}
        /* sorting */
        sortingMode="server"
        onSortModelChange={(params) => {
          sortColumn(params);
        }}
      />
    </ThemeProvider>
  );
};

export default DataGridBuilder;
