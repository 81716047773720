import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { RiLockPasswordLine } from "react-icons/ri";
import { _RESET_PASSWORD_FIELDS } from "../../utils/_users_constants";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS } from "../../utils/constants";
import { createToast } from "../../utils/toast";

/**
 * Form component for resetting the password of a user.
 *
 * @component
 * @param {Object} currentUser - The current user object.
 */
export const FormResetPassword = ({ currentUser }) => {
  const { user } = useContext(UserContext);
  const requestHelper = new RequestHelper();
  // CHECK IF SAME USER BETWEEN CONTEXT AND CURRENT USER
  const isRightUser = user?.id === currentUser?.id;

  const formik = useFormik({
    initialValues: {
      ...(isRightUser && {
        previousPassword: "",
      }),
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object({
      ...(isRightUser && {
        previousPassword: Yup.string().required(
          "Le mot de passe actuel est requis"
        ),
      }),
      password: Yup.string()
        .min(8, "Le mot de passe doit contenir au moins 8 caractères")
        .matches(
          /(?=.*\d)/,
          "Le mot de passe doit contenir au moins un chiffre"
        )
        .matches(
          /(?=.*[!@#$%^&*])/,
          "Le mot de passe doit contenir au moins un caractère spécial"
        )
        .required("Le mot de passe est requis"),
      passwordConfirm: Yup.string()
        .oneOf(
          [Yup.ref("password"), null],
          "Les mots de passe ne correspondent pas"
        )
        .required("La confirmation du mot de passe est requise"),
    }),
    onSubmit: async (values) => {
      const data = {
        previousPassword: values.previousPassword,
        password: values.password,
      };
      const res = await requestHelper.doRequest(
        _ACTIONS.PUT,
        `/reset-password/${currentUser.id}`,
        data
      );
      if (res.status === 405 || res.status !== 200) {
        const status = res.status;
        let displayedMessage =
          status === 405
            ? "Vous n'êtes pas autorisé à effectuer cette action "
            : "Une erreur est survenue lors de la modification du mot de passe";
        createToast("error", displayedMessage);
      } else {
        createToast("success", "Mot de passe modifié avec succès");
      }
    },
  });

  return (
    <Grid container spacing={1} justifyContent="center" marginTop={5}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          item
          xs={12}
          md={12}
          display={"flex"}
          gap={1}
          flexDirection={"column"}
        >
          {/* IF IS RIGHT USER WE ASK TO WRITE HIS PREVIOUS PASSWORD */}
          {isRightUser && (
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>Mot de passe actuel *</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  size="small"
                  label="Mot de passe actuel"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiLockPasswordLine />
                      </InputAdornment>
                    ),
                  }}
                  type="password"
                  id="previousPassword"
                  name="previousPassword"
                  value={formik.values.previousPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.previousPassword &&
                    !formik.values.previousPassword &&
                    Boolean(formik.errors.previousPassword)
                  }
                  helperText={
                    formik.touched.previousPassword &&
                    !formik.values.previousPassword
                      ? formik.errors.previousPassword
                      : ""
                  }
                />
              </Grid>
            </Grid>
          )}
          {_RESET_PASSWORD_FIELDS.map((field, index) => (
            <Grid container alignItems="center" spacing={2} key={index}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="body1" component="div">
                  <strong>{field.label} *</strong>
                </Typography>
              </Grid>

              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  size="small"
                  label={field.label}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiLockPasswordLine />
                      </InputAdornment>
                    ),
                  }}
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[field.name] &&
                    Boolean(formik.errors[field.name])
                  }
                  helperText={
                    formik.touched[field.name] && formik.errors[field.name]
                  }
                />
              </Grid>
            </Grid>
          ))}
          <Box display={"flex"} justifyContent="flex-end" marginTop={2}>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="error"
            >
              Valider
            </Button>
          </Box>
        </Grid>
      </form>
    </Grid>
  );
};
