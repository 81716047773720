import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import { getDataStat } from "../../../repository/Stats";
import { _ROUTES } from "../../../utils/_routes_constants";
import { _JOURS_SEMAINE, _STAT_EQUIPE_NB_HEURES } from "../../../utils/constants";
import Chart from "./Chart";
import { UserContext } from "../../../contexts/UserContext";
import useHasRoles from "../../../hooks/useHasRole";
import { _ROLES } from "../../../utils/constants";
import { mkConfig, generateCsv, download } from 'export-to-csv'; 
import { exportDataWeekEquipe } from './makeData';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoTicketWeek from "./InfoTicketWeek";

const TableEquipeWeek = ({invoiceTypes, startWeek, endWeek}) => {
    const [datas, setDatas] = useState([]);
    const {getUser} = useContext(UserContext);
    const user = getUser();
    const canAccess = useHasRoles(user, [_ROLES.ADMIN]);
    const userId = canAccess ? null : user.id;

    useEffect(() => {
        if(!startWeek) return;
        getDataStat(
            {invoiceTypes: invoiceTypes, startWeek: startWeek, endWeek: endWeek, userId},
            setDatas,
            "Une erreur est survenue lors de la récupération des données",
            _ROUTES.STAT_EQUIPE_WEEK
        );
    }, [invoiceTypes, startWeek]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'user',
                header: 'Équipe',
            },
        ],
    );

    _JOURS_SEMAINE.map(element => {
        columns.push(
            {
                accessorKey: element,
                header: element,
                Cell: ({row}) => (
                    (row.original[element] === undefined) ? <></> :
                    <>
                        <Chart type={'DF'} data={row.original[element].facturable} max={_STAT_EQUIPE_NB_HEURES.day*60} />
                        <Chart type={'DR'} data={row.original[element].reel} max={_STAT_EQUIPE_NB_HEURES.day*60} />
                    </>
                ),
            }
        );
    });

    columns.push(
        {
            accessorKey: 'total',
            header: 'Total (35hrs/semaine)',
            Cell: ({row}) => (
                <>
                    <Chart type={'TF'} data={row.original.totalSemaineFacturable} max={_STAT_EQUIPE_NB_HEURES.week*60} />
                    <Chart type={'TR'} data={row.original.totalSemaineReel} max={_STAT_EQUIPE_NB_HEURES.week*60} />
                </>
            ),
        }
    );

    const options = {
        columns: columns,
        data: datas ?? [],
        enableExpanding: true,
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
              >
                Export All Data
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Page Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Selected Rows
              </Button>
            </Box>
          ),
    };

    options.renderDetailPanel  = ({ row }) => {
        return (
            <InfoTicketWeek 
                datas={row.original} 
                invoiceTypes={invoiceTypes} 
                startWeek={startWeek} 
                endWeek={endWeek}  
            />
        );
  }

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
      }
    );

    const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(exportDataWeekEquipe(rowData));
        download(csvConfig)(csv);
    };

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(exportDataWeekEquipe(datas));
        download(csvConfig)(csv);
    };

    const table = useMaterialReactTable(options);

    return (
        <Box sx={{mt: 2}}>
            <MaterialReactTable table={table} />
        </Box>
    );
};

export default TableEquipeWeek;