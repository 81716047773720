import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { __FORM_STYLES } from "../../../../utils/_style_constants";
import {
  _ACTIONS,
  _CIVILITES,
  _DATE_FORMAT,
} from "../../../../utils/constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { RequestHelper } from "../../../../utils/requestHelper";
import { _ROUTES } from "../../../../utils/_routes_constants";
import { createToast } from "../../../../utils/toast";
import moment from "moment";

export const ContactClientOrFournisseurForm = ({
  setListContacts,
  contact,
  fournisseur,
  setListFournisseurContacts,
  client,
  setShowModal,
}) => {
  const rq = new RequestHelper();
  const schema = yup.object({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email(),
    phonePro: yup
      .string()
      .test("empty-check", "Numéro de téléphone invalide", (value) => {
        if (value.length === 0) return true;
        return value.length === 10 && /^[0-9]+$/.test(value);
      }),
    phoneFix: yup
      .string()
      .test("empty-check", "Numéro de téléphone invalide", (value) => {
        if (value.length === 0) return true;
        return value.length === 10 && /^[0-9]+$/.test(value);
      }),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const initialValues = {
    civilite: contact?.civilite || "",
    firstName: contact?.firstName || "",
    lastName: contact?.lastName || "",
    email: contact?.email || "",
    phonePro: contact?.phonePro || "",
    phoneFix: contact?.phoneFix || "",
    poste: contact?.poste || "",
  };

  const onSubmit = (values) => {
    const route = fournisseur ? _ROUTES.CONTACT_FOURNISSEUR : _ROUTES.CONTACT;
    let data = {
      ...values,
      isEnabled: true,
      createdAt: moment().format(_DATE_FORMAT),
    };

    if (fournisseur) {
      data = { ...data, fournisseur: fournisseur.id };
    } else if (client) {
      data = { ...data, societe: client.id };
    }

    rq.doRequest(_ACTIONS.POST, route, data).then((response) => {
      if (response.status === 201) {
        if (fournisseur) {
          setListFournisseurContacts((prev) => [...prev, response.data]);
        } else if (client) {
          setListContacts((prev) => [...prev, response.data]);
        }

        createToast("success", "Contact créé avec succès").then(() => {
          setShowModal(false);
        });
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ ...__FORM_STYLES.boxInputRow, width: "100%" }}>
            <FormControl sx={__FORM_STYLES.basicInput} size="small">
              <InputLabel sx={{ paddingBottom: "15px" }} shrink>
                Civilité
              </InputLabel>
              <Select
                size="small"
                label="Civilité"
                name="civilite"
                {...register("civilite")}
              >
                {Object.keys(_CIVILITES).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {_CIVILITES[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={__FORM_STYLES.boxInputRow}>
            <FormControl sx={__FORM_STYLES.basicInput} size="small">
              <TextField
                size="small"
                label="Nom *"
                name="firstName"
                {...register("firstName", {})}
              ></TextField>
              {errors?.firstName && (
                <Box sx={__FORM_STYLES.errorContainer}>
                  <Typography sx={__FORM_STYLES.errorMessage}>
                    Nom obligatoire
                  </Typography>
                </Box>
              )}
            </FormControl>
          </Box>
          <Box sx={__FORM_STYLES.boxInputRow}>
            <FormControl sx={__FORM_STYLES.basicInput} size="small">
              <TextField
                size="small"
                label="Prénom *"
                name="lastName"
                {...register("lastName", {})}
              ></TextField>
              {errors?.lastName && (
                <Box sx={__FORM_STYLES.errorContainer}>
                  <Typography sx={__FORM_STYLES.errorMessage}>
                    Prénom obligatoire
                  </Typography>
                </Box>
              )}
            </FormControl>
          </Box>
          <Box sx={__FORM_STYLES.boxInputRow}>
            <FormControl sx={__FORM_STYLES.basicInput} size="small">
              <TextField
                variant="standard"
                size="small"
                label="Adresse e-mail"
                name="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailIcon />
                    </InputAdornment>
                  ),
                }}
                {...register("email", {})}
              />
              {errors?.email && (
                <Box sx={__FORM_STYLES.errorContainer}>
                  <Typography sx={__FORM_STYLES.errorMessage}>
                    Adresse e-mail invalide
                  </Typography>
                </Box>
              )}
            </FormControl>
          </Box>
          <Box sx={__FORM_STYLES.boxInputRow}>
            <FormControl sx={__FORM_STYLES.basicInput} size="small">
              <TextField
                variant="standard"
                size="small"
                label="Téléphone pro"
                name="phonePro"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  ),
                }}
                {...register("phonePro", {})}
              />
              {errors?.phonePro && (
                <Box sx={__FORM_STYLES.errorContainer}>
                  <Typography sx={__FORM_STYLES.errorMessage}>
                    Numéro de téléphone invalide
                  </Typography>
                </Box>
              )}
            </FormControl>
          </Box>
          <Box sx={__FORM_STYLES.boxInputRow}>
            <FormControl sx={__FORM_STYLES.basicInput} size="small">
              <TextField
                variant="standard"
                size="small"
                label="Téléphone fixe"
                name="phoneFix"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneIcon />
                    </InputAdornment>
                  ),
                }}
                {...register("phoneFix", {})}
              />
              {errors?.phoneFix && (
                <Box sx={__FORM_STYLES.errorContainer}>
                  <Typography sx={__FORM_STYLES.errorMessage}>
                    Numéro de téléphone invalide
                  </Typography>
                </Box>
              )}
            </FormControl>
          </Box>
          <Box sx={__FORM_STYLES.boxInputRow}>
            <FormControl sx={__FORM_STYLES.basicInput} size="small">
              <TextField
                size="small"
                label="Poste/Fonction"
                name="poste"
                {...register("poste", {})}
              ></TextField>
            </FormControl>
          </Box>
          <Box sx={__FORM_STYLES.submitContainer}>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ContactClientOrFournisseurForm;
