import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import { _ACTIONS, _COLORS, _ROLES } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { UserContext } from "../../contexts/UserContext";
import useHasRoles from "../../hooks/useHasRole";
import { useNavigate } from "react-router-dom";

export const Catalogue = () => {
  const requestHelper = new RequestHelper();
  const [parentPS, setParentPS] = useState();
  const { user } = useContext(UserContext);
  const canAccess = useHasRoles(user, [
    _ROLES.ADMIN,
    _ROLES.TECHNICIEN,
    _ROLES.SUPER_ADMIN,
  ]);
  const navigate = useNavigate();

  const fetchNotificationCount = async (idSociete = null) => {
    try {
      const url = idSociete
        ? `get-societe-produit-service-count/${idSociete}`
        : `get-societe-produit-service-count`;

      const response = await requestHelper.doRequest(_ACTIONS.GET, url);
      if (200 !== response.status) {
        throw new Error(
          "Erreur lors de la récupération du nombre de notifications"
        );
      }
      const tmpData = response.data.produitService;
      setParentPS(tmpData);
    } catch (error) {
      createToast("error", error.message);

      return 0;
    }
  };

  useEffect(() => {
    if (!canAccess) {
      fetchNotificationCount(user.societeId);
    } else {
      fetchNotificationCount();
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          w: "100%",
          border: `2px solid ${_COLORS.turquoiseBox}`,
          backgroundColor: _COLORS.white,
        }}
      >
        <Box
          sx={{
            backgroundColor: _COLORS.turquoiseBox,
            display: "flex",
            gap: 2,
            alignItems: "center",
            p: 1,
          }}
        >
          <Inventory2RoundedIcon sx={{ color: _COLORS.white }} />
          <Typography variant="h6" sx={{ color: _COLORS.white }}>
            Actifs et configurations
          </Typography>
        </Box>
        <Box>
          <List>
            {parentPS &&
              parentPS.map((ps) => (
                <ListItem key={ps.produitServiceId} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(
                        "/actifs_configuration/list/" + ps.produitServiceId
                      );
                    }}
                  >
                    <ListItemText primary={ps.name} />
                    <Chip label={ps.count} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Box>
    </>
  );
};
