import { Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { SAUVEGARDE_FORM_FIELD } from "../../../utils/actif-config/actif_configuration_constants";

function FormFieldSauvegarde({ formik, formStates, setFormStates }) {
  useEffect(() => {
    setFormStates({
      ...formStates,
      isSauvegardeForm: true,
    });
  }, []);

  return (
    <Grid container spacing={1} justifyContent="center" marginTop={2}>
      {SAUVEGARDE_FORM_FIELD.map((field) => (
        <Grid item xs={12} md={8} key={field.name}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>{field.label}</strong>
              </Typography>
            </Grid>
            <Grid item xs={field.size}>
              <TextField
                fullWidth
                size="small"
                label={field.label}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors[field.name])}
                helperText={formik.errors[field.name]}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default FormFieldSauvegarde;
