import React, { useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Input, TextFieldForm } from "../../components/form/Input";
import { CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RequestHelper } from "../../utils/requestHelper";
import { UserContext } from "../../contexts/UserContext";
import { _ACTIONS } from "../../utils/constants";
import { setCookie } from "../../utils/cookieHelper";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";

export const theme = createTheme();

function Login() {
  let navigate = useNavigate();
  const requestHelper = new RequestHelper();
  const { setUser, setToken } = useContext(UserContext);

  const onSubmit = (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const password = e.target.password.value;
    requestHelper
      .doRequest(_ACTIONS.POST, `${_ROUTES.LOGIN}`, {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.data.user) {
          /** Update Context */
          setUser(response.data.user);
          setToken(response.data.token);
          /** Set Cookies */
          setCookie("token", response.data.token, 1);
          window.localStorage.setItem(
            "user",
            JSON.stringify(response.data.user)
          );
          /** Redirect */
          let redirect = _ROUTER_DOM_URL.DASHBOARD_GENERAL;

          if (window.localStorage.getItem("redirect")) {
            redirect = window.localStorage.getItem("redirect");
            window.localStorage.removeItem("redirect");
          }

          navigate(redirect);
        }
      });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid container xs={6} md={8} item>
              <CardMedia
                component="img"
                className="logoToolIn"
                image="https://www.toolin.fr/wp-content/uploads/2022/06/LogoPleinVert.png"
              />
            </Grid>
            <Typography component="h1" variant="h5">
              Se connecter
            </Typography>
            <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
              <TextFieldForm
                margin={"normal"}
                required
                fullWidth
                id={"email"}
                label={"Email | Identifiant"}
                name={"username"}
                autoComplete={"email"}
                autoFocus
              />
              <TextFieldForm
                margin={"normal"}
                required
                fullWidth
                name={"password"}
                label={"Mot de passe"}
                type={"password"}
                id={"password"}
                autoComplete={"current-password"}
              />
              <Input
                value={"Se connecter"}
                variant={"contained"}
                type="submit"
              />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default Login;
