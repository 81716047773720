import { stylesMuiTable } from "./muiTableStyle";

/**
 * Used to get default config for MuiTable in NextV2
 *
 * @param {*} params
 * @param {*} states {
 *  rowCount, isLoaded, isError, pagination, setPagination,
 *  columnFilters ,setColumnFilters, globalFilter, setGlobalFilter,sorting,
 *  setSorting, isLoading
 * }
 * @returns
 */
export const getMuiTableConfig = (params, states) => {
  // Default styles
  let conf = {
    muiTableContainerProps: stylesMuiTable.muiTableContainerProps,
    muiTableBodyCellProps: stylesMuiTable.muiTableBodyCellProps,
    muiTopToolbarProps: stylesMuiTable.muiTopToolbarProps,
    muiTableHeadCellProps: stylesMuiTable.muiTableHeadCellProps,
    muiBottomToolbarProps: stylesMuiTable.muiBottomToolbarProps,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
  };

  // Filters
  conf = {
    ...conf,
    manualFiltering: true,
    manualSorting: true,
    enableGlobalFilter: true,
    enableDensityToggle: true,
    enableFullScreenToggle: false,
    enableColumnOrdering: params.enableColumnOrdering ?? true,
    enableColumnFilters: params.enableColumnFilters || false,
    enableExpanding: params.enableExpanding || false,
    enableFilter: false,
    enableHiding: params.enableHiding ?? true,
    enableBottomToolbar: true,
    enableColumnFilterModes: true,
  };

  // Pagination
  conf = {
    ...conf,
    paginationDisplayMode: "pages",
    enablePagination: true,
    manualPagination: true,
  };

  // States & setStates
  conf = {
    ...conf,
    rowCount: states.rowCount,
    pageCout: -1,
    state: {
      pagination: states.pagination,
      columnFilters: states.columnFilters,
      globalFilter: states.globalFilter,
      sorting: states.sorting,
      isLoading: states.isLoading,
      showAlertBanner: states.isError,
      showProgressBars: states.isLoaded,
    },
    onColumnFiltersChange: states.setColumnFilters,
    onGlobalFilterChange: states.setGlobalFilter,
    onPaginationChange: states.setPagination,
    onSortingChange: states.setSorting,
  };

  // language
  conf = {
    ...conf,
    localization: {
      clearFilters: "Effacer les filtres",
      rowsPerPage: "Lignes par page",
      showHideColumns: "Afficher/Masquer les colonnes",
      hideAll: "Masquer tout",
      showAll: "Afficher tout",
      resetOrder: "Réinitialiser",
      noRecordsToDisplay: "Aucun enregistrement à afficher",
      search: "Rechercher",
      toggleDensity: "Densité",
      sortByColumnAsc: "Trier par {column} ascendant",
      sortByColumnDesc: "Trier par {column} descendant",
      resetColumnSize: "Réinitialiser la taille de la colonne",
      clearSort: "Effacer le tri",
      clearFilter: "Effacer le filtre",
      clearSearch: "Effacer la recherche",
      hideColumn: "Masquer {column} colonne",
      showAllColumns: "Afficher toutes les colonnes",
      sortedByColumnAsc: "Triée par {column} ascendant",
      sortedByColumnDesc: "Triée par {column} descendant",
    },
  };

  // Misc
  conf = {
    ...conf,
    positionActionsColumn: "last",
    // muiTableProps: {
    //   onChangeRowsPerPage: (pageSize) => {
    //     states.setPagination((prev) => ({ ...prev, pageSize }));
    //   },
    // },
  };

  // Example of how to override the default configuration
  if (params.hasOwnProperty("property")) {
    // Do something like this:
    // conf.property = params.property;
  }

  // ************************** //
  // IMPORTANT !!
  // Properties to do on your side :
  // - Columns
  // - Data
  // - Initial state
  // - Renders (optional)
  // ************************** //

  return conf;
};
