  import {
    MaterialReactTable,
    useMaterialReactTable,
  } from "material-react-table";
  import { MRT_Localization_FR } from "material-react-table/locales/fr";
  import { TextFormatter } from "../../../../utils/muiTableHelper";

  export const PurchasesList = (purchases) => {

  const columns = [
    {
        accessorKey: "dolProduct",
        header: "Produit|Service",
        enableEditing: false,
        Cell: ({ row }) => {
          return (
            <TextFormatter
              text={row.original.dolProduct}
            />
          );
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ row }) => {
          return <div dangerouslySetInnerHTML={{__html: row.original.description}} />;
        },
      },
      {
        accessorKey: "serialNumber",
        header: "N° de série",
        Cell: ({ row }) => {
          return <TextFormatter text={row.original.serialNumber} />;
        },
      },
      {
        accessorKey: "tvaTx",
        header: "TVA %",
        Cell: ({ row }) => {
          return <TextFormatter text={row.original.tvaTx} />;
        },
      },
      {
        accessorKey: "price",
        header: "P.U. Vente H.T.",
        Cell: ({ row }) => {
          return <TextFormatter text={row.original.price} />;
        },
      },
      {
        accessorKey: "quantity",
        header: "Quantité",
        Cell: ({ row }) => {
          return <TextFormatter text={row.original.quantity} />;
        },
      },
  ];

  // Get the table
  const table = useMaterialReactTable({
    columns: columns,
    data: purchases,
    enableEditing: true,
    editDisplayMode: "cell",
    initialState: {
      columnOrder: [
        "dolProduct",
        "description",
        "serialNumber",
        "tvaTx",
        "price",
        "quantity",
      ],
    },
    localization: MRT_Localization_FR,
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default PurchasesList;