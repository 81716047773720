import { Card, Typography, Box, Chip, Divider } from "@mui/material";
import { MdOutlineInfo } from "react-icons/md";
import { IoMdArrowDropright } from "react-icons/io";
import { useEffect } from "react";
import { getDataOvh } from "../../../repository/telephonies/Ovh";
import { _ROUTES } from "../../../utils/_routes_constants";

const InfoGenerales = ({group, phonebook, setPhonebook}) => {
    useEffect(() => {
        getDataOvh(
            {billingAccount: group?.billingAccount}, 
            setPhonebook, 
            "Une erreur est survenue lors de la récupération des données phonebook",
            _ROUTES.GROUP_OVH_PHONEBOOK
        );
    }, [group]);

    return (
        <>
            <Card sx={{borderTop: 3, borderTopColor: "primary.main"}}>
                <Box>
                    <Typography sx={{ padding:"5px", fontSize: "1rem"}}>
                        <MdOutlineInfo size="20" style={{verticalAlign:"text-top", marginRight:"10px"}}/> 
                        <strong>Informations générales</strong>
                    </Typography>
                </Box>
                <Divider sx={{background: "blue"}} />
                <Box sx={{padding: "5px"}}>
                    <IoMdArrowDropright style={{verticalAlign:"text-top", marginRight:"10px"}} /> 
                    <strong>Compte de facturation</strong> 
                    <Chip sx={{float: "right"}} variant="outlined" size="small"  label ={group?.billingAccount} />
                </Box>
                <Divider />
                <Box sx={{padding: "5px"}}>
                    <IoMdArrowDropright style={{verticalAlign:"text-top", marginRight:"10px"}} /> 
                    <strong>État de service</strong> 
                    {group?.status === "enabled" 
                        ? (
                            <Chip 
                                sx={{float: "right"}} 
                                variant="outlined" 
                                size="small" 
                                color="success"  
                                label ={"Actif"} 
                            />
                            )
                        : (
                            <Chip 
                                sx={{float: "right"}} 
                                variant="outlined" 
                                size="small" 
                                color="error"  
                                label ={"Inactif"} 
                            />
                            )
                    }
                </Box>
                <Divider />
                <Box sx={{padding: "5px"}}>
                    <IoMdArrowDropright style={{verticalAlign:"text-top", marginRight:"10px"}} /> 
                    <strong>Hors forfait actuel</strong> 
                    <Chip 
                        sx={{float: "right"}} 
                        variant="outlined" 
                        size="small"  
                        label ={group?.currentOutplan?.text} 
                    />
                </Box>
                <Divider />
                <Box sx={{padding: "5px"}}>
                    <IoMdArrowDropright style={{verticalAlign:"text-top", marginRight:"10px"}} /> 
                    <strong>Carnet de contacts</strong> 
                    {phonebook?.length  > 0 
                        ? (
                            <Chip 
                                sx={{float: "right"}} 
                                variant="outlined" 
                                size="small" 
                                color="success"  
                                label ={"Oui"} 
                            />
                            )
                        : (
                            <Chip 
                                sx={{float: "right"}} 
                                variant="outlined" 
                                size="small" 
                                color="error"  
                                label ={"Non"} 
                            />
                            )
                    }
                </Box>
            </Card>
        </>
    )
};

export default InfoGenerales;