import React, { useEffect, useState } from "react";
import PrixFacturationTemplate from "./PrixFacturationTemplate";
import { _ACTIONS } from "../../utils/constants";
import { createToast } from "../../utils/toast";
import { useUpdateTarifs } from "../../hooks/useUpdateTarifs";
import { RequestHelper } from "../../utils/requestHelper";

export default function TeleSauvegarde() {
  const customColumns = [
    {
      field: "QuotaGO",
      headerName: "Quota en GO (contractuel)",
      width: 230,
      editable: true,
    },
    {
      field: "vente",
      headerName: "Prix de vente HT/mensuel",
      type: "number",
      width: 180,
      editable: true,
      renderCell: (params) => {
        return (
          <div>
            {params.value} €
          </div>
        );
      }
    },
  ];

  
  const [dataPrix, setDataPrix] = useState([]);
  const requestHelper = new RequestHelper();
  const [processRowUpdate, handleProcessRowUpdateError] = useUpdateTarifs();

  useEffect(() => {
    getTarifs();
  }, []);

  /**
   * Fetches the tarifs from the server.
   * @returns {Promise<void>} A promise that resolves when the tarifs are fetched successfully.
   */
  function getTarifs() {
    try {
      requestHelper.doRequest(
        _ACTIONS.GET,
        "/prix-facturations?produitService=6"
      ).then((response) => {
        setDataPrix(
          response.data["hydra:member"].map((tarif) => ({
            id: tarif.id,
            QuotaGO: tarif.quantite,
            vente: tarif.vente
          }))
        );
      });
    } catch (error) {
      createToast("error", error.message);
    }
  }

  return (
    <>
      <PrixFacturationTemplate
        height={500}
        columns={customColumns} 
        row={dataPrix}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
       />
    </>
  );
}
