import { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import _ from "lodash";
import { getDataOvh } from "../../../repository/telephonies/Ovh";
import { _ROUTES } from "../../../utils/_routes_constants";

const TelephonieOvhLines = ({client, setLine, group, setIsAlert, linesDetails, setLinesDetails, setQueue, lineNumber}) => {
    const [number, setNumber] = useState(0);

    /**
     * Retrieves a line object by its number.
     *
     * @param {string} number - The number of the line to retrieve.
     * @returns {Object|undefined} - The line object if found, otherwise undefined.
     */
    const getLineById = (number) => {
        return linesDetails?.tableauLigne?.find(line => line?.number === number);
    }

    useEffect(() => {
        setLinesDetails([]);
        getDataOvh(
            {billingAccount: group.billingAccount, fkParcPhone: client.id}, 
            setLinesDetails, 
            "Une erreur est survenue lors de la récupération des lignes",
            _ROUTES.RECAP_OVH_TELEPHONIE_LINE
        );
    }, [group]);

    useEffect(() => {
        if(number !== 0){
            getDataOvh(
                {billingAccount: group?.billingAccount, line: number}, 
                setQueue, 
                "Une erreur est survenue lors de la récupération de la queue",
                _ROUTES.RECAP_OVH_TELEPHONIE_QUEUE
            );
        }
    }, [number]);

    useEffect(() => {
        if(lineNumber !== null) {
            setNumber(lineNumber);
            setLine(getLineById(lineNumber, linesDetails?.tableauLigne));
            setIsAlert(false);
        }
    }, []);

    /**
     * Handles the change event of the line input.
     * @param {Object} event - The event object.
     */
    const handleChangeLine = (event) => {
        setNumber(event.target.value);
        setLine(getLineById(event.target.value));
        event.target.value !== 0 ? setIsAlert(false) : setIsAlert(true);
    };

    return(
        <> 
            {!_.isEmpty(linesDetails) &&
                <FormControl key="fc-2" variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="client-line-label">Ligne</InputLabel>
                    <Select
                        labelId="client-line-label"
                        id="client-ligne"
                        value={ number }
                        onChange={handleChangeLine}
                    >   
                    <MenuItem key="c" value={0}>Veuillez choisir une ligne </MenuItem>
                    {linesDetails?.tableauLigne?.map((line) => (
                        <MenuItem key={line?.number} value={line?.number}>{line?.name}</MenuItem>
                    )
                    )}
                    </Select>
                </FormControl>
            }
        </>
    );
};

export default TelephonieOvhLines;