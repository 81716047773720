import React, { useEffect } from "react";
import { _ACTIONS } from "../../../utils/constants";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { RequestHelper } from "../../../utils/requestHelper";
import moment from "moment";
import MuiDatePicker from "../../helpers/MuiDatePicker";

const FormFieldsVadeSecure = ({
  formik,
  user,
  formStates,
  setFormStates,
  setVadeSecureCustomers,
  vadeSecureCustomers,
}) => {
  const rq = new RequestHelper();

  useEffect(() => {
    setFormStates({
      ...formStates,
      isVadeSecureForm: true,
    });
  }, []);

  const getVadeSecureCustomers = () => {
    rq.doRequest(_ACTIONS.GET, `/get-customers-list`).then((res) => {
      setVadeSecureCustomers(res.data);
    });
  };
  useEffect(() => {
    getVadeSecureCustomers();
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={1} justifyContent="center" marginTop={2}>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Vade Secure Id</strong>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl
                fullWidth
                error={
                  formik.touched.clientId && Boolean(formik.errors.clientId)
                }
              >
                <InputLabel id="clientId-label" size="small">
                  Vade Secure Id
                </InputLabel>
                <Select
                  labelId="clientId-label"
                  id="clientId"
                  value={formik.values.clientId}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="clientId"
                  size="small"
                >
                  {vadeSecureCustomers.map((customer) => (
                    <MenuItem key={customer.id} value={customer.id}>
                      <strong>{customer.brand}</strong> - {customer.id}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.clientId && formik.errors.clientId ? (
                  <FormHelperText>{formik.errors.clientId}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Date de début</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <MuiDatePicker
                label="Date de début"
                name="startDate"
                value={formik.values.startDate}
                onChange={(newDate) => {
                  formik.setFieldValue("startDate", moment(newDate));
                }}
                errors={formik.errors.startDate}
                touched={formik.touched.startDate}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Date de fin</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <MuiDatePicker
                label="Date de fin"
                name="endDate"
                value={formik.values.endDate}
                onChange={(newDate) => {
                  formik.setFieldValue("endDate", moment(newDate));
                }}
                errors={formik.errors.endDate}
                touched={formik.touched.endDate}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FormFieldsVadeSecure;
