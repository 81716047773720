import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS } from "../../utils/constants";
import { RiAddBoxLine } from "react-icons/ri";
import { RiDeleteBin5Line } from "react-icons/ri";
import { IconButton } from "@mui/material";
import { createToast } from "../../utils/toast";

const FormProduitServiceConfig = ({ setNewConfig }) => {
  const rq = new RequestHelper();
  const [selectOptions, setSelectOptions] = useState([""]);

  const validationSchema = Yup.object({
    label: Yup.string().required("Le nom est requis"),
    typeFieldForm: Yup.string().required("Le type de champ est requis"),
    optionsSelectValues: Yup.array().of(Yup.string()),
  });

  const handleAddOption = () => {
    setSelectOptions([...selectOptions, ""]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = selectOptions.filter((_, i) => i !== index);
    setSelectOptions(newOptions);
  };

  /* Gère la mise à jour des options de sélection */
  const handleOptionChange = (e, index) => {
    const newOptions = [...selectOptions];
    newOptions[index] = e.target.value;
    setSelectOptions(newOptions);
  };

  const formik = useFormik({
    initialValues: {
      label: "",
      typeFieldForm: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const submissionData = {
        ...values,
        optionsSelectValues: selectOptions, // Utilisez le tableau selectOptions pour les options
      };
      rq.doRequest(
        _ACTIONS.POST,
        `create_new_config_field`,
        submissionData
      ).then((res) => {
        if (res.status === 201 || res.status === 200) {
          setNewConfig(res.data);
          createToast("success", "La configuration a bien été créée");
        } else {
          createToast("error", "Une erreur est survenue");
        }
      });
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} justifyContent="center" marginTop={1}>
          <Grid item xs={3}>
            <Typography variant="body1" component="div">
              <strong>Nom*</strong>
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              size="small"
              id="label"
              name="label"
              sx={{
                "& .MuiInputBase-input": {
                  height: "13px",
                },
              }}
              value={formik.values.label}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.label &&
                !formik.values.label &&
                Boolean(formik.errors.label)
              }
              helperText={
                formik.touched.label && !formik.values.label
                  ? formik.errors.label
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" component="div">
              <strong>Type de champ *</strong>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl
              fullWidth
              error={
                formik.touched.typeFieldForm &&
                Boolean(formik.errors.typeFieldForm)
              }>
              <Select
                labelId="typeFieldForm-label"
                id="typeFieldForm"
                value={formik.values.typeFieldForm}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                name="typeFieldForm"
                size="small"
                sx={{ height: "30px" }}>
                <MenuItem value={"SELECT"}>Select</MenuItem>
                <MenuItem value={"CHECKBOX"}>Checkbox</MenuItem>
                <MenuItem value={"TEXT"}>Texte</MenuItem>
              </Select>
              {formik.touched.typeFieldForm && formik.errors.typeFieldForm ? (
                <FormHelperText>{formik.errors.typeFieldForm}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          {formik.values.typeFieldForm === "SELECT" &&
            selectOptions.map((option, index) => {
              return (
                <>
                  <Box key={index}>
                    <Grid item xs={3}>
                      <Typography variant="body1" component="div">
                        <strong>Options *</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={7.5}>
                      <TextField
                        fullWidth
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "13px",
                          },
                        }}
                        id="optionsSelectValues"
                        name="optionsSelectValues"
                        size="small"
                        onChange={(e) => handleOptionChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={1.5} display={"flex"}>
                      <IconButton
                        aria-label="add"
                        size="small"
                        color="inherit"
                        onClick={handleAddOption}>
                        <RiAddBoxLine />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        color="inherit"
                        disabled={selectOptions.length === 1}
                        onClick={() => handleRemoveOption(index)}>
                        <RiDeleteBin5Line />
                      </IconButton>
                    </Grid>
                  </Box>
                </>
              );
            })}
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            marginTop={1}>
            <Button type="submit" variant="contained" color="primary">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormProduitServiceConfig;
