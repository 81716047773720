/* PATTERN FORM RESERVATION */
export const _PATTERN_FORM_RESERVATION = [
  {
    name: "quantity",
    label: "Quantité",
    type: "text",
    size: 4,
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "ckEditor",
    size: 4,
    required: false,
  },
  {
    name: "startDate",
    label: "Date de début",
    type: "date",
    size: 4,
    required: true,
  },
  {
    name: "endDate",
    label: "Date de fin",
    type: "date",
    size: 4,
    required: true,
  },
];
