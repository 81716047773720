import React, { useEffect } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { _ACTIONS } from "../../../utils/constants";

const FormFieldsBitdefender = ({
  formik,
  formStates,
  setFormStates,
  bitdefenderCustomers,
  setBitdefenderCustomers,
}) => {
  const rq = new RequestHelper();

  useEffect(() => {
    setFormStates({
      ...formStates,
      isBitdefenderForm: true,
    });
  }, []);

  useEffect(() => {
    rq.doRequest(_ACTIONS.GET, `get-companies-list`).then((res) => {
      setBitdefenderCustomers(res.data);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={1} justifyContent="center" marginTop={2}>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3.5}>
              <Typography variant="body1" component="div">
                <strong>Bitdefender ID</strong>
              </Typography>
            </Grid>
            <Grid item xs={8.5}>
              <FormControl
                fullWidth
                error={
                  formik.touched.compagnies && Boolean(formik.errors.compagnies)
                }
              >
                <InputLabel id="compagnies-label">
                  Identifiant Bitdefenfer
                </InputLabel>
                <Select
                  labelId="compagnies-label"
                  id="compagnies"
                  value={formik.values.compagnies}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="compagnies"
                  size="small"
                  sx={{ height: "30px" }}
                >
                  {bitdefenderCustomers &&
                    bitdefenderCustomers.map((customer) => (
                      <MenuItem key={customer.id} value={customer.id}>
                        <strong>{customer.name}</strong> - {customer.id}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.compagnies && formik.errors.compagnies ? (
                  <FormHelperText>{formik.errors.compagnies}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FormFieldsBitdefender;
