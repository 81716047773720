import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { _ACTIONS, _COLORS, _MESSAGES } from "../../utils/constants";
import { _TABLE_PRODUIT_SERVICE_CONFIG_VALUES } from "../../utils/produit-service/_CONSTANTS";
import { createToast } from "../../utils/toast";

/**
 * Renders a table row for configuring a specific item.
 *
 * @param {number} idx - The index of the row.
 * @param {string} item - The item being configured.
 * @param {Object} currentItem - The current item being configured.
 * @param {Function} handleCheckboxChange - The callback function for handling checkbox changes.
 * @param {Object} requestHelper - The request helper object.
 * @param {Function} setNewChamps - The function for setting new champs.
 */
export function TableRowConfig(props) {
  const {
    item,
    label,
    currentObj,
    handleCheckboxChange,
    requestHelper,
    setNewChamps = () => {},
  } = props;

  const handleDeleteConfigField = (item) => {
    requestHelper
      .doRequest(_ACTIONS.DELETE, `produit-service-configurations/${item.id}`)
      .then(() => {
        setNewChamps((prev) => {
          return prev.filter((champ) => champ.id !== item.id);
        });
      })
      .catch(() => {
        createToast("error", _MESSAGES.error_delete);
      });
  };

  return (
    <TableRow
      sx={{
        "& > *": {
          borderBottom: `solid 1px ${_COLORS.midGrey}`,
          padding: "6px",
        },
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {label}
      </TableCell>
      <TableCell align="center">
        <Checkbox
          name={item.id ? item.id.toString() : item}
          id={item.id ? item.id.toString() : item}
          checked={!!currentObj}
          onChange={handleCheckboxChange(item)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </TableCell>
      <TableCell align="center">
        {
          // IF ITEM DOES NOT EXIST IN _TABLE_PRODUIT_SERVICE_CONFIG_VALUES ADD DELETE BUTTON //
          !_TABLE_PRODUIT_SERVICE_CONFIG_VALUES.hasOwnProperty(item) && (
            <IconButton
              aria-label="delete"
              size="small"
              color="inherit"
              onClick={() => handleDeleteConfigField(item)}
              title="Supprimer"
            >
              <RiDeleteBin5Line />
            </IconButton>
          )
        }
      </TableCell>
    </TableRow>
  );
}
