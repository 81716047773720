import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";

/**
 * Renders a tooltip with information.
 *
 * @component
 * @param {string} props.message - The message to display in the tooltip.
 * @returns {JSX.Element} The rendered tooltip component.
 */
const ToolTipInfo = (props) => {
  const { message } = props;
  return (
    <IconButton title={message} color="inherit">
      <InfoIcon />
    </IconButton>
  );
};

export default ToolTipInfo;
