import Swal from "sweetalert2";
import { hasNumber } from "../../../utils/function";
import { _ACTIONS, _RESULT } from "../../../utils/constants";
import { _ROUTER_DOM_URL, _ROUTES } from "../../../utils/_routes_constants";
import { RequestHelper } from "../../../utils/requestHelper";
import _ from "lodash";
import { createToast } from "../../../utils/toast";

export const _DEFAULT_TICKET = {
  actions: [],
  closedAt: null,
  closedBy: null,
  contacts: [],
  contrat: 0,
  contratType: null,
  createdAt: new Date(),
  createdBy: null,
  description: "",
  estimedEndAt: new Date(),
  fournisseur: null,
  fournisseurContacts: [],
  id: null,
  invoiceType: { id: 1, name: "Contrat", "@id": "/api/ticket-invoice-types/1" },
  isFactured: false,
  origin: {
    "@id": "/api/ticket-origins/1",
    "@type": "TicketOrigin",
    id: 1,
    name: "E-mail",
  },
  priority: {
    id: 2,
    name: "Normal",
    "@id": "/api/ticket-priorities/2",
  },
  produitService: null,
  produitServiceCategorie: null,
  refTicket: "",
  reservations: [],
  societe: null,
  status: {
    "@id": "/api/ticket-statuses/3",
    "@type": "TicketStatus",
    id: 3,
    name: "En cours",
  },
  subject: "",
  ticketLogs: [],
  type: {
    "@id": "/api/ticket-types/4",
    "@type": "TicketType",
    id: 4,
    name: "Achat",
    description:
      "demande d’achat effectuée en interne pour des besoins internes ou externes",
  },
  updatedAt: null,
  updatedBy: null,
  user: null,
};

const errorsTrad = {
  subject: "Le sujet du ticket est obligatoire",
  description: "La description du ticket est obligatoire",
  descriptionPret: "La description du prêt est obligatoire",
  societe: "Le client du ticket est obligatoire",
  produitService: "La ressource est obligatoire",
  startDate: "La date de début est obligatoire",
  endDate: "La date de fin est obligatoire",
  actionType: "Le type d'action est obligatoire",
  user: "Le technicien est obligatoire",
  contacts: "Au moins un contact est obligatoire",
};

const entityTrad = {
  reservation: "Pret",
  action: "Action",
  purchase: "Vente",
  user: "Technicien",
};

export const doCreateTicketValidation = (ticket) => {
  let errors = [];

  if (!ticket.subject) {
    errors.push("subject");
  }
  if (!ticket.description) {
    errors.push("description");
  }
  if (!ticket.societe) {
    errors.push("societe");
  }

  if (!ticket.produitService) {
    errors.push("produitService");
  }

  if (ticket.contacts.length === 0) {
    errors.push("contacts");
  }

  // Reservations validation
  if (ticket.reservations) {
    ticket.reservations.forEach((reservation, index) => {
      if (!reservation.resource) {
        errors.push(`reservation_produitService_${index}`);
      }
      if (!reservation.description) {
        errors.push(`reservation_descriptionPret_${index}`);
      }
      if (!reservation.startDate) {
        errors.push(`reservation_startDate_${index}`);
      }
      if (!reservation.endDate) {
        errors.push(`reservation_endDate_${index}`);
      }
    });
  }

  // Action validation
  if (ticket.actions) {
    ticket.actions.forEach((action, index) => {
      if (!action.user) {
        errors.push(`action_user_${index}`);
      }
      if (!action.actionType) {
        errors.push(`action_actionType_${index}`);
      }
      if (!action.description) {
        errors.push(`action_description_${index}`);
      }
      if (!action.achievedAt) {
        errors.push(`action_achievedAt_${index}`);
      }
    });
  }

  // Ventes validation
  if (!_.isEmpty(ticket.actions) && ticket.actions[0].purchases) {
    const action = ticket.actions[0];
    action.purchases.forEach((purchase, index) => {
      console.log(purchase);
      if (!purchase.product) {
        errors.push(`purchase_produitService_${index}`);
      }
      if (!purchase.description) {
        errors.push(`purchase_description_${index}`);
      }
      if (!purchase.quantity) {
        errors.push(`purchase_quantity_${index}`);
      }
    });
  }

  if (errors.length === 0) {
    return true;
  }

  return Swal.fire({
    title: "Erreur",
    text: "Veuillez remplir les champs obligatoires",
    html: swalErrorsLisHtmlBuilder(errors),
  });
};

/**
 *
 * @param {*} errors
 * @returns
 */
export function swalErrorsLisHtmlBuilder(errors) {
  let html =
    "<span>Les erreurs suivantes ont été détectées sur le mandat :</span><ul>";

  errors.forEach((error) => {
    let addline = "";

    html += `<li style="font-style:italic">${errorToHtml(
      error
    )} ${addline}</li>`;
  });

  html += "</ul>";

  html +=
    "<h3>Veuillez corriger ces erreurs afin de générer le ticket</h3><ul>";

  return html;
}

/**
 *
 * @param {*} error
 * @returns
 */
function errorToHtml(error) {
  if (hasNumber(error)) {
    const entity = entityTrad[error.split("_")[0]];
    const field = error.split("_")[1];
    const index = error.split("_")[2];

    return errorsTrad[field] + " pour " + entity + " " + (parseInt(index) + 1);
  }

  return errorsTrad[error];
}

/**
 *
 * @param {*} ticket
 */
export function doCreateTicket(formData, navigate) {
  const rq = new RequestHelper();
  rq.doRequest(_ACTIONS.POSTFILE, _ROUTES.CREATE_TICKET, formData).then(
    (res) => {
      if (res.data.status === _RESULT.success) {
        createToast("success", "Le ticket a été créé avec succès").then(() => {
          navigate(_ROUTER_DOM_URL.TICKET_EDIT + res.data.ticket);
        });
      } else {
        Swal.fire({
          title: "Erreur",
          text: "Une erreur est survenue lors de la création du ticket",
          icon: "error",
        });
      }
    }
  );
}
