import React, { useEffect } from "react";
import { RequestHelper } from "../../utils/requestHelper";
import { useFormik } from "formik";
import * as yup from "yup";
import { _ACTIONS } from "../../utils/constants";
import { Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { createToast } from "../../utils/toast";
import { _ROUTES } from "../../utils/_routes_constants";
import Editor from "ckeditor5-custom-build";

const FormProduitService = ({
  isEdit,
  id = null,
  isCategorie = false,
  setRefreshList,
}) => {
  const rQ = new RequestHelper();
  const theme = useTheme();

  const validationSchema = yup.object({
    name: yup.string().required("Veuillez séléctionner une option"),
    description: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    onSubmit: async () => {
      let actionType;
      if (isCategorie && !isEdit) {
        actionType = "createCategorie";
      } else if (isEdit && isCategorie) {
        actionType = "updateCategorie";
      } else if (!isEdit) {
        actionType = "create";
      } else {
        actionType = "update";
      }
      switch (actionType) {
        case "create":
          rQ.doRequest(
            _ACTIONS.POST,
            `${_ROUTES.PRODUIT_SERVICE}`,
            formik.values
          ).then((res) => {
            if (res.status === 201)
              createToast("success", "Ajout d'un produit effectué avec succès");
            else {
              createToast("error", "Une erreur est survenue");
            }
          });
          break;
        case "createCategorie":
          const data = {
            ...formik.values,
            parent: id,
          };
          rQ.doRequest(_ACTIONS.POST, `${_ROUTES.PRODUIT_SERVICE}`, data).then(
            (res) => {
              if (res.status === 201)
                createToast(
                  "success",
                  "Ajout d'un catalogue effectué avec succès"
                );
              else {
                createToast("error", "Une erreur est survenue");
              }
            }
          );
          break;
        case "updateCategorie":
          rQ.doRequest(
            _ACTIONS.PUT,
            `${_ROUTES.PRODUIT_SERVICE}/${id}`,
            formik.values
          ).then((res) => {
            if (res.status === 200)
              createToast("success", "Modification effectuée avec succès");
            else {
              createToast("error", "Une erreur est survenue");
            }
          });
          break;
        case "update":
          rQ.doRequest(
            _ACTIONS.PUT,
            `${_ROUTES.PRODUIT_SERVICE}/${id}`,
            formik.values
          ).then((res) => {
            if (res.status === 200)
              createToast("success", "Modification effectuée avec succès");
            else {
              createToast("error", "Une erreur est survenue");
            }
          });
          break;
        default:
          createToast("error", "Une action non reconnue a été demandée");
      }
      setRefreshList((prev) => prev + 1);
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    if (isEdit && id) {
      rQ.doRequest(_ACTIONS.GET, `${_ROUTES.PRODUIT_SERVICE}/${id}`).then(
        (res) => {
          formik.setValues({
            name: res.data.name,
            description:
              !res.data.description || res.data.description === "NULL"
                ? ""
                : res.data.description,
          });
        }
      );
    }
  }, [isEdit, id]);

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2" component="div" fontStyle={"italic"}>
              <strong>Produit | Service </strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.name &&
                !formik.values.name &&
                Boolean(formik.errors.name)
              }
              helperText={
                formik.touched.name && !formik.values.name
                  ? formik.errors.name
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" component="div" fontStyle={"italic"}>
              <strong>Description </strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CKEditor
              editor={Editor}
              name="description"
              data={formik.values.description ? formik.values.description : ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                formik.setFieldValue("description", data);
              }}
            />
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: theme.palette.background.lightBlue }}
            >
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default FormProduitService;
