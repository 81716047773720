import { useContext } from "react";
import BasicModal from "../helpers/BasicModal";
import { HeaderDashboardGeneral } from "./HeaderDashboardGeneral";
import { AvisModal } from "./AvisModal";
import { _MESSAGES_DASHBOARD_GENERAL } from "../../utils/dashboard-general/constant";
import { DashboardGeneralContext } from "../../contexts/DashboardGeneralContext";
import { Container, Grid } from "@mui/material";
import { Profile } from "./Profile";
import { Alert } from "./Alert";
import { Catalogue } from "./Catalogue";
import { Stats } from "./Stats";
import { EtatTicket } from "./EtatTicket";
import { Contrat } from "./Contrat";
import Loader from "../utils/Loader";
import { useCustomRequestDashboard } from "./useCustomRequestDashboard";
import { UserContext } from "../../contexts/UserContext";
import useHasRoles from "../../hooks/useHasRole";
import { _ROLES } from "../../utils/constants";

export function DashboardGeneralComponent() {
  const { showModal, setShowModal } = useContext(DashboardGeneralContext);
  const { user } = useContext(UserContext);
  const canAccess = useHasRoles(user, [
    _ROLES.ADMIN,
    _ROLES.TECHNICIEN,
    _ROLES.SUPER_ADMIN,
  ]);
  const { loading } = useCustomRequestDashboard();

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {/* Modal */}
      <BasicModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={_MESSAGES_DASHBOARD_GENERAL.h1ModalAvis}
        content={<AvisModal />}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderDashboardGeneral />
        </Grid>
      </Grid>
      <Container
        component="main"
        maxWidth={false}
        sx={{
          mt: 1,
          padding: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12} md={6}>
              <Profile />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Alert />
          </Grid>
          <Grid item xs={12}>
            <EtatTicket canAccess={canAccess} user={user} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Catalogue />
          </Grid>
          <Grid item xs={12} md={6}>
            {canAccess && (
              <Grid item xs={12}>
                <Stats />
              </Grid>
            )}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Contrat />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
