import { Box, Button } from "@mui/material";
import React, { useContext } from "react";
import { IconButton, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { RiDeleteBin5Line } from "react-icons/ri";
import { LuUserSquare } from "react-icons/lu";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { createToast } from "../../../utils/toast";
import { useNavigate } from "react-router-dom";
import { RequestHelper } from "../../../utils/requestHelper";
import { _ACTIONS } from "../../../utils/constants";
import { _ROUTER_DOM_URL, _ROUTES } from "../../../utils/_routes_constants";
import { BaseConnaissanceContext } from "../../../contexts/BaseConnaissanceContext";

/**
 * Renders the header section of an article.
 * Includes the article's status, share button, and delete/edit buttons.
 *
 * @param {Object} props.article - The article object.
 * @param {boolean} props.isTechnicien - Indicates whether the user is a technician.
 * @param {string} props.urlActuelArticle - The current article URL.
 * @param {Function} props.setArticle - A function to set the article state.
 */
export default function ArticleHeader({
  article,
  isTechnicien,
  urlActuelArticle,
  setArticle,
}) {
  const { setRefresh } = useContext(BaseConnaissanceContext);
  const navigate = useNavigate();
  const requestHelper = new RequestHelper();

  /**
   * Copies the current article URL to the clipboard and displays a toast message.
   */
  const doShareArticle = () => {
    const currentUrl = window.location.origin + urlActuelArticle;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        createToast("success", "Lien copié dans le presse-papiers");
      })
      .catch((err) => {
        createToast("error", "Erreur lors de la copie du lien");
      });
  };

  /**
   * Deletes an article.
   *
   * @param {string} articleId - The ID of the article to be deleted.
   * @returns {Promise<void>} - A promise that resolves when the article is successfully deleted.
   */
  const doDeleteArticle = async (articleId) => {
    try {
      const res = await requestHelper.doRequest(
        _ACTIONS.DELETE,
        _ROUTES.BASE_CONNAISSANCE_ARTICLES + "/" + articleId
      );
      if (res.status === 204) {
        createToast("success", "Article supprimé avec succès");
        setArticle(null);
        navigate(_ROUTER_DOM_URL.BASE_CONNAISSANCE);
        setRefresh((prev) => !prev + 1);
      }
    } catch (error) {
      createToast("error", "Erreur lors de la suppression de l'article");
    }
  };

  return (
    <>
      <Box
        sx={{
          g: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {article.isInterne ? <LockIcon /> : <LockOpenIcon />}
        {article.isEnabled === 1 ? (
          <Typography variant="body2">Actif</Typography>
        ) : article.isEnabled === -1 ? (
          <Typography variant="body2">Brouillon</Typography>
        ) : (
          <Typography variant="body2">Inactif</Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <IconButton
          onClick={() => {
            doShareArticle();
          }}
          title="Copier le lien de l'article dans le presse-papiers"
        >
          <ContentCopyIcon />
        </IconButton>
        {isTechnicien && (
          <>
            <Button
              variant="contained"
              title="Supprimer l'article"
              endIcon={<RiDeleteBin5Line />}
              color="error"
              size="small"
              onClick={() => {
                doDeleteArticle(article.id);
              }}
            >
              Supprimer
            </Button>
            <Button
              variant="contained"
              title="Editer l'article"
              endIcon={<LuUserSquare />}
              size="small"
              onClick={() => {
                navigate(`/base-connaissance-form/${article.id}`);
              }}
            >
              Edit
            </Button>
          </>
        )}
      </Box>
    </>
  );
}
