import { Typography } from "@mui/material";
import { isNull } from "lodash";
import moment from "moment";
import { _DATE_FORMAT } from "../../../../utils/constants";
import { DateFormatter } from "../../../../utils/muiTableHelper";
import { sizeColumnMuiTable } from "../../../../utils/actif-config/actif_configuration_constants";

const { sizeMediumText, sizeLongText } = sizeColumnMuiTable;

/*
 * Columns for Router-Firewall usefull in the ResponseListCatalogue.jsx
 * @returns Array
 * */
export const routerFirewallColumns = [
  {
    accessorKey: "societeProduitServiceOpnsense.osVersion",
    header: "Os version",
    size: sizeLongText,
    Cell: ({ row }) => {
      const osVersion = row.original.societeProduitServiceOpnsense?.osVersion;

      return <Typography>{isNull(osVersion) ? "" : osVersion}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceOpnsense.productVersion",
    header: "Version",
    size: sizeMediumText,
    enableSorting: false,
    Cell: ({ row }) => {
      const productVersion =
        row.original.societeProduitServiceOpnsense?.productVersion;

      return (
        <Typography>{isNull(productVersion) ? "" : productVersion}</Typography>
      );
    },
  },
  {
    accessorKey: "dateUpdate",
    header: "Date MàJ",
    size: sizeMediumText,
    enableSorting: false,
    Cell: ({ row }) => {
      const dateUpdate = row.original.societeProduitServiceOpnsense?.dateMaj;

      if (!dateUpdate) return "";
      let momentDate = moment(dateUpdate, _DATE_FORMAT.datetime_exact);

      return isNull(momentDate) ? (
        ""
      ) : (
        <DateFormatter momentDate={momentDate} />
      );
    },
  },
];
