import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import _ from "lodash";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { listContactsColumnBuilder } from "./ListContactColumns";
import { useMemo } from "react";

export const ListContactsModal = ({ listContacts, setListContacts }) => {
  const columns = useMemo(
    () => listContactsColumnBuilder(listContacts, setListContacts),
    []
  );

  // Get the table
  const table = useMaterialReactTable({
    columns: columns,
    data: listContacts,
    enableEditing: true,
    editDisplayMode: "cell",
    initialState: {
      columnOrder: [
        "firstName",
        "email",
        "phonePro",
        "phoneFix",
        "poste",
        "actions",
      ],
      density: "compact",
    },
    localization: MRT_Localization_FR,
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};
