import { Button, ButtonGroup } from "@mui/material";
import { useContext } from "react";
import { _BTN } from "../../../../utils/_style_constants";
import SearchIcon from "@mui/icons-material/Search";
import { TicketsListContext } from "../../../../contexts/TicketsListContext";

export const TableToolBox = () => {
  const { refresh, setRefresh } = useContext(TicketsListContext);

  const doRefresh = () => {
    setRefresh(refresh + 1);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        aria-label="text primary button group"
      >
        <Button sx={_BTN.FILTER_BTN} size="small" onClick={doRefresh}>
          <SearchIcon></SearchIcon> Lancer la recherche
        </Button>
      </ButtonGroup>
    </>
  );
};

export default TableToolBox;
