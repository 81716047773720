import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFormik } from "formik";
import { _ACTION_FORMIK } from "./AddActionFormik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import DownloadIcon from "@mui/icons-material/Download";
import { useContext, useEffect, useState } from "react";
import { TicketsListContext } from "../../../../contexts/TicketsListContext";
import { __FORM_STYLES } from "../../../../utils/_style_constants";
import _ from "lodash";
import { RequestHelper } from "../../../../utils/requestHelper";
import { _ROUTES } from "../../../../utils/_routes_constants";

export const AddActionForm = ({
  ticket,
  setTicket,
  setOpenActionModal,
  action = null,
}) => {
  const rq = new RequestHelper();
  const { filtersList } = useContext(TicketsListContext);
  const [fileName, setFileName] = useState("Aucun fichier sélectionné");
  const [listTechniciens, setListTechniciens] = useState([]);
  const [listActionType, setListActionType] = useState([]);
  const formik = useFormik(
    _ACTION_FORMIK(ticket, setTicket, setOpenActionModal, action)
  );
  const notifEmail = [
    { id: 2, name: "Support" },
    { id: 1, name: "Infogérance" },
    { id: 0, name: "Non" },
  ];

  const handleFileChange = (e) => {
    const files = e.target?.files;
    if (_.isEmpty(files)) {
      setFileName("Aucun fichier sélectionné");
      formik.setFieldValue("doc", []);
      return;
    }
    setFileName("");
    Array.from(files).forEach((file) => {
      setFileName((prev) => {
        return prev + file.name + " ";
      });
    });

    formik.setFieldValue("doc", files);
  };

  const handleChangeDureeFacturee = (e) => {
    const value = e.target.value;
    formik.setFieldValue("durationEstimated", value);
    formik.setFieldValue("durationReal", value);
  };

  useEffect(() => {
    if (_.isEmpty(listActionType)) {
      rq.doRequest("GET", _ROUTES.ACTION_TYPE).then((res) => {
        if (res.status === 200) {
          return setListActionType(res.data["hydra:member"]);
        }
      });
    }
  }, [listActionType]);

  useEffect(() => {
    if (filtersList && _.isEmpty(listTechniciens)) {
      setListTechniciens(
        filtersList.technicien.map((technicien) => {
          return {
            "@id": "/api/users/" + technicien.id,
            name: technicien.name,
          };
        })
      );
    }
    if (filtersList && _.isEmpty(listActionType)) {
      rq.doRequest("GET", _ROUTES.ACTION_TYPE).then((res) => {
        if (res.status === 200) {
          setListActionType(res.data["hydra:member"]);
        }
      });
    }
  }, [filtersList]);

  return (
    <>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
            <Grid container spacing={1} sx={{ display: "flex" }}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography variant="h6">Description</Typography>
                <CKEditor
                  editor={Editor}
                  data={formik.values.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue("description", data);
                  }}
                ></CKEditor>
                {formik.errors.description ? (
                  <FormHelperText sx={__FORM_STYLES.errorMessage}>
                    {formik.errors.description}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "1rem",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">{fileName}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "5px",
                      width: "100%",
                    }}
                  >
                    <FormControl>
                      <Button variant="text" size="small" component="label">
                        Sélectionner un fichier <DownloadIcon />
                        <input
                          type="file"
                          name="files"
                          multiple
                          hidden
                          onChange={(e) => {
                            handleFileChange(e);
                          }}
                        />
                      </Button>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <Autocomplete
                    size="small"
                    name="user"
                    label="Technicien"
                    options={listTechniciens}
                    getOptionLabel={(option) => option.name}
                    value={
                      listTechniciens.find(
                        (option) => option["@id"] === formik.values.user
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "user",
                        newValue ? newValue["@id"] : ""
                      );
                    }}
                    onBlur={() => {}}
                    renderInput={(params) => (
                      <TextField
                        name="user"
                        label="Technicien"
                        sx={{}}
                        error={Boolean(formik.errors.user)}
                        {...params}
                      />
                    )}
                  ></Autocomplete>
                  {formik.errors.user ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.user}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <DatePicker
                    size="small"
                    label="Date d'action"
                    name="achievedAt"
                    value={formik.values.achievedAt}
                    onChange={(value) => {
                      formik.setFieldValue("achievedAt", value);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: Boolean(formik.errors.achievedAt),
                      },
                    }}
                  />
                  {formik.errors.achievedAt ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.achievedAt}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <TextField
                    fullWidth
                    label="Durée fact (mn)"
                    name="durationEstimated"
                    size="small"
                    inputProps={{ type: "number", min: 0, step: 15 }}
                    value={formik.values.durationEstimated}
                    onChange={handleChangeDureeFacturee}
                    error={Boolean(formik.errors.durationEstimated)}
                  />
                  {formik.errors.durationEstimated ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.durationEstimated}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <TextField
                    fullWidth
                    label="Durée réelle (mn)"
                    name="durationReal"
                    size="small"
                    inputProps={{ type: "number", min: 0, step: 15 }}
                    value={formik.values.durationReal}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.durationReal)}
                  />
                  {formik.errors.durationReal ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.durationReal}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <InputLabel id="ticketActionType">Type d'action</InputLabel>
                  <Select
                    fullWidth
                    id="ticketActionType"
                    name="ticketActionType"
                    value={formik.values.ticketActionType}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.ticketActionType)}
                  >
                    {listActionType.map((actionType) => {
                      return (
                        <MenuItem key={actionType.id} value={actionType["@id"]}>
                          {actionType.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.errors.ticketActionType ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.ticketActionType}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <InputLabel id="notifEmail">Notification Email</InputLabel>
                  <Select
                    fullWidth
                    id="notifEmail"
                    name="notifEmail"
                    value={formik.values.notifEmail}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.notifEmail)}
                  >
                    {notifEmail.map((n) => {
                      return (
                        <MenuItem key={n.id} value={n.id}>
                          {n.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid
                item
                xs={10}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  id="isPrivate"
                  value={formik.values.isPrivate}
                  onChange={formik.handleChange}
                  name="isPrivate"
                  {...(formik.values.isPrivate && { checked: true })}
                />
                <InputLabel htmlFor="isPrivate">Note Interne</InputLabel>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
              <Grid
                item
                xs={10}
                sx={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setOpenActionModal(false);
                  }}
                  variant="contained"
                >
                  <Typography>Annuler</Typography>
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    console.log(formik.errors);
                  }}
                  variant="contained"
                >
                  <Typography>Valider</Typography>
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
      </Box>
    </>
  );
};

export default AddActionForm;
