import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect , useState} from "react";
import { _SUBSCRIPTION_TYPE } from "../../utils/constants";
import { Chip } from "@mui/material";
import { MdOutlineInfo } from "react-icons/md";
import { IoMdArrowDropright } from "react-icons/io";
import moment from "moment";
import { _DATE_FORMAT } from "../../utils/constants";
import { getDataBitdefender } from "../../repository/antivirus/Bitdefender";
import { _ROUTES } from "../../utils/_routes_constants";


const InfoGenerales = ({client, infosLicence, setInfosLicence}) => {
    const [infos, setInfos] = useState({});
    const [monthlyUsage, setMonthlyUsage] = useState([]);

    const getInfos = () => {
        setInfos({});
        if(infosLicence?.subscriptionType === 3){
            setMonthlyUsage([]);
            setInfos([]);
            const targetMonth = moment().format('MM/YYYY');
            getDataBitdefender(
                {companyId: client.compagnies, targetMonth: targetMonth}, 
                setMonthlyUsage, 
                "Une erreur est survenue lors de la récupération des infos d'utilisation mensuelle",
                _ROUTES.SOCIETE_BITDEFENDER_USAGE
            );
            if(monthlyUsage?.length === 0) {
                setInfos({
                    subscriptionType: _SUBSCRIPTION_TYPE[infosLicence?.subscriptionType],
                    endpointMonthlyUsage: monthlyUsage.endpointMonthlyUsage,
                    patchManagementMonthlyUsage: monthlyUsage.patchManagementMonthlyUsage,
                    encryptionMonthlyUsage: monthlyUsage.encryptionMonthlyUsage,
                    totalSlots: infosLicence.totalSlots,
                    dateExpiry: 'Jamais'
                });
            }
        } else {
            setInfos({
                subscriptionType: _SUBSCRIPTION_TYPE[infosLicence?.subscriptionType],
                endpointMonthlyUsage: infosLicence?.usedSlots,
                patchManagementMonthlyUsage: 'N/D',
                encryptionMonthlyUsage: 'N/D',
                totalSlots: infosLicence?.totalSlots,
                dateExpiry: infosLicence?.expiryDate
            });
        }
    };

    useEffect(() => {
        setInfosLicence([]);
        setInfos({});
        getDataBitdefender(
            {companyId: client.compagnies}, 
            setInfosLicence, 
            "Une erreur est survenue lors de la récupération des infos de licence",
            _ROUTES.SOCIETE_BITDEFENDER_LICENCE
        );
    }, [client]);

    useEffect(() => {
        getInfos()
    }, [infosLicence]);

    useEffect(() => {
        setInfos({
            subscriptionType: _SUBSCRIPTION_TYPE[infosLicence?.subscriptionType],
            endpointMonthlyUsage: monthlyUsage.endpointMonthlyUsage,
            patchManagementMonthlyUsage: monthlyUsage.patchManagementMonthlyUsage,
            encryptionMonthlyUsage: monthlyUsage.encryptionMonthlyUsage,
            totalSlots: infosLicence.totalSlots,
            dateExpiry: 'Jamais'
        });
    }, [monthlyUsage]);

    return (
        <>
            <Card>
                <Box sx={{backgroundColor: "#00c0ef"}}>
                    <Typography sx={{ padding:"3px", fontSize: "1rem", color: "white"}} >
                        <MdOutlineInfo style={{verticalAlign: 'text-bottom', marginRight: '5px'}} size="20"/>
                        <strong>Informations générales</strong>
                    </Typography>
                </Box>
                <Box sx={{padding: "3px"}}>
                    <IoMdArrowDropright style={{verticalAlign: 'text-bottom', marginRight: '5px'}} />
                    <strong>Type d'abonnement</strong> 
                    <Chip sx={{float: "right"}} variant="outlined" size="small" color="success"  label ={infos?.subscriptionType} />
                </Box>
                <Box sx={{padding: "3px"}}>
                    <IoMdArrowDropright style={{verticalAlign: 'text-bottom', marginRight: '5px'}} />
                    <strong>Utilisateur ayant une license</strong> 
                    <Chip sx={{float: "right"}} variant="outlined" size="small" color="primary" label={infos?.endpointMonthlyUsage} />
                </Box>
                <Box sx={{padding: "3px"}}>
                    <IoMdArrowDropright style={{verticalAlign: 'text-bottom', marginRight: '5px'}} />
                    <strong>Patch management</strong> 
                    <Chip sx={{float: "right"}} variant="outlined" size="small" color="primary" label={infos?.patchManagementMonthlyUsage} />
                </Box>
                <Box sx={{padding: "3px"}}>
                    <IoMdArrowDropright style={{verticalAlign: 'text-bottom', marginRight: '5px'}} />
                    <strong>Full Disk Encryption</strong> 
                    <Chip sx={{float: "right"}} variant="outlined" size="small" color="primary" label={infos?.encryptionMonthlyUsage} />
                </Box>
                <Box sx={{padding: "3px"}}>
                    <IoMdArrowDropright style={{verticalAlign: 'text-bottom', marginRight: '5px'}} />
                    <strong>Licence réservée</strong> 
                    <Chip sx={{float: "right"}} variant="outlined" size="small" color="primary"  label ={infos?.totalSlots} />
                </Box>
                <Box sx={{padding: "3px"}}>
                    <IoMdArrowDropright style={{verticalAlign: 'text-bottom', marginRight: '5px'}} /><strong>Validité de la licence</strong> 
                    {infos?.dateExpiry === 'Jamais' && (
                        <Chip sx={{float: "right"}} variant="outlined" size="small" color="success"  label ={infos?.dateExpiry} /> 
                    )}
                    {(infos?.dateExpiry !== 'Jamais' 
                        && (moment(infos?.dateExpiry) < moment())) && (
                        <Chip sx={{float: "right"}} variant="outlined" size="small" color="error"  label={moment(
                            infos?.dateExpiry
                        ).format(_DATE_FORMAT.date_display)}/>
                    )}
                    {(infos?.dateExpiry !== 'Jamais' 
                        && (moment(infos?.dateExpiry) >= moment())) && (
                        <Chip sx={{float: "right"}} variant="outlined" size="small" color="success"  label={moment(
                            infos?.dateExpiry
                        ).format(_DATE_FORMAT.date_display)}/>
                    )} 
                </Box>
            </Card>
        </>
    );
};

export default InfoGenerales;