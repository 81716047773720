import { Chip } from "@mui/material";
import React from "react";

/**
 * Renders a <Chip/> mui component with information.
 *
 */
export const ChipTransformer = (props) => {
  const { onClick, label, color, size } = props;

  return <Chip label={label} onClick={onClick} color={color} size={size} />;
};
