import { useState } from "react";
import { createContext } from "react";

export const BaseConnaissanceContext = createContext({});

function BaseConnaissanceContextProvider({ children }) {
  const [article, setArticle] = useState(null);
  const [filter, setFilter] = useState("");
  const [refresh, setRefresh] = useState(0);
  const value = {
    article,
    setArticle,
    filter,
    setFilter,
    refresh,
    setRefresh,
  };

  return (
    <BaseConnaissanceContext.Provider value={value}>
      {children}
    </BaseConnaissanceContext.Provider>
  );
}

export default BaseConnaissanceContextProvider;
