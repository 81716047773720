/**
 * Fetches the filters list from the API and stores it in a cookie.
 */
import moment from "moment";
import { _ROUTER_DOM_URL, _ROUTES } from "./_routes_constants";
import { _ACTIONS } from "./constants";
import { RequestHelper } from "./requestHelper";
import _ from "lodash";
import { Box, Link } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import EuroIcon from "@mui/icons-material/Euro";

const rq = new RequestHelper();

export function getFiltersListFromAPI() {
  // fetch filters list
  rq.doRequest(_ACTIONS.GET, _ROUTES.TICKETS_LIST_FILTERS).then((res) => {
    if (res.data.code === 401) {
      console.log("Error: Unauthorized", res);
    } else {
      window.localStorage.setItem("ticketFilterList", JSON.stringify(res.data));
    }
  });
}

// Fonction pour calculer le nombre de jours ouvrés entre deux dates
export function joursOuvres(dateDebut, dateFin) {
  let nbJours = 0;
  let currentDate = new Date(dateDebut);
  while (currentDate <= dateFin) {
    if (
      currentDate.getDay() !== 0 &&
      currentDate.getDay() !== 6 &&
      !estJourFerie(currentDate)
    ) {
      nbJours++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return nbJours;
}
const year = new Date().getFullYear();

// Liste des jours fériés en France
const joursFeries = [
  new Date(year, 0, 1), // Nouvel An
  new Date(year, 4, 1), // Fête du Travail
  new Date(year, 4, 8), // Victoire 1945
  new Date(year, 6, 14), // Fête Nationale
  new Date(year, 7, 15), // Assomption
  new Date(year, 10, 1), // Toussaint
  new Date(year, 10, 11), // Armistice 1918
  new Date(year, 11, 25), // Noël
];

// Fonction pour vérifier si une date est un jour férié
function estJourFerie(date) {
  const dateFerie = joursFeries.find(
    (ferie) =>
      ferie.getDate() === date.getDate() && ferie.getMonth() === date.getMonth()
  );
  return dateFerie !== undefined;
}

/**
 *
 * RefTicketFormatter for Material React Table
 * @param {*} row
 * @returns
 */
export const RefTicketFormatter = ({
  row,
  setOpenHistory,
  setTicketLogs,
  navigate,
}) => {
  const ALERT_NON_ASSOCIE_STATUS = 1;
  const ALERT_NON_AFFECTE_STATUS = 1;
  const ALERT_AFFECTE_STATUS = 2;
  const ALERT_EN_COURS_STATUS = 3;
  const ALERT_EN_ATTENTE_STATUS = 3;

  const NON_AFFECTE_STATUS = "Non affecté";
  const AFFECTE_STATUS = "Affecté";
  const EN_COURS_STATUS = "En cours";
  const EN_ATTENTE_STATUS = "En attente";
  const CLOTURE_STATUS = "Clôturé";
  const ARCHIVED_STATUS = "Archivé";

  const messages = {
    alertDepassementNonAssocies: "Ticket non associé depuis plus de ",
    alertDepassementNonAffectes: "Ticket non affecté depuis plus de ",
    alertDepassementAffectes: "Ticket affecté depuis plus de ",
    alertDepassementEncours: "Ticket en cours depuis plus de ",
    alertDepassementAttentes: "Ticket en attente depuis plus de ",
  };

  let messageDepassement = "";
  const today = new moment();
  const actions = row.original.actions;

  const actionDate =
    !_.isEmpty(actions) && actions[0] !== null
      ? actions[0].getAchievedAt
      : row.original.createdAt;

  const intervalResolutionEstimee = row.original.estimedEndAt < today;
  const nbJoursOuvres = joursOuvres(actionDate, today);
  const status = row.original.status.name;
  let alertDepassement;

  if (row.original.societe === null) {
    messageDepassement =
      nbJoursOuvres > ALERT_NON_ASSOCIE_STATUS && intervalResolutionEstimee
        ? messages.alertDepassementNonAssocies +
          ALERT_NON_ASSOCIE_STATUS +
          " jours"
        : "";
  } else if (status === NON_AFFECTE_STATUS) {
    messageDepassement =
      nbJoursOuvres > ALERT_NON_AFFECTE_STATUS && intervalResolutionEstimee
        ? messages.alertDepassementNonAffectes +
          ALERT_NON_AFFECTE_STATUS +
          " jours"
        : "";
  } else if (status === AFFECTE_STATUS) {
    messageDepassement =
      nbJoursOuvres > ALERT_AFFECTE_STATUS && intervalResolutionEstimee
        ? messages.alertDepassementAffectes + ALERT_AFFECTE_STATUS + " jours"
        : "";
  } else if (status === EN_COURS_STATUS) {
    messageDepassement =
      nbJoursOuvres > ALERT_EN_COURS_STATUS && intervalResolutionEstimee
        ? messages.alertDepassementEncours + ALERT_EN_COURS_STATUS + " jours"
        : "";
  } else if (status === EN_ATTENTE_STATUS) {
    messageDepassement =
      nbJoursOuvres > ALERT_EN_ATTENTE_STATUS && intervalResolutionEstimee
        ? messages.alertDepassementAttentes + ALERT_EN_ATTENTE_STATUS + " jours"
        : "";
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Dépassement */}
      {messageDepassement !== "" &&
        // @Todo Ajouter alerte depassement
        ""}
      {/* History */}
      <Link
        onClick={() => {
          setOpenHistory(true);
          setTicketLogs(row.original.ticketLogs);
        }}
        title="Voir l'historique"
        sx={{
          cursor: "pointer",
          color: "#00acd6",
        }}
      >
        <HistoryIcon></HistoryIcon>
      </Link>
      {/* Link To Edit */}
      <Link
        onClick={() => {
          navigate(_ROUTER_DOM_URL.TICKET_EDIT + row.original.id);
        }}
        sx={{
          fontWeight: "bold",
          margin: "5px",
          cursor: "pointer",
          fontSize: "0.9rem",
        }}
        title="Voir le ticket"
      >
        {row.original.refTicket}
      </Link>
      {/* Factured */}
      {(status === CLOTURE_STATUS || status === ARCHIVED_STATUS) && (
        <Link
          title={row.original.isFactured ? "Facturé" : "Non facturé"}
          onClick={() => {}}
        >
          <EuroIcon
            sx={{
              color: row.original.isFactured ? "green" : "red",
              cursor: "pointer",
              width: "1rem",
              height: "1rem",
            }}
          />
        </Link>
      )}
    </Box>
  );
};

/**
 *
 * @param {*} row
 */
export function updateTicketPriority(row) {
  const rq = new RequestHelper();
  const data = {
    priority:
      row.priority.id < 3
        ? "/api/ticket_priorities/" + row.priority.id + 1
        : "/api/ticket_priorities/1",
  };

  rq.doRequest(_ACTIONS.PATCH, _ROUTES.TICKETS + "/" + row.id, data).then(
    (res) => {}
  );
}
