import { Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { LOGICIEL_FORM_FIELD } from "../../../utils/actif-config/actif_configuration_constants";
import moment from "moment";
import MuiDatePicker from "../../helpers/MuiDatePicker";

export default function FormFieldLogiciel({
  formik,
  formStates,
  setFormStates,
}) {
  useEffect(() => {
    setFormStates({
      ...formStates,
      isLogicielForm: true,
    });
  }, []);

  return (
    <Grid container spacing={1} justifyContent="center" marginTop={2}>
      {LOGICIEL_FORM_FIELD.filter((field) => field.type === "text").map(
        (field) => (
          <Grid item xs={12} md={8} key={field.name}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body1" component="div">
                  <strong>{field.label}</strong>
                </Typography>
              </Grid>
              <Grid item xs={field.size}>
                <TextField
                  fullWidth
                  size="small"
                  label={field.label}
                  name={field.name}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors[field.name])}
                  helperText={formik.errors[field.name]}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      )}
      {LOGICIEL_FORM_FIELD.filter((field) => field.type === "date").map(
        (field) => {
          return (
            <Grid item xs={12} md={8} key={field.name}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body1" component="div">
                    <strong>
                      {field.label} {field.required && "*"}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={field.size}>
                  <MuiDatePicker
                    label={field.label}
                    name={field.name}
                    value={formik.values[field.name]}
                    onChange={(newDate) => {
                      formik.setFieldValue(field.name, moment(newDate));
                    }}
                    errors={formik.errors[field.name]}
                    touched={formik.touched[field.name]}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        }
      )}
    </Grid>
  );
}
