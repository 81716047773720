import React from "react";
// MUI //
import { Box, IconButton } from "@mui/material";
// REACT ICONS //
import { MdOutlineLibraryAdd } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { RequestHelper } from "../../../utils/requestHelper";
import { createConfirmToast, createToast } from "../../../utils/toast";
import { _ACTIONS } from "../../../utils/constants";
import { _ROUTES } from "../../../utils/_routes_constants";

/**
 * Renders the row actions for the BaseConnaissanceConfigurationTable.jsx
 *
 * @param {Object} props.row - The row object.
 */
export function RowActionsBsConfiguration({
  row,
  setShowModal,
  setIsSectionForm,
  setCategoryRow,
  setCategories,
  setSectionRow,
}) {
  const requestHelper = new RequestHelper();

  function doConfirmDelete(id) {
    createConfirmToast(
      "warning",
      null,
      "Êtes-vous sûr de vouloir supprimer la catégorie ?",
      "Oui",
      "Non"
    ).then((res) => {
      if (res) {
        requestHelper
          .doRequest(
            _ACTIONS.DELETE,
            `${_ROUTES.BASE_CONNAISSANCE_CATEGORIES}/${id}`
          )
          .then(() => {
            setCategories((prevData) =>
              prevData.filter((item) => item.id !== id)
            );
          });
        createToast("success", "Catégorie supprimée avec succès").catch(
          (error) => {
            createToast(
              "error",
              "Erreur lors de la suppression de la catégorie"
            );
          }
        );
      }
    });
  }

  return (
    <Box display="flex" flexWrap={"nowrap"}>
      <IconButton
        key="1"
        size="small"
        color="inherit"
        title="Ajouter une section"
        onClick={() => {
          setShowModal(true);
          setIsSectionForm(true);
          setSectionRow(null);
        }}
      >
        <MdOutlineLibraryAdd />
      </IconButton>
      <IconButton
        key="2"
        size="small"
        title="Supprimer la catégorie"
        color="error"
        onClick={() => {
          doConfirmDelete(row.original.id);
        }}
      >
        <RiDeleteBin5Line />
      </IconButton>
      <IconButton
        key="3"
        size="small"
        title="Modifier la catégorie"
        color="success"
        onClick={() => {
          setShowModal(true);
          setIsSectionForm(false);
          setCategoryRow(row.original);
        }}
      >
        <FaEdit />
      </IconButton>
    </Box>
  );
}
