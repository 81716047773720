import { _ACTIONS, _HTTP } from "../../utils/constants";
import { _ROUTES } from "../../utils/_routes_constants";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";

const requestHelper = new RequestHelper();


/**
 * Retrieves resources based on the provided parameters.
 * @param {object} params - The parameters for the request.
 * @param {function} setData - The function to set the retrieved data.
 * @param {string} errorMessage - The error message to display if the request fails.
 */
export const getResources = (params, setData, errorMessage) => {
    requestHelper
    .doRequest(_ACTIONS.GET, `${_ROUTES.RESSOURCES}`, params)
    .then((response) => {
        if(_HTTP.ok !== response.status) {
            throw new Error(errorMessage);
        }
        setData(response.data["hydra:member"]);
    })
    .catch((error) => {
        createToast("error", error.message);
    });
};