import {
  _MESSAGES_ACTIF_CONFIG,
  getInitialEmptyValues,
} from "../../utils/actif-config/actif_configuration_constants";
import { useFormik } from "formik";
import {
  getValidationSchema,
  saveAlso,
  saveBitdefender,
  saveGsuite,
  saveHebergement,
  saveLogiciel,
  saveOpnSense,
  saveStepOne,
  saveStepTwo,
  saveTelephonieAsterisk,
  saveTelephonieOvh,
  saveVPN,
  saveVadeSecure,
} from "../../utils/actif-config/actif_configuration_functions";
import { determineMethodHttp } from "../../utils/function";
import { createToast } from "../../utils/toast";

export default function DoSubmitFormActif(props) {
  const {
    currentActifConfig,
    formStates,
    currentId,
    valueField,
    companiesAlso,
  } = props;

  const formik = useFormik({
    initialValues: getInitialEmptyValues(currentActifConfig, formStates),
    validationSchema: getValidationSchema(formStates),
    onSubmit: async (values) => {
      try {
        /* Step One include (societes, produitService, produitServiceCategorie...)*/
        const reqStepOne = determineMethodHttp(currentActifConfig);
        let produitServiceConfig = null;
        currentActifConfig &&
          (produitServiceConfig =
            currentActifConfig.produitService.produitServiceConfig);

        const responseStepOne = await saveStepOne(
          values,
          reqStepOne,
          formStates,
          produitServiceConfig
        );
        if (responseStepOne.status !== 200 && responseStepOne.status !== 201) {
          throw new Error(_MESSAGES_ACTIF_CONFIG.BAD_REQUEST);
        }

        /* Step Two include (valueField) */
        if (valueField && valueField.length > 0) {
          const responseTwo = await saveStepTwo(values, currentId);
          if (responseTwo.status !== 200) {
            throw new Error(_MESSAGES_ACTIF_CONFIG.BAD_REQUEST);
          }
        }

        switch (true) {
          case formStates.isVadeSecureForm:
            const responseVadeSecure = await saveVadeSecure(
              values,
              currentActifConfig
            );
            if (
              responseVadeSecure.status !== 200 &&
              responseVadeSecure.status !== 201
            ) {
              throw new Error(
                `${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} VadeSecure`
              );
            }
            break;
          case formStates.isBitdefenderForm:
            const responseBitdefender = await saveBitdefender(
              values,
              currentId
            );
            if (responseBitdefender.status !== 200) {
              throw new Error(
                `${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} Bitdefender`
              );
            }
            break;
          case formStates.isGsuiteForm:
            const responseGsuite = await saveGsuite(values, currentId);
            if (responseGsuite.status !== 200) {
              throw new Error(`${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} Gsuite`);
            }
            break;
          case formStates.isAlsoCustomer:
            const responseAlso = await saveAlso(
              values,
              currentId,
              companiesAlso
            );
            if (responseAlso.status !== 200) {
              throw new Error(`${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} Also`);
            }
            break;
          case formStates.isOpnSenseForm:
            const responseOpnSense = await saveOpnSense(
              values,
              currentActifConfig
            );
            if (
              responseOpnSense.status !== 200 &&
              responseOpnSense.status !== 201
            ) {
              throw new Error(`${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} OpnSense`);
            }
            break;
          case formStates.isVPNForm:
            const responseVpn = await saveVPN(values, currentActifConfig);
            if (responseVpn.status !== 200 && responseVpn.status !== 201) {
              throw new Error(`${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} VPN`);
            }
            break;
          case formStates.isHebergementForm:
            const responseHebergement = await saveHebergement(
              values,
              currentActifConfig
            );
            if (
              responseHebergement.status !== 200 &&
              responseHebergement.status !== 201
            ) {
              throw new Error(
                `${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} Hébergement`
              );
            }
            break;
          case formStates.isLogicielForm:
            const responseLogiciel = await saveLogiciel(
              values,
              currentActifConfig
            );
            if (
              responseLogiciel.status !== 200 &&
              responseLogiciel.status !== 201
            ) {
              throw new Error(
                `${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} logiciels`
              );
            }
            break;
          case formStates.isTelephonieAsteriskForm:
            const responseTelephonieAsterisk = await saveTelephonieAsterisk(
              values,
              currentActifConfig
            );
            if (
              responseTelephonieAsterisk.status !== 200 &&
              responseTelephonieAsterisk.status !== 201
            ) {
              throw new Error(
                `${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} logiciels`
              );
            }
            break;
          case formStates.isTelephonieOvhForm:
            const responseTelephonieOvh = await saveTelephonieOvh(
              values,
              currentActifConfig
            );
            if (
              responseTelephonieOvh.status !== 200 &&
              responseTelephonieOvh.status !== 201
            ) {
              throw new Error(
                `${_MESSAGES_ACTIF_CONFIG.BAD_REQUEST} logiciels`
              );
            }
            break;
          default:
        }
        createToast("success", "La configuration a bien été créée");
      } catch (error) {
        createToast(
          "error",
          "Une erreur est survenue lors de la soumission du formulaire"
        );
      }
    },
  });

  return formik;
}
