import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  FormControl,
  Grid,
  Select,
  Typography,
  MenuItem,
  InputLabel,
  TextField,
  InputAdornment,
  Button,
  Box,
  Autocomplete,
  Divider,
} from "@mui/material";
import { LuUserSquare } from "react-icons/lu";
import { MdOutlineAttachEmail } from "react-icons/md";
import { CIVILITY, _ACTIONS, _COLORS } from "../../src/utils/constants";
import { CiUser } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import { RequestHelper } from "../../src/utils/requestHelper";
import { createToast } from "../../src/utils/toast";
import FormTitle from "../../src/components/helpers/FormTitle";
import Loader from "../components/utils/Loader";
import { _ROUTER_DOM_URL, _ROUTES } from "../utils/_routes_constants";

function FormContactFournisseur() {
  const [contactInfo, setContactInfo] = useState(null);
  const [tiersList, setTiersList] = useState([]);
  const { type, id } = useParams();
  const navigate = useNavigate();
  const rq = new RequestHelper();

  /* Fetches societes when type of form is contact */
  useEffect(() => {
    if (type === "contact") {
      searchSociete();
    }
  }, []);

  /**
   * Searches for a societe based on the provided value.
   * @param {string} value - The value to search for.
   * @returns {Promise<void>} - A promise that resolves when the search is complete.
   */
  function searchSociete(value) {
    rq.doRequest(_ACTIONS.GET, _ROUTES.SOCIETE, {
      name: value || "",
      pagination: "disabled",
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(
            "La récupération des sociétés a échoué avec le statut " +
              response.status
          );
        }
        setTiersList(response.data["hydra:member"]);
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  }

  /**
   * Fetches contact information based on the provided ID.
   * @returns {void}
   */
  const fetchContactInfo = () => {
    if (!id) return;
    const currentType =
      type === "contact_fournisseur" ? "contact-fournisseurs" : "contacts";
    rq.doRequest(_ACTIONS.GET, `/${currentType}/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setContactInfo(res.data);
        } else {
          throw new Error(
            "La récupération des informations a échoué avec le statut " +
              res.status
          );
        }
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  };

  useEffect(() => {
    fetchContactInfo();
  }, [id, type]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      poste: "",
      phonePro: "",
      phoneFix: "",
      civility: "",
      ...(type === "contact" && { societe: "" }),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required("Le prénom est requis"),
      lastName: Yup.string().required("Le nom est requis"),
      email: Yup.string().email("L'adresse e-mail est invalide").nullable(),
      poste: Yup.string().nullable(),
      phonePro: Yup.string().nullable(),
      phoneFix: Yup.string().nullable(),
      civility: Yup.string().nullable(),
      ...(type === "contact" && { societe: Yup.string().nullable() }),
    }),
    onSubmit: async (values) => {
      const action = id ? "PUT" : "POST";
      const currentType =
        type === "contact_fournisseur" ? "contact-fournisseurs" : "contacts";
      const endpoint = id ? `${currentType}/${id}` : currentType;

      try {
        const response = await rq.doRequest(action, endpoint, {
          ...values,
          createdAt: new Date(),
          isEnabled: false,
        });
        const successMessage = id ? "Modification réussie" : "Création réussie";
        createToast("success", successMessage);
      } catch (error) {
        createToast("error", "Une erreur est survenue lors de l'opération");
      }
    },
  });

  useEffect(() => {
    if (contactInfo) {
      formik.setValues({
        ...formik.values,
        ...contactInfo,
        societe: contactInfo.societe ? contactInfo.societe["@id"] : "",
      });
    }
  }, [contactInfo]);

  if (tiersList.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <Grid>
        <FormTitle
          title={
            id && contactInfo
              ? `Modifier le contact ${
                  contactInfo.firstName + " " + contactInfo.lastName
                }`
              : "Créer un contact"
          }
        />
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Container
          component="main"
          maxWidth={false}
          sx={{
            backgroundColor: "white",
            padding: "1rem",
            pb: "2rem",
            borderTop: `3px solid ${_COLORS.grey}`,
          }}
        >
          <Grid display={"flex"} gap={2} alignItems={"center"}>
            <LuUserSquare size={20} />
            <Typography variant="h6" component="div">
              {id ? "Modifier le contact" : "Créer un contact"}
            </Typography>
          </Grid>
          <Divider />
          <Container component="main" maxWidth="xl">
            <Grid container spacing={1} justifyContent="center" marginTop={5}>
              <Grid
                item
                xs={12}
                md={8}
                display={"flex"}
                gap={1}
                flexDirection={"column"}
              >
                {tiersList && type === "contact" && (
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body1" component="div">
                        <strong>Tiers *</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        fullWidth
                        size="small"
                        /* data */
                        options={tiersList ? tiersList : []}
                        /* display label */
                        getOptionLabel={(option) =>
                          option ? option.name : "Veuillez choisir une option"
                        }
                        defaultValue={tiersList.find(
                          (option) => option["@id"] === formik.values.societe
                        )}
                        onChange={(e, value) => {
                          if (value === null) {
                            formik.setFieldValue("societe", null);
                          } else {
                            formik.setFieldValue("societe", value["@id"]);
                          }
                        }}
                        /* search */
                        onInputChange={(e, value) => {
                          setTimeout(() => {
                            searchSociete(value);
                          }, 500);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Séléctionner un tiers"
                            fullWidth
                            name="societe"
                            sx={{
                              marginTop: "0px",
                            }}
                            onBlur={formik.handleBlur}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body1" component="div">
                      <strong>Civilité</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.civility &&
                        Boolean(formik.errors.civility)
                      }
                    >
                      <InputLabel size="small" id="civlity-label">
                        Civilité
                      </InputLabel>
                      <Select
                        labelId="civlity-label"
                        id="civility"
                        value={formik.values.civility}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        name="civility"
                        size="small"
                      >
                        {Object.entries(CIVILITY).map(([key, value]) => {
                          return (
                            <MenuItem key={value} value={value}>
                              {key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="body1" component="div">
                      <strong>Nom *</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Nom"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CiUser />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstName &&
                        !formik.values.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && !formik.values.firstName
                          ? formik.errors.firstName
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="body1" component="div">
                      <strong>Prénom *</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Prénom"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CiUser />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.lastName &&
                        !formik.values.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && !formik.values.lastName
                          ? formik.errors.lastName
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body1" component="div">
                      <strong>Email</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Email"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdOutlineAttachEmail />
                          </InputAdornment>
                        ),
                      }}
                      type="email"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="body1" component="div">
                      <strong>Poste/Fonction</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Poste"
                      size="small"
                      type="text"
                      id="poste"
                      name="poste"
                      value={formik.values.poste}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="body1" component="div">
                      <strong>Téléphone Fix</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Téléphone Fix"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CiPhone />
                          </InputAdornment>
                        ),
                      }}
                      type="phoneFix"
                      id="phoneFix"
                      value={formik.values.phoneFix}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="body1" component="div">
                      <strong>Téléphone Pro</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Téléphone Pro"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CiPhone />
                          </InputAdornment>
                        ),
                      }}
                      type="phonePro"
                      id="phonePro"
                      name="phonePro"
                      value={formik.values.phonePro}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={5}
            >
              <Button
                variant="contained"
                size="small"
                color="inherit"
                onClick={() => {
                  navigate(_ROUTER_DOM_URL.ADMIN_CONTACT);
                }}
              >
                Retour
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="success"
                size="small"
              >
                Enregistrer
              </Button>
            </Box>
          </Container>
        </Container>
      </form>
    </>
  );
}

export default FormContactFournisseur;
