import { Grid, Typography, Box } from "@mui/material";
import Resources from "../../components/gestion-exploitation/gestion-reservations/Resources";
import TypesReservation from "../../components/gestion-exploitation/gestion-reservations/TypesReservation";
import { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { _TYPES_RESERVATION } from "../../utils/constants";
import CalendarReservations from "../../components/gestion-exploitation/gestion-reservations/CalendarReservations";
import { getAllReservations } from "../../components/gestion-exploitation/gestion-reservations/reservations";

const GestionReservations = () => {
    const [resources, setResources] = useState([]);
    const [selectedTypeReservation, setSelectedTypeReservation] = useState(null);
    const [selectedResource, setSelectedResource] = useState(null);
    const [reservations, setReservations] = useState([]);   

    useEffect(() => {
        const status = [];
        const resourcesToShow = [];
        selectedTypeReservation === null ? status.push(0, 1) : status.push(selectedTypeReservation.id);
        selectedResource === null ? resourcesToShow.push(...resources) : resourcesToShow.push(selectedResource);
        setReservations(getAllReservations(resourcesToShow, status));
    }, [selectedTypeReservation, selectedResource, resources?.length > 0]);

    return (
        <>
            <Typography 
                variant="h5">Gestion des réservations 
                <span style={{fontSize:"0.6em", color:"grey", marginLeft:"10px"}}>Gérer les réservations internes et externe</span>
            </Typography>
            <Box sx={{marginTop: 5}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Resources 
                            resources={resources} 
                            setResources={setResources} 
                            selectedResource={selectedResource}
                            setSelectedResource={setSelectedResource}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TypesReservation 
                            selectedTypeReservation={selectedTypeReservation}
                            setSelectedTypeReservation={setSelectedTypeReservation}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ marginTop: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {_TYPES_RESERVATION.map((type, index) => (
                    (selectedTypeReservation === null || selectedTypeReservation?.id === type.id) && 
                    <Chip
                        key={index}
                        label={type.name}
                        color= {type.id === 1 ? "error" : "primary"}
                        size="small"
                    />
                )
                )}
            </Box>
            <CalendarReservations reservations={reservations} />
        </>
    );
};

export default GestionReservations;