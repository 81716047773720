import { _ACTIONS } from "../utils/constants";
import { createToast } from "../utils/toast";
import { useCallback } from "react";
import { RequestHelper } from "../utils/requestHelper";


/**
 * Custom hook for updating a parameter contract.
 * @param {string} route - The route for the parameter contract.
 * @returns {Array} An array containing the processRowUpdate and handleProcessRowUpdateError functions.
 */
export function useUpdateParamContrat(route) {
        const requestHelper = new RequestHelper()
        
        /**
         * Updates the tarif with the provided newRow data.
         * @param {Object} newRow - The new row data to update the tarif.
         * @returns {Promise} A promise that resolves with the response from the server.
         */
        function updateTarif(newRow){
                try{
                    requestHelper.doRequest(
                        _ACTIONS.PUT,
                        `/${route}/${newRow.id}`,
                        newRow
                    ).then((response) => {
                        if(response.status === 200){
                            createToast("success", "Données sauvegardées avec succès");
                        } else {
                            createToast("error", "Erreur lors de la sauvegarde des données");
                        }
    
                        return response;
                    });

                } catch (error) {
                    createToast("error", error.message);
                };
        };

        /**
         * Processes the row update by calling the updateTarif function and returning the updated row.
         * @param {Object} newRow - The new row data to update the tarif.
         * @returns {Promise} A promise that resolves with the updated row.
         */
        const processRowUpdate = useCallback (
                async (newRow) => {
                        await updateTarif(newRow);

                        return newRow;
                }, []
        );
        
        /**
         * Handles the error that occurs during the row update process.
         * @param {Error} error - The error that occurred during the row update.
         * @returns {Promise} A promise that resolves with the error message.
         */
        const handleProcessRowUpdateError = useCallback(
                async (error) => {
                createToast("error", error.message);
            }, []
        ); 
        
        return [processRowUpdate, handleProcessRowUpdateError];
};