import {
  Box,
  Chip,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { _ACTIONS, _COLORS, _MESSAGES } from "../../utils/constants";
import DonutSmallRoundedIcon from "@mui/icons-material/DonutSmallRounded";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { FaFileContract } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { _ROUTER_DOM_URL } from "../../utils/_routes_constants";

export const Contrat = () => {
  const [contrats, setContrats] = useState(null);
  const requestHelper = new RequestHelper();
  const navigate = useNavigate();

  const fetchContratsCount = async () => {
    try {
      const res = await requestHelper.doRequest(
        _ACTIONS.GET,
        "count-stat-contrats"
      );
      setContrats(res.data);
    } catch (error) {
      createToast("error", _MESSAGES.error2);
    }
  };

  useEffect(() => {
    fetchContratsCount();
  }, []);

  return (
    <>
      <Box
        sx={{
          w: "100%",
          border: `2px solid ${_COLORS.turquoiseBox}`,
          backgroundColor: _COLORS.white,
        }}
      >
        <Box
          sx={{
            backgroundColor: _COLORS.turquoiseBox,
            display: "flex",
            gap: 2,
            alignItems: "center",
            p: 1,
          }}
        >
          <DonutSmallRoundedIcon sx={{ color: _COLORS.white }} />
          <Typography variant="h6" sx={{ color: _COLORS.white }}>
            Contrats
          </Typography>
        </Box>
        {contrats &&
          contrats.map((c, i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(_ROUTER_DOM_URL.CONTRATS, {
                    state: { typeContratId: c.typeContratId },
                  });
                }}
              >
                <FaFileContract />
                <ListItemText primary={c.name} sx={{ ml: 1.5 }} />
                <Chip label={c.total} />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
    </>
  );
};
