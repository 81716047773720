import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { __FORM_STYLES } from "../../utils/_style_constants";
import { editInstantRequest } from "../../utils/function";
import _ from "lodash";
import { useEffect, useState } from "react";
import { createToast } from "../../utils/toast";

/**
 * InstantEditSelect is a component that renders a select input field with instant edit functionality.
 *
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered JSX element.
 */
export const InstantEditSelect = ({
  entityValue, // The current value of the entity
  setEntityValue, // Function to update the entity value
  label, // The label for the select input
  field, // The field of the entity to be edited
  options, // The options for the select input
  optionValue, // The field of the option objects to be used as the option value
  optionLabel, // The field of the option objects to be displayed as the option label
  relation = false, // Boolean indicating whether the field is a relation field
  disabled = false, // Boolean indicating whether the select input is disabled
  edit = true, // Boolean indicating whether instant edit is enabled
  defaultValue = null, // The initial value of the field
}) => {
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    if (entityValue && !_.isUndefined(entityValue[field])) {
      const initialValue = relation
        ? entityValue[field]
          ? entityValue[field]["@id"]
          : "" // If relation is true, get the @id property of the field value
        : entityValue[field];
      setInitialValue(initialValue);
    }
  }, [entityValue]);

  // Function to get the label for an option
  const getLabel = (c) =>
    _.isArray(optionLabel)
      ? optionLabel.map((o) => c[o]).join(" ") // If optionLabel is an array, join all corresponding properties from c
      : c[optionLabel]; // If optionLabel is not an array, get the corresponding property from c

  return (
    <FormControl sx={__FORM_STYLES.basicInput} size="small">
      <InputLabel
        sx={{ paddingBottom: "15px" }}
        shrink
        htmlFor={field + "select-instant"}
      >
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        inputProps={{
          name: field,
          id: field + "select-instant",
        }}
        value={
          !_.isNull(entityValue)
            ? relation
              ? entityValue[field]
                ? entityValue[field]["@id"]
                : "" // If relation is true, get the @id property of the field value
              : entityValue[field] // If relation is false, get the field value
            : "" // If entityValue is falsy, set value to an empty string
        }
        onChange={(e) => {
          // On change, update the entity value based on the new input value and whether the field is a relation field
          const value = relation
            ? options.find((o) => o[optionValue] === e.target.value) // If relation is true, find the option with a matching optionValue
            : e.target.value; // If relation is false, get the target value of the event

          setEntityValue({
            ...entityValue,
            [field]: value,
          });
        }}
        onBlur={(e) => {
          // On blur, if the target value is not undefined, make an instant edit request
          if (!_.isUndefined(e.target.value) && edit) {
            const value = relation
              ? options.find((o) => o[optionValue] === e.target.value) // If relation is true, find the option with a matching optionValue
              : e.target.value; // If relation is false, get the target value of the event
            // If the value is different from the initial value, make an instant edit request
            if (value && value[optionValue] !== initialValue) {
              editInstantRequest(value, entityValue["@id"], field).then(
                (res) => {
                  if (res["@id"]) {
                    createToast("success", "Modification enregistrée"); // If the request is successful, display a success toast
                    setEntityValue(res); // Update the entity value with the response
                  }
                }
              );
            }
          }
        }}
        initialvalue={defaultValue ? defaultValue : ""}
      >
        {options.map((o, i) => (
          <MenuItem key={o.id} value={relation ? o[optionValue] : o[field]}>
            {/* Get the label for the option */}
            {getLabel(o)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InstantEditSelect;
