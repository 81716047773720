import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { _ACTIONS, _API, _API_ID } from "../../utils/constants";
import { Checkbox } from "@mui/material";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import _ from "lodash";
import { _ROUTES } from "../../utils/_routes_constants";

const ConfigurationApi = ({
  currentItem,
  hebergement = false,
  bitdefender = false,
  logiciel = false,
  firewall = false,
}) => {
  const [rows, setRows] = React.useState([]);
  const rq = new RequestHelper();

  useEffect(() => {
    if (currentItem) {
      const determineType = () => {
        if (bitdefender) return "bitdefender";
        if (hebergement) return "hebergement";
        if (logiciel) return "logiciel";
        if (firewall) return "opnsense";
        return "";
      };

      const type = determineType();

      let newData = currentItem.childrens.map((item) => {
        let dataObject = {
          categorie: item?.name ?? null,
          state: item.produitServiceApi ? item.produitServiceApi[type] : false,
          module: _API[type.toUpperCase()],
          id: item?.produitServiceApi?.id ?? null,
          type: type,
        };
        return dataObject;
      });
      setRows(newData);
    }
  }, [currentItem, bitdefender, hebergement, logiciel, firewall]);

  const updateConfig = (index, checked, type) => {
    const update = {
      [type]: checked,
    };
    rq.doRequest(_ACTIONS.PUT, `${_ROUTES.PRODUIT_SERVICE}/${index}`, update)
      .then((res) => {
        if (res.status === 200) {
          const updatedRows = rows.map((row, i) =>
            /* si i === index, on retourne la row avec la valeur de checked, sinon on retourne la row */
            row.id === index ? { ...row, state: checked } : row
          );
          setRows(updatedRows);
          createToast("success", "Mise à jour effectuée");
        } else {
          createToast("error", "Une erreur est survenue");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCheckboxChange = (index, type) => (event) => {
    updateConfig(index, event.target.checked, type);
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Catégorie</TableCell>
              <TableCell>Etat</TableCell>
              <TableCell align="right">Module</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <strong>{row.categorie}</strong>
                  </TableCell>
                  <Checkbox
                    checked={row.state}
                    onChange={handleCheckboxChange(row.id, row.type)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <TableCell align="right">{row.module}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ConfigurationApi;
