import { Box, Tooltip, Typography } from "@mui/material";
import { _ACTIONS, _DATE_FORMAT, _SWAL_ICONS } from "./constants";
import _ from "lodash";
import { editInstantRequest } from "./function";
import { RequestHelper } from "./requestHelper";
import { createConfirm } from "./confirm";
import { createToast } from "./toast";

// COMON FORMATTERS

/**
 * TextFormatter for Material React Table
 *
 * @param {*} text
 * @param {*} options
 * @returns
 */
export const TextFormatter = ({
  text,
  options,
  fontSize = null,
  justifyContent = null,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: justifyContent ?? "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            justifyContent: "center",
            fontSize: fontSize ?? "0.9rem",
            ...options,
          }}
          title={text}
        >
          {text}
        </Typography>
      </Box>
    </>
  );
};

/**
 * DateFormatter for Material React Table
 *
 * @param {*} date
 * @param {*} format
 * @returns
 */
export const DateFormatter = ({
  momentDate,
  format = _DATE_FORMAT["date_and_time_display"],
}) => {
  return (
    <>
      <TextFormatter
        text={momentDate.format(format)}
        options={{
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      ></TextFormatter>
    </>
  );
};

export const TooltipFormatter = ({
  text,
  color,
  title,
  onClick,
  justifyContent = null,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: justifyContent ?? "flex-start",
        alignItems: "center",
        cursor: "pointer",
      }}
      title={title}
      onClick={onClick}
    >
      <Tooltip title={text}>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: color,
            padding: "5px",
            borderRadius: "5px",
            fontSize: "0.9rem",
            color: "black",
            fontWeight: "bold",
          }}
        >
          {text}
        </Box>
      </Tooltip>
    </Box>
  );
};

// SPECIFIC FORMATTERS

// HANDLERS

/**
 * Function to instantly edit a row in a table.
 *
 * @param {*} e - The event triggered by the edit action.
 * @param {*} row - The row to be edited.
 */
export function instantEdit(e, row) {
  // If the new value is not equal to the original email value
  if (!_.isEqual(e.target.value, row.original.email)) {
    // Make an instant edit request with the new value, the id of the original row, and the field to be edited
    editInstantRequest(e.target.value, row.original["@id"], "email");
    // Update the email value of the original row
    row.original.email = e.target.value;
  }
}

// Confirmation phrase for deleting a row
const CONFIRM_PHRASE = "Voulez vous supprimer cette ligne ?";
const DELETE_SUCCESS = "La ligne a été supprimée avec succès";

/**
 * Function to instantly delete a row in a table.
 *
 * @param {*} row - The row to be deleted.
 * @param {*} data - The data of the table.
 */
export function instantDelete(row, data, dataSetter) {
  // Create a confirmation dialog with the confirm phrase
  createConfirm(CONFIRM_PHRASE)
    .fire({})
    .then((result) => {
      // If the deletion is confirmed
      if (result.isConfirmed) {
        // Create a new RequestHelper
        const rq = new RequestHelper();
        // Get the URL of the original row
        const url = row.original["@id"].replace("/api", "", 1);
        // Make a DELETE request with the id of the original row
        rq.doRequest(_ACTIONS.DELETE, url).then((res) => {
          if (res.status === 200 || res.status === 204) {
            // Filter out the deleted row from the data
            data = data.filter((item) => item["@id"] !== row.original["@id"]);
            dataSetter(data);
            createToast(_SWAL_ICONS.success, DELETE_SUCCESS);
          }
        });
      }
    });
}
