import { useEffect, useState } from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { getDataTicketInvoiceType } from '../../../repository/tickets/TicketInvoiceType';
import _ from 'lodash';
import { _ROUTES } from '../../../utils/_routes_constants';
import { getIdsTypeInvoice } from './function';

const TypeFacturation = ({setInvoiceTypes}) => {
    const [typeFacturation, setTypeFacturation] = useState([]);
    const [typeFacturations, setTypeFacturations] = useState([]);

    useEffect(() => {
      if(typeFacturations.length === 0) {
          getDataTicketInvoiceType(
              {}, 
              setTypeFacturations, 
              'Une erreur est survenue lors de la récupération des types de facturation'
              , _ROUTES.TICKET_INVOICE_TYPE
          );
      }
    }, []);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setTypeFacturation(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
      setInvoiceTypes(getIdsTypeInvoice(typeFacturations, value));
    };

    return (
        <>
            <FormControl sx={{ m: 1, width: 300, mt:2 }}>
                <InputLabel id="type-facturation-checkbox-label">Type de facturation</InputLabel>
                <Select
                    labelId="type-facturation-checkbox-label"
                    id="type-facturation-checkbox"
                    multiple
                    value={typeFacturation}
                    onChange={handleChange}
                    input={<OutlinedInput label="Type de facturation" />}
                    renderValue={(selected) => selected.join(', ')}
                >
                {typeFacturations.map((type) => (
                    <MenuItem key={type.id} value={type.name}>
                        <Checkbox checked={typeFacturation.indexOf(type.name) > -1} />
                        <ListItemText primary={type.name} />
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </>
    );
};

export default TypeFacturation;