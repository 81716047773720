import { TYPE_CONTRATS } from "./ColumnsContrat";
import { useFormik } from "formik";
import { Button } from "@mui/material";
import { _ROUTES } from "../../utils/_routes_constants";
import { RequestHelper } from '../../utils/requestHelper';
import { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";
import { 
    getRoute, 
    generateRefContrat, 
    calculProrata, 
    getApporteurDolibarr, 
    getApporteurName, 
    getSauvegardes } from "./FunctionsContrat";
import { getInitialValues, getValidationSchema } from "./DatasFormik";
import FormContrats from "./forms/FormContrats";
import FormsContratLocation from "./forms/FormsContratLocation";
import { getLastNumberCompteurAndRef, saveLastNb } from "./RefContrats";
import { getDataSociete } from "../../repository/Societe";
import { getApporteurId } from "./FunctionsContrat";
import { getDataDolibar } from "../../repository/Dolibar";
import { getDataPrixFacturation } from "../../repository/PrixFacturation";
import { getDataContratTypeRegie } from "../../repository/contrats/ContratTypeRegie";
import { getDataContratTypeLocation } from "../../repository/contrats/ContratTypeLocation";
import { getDataSocieteProduitService } from "../../repository/SocieteProduiService";
import { putDataContrat, saveDataContrat } from "../../repository/contrats/Contrat";
import { createToast } from "../../utils/toast";
import { BEEMO_PRODUIT_DOLIBARS, LOCATION_PRODUIT_DOLIBARS } from "../../utils/constants";
import { is } from "../../utils/function";

const FormModalAddContrat = ({row = null, typeContrat, nomContrat, setRow, setShowModal}) => {
    const requestHelper = new RequestHelper();
    const [societes, setSocietes] = useState([]);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("Aucun fichier sélectionné");
    const [rapporteur, setRapporteur] = useState(false);
    const [idApporteur, setIdApporteur] = useState(null);
    const [produitsServices, setProduitsServices] = useState([]);
    const [prixFacturation, setPrixFacturation] = useState([]);
    const [selectedSociete, setSelectedSociete] = useState(null);
    const [sauvegardes, setSauvegardes] = useState([]);
    const [typeRegies, setTypeRegies] = useState([]);
    const [typeLocations, setTypeLocations] = useState([]);
    const [actifsClient, setActifsClient] = useState([]);
    let nb = 0;
    let id = 0;
    let title = 'Ajouter un nouveau contrat :: ' + nomContrat ;
    let titleButton = "Ajouter";
    if(row !== null){
        title = 'Mettre à jour le contrat :: ' + nomContrat + ' - ' + row.societe.name;
        titleButton = "Mettre à jour";
    }
    const labelPrice = (typeContrat === TYPE_CONTRATS.CONTRAT_MAINTENANCE) ? "Prix HT/heure" : 
        (typeContrat === TYPE_CONTRATS.CONTRAT_HEBERGEMENT) ? "Prix HT/mensuel" : "Prix HT/annuel";

    const formik = useFormik({
        initialValues: getInitialValues(row, typeContrat, idApporteur),
        validationSchema: getValidationSchema(typeContrat, rapporteur),
        onSubmit: values => {
            row === null
                ? postContrat(values, getRoute(typeContrat))
                : updateContrat(values, getRoute(typeContrat));
        }
    });

    /**
     * Updates the contrat with the given values and route.
     *
     * @param {object} values - The values to update the contrat with.
     * @param {string} route - The route to update the contrat.
     */
    function updateContrat(values, route){
        if(typeContrat === TYPE_CONTRATS.CONTRAT_HEBERGEMENT || typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES){  
            values.apporteurDolibarr = (values.apporteur && values.apporteurName !== null) 
                ? getApporteurDolibarr(values.apporteurName, societes) 
                : 0;
            values.apporteurName = (values.apporteur && values.apporteurName !== null) 
                ? getApporteurName(values.apporteurName, societes) 
                : null;
            values.apporteurMontant = (values.apporteur) ? values.apporteurMontant : 0;
            values.commission = (values.apporteur) ? true : false;
        }

        if(typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES || typeContrat === TYPE_CONTRATS.CONTRAT_REGIE){
            values.dolProduct = (values.dolProduct !== null && values.dolProduct !== "") ? values.dolProduct : null;
        }

        if(typeContrat === TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE){
            values.societeProduitServiceSauvegarde = (values.societeProduitServiceSauvegarde !== null && values.societeProduitServiceSauvegarde !== "") 
            ? values.societeProduitServiceSauvegarde 
            : null;
        }

        if(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION){
            values.devisDolibarr = (values.devisDolibarr !== null && values.devisDolibarr !== "") ? values.dolProduct : null;
            values.contratTypeOffre = (values.contratTypeOffre !== null && values.contratTypeOffre !== "") ? values.contratTypeOffre : null;
            values.fournisseur = (values.fournisseur !== null && values.fournisseur !== "") ? values.fournisseur : null;
            values.locationProduitDolibars.map((produit) => {
                delete produit.id;
            });
        }

        if(fileName !== "Aucun fichier sélectionné"){
                values.fileName = fileName;
        }
      
        if(typeContrat === TYPE_CONTRATS.CONTRAT_REGIE){
            gestionRegieMultiple(values);
            values.contratId = row.id;
        } 

        if(typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO){
            values.beemoProduitDolibars.map((produit) => {
                delete produit.id;
            });
        }

        const isSaved = (results) => {
            setRow(results);
            setShowModal(false);
        };
      
        let isValide = true;
        if(typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO || typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION){
            isValide = validModeles(values)
        }
        if(isValide){
            putDataContrat(
                values, 
                "Une erreur est survenue lors de la modification du contrat", 
                `${route}/${row.id}`, 
                isSaved
            );
        }
    }

    /**
     * Posts a new contrat with the provided values and route.
     * 
     * @param {object} values - The values for the contrat.
     * @param {string} route - The route for saving the contrat.
     * @returns {Promise<void>} - A promise that resolves when the contrat is saved.
     */
    async function  postContrat(values, route){
        if(typeContrat === TYPE_CONTRATS.CONTRAT_MAINTENANCE){
            values.refContrat = generateRefContrat(values.dateSignature, values.societe);
            values.creditConsomme = 0;
            values.decompteTemps = 0;
            values.prorata = calculProrata(values.dateDebut, values.creditContrat);
            values.isFactured = false;
            values.isClotured = false;
            values.isPaused = false;
            values.totalSolde = values.creditContrat + values.prorata;
            values.notifyBetweenT1 = false;
            values.notifyBetweenT2 = false;
            values.notifyBetweenT3 = false; 
        }

        // génération de la référence du contrat
        if(typeContrat !== TYPE_CONTRATS.CONTRAT_MAINTENANCE){
            const result =  await getLastNumberCompteurAndRef(typeContrat, requestHelper);
            if(result === null) return;
            nb = result[0];
            id = result[2];
            values.refContrat = result[1] + (nb + 1);
        }
    

        if(typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES || typeContrat === TYPE_CONTRATS.CONTRAT_REGIE){
            values.dolProduct = (values.dolProduct !== null && values.dolProduct !== "") ? values.dolProduct : null;
        }

        if(typeContrat === TYPE_CONTRATS.CONTRAT_HEBERGEMENT || typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES){
            values.apporteurDolibarr = (rapporteur) ? getApporteurDolibarr(idApporteur, societes) : 0;
            values.apporteurName = (rapporteur) ? getApporteurName(idApporteur, societes) : null;
            values.commission = (rapporteur) ? true : false;
            
        }
        
        if(fileName !== "Aucun fichier sélectionné"){
            values.fileName = fileName;
        }

        if(typeContrat === TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE && values.societeProduitServiceSauvegarde === ""){
            values.societeProduitServiceSauvegarde = null;
        }

        if(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION){
            values.dateFin = moment(values.dateDebut).add(-1, 'days').add(3, 'years').format("YYYY-MM-DD");
            values.dateAnniversaire = moment(values.dateDebut).add(3, 'years').format("YYYY-MM-DD");
            values.devisDolibarr = (values.devisDolibarr !== null && values.devisDolibarr !== "") ? values.dolProduct : null;
            values.contratTypeOffre = (values.contratTypeOffre !== null && values.contratTypeOffre !== "") ? values.contratTypeOffre : null;
            values.fournisseur = (values.fournisseur !== null && values.fournisseur !== "") ? values.fournisseur : null;
            gestionProduitDolibars(values, typeContrat);

        } 
        
        if(typeContrat === TYPE_CONTRATS.CONTRAT_REGIE){
            gestionRegieMultiple(values)
        } 

        if(typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO){
            gestionProduitDolibars(values, typeContrat);
        }

        // en commun
        values.isDeleted = false;
        values.contratConfig = '/api/contrat-configs/' + typeContrat;
        const isSaved = (results) => {
            setRow(results);
            setShowModal(false);
            if(id !== 0) saveLastNb(requestHelper, id, (nb + 1));
        };
        
        let isValide = true;
        if(typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO || typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION){
            isValide = validModeles(values)
        } 
        
        if(isValide){
            saveDataContrat(
                values, 
                "Une erreur est survenue lors de l'ajout du contrat", 
                route,
                isSaved
            );
        }
    }

    /**
     * Handles the management of multiple regies in the form.
     *
     * @param {Object} values - The form values.
     */
    const gestionRegieMultiple = (values) => {
        const regies = values.contratSocieteRegieContratTypeRegies;
        const datas = []

        regies.forEach((regie) => {
            const prix = values[regie];
            datas.push({contratTypeRegie: regie, price: prix ? prix : null});
        });
        delete values["contratSocieteRegieContratTypeRegies"];

        values.csrctr = datas;
    }

    /**
     * Updates the gestionProduitDolibars array with the provided values.
     *
     * @param {Object} values - The values to update the gestionProduitDolibars array.
     */
    const gestionProduitDolibars = (values, typeContrat) => {
        const typeProduitsDolibars = typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO  ? BEEMO_PRODUIT_DOLIBARS : LOCATION_PRODUIT_DOLIBARS;
        const produitDolibars = values[typeProduitsDolibars];
        produitDolibars.forEach((produit) => {
            const id = produit.idDolibar;
            produit.quantite = values[id + '_quantite'] ?? null;
            produit.prix = values[id + '_prix'] ?? null;
            produit.referenceDolibar = produit.ref;
            produit.designationDolibar = produit.name;
            delete produit.id;
            if(typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO){
                produit.quantiteExtern = values[id + '_quantiteExtern'] ?? null;
                produit.prixExtern = values[id + '_prixExtern'] ?? null;
                produit.tailleExtern = values[id + '_tailleExtern'] ?? null;
            }
        });
    }

    /**
     * Validates the modeles array by checking if each produit has a valid quantite and prix.
     * If any produit is invalid, it displays an error toast message.
     *
     * @param {Array} values - The array of modeles to be validated.
     * @returns {boolean} - Returns true if all modeles are valid, false otherwise.
     */
    const validModeles = (values, typeContrat) => {
        const typeProduitsDolibars = typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO  ? BEEMO_PRODUIT_DOLIBARS : LOCATION_PRODUIT_DOLIBARS;
        let valid = true;
        if(values[typeProduitsDolibars]) {
            const produitDolibars = values[typeProduitsDolibars];
            produitDolibars.forEach((produit) => {
                if(isNaN(produit.quantite) || produit.quantite ===null  || isNaN(produit.prix)  || produit.prix === null){
                    createToast("error", "Veuillez renseigner la quantité et le prix pour le modèle " + produit.name, 6000);
                    valid = false;
                }
            });
        }

        return valid;
    }

    useEffect(() => {
        if(societes.length === 0){
            getDataSociete(
                {isEnabled: true, pagination: "disabled" }, 
                setSocietes, 
                "Une erreur est survenue lors de la récupération des tiers", 
                _ROUTES.SOCIETE
            );
            if(societes?.length > 0 && row !== null && ( !_.isUndefined(row.apporteurDolibarr) && row.apporteurDolibarr !== 0 && row.apporteurDolibarr !== null)) {
                setIdApporteur(getApporteurId(row.apporteurDolibarr, societes));
            }
        }
        if((typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES || typeContrat === TYPE_CONTRATS.CONTRAT_REGIE || TYPE_CONTRATS.CONTRAT_BEEMO) && produitsServices.length === 0)
            getDataDolibar(
                {}, 
                setProduitsServices,
                "Une erreur est survenue lors de la récupération des produits et services", 
                _ROUTES.DOL_PRODUCTS_LIST
            );

        if(typeContrat === TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE && prixFacturation.length === 0)
            getDataPrixFacturation(
                {}, 
                setPrixFacturation, 
                "Une erreur est survenue lors de la récupération des prix de facturation", 
                _ROUTES.PRIX_FACTURATION
            );
        if(typeContrat === TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE  && row !== null)
            getDataSociete(
                {}, 
                setSelectedSociete, 
                "Une erreur est survenue lors de la récupération de la société", 
                _ROUTES.SOCIETE + '/' + row.societe.id
            );
        if(typeContrat === TYPE_CONTRATS.CONTRAT_REGIE  && typeRegies.length === 0)
            getDataContratTypeRegie(
                {actif: true}, 
                setTypeRegies, 
                "Une erreur est survenue lors de la récupération des types de régie", 
                _ROUTES.TYPES_REGIE
            );
        if(typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION  && typeLocations.length === 0)
            getDataContratTypeLocation(
                {}, 
                setTypeLocations, 
                "Une erreur est survenue lors de la récupération des types de contrat location", 
                _ROUTES.TYPES_LOCATION
            );
        if(typeContrat === TYPE_CONTRATS.CONTRAT_HEBERGEMENT && row !== null){
            getDataSocieteProduitService(
                {societe: row.societeId, produitService: 8}, 
                setActifsClient, 
                "Une erreur est survenue lors de la récupération des actifs clients", 
                _ROUTES.SOCIETE_PRODUIT_SERVICE
            );
        }
    }, []);

    useEffect(() => {
        if(typeContrat === TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE && selectedSociete !== null){
            setSauvegardes(getSauvegardes(selectedSociete));
        }
        if(typeContrat === TYPE_CONTRATS.CONTRAT_HEBERGEMENT && selectedSociete !== null){
            getDataSocieteProduitService(
                {societe: selectedSociete?.id, produitService: 8}, 
                setActifsClient, 
                "Une erreur est survenue lors de la récupération des actifs clients", 
                _ROUTES.SOCIETE_PRODUIT_SERVICE
            );
        }
    }, [selectedSociete]);

    /**
     * Handles the change event when a file is selected.
     * @param {Event} e - The change event object.
     */
    const handleFileChange = (e) => {
        const file = e.target?.files[0];
        if (!file) {
          setFileName("Aucun fichier sélectionné");
          setFile(null);
          return;
        }
        setFile(file);
        setFileName(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setFile(reader.result);
        };
        reader.readAsDataURL(file);
      };

    return (
        <>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <h2 style={{fontSize: '3em'}}>{title}</h2>
            <form onSubmit={formik.handleSubmit}>
                {(typeContrat !== TYPE_CONTRATS.CONTRAT_LOCATION)&& (
                    <FormContrats 
                        formik={formik} 
                        typeContrat={typeContrat} 
                        societes={societes} 
                        selectedSociete={selectedSociete}
                        setSelectedSociete={setSelectedSociete} 
                        rapporteur={rapporteur}
                        typeRegies={typeRegies}
                        labelPrice={labelPrice}
                        produitsServices={produitsServices}
                        sauvegardes={sauvegardes}
                        setRapporteur={setRapporteur}
                        setIdApporteur={setIdApporteur}
                        idApporteur={idApporteur}
                        handleFileChange={handleFileChange}
                        prixFacturation={prixFacturation}
                        row={row}
                        actifsClient={actifsClient}
                    />
                )}
                {typeContrat === TYPE_CONTRATS.CONTRAT_LOCATION && (
                    <FormsContratLocation 
                        requestHelper={requestHelper}
                        formik={formik} 
                        typeContrat={typeContrat}
                        typeLocations={typeLocations}
                        societes={societes} 
                        setSelectedSociete={setSelectedSociete} 
                        selectedSociete={selectedSociete}
                        row={row}
                        handleFileChange={handleFileChange}
                        produitsServices={produitsServices}
                    />
                )}
                <Button type="submit" sx={{ marginTop: 2}} color="success" variant="contained">{titleButton}</Button>  
            </form>
        </LocalizationProvider>
        </>
    );
};

export default FormModalAddContrat;