import { Autocomplete, FormControl, TextField } from "@mui/material";
import { __FORM_STYLES } from "../../utils/_style_constants";
import { editInstantRequest } from "../../utils/function";
import { createToast } from "../../utils/toast";
import _, { indexOf } from "lodash";
import { useEffect, useState } from "react";

const _SELECT_DEFAULT = "Veuillez selectionner une option";

/**
 * InstantEditAutocomplete is a component that renders an autocomplete input field with instant edit functionality.
 *
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered JSX element.
 */
export const InstantEditAutocomplete = ({
  entityValue, // The current value of the entity
  setEntityValue, // Function to update the entity value
  label, // The label for the autocomplete input
  field, // The field of the entity to be edited
  options, // The options for the autocomplete input
  optionLabel, // The field of the option objects to be displayed as the option label
  refresh, // A value used to trigger a refresh of the component
  setRefresh, // Function to update the refresh value
  relation = false, // Boolean indicating whether the field is a relation field
  disabled = false, // Boolean indicating whether the autocomplete input is disabled
  edit = true, // Boolean indicating whether instant edit is enabled
}) => {
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    if (entityValue && !_.isUndefined(entityValue[field])) {
      const initialValue = relation
        ? entityValue[field]
          ? entityValue[field]["@id"]
          : "" // If relation is true, get the @id property of the field value
        : entityValue[field];
      setInitialValue(initialValue);
    }
  }, [entityValue]);

  return (
    <FormControl sx={__FORM_STYLES.basicInput} size="small">
      <Autocomplete
        disabled={disabled}
        size="small"
        /* data */
        options={options}
        /* display label */
        getOptionLabel={(option) => {
          // If the option has a property matching optionLabel, return it; otherwise, return _SELECT_DEFAULT
          return option[optionLabel] ?? _SELECT_DEFAULT;
        }}
        value={entityValue ? entityValue[field] : ""}
        onChange={(e, value) => {
          // On change, update the entity value based on the new input value
          setEntityValue({
            ...entityValue,
            [field]: value ? value : null,
          });
        }}
        onBlur={(e) => {
          if (!edit) {
            // If edit is false, do nothing
            return;
          }
          let value = e.target.value;

          // If the value is empty or equals _SELECT_DEFAULT, set it to null
          if (_.isEmpty(value) || value === _SELECT_DEFAULT) {
            value = null;
          }

          // If relation is true and value is truthy, find the option with a matching optionLabel and set value to its @id
          if (relation && value) {
            const el = options.find((o) => o[optionLabel] === e.target.value);
            value = el["@id"];
          }

          if (_.isNull(value) || _.isEqual(value, initialValue)) {
            // If the new value is equal to the initial value, do nothing
            return;
          }
          // Make an instant edit request
          editInstantRequest(value, entityValue["@id"], field).then((res) => {
            if (indexOf(res, "@id")) {
              // If the request is successful, display a success toast
              createToast("success", "Modification enregistrée");
            }
            if (!_.isUndefined(refresh)) {
              // If refresh is not undefined, increment it by 1
              setRefresh(refresh + 1);
            }
          });
        }}
        renderInput={(params) => (
          // Render a TextField input with the provided label and params
          <TextField name={label} label={label} sx={{}} {...params} />
        )}
      />
    </FormControl>
  );
};

export default InstantEditAutocomplete;
