import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomButtonCatalogue from "../utils/CustomButtonCatalogue";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import Badge from "@mui/material/Badge";
import { _ROUTES } from "../../utils/_routes_constants";
import { ActifsConfigurationsContext } from "../../contexts/ActifsConfigurationsContext";
import { createToast } from "../../utils/toast";
import { _ACTIONS, _COLORS, iconsPS } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import SearchSocieteInSocieteProduitService from "./SearchSocieteInSocieteProduitService";
import { getDataSocieteProduitService } from "../../repository/SocieteProduiService";
import { _POSTE_NOT_ASSOCIED } from "../../utils/actif-config/actif_configuration_constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const FormFilerActifConfig = () => {
  const rq = new RequestHelper();
  const [parentPS, setParentPS] = useState();
  const [nonAssocies, setNonAssocies] = useState({
    gsuiteNonAssocies: null,
    posteNonAssocies: null,
  });
  const navigate = useNavigate();
  const {
    selectedProduitService,
    setSelectedProduitService,
    societeId,
    setSocieteId,
    setIsLoading,
    setResponseActif,
    refresh,
    pagination,
    setRowCount,
    sorting,
    globalFilter,
  } = useContext(ActifsConfigurationsContext);
  // UTILS
  const produitServiceId = useParams().id;
  const paramsPSName = useLocation().state?.name;

  /**
   *  Fonction pour obtenir l'actif par le slug ou produitServiceId présent dans l'url
   *
   * @param {string} paramsPSName - The name of the produit service.
   * @param {number} produitServiceId - The ID of the produit service.
   */
  const getActifsFromDashboardGeneral = (paramsPSName, produitServiceId) => {
    if (parentPS) {
      const item = parentPS.find(
        (item) =>
          item.name === paramsPSName ||
          item.produitServiceId === parseInt(produitServiceId)
      );

      if (item) {
        setSelectedProduitService({
          id: item.produitServiceId,
          name: item.name,
        });
      }
    }
  };

  useEffect(() => {
    if (paramsPSName === "posteNotAssocied") {
      setSelectedProduitService({
        id: _POSTE_NOT_ASSOCIED,
        name: "Poste non catégorisé",
      });
    } else if (parentPS && parentPS.length > 0) {
      getActifsFromDashboardGeneral(paramsPSName, produitServiceId);
    }
  }, [paramsPSName, parentPS]);

  useEffect(() => {
    fetchNotificationCount(societeId);
  }, [societeId]);

  /* Set the selected PS in the cookie */
  useEffect(() => {
    handleSearch(selectedProduitService.id);
  }, [
    selectedProduitService,
    societeId,
    refresh,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    globalFilter,
  ]);

  /**
   * Fetches the notification count for a given company ID.
   * If no company ID is provided, it fetches the notification count for all companies.
   *
   * @param {string} idSociete - The ID of the company (optional)
   * @returns {Promise<number>} - The notification count
   */
  const fetchNotificationCount = async (idSociete) => {
    try {
      setSocieteId(idSociete);
      const url = idSociete
        ? `get-societe-produit-service-count/${idSociete}`
        : `get-societe-produit-service-count`;

      const response = await rq.doRequest(_ACTIONS.GET, url);
      if (200 !== response.status) {
        throw new Error(
          "Erreur lors de la récupération du nombre de notifications"
        );
      }
      const tmpData = response.data.produitService;
      setParentPS(tmpData);
      setNonAssocies({
        gsuiteNonAssocies: response.data.gsuiteNonAssocies,
        posteNonAssocies: response.data.posteNonAssocies,
      });
    } catch (error) {
      createToast("error", error.message);

      return 0;
    }
  };

  /**
   * Constructs the query parameters object for filtering active configurations.
   *
   * @param {string} id - The ID of the product service.
   * @param {string} societeId - The ID of the company.
   * @param {object} pagination - The pagination configuration.
   * @param {array} sorting - The sorting configuration.
   * @returns {object} - The constructed query parameters object.
   */
  const queryParams = (id, societeId, pagination, sorting) => {
    let queryData = {};
    if (id === _POSTE_NOT_ASSOCIED) {
      queryData["exists[produitService]"] = false;
    } else {
      queryData.produitService = id;
    }

    /* produitService */
    if (id !== _POSTE_NOT_ASSOCIED) {
      queryData = {
        ...queryData,
        produitService: id,
      };
    }
    /* societe */
    if (societeId) {
      queryData = {
        ...queryData,
        societe: societeId,
      };
    }
    /* global filters */
    if (globalFilter) {
      queryData = {
        ...queryData,
        societeProduitServicefilter: globalFilter,
      };
    }
    /* pagination */
    queryData = {
      ...queryData,
      page: pagination.pageIndex + 1,
      itemsPerPage: pagination.pageSize,
    };
    /* sorting */
    if (sorting) {
      sorting.forEach((sort) => {
        const key = "order[" + sort.id + "]";
        queryData[key] = sort.desc ? "desc" : "asc";
      });
    }

    return queryData;
  };

  /**
   * Handles the search for a specific id.
   * @param {number} id - The id to search for.
   */
  const handleSearch = (id) => {
    const queryData = queryParams(id, societeId, pagination, sorting);
    const actions = (data) => {
      setResponseActif(data);
      setIsLoading(false);
      setRowCount(data);
    };
    setIsLoading(true);
    getDataSocieteProduitService(
      queryData,
      actions,
      "Erreur de récupération des actifs",
      _ROUTES.SOCIETE_PRODUIT_SERVICE
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          borderRadius: "1px",
          boxShadow: `0px 0px 2px ${_COLORS.grey} `,
        }}
        mt={4}
      >
        <SearchSocieteInSocieteProduitService />
        <Grid container spacing={2} p={1}>
          <Grid item xs={12}>
            <Box display={"flex"} gap={1} flexWrap={"wrap"}>
              {parentPS &&
                parentPS.map((item) => {
                  const matchedIcon = iconsPS.find(
                    (icon) => icon.name === item.name
                  );
                  const iconToUse = matchedIcon ? (
                    matchedIcon.icon
                  ) : (
                    <RoomPreferencesIcon />
                  );

                  return (
                    <Badge
                      badgeContent={item.count}
                      color="success"
                      key={item.produitServiceId}
                    >
                      <CustomButtonCatalogue
                        title={item.name}
                        icon={iconToUse}
                        onClick={() => {
                          setSelectedProduitService({
                            id: item.produitServiceId,
                            name: item.name,
                          });
                          navigate(`/actifs_configuration/list`);
                        }}
                      />
                    </Badge>
                  );
                })}
              {/* POSTE NOT ASSOCIED */}
              {nonAssocies?.posteNonAssocies?.count > 0 && (
                <Badge
                  badgeContent={nonAssocies.posteNonAssocies.count}
                  color="error"
                >
                  <CustomButtonCatalogue
                    title={"Postes non catégorisés"}
                    icon={<RoomPreferencesIcon />}
                    onClick={() =>
                      setSelectedProduitService({
                        id: _POSTE_NOT_ASSOCIED,
                        name: "Postes non catégorisés",
                      })
                    }
                  />
                </Badge>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FormFilerActifConfig;
