import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { LuUserSquare } from "react-icons/lu";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { _COLORS, _DOLIBARR } from "../../utils/constants";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS } from "../../utils/constants";
import AddIcon from "@mui/icons-material/Add";
import BasicModal from "../helpers/BasicModal";
import FormTitle from "../helpers/FormTitle";
import DecompteTempsPersonnalise from "../../screens/decompte-temps/DecompteTempsPersonnalise";
import { createToast } from "../../utils/toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Loader from "../utils/Loader";
import SiteGeographiqueModal from "./modals/SiteGeographiqueModal";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";
import InfoIcon from "@mui/icons-material/Info";
import Editor from "ckeditor5-custom-build";

/**
 * It provides a form to create or update tiers (clients, fournisseurs).
 *
 * @returns {JSX.Element} The FormTiers component.
 */
const FormTiers = () => {
  /* States */
  const [tiers, setTiers] = useState([]);
  const [tiersList, setTiersList] = useState([]);
  const [referents, setReferents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
  });
  /* Variables */
  const params = new useParams();
  const navigate = useNavigate();
  const rQ = new RequestHelper();
  const isFournisseur = params.isFournisseur == "fournisseurs" ? true : false;
  const url = isFournisseur ? _ROUTES.FOURNISSEUR : _ROUTES.SOCIETE;

  useEffect(() => {
    rQ.doRequest(_ACTIONS.GET, "referent_toolin")
      .then((res) => {
        if (200 !== res.status) {
          throw new Error(
            "Une erreur est survenue lors de la récupération des référents Toolin"
          );
        }
        setReferents(res.data);
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  }, []);

  const handleCheckboxChange = (event, field) => {
    const { checked } = event.target;
    formik.setFieldValue(field, checked);
    rQ.doRequest(_ACTIONS.PUT, `${url}/${params.id}`, {
      [field]: checked,
    });
  };

  const openModalWithContent = (title, content) => {
    setShowModal(true);
    setModalContent({
      title,
      content,
    });
  };

  useEffect(() => {
    rQ.doRequest(_ACTIONS.GET, `${url}/${params.id}`)
      .then((res) => {
        setTiers(res.data);
        if (200 !== res.status) {
          throw new Error(
            "Une erreur est survenue lors de la récupération de la société"
          );
        }
      })
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  }, []);

  useEffect(() => {
    searchSociete();
  }, []);

  async function searchSociete(value) {
    try {
      const response = await rQ.doRequest(_ACTIONS.GET, `${url}`, {
        filialeBoolean: false,
        name: !value ? "" : value,
      });
      if (response.status !== 200) {
        throw new Error(
          "Une erreur est survenue lors de la récupération des sociétés"
        );
      }
      setTiersList(response.data["hydra:member"]);
    } catch (error) {
      createToast("error", error.message);
    }
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required("Le champs Tiers est requis"),
    adresse: yup.string().required("Une adresse est requise"),
    codePostal: yup.string().required("Un code postal est requis"),
    ville: yup.string().required("Une ville est requise"),
    pays: yup.string().required("Un pays est requis"),
    email: yup.string().nullable(),
    phone: yup.string().nullable(),
    commentaire: yup.string().nullable(),
    decompteTempsPersonnaliseBool: yup
      .bool()
      .required("Un décompte temps est requis"),
    aliasToolin: yup.string().nullable(),
    lastAuditDate: yup.string().nullable(),
    keeperVault: yup.string().nullable(),
    responsableDecision: yup.string().nullable(),
    filiale: yup.string().nullable(),
    filialeBoolean: yup.bool().nullable(),
    ...(isFournisseur
      ? {
          domains: yup.array().of(yup.string()),
        }
      : {
          domains: yup.string().nullable(),
        }),
  });

  const initialValues = (tiers) => {
    return {
      name: "",
      referent: "",
      ...(isFournisseur ? { domains: [] } : { domains: "" }),
      folderDrive: "",
      adresse: "",
      codePostal: "",
      ville: "",
      pays: "",
      email: "",
      phone: "",
      commentaire: "",
      decompteTempsPersonnaliseBool: false,
      aliasToolin: "",
      lastAuditDate: moment(new Date()),
      keeperVault: "",
      responsableDecision: "",
      filiale: tiers.filiale,
      filialeBoolean: false,
      ...tiers,
    };
  };

  const formik = useFormik({
    initialValues: initialValues(tiers),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = {
        name: values.name,
        domains: isFournisseur ? values.domains : values.domains,
        adresse: values.adresse,
        codePostal: values.codePostal,
        ville: values.ville,
        pays: values.pays,
        email: values.email,
        phone: values.phone,
        commentaire: values.commentaire,
        decompteTempsPersonnaliseBool: values.decompteTempsPersonnaliseBool,
        isEnabled: true,
      };
      if (!isFournisseur) {
        data.referent = values ? "/api/users/" + values.referent : null;
        data.folderDrive = values.folderDrive;
        data.aliasToolin = values.aliasToolin;
        data.lastAuditDate = values.lastAuditDate;
        data.keeperVault = values.keeperVault;
        data.responsableDecision = values.responsableDecision;
        data.filiale = values.filiale;
        data.filialeBoolean = values.filialeBoolean;
      }
      rQ.doRequest(_ACTIONS.PUT, `${url}/${params.id}`, data)
        .then((res) => {
          if (200 !== res.status) {
            throw new Error(
              "Une erreur est survenue lors de la modification de la société"
            );
          }
          createToast("success", "La société a été modifiée avec succès");
        })
        .catch((error) => {
          createToast("error", error.message);
        });
    },
  });

  useEffect(() => {
    if (dataLoaded) {
      const updatedValues = {
        ...tiers,
        lastAuditDate: tiers.lastAuditDate && moment(tiers.lastAuditDate),
        referent: tiers?.referent?.id,
        ...(tiers.filiale && tiers.filiale["@id"]
          ? { filiale: tiers.filiale["@id"] }
          : {}),
      };

      formik.setValues(initialValues(updatedValues));
    }
  }, [tiers, formik.setValues, dataLoaded]);

  if (!dataLoaded) {
    return <Loader />;
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Grid>
          <FormTitle
            title="Gestion des tiers"
            children=<Typography variant="body" sx={{ color: _COLORS.grey }}>
              clients, fournisseurs, particuliers
            </Typography>
          />
        </Grid>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              backgroundColor: _COLORS.defaultBlue,
              borderBlockStartColor: "2px solid #3c8dbc",
              color: "white",
              width: "100%",
              alignItems: "center",
              height: "3rem",
              borderRadius: 1,
              mb: 2,
              paddingLeft: 2,
            }}
          >
            <InfoOutlinedIcon sx={{ marginRight: 1, color: "white" }} />
            <strong>{_DOLIBARR.m1}</strong>
          </Box>
        </Box>
        {/* Modal */}
        <BasicModal
          showModal={showModal}
          setShowModal={setShowModal}
          title={modalContent.title}
          content={modalContent.content}
        />
        {/* Formulaire */}
        <Container
          component="main"
          maxWidth={false}
          sx={{
            backgroundColor: "white",
            padding: "1rem",
            pb: "2rem",
            borderTop: `3px solid ${_COLORS.grey}`,
          }}
        >
          <Grid display={"flex"} gap={2} alignItems={"center"}>
            <LuUserSquare size={20} />
            {isFournisseur ? (
              <>
                <Typography variant="h6">Modifier le Fournisseur</Typography>
              </>
            ) : (
              <>
                <Typography variant="h6">Modifier le Client</Typography>
              </>
            )}
          </Grid>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <Container component="main" maxWidth="lg">
              <Grid container spacing={1} justifyContent="center" marginTop={5}>
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Tiers *
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Renseigner un tiers"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.name &&
                          !formik.values.name &&
                          Boolean(formik.errors.name)
                        }
                        helperText={
                          formik.touched.name && !formik.values.name
                            ? formik.errors.name
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  {!isFournisseur && (
                    <>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            variant="body1"
                            component="div"
                            fontWeight={"bold"}
                          >
                            Referent Toolin
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="referent" size="small">
                              Sélectionner un referent
                            </InputLabel>
                            <Select
                              labelId="referent"
                              id="referent"
                              name="referent"
                              value={formik.values.referent}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              {referents.map((referent) => (
                                <MenuItem key={referent.id} value={referent.id}>
                                  {referent.firstName + " " + referent.lastName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Domaine
                      </Typography>
                    </Grid>
                    <Grid item xs={isFournisseur ? 7 : 9}>
                      <TextField
                        fullWidth
                        label={
                          isFournisseur
                            ? "Renseigner les domaines séparés par une virgule"
                            : "Renseigner un domaine"
                        }
                        size="small"
                        id="domains"
                        name="domains"
                        value={
                          isFournisseur
                            ? formik.values.domains.join(", ")
                            : formik.values.domains
                        }
                        onChange={(event) => {
                          const value = isFournisseur
                            ? event.target.value
                                .split(",")
                                .map((domain) => domain.trim())
                            : event.target.value;
                          formik.setFieldValue("domains", value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    {isFournisseur && (
                      <Grid item xs={2}>
                        <IconButton
                          title="Liste des domaines utilisés par le fournisseur (séparés par des virgules)"
                          color="inherit"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {!isFournisseur && (
                  <>
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            variant="body1"
                            component="div"
                            fontWeight={"bold"}
                          >
                            Lien Drive
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Renseigner un lien Drive"
                            id="folderDrive"
                            name="folderDrive"
                            value={formik.values.folderDrive}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Adresse *
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Renseigner une adresse"
                        id="adresse"
                        name="adresse"
                        value={formik.values.adresse}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.adresse &&
                          formik.values.adresse &&
                          Boolean(formik.errors.adresse)
                        }
                        helperText={
                          formik.touched.adresse && formik.errors.adresse
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Code Postal *
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size="small"
                        id="codePostal"
                        label="Renseigner un code postal"
                        name="codePostal"
                        value={formik.values.codePostal}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.codePostal &&
                          formik.values.codePostal &&
                          Boolean(formik.errors.codePostal)
                        }
                        helperText={
                          formik.touched.codePostal && formik.errors.codePostal
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Ville *
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Renseigner une ville"
                        id="ville"
                        name="ville"
                        value={formik.values.ville}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.ville &&
                          formik.values.ville &&
                          Boolean(formik.errors.ville)
                        }
                        helperText={formik.touched.ville && formik.errors.ville}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Pays *
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size="small"
                        id="pays"
                        label="Renseigner un code postal"
                        name="pays"
                        value={formik.values.pays}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.pays &&
                          formik.values.pays &&
                          Boolean(formik.errors.pays)
                        }
                        helperText={formik.touched.pays && formik.errors.pays}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Email
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Renseigner un email"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Téléphone
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Renseigner un téléphone"
                        id="phone"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Commentaire
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <CKEditor
                        editor={Editor}
                        name="commentaire"
                        data={
                          formik.values.commentaire
                            ? formik.values.commentaire
                            : ""
                        }
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          formik.setFieldValue("commentaire", data);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!isFournisseur && (
                  <>
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            variant="body1"
                            component="div"
                            fontWeight={"bold"}
                          >
                            Alias Toolin
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Renseigner un alias Toolin"
                            id="aliasToolin"
                            name="aliasToolin"
                            value={formik.values.aliasToolin}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.aliasToolin &&
                              formik.values.aliasToolin &&
                              Boolean(formik.errors.aliasToolin)
                            }
                            helperText={
                              formik.touched.aliasToolin &&
                              formik.errors.aliasToolin
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            variant="body1"
                            component="div"
                            fontWeight={"bold"}
                          >
                            Coffre Keeper
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Renseigner un coffre Keeper"
                            id="keeperVault"
                            name="keeperVault"
                            value={formik.values.keeperVault}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.keeperVault &&
                              formik.values.keeperVault &&
                              Boolean(formik.errors.keeperVault)
                            }
                            helperText={
                              formik.touched.keeperVault &&
                              formik.errors.keeperVault
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            variant="body1"
                            component="div"
                            fontWeight={"bold"}
                          >
                            Décisonnaire
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Renseigner un décisionnaire"
                            id="responsableDecision"
                            name="responsableDecision"
                            value={formik.values.responsableDecision}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.responsableDecision &&
                              formik.values.responsableDecision &&
                              Boolean(formik.errors.responsableDecision)
                            }
                            helperText={
                              formik.touched.responsableDecision &&
                              formik.errors.responsableDecision
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            variant="body1"
                            component="div"
                            fontWeight={"bold"}
                          >
                            Dernier audit
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <DatePicker
                            fullWidth
                            label="Date de dernier audit"
                            size="small"
                            format="DD/MM/YYYY"
                            name="lastAuditDate"
                            value={formik.values.lastAuditDate}
                            defaultValue={moment(tiers.lastAuditDate)}
                            onChange={(newDate) => {
                              formik.setFieldValue(
                                "lastAuditDate",
                                moment(newDate)
                              );
                            }}
                            slots={{
                              openPickerIcon: EditCalendarRoundedIcon,
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                              },
                              openPickerButton: { color: "success" },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            variant="body1"
                            component="div"
                            fontWeight={"bold"}
                          >
                            Filiale
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={9}
                          spacing={2}
                          alignItems="center"
                        >
                          <Grid item xs={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.filialeBoolean}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "filialeBoolean")
                                  }
                                  name="filialeBoolean"
                                  title={`${tiers.name} est une filiale ?`}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          {formik.values.filialeBoolean && (
                            <Grid item xs={7}>
                              {tiersList && (
                                <Autocomplete
                                  fullWidth
                                  size="small"
                                  /* data */
                                  options={tiersList ? tiersList : []}
                                  /* display label */
                                  getOptionLabel={(option) =>
                                    option
                                      ? option.name
                                      : "Veuillez choisir une option"
                                  }
                                  value={
                                    tiersList.find(
                                      (option) =>
                                        option["@id"] === formik.values.filiale
                                    ) || ""
                                  }
                                  onChange={(e, value) => {
                                    if (value === null) {
                                      formik.setFieldValue("filiale", null);
                                    } else {
                                      formik.setFieldValue(
                                        "filiale",
                                        value["@id"]
                                      );
                                    }
                                  }}
                                  /* search */
                                  onInputChange={(e, value) => {
                                    setTimeout(() => {
                                      searchSociete(value);
                                    }, 500);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      label="Séléctionner une holding"
                                      fullWidth
                                      name="filiale"
                                      sx={{
                                        marginTop: "0px",
                                      }}
                                      onBlur={formik.handleBlur}
                                      {...params}
                                    />
                                  )}
                                />
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Décompte temps personnalisé
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              formik.values.decompteTempsPersonnaliseBool
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                "decompteTempsPersonnaliseBool"
                              )
                            }
                            name="isDecompteTempsPersonnalise"
                            color="primary"
                          />
                        }
                      />
                      {formik.values.decompteTempsPersonnaliseBool && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() =>
                            openModalWithContent(
                              "",
                              <DecompteTempsPersonnalise
                                isFournisseur={isFournisseur}
                              />
                            )
                          }
                          startIcon={<AddIcon />}
                          sx={{ color: _COLORS.buttonLightBlue }}
                        >
                          Ajouter
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={6} display={"flex"}>
                    <Button
                      size="small"
                      variant="contained"
                      color="inherit"
                      onClick={() => navigate(_ROUTER_DOM_URL.SOCIETE)}
                    >
                      Listes des tiers
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      sx={{ backgroundColor: _COLORS.buttonLightBlue }}
                    >
                      Enregistrer
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Link
                    color="inherit"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      openModalWithContent(
                        "Ajouter un site géographique",
                        <SiteGeographiqueModal
                          societe={tiers}
                          responsables={referents}
                        />
                      )
                    }
                  >
                    <Typography variant="body2">
                      Ajouter un site géographique
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </form>
        </Container>
      </LocalizationProvider>
    </>
  );
};

export default FormTiers;
