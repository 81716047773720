import { Typography } from "@mui/material";
import { isNull } from "lodash";
import moment from "moment";
import { DateFormatter } from "../../../../utils/muiTableHelper";
import { _DATE_FORMAT } from "../../../../utils/constants";
import { sizeColumnMuiTable } from "../../../../utils/actif-config/actif_configuration_constants";

const { sizeMediumText, sizeShortText, minSizeShortText, tinyText } =
  sizeColumnMuiTable;

/*
 * Columns for VPN usefull in the ResponseListCatalogue.jsx
 * @returns Array
 * */
export const vpnColumns = () => [
  {
    accessorKey: "societeProduitServiceOpenVpn.protocole",
    header: "Protocole",
    size: sizeShortText,
    enableSorting: false,
    Cell: ({ row }) => {
      const protocole = row.original.societeProduitServiceOpenVpn?.protocole;

      return <Typography>{isNull(protocole) ? "" : protocole}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceOpenVpn.vpnServeur",
    header: "Serveur VPN",
    size: minSizeShortText,
    enableSorting: false,
    Cell: ({ row }) => {
      const vpnServeur = row.original.societeProduitServiceOpenVpn?.vpnServeur;

      return <Typography>{isNull(vpnServeur) ? "" : vpnServeur}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceOpenVpn.port",
    header: "Port",
    size: tinyText,
    enableSorting: false,
    Cell: ({ row }) => {
      const ports = row.original.societeProduitServiceOpenVpn?.ports;

      return <Typography>{ports && ports.join("," || "")}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceOpenVpn.adresseVPN",
    header: "Adresse VPN",
    size: tinyText,
    enableSorting: false,
    Cell: ({ row }) => {
      const adresseVpn = row.original.societeProduitServiceOpenVpn?.adresseVpn;

      return <Typography>{isNull(adresseVpn) ? "" : adresseVpn}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceOpenVpn.ip",
    header: "IP",
    size: tinyText,
    enableSorting: false,
    Cell: ({ row }) => {
      const ip = row.original.societeProduitServiceOpenVpn?.adresseIp;

      return <Typography>{isNull(ip) ? "" : ip}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceOpenVpn.dns",
    header: "DNS",
    size: tinyText,
    enableSorting: false,
    Cell: ({ row }) => {
      const dns = row.original.societeProduitServiceOpenVpn?.dns;

      return <Typography>{isNull(dns) ? "" : dns}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceOpenVpn.gateway",
    header: "Gateway",
    size: sizeShortText,
    enableSorting: false,
    Cell: ({ row }) => {
      const gateway = row.original.societeProduitServiceOpenVpn?.gateway;

      return <Typography>{isNull(gateway) ? "" : gateway}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceOpenVpn.fullTunnel",
    header: "Full Tunnel",
    size: sizeMediumText,
    enableSorting: false,
    Cell: ({ row }) => {
      const fullTunnel = row.original.societeProduitServiceOpenVpn?.fullTunnel;

      return <Typography>{isNull(fullTunnel) ? "" : fullTunnel}</Typography>;
    },
  },
  {
    accessorKey: "societeProduitServiceOpenVpn.certificatDate",
    header: "Date Certificat",
    size: sizeMediumText,
    enableSorting: false,
    Cell: ({ row }) => {
      let momentDate = moment(
        row.original.societeProduitServiceOpenVpn?.certificatDate,
        _DATE_FORMAT.datetime_exact
      );

      return isNull(momentDate) ? (
        ""
      ) : (
        <DateFormatter momentDate={momentDate} />
      );
    },
  },
];
