import { DECOMPTE_TEMPS } from "./constants";

/* Obj for endpoints back-end */
export const _ROUTES = {
  HOME: "/",
  LOGIN: "/login_check",
  LOGOUT: "/logout",
  USER: "/users",
  UPDATE_USER: "/update_user",
  CREATE_USER: "/create_user",
  CREATE_TICKET: "/create-ticket",
  DASHBOARD_ALERTS: "/dashboard-alerts",
  SOCIETE: "/societes",
  CONTACT: "/contacts",
  CONTACT_FOURNISSEUR: "/contact-fournisseurs",
  RESSOURCES: "/resources",
  RESERVATION: "/reservations",
  PRODUIT_SERVICE: "/produit-services",
  PRODUIT_SERVICE_CONFIG: "/produit-service-configs",
  PRODUIT_SERVICE_CONFIG_VALUES: "/produit-service-config-values",
  PRODUIT_SERVICE_CONFIGURATION: "/produit-service-configurations",
  PRODUIT_SERVICE_API: "/produit-service-apis",
  DECOMPTE_TEMPS: "/decompte-temps",
  DECOMPTE_TEMPS_SOCIETE: "/decompte-temps-societes",
  DECOMPTE_TEMPS_PERSONNALISE: "/decompte-temps-societe-personnalises",
  SOCIETE_PRODUIT_SERVICE: "/societe-produit-services",
  SPS_HEBERGEMENT: "/societe-produit-service-hebergements",
  SPS_LOGICIEL: "/societe-produit-service-logiciels",
  SPS_ASTERISK: "/societe-produit-service-asterisks",
  SPS_OVH: "/societe-produit-service-ovhs",
  SOCIETE_PRODUIT_SERVICE_VADE_SECURE: "/societe-produit-service-vade-secures",
  SPS_OPEN_VPN: "/societe-produit-service-open-vpns",
  SPS_OPN_SENSE: "/societe-produit-service-opnsenses",
  TMP_ALSO: "/tmp-alsos",
  TMP_GLPI: "/tmp-glpis",
  TICKETS: "/tickets",
  TICKETS_STATES: "/tickets-state-total",
  TICKETS_LIST_FILTERS: "/tickets-list-filters",
  TICKETS_ORIGIN: "/ticket-origins",
  TICKETS_TYPE: "/ticket-types",
  TICKETS_STATUS: "/ticket-statuses",
  TICKET_INVOICE_TYPE: "/ticket-invoice-types",
  DOL_PRODUCT: "/purchase-product-ref",
  DOL_PRODUCTS_LIST: "/dolibar-product-list",
  DOL_DEVIS_SOCIETE: "/dolibar-societe-devis",
  DOL_DEVIS: "/dolibar-devis",
  DOL_PRODUCTS_DETAIL: "/dolibar-product-detail",
  TICKETS_OTHERS: "/tickets-others",
  SITE_GEOGRAPHIQUE: "/site-geographiques",
  UPLOAD_AVATAR: "/upload-avatar",
  ADD_TICKET_FILE: "/add-ticket-file",
  TICKET_FILE: "/ticket-files",
  TICKET_PURCHASE: "/ticket-purchases",
  ACTION_TYPE: "/ticket-action-types",
  CONTRAT_TYPES: "/contrat-configs",
  CONTRAT_MAINTENANCE: "/contrat-societe-maintenances",
  CONTRAT_HEBERGEMENT: "/contrat-societe-infogerences",
  CONTRAT_AUTRE: "/contrat-societe-autres",
  CONTRAT_TELESAUVEGARDE: "/contrat-societe-telesauvegardes",
  CONTRAT_REGIE: "/contrat-societe-regies",
  CONTRAT_LOCATION: "/contrat-societe-locations",
  CONTRAT_BEEMO: "/contrat-societe-beemos",
  PRIX_FACTURATION: "/prix-facturations",
  ALL_CONTRACTS: "/tickets-contracts",
  ADD_ACTION_FILE: "/add-action-file",
  ADD_TICKET_ACTION: "add-ticket-action",
  EDIT_TICKET_ACTION: "/edit-ticket-action",
  TICKET_ACTIONS: "/ticket-actions",
  BASE_CONNAISSANCE_CATEGORIES: "/base-connaissances-categories",
  BASE_CONNAISSANCE_SECTION: "/base-connaissances-sections",
  BASE_CONNAISSANCE_ARTICLES: "/base-connaissances-articles",
  BASE_CONNAISSANCE_TREE_VIEW: "/base-connaissance-tree-view",
  BASE_CONNAISSANCE_SECTIONS_GROUPED_CATEGORIES: "/bs-sections-form",
  BS_SAVE_ARTICLE: "/bs-store-article",
  SOCIETE_BITDEFENDER: "/societe-produit-service-bitdefenders",
  SOCIETE_BITDEFENDER_LICENCE: "/get-licence-detail",
  SOCIETE_BITDEFENDER_USAGE: "/get-monthly-usage",
  SOCIETE_BITDEFENDER_ENDPOINT: "/get-endpoint-list",
  SOCIETE_BITDEFENBDER_ENDPOINT_DETAILS: "/get-managed-endpoint-details",
  SOCIETE_BITDEFENDER_ENDPOINT_LIST: "/get-endpoint-infos",
  SOCIETE_BITDEFENDER_COLOR: "/get-client-color",
  TYPES_REGIE: "/contrat-type-regies",
  TYPES_LOCATION: "/contrat-type-locations",
  TYPE_OFFRE: "/contrat-type-offres",
  FOURNISSEUR: "/fournisseurs",
  COMPTEURS: "/compteurs-contrats",
  CONTRAT_MULTI_REGIES: "/contrat-societe-regie-contrat-type-regies",
  CLIENT_OVH: "/societe-produit-service-ovhs",
  NB_GROUP_OVH: "/get-telephony-groupes-count",
  GROUPS_OVH_DETAILS: "/get-telephony-groupes-details",
  GROUP_OVH_PHONEBOOK: "/get-telephony-groupes-phonebook",
  GROUP_OVH_COMSUMPTION: "/get-telephony-groupes-consumption",
  GROUP_OVH_NB_LIGNES: "/get-telephony-lines-count",
  GROUP_OVH_NB_NUMEROS: "/get-telephony-numbers-count",
  GROUP_OVH_NB_FAX: "/get-telephony-fax-count",
  GROUP_OVH_LINES_LIST: "/get-telephony-lines-list",
  GROUP_OVH_LINE_CREDENTIELS: "/get-telephony-lines-details",
  GROUP_OVH_UPGRADE_VOICEMAIL: "/upgrade-line-fax-voicemail",
  GROUP_OVH_NUMEROS_LIST: "/get-telephony-numbers-list",
  GROUP_OVH_FAX_LIST: "/get-telephony-fax-list",
  RECAP_OVH_TELEPHONIE: "/recap/telephonie-ovh",
  RECAP_OVH_TELEPHONIE_LINE: "/get-telephony-numbers-recap",
  RECAP_OVH_TELEPHONIE_LINE_DETAILS: "/get-telephony-numbers-details",
  RECAP_OVH_TELEPHONIE_QUEUE: "/get-telephony-queue",
  STAT_EQUIPE_DAY: "/reporting/ticket/activtity/day/response",
  STAT_EQUIPE_WEEK: "/reporting/ticket/activtity/week/response",
  STAT_EQUIE_WEEK_DETAILS: "/reporting/ticket/activtity/week/ticket/response",
  STAT_EQUIPE_MONTH: "/reporting/ticket/activtity/month/response",
  STAT_EQUIPE_YEAR: "/reporting/ticket/activtity/year/response",
};

/* Obj for router-dom */
export const _ROUTER_DOM_URL = {
  HOME: "/",
  DASHBOARD_GENERAL: "/dashboard-general",
  USER: "/utilisateur",
  USER_CREATE: "/utilisateur/create",
  USER_EDIT: "/utilisateur/edit",
  PRODUIT_SERVICE_LIST: "/produit-service/list",
  SOCIETE: "/societe-list",
  RESERVATION: "/reservations",
  RESERVATION_UPDATE_INTERNE: "/reservation-update-interne",
  RESERVATION_UPDATE_EXTERNE: "/reservation-update-externe",
  RESSOURCES_LIST: "/resource-list",
  RESSOURCE_FORM: "/resource-form",
  CONTACT_CREATE: "/contact/create",
  CONTACT_UPDATE: "/contact/update",
  TIERS_EDIT: "/tiers/edit",
  CONTACT_FOURNISSEUR_CREATE: "/contact_fournisseur/create",
  CONTACT_FOURNISSEUR_UPDATE: "/contact_fournisseur/update",
  CONTRATS: "/contrats",
  ADMIN_CONTACT: "/admin-contact",
  RECAP_CLIENT_ALSO: "/recapitulatif-client-also",
  CREATE_RESERVATION_INTERNE: "/reservation-form/interne",
  CREATE_RESERVATION_EXTERNE: "/reservation-form/externe",
  ACTIF_CONFIGURATION_LIST: "/actifs_configuration/list",
  ACTIF_CONFIGURATION_CREATE: "/actifs_configuration/create",
  ACTIF_CONFIGURATION_EDIT: "/actifs_configuration/edit",
  ACTIF_CONFIGURATION_GSUITE: "/actifs_configuration/users_gsuite",
  PRODUIT_SERVICE: "/produit-service",
  TICKET_EDIT: "/ticket-edit/",
  BASE_CONNAISSANCE: "/base-connaissance/dashboard",
  BASE_CONNAISSANCE_FORM: "/base-connaissance/",
  DASHBOARD: "/dashboard",
  TICKET_CREATE: "/ticket-create",
};
