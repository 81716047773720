import React, { useEffect, useState } from "react";
import { _ROUTES } from "../../../utils/_routes_constants";
import { Box } from "@mui/material";
import { MdOutlinePermIdentity } from "react-icons/md";
import { PiPasswordBold } from "react-icons/pi";
import { getDataOvh } from "../../../repository/telephonies/Ovh";

const IdendifiantInterfaceWeb = ({details}) => {
    const [credentiels, setDetailsLines] = useState([]);

    useEffect(() => {
        getDataOvh(
            {billingAccount: details?.id, line: details?.fkLigne}, 
            setDetailsLines, 
            "Une erreur est survenue lors de la récupération des détails de connexion", 
            _ROUTES.GROUP_OVH_LINE_CREDENTIELS
        );
    }, [details]);

    return(
        <>
            <Box sx={{fontSize:"2em"}}><MdOutlinePermIdentity /> <stron>Identifiant</stron> : {credentiels.user}</Box>
            <Box sx={{fontSize:"2em"}}><PiPasswordBold /> <stron>Mot de passe </stron> : {credentiels.password}</Box>
        </>
    )
};

export default IdendifiantInterfaceWeb;