import { isNull } from "lodash";
import { Typography } from "@mui/material";
import { _DATE_FORMAT } from "../../../../utils/constants";
import { DateFormatter } from "../../../../utils/muiTableHelper";
import moment from "moment";

export const sauvegardeColumns = () => {
  return [
    {
      accessorKey: "societeProduitServiceSauvegarde.prtgSensor",
      header: "Capteur prtg",
      enableSorting: false,
      Cell: ({ row }) => {
        const prtgSensor =
          row.original.societeProduitServiceSauvegarde?.prtgSensor;

        return <Typography>{isNull(prtgSensor) ? "" : prtgSensor}</Typography>;
      },
    },
    {
      accessorKey: "nomnas",
      header: "Nom du NAS",
      Cell: ({ row }) => {
        const nomnas = row.original.nomnas;

        return <Typography>{isNull(nomnas) ? "" : nomnas}</Typography>;
      },
    },
    {
      accessorKey: "nomtache",
      header: "Nom de la tâche",
      Cell: ({ row }) => {
        const nomtache = row.original.nomtache;

        return <Typography>{isNull(nomtache) ? "" : nomtache}</Typography>;
      },
    },
    {
      accessorKey: "societeProduitServiceSauvegarde.dateLastBackup",
      header: "Dernière sauvegarde",
      enableSorting: false,
      Cell: ({ row }) => {
        const dateLastBackup =
          row.original.societeProduitServiceSauvegarde?.dateLastBackup;
        if (!dateLastBackup) {
          return "";
        }
        const momentDate = moment(dateLastBackup, _DATE_FORMAT.datetime_exact);

        return <DateFormatter momentDate={momentDate} />;
      },
    },
    {
      accessorKey: "societeProduitServiceSauvegarde.updatedAt",
      header: "Dernière mise à jour",
      enableSorting: false,
      Cell: ({ row }) => {
        const updatedAt =
          row.original.societeProduitServiceSauvegarde?.updatedAt;
        if (!updatedAt) {
          return "";
        }
        const momentDate = moment(updatedAt, _DATE_FORMAT.datetime_exact);

        return <DateFormatter momentDate={momentDate} />;
      },
    },
  ];
};
