import { FormControl, Autocomplete, TextField } from "@mui/material";
import { _TYPES_RESERVATION } from "../../../utils/constants";

const TypesReservation = ({selectedTypeReservation, setSelectedTypeReservation}) => {
    const typesReservation = _TYPES_RESERVATION;

    return (
        <>
            <FormControl fullWidth>
                <Autocomplete 
                    id="resource-select"
                    options={typesReservation?.length > 0 ? typesReservation : []}
                    getOptionLabel={(option) =>
                        option
                            ? `${option.name}`
                            : null

                    }
                    onChange={(event, newValue) => {
                        setSelectedTypeReservation(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Type de réservation"/>}
                />
            </FormControl>
        </>
    );
};

export default TypesReservation;