import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";

/**
 * Custom toolbar component for the DataGrid.
 *
 * @param {Function} props.onChange - The onChange event handler for the filter.
 * @param {string} props.value - The value of the filter.
 * @returns {JSX.Element} The custom toolbar component.
 */
export const CustomToolbar = (props) => {
  return (
    <GridToolbarContainer
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Box>
        <GridToolbarExport />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector lang="FR" />
      </Box>
      <Box>
        {props.onChange /* onChange prop => render a server filter  */ ? (
          <GridToolbarQuickFilter
            value={props.value}
            onChange={props.onChange}
            debounceMs={500}
          />
        ) : (
          /* no onChange prop => render a client filter */
          <GridToolbarQuickFilter />
        )}
      </Box>
    </GridToolbarContainer>
  );
};
