import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { _COLORS } from "../../utils/constants";

const DetailPanelVadeSecure = ({ row }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead sx={{ backgroundColor: _COLORS.midGrey }}>
            <TableRow>
              <TableCell>
                <strong>Nom de license</strong>
              </TableCell>
              <TableCell>
                <strong>Code License</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.original.societeProduitServiceVadeSecure?.nameProduct ??
                  "N/A"}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.original.societeProduitServiceVadeSecure?.codeProduct ??
                  "N/A"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DetailPanelVadeSecure;
