import { useContext, useEffect, useState } from "react";
import { TicketsListContext } from "../../../contexts/TicketsListContext";
import { RequestHelper } from "../../../utils/requestHelper";
import {
  _STATE_FILTER,
  _TICKET_OWNER,
} from "../../../utils/_tickets_constants";
import { UserContext } from "../../../contexts/UserContext";
import { _ACTIONS, _DATE_FORMAT } from "../../../utils/constants";

const _SPECIFIC_FILTER = [
  "user.firstName",
  "createdAt",
  "updatedAt",
  "closedAt",
  "estimedAt",
];

export const DataTicketList = ({}) => {
  // Fetch data with filter setted in context;
  const {
    stateFilter,
    ownerFilter,
    data,
    setData,
    setIsLoaded,
    setIsLoading,
    setIsRefetching,
    pagination,
    columnFilters,
    globalFilter,
    sorting,
    setRowCount,
    refresh,
  } = useContext(TicketsListContext);
  const { getUser } = useContext(UserContext);
  const user = getUser();

  const requestHelper = new RequestHelper();

  /**
   * Generates the query data for the ticket list.
   * @returns {Object} The query data object.
   */
  const doTicketListQueryData = () => {
    // Loading
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    // inital query
    let queryData = {};

    // pagination
    queryData = {
      ...queryData,
      page: pagination.pageIndex + 1,
      itemsPerPage: pagination.pageSize,
    };

    // External Filters
    if (stateFilter && stateFilter !== _STATE_FILTER.ALL.name) {
      queryData = {
        ...queryData,
        "status.name": stateFilter,
      };
    }

    if (ownerFilter == _TICKET_OWNER.PERSO) {
      queryData = {
        ...queryData,
        "user.id": user.id,
      };
    }

    // Mui Table Filters
    if (columnFilters.length) {
      columnFilters.forEach((filter) => {
        if (_SPECIFIC_FILTER.includes(filter.id)) {
          // Specific Filter
          switch (filter.id) {
            case "user.firstName":
              queryData = {
                ...queryData,
                "user.firstName": filter.value.split(" ")[0],
              };
              break;
            case "createdAt":
            case "updatedAt":
            case "closedAt":
            case "estimedAt":
              queryData = {
                ...queryData,
                [filter.id + "[after]"]: filter.value[0].format(
                  _DATE_FORMAT.datetime
                ),
                [filter.id + "[before]"]: filter.value[1].format(
                  _DATE_FORMAT.datetime
                ),
              };
              break;
            default:
              break;
          }
        } else {
          queryData = {
            ...queryData,
            [filter.id]: filter.value,
          };
        }
      });
    }
    // Mui Table Sorting
    if (sorting) {
      sorting.forEach((sort) => {
        const key = "order[" + sort.id + "]";

        queryData = {
          ...queryData,
          [key]: sort.desc ? "desc" : "asc",
        };
      });
    }

    return queryData;
  };

  useEffect(() => {
    const queryData = doTicketListQueryData();

    requestHelper.doRequest(_ACTIONS.GET, "/tickets", queryData).then((res) => {
      setData(res.data["hydra:member"]);
      setRowCount(res.data["hydra:totalItems"]);
      setIsLoading(false);
    });
  }, [
    refresh,
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  return <></>;
};
