import * as React from "react";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Loader from "../../utils/Loader";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { RiUserSharedLine } from "react-icons/ri";
import BasicModal from "../../helpers/BasicModal";
import LoansList from "./Modals/LoansList";
import { LiaEdit } from "react-icons/lia";
import AddLoanForm from "./Modals/AddLoanForm";
import { RiDeleteBin6Line } from "react-icons/ri";
import { _HTTP, _ACTIONS } from "../../../utils/constants";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { createToast } from "../../../utils/toast";
import { RequestHelper } from "../../../utils/requestHelper";

export const TicketEditPrets = (ticket, setTicket) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [loans, setLoans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openLoanModal, setOpenLoanModal] = useState(false);
  const [loan, setLoan] = useState(null);
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
    float: "right",
    marginRight: "10px",
  }));
  const requestHelper = new RequestHelper();

  useEffect(() => {
    const reservations = ticket?.ticket?.reservations;
    if (reservations) {
      setLoans(reservations);
    }
  }, [ticket]);

  function deleteLoan(loan) {
    setOpen(false);
    try {
      requestHelper
        .doRequest(_ACTIONS.DELETE, `reservations/${loan?.id}`)
        .then((response) => {
          if ([_HTTP.noContent].includes(response.status)) {
            createToast("success", "Prêt supprimé avec succès");
            setLoans(loans.filter((element) => element.id !== loan.id));
            ticket.setTicket((prev) => {
              return {
                ...prev,
                reservations: prev.reservations.filter(
                  (element) => element.id !== loan.id
                ),
              };
            });
          } else {
            createToast("error", "Erreur lors de la suppression du prêt");
          }
        });
    } catch (error) {
      createToast("error", "Erreur lors de la suppression du prêt");
    }
  }

  return (
    <>
      <BasicModal
        showModal={showModal}
        modalWidth="800px"
        setShowModal={setShowModal}
        title="Liste des prêts"
        width={1500}
        content={LoansList(loans)}
      ></BasicModal>
      {/* ADD LOAN */}
      <BasicModal
        showModal={openLoanModal}
        setShowModal={setOpenLoanModal}
        title="Modifier un prêt"
        icon={<LiaEdit size="25" />}
        content={
          <AddLoanForm
            ticket={ticket.ticket}
            setTicket={ticket.setTicket}
            setOpenLoanModal={setOpenLoanModal}
            loan={loan}
          ></AddLoanForm>
        }
        width={700}
      ></BasicModal>
      {ticket?.ticket ? (
        <Card sx={{ mt: 2 }}>
          <Typography sx={{ m: 2, fontSize: "1rem" }} color="text.secondary">
            <strong>Liste des prêts</strong>
            <StyledBadge
              title={"Détails des prêts"}
              badgeContent={loans.length}
              color="primary"
            >
              <VolunteerActivismIcon
                onClick={() => setShowModal(true)}
                style={{ cursor: "pointer" }}
              />
            </StyledBadge>
          </Typography>
          {loans.map((loan, index) => {
            return (
              <Typography key={index} sx={{ padding: 0.5 }}>
                <RiUserSharedLine /> {loan?.quantity} x{" "}
                {loan?.resource.type
                  ? loan?.resource?.type
                  : "" + " - " + loan?.resource?.brand
                  ? loan?.resource?.brand
                  : "" + " - " + loan?.resource?.reference
                  ? loan?.resource?.reference
                  : "" + " - " + loan?.resource?.serialNumber
                  ? loan?.resource?.serialNumber
                  : ""}
                <RiDeleteBin6Line
                  title="Supprimer le prêt"
                  size="20"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginRight: "10px",
                    color: "red",
                  }}
                  onClick={handleClickOpen}
                />
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Voulez-vous vraiment supprimer ce prêt ?"}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={handleClose}>Non</Button>
                    <Button
                      onClick={() => deleteLoan(loan)}
                      autoFocus
                      style={{ color: "red" }}
                    >
                      Oui
                    </Button>
                  </DialogActions>
                </Dialog>
                <LiaEdit
                  title="Modifier le prêt"
                  size="20"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginRight: "10px",
                    color: "green",
                  }}
                  align="right"
                  onClick={() => {
                    setLoan(loan);
                    setOpenLoanModal(true);
                  }}
                />
              </Typography>
            );
          })}
        </Card>
      ) : (
        <Card sx={{ mt: 2 }}>
          {/* LOADER */}
          <Loader height={100} />
        </Card>
      )}
    </>
  );
};

export default TicketEditPrets;
