import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { __FORM_STYLES } from "../../utils/_style_constants";
import { editInstantRequest } from "../../utils/function";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { createToast } from "../../utils/toast";
import { _SWAL_ICONS } from "../../utils/constants";
import { useEffect, useState } from "react";

/**
 * InstantEditMultipleSelect is a component that renders a multiple select input field with instant edit functionality.
 *
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered JSX element.
 */
export const InstantEditMultipleSelect = ({
  entityValue, // The current value of the entity
  setEntityValue, // Function to update the entity value
  label, // The label for the select input
  field, // The field of the entity to be edited
  options, // The options for the select input
  optionValue, // The field of the option objects to be used as the option value
  optionLabel, // The field of the option objects to be displayed as the option label
  refresh, // A value used to trigger a refresh of the component
  setRefresh, // Function to update the refresh value
  relation = false, // Boolean indicating whether the field is a relation field
  disabled = false, // Boolean indicating whether the select input is disabled
}) => {
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    if (entityValue && !_.isUndefined(entityValue[field])) {
      const initialValue = relation
        ? entityValue[field].map((r) => r["@id"])
        : entityValue[field];
      setInitialValue(initialValue);
    }
  }, [entityValue]);

  // Define constants for the menu item height and padding
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  // Define properties for the menu
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Function to get the label for an option
  const getLabel = (c) =>
    _.isArray(optionLabel)
      ? optionLabel.map((o) => c[o]).join(" ") // If optionLabel is an array, join all corresponding properties from c
      : c[optionLabel]; // If optionLabel is not an array, get the corresponding property from c

  return (
    <FormControl sx={__FORM_STYLES.basicInput} size="small">
      <InputLabel size="small">{label}</InputLabel>
      <Select
        id={field}
        disabled={disabled}
        multiple
        value={entityValue ? entityValue[field] : []}
        onChange={(e) => {
          // On change, remove duplicate entries from the target value
          e.target.value = e.target.value.filter(
            (v, i, a) =>
              a.findIndex((t) => t[optionValue] === v[optionValue]) === i
          );

          // If the entity value does not include the target value, update the entity value
          if (!entityValue[field].includes(e.target.value)) {
            setEntityValue({
              ...entityValue,
              [field]: e.target.value,
            });
          } else {
            e.preventDefault();
          }
        }}
        onBlur={(e) => {
          if (
            !_.isEmpty(e.target.value) &&
            _.isEqual(
              e.target.value.map((r) => r["@id"]),
              initialValue
            )
          ) {
            // If the new value is equal to the initial value, do nothing
            return;
          }
          // On blur, make an instant edit request
          editInstantRequest(e.target.value, entityValue["@id"], field).then(
            (res) => {
              if(_.isUndefined(res)) return
              if ('@id' in res) {
                // If the request is successful, display a success toast
                createToast(_SWAL_ICONS.success, "Modification enregistrée");
              }
              if (!_.isUndefined(refresh)) {
                // If refresh is not undefined, increment it by 1
                setRefresh(refresh + 1);
              }
            }
          );
        }}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          // Render the selected values as chips
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((c) => (
              <Chip key={c.id} label={getLabel(c)} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        IconComponent={() => (
          // Render a close icon button that clears the selected values on click
          <IconButton
            size="small"
            onClick={(e) => {
              setEntityValue({
                ...entityValue,
                [field]: [],
              });
              editInstantRequest([], entityValue["@id"], field);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      >
        {options?.map((u, i) => (
          // Render the options as menu items
          <MenuItem key={i} value={u}>
            {getLabel(u)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
