import { FormControl, Autocomplete, TextField, Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { TYPE_CONTRATS } from "../ColumnsContrat";
import { LOCATION_PRODUIT_DOLIBARS, BEEMO_PRODUIT_DOLIBARS } from "../../../utils/constants";

/**
 * Renders a form field for the Dolibar product.
 *
 * @param {Object} formik - The formik object.
 * @param {Array} produitsServices - The array of products/services.
 * @returns {JSX.Element} The rendered form field component.
 */
export const FormFieldProduitDolibar = ({ formik, produitsServices, typeContrat }) => {

    const fieldId = typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO ? BEEMO_PRODUIT_DOLIBARS : LOCATION_PRODUIT_DOLIBARS;
    
   
    /**
     * Retrieves the fields for a product.
     *
     * @param {Array} selects - The array of selects.
     * @returns {Array} - The array of ChampsProduit components.
     */
    const getChampsProduit = (selects) => {
        return selects?.map((c) => {
            const index = (c.idDolibar) ?? c.id; 
            
            formik.values[index + '_quantite'] = c.quantite ?? null;
            formik.values[index + '_prix'] = c.prix ?? null;
            formik.values[index + '_quantiteExtern'] = c.quantiteExtern ?? null;
            formik.values[index + '_prixExtern'] = c.prixExtern ?? null;
            formik.values[index + '_tailleExtern'] = c.tailleExtern ?? null;
            
            return ChampsProduit(
                {
                    formik, 
                    label: c.name, 
                    nameQuantite:index + '_quantite', 
                    namePrix:index + '_prix',
                    nameQuantiteExt:index + '_quantiteExtern', 
                    namePrixExt:index + '_prixExtern',
                    nameTailleExt:index + '_tailleExtern',
                    idDolibar: index,
                    fieldId: fieldId
                });
        });
    }
 
    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <Autocomplete
                    multiple
                    limitTags={1}
                    value={formik.values[fieldId]}
                    onChange={(event, value) => {  
                        formik.setFieldValue(fieldId, value);
                    }}
                    id={fieldId}
                    options={produitsServices}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.idDolibar}
                    renderInput={(params) => (
                        <TextField {...params} label="Modèle" />
                    )}
                />
            </FormControl>
            {getChampsProduit(formik.values[fieldId])}
        </>
  );     
};

/**
 * Updates the value of a specific field in the formik values array.
 *
 * @param {object} formik - The formik object.
 * @param {string} name - The name of the field to update.
 * @param {string|number} value - The new value for the field.
 * @param {number} idDolibar - The id of the Dolibar product.
 */
const changeValue = (formik, name, value, idDolibar, fieldId) => {
    formik.values[fieldId].map((produit) => {
        const names = name.split('_');
        if(produit.idDolibar === idDolibar){
            produit[names[1]] = !isNaN(value) ? parseInt(value) : value;
        }
    });
}

/**
 * Renders a form field for a product in Dolibar.
 * @param {Object} props - The component props.
 * @param {Object} props.formik - The formik object.
 * @param {string} props.label - The label for the form field.
 * @param {string} props.nameQuantite - The name for the quantity field.
 * @param {string} props.namePrix - The name for the price field.
 * @param {string} props.nameQuantiteExt - The name for the external quantity field.
 * @param {string} props.namePrixExt - The name for the external price field.
 * @param {string} props.nameTailleExt - The name for the external size field.
 * @returns {JSX.Element} The rendered component.
 */
export const ChampsProduit = ({ formik, label, nameQuantite, namePrix, nameQuantiteExt, namePrixExt, nameTailleExt, idDolibar, fieldId }) => {
    return (
        <Card sx={{marginTop: 1}}> 
            <CardHeader sx={{paddingBottom: 0}} title={label} />
            <CardContent>
                <Quantite formik={formik} name={nameQuantite} required={true} idDolibar={idDolibar} fieldId={fieldId} />
                <Prix formik={formik} name={namePrix} required={true} idDolibar={idDolibar} fieldId={fieldId}/>
                {fieldId === BEEMO_PRODUIT_DOLIBARS && (
                    <Box sx={{ m: 1.5 }}>
                        <Typography color="text.secondary">Externalisation</Typography>
                        <Taille formik={formik} name={nameTailleExt} required={false} idDolibar={idDolibar} fieldId={fieldId} />
                        <Quantite formik={formik} name={nameQuantiteExt} required={false} idDolibar={idDolibar} fieldId={fieldId}/>
                        <Prix formik={formik} name={namePrixExt} required={false} idDolibar={idDolibar} fieldId={fieldId}/>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

/**
 * Renders a quantity input field.
 *
 * @param {object} formik - The formik object.
 * @param {string} name - The name of the input field.
 * @param {boolean} required - Indicates if the field is required.
 * @returns {JSX.Element} - The quantity input field component.
 */
export const Quantite = ({ formik, name, required, idDolibar, fieldId }) => {
    return (
        <Box>
            <TextField 
                key={name}
                fullWidth
                sx={{ marginTop: 2 }}
                name={name}
                type="number"
                label={"Quantité" + (required ? '*' : '')}
                defaultValue={formik.values[name]}
                onChange={(e) => {changeValue(formik, name, e.target.value, idDolibar, fieldId)}}
            />
        </Box>
    );
}


/**
 * Renders a text field for entering a price value.
 *
 * @param {Object} formik - The formik object.
 * @param {string} name - The name of the field.
 * @param {boolean} required - Indicates if the field is required.
 * @returns {JSX.Element} - The rendered text field component.
 */
export const Prix = ({ formik, name , required, idDolibar, fieldId }) => {
    return (
        <Box>
            <TextField 
            key={name}
                fullWidth
                sx={{ marginTop: 2 }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                name={name}
                type="number"
                label={"Prix" + (required ? '*' : '')}
                defaultValue={formik.values[name]}
                onChange={(e) => {changeValue(formik, name, e.target.value, idDolibar, fieldId)}}
            />
        </Box>
    );
}

/**
 * Renders a form field for the "Taille" input.
 *
 * @component
 * @param {object} formik - The Formik object.
 * @param {string} name - The name of the input field.
 * @param {boolean} required - Indicates if the field is required.
 * @returns {JSX.Element} - The rendered component.
 */
export const Taille = ({ formik, name, required, idDolibar, fieldId }) => {
    return (
        <Box>
            <TextField 
                key={name}
                fullWidth
                sx={{ marginTop: 2 }}
                name={name}
                type="string"
                label={"Taille" + (required ? '*' : '')}
                defaultValue={formik.values[name]}
                onChange={(e) => {changeValue(formik, name, e.target.value, idDolibar, fieldId)}}
            />
        </Box>
    );
}