import { TextField, FormControl, Box } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import _ from "lodash";
import InputAdornment from '@mui/material/InputAdornment';

export const TypeLocation = ({formik, typeLocations, setChoice, setSelectedTypeLocation, setNomTypeLocation}) => {

    return (
    <>
        <FormControl fullWidth sx={{marginTop: "20px"}}>
            <Autocomplete
                fullWidth
                /* data */
                options={typeLocations ? typeLocations : []}
                /* display label */
                getOptionLabel={(option) =>
                    option
                        ? option.nom
                        : null
                    }
                    value={
                        typeLocations?.find(
                            (option) =>
                                option["@id"] === formik.values.contratTypeLocation
                            ) || null
                    }
                    onChange={(e, value) => {
                        if (value === "" || value === null) {
                                formik.setFieldValue("contratTypeLocation", null);
                                setChoice(false);
                                setSelectedTypeLocation(null);
                                setNomTypeLocation(null);
                        } else {
                            formik.setFieldValue(
                                "contratTypeLocation",
                                value["@id"]
                            );
                            setChoice(true);
                            setSelectedTypeLocation(value["@id"]);
                            setNomTypeLocation(value.nom);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            label="Type de location*" 
                            fullWidth
                            name="contratTypeLocation"
                            sx={{
                                    marginTop: "0px",
                                }}
                            onBlur={formik.handleBlur}
                                    {...params}
                            error={
                                    formik.touched.contratTypeLocation &&
                                    !formik.values.contratTypeLocation &&
                                    Boolean(formik.errors.contratTypeLocation)
                                }
                            helperText={
                                    formik.touched.contratTypeLocation && !formik.values.contratTypeLocation
                                        ? formik.errors.contratTypeLocation
                                        : ""
                                }
                        />
                    )}
            />
        </FormControl>
    </>
    );
};

export const DevisDolibarr = ({formik, devisList}) => {

    return (
    <>
        <FormControl fullWidth sx={{marginTop: "20px"}}>
            <Autocomplete
                fullWidth
                /* data */
                options={devisList ? devisList : []}
                /* display label */
                getOptionLabel={(option) =>
                    option
                        ? option.ref
                        : null
                    }
                    value={
                        devisList?.find(
                            (option) =>
                                option.id === formik.values.devisDolibarr
                            ) || null
                    }
                    onChange={(e, value) => {
                        if (value === "" || value === null) {
                                formik.setFieldValue("devisDolibarr", null);
                        } else {
                            formik.setFieldValue(
                                "devisDolibarr",
                                value.id
                            );
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            label="Devis" 
                            fullWidth
                            name="devisDolibarr"
                            sx={{
                                    marginTop: "0px",
                                }}
                            onBlur={formik.handleBlur}
                                    {...params}
                        />
                    )}
            />
        </FormControl>
    </>
    );
};

export const TypeOffre = ({formik, contratTypeOffres}) => {
    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <Autocomplete
                    fullWidth
                    /* data */
                    options={contratTypeOffres ? contratTypeOffres : []}
                    /* display label */
                    getOptionLabel={(option) =>
                        option
                            ? option.nom
                            : null
                        }
                        value={
                            contratTypeOffres?.find(
                                (option) =>
                                    option["@id"] === formik.values.contratTypeOffre
                                ) || null
                        }
                        onChange={(e, value) => {
                            if (value === "" || value === null) {
                                    formik.setFieldValue("contratTypeOffre", null);
                            } else {
                                formik.setFieldValue(
                                    "contratTypeOffre",
                                    value["@id"]
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                label="Type d'offre" 
                                fullWidth
                                name="contratTypeOffre"
                                sx={{
                                        marginTop: "0px",
                                    }}
                                onBlur={formik.handleBlur}
                                        {...params}
                            />
                        )}
                />
            </FormControl>
        </>
    );
};

export const NbMateriel =({formik}) => {
    return (
        <>
            <TextField 
                fullWidth
                sx={{ marginTop: 2 }}
                InputProps={{
                    endAdornment: <MdOutlineProductionQuantityLimits size="1.5em" position="start" />,
                }}
                name="nbMateriel" 
                type="number"
                label="Nombre de matériel"
                value={formik.values.nbMateriel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
        </>
    );
};

export const Lieu = ({formik}) => {
    return (
        <>
            <TextField 
                fullWidth
                sx={{ marginTop: 2 }}
                InputProps={{
                    endAdornment: <FaMapLocationDot size="1.5em" position="start" />,
                }}
                name="lieu" 
                label="Lieu"
                value={formik.values.lieu}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
        </>
    );
};

export const Fournisseurs = ({formik, fournisseurs}) => {
    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <Autocomplete
                    fullWidth
                    /* data */
                    options={fournisseurs ? fournisseurs : []}
                    /* display label */
                    getOptionLabel={(option) =>
                        option
                            ? option.name
                            : null
                        }
                        value={
                            fournisseurs?.find(
                                (option) =>
                                    option["@id"] === formik.values.fournisseur
                                ) || null
                        }
                        onChange={(e, value) => {
                            if (value === "" || value === null) {
                                    formik.setFieldValue("fournisseur", null);
                            } else {
                                formik.setFieldValue(
                                    "fournisseur",
                                    value["@id"]
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                label="Fournisseur" 
                                fullWidth
                                name="fournisseur"
                                sx={{
                                        marginTop: "0px",
                                    }}
                                onBlur={formik.handleBlur}
                                        {...params}
                            />
                        )}
                />
            </FormControl>
        </>
        );
};

export const DateEnvoi = ({formik}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <DatePicker
                    label="Date d'envoi"
                    size="small"
                    format="DD/MM/YYYY"
                    name="dateEnvoi"
                    value={formik.values.dateEnvoi}
                    onChange={(newDate) => {
                        formik.setFieldValue(
                        "dateEnvoi",
                        moment(newDate)
                        );
                    }}
                    slots={{
                        openPickerIcon: EditCalendarRoundedIcon,
                    }}
                    slotProps={{
                        openPickerButton: { color: "success" },
                    }}
                />
            </FormControl>
        </>
    );
};

export const DateRetour = ({formik}) => {

    return (
        <>
            <FormControl fullWidth sx={{marginTop: "20px"}}>
                <DatePicker
                    label="Date de retour"
                    size="small"
                    format="DD/MM/YYYY"
                    name="dateRetour"
                    value={formik.values.dateRetour}
                    onChange={(newDate) => {
                        formik.setFieldValue(
                        "dateRetour",
                        moment(newDate)
                        );
                    }}
                    slots={{
                        openPickerIcon: EditCalendarRoundedIcon,
                    }}
                    slotProps={{
                        openPickerButton: { color: "success" },
                    }}
                />
            </FormControl>
        </>
    );
};    

export const Materiel = ({formik}) => {

    return (
        <>
            <TextField 
                fullWidth
                sx={{ marginTop: 2 }}
                multiline
                name="resource" 
                label="Matériels"
                value={formik.values.resource}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
        </>
    );
};

export const Price = ({formik}) => {

    return (
        <>
            <TextField 
                fullWidth
                sx={{ marginTop: 2 }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                name="price" 
                type="number"
                label="Prix"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <Box sx={{fontSize:'12px', color:'grey', padding:'5px'}}>
                Somme totale pour des locations.
            </Box>
        </>
    );
}