import React from "react";
import { Grid } from "@mui/material";
import FormTitle from "../../components/helpers/FormTitle";
import { useNavigate } from "react-router-dom";
import { BaseConnaissanceArticle } from "../../components/base-connaissance/dashboard/BaseConnaissanceArticle";
import { SearchArticles } from "../../components/base-connaissance/dashboard/SearchArticles";
import { CreateArticleBtn } from "../../components/base-connaissance/dashboard/CreateArticleBtn";
import { MemoizedTreeView } from "../../components/base-connaissance/dashboard/TreeView";
import BaseConnaissanceContextProvider from "../../contexts/BaseConnaissanceContext";

/**
 * Représente le tableau de la B-C (gestion de transition)
 *
 * @returns {JSX.Element} The rendered BaseConnaissanceDashboard component.
 */
export default function BaseConnaissanceDashboard() {
  const navigate = useNavigate();

  return (
    <BaseConnaissanceContextProvider>
      <FormTitle title="Centre de la base de connaissances" sx={{ mb: 2 }} />
      <Grid container spacing={2} width={"100%"} sx={{ display: "flex" }}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CreateArticleBtn navigate={navigate} set />
          <MemoizedTreeView newArticle={""} navigate={navigate} />
        </Grid>
        <Grid item xs={12} md={8}>
          {/*  DISPLAY SEARCH FIELD ARTICLE */}
          <SearchArticles />
          {/* DISPLAY ARTICLE */}
          <BaseConnaissanceArticle />
        </Grid>
      </Grid>
    </BaseConnaissanceContextProvider>
  );
}
