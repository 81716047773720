import WifiIcon from "@mui/icons-material/Wifi";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import { head } from "lodash";

export const _ACTIONS = {
  GET: "GET",
  POST: "POST",
  POSTFILE: "POSTFILE",
  PATCH: "PATCH",
  DELETE: "DELETE",
  PUT: "PUT",
};

export const _ROLES = {
  USER: "ROLE_USER",
  TECHNICIEN: "ROLE_TECHNICIEN",
  ADMIN: "ROLE_ADMIN",
  SUPER_ADMIN: "ROLE_SUPER_ADMIN",
  CLIENT: "ROLE_CLIENT",
};

export const _HTTP = {
  created: 201,
  ok: 200,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  internalServerError: 500,
};

export const _RESULT = {
  success: "success",
  error: "error",
};

export const _HYDRAMEMBERS = "hydra:member";

export const _API = {
  BITDEFENDER: "Module Bitdefender",
  HEBERGEMENT: "Module d'hébergement",
  LOGICIEL: "Module logiciel",
  FIREWALL: "Module opnsense",
};

export const _COLORS = {
  grey: "#777a7f",
  lightGrey: "#f4f4f4",
  midGrey: "#D2D6DE",
  highGrey: "#4d4e51",
  darkGrey: "#534e48", // used for text
  buttonLightBlue: "#3c8dbc",
  drawerNav: "#1a2226",
  drawerNavCollapse: "#2c3b41",
  drawerNavCollapseHover: "#2E393D",
  lightBlue: "#3c8dbc",
  turquoiseBox: "#00c0ef",
  defaultGreen: "#57a85c",
  hoverDefaultGreen: "  #73b577",
  lightGreen: "#DFF0D8",
  defaultBlue: "#4fc1ef",
  error: "#C9302C",
  warning: "#F39C12",
  success: "#00a65a",
  lightDanger: "#D87A68",
  link: "#428fbc",
  white: "#fff",
  backgroundApp: "#ecf0f5",
};

export const _MESSAGES = {
  form_error: "Veuillez vérifier les champs du formulaire",
  success: "Opération effectuée avec succès",
  error: "Une erreur est survenue",
  error2: "Une erreur est survenue. Merci de réessayer.",
  error_delete: "Une erreur est survenue lors de la suppression",
  identifiants_invalides: "Identifiants invalides",
  unAuthorized: "Vous n'êtes pas autorisé à accéder à cette ressource",
  notFound: "La ressource demandée n'existe pas",
  emailsent: "Un email vous a été envoyé",
  emailnotsent: "Une erreur est survenue lors de l'envoi de l'email",
  message_ticket_devis:
    "Si vous ne sélectionnez pas de contrat, la durée facturable ne sera pas déduite du temps du contrat.",
  field_required: "Ce champ est requis",
};

// @TODO ajouter les messages d'erreur de l'application
export const _SWAL_ICONS = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
  question: "question",
};

export const _SWAL_BTN = {
  Confirmer: "Confirmer",
  Annuler: "Annuler",
  Ok: "Ok",
};

export const drawerWidth = 270;

export const _NAV = {
  title: "Next Toolin",
  logout: "Déconnexion",
};

export const PRODUIT_SERVICE = {
  h1: "Gestion des catalogues",
  subh1: "(produits, services)",
  h2: "Listes des produits, services",
};

export const iconsPS = [
  {
    name: "ACCÈS A INTERNET",
    icon: <WifiIcon />,
  },
  {
    name: "ANTIVIRUS",
    icon: <CoronavirusIcon />,
  },
  {
    name: "ROUTEUR / FIREWALL",
    icon: <RoomPreferencesIcon />,
  },
];

export const DECOMPTE_TEMPS = {
  h1: "Gestion de décompte temps",
  h4: "Créer un décompte temps personnalisé",
  recap: "Récapitulatif décompte temps",
  titleDataGrid: "Décompte en temps par client",
};

export const CONTRATS = {
  h1: "Gestion des contrats",
};

export const STATS = {
  h1: "Statistiques",
}

export const _RESERVATIONS = {
  h1: "Gestion des réservations",
  subh1: "(Gérer les réservations internes et externes.)",
  buttonInterne: " Ajouter une réservation interne",
  buttonExterne: " Ajouter une réservation externe",
  resaEnCour: "Réservations en cours",
  resaTerminé: "Réservations terminées",
};

export const _TIERS = {
  h1: "Gestion des tiers",
  subh1: "(clients, fournisseurs, particuliers)",
  h2: "Listes des tiers",
  refToolin: "Référent Toolin",
  refClient: "Référent client",
  adresse: "Adresse",
  adresseMail: "Adresse e-mail",
  fixPro: "Fixe (professionnel)",
  domaine: "Domaine",
  actifConfig: "Actifs & Configuration du client",
  folderDrive: "Dossier client dans le Google Drive Toolin",
};

export const _ACTIFS_CONFIGURATION = {
  h1: "Gestion des actifs et configurations",
  subh1: "",
  h2: "Modifier des actifs et configurations, schémas",
  H2_CREATE: "Créer un actif et configuration",
};

export const subscriptionTypeArray = [
  {
    id: 1,
    name: "Abonnement d'essai",
  },
  {
    id: 2,
    name: "Abonnement sous licence",
  },
  {
    id: 3,
    name: "Abonnement mensuel",
  },
];

export const _DOLIBARR = {
  m1: "Attention, tout changement effectué (ajout, modification, suppression...) à un impact sur dolibarr",
};

export const listyears = [
  {
    id: 1,
    name: "2023",
  },
  {
    id: 2,
    name: "2022",
  },
  {
    id: 3,
    name: "2021",
  },
  {
    id: 4,
    name: "2020",
  },
];

export const typeDecompteTemps = [
  {
    id: 1,
    type: "Décompte en temps par client",
  },
  {
    id: 2,
    type: "Décompte en temps par catalogue",
  },
];

export const GSUITE_ANNUEL = 1;
export const GSUITE_MENSUEL = 2;

export const SUBSCRIPTION_TYPE_INVERSE = {
  [GSUITE_ANNUEL]: "Abonnement annuel",
  [GSUITE_MENSUEL]: "Abonnement mensuel",
};

export const CIVILITY = {
  Madame: "Mme",
  Monsieur: "Mr",
  Maître: "Mtre",
};

export const ROLES = {
  ROLE_ADMIN: "Administrateur",
  ROLE_USER: "Utilisateur",
  ROLE_SUPER_ADMIN: "Super admin",
  ROLE_TECHNICIEN: "Technicien",
  ROLE_CLIENT: "Client",
};

export const TYPE_ROLES = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_TECHNICIEN: "ROLE_TECHNICIEN",
  ROLE_CLIENT: "ROLE_CLIENT",
  ROLE_ALLOWED_TO_SWITCH: "ROLE_ALLOWED_TO_SWITCH",
};

/* Screen Contacts */
export const CONTACTS = {
  h1: "Gestion des contacts",
  subh1: "(clients, fournisseurs, particuliers)",
  h2: "Listes des contacts",
};

/** ALSO API */
export const MARKET_PLACES_ID = [5139, 11529, 11530];

/* VPN Actifs */
export const protocolesVPN = {
  1: "WireGuard",
  2: "L2TP",
  3: "IPSec",
  4: "OpenVPN",
};

export const serverVpn = {
  1: "Routeur",
  2: "NAS",
  3: "Windows",
  4: "Autres",
};

export const tunnels = {
  1: "Full Tunnel",
  2: "Split Tunnel",
};

export const _DATE_FORMAT = {
  input_time: "HH:mm",
  input_date: "yyyy-MM-DD",
  datetime: "YYYY-MM-DDTHH:mm",
  datetime_exact: "YYYY-MM-DDTHH:mm:ss.sssZ",
  date_minutes: "YYYY-MM-DDTHH:mm",
  date_display: "DD/MM/YYYY",
  hours_minutes: "HH:mm",
  month_letters: "MMMM",
  month: "MM",
  pretty: "dddd DD/MM/YYYY",
  pretty_tiny: "ddd DD/MM/YYYY",
  month_year: "MMMM YYYY",
  month_slash_year: "MM/YYYY",
  date_and_time_display: "DD/MM/YYYY - HH:mm",
  date_and_time_display_no_bar: "DD/MM/YYYY HH:mm",
  date_and_time_display_ss_no_bar: "DD/MM/YYYY HH:mm:ss",
  year: "YYYY",
  day: "DD",
  year_month: "YYYYMM",
  date_jour_letter_display: "DD MMMM YYYY",
  complete_date: "dddd DD MMMM YYYY",
  day_letter_date: "dddd",
  classic: "YYYY-MM-DDTHH:mm:ssZ",
  fileName: "DD_MM_YYYY_HH:mm:ss",
  next_display: "DD MMMM YYYY - HH:mm",
};

export const _CIVILITES = {
  MLE: "Mademoiselle",
  MME: "Madame",
  MR: "Monsieur",
  MTRE: "Maître",
};

/**
 * Represents a mapping of language keys to their corresponding translations for a Material-UI table.
 *
 * @type {Object.<string, string>}
 */
export const _LANGUAGES_MUI_TABLE = {
  clearFilters: "Effacer les filtres",
  rowsPerPage: "Lignes par page",
  showHideColumns: "Afficher/Masquer les colonnes",
  showHideSearch: "Afficher/Masquer la recherche",
  toggleFullScreen: "Plein écran",
  hideAll: "Masquer tout",
  showAll: "Afficher tout",
  resetOrder: "Réinitialiser",
  noRecordsToDisplay: "Aucun enregistrement à afficher",
  search: "Rechercher",
  toggleDensity: "Densité",
  sortByColumnAsc: "Trier par {column} ascendant",
  sortByColumnDesc: "Trier par {column} descendant",
  resetColumnSize: "Réinitialiser la taille de la colonne",
  clearSort: "Effacer le tri",
  clearFilter: "Effacer le filtre",
  clearSearch: "Effacer la recherche",
  hideColumn: "Masquer {column} colonne",
  showAllColumns: "Afficher toutes les colonnes",
  sortedByColumnAsc: "Triée par {column} ascendant",
  sortedByColumnDesc: "Triée par {column} descendant",
};

export const _SUBSCRIPTION_TYPE = {
  1: "Abonnement d'essai",
  2: "Abonnement sous licence",
  3: "Abonnement mensuel",
};

export const _LICENCED_STATUS = {
  0: "En attente d'authentification",
  1: "License active",
  2: "License expirée",
  6: "Il n'y a pas de license ou pas applicable",
};

export const _COMPUTER_STATUS = {
  0: { title: "inconu", color: "grey" },
  1: { title: "en ligne", color: "green" },
  2: { title: "hors ligne", color: "grey" },
  3: { title: "suspendu", color: "red" },
};

export const _MODE_FACTURATION = [
  { id: 1, mode: "Mensuel" },
  { id: 2, mode: "Trimestriel" },
  { id: 3, mode: "Annuel" },
];

export const TYPE_PARAM = {
  REGIE: "régie",
  LOCATION: "location",
  OFFRE: "offre",
};

export const _OVH = {
  actionLignesAssociees:
    "Renvoie l'utilisateur administrateur et le mot de passe de la ligne",
  actionLignesVoicemail:
    "Mise à jours de messagerie. Attention ! La sauvegarde de l'annonce personnalisée et des messages n'est pas garantie !",
  identifiantInterfaceWeb:
    "Identifiants pour accéder à l'interface Web du téléphone",
};
export const _CONTRATS_TYPE_TEXT = {
  CMT_TXT: "cmt",
  CI_TXT: "ci",
  CA_TXT: "ca",
  CT_TXT: "ct",
};

export const _TYPES_RESERVATION = [
  { id: 0, name: "Interne" },
  { id: 1, name: "Externe" },
];

export const BEEMO_PRODUIT_DOLIBARS = "beemoProduitDolibars";
export const LOCATION_PRODUIT_DOLIBARS = "locationProduitDolibars";

export const _STAT_POURCENTAGE_EQUPIPE = [
  {id: 1, value: "40", color: "error"},
  {id: 2, value: "40-50", color: "warning"},
  {id: 3, value: "50-80", color: "primary"},
  {id: 4, value: "80-100", color: "success"},
  {id: 5, value: "100", color: "turquoiseBox"},
];

export const _STAT_EQUIPE_NB_HEURES = {
  day: 7,
  week: 35,
  month: 133.91,
  year: 1607,
};

export const _JOURS_SEMAINE = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

export const _MOIS = [
  {id: 1, header: "Janvier", key: "janvier"},
  {id: 2, header: "Février", key: "fevrier"},
  {id: 3, header: "Mars", key: "mars"},
  {id: 4, header: "Avril", key: "avril"},
  {id: 5, header: "Mai", key: "mai"},
  {id: 6, header: "Juin", key: "juin"},
  {id: 7, header: "Juillet", key: "juillet"},
  {id: 8, header: "Août", key: "aout"},
  {id: 9, header: "Septembre", key: "septembre"},
  {id: 10, header: "Octobre", key: "octobre"},
  {id: 11, header: "Novembre", key: "novembre"},
  {id: 12, header: "Décembre", key: "decembre"},
]



