import { useState } from "react";
import FormTitle from "../../components/helpers/FormTitle";
import { Button, Container, Grid } from "@mui/material";
import { MdAddBox } from "react-icons/md";
import { _COLORS } from "../../utils/constants";
import { BoxInfoConfiguration } from "../../components/base-connaissance/configuration-dashboard/BoxInfoConfiguration";
import { BaseConnaissanceConfigurationTable } from "../../components/base-connaissance/configuration-dashboard/BaseConnaissanceConfigurationTable";

export function BaseConnaissanceConfigurationDashboard() {
  const [showModal, setShowModal] = useState(false);
  // ROW CATEGORY CLICKED
  const [categoryRow, setCategoryRow] = useState(null);
  // DETERMINE WHICH FORM MODAL IT IS (BETWEEN SECTION AND CATEGORY)
  const [isSectionForm, setIsSectionForm] = useState(false);

  return (
    <>
      <FormTitle title="Configuration base de connaissances" />
      <Container
        component="main"
        maxWidth={false}
        sx={{
          mt: 1,
          backgroundColor: "white",
          padding: "1rem",
          pb: "2rem",
          borderTop: `3px solid ${_COLORS.grey}`,
          boxShadow: `4px 4px 4px ${_COLORS.midGrey}`,
        }}
      >
        <Grid container spacing={2} width={"100%"}>
          <Grid item xs={12}>
            <BoxInfoConfiguration />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              title="Editer l'article"
              size="small"
              startIcon={<MdAddBox />}
              onClick={() => {
                setShowModal(true);
                setCategoryRow(null);
                setIsSectionForm(false);
              }}
            >
              Nouvelle catégorie
            </Button>
          </Grid>
          <Grid item xs={12}>
            <BaseConnaissanceConfigurationTable
              showModal={showModal}
              setShowModal={setShowModal}
              categoryRow={categoryRow}
              setCategoryRow={setCategoryRow}
              isSectionForm={isSectionForm}
              setIsSectionForm={setIsSectionForm}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
