/* PATTERN FORM RESET PASSWORD  */
export const _RESET_PASSWORD_FIELDS = [
  {
    name: "password",
    label: "Nouveau MDP",
    type: "password",
  },
  {
    name: "passwordConfirm",
    label: "Confirmer le MDP",
    type: "password",
  },
];
