import { Box } from "@mui/material";
import { TYPE_CONTRATS } from "../ColumnsContrat";
import { 
    Apporteur, 
    ApporteurMontant, 
    ApporteurName, 
    CreditContrat, 
    DateDebut, 
    DateFin,
    DateAnniversaire,
    Facturation, 
    FacturationDeplacement, 
    Price, 
    SocieteField,
    Reconduit,
    Commentaire,
    Field,
    ProduitsService,
    CostPrice,
    PrixFacturation,
    SocieteProduitServiceSauvegarde,
    TypeRegie,
    ActifClient
 } from "../forms-field/FormField";
import _ from "lodash";
import { useState, useEffect } from 'react';
import { FormFieldProduitDolibar } from "../forms-field/FormsFieldProduitDolibar";
import { getDataDolibar } from "../../../repository/Dolibar";
import { _ROUTES } from "../../../utils/_routes_constants";
import { DevisDolibarr } from "../forms-field/FormFieldLocation";

const FormContrats = (props) => {
    const { typeContrat, row, societes, formik, setSelectedSociete, selectedSociete, rapporteur, typeRegies, labelPrice, produitsServices, sauvegardes,
        setRapporteur, setIdApporteur, idApporteur, handleFileChange, prixFacturation, actifsClient } = props

    const [selects, setSelects] = useState([]);
    const [devisList, setDevisList] = useState([]);

    useEffect(() => {
        if(row !== null || selectedSociete !== null) {
            const societeId = row !== null ? row.societe.id : selectedSociete?.id;
            getDataDolibar(
                {id: societeId}, 
                setDevisList, 
                "Une erreur est survenue lors de la récupération des devis", 
                _ROUTES.DOL_DEVIS_SOCIETE
            );  
        }
    }, [selectedSociete]);
    
    return (
        <>
                { _.isNull(row) && (
                    <SocieteField formik={formik} societes={societes} setSelectedSociete={setSelectedSociete} />
                )}

                <DateDebut formik={formik} />
                <DateFin formik={formik} />
                <DateAnniversaire formik={formik} />

                {typeContrat === TYPE_CONTRATS.CONTRAT_HEBERGEMENT && (
                    <ActifClient formik={formik} actifsClient={actifsClient}/>
                )}
                {typeContrat === TYPE_CONTRATS.CONTRAT_BEEMO && (
                    <>
                        <DevisDolibarr formik={formik} devisList={devisList}/>
                        <FormFieldProduitDolibar formik={formik} produitsServices={produitsServices} typeContrat={typeContrat} />
                    </>
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_REGIE) && (
                    <TypeRegie formik={formik} typeRegies={typeRegies} setSelectedSociete={setSelectedSociete} selects={selects} setSelects={setSelects}  />
                )}
                {typeContrat === TYPE_CONTRATS.CONTRAT_MAINTENANCE && (
                    <Box>
                        <Facturation formik={formik} />
                        <CreditContrat formik={formik} />
                        <FacturationDeplacement formik={formik} />
                    </Box>
                )}
                {(typeContrat !== TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE && typeContrat !== TYPE_CONTRATS.CONTRAT_REGIE && typeContrat !== TYPE_CONTRATS.CONTRAT_BEEMO) && (
                    <Price formik={formik} labelPrice={labelPrice} />
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES) && (
                    <CostPrice formik={formik} />                   
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES || typeContrat === TYPE_CONTRATS.CONTRAT_REGIE) && (
                    <ProduitsService formik={formik} produitsServices={produitsServices} typeContrat={typeContrat} />
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE) && (
                    <Box>
                        <SocieteProduitServiceSauvegarde formik={formik} sauvegardes={sauvegardes} typeContrat={typeContrat} />
                        <PrixFacturation formik={formik} prixFacturation={prixFacturation} />
                    </Box> 
                )}
                {(typeContrat === TYPE_CONTRATS.CONTRAT_HEBERGEMENT || typeContrat === TYPE_CONTRATS.CONTRAT_AUTRES) && (
                    <Box>
                        <Apporteur formik={formik} setRapporteur={setRapporteur} />
                        {rapporteur && (
                            <Box>
                                <ApporteurMontant formik={formik} />
                                <ApporteurName formik={formik} societes={societes} setIdApporteur={setIdApporteur} idApporteur={idApporteur} />
                            </Box>
                        )}
                    </Box>
                )}    
                <Reconduit formik={formik} />
                <Commentaire formik={formik} row={row} />
                <Field handleFileChange={handleFileChange}/>
        </>
    );  
};

export default FormContrats;