import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RequestHelper } from "../../utils/requestHelper";
import { Button, Container } from "@mui/material";
import FormStepOne from "../../components/actifs-configurations/form/FormStepOne";
import FormStepTwo from "../../components/actifs-configurations/form/FormStepTwo";
import FormTitle from "../../components/helpers/FormTitle";
import {
  _ACTIFS_CONFIGURATION,
  _ACTIONS,
  _COLORS,
} from "../../utils/constants";
import { createToast } from "../../utils/toast";
import { UserContext } from "../../contexts/UserContext";
import FormFieldsVadeSecure from "../../components/actifs-configurations/form/FormFieldVadeSecure";
import FormFieldsBitdefender from "../../components/actifs-configurations/form/FormFieldsBitdefender";
import { FormFieldsGsuite } from "../../components/actifs-configurations/form/FormFieldsGsuite";
import { FormFieldAlso } from "../../components/actifs-configurations/form/FormFieldAlso";
import { FormFieldsOpnSense } from "../../components/actifs-configurations/form/FormFieldOpnSense";
import FormFieldsVpn from "../../components/actifs-configurations/form/FormFieldsVpn";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";
import FormFieldHebergement from "../../components/actifs-configurations/form/FormFieldHebergement";
import {
  getCataloguesProduitService,
  getSocietes,
  getValueFieldFormStepTwo,
  valuesFormActifConfig,
} from "../../utils/actif-config/actif_configuration_functions";
import Loader from "../../components/utils/Loader";
import FormFieldSauvegarde from "../../components/actifs-configurations/form/FormFieldSauvegarde";
import FormFieldLogiciel from "../../components/actifs-configurations/form/FormFieldLogiciel";
import FormFieldAsterisk from "../../components/actifs-configurations/form/FormFieldAsterisk";
import DoSubmitFormActif from "../../components/actifs-configurations/DoSubmitFormActif";
import FormFieldOvh from "../../components/actifs-configurations/form/FormFieldOvh";
import { FormBuilderProduitServiceConfig } from "../../components/actifs-configurations/form/FormBuilderProduitServiceConfig";

/**
 * Formulaire de création d'un actif (Etape 2) ou de modification d'un actif.
 *
 */
const FormActifConfig = () => {
  /** -------------------------------- */
  //  STATES //
  /** -------------------------------- */
  // LOADING STATES //
  const [isLoaded, setIsLoaded] = useState(false); // if false data isn't loaded yet
  const [loadingStates, setLoadingStates] = useState({
    // isParentPSLoaded && isProduitServiceCategoriesLoaded = true then => isLoaded = true
    isParentPSLoaded: false,
    isProduitServiceCategoriesLoaded: false,
  });
  /** -------------------------------- */
  // DATA STATES //
  const [currentActifConfig, setCurrentActifConfig] = useState(); // in edit, current object to treat in form
  const [parentPS, setParentPS] = useState(); // list produit service for select field
  const [produitServiceCategories, setProduitServiceCategories] = useState(); // list produit service categories for select field
  const [vadeSecureCustomers, setVadeSecureCustomers] = useState([]); // response vade secure customers for select field
  const [valueField, setValueField] = useState([]); // custom fields for produit service configuration
  const [companiesAlso, setCompaniesAlso] = useState(); // list companies also for select field
  const [options, setOptions] = useState([]); // response societe for autocomplete field
  const [bitdefenderCustomers, setBitdefenderCustomers] = useState(); // response bitdefender customers for select field
  /** -------------------------------- */
  // FORM STATES //
  const [isEditForm, setIsEditForm] = useState(false); // if true, display edit form
  const [formStates, setFormStates] = useState({
    isVadeSecureForm: false,
    isBitdefenderForm: false,
    isGsuiteForm: false,
    isOpnSenseForm: false,
    isVPNForm: false,
    isHebergementForm: false,
    isAlsoCustomer: false,
    isSauvegardeForm: false,
    isLogicielForm: false,
    isTelephonieAsteriskForm: false,
    isTelephonieOvhForm: false,
  });

  /** -------------------------------- */
  //  OTHERS //
  /** -------------------------------- */
  const { getUser } = useContext(UserContext);
  const rq = new RequestHelper();
  const currentId = useParams().id;
  const navigate = useNavigate();
  const user = getUser();

  /**
   * Searches for categories related to a produit service.
   *
   * @param {number} id - The ID of the parent category.
   * @returns {void}
   */
  const searchCategoriesPS = (id) => {
    rq.doRequest(_ACTIONS.GET, _ROUTES.PRODUIT_SERVICE, {
      parent: id,
    })
      .then((res) => {
        setProduitServiceCategories(res.data["hydra:member"]);
        setLoadingStates((prevState) => ({
          ...prevState,
          isProduitServiceCategoriesLoaded: true,
        }));
      })
      .catch((err) => {
        createToast("error", "Erreur de récupération des catégories");
      });
  };

  // FIRSTLY GET SOCIETES & PRODUIT | SERVICE //
  useEffect(() => {
    getSocietes(setOptions);
    getCataloguesProduitService(setParentPS, setLoadingStates);
  }, []);

  //------------------------------------------
  //-------------EDIT MODE--------------------
  //------------------------------------------

  /* 1- IN EDIT: IN FIRST GET THE CURRENT ACTIF OBJECT */
  useEffect(() => {
    if (currentId) {
      setIsEditForm(true);
      getCurrentActifConfig();
    }
  }, []);

  const getCurrentActifConfig = () => {
    rq.doRequest(
      _ACTIONS.GET,
      `${_ROUTES.SOCIETE_PRODUIT_SERVICE}/${currentId}`
    )
      .then((res) => {
        setCurrentActifConfig(res.data);
      })
      .catch(() => {
        createToast("error", "Une erreur est survenue");
      });
  };

  function getFormikInitialesValues() {
    const initialValuesForm = valuesFormActifConfig(currentActifConfig);
    formik.setValues(initialValuesForm);
    return initialValuesForm;
  }

  /* 2-: IN EDIT: GET VALUEFIELDS & FORMIK INITIAL VALUES */
  useEffect(() => {
    if (currentActifConfig) {
      const valuesStepTwo = getValueFieldFormStepTwo(currentActifConfig);
      setValueField(valuesStepTwo);
      searchCategoriesPS(currentActifConfig.produitService?.id);
    }
  }, [currentActifConfig]);

  useEffect(() => {
    if (isEditForm && isLoaded) {
      getFormikInitialesValues();
    }
  }, [isLoaded, isEditForm]);

  // AND SET CUSTOM LOADER IF EDIT OR NOT //
  useEffect(() => {
    if (isEditForm) {
      setIsLoaded(
        loadingStates.isParentPSLoaded &&
          loadingStates.isProduitServiceCategoriesLoaded
          ? true
          : false
      );
    } else {
      setIsLoaded(loadingStates.isParentPSLoaded ? true : false);
    }
  }, [
    loadingStates.isParentPSLoaded,
    loadingStates.isProduitServiceCategoriesLoaded,
    isEditForm,
  ]);

  const formik = DoSubmitFormActif({
    currentActifConfig,
    formStates,
    currentId,
    valueField,
    companiesAlso,
  });

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <FormTitle title={_ACTIFS_CONFIGURATION.h1} />
      <Container
        component="main"
        maxWidth={false}
        sx={{
          backgroundColor: "white",
          padding: "1rem",
          borderTop: `3px solid ${_COLORS.grey}`,
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ borderBottom: `1px solid ${_COLORS.midGrey}` }}
        >
          {_ACTIFS_CONFIGURATION.h2}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormStepOne
            formik={formik}
            parentPS={parentPS}
            options={options}
            produitServiceCategories={produitServiceCategories}
            isEditForm={isEditForm}
            searchCategoriesPS={searchCategoriesPS}
          />
          {/* DISPLAY FIXED P|S CONFIG  */}
          {currentActifConfig && (
            <FormBuilderProduitServiceConfig
              formik={formik}
              produitServiceConfig={
                currentActifConfig?.produitService?.produitServiceConfig
              }
            />
          )}
          {/*  display VADESECURE form fields */}
          {currentActifConfig &&
            currentActifConfig.produitServiceCategorie?.name ===
              "VADE SECURE" && (
              <FormFieldsVadeSecure
                formik={formik}
                user={user}
                formStates={formStates}
                setFormStates={setFormStates}
                setVadeSecureCustomers={setVadeSecureCustomers}
                vadeSecureCustomers={vadeSecureCustomers}
              />
            )}
          {/*  display BITDEFENDER form fields */}
          {currentActifConfig &&
            currentActifConfig.produitServiceCategorie?.name ===
              "BITDEFENDER" && (
              <FormFieldsBitdefender
                formik={formik}
                formStates={formStates}
                setFormStates={setFormStates}
                bitdefenderCustomers={bitdefenderCustomers}
                setBitdefenderCustomers={setBitdefenderCustomers}
              />
            )}
          {/*  display Google Workspace form fields */}
          {currentActifConfig &&
            currentActifConfig.produitServiceCategorie?.name ===
              "GOOGLE WORKSPACE" && (
              <FormFieldsGsuite
                currentActifConfig={currentActifConfig}
                formik={formik}
                user={user}
                formStates={formStates}
                setFormStates={setFormStates}
                isLoaded={isLoaded}
              />
            )}
          {currentActifConfig &&
            currentActifConfig.produitServiceCategorie?.alsoService && (
              <FormFieldAlso
                currentActifConfig={currentActifConfig}
                formik={formik}
                formStates={formStates}
                setFormStates={setFormStates}
                setCompaniesAlso={setCompaniesAlso}
                companiesAlso={companiesAlso}
              />
            )}
          {/*  display OpnSense form fields */}
          {currentActifConfig &&
            currentActifConfig.produitServiceCategorie?.name === "OPNSENSE" && (
              <FormFieldsOpnSense
                currentActifConfig={currentActifConfig}
                formik={formik}
                formStates={formStates}
                setFormStates={setFormStates}
              />
            )}
          {/*  display VPN form fields */}
          {currentActifConfig &&
            currentActifConfig.produitServiceCategorie?.name === "OPEN VPN" && (
              <FormFieldsVpn
                formik={formik}
                setFormStates={setFormStates}
                formStates={formStates}
              />
            )}
          {/*  display Hébergement form fields */}
          {currentActifConfig?.produitService?.name === "HÉBERGEMENT" && (
            <FormFieldHebergement
              formik={formik}
              formStates={formStates}
              setFormStates={setFormStates}
            />
          )}
          {currentActifConfig?.produitService?.name === "SAUVEGARDES" && (
            <FormFieldSauvegarde
              formik={formik}
              formStates={formStates}
              setFormStates={setFormStates}
            />
          )}
          {/*  display Logiciel form fields */}
          {currentActifConfig?.produitService?.name === "LOGICIELS" && (
            <FormFieldLogiciel
              formik={formik}
              formStates={formStates}
              setFormStates={setFormStates}
            />
          )}
          {/*  display Téléphonie Asterisk form fields */}
          {currentActifConfig?.produitServiceCategorie?.name === "ASTERISK" && (
            <FormFieldAsterisk
              formik={formik}
              formStates={formStates}
              setFormStates={setFormStates}
            />
          )}
          {/*  display Téléphonie Asterisk form fields */}
          {currentActifConfig?.produitServiceCategorie?.name === "OVH" && (
            <FormFieldOvh
              formik={formik}
              formStates={formStates}
              setFormStates={setFormStates}
              requestHelper={rq}
            />
          )}
          {/* Produit Service Configuration Field */}
          <FormStepTwo formik={formik} valueField={valueField} />
          <Grid container spacing={1} marginTop={2}>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  navigate(_ROUTER_DOM_URL.ACTIF_CONFIGURATION_LIST);
                }}
                color="inherit"
              >
                Retour à la liste des actifs
              </Button>
              {/* @TODO: Vérifier affichage lorsqu'on est en création d'un produit service  */}
              {currentActifConfig.produitServiceCategorie &&
              currentActifConfig.produitServiceCategorie["@id"] ===
                "/api/produit_services/55"
                ? (() => {
                    const customerDomain =
                      currentActifConfig.societeProduitServiceGsuite
                        .customerDomain;
                    return (
                      <Button
                        component="button"
                        variant="outlined"
                        color="success"
                        title="Afficher les utilisateurs Gsuite"
                        onClick={() => {
                          navigate(
                            `${_ROUTER_DOM_URL.ACTIF_CONFIGURATION_GSUITE}/${customerDomain}`
                          );
                        }}
                      >
                        Utilisateurs Gsuite
                      </Button>
                    );
                  })()
                : null}
              <Button
                type="submit"
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: _COLORS.defaultGreen,
                  color: "white",
                  ":hover": {
                    backgroundColor: _COLORS.hoverDefaultGreen,
                  },
                }}
              >
                Mettre à jour
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default FormActifConfig;
