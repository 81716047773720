import { Box, Grid, Typography, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import TelephonieOvhClients from "../../components/ovh/telephonie/TelephonieOvhClients";
import TelephonieOvhGroups from "../../components/ovh/telephonie/TelephonieOvhGroups";
import TelephonieOvhLines from "../../components/ovh/telephonie/TelephonieOvhLines";
import RecapTelephonie from "../../components/ovh/telephonie/RecapTelephonie";
import { useLocation } from "react-router-dom";
import _ from "lodash";

const RecapTelephonieOvh = () => {
    const textDebut = "Pour commencer, merci de bien vouloir choisir une ligne...";
    const [isAlert, setIsAlert] = useState(true);
    const [client, setClient] = useState(0);
    const [group, setGroup] = useState(0);
    const [selectsGroup, setSelectsGroup] = useState(0);
    const [selectGroups, setSelectGroups] = useState([]);
    const [line, setLine] = useState(0);
    const [linesDetails, setLinesDetails] = useState({});
    const [queue, setQueue] = useState(null);
    const {state} = useLocation();
    const [lineNumber, setLineNumber] = useState(null);

    useEffect(() => {
        if(state !== null){
            if(state.client !== client){
                setClient(state.client);
                setSelectsGroup(state.client?.societeProduitServiceOvh?.billingAccount);
            }
    
            if(state.group !== group){
            setGroup(state.group);
            }
    
            if(state.line){
                setLineNumber(state.line);
            }    
        }
    }, []);

    /**
   * Handles the change event of the group selection.
   * @param {Object} event - The event object.
   */
    const handleChangeGroup = (event) => {
        const billingAccount = event.target.value;
        const group = getGroupById(billingAccount);
        setGroup(group);
        setIsAlert(true);
        setLine(0);
        setLineNumber(null);
    };

    /**
    * Retrieves a group object by its billing account.
    *
    * @param {string} billingAccount - The billing account of the group.
    * @returns {object|undefined} - The group object if found, otherwise undefined.
    */
    const getGroupById = (billingAccount) => {
        return selectGroups.find(group => group.billingAccount === billingAccount);
    }

    return(
        <>
            <Typography variant="h4">Téléphonie ovh</Typography>
            <Box sx={{marginTop:"20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TelephonieOvhClients  
                            client={client} 
                            setClient={setClient}
                            setSelectsGroup={setSelectsGroup}
                            setGroup={setGroup}
                            setIsAlert={setIsAlert}
                            setLine={setLine}
                        />
                    </Grid>
                    {client !== 0 && (
                        <Grid item xs={3}>
                            {selectsGroup?.length > 0 && (<TelephonieOvhGroups 
                                                            groups={selectsGroup}
                                                            handleChangeGroup={handleChangeGroup}
                                                            group={group} 
                                                            selectGroups={selectGroups}
                                                            setSelectGroups={setSelectGroups}
                                                        />)}
                        </Grid>
                    )}
                    {group !== 0 && (
                        <Grid item xs={3}>
                            <TelephonieOvhLines
                                client={client} 
                                group={group}
                                setLine={setLine}
                                setIsAlert={setIsAlert}
                                linesDetails={linesDetails}
                                setLinesDetails={setLinesDetails}
                                setQueue={setQueue}
                                lineNumber={lineNumber}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box sx={{marginTop:"20px"}}>
                {isAlert 
                    ? <Alert severity="info">{textDebut}</Alert>
                    : <RecapTelephonie line={line} linesDetails={linesDetails} queue={queue} setQueue={setQueue} lineNumber={lineNumber} />
                }
            </Box>
        </>
    );
};

export default RecapTelephonieOvh;