import Chip from '@mui/material/Chip';
import { MUI_THEME } from '../../../style/MuiTheme';
import { Box } from '@mui/material';
import { getPourcentage } from './function';
import { useEffect, useState } from 'react';


const MenuPourcentage = () => {
    const theme = MUI_THEME;
    const [pourcentage, setPourcentage] = useState([]);

    useEffect(() => {
        if(getPourcentage.length === 0) {
            setPourcentage(getPourcentage());
        }
    }, []);

    return (
        <Box theme={theme} sx={{marginTop: 5}}>
            {pourcentage?.map((item, index) => (
                <Chip
                    key={index}
                    label={item.text}
                    color={item.color}
                    style={{margin: "5px"}}
                    size="small" 
                    variant="outlined" 
                />
            ))}
        </Box>
    )
};

export default MenuPourcentage;