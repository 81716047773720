import {
  DesktopDateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { __FORM_STYLES } from "../../utils/_style_constants";
import { FormControl } from "@mui/material";
import _ from "lodash";
import { _DATE_FORMAT } from "../../utils/constants";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { editInstantRequest } from "../../utils/function";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";

export const InstantDateTimePicker = ({
  entityValue,
  setEntityValue,
  label,
  field,
  format = _DATE_FORMAT.date_and_time_display_no_bar,
  disabled = false,
}) => {
  const [mobile, setMobile] = useState(true);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (_.isEmpty(user)) {
      setMobile(user.dateTimePickerMode === "mobile");
    }
  }, [user]);

  const handleChangeDateTime = (e) => {
    setEntityValue({
      ...entityValue,
      [field]: moment(e).format(_DATE_FORMAT.classic),
    });
  };

  const handleUpdateBackEnd = () => {
    editInstantRequest(entityValue[field], entityValue["@id"], field);
  };

  const props = {
    ampm: false,
    format: format,
    value: !_.isNull(entityValue) ? moment(entityValue[field]) : "",
    onChange: handleChangeDateTime,
    onClose: handleUpdateBackEnd,
    label: label,
    disabled: disabled,
    slotProps: {
      textField: {
        size: "small",
      },
    },
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
        <FormControl sx={__FORM_STYLES.datePickers} size="small">
          {mobile ? (
            <MobileDateTimePicker {...props} />
          ) : (
            <DesktopDateTimePicker {...props} />
          )}
        </FormControl>
      </LocalizationProvider>
    </>
  );
};

export default InstantDateTimePicker;
