import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Box } from "@mui/system";
import { FaCalendarDay, FaCalendarWeek, FaCalendarAlt, FaCalendar } from "react-icons/fa";

const MenuEquipe = ({menuChoice, setMenuChoice}) => {

    return (
        <Box sx={{with: 500}}>
            <BottomNavigation
                showLabels
                value={menuChoice}
                onChange={(event, newValue) => {
                    setMenuChoice(newValue);
                }}
            >
                <BottomNavigationAction sx={{ fontSize: 16 }}  label="Activités du jour" icon={<FaCalendarDay size="30px" style={{margin:"5px"}} />} />
                <BottomNavigationAction sx={{ fontSize: 16 }} label="Historique semaine" icon={<FaCalendarWeek size="30px" style={{margin:"5px"}} />}/>
                <BottomNavigationAction sx={{ fontSize: 16 }} label="Historique mois" icon={<FaCalendarAlt size="30px" style={{margin:"5px"}} />}/>
                <BottomNavigationAction sx={{ fontSize: 16 }} label="Historique année" icon={<FaCalendar size="30px" style={{margin:"5px"}} />} />
            </BottomNavigation>
        </Box>
    );
};

export default MenuEquipe;