import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import ColonneSiteGeographique from "../ColonneSiteGeographique";
import { RequestHelper } from "../../../utils/requestHelper";

const SitesGeographiquesList = ({
  showList,
  setShowList,
  sitesGeographiques,
  setSitesGeographiques,
}) => {
  const requestHelper = new RequestHelper();
  const columns = ColonneSiteGeographique({
    sitesGeographiques,
    requestHelper,
    setSitesGeographiques,
  });

  return (
    <>
      <Grid
        container
        spacing={1}
        marginTop={1}
        sx={{
          height: "15rem",
        }}
      >
        <DataGrid
          rows={sitesGeographiques}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          hideFooter
          hideFooterPagination
          hideFooterRowCount
        />
      </Grid>
      <Grid marginTop={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowList(!showList)}
        >
          Retour
        </Button>
      </Grid>
    </>
  );
};

export default SitesGeographiquesList;
