import React from "react";
import { ROLES, _ACTIONS } from "../../utils/constants";
import { LuUserSquare } from "react-icons/lu";
import { MdOutlineToggleOff } from "react-icons/md";
import { MdOutlineToggleOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IconButton, Typography } from "@mui/material";
import { _ROUTER_DOM_URL, _ROUTES } from "../../utils/_routes_constants";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { toUpper } from "lodash";

/**
 * Component representing the columns configuration for the Utilisateur.jsx.
 *
 * @component
 * @param {Object[]} users - The array of user objects.
 * @param {Function} setUsers - The function to update the array of user objects.
 * @returns {Object[]} The columns configuration for the "Utilisateur" table.
 */
const ColonnesUtilisateur = ({ users, setUsers }) => {
  const Navigate = useNavigate();
  const rq = new RequestHelper();

  /**
   * Toggles the status of a user.
   *
   * @param {string} userId - The ID of the user.
   * @param {boolean} currentStatus - The current status of the user.
   * @returns {void}
   */
  const toggleUserStatus = (userId, currentStatus) => {
    rq.doRequest(_ACTIONS.PUT, `${_ROUTES.USER}/${userId}`, {
      isEnabled: !currentStatus,
    })
      .then((response) => {
        if (200 !== response.status) {
          throw new Error(
            "Une erreur est survenue lors de la mise à jour de l'utilisateur"
          );
        }
        const updatedUsers = users.map((user) => {
          if (user.id === userId) {
            return { ...user, isEnabled: !currentStatus };
          }

          return user;
        });
        setUsers(updatedUsers);
      })
      .catch((error) => {
        createToast("error", error.message);
      });
  };

  const columnsUsers = [
    {
      field: "societe.name",
      headerName: "Client",
      width: 200,
      filterable: false,
      renderCell: (params) => {
        return (
          <Typography fontWeight={"bold"}>
            {params.row?.societe?.name ?? ""}
          </Typography>
        );
      },
    },
    {
      field: "roles",
      headerName: "Rôles",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        const roleKeys = params.row.roles ? Object.keys(params.row.roles) : [];
        const tmpRole = roleKeys.length > 1 ? roleKeys[1] : null;
        const role = tmpRole ? ROLES[tmpRole] : "";

        return <Typography>{role}</Typography>;
      },
    },
    {
      field: "email",
      headerName: "Adresse e-mail",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        return <span>{params.row?.email}</span>;
      },
    },
    {
      field: "firstName",
      headerName: "Nom complet",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        return (
          <Typography>
            {toUpper(params.row?.firstName) || ""} {params.row?.lastName || ""}
          </Typography>
        );
      },
    },
    {
      field: "poste",
      headerName: "Poste",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        return <Typography>{params.row?.poste}</Typography>;
      },
    },
    {
      field: "phone",
      headerName: "Téléphone",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        return <Typography>{params.row?.phone}</Typography>;
      },
    },
    {
      field: "createdAt",
      headerName: "Date de création",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        return <Typography>{params.row?.createdAt}</Typography>;
      },
    },
    {
      field: "updatedAt",
      headerName: "Date de modification",
      width: 150,
      filterable: false,
      /* @TODO: Add a date formatter */
    },
    {
      field: "connected",
      headerName: "Date connexion",
      width: 150,
      filterable: false,
      /* @TODO: Add a date formatter */
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <IconButton
            label="Edit"
            title="Mettre à jour l'utilisateur"
            color="inherit"
            onClick={() => {
              Navigate(`${_ROUTER_DOM_URL.USER_EDIT}/${row.id}`);
            }}
          >
            <LuUserSquare />
          </IconButton>,
          <IconButton
            label="Edit"
            title="Activer / Désactiver l'utilisateur"
            color="inherit"
            onClick={() => toggleUserStatus(row.id, row.isEnabled)}
          >
            {row.isEnabled ? (
              <MdOutlineToggleOn style={{ color: "green" }} />
            ) : (
              <MdOutlineToggleOff style={{ color: "red" }} />
            )}
          </IconButton>,
        ];
      },
    },
  ];

  return columnsUsers;
};

export default ColonnesUtilisateur;
