import { Box, IconButton, Link, Typography } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import React from "react";
import { _ACTIONS } from "../../utils/constants";
import { _ROUTES } from "../../utils/_routes_constants";
import { createToast } from "../../utils/toast";
import { isNull } from "lodash";

const ColonneSiteGeographique = (props) => {
  const { sitesGeographiques, requestHelper, setSitesGeographiques } = props;

  const deleteSiteGeographique = async (id) => {
    try {
      const res = await requestHelper.doRequest(
        _ACTIONS.DELETE,
        `${_ROUTES.SITE_GEOGRAPHIQUE}/${id}`
      );
      if (204 !== res.status) {
        throw new Error("Erreur lors de la suppression");
      }
      setSitesGeographiques(
        sitesGeographiques.filter((site) => site.id !== id)
      );
      createToast("success", "Site géographique supprimé avec succès");
    } catch (error) {
      createToast("error", error.message);
    }
  };

  const columnsSiteGeographique = [
    {
      field: "actions",
      headerName: " ",
      width: 50,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={() => {
                deleteSiteGeographique(params.row.id);
              }}
            >
              <RiDeleteBin5Line color="red" size={20} />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Nom",
      width: 100,
      renderCell: (params) => {
        return isNull(params.row.name) ? "" : params.row.name;
      },
    },
    {
      field: "adresse",
      headerName: "Adresse",
      width: 150,
      renderCell: (params) => {
        return isNull(params.row.adresse) ? "" : params.row.adresse;
      },
    },
    {
      field: "batiment",
      headerName: "Batiment",
      width: 150,
      renderCell: (params) => {
        return isNull(params.row.batiment) ? "" : params.row.batiment;
      },
    },
    {
      field: "phone",
      headerName: "Téléphone",
      width: 100,
      renderCell: (params) => {
        return <Link variant="body2">{params.row.phone}</Link>;
      },
    },
    {
      field: "responsable",
      headerName: "Responsable",
      width: 150,
      renderCell: (params) => {
        return isNull(params.row.responsable) ? (
          "N/A"
        ) : (
          <Typography variant="body2">
            {params.row.responsable.firstName +
              " " +
              params.row.responsable.lastName}
          </Typography>
        );
      },
    },
  ];

  return columnsSiteGeographique;
};

export default ColonneSiteGeographique;
