import { Box } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { _ACTIONS } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { _ROUTES } from "../../utils/_routes_constants";
import { ExpandedRows } from "../produit-service/ExpandedRows";
import { createToast } from "../../utils/toast";

const TableNext = ({
  setCatalogueNextSelected,
  catalogueNextSelected,
  refreshCounter,
}) => {
  const rq = new RequestHelper();
  const [catalogueNext, setCatalogueNext] = useState([]);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Produit | Service",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: catalogueNext,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        setCatalogueNextSelected(row);
      },
      sx: {
        cursor: "pointer",
        backgroundColor:
          catalogueNextSelected && catalogueNextSelected.id == row.id
            ? "#c7d7e5"
            : "#ffffff",
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
      },
    }),
    /* styles */
    muiTopToolbarProps: {
      sx: {
        backgroundColor: "#ffff",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: "#ffff",
      },
    },
    muiTableContainerProps: {
      sx: {
        height: "680px",
      },
    },
    /* pagination */
    paginationDisplayMode: "pages",
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount,

    /* filter */
    manualFiltering: true,
    onGlobalFilterChange: (value) => {
      if (value === undefined) {
        value = "";
      }
      setValue(value);
    },
    muiPaginationProps: {
      showRowsPerPage: false,
    },
    state: {
      isLoading,
      value,
      pagination,
      showProgressBars: isRefetching,
    },
    renderDetailPanel: ({ row }) => {
      return (
        <>
          <Box sx={{ width: "100%" }}>
            <ExpandedRows
              childsPS={row.original.childrens || []}
              alsoContext={true}
              setCatalogueNext={setCatalogueNext}
            />
          </Box>
        </>
      );
    },
  });

  useEffect(() => {
    if (catalogueNext.length === 0) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    try {
      const pageToFetch = pagination.pageIndex + 1;
      const limit = pagination.pageSize;
      rq.doRequest(_ACTIONS.GET, `${_ROUTES.PRODUIT_SERVICE}`, {
        "exists[parent]": false,
        name: value,
        page: pageToFetch,
        limit,
      }).then((res) => {
        let data = res.data["hydra:member"];
        setRowCount(res.data["hydra:totalItems"]);
        setCatalogueNext(data);
        setIsLoading(false);
        setIsRefetching(false);
      });
    } catch (error) {
      setIsRefetching(false);
      setIsLoading(false);
      createToast("error", "Erreur lors du chargement des données");
    }
  }, [value, refreshCounter, pagination.pageIndex, pagination.pageSize]);

  return (
    <Box sx={{ height: 800, width: "40%" }}>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default TableNext;
