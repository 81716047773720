import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useEffect } from "react";
import _ from "lodash";
import { getDataOvh } from "../../../repository/telephonies/Ovh";
import { _ROUTES } from "../../../utils/_routes_constants";

export const TelephonieOvhGroups = ({
  groups,
  handleChangeGroup,
  group,
  selectGroups,
  setSelectGroups,
}) => {

  useEffect(() => {
    setSelectGroups([]);
    getDataOvh(
      {billingAccounts: groups}, 
      setSelectGroups, 
      "Une erreur est survenue lors de la récupération des groupes",
      _ROUTES.GROUPS_OVH_DETAILS
      
    );
  }, [groups]);

  return (
    <>
      {!_.isEmpty(selectGroups) && 
        <FormControl key="fc-2" variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="client-group-label">Groupe</InputLabel>
          <Select
            labelId="client-group-label"
            id="client-group"
            value={group?.billingAccount ?? 0}
            onChange={handleChangeGroup}
          >
            <MenuItem key="b" value="0">
              Veuillez choisir un groupe
            </MenuItem>
            {selectGroups?.map((select) => (
              <MenuItem
                key={select?.billingAccount}
                value={select?.billingAccount}
              >
                {select?.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    </>
  );
};

export default TelephonieOvhGroups;
