import styled from "@emotion/styled";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import LabelForm from "../utils/LabelForm";
import { listyears } from "../../utils/constants";
import { getQueryParamsFromFormData,} from "../../utils/function";
import { _ROUTES } from "../../utils/_routes_constants";
import { getDataSociete } from "../../repository/Societe";
import { getDataDecompteTempsSociete } from "../../repository/decompte-temps/decompteTempsSociete";

const BoxFormFilter = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  backgroundColor: "white",
  border: `1px solid ${theme.palette.color.turquoiseBox}`,
}));
const FormFilterRecapDT = ({
  societes,
  setResultFilter,
  isExterne = false,
}) => {
  const [options, setOptions] = useState([]);
  const validationSchema = yup.object({
    years: yup.string(),
    type: yup.string(),
    societe: yup.string(),
  });

  const searchSociete = (value) => {
    (!value) 
      ? getDataSociete(
        {}, 
        setOptions, 
        "Une erreur est survenue lors de la récupération des sociétés", 
        _ROUTES.SOCIETE
      )
      : getDataSociete(
        { name: value }, 
        setOptions, 
        "Une erreur est survenue lors de la récupération des sociétés", 
        _ROUTES.SOCIETE
      );
  };
  useEffect(() => {
    searchSociete();
  }, []);

  return (
    <>
      <BoxFormFilter width={"100%"}>
        <Formik
          initialValues={{
            years: "",
            type: "",
            societe: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const params = getQueryParamsFromFormData(values);
            getDataDecompteTempsSociete(
              params, 
              setResultFilter, 
              "Une erreur est survenue lors de la récupération des données", 
              _ROUTES.DECOMPTE_TEMPS_SOCIETE
            );
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <LabelForm title="Année *" />
                  <FormControl
                    fullWidth
                    error={formik.touched.years && Boolean(formik.errors.years)}
                  >
                    <Select
                      labelId="years-label"
                      id="years"
                      value={formik.values.years}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.submitForm();
                      }}
                      name="years"
                      size="small"
                      sx={{ height: "30px" }}
                    >
                      {listyears.map((p) => (
                        <MenuItem key={p.id} value={p.name}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.years && formik.errors.years ? (
                      <FormHelperText>{formik.errors.years}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                {!isExterne && (
                  <>
                    {" "}
                    {/*TODO: en discution avec le client pour la suppression de ce champ
                    <Grid item xs={3}>
                      <LabelForm title="Type" />
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.type && Boolean(formik.errors.type)
                        }
                      >
                        <Select
                          labelId="type-label"
                          id="type"
                          value="Décompte en temps par client"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name="type"
                          size="small"
                          sx={{ height: "30px" }}
                          disabled
                        >
                          {typeDecompteTemps.map((type) => (
                            <MenuItem key={type.id} value={type.type}>
                              {type.type}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.type && formik.errors.type ? (
                          <FormHelperText>{formik.errors.type}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    */}
                    <Grid item xs={4}>
                      <LabelForm title="Société *" />
                      {societes && (
                        <Autocomplete
                          id="societe"
                          name="societe"
                          options={options ? options : []}
                          getOptionLabel={(option) =>
                            option ? option.name : "Veuillez choisir une option"
                          }
                          onChange={(e, value) => {
                            if (value === null) {
                              setResultFilter(null);
                              formik.resetForm();
                            } else {
                              formik.setFieldValue("societe", value.id);
                              formik.submitForm();
                            }
                          }}
                          onInputChange={(e, value) => {
                            searchSociete(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              name="societe"
                              sx={{ marginTop: "0px" }}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.societe &&
                                Boolean(formik.errors.societe)
                              }
                              {...params}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </BoxFormFilter>
    </>
  );
};

export default FormFilterRecapDT;
