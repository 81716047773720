import { Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { _ACTIONS } from "../../../utils/constants";
import { createToast } from "../../../utils/toast";
import MuiMultiSelect from "../../helpers/MuiMultiSelect";

export default function FormFieldOvh({
  formik,
  formStates,
  setFormStates,
  requestHelper,
}) {
  const [ovhCompanies, setOvhCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // TRUE = LOADING

  /**
   * Fetches the telephony list from the server and updates the state with the retrieved data.
   * @function telephonyList
   */
  function telephonyList() {
    setIsLoading(true);
    requestHelper
      .doRequest(_ACTIONS.GET, "get-telephony-list")
      .then((res) => {
        const companiesArray = Object.entries(res.data).map(([key, value]) => {
          return { key, value };
        });
        setOvhCompanies(companiesArray);
        setIsLoading(false);
      })
      .catch((err) => {
        createToast("error", "Erreur lors de la récupération des comptes OVH");
      });
  }

  // COSTLY OPERATION => useMemo
  const data = useMemo(() => {
    telephonyList();
  }, []);

  useEffect(() => {
    setFormStates({
      ...formStates,
      isTelephonieOvhForm: true,
    });
  }, []);

  /**
   * Updates the value of the "billingAccount" field in the formik form.
   *
   * @param {Object} event - The change event object.
   */
  const handleChange = (event) => {
    const value = event.target.value;
    // CATCH THE WHOLE OBJECT
    const newBillingAccount = value || [];
    // SET ONLY THE VALUE IN OBJCT
    formik.setFieldValue(
      "billingAccount",
      newBillingAccount.map((item) => item.value)
    );
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="center" marginTop={2}>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" component="div">
                <strong>Compte de facturation *</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <MuiMultiSelect
                id="billing-account"
                name="billingAccount"
                label="Compte de facturation"
                value={ovhCompanies.filter((item) =>
                  formik.values.billingAccount.includes(item.value)
                )}
                onChange={handleChange}
                options={ovhCompanies}
                formik={formik}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
