import ChipInfos from "../../components/stats/stat-equipe/ChipInfos";
import MenuEquipe from "../../components/stats/stat-equipe/MenuEquipe";
import MenuPourcentage from "../../components/stats/stat-equipe/MenuPourcentage";
import SelectWeek from "../../components/stats/stat-equipe/SelectWeek";
import SelectMonth from "../../components/stats/stat-equipe/SelectMonth";
import SelectYear from "../../components/stats/stat-equipe/SelectYear";
import TableEquipeDay from "../../components/stats/stat-equipe/TableEquipeDay";
import TableEquipeWeek from "../../components/stats/stat-equipe/TableEquipeWeek";
import TableEquipeMonth from "../../components/stats/stat-equipe/TableEquipeMonth";
import TableEquipeYear from "../../components/stats/stat-equipe/TableEquipeYear";
import TypeFacturation from "../../components/stats/stat-equipe/TypeFacturation";
import { _DATE_FORMAT, STATS } from "../../utils/constants";
import {Grid, Typography } from "@mui/material";
import { useState } from "react";
import moment from "moment";

const StatEquipe = () => { 
    const titre = "Activité d'équipe";
    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const [menuChoice, setMenuChoice] = useState(0);
    const [annee, setAnnee] = useState(moment().format(_DATE_FORMAT.year));
    const [mois, setMois] = useState(moment().format(_DATE_FORMAT.month));
    const [startWeek, setStartWeek] = useState(null);
    const [endWeek, setEndWeek] = useState(null);

    return (
        <>
            <Typography variant="h4">
                {STATS.h1}
                <span style={{fontSize:"0.6em", color:"grey", marginLeft:"10px"}}>{titre}</span>
            </Typography>
            <MenuEquipe menuChoice={menuChoice} setMenuChoice={setMenuChoice} />
            <MenuPourcentage />
            <ChipInfos />
            { menuChoice === 0 && 
                <>
                    <TypeFacturation setInvoiceTypes={setInvoiceTypes} />
                    <TableEquipeDay invoiceTypes={invoiceTypes} /> 
                </>
            }
            { menuChoice === 1 && 
                <>
                    <Grid container>
                        <Grid>
                            <SelectWeek setStartWeek={setStartWeek} startWeek={startWeek} setEndWeek={setEndWeek} endWeek={endWeek} />
                        </Grid>
                        <Grid item sx={{mt: 1}}>
                            <TypeFacturation setInvoiceTypes={setInvoiceTypes} />   
                        </Grid>
                    </Grid>
                    <TableEquipeWeek invoiceTypes={invoiceTypes} startWeek={startWeek} endWeek={endWeek} /> 
                </>
            }
            { menuChoice === 2 && 
                <>
                    <Grid container>
                        <Grid item >
                            <SelectMonth setMois={setMois} />
                        </Grid>
                        <Grid item >
                            <SelectYear setAnnee={setAnnee} />
                        </Grid>
                    </Grid>
                    <TableEquipeMonth mois={mois} annee={annee} /> 
                </>
            }
            { menuChoice === 3 && 
                <>
                    <SelectYear setAnnee={setAnnee} />
                    <TableEquipeYear annee={annee} /> 
                </>
            }
        </>
    );
}

export default StatEquipe;