// NOT ASSOCIED SUFFIXE URL & SELECTEDPRODUITSERVICE.ID //
export const _POSTE_NOT_ASSOCIED = "POSTE_NOT_ASSOCIED";
export const _GSUITE_NOT_ASSOCIED = "GSUITE_NOT_ASSOCIED";
export const _DEFAULT_ID_GSUITE = 63;

/* texts/titles related to actif & configuration */
export const _TEXTS_ACTIFS_CONFIGS = {
  TITLE_BOX_INFO: "Liste des actifs et configurations, schémas",
  LOADER_BOX_INFO:
    "Pour commencer, merci de bien vouloir choisir un produit ou service...",
};

export const _MESSAGES_ACTIF_CONFIG = {
  BAD_REQUEST: "Une erreur est survenue lors de la soumission des données",
};

/* alias headers form column configuration Produit Service */
export const aliasColumnConfiguration = {
  champsName: "Name",
  champsIpLocal: "IP Local",
  champsDynhost: "DynDNS",
  champsLieu: "Lieu",
  champsOS: "Système d'exploitation",
  champsFabricant: "Fabricants",
  champsSerie: "N° Série",
  champsDomains: "Domaines",
  champsFti: "Fti",
  champsLigneSupport: "Ligne Support",
  champsMac: "Adresse Mac",
  champsMarque: "Marque",
  champsModel: "Modèle",
  champsProcesseur: "Architecture processeur",
  champsBridge: "Mode Bridge",
  champsUsager: "Champs Usager",
};

/* determines order & initial state of columns */
export const columnsVisibility = {
  /* commons cols */
  "societe.name": true,
  "produitServiceCategorie.name": true,
  "societe.ville": true,
  /* produit service configuration cols */
  bridge: false,
  domains: false,
  dynhost: true,
  fabricant: false,
  fti: false,
  ipLocal: false,
  lieu: true,
  mac: false,
  marque: false,
  model: false,
  name: false,
  os: false,
  processeur: false,
  serie: false,
  usager: false,
  ligneSupport: false,
  /* antivirus cols */
  "societeProduitServiceBitdefender.subscriptionType": false,
  "societeProduitServiceBitdefender.isExpiry": false,
  quantite: false,
  "societeProduitServiceBitdefender.endpointMonthlyUsage": false,
  "societeProduitServiceBitdefender.patchManagementMonthlyUsage": false,
  "societeProduitServiceBitdefender.encryptionMonthlyUsage": false,
  licenceVadeSecure: false,
  "societeProduitServiceVadeSecure.duration": false,
  /* messaging cols */
  "societeProduitServiceGsuite.customerDomain": false,
  "societeProduitServiceGsuite.subscriptionType": false,
  "societeProduitServiceGsuite.subscriptionDate": false,
  "societeProduitServiceGsuite.licensedNumberOfSeats": false,
  /* router firewall cols */
  "societeProduitServiceOpnsense.osVersion": false,
  "societeProduitServiceOpnsense.productVersion": false,
  dateUpdate: false,
  /* vpn cols */
  "societeProduitServiceOpenVpn.protocole": false,
  "societeProduitServiceOpenVpn.vpnServeur": false,
  "societeProduitServiceOpenVpn.port": false,
  "societeProduitServiceOpenVpn.adresseVPN": false,
  "societeProduitServiceOpenVpn.ip": false,
  "societeProduitServiceOpenVpn.dns": false,
  "societeProduitServiceOpenVpn.gateway": false,
  "societeProduitServiceOpenVpn.fullTunnel": false,
  "societeProduitServiceOpenVpn.certificatDate": false,
  /* hebergement cols */
  "societeProduitServiceHebergement.domaine": true,
  "societeProduitServiceHebergement.dbName": true,
  "societeProduitServiceHebergement.dbUser": true,
  "societeProduitServiceHebergement.dbPassword": false,
  "societeProduitServiceHebergement.ftpUser": true,
  "societeProduitServiceHebergement.ftpPassword": false,
  "societeProduitServiceHebergement.cms": false,
  "societeProduitServiceHebergement.version": false,
  "societeProduitServiceHebergement.serveurWeb": false,
  "societeProduitServiceHebergement.webmaster": false,
  "societeProduitServiceHebergement.docGen": false,
  /* sauvegarde cols */
  "societeProduitServiceSauvegarde.prtgSensor": false,
  nomnas: true,
  nomtache: true,
  "societeProduitServiceSauvegarde.dateLastBackup": true,
  "societeProduitServiceSauvegarde.updatedAt": true,
  /* logiciel */
  "societeProduitServiceLogiciel.posteName": true,
  "societeProduitServiceLogiciel.licenseOfficiel": true,
  "societeProduitServiceLogiciel.licenseEmail": true,
  "societeProduitServiceLogiciel.emailAssocier": true,
  "societeProduitServiceLogiciel.dateDownload": false,
  "societeProduitServiceLogiciel.dateInstall": false,
  /* téléphonie cols */
  /* service asterisk */
  "societeProduitServiceAsterisk.user": false,
  "societeProduitServiceAsterisk.password": false,
  /* service ovh */
  "societeProduitServiceOvh.billingAccount": true,
  /* action */
  decompteTemps: true,
  isActive: true,
  actions: true /* actions in last position */,
};

/* INITIAL VALUES USED FOR A POST ACTIF CONFIG FORM */
export const getInitialEmptyValues = (currentActifConfig) => {
  return {
    // PRODUIT SERVICE FORM (FORM STEP ONE) //
    societe: "",
    produitService: "",
    produitServiceCategorie: "",
    quantite: "",
    decompteTemps: false,
    description: "",
    // FIXED PS CONFIG //
    ...FIXED_PRODUIT_SERVICE_CONFIG.textFields.reduce((fields, field) => {
      fields[field.name] = "";
      return fields;
    }, {}),
    ...FIXED_PRODUIT_SERVICE_CONFIG.arrayFields.reduce((fields, field) => {
      fields[field.name] = [];
      return fields;
    }, {}),
    ...FIXED_PRODUIT_SERVICE_CONFIG.booleanFields.reduce((fields, field) => {
      fields[field.name] = false;
      return fields;
    }, {}),
    // PRODUIT SERVICE CONFIGURATION (FORM STEP TWO) //
    valueField: [],
    // VADESECURE FORM //
    clientId: "",
    startDate: null,
    endDate: null,
    //* BITDEFENDER FORM *//
    compagnies: "",
    // GSUITE FORM //
    customerId: "",
    customerDomain: "",
    // ALSO FORM //
    accountId: "",
    // OPNSENSE FORM //
    dynhost: "",
    ipLocal: "",
    apiKey: "",
    apiSecret: "",
    dateMaj: null,
    location: false,
    version: "",
    supervision: false,
    cluster: false,
    zfs: false,
    ipTunnel: "",
    installationDate: null,
    uptime: null,
    fournisseur: "",
    numberPhysicalInterface: "",
    urlBackup: "",
    unbound: false,
    webAccess: "",
    // contrat: "", @TODO: to add when contact done
    // VPNForm //
    protocole: "",
    vpnServeur: "",
    ports: "",
    adresseVpn: "",
    adresseIp: "",
    dns: "",
    gateway: "",
    fullTunnel: "",
    certificatDate: null,
    configFile: "",
    // HEBERGEMENT FORM //
    ...HEBERGEMENT_FORM_FIELD.reduce((fields, field) => {
      fields[field.name] = "";
      return fields;
    }, {}),
    isSsl: currentActifConfig?.societeProduitServiceHebergement?.isSsl ?? false,
    ...SAUVEGARDE_FORM_FIELD.reduce((fields, field) => {
      fields[field.name] = "";
      return fields;
    }, {}),
    // LOGICIEL FORM //
    ...LOGICIEL_FORM_FIELD.reduce((fields, field) => {
      if (field.type === "text") {
        fields[field.name] = "";
      } else if (field.type === "date") {
        fields[field.name] = null;
      }
      return fields;
    }, {}),
    // TELEPHONIE FORM //
    // ASTERISK FORM //
    user: "",
    password: "",
    // OVH FORM //
    billingAccount: [], // must be an array (multiple select input value)
  };
};

export const sizeColumnMuiTable = {
  actions: 50, // actions cols || buttons
  minSizeLongText: 150,
  sizeLongText: 180, // > 150
  sizeMediumText: 150, // < 100 || date
  minSizeMediumText: 100,
  sizeShortText: 100,
  minSizeShortText: 50,
  tinyText: 40, // for numbers
};

/** -------------------------------- */
//  PATTERNS OF FIELDS FOR EACH SUB FORM  //
/** -------------------------------- */

/* PATTERN HEBERGEMENTS */
export const HEBERGEMENT_FORM_FIELD = [
  {
    id: "domaine",
    name: "domaine",
    label: "Domaine",
    size: 4, // size of the field
    required: true,
  },
  {
    id: "dbName",
    name: "dbName",
    label: "DataBase",
    size: 4,
    required: false,
  },
  {
    id: "dbUser",
    name: "dbUser",
    label: "User dataBase",
    size: 4,
    required: false,
  },
  {
    id: "ftpUser",
    name: "ftpUser",
    label: "User FTP",
    size: 4,
    required: false,
  },
  {
    id: "ftpPassword",
    name: "ftpPassword",
    label: "Password FTP",
    size: 5,
    required: false,
  },
  {
    id: "cms",
    name: "cms",
    label: "CMS",
    size: 6,
    required: false,
  },
  {
    id: "version",
    name: "version",
    label: "Version",
    size: 3,
    required: false,
  },
  {
    id: "serveurWeb",
    name: "serveurWeb",
    label: "CMS",
    size: 4,
    required: false,
  },
  {
    id: "webmaster",
    name: "webmaster",
    label: "Webmaster",
    size: 6,
    required: false,
  },
  {
    id: "docGen",
    name: "docGen",
    label: "Doc général",
    size: 4,
    required: false,
  },
];

/* PATTERN SAUVEGARDE */
export const SAUVEGARDE_FORM_FIELD = [
  {
    id: "nomnas",
    name: "nomnas",
    label: "Nom du NAS",
    size: 4,
    required: false,
  },
  {
    id: "nomtache",
    name: "nomtache",
    label: "Nom de la tâche",
    size: 4,
    required: false,
  },
];

/* PATTERN LOGICIEL */
export const LOGICIEL_FORM_FIELD = [
  {
    name: "posteName",
    label: "Postes associés",
    size: 4,
    required: false,
    type: "text",
  },
  {
    name: "licenseOfficiel",
    label: "License",
    size: 4,
    required: false,
    type: "text",
  },
  {
    name: "licenseEmail",
    label: "Licence autres",
    size: 4,
    required: false,
    type: "text",
  },
  {
    name: "emailAssocier",
    label: "Email associé",
    size: 4,
    required: false,
    type: "text",
  },
  {
    name: "dateDownload",
    label: "Date de téléchargement",
    size: 3,
    required: true,
    type: "date",
  },
  {
    name: "dateInstall",
    label: "Date d'installation",
    size: 3,
    required: true,
    type: "date",
  },
];

/* PATTERN TELEPHONIE.ASTERISK */
export const TELEPHONIE_ASTERISK_FORM_FIELD = [
  {
    name: "user",
    label: "Identifiant",
    size: 5,
    required: false,
    type: "text",
  },
  {
    name: "password",
    label: "Mot de passe",
    size: 5,
    required: false,
    type: "text",
  },
];

/* PATTERN FIXED PRODUIT SERVICE CONFIG */
export const FIXED_PRODUIT_SERVICE_CONFIG = {
  textFields: [
    {
      name: "lieu",
      fieldConfig: "champsLieu",
      label: "Lieu",
      size: 4,
    },
    {
      name: "os",
      fieldConfig: "champsOs",
      label: "Système d'exploitation",
      size: 4,
    },
    {
      name: "fabricant",
      fieldConfig: "champsFabricant",
      label: "Fabricants",
      size: 4,
    },
    {
      name: "serie",
      fieldConfig: "champsSerie",
      label: "N° Série",
      size: 4,
    },

    {
      name: "fti",
      fieldConfig: "champsFti",
      label: "Fti",
      size: 4,
    },
    {
      name: "ligneSupport",
      fieldConfig: "champsLigneSupport",
      label: "Ligne Support",
      size: 4,
    },

    {
      name: "marque",
      fieldConfig: "champsMarque",
      label: "Marque",
      size: 4,
    },
    {
      name: "model",
      fieldConfig: "champsModel",
      label: "Modèle",
      size: 4,
    },
    {
      name: "name",
      fieldConfig: "champsName",
      label: "Name",
      size: 4,
    },
    {
      name: "processeur",
      fieldConfig: "champsProcesseur",
      label: "Architecture processeur",
      size: 4,
    },
    {
      name: "usager",
      fieldConfig: "champsUsager",
      label: "Usager",
      size: 4,
    },
    {
      name: "ipLocal",
      fieldConfig: "champsIpLocal",
      label: "IP Local",
      size: 4,
      type: "text",
    },
    {
      name: "domains",
      fieldConfig: "champsDomains",
      label: "Domaines",
      size: 4,
    },
  ],
  arrayFields: [
    {
      name: "dynhost",
      fieldConfig: "champsDynhost",
      label: "DynDNS",
      size: 4,
    },
    {
      name: "mac",
      fieldConfig: "champsMac",
      label: "Adresse Mac",
      size: 4,
    },
  ],
  booleanFields: [
    {
      name: "bridge",
      fieldConfig: "champsBridge",
      label: "Mode Bridge",
      size: 4,
    },
  ],
};
