import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Box, IconButton } from "@mui/material";
import { _ACTIONS } from "../../utils/constants";
import { RequestHelper } from "../../utils/requestHelper";
import { createConfirmToast, createToast } from "../../utils/toast";
import { _ROUTES } from "../../utils/_routes_constants";
import { ChipTransformer } from "../utils/ChipTransformer";
import { stripHtml } from "../../utils/function";
import { ToggleTransformer } from "../utils/ToggleTransformer";

export const ExpandedRows = ({
  childsPS,
  alsoContext = false,
  setCatalogueNext,
  setShowModal,
  setIsEdit,
  setRowId,
  setIsCategorie,
}) => {
  const requestHelper = new RequestHelper();
  const [produitServiceCategories, setProduitServiceCategories] = useState([]);

  useEffect(() => {
    setProduitServiceCategories(childsPS);
  }, [childsPS]);

  const doUpdateStatus = async (params) => {
    const status = params.row.isActive;
    const res = await createConfirmToast(
      "error",
      null,
      "Êtes-vous sûr de vouloir désactiver la catégorie ?",
      "Oui",
      "Non"
    );
    if (res) {
      requestHelper
        .doRequest(
          _ACTIONS.PUT,
          `${_ROUTES.PRODUIT_SERVICE}/${params.row.id}`,
          {
            isActive: !status,
          }
        )
        .then((updateResponse) => {
          if (updateResponse.status === 200) {
            createToast("success", "Mise à jour effectuée avec succès");
          }
          setProduitServiceCategories((prevData) => {
            const updatedData = prevData.map((item) => {
              if (item.id === params.row.id) {
                return {
                  ...item,
                  isActive: !status,
                };
              }

              return item;
            });

            return updatedData;
          });
        });
    }
  };

  const doConfirmDelete = async (params) => {
    const res = await createConfirmToast(
      "error",
      null,
      "Êtes-vous sûr de vouloir supprimer la catégorie ?",
      "Oui",
      "Non"
    );
    if (res) {
      requestHelper
        .doRequest(
          _ACTIONS.DELETE,
          `${_ROUTES.PRODUIT_SERVICE}/${params.row.id}`
        )
        .then((deleteResponse) => {
          if (deleteResponse.status !== 204) {
            createToast("error", "Erreur lors de la suppression");
          }
          setProduitServiceCategories((prevData) => {
            const updatedData = prevData.filter(
              (item) => item.id !== params.row.id
            );

            return updatedData;
          });
        });
    }
  };

  const deleteAlsoService = (id) => {
    requestHelper
      .doRequest(_ACTIONS.DELETE, `${_ROUTES.PRODUIT_SERVICE}/${id}`)
      .then((deleteResponse) => {
        if (deleteResponse.status !== 204) {
          createToast("error", "Erreur lors de la dissociation");
        } else {
          requestHelper
            .doRequest(_ACTIONS.GET, `${_ROUTES.PRODUIT_SERVICE}`)
            .then((getResponse) => {
              const updatedServices = getResponse.data["hydra:member"];
              createToast("success", "Dissociation effectuée avec succès");
              setCatalogueNext(updatedServices);
            });
        }
      })
      .catch((e) => {
        console.error("Une erreur s'est produite lors de la suppression:", e);
        createToast(
          "error",
          "Une erreur s'est produite lors de la suppression"
        );
      });
  };

  const columns = useMemo(() => {
    const cols = [
      {
        field: "name",
        headerName: "Produit | Service",
        width: 400,
        flex: 1,
      },
    ];

    if (alsoContext === false) {
      cols.push(
        {
          field: "description",
          headerName: "Description",
          width: 400,
          flex: 1,
          renderCell: (params) => {
            return params.row.description
              ? stripHtml(params.row.description)
              : "";
          },
        },
        {
          field: "etat",
          headerName: "Etat",
          width: 300,
          flex: 1,
          renderCell: (params) => {
            const etat = params.row.isActive;

            return (
              <ChipTransformer
                label={etat ? "Actif" : "Inactif"}
                color={etat ? "success" : "error"}
              />
            );
          },
        },
        {
          field: "actions",
          headerName: "Actions",
          width: 300,
          flex: 1,
          renderCell: (params) => {
            const isDisabled =
              params.row.societeProduitServicesCategorie &&
              params.row.societeProduitServicesCategorie.length > 0;

            return (
              <Box sx={{ display: "flex" }}>
                <IconButton
                  color="success"
                  title="Mettre à jour"
                  size="small"
                  onClick={() => {
                    setShowModal(true);
                    setIsEdit(true);
                    setRowId(params.row.id);
                    setIsCategorie(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  title="Activer/Désactiver"
                  size="small"
                  disabled={isDisabled}
                  onClick={() => {
                    doUpdateStatus(params);
                  }}
                >
                  <ToggleTransformer
                    value={params.row.isActive}
                    disabled={isDisabled}
                  />
                </IconButton>
                <IconButton
                  color="error"
                  disabled={
                    params.row.societeProduitServicesCategorie &&
                    params.row.societeProduitServicesCategorie.length > 0
                  }
                  title="Supprimer"
                  size="small"
                  onClick={() => doConfirmDelete(params)}
                >
                  <RiDeleteBin5Line />
                </IconButton>
              </Box>
            );
          },
        }
      );
    }
    if (alsoContext === true) {
      cols.push({
        field: "actions",
        headerName: "Actions",
        width: 400,
        flex: 1,
        renderCell: (params) => {
          if (params.row.alsoService === true) {
            return (
              <Box sx={{ display: "flex" }}>
                <IconButton
                  title="Dissocier"
                  size="small"
                  onClick={() => deleteAlsoService(params.row.id)}
                >
                  <RiDeleteBin5Line />
                </IconButton>
              </Box>
            );
          }
        },
      });
    }

    return cols;
  }, [alsoContext]);

  return (
    <>
      {produitServiceCategories && (
        <DataGrid
          density="compact"
          rows={produitServiceCategories}
          columns={columns}
          disableRowSelectionOnClick
          pageSizeOptions={[5, 10, 20]}
        />
      )}
    </>
  );
};
