import { Grid } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { barColor, calculPourcentage } from './function';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { minutesInHours } from '../../../utils/function';

const Chart = ({type, data, max}) => {
    const pourcentage = calculPourcentage(data, max);
    const BorderLinearProgress = styled(LinearProgress)(() => ({
        height: 10,
        borderRadius: 5,
      }));
      const [colorBar, setColorBar] = useState();

    useEffect(() => {
        if(_.isUndefined(colorBar)) {
            setColorBar(barColor(pourcentage));
        }
    }, [pourcentage]);

    return (
        <Grid container sx={{mt: type !== 'DF' ? 1 : 0}}>
            <Grid item xs={6}><span style={{fontWeight:"bold"}}>{type}</span> : {minutesInHours(data, true)}</Grid>
            <Grid item xs={6} sx={{textAlign: "right"}}>{pourcentage + '%'}</Grid>
            <Grid item xs={12}>
                <BorderLinearProgress 
                    color={colorBar} 
                    variant="determinate" 
                    value={pourcentage <= 100 ? pourcentage : 100} 
                />
            </Grid>
        </Grid>
      );
};

export default Chart;