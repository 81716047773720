import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SidebarNav from "./screens/navigation/NavGlobal";
import Login from "./screens/authentication/Login";
import { RessourceDashboard } from "./screens/ressource/RessourceDashboard";
import ProduitService from "./screens/produit-service/ProduitService";
import Tiers from "./screens/Tiers";
import FormTiers from "./components/admin-tiers/FormTiers";
import Contrats from "./screens/contrats/Contrats";
import DecompteTemps from "./screens/decompte-temps/DecompteTemps";
import DecompteTempsRecap from "./screens/decompte-temps/DecompteTempsRecap";
import ProduitServiceConfig from "./screens/produit-service/ProduitServiceConfig";
import FormRessource from "./screens/ressource/FormRessource";
import FormActifConfig from "./screens/actifs-configuration/FormActifConfig";
import ActifConfigList from "./screens/actifs-configuration/ActifConfigList";
import { UsersGsuite } from "./screens/actifs-configuration/UsersGsuite";
import Utilisateur from "./screens/utilisateur/Utilisateur";
import FormUtilisateur from "./screens/utilisateur/FormUtilisateur";
import PrixFacturation from "./screens/PrixFacturation";
import { Contact } from "./screens/Contact";
import { ParametrageRegie } from "./screens/ParametrageRegie";
import FormContactFournisseur from "./screens/FormContactFournisseur";
import { ParametrageAlso } from "./screens/ParametrageAlso";
import { RecapitulatifClientAlso } from "./screens/RecapitulatifClientAlso";
import TicketsDashboard from "./screens/tickets/TicketDashboard";
import TicketEdit from "./components/tickets/edit/TicketEdit";
import BaseConnaissanceDashboard from "./screens/base-connaisance/BaseConnaissanceDashboard";
import { FormReservation } from "./screens/reservation/FormReservation";
import { ReservationDashboard } from "./screens/reservation/ReservationDashboard";
import { BaseConnaissanceForm } from "./screens/base-connaisance/BaseConnaissanceForm";
import { BaseConnaissanceConfigurationDashboard } from "./screens/base-connaisance/BaseConnaissanceConfigurationDashboard";
import { BaseConnaissanceFormConfiguration } from "./screens/base-connaisance/BaseConnaissanceFormConfiguration";
import { FormActifConfigStepOne } from "./screens/actifs-configuration/FormActifConfigStepOne";
import { Antivirus } from "./screens/antivirus/Antivirus";
import { ParametrageLocation } from "./screens/ParametrageLocation";
import { ParametrageOffre } from "./screens/ParametrageOffre";
import TelephonieOvh from "./screens/ovh/TelephonieOvh.jsx";
import RecapTelephonieOvh from "./screens/recap/RecapTelephonieOvh.jsx";
import ContratsExploitation from "./screens/gestion-exploitation/Contrats";
import { DashboardGeneral } from "./screens/tableau-general/DashboardGeneral";
import GestionReservations from "./screens/gestion-exploitation/GestionReservations";
import CreateTicket from "./components/tickets/create/TicketCreate";
import StatEquipe from "./screens/stats/StatEquipe.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/prix-facturation",
    element: <SidebarNav children={<PrixFacturation />} />,
  },
  {
    path: "/admin-contact",
    element: <SidebarNav children={<Contact />} />,
  },
  {
    path: "/societe-list/:societe?",
    element: <SidebarNav children={<Tiers />} />,
  },
  {
    path: "/utilisateur",
    element: <SidebarNav children={<Utilisateur />} />,
  },
  {
    path: "/utilisateur/:method/:id?",
    element: <SidebarNav children={<FormUtilisateur />} />,
  },
  {
    path: "/:type/:method/:id?",
    element: <SidebarNav children={<FormContactFournisseur />} />,
  },
  {
    path: "/resource-list",
    element: <SidebarNav children={<RessourceDashboard />} />,
  },
  {
    path: "/resource-form/:id?",
    element: <SidebarNav children={<FormRessource />} />,
  },
  {
    path: "/reservations",
    element: <SidebarNav children={<ReservationDashboard />} />,
  },
  {
    path: "/produit-service/list",
    element: <SidebarNav children={<ProduitService />} />,
  },
  {
    path: "/contrats",
    element: <SidebarNav children={<Contrats />} />,
  },
  {
    path: "/antivirus",
    element: <SidebarNav children={<Antivirus />} />,
  },
  {
    path: "/decompte-temps",
    element: <SidebarNav children={<DecompteTemps />} />,
  },
  {
    path: "/telephonie-ovh",
    element: <SidebarNav children={<TelephonieOvh />} />,
  },
  {
    path: "/tiers/edit/:id/:isFournisseur",
    element: <SidebarNav children={<FormTiers />} />,
  },
  {
    path: "/decompte-temps/recap",
    element: <SidebarNav children={<DecompteTempsRecap />} />,
  },
  {
    path: "/produit-service/config/:id",
    element: <SidebarNav children={<ProduitServiceConfig />} />,
  },
  {
    path: "/actifs_configuration/list/:id?",
    element: <SidebarNav children={<ActifConfigList />} />,
  },
  {
    path: "/actifs_configuration/create",
    element: <SidebarNav children={<FormActifConfigStepOne />} />,
  },
  {
    path: "/actifs_configuration/edit/:id?",
    element: <SidebarNav children={<FormActifConfig />} />,
  },
  {
    path: "/actifs_configuration/users_gsuite/:domain?",
    element: <SidebarNav children={<UsersGsuite />} />,
  },
  {
    path: "/actifs_configuration/users_gsuite/:domain?",
    element: <SidebarNav children={<UsersGsuite />} />,
  },
  {
    path: "/parametrage_also",
    element: <SidebarNav children={<ParametrageAlso />} />,
  },
  {
    path: "/parametrage_regie",
    element: <SidebarNav children={<ParametrageRegie />} />,
  },
  {
    path: "/parametrage_location",
    element: <SidebarNav children={<ParametrageLocation />} />,
  },
  {
    path: "/parametrage_offre",
    element: <SidebarNav children={<ParametrageOffre />} />,
  },
  {
    path: "/recapitulatif-client-also",
    element: <SidebarNav children={<RecapitulatifClientAlso />} />,
  },
  {
    path: "/tickets/dashboard",
    element: <SidebarNav children={<TicketsDashboard />} />,
  },
  {
    path: "/ticket-edit/:id",
    element: <SidebarNav children={<TicketEdit />} />,
  },
  {
    path: "/reservation-form/:type/:id?",
    element: <SidebarNav children={<FormReservation />} />,
  },
  {
    path: "/base-connaissance/dashboard/:articleId?",
    element: <SidebarNav children={<BaseConnaissanceDashboard />} />,
  },
  {
    path: "/base-connaissance-form/:id?",
    element: <SidebarNav children={<BaseConnaissanceForm />} />,
  },
  {
    path: "/base-connaissance/configuration/dashboard",
    element: (
      <SidebarNav children={<BaseConnaissanceConfigurationDashboard />} />
    ),
  },
  {
    path: "/base-connaissance-form/configuration",
    element: <SidebarNav children={<BaseConnaissanceFormConfiguration />} />,
  },
  {
    path: "/gestion-exploitation/contrats",
    element: <SidebarNav children={<ContratsExploitation />} />,
  },
  {
    path: "/recap/telephonie-ovh",
    element: <SidebarNav children={<RecapTelephonieOvh />} />,
  },
  {
    path: "/dashboard-general",
    element: <SidebarNav children={<DashboardGeneral />} />,
  },
  {
    path: "/gestion-exploitation/gestion-reservation",
    element: <SidebarNav children={<GestionReservations />} />,
  },
  {
    path: "/ticket-create",
    element: <SidebarNav children={<CreateTicket />} />,
  },
  {
    path: "/stats/stat-equipe",
    element: <SidebarNav children={<StatEquipe />} />,
  },
]);

export default function CustomRouter() {
  return <RouterProvider router={router} />;
}
