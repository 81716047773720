import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/x-date-pickers";
import { _COLORS } from "../utils/constants";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

export const MUI_THEME = createTheme({
  typography: {
    fontFamily: "Poppins, light[300], sans-serif",
  },
  button: {
    fontFamily: "Poppins, light[300], sans-serif",
  },
  palette: {
    default: createColor("#f5f5f5"),
    grey: createColor("#777a7f"),
    lightBlue: createColor("#3c8dbc"),
    hoverlightBlue: createColor("#60a8d0"),
    turquoiseBox: createColor("#00c0ef"),
    lightGrey: createColor("#f4f4f4"),
    error: createColor("#C9302C"),
    warning: createColor("#F39C12"),
    success: createColor("#00a65a"),
    lightDanger: createColor("#D87A68"),
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: _COLORS.backgroundApp,
        },
      },
    },
  },
  frFR,
});
