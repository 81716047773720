export const _BS_MESSAGES = {
  _INFO_CONFIGURATION:
    " Configurer une base de connaissances client générale contenant des articles de support et d'autres informations utiles ou une base de connaissances personnalisée pour un client spécifique.",
  _INFO_CONFIGURATION_2:
    " Créer des nouvelles catégories pour stocker des sections et des articles",
};

export const _BS_CONFIG_MESSAGES = {
  _MODAL_ADD_SECTION: "Ajouter une section",
  _MODAL_ADD_SECTION_INFO: "Saisir une courte description",
  _MODAL_ADD_CATEGORY: "Ajouter une catégorie",
};
