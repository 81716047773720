import * as Yup from "yup";
import {
  FIXED_PRODUIT_SERVICE_CONFIG,
  HEBERGEMENT_FORM_FIELD,
  LOGICIEL_FORM_FIELD,
  SAUVEGARDE_FORM_FIELD,
  TELEPHONIE_ASTERISK_FORM_FIELD,
} from "./actif_configuration_constants";
import moment from "moment";
import { determineMethodHttp, getIdFromIRI } from "../function";
import { _ROUTES } from "../_routes_constants";
import { _ACTIONS } from "../constants";
import { RequestHelper } from "../requestHelper";
import _ from "lodash";
import { createToast } from "../toast";

const rq = new RequestHelper();

/** -------------------------------- */
//  VALIDATION FORMACTIFCONFIG.JSX //
/** -------------------------------- */
/**
 * Returns the validation schema for actif configuration form.
 * @param {boolean} isVadeSecureForm - Indicates if the form is for VadeSecure.
 * @param {boolean} isBitdefenderForm - Indicates if the form is for Bitdefender.
 * @param {boolean} isHebergementForm - Indicates if the form is for Hebergement.
 * @returns {object} - The validation schema object.
 */
export const getValidationSchema = (formStates) => {
  let hebergementSchema = {};
  if (formStates.isVadeSecureForm) {
    hebergementSchema = HEBERGEMENT_FORM_FIELD.filter(
      (item) => item.required === false
    ).reduce((fields, field) => {
      fields[field.name] = Yup.string().nullable();
      return fields;
    }, {});
    hebergementSchema.isSsl = Yup.boolean().required(
      "La valeur SSL est requise"
    );
    hebergementSchema.domaine = Yup.string().required("Le domaine est requis");
  }
  let schema = {
    societe: Yup.string().required("Veuillez renseigner une société"),
    produitService: Yup.string().required(
      "Veuillez renseigner un produit ou un service"
    ),
    produitServiceCategorie: Yup.string().required(
      "Veuillez renseigner une catégorie"
    ),
    quantite: Yup.number().required("Veuillez renseigner une quantité"),
    decompteTemps: Yup.boolean().required(
      "Veuillez renseigner un décompte de temps"
    ),
    description: Yup.string(),
    valueField: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
      })
    ),
    ...(formStates.isBitdefenderForm && {
      clientId: Yup.string().nullable(),
      startDate: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
    }),
    ...(formStates.isGsuiteForm && {
      compagnies: Yup.string().nullable(),
    }),
    ...(formStates.isAlsoCustomer && {
      customerId: Yup.string().required("Veuillez renseignez un ID Gsuite"),
      customerDomain: Yup.string().required(
        "Veuillez renseignez un Domaine Gsuite"
      ),
    }),
    ...(formStates.isAlsoCustomer && {
      accountId: Yup.string().required(
        "Veuillez renseignez un identifiant Also"
      ),
    }),
    ...(formStates.isOpnSenseForm && {
      apiKey: Yup.string().nullable(),
      apiSecret: Yup.string().nullable(),
      dateMaj: Yup.string().nullable(),
      location: Yup.bool().nullable(),
      version: Yup.string().nullable(),
      supervision: Yup.bool().nullable(),
      cluster: Yup.bool().nullable(),
      zfs: Yup.bool().nullable(),
      ipTunnel: Yup.string().nullable(),
      installationDate: Yup.string().nullable(),
      uptime: Yup.string().nullable(),
      fournisseur: Yup.string().nullable(),
      numberPhysicalInterface: Yup.string().nullable(),
      urlBackup: Yup.string().nullable(),
      unbound: Yup.bool().nullable(),
      webAccess: Yup.string().nullable(),
      // contrat: Yup.string(),
    }),
    ...(formStates.isVPNForm && {
      protocole: Yup.string().required("Veuillez renseignez un protocole"),
      vpnServeur: Yup.string().required("Veuillez renseignez un serveur"),
      ports: Yup.string().nullable(),
      adresseVpn: Yup.string().nullable(),
      adresseIp: Yup.string().nullable(),
      dns: Yup.string().nullable(),
      gateway: Yup.string().nullable(),
      fullTunnel: Yup.string().nullable(),
      certificatDate: Yup.string().nullable(),
      configFile: Yup.string().nullable(),
    }),
    ...(formStates.isHebergementForm && {
      ...hebergementSchema,
    }),
    ...(formStates.isSauvegardeForm && {
      nomnas: Yup.string().nullable(),
      nomtache: Yup.string().nullable(),
    }),
    ...(formStates.isLogicielForm && {
      posteName: Yup.string().nullable(),
      licenseOfficiel: Yup.string().nullable(),
      licenseEmail: Yup.string().nullable(),
      emailAssocier: Yup.string().nullable(),
      dateDownload: Yup.date().required("Veuillez renseignez une date"),
      dateInstall: Yup.date().required("Veuillez renseignez une date"),
    }),
    ...(formStates.isTelephonieAsteriskForm && {
      user: Yup.string()
        .nullable()
        .max(10, "Le nombre maximal de caractères autorisés est 10"),
      password: Yup.string().nullable(),
    }),
    ...(formStates.isTelephonieOvhForm && {
      billingAccount: Yup.array()
        .required("Veuillez renseignez un compte OVH")
        .min(1, "Veuillez renseignez un compte OVH"),
    }),
  };

  return Yup.object().shape(schema);
};

/** -------------------------------- */
//  EDIT  - INITIAL VALUES //
/** -------------------------------- */

/**
 *
 * @param {Array} produitServiceConfigFields - Configuration array of a produit service.
 * @returns {Object} - Return dynamic field values
 * from the given ProduitService Configuration (model, mac...) array.
 */
export const produitServiceConfigurationsFields = (
  produitServiceConfigFields
) => {
  const dynamicFieldsValues = produitServiceConfigFields?.reduce(
    (values, field) => {
      const fieldKey = `field_${field.produitServiceConfiguration.id}`;
      values[fieldKey] = field.valueField;

      return values;
    },
    {}
  );

  return dynamicFieldsValues;
};

/**
 *
 * @param {Object} currentActifConfig - The current actif configuration object.
 * @returns {Object} - object with the form fields for step 1 of the actif configuration
 */
export const formStep1Fields = (currentActifConfig) => {
  const {
    societe,
    produitService,
    produitServiceCategorie,
    quantite,
    decompteTemps,
    description,
  } = currentActifConfig;

  return {
    societe: getValue(societe["@id"]) || "",
    produitService: getValue(produitService["@id"]) || "",
    produitServiceCategorie: getValue(produitServiceCategorie["@id"]) || "",
    quantite: quantite || "",
    decompteTemps: !!decompteTemps, // Coerce to boolean
    description: description || "",
  };
};

export const fixedPsConfig = (currentActifConfig) => {
  const {
    // STRING
    os,
    version,
    domains,
    lieu,
    fabricant,
    serie,
    fti,
    ligneSupport,
    marque,
    model,
    name,
    processeur,
    usager,
    // ARRAY
    mac,
    ipLocal,
    dynhost,
    // BOOL
    bridge,
  } = currentActifConfig;

  return {
    // STRING //
    os: getValue(os),
    version: getValue(version),
    lieu: getValue(lieu),
    fabricant: getValue(fabricant),
    serie: getValue(serie),
    fti: getValue(fti),
    ligneSupport: getValue(ligneSupport),
    marque: getValue(marque),
    model: getValue(model),
    name: getValue(name),
    processeur: getValue(processeur),
    usager: getValue(usager),
    ipLocal: getValue(ipLocal),
    domains: getValue(domains),
    // ARRAY //
    mac: (mac && mac.join()) ?? "",
    dynhost: (dynhost && dynhost.join()) ?? "",
    // BOOL //
    bridge: bridge || false,
  };
};

/**
 *
 * @param {Object} currentActifConfig - The current actif configuration object.
 * @returns {Object} - object with the Vade Secure fields of the actif configuration
 */
export const vadeSecureFields = (currentActifConfig) => {
  const { societeProduitServiceVadeSecure } = currentActifConfig;

  return {
    clientId: getValue(societeProduitServiceVadeSecure?.clientId),
    startDate: getValueMoment(societeProduitServiceVadeSecure?.startDate),
    endDate: getValueMoment(societeProduitServiceVadeSecure?.endDate),
  };
};

/**
 *
 * @param {Object} currentActifConfig - The current actif configuration object.
 * @returns {Object} - object with the Gsuite form fields of the actif configuration
 */
export const gsuiteFields = (currentActifConfig) => {
  const { societeProduitServiceGsuite } = currentActifConfig;

  return {
    customerId: getValue(societeProduitServiceGsuite?.customerId),
    customerDomain: getValue(societeProduitServiceGsuite?.customerDomain),
  };
};

/**
 *
 * @param {Object} currentActifConfig - The current actif configuration object.
 * @returns {Object} - object with the OpnSense form fields of the actif configuration
 */
export const opnSenseFields = (currentActifConfig) => {
  const { societeProduitServiceOpnsense } = currentActifConfig;

  return {
    dynhost: getValue(currentActifConfig?.dynhost),
    ipLocal: getValue(currentActifConfig?.ipLocal),
    apiKey: getValue(societeProduitServiceOpnsense?.apiKey),
    apiSecret: getValue(societeProduitServiceOpnsense?.apiSecret),
    dateMaj: getValueMoment(societeProduitServiceOpnsense?.dateMaj),
    location: getValue(societeProduitServiceOpnsense?.location, false),
    version: getValue(societeProduitServiceOpnsense?.version),
    supervision: getValue(societeProduitServiceOpnsense?.supervision, false),
    cluster: getValue(societeProduitServiceOpnsense?.cluster, false),
    zfs: getValue(societeProduitServiceOpnsense?.zfs, false),
    ipTunnel: getValue(societeProduitServiceOpnsense?.ipTunnel),
    installationDate: getValueMoment(
      societeProduitServiceOpnsense?.installationDate
    ),
    uptime: getValueMoment(societeProduitServiceOpnsense?.uptime),
    fournisseur: getValue(societeProduitServiceOpnsense?.fournisseur),
    numberPhysicalInterface: getValue(
      societeProduitServiceOpnsense?.numberPhysicalInterface
    ),
    urlBackup: getValue(societeProduitServiceOpnsense?.urlBackup),
    unbound: getValue(societeProduitServiceOpnsense?.unbound, false),
    webAccess: getValue(societeProduitServiceOpnsense?.webAccess),
    contrat: getValue(societeProduitServiceOpnsense?.contrat),
  };
};

/**
 *
 * @param {Object} currentActifConfig - The current actif configuration object.
 * @returns {Object} - object with the OpenVpn form fields of the actif configuration
 */
export const vpnFields = (currentActifConfig) => {
  const { societeProduitServiceOpenVpn } = currentActifConfig;

  return {
    protocole: getValue(societeProduitServiceOpenVpn?.protocole),
    vpnServeur: getValue(societeProduitServiceOpenVpn?.vpnServeur),
    ports: getValue(societeProduitServiceOpenVpn?.ports).toString(),
    adresseVpn: getValue(societeProduitServiceOpenVpn?.adresseVpn),
    adresseIp: getValue(societeProduitServiceOpenVpn?.adresseIp),
    dns: getValue(societeProduitServiceOpenVpn?.dns),
    gateway: getValue(societeProduitServiceOpenVpn?.gateway),
    fullTunnel: getValue(societeProduitServiceOpenVpn?.fullTunnel),
    certificatDate: getValueMoment(
      societeProduitServiceOpenVpn?.certificatDate
    ),
    configFile: getValue(societeProduitServiceOpenVpn?.configFile),
  };
};

/**
 * Get current values for hebergement from the given actif configuration.
 *
 * @param {Object} currentActifConfig - The current actif configuration object.
 * @returns {Object} - The current values for hebergement.
 */
export const currentValuesHebergement = (currentActifConfig) => {
  if (!currentActifConfig?.societeProduitServiceHebergement) {
    return {};
  }

  // reducing each fields in constant to create an object with the current values
  const hebergementFields = HEBERGEMENT_FORM_FIELD.reduce((acc, field) => {
    acc[field.name] =
      currentActifConfig.societeProduitServiceHebergement[field.name] || "";

    return acc;
  }, {});

  hebergementFields.isSsl =
    currentActifConfig.societeProduitServiceHebergement.isSsl || false;
  hebergementFields.domaine =
    currentActifConfig.societeProduitServiceHebergement.domaine || "";

  return hebergementFields;
};

export const currentValuesSauvegarde = (currentActifConfig) => {
  if (!currentActifConfig?.societeProduitServiceSauvegarde) {
    return {};
  }

  // reducing each fields in constant to create an object with the current values
  const sauvegardeFields = SAUVEGARDE_FORM_FIELD.reduce((acc, field) => {
    acc[field.name] = currentActifConfig[field.name] || "";

    return acc;
  }, {});

  return sauvegardeFields;
};

export const currentValuesLogiciel = (currentActifConfig) => {
  // reducing each fields in constant to create an object with the current values
  const logicielFields = LOGICIEL_FORM_FIELD.reduce((fields, field) => {
    if (field.type === "text") {
      fields[field.name] =
        currentActifConfig?.societeProduitServiceLogiciel?.[field.name] ?? "";
    } else if (field.type === "date") {
      fields[field.name] =
        getValueMoment(
          currentActifConfig?.societeProduitServiceLogiciel?.[field.name]
        ) ?? null;
    }

    return fields;
  }, {});

  return logicielFields;
};

export const currentValuesAsterisk = (currentActifConfig) => {
  const { societeProduitServiceAsterisk } = currentActifConfig;

  const asteriskFields = TELEPHONIE_ASTERISK_FORM_FIELD.reduce(
    (fields, field) => {
      fields[field.name] = societeProduitServiceAsterisk?.[field.name] ?? "";

      return fields;
    },
    {}
  );

  return asteriskFields;
};

export const currentValuesOvh = (currentActifConfig) => {
  const { societeProduitServiceOvh } = currentActifConfig;

  return {
    billingAccount: societeProduitServiceOvh?.billingAccount ?? [], // assure that billingAccount is an array
  };
};

/* Manage the initial values of every part of form (type, null) */
export const valuesFormActifConfig = (currentActifConfig) => {
  if (!currentActifConfig) {
    return "";
  }
  const {
    tmpAlso,
    societeProduitServiceBitdefender,
    produitServiceConfigFields,
  } = currentActifConfig;
  // config field for the current product service
  const produitServiceConfigurations = produitServiceConfigurationsFields(
    produitServiceConfigFields
  );

  const initialValues = {
    // ------------------------ //
    // FORM STEP 1
    ...formStep1Fields(currentActifConfig),
    // ------------------------ //
    // FORM STEP 2
    // FIXED FIELD IN DB
    ...fixedPsConfig(currentActifConfig),
    // DYNAMIC FIELD
    ...produitServiceConfigurations,
    // ------------------------ //
    // VADE SECURE
    ...vadeSecureFields(currentActifConfig),
    // ------------------------ //
    // BITDEFENDER
    compagnies: societeProduitServiceBitdefender?.compagnies,
    // ------------------------ //
    // GSUITE
    ...gsuiteFields(currentActifConfig),
    // ------------------------ //
    // FORM STEP 2
    accountId: tmpAlso?.map((item) => item.compagny),
    // ------------------------ //
    // OPNSENSE
    ...opnSenseFields(currentActifConfig),
    // ------------------------ //
    // VPN
    ...vpnFields(currentActifConfig),
    // ------------------------ //
    // HEBERGEMENT
    ...currentValuesHebergement(currentActifConfig),
    // ------------------------ //
    // SAUVEGARDE
    ...currentValuesSauvegarde(currentActifConfig),
    // ------------------------ //
    // LOGICIEL
    ...currentValuesLogiciel(currentActifConfig),
    // ------------------------ //
    // TELEPHONIE.ASTERISK
    ...currentValuesAsterisk(currentActifConfig),
    // ------------------------ //
    // TELEPHONIE.OVH
    ...currentValuesOvh(currentActifConfig),
  };

  return initialValues;
};

/**
 *
 * @param {object} currentActifConfig - The current actif configuration object.
 * @returns {array} - An array of value in FormStepTwo.jsx
 */
export const getValueFieldFormStepTwo = (currentActifConfig) => {
  if (!currentActifConfig) return;
  const newValueField =
    currentActifConfig.produitService?.produitServiceConfigValues.map(
      (item) => {
        const customField = item.produitServiceConfiguration
          .customFieldFormProduitServiceConfigs
          ? item.produitServiceConfiguration.customFieldFormProduitServiceConfigs.map(
              (field) => field.optionsSelectValues
            )
          : [];
        const selectOptions =
          customField &&
          item.produitServiceConfiguration.typeFieldForm === "SELECT" &&
          customField.length > 0 &&
          customField;

        return {
          iri: item.produitServiceConfiguration["@id"],
          id: getIdFromIRI(item.produitServiceConfiguration["@id"]),
          label: item.produitServiceConfiguration.label,
          typeField: item.produitServiceConfiguration.typeFieldForm,
          value: item.value,
          selectOptions: selectOptions,
        };
      }
    );

  return newValueField;
};

/**
 * Retrieves the value from a given path, or returns a default value if the path is falsy.
 *
 * @param {any} path - The path to retrieve the value from.
 * @param {any} [defaultValue=""] - The default value to return if the path is falsy.
 * @returns {any} The value from the given path, or the default value if the path is falsy.
 */
export const getValue = (path, defaultValue = "") => {
  return path ? path : defaultValue;
};

/**
 * Returns the moment value of the given path, or a default value if the path is falsy.
 *
 * @param {string} path - The path to convert to a moment value.
 * @param {string} [defaultValue=""] - The default value to return if the path is falsy.
 * @returns {moment} - The moment value of the path, or the default value if the path is falsy.
 */
export const getValueMoment = (path, defaultValue = null) => {
  return path ? moment(path) : defaultValue;
};

/** -------------------------------- */
//  INSERTION EN BASE DE DONNÉE //
/** -------------------------------- */

/**
 * @param {object} values - The values for step one.
 * @param {object} reqStepOne - Determine request method.
 * Save the step one of the actif configuration form.
 */
export const saveStepOne = async (values, reqStepOne, formStates, config) => {
  let data = {};
  if (!config) {
    data = {
      societe: values.societe,
      produitService: values.produitService,
      produitServiceCategorie: values.produitServiceCategorie,
      quantite: values.quantite,
      decompteTemps: values.decompteTemps,
      description: values.description,
      isActive: true,
    };
  } else {
    const defaultConfigData = await saveFixedConfig(values, config);
    data = {
      ...defaultConfigData,
      societe: values.societe,
      produitService: values.produitService,
      produitServiceCategorie: values.produitServiceCategorie,
      quantite: values.quantite,
      decompteTemps: values.decompteTemps,
      description: values.description,
      isActive: true,
    };
  }
  // if we're in a case of sauvegarde form we save nomnas & nomtache
  if (formStates.isSauvegardeForm) {
    data = {
      ...data,
      nomnas: values.nomnas,
      nomtache: values.nomtache,
    };
  }

  const responseOne = await rq.doRequest(
    reqStepOne.method,
    `${_ROUTES.SOCIETE_PRODUIT_SERVICE}${
      reqStepOne.method === _ACTIONS.PUT ? `/${reqStepOne.id}` : ""
    }`,
    data
  );

  return responseOne;
};

export const saveFixedConfig = async (values, config) => {
  const data = {};

  const allFields = [
    ...FIXED_PRODUIT_SERVICE_CONFIG.textFields,
    ...FIXED_PRODUIT_SERVICE_CONFIG.arrayFields,
    ...FIXED_PRODUIT_SERVICE_CONFIG.booleanFields,
  ];

  const fieldConfigToName = {};
  const arrayFieldConfigs = new Set();

  allFields.forEach((field) => {
    fieldConfigToName[field.fieldConfig] = field.name;
    if (FIXED_PRODUIT_SERVICE_CONFIG.arrayFields.includes(field)) {
      arrayFieldConfigs.add(field.fieldConfig);
    }
  });

  for (const [key, value] of Object.entries(config)) {
    const fieldName = fieldConfigToName[key];
    if (fieldName) {
      if (arrayFieldConfigs.has(key)) {
        // Forcer un tableau vide si la valeur est absente ou non définie
        data[fieldName] = values[fieldName]
          ? _.isString(values[fieldName])
            ? values[fieldName].split(",")
            : values[fieldName]
          : [];
      } else {
        if (value) {
          data[fieldName] = values[fieldName];
        }
      }
    }
  }

  return data;
};

/**
 * @param {object} values - The values for step two.
 * @param {object} currentId - Determine request method.
 * Save the step two of the actif configuration form.
 */
export const saveStepTwo = async (values, currentId) => {
  const saveStepTwo = Object.keys(values)
    .map((key) => {
      if (key.startsWith("field_")) {
        /* ici on récpère tout les champs qui commencent par 'field_' */
        const fieldId = key.split("_")[1]; /* On récupère l'id du champ */

        return {
          valueField:
            values[
              key
            ] /* On récupère la valeur du champ par rapport à son id */,
          societeProduitService: currentId,
          produitServiceConfiguration: fieldId,
        };
      }

      return null;
    })
    .filter((field) => field !== null);

  const responseTwo = await rq.doRequest(
    _ACTIONS.POST,
    "save_multiple_config_field",
    saveStepTwo
  );

  return responseTwo;
};

/**
 * @param {object} values - The values for Vade Secure.
 * @param {object} currentActifConfig - The current actif configuration object.
 * Save the Vade Secure fields of the actif configuration form.
 */
export const saveVadeSecure = async (values, currentActifConfig) => {
  const method = determineMethodHttp(
    currentActifConfig.societeProduitServiceVadeSecure
  ).method;

  const data = {
    clientId: values.clientId,
    societeProduitService: currentActifConfig["@id"],
    startDate: values.startDate ? values.startDate.format("YYYY-MM-DD") : null,
    endDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : null,
  };

  const responseVadeSecure = await rq.doRequest(
    method,
    `/societe-produit-service-vade-secures${
      method === _ACTIONS.PUT
        ? `/${currentActifConfig.societeProduitServiceVadeSecure.id}`
        : ""
    }`,
    data
  );

  return responseVadeSecure;
};

/**
 * Saves SocieteProduitServiceBitdefender entity.
 *
 * @param {Object} values - The values to be saved.
 * @param {string} currentId - The current ID.
 * @returns {Promise<Object>} - The response from the server.
 */
export const saveBitdefender = async (values, currentId) => {
  const dataBitdefender = {
    compagnies: values.compagnies,
    produitService: currentId,
  };

  const responseBitdefender = await rq.doRequest(
    _ACTIONS.POST,
    "save_customers_bitdefender",
    dataBitdefender
  );

  return responseBitdefender;
};

/**
 * Saves SocieteProduitServiceGsuite entity.
 * @param {Object} values - The values to be saved.
 * @param {string} currentId - The current ID.
 */
export const saveGsuite = async (values, currentId) => {
  const dataGsuite = {
    customerId: values.customerId,
    produitService: currentId,
    customerDomain: values.customerDomain,
  };

  const responseGsuite = await rq.doRequest(
    _ACTIONS.POST,
    "save_customers_gsuite",
    dataGsuite
  );

  return responseGsuite;
};

/**
 * Saves TmpAlso entity.
 * @param {Object} values - The values to be saved.
 * @param {string} currentId - The current ID.
 * @param {Array} companiesAlso - The companies Also array.
 **/
export const saveAlso = async (values, currentId, companiesAlso) => {
  let CompanyName = companiesAlso?.find(
    (company) => company.AccountId === values.accountId
  ).CompanyName;

  const dataAlso = {
    accountId: values.accountId,
    CompanyName: CompanyName,
    isAssocied: 0,
    societeProduitService: currentId,
  };

  const responseAlso = await rq.doRequest(
    _ACTIONS.POST,
    "save-customer-also",
    dataAlso
  );

  return responseAlso;
};

export const saveOpnSense = async (values, currentActifConfig) => {
  const dataOpnSense = {
    apiKey: values.apiKey,
    apiSecret: values.apiSecret,
    dateMaj: values.dateMaj,
    produitService: currentActifConfig["@id"],
    location: values.location,
    version: values.version,
    supervision: values.supervision,
    cluster: values.cluster,
    zfs: values.zfs,
    ipTunnel: values.ipTunnel,
    installationDate: values.installationDate,
    uptime: values.uptime,
    fournisseur: values.fournisseur,
    numberPhysicalInterface: values.numberPhysicalInterface,
    urlBackup: values.urlBackup,
    unbound: values.unbound,
    webAccess: values.webAccess,
    // contrat: values.contrat, @TODO : To ADD when contrat done
  };

  const reqOpnSense = determineMethodHttp(
    currentActifConfig?.societeProduitServiceOpnsense
  );

  const responseOpnSense = await rq.doRequest(
    reqOpnSense.method,
    `${_ROUTES.SPS_OPN_SENSE}${
      reqOpnSense.method === _ACTIONS.PUT ? `/${reqOpnSense.id}` : ""
    }`,
    dataOpnSense
  );

  return responseOpnSense;
};

export const saveVPN = async (values, currentActifConfig) => {
  const dataVPN = {
    protocole: values.protocole,
    vpnServeur: values.vpnServeur,
    ports: values.ports.split(","),
    adresseVpn: values.adresseVpn,
    adresseIp: values.adresseIp,
    dns: values.dns,
    gateway: values.gateway,
    fullTunnel: values.fullTunnel,
    certificatDate: values.certificatDate,
    configFile: values.configFile,
    societeProduitService: currentActifConfig["@id"],
  };

  const reqVPN = determineMethodHttp(
    currentActifConfig?.societeProduitServiceOpenVpn
  );

  const responseVPN = await rq.doRequest(
    reqVPN.method,
    `${_ROUTES.SPS_OPEN_VPN}${
      reqVPN.method === _ACTIONS.PUT ? `/${reqVPN.id}` : ""
    }`,
    dataVPN
  );

  return responseVPN;
};

export const saveHebergement = async (values, currentActifConfig) => {
  const methodHebergement = determineMethodHttp(
    currentActifConfig?.societeProduitServiceHebergement
  );

  const dataHebergement = {
    ...HEBERGEMENT_FORM_FIELD.reduce((fields, field) => {
      fields[field.name] = values[field.name];

      return fields;
    }, {}),
    isSsl: values.isSsl ?? 0,
    produitService: currentActifConfig["@id"],
  };

  const responseHebergement = await rq.doRequest(
    methodHebergement.method,
    `${_ROUTES.SPS_HEBERGEMENT}${
      methodHebergement.method === _ACTIONS.PUT
        ? `/${methodHebergement.id}`
        : ""
    }`,
    dataHebergement
  );

  return responseHebergement;
};

export const saveLogiciel = async (values, currentActifConfig) => {
  const { societeProduitServiceLogiciel } = currentActifConfig;
  const method = determineMethodHttp(societeProduitServiceLogiciel).method;

  const dataLogiciel = {
    ...LOGICIEL_FORM_FIELD.reduce((fields, field) => {
      if (field.type === "text") {
        fields[field.name] = values[field.name];
      } else if (field.type === "date") {
        fields[field.name] = values[field.name].format("YYYY-MM-DD");
      }

      return fields;
    }, {}),
    produitService: currentActifConfig["@id"],
  };

  const responseLogiciel = await rq.doRequest(
    method,
    `${_ROUTES.SPS_LOGICIEL}${
      method === _ACTIONS.PUT ? `/${societeProduitServiceLogiciel.id}` : ""
    }`,
    dataLogiciel
  );

  return responseLogiciel;
};

export const saveTelephonieAsterisk = async (values, currentActifConfig) => {
  const { societeProduitServiceAsterisk } = currentActifConfig;
  const method = determineMethodHttp(societeProduitServiceAsterisk).method;

  const dataAsterisk = {
    ...TELEPHONIE_ASTERISK_FORM_FIELD.reduce((fields, field) => {
      fields[field.name] = values[field.name];

      return fields;
    }, {}),
    produitService: currentActifConfig["@id"],
  };

  const responseAsterisk = await rq.doRequest(
    method,
    `${_ROUTES.SPS_ASTERISK}${
      method === _ACTIONS.PUT ? `/${societeProduitServiceAsterisk.id}` : ""
    }`,
    dataAsterisk
  );

  return responseAsterisk;
};

export const saveTelephonieOvh = async (values, currentActifConfig) => {
  const { societeProduitServiceOvh } = currentActifConfig;
  const method = determineMethodHttp(societeProduitServiceOvh).method;

  const dataOvh = {
    ...values,
    billingAccount: values.billingAccount,
    produitService: currentActifConfig["@id"],
  };

  const responseOvh = await rq.doRequest(
    method,
    `${_ROUTES.SPS_OVH}${
      method === _ACTIONS.PUT ? `/${societeProduitServiceOvh.id}` : ""
    }`,
    dataOvh
  );

  return responseOvh;
};

/** -------------------------------- */
//  DATA FETCHER //
/** -------------------------------- */

// sociétés array for autocomplete
export const getSocietes = (setOptions) => {
  rq.doRequest(_ACTIONS.GET, `${_ROUTES.SOCIETE}`, {
    pagination: "disabled",
  }).then((res) => {
    setOptions(res.data["hydra:member"]);
  });
};

// Produit Service for Produit | Service
export const getCataloguesProduitService = (setParentPS, setLoadingStates) => {
  const res = rq
    .doRequest(_ACTIONS.GET, `${_ROUTES.PRODUIT_SERVICE}`, {
      exists: { parent: false },
      pagination: "disabled",
      isActive: true,
    })
    .then((res) => {
      setParentPS(res.data["hydra:member"]);
      setLoadingStates((prevState) => ({
        ...prevState,
        isParentPSLoaded: true,
      }));
    })
    .catch((err) => {
      createToast("error", "Une erreur est survenue");
    });
};

// GET PRODUIT SERVICE CONFIG
