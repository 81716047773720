import React, { useEffect } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import { FormControl, FormHelperText, Grid, Typography } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { createToast } from "../../../utils/toast";
import { _ACTIONS } from "../../../utils/constants";

export const FormFieldAlso = ({
  formik,
  formStates,
  setFormStates,
  setCompaniesAlso,
  companiesAlso,
}) => {
  const rq = new RequestHelper();

  async function getCompagnies() {
    try {
      let res = await rq.doRequest(_ACTIONS.POST, "get-companies-also");
      let tmp = res.data;
      setCompaniesAlso(tmp);
    } catch (error) {
      createToast("error", "Erreur lors de la récupération des comptes");
    }
  }

  useEffect(() => {
    setFormStates({
      ...formStates,
      isAlsoCustomer: true,
    });
    getCompagnies();
  }, []);

  return (
    <>
      <Grid container spacing={1} justifyContent="center" marginTop={2}>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3.5}>
              <Typography variant="body1" component="div">
                <strong>Account Id *</strong>
              </Typography>
            </Grid>
            <Grid item xs={8.5}>
              <FormControl
                fullWidth
                error={
                  formik.touched.accountId && Boolean(formik.errors.accountId)
                }
              >
                <Select
                  labelId="accountId-label"
                  id="accountId"
                  value={formik.values.accountId}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="accountId"
                  size="small"
                  sx={{ height: "30px" }}
                >
                  {companiesAlso?.map((company) => (
                    <MenuItem key={company.AccountId} value={company.AccountId}>
                      {company.CompanyName}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.accountId && formik.errors.accountId ? (
                  <FormHelperText>{formik.errors.accountId}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
