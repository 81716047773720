import { Box, Typography } from "@mui/material";
import React from "react";

const InfoTableClient = ({ bgColor, title }) => {
  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        color: "white",
        p: 0.5,
        borderRadius: "2px",
      }}
    >
      <Typography sx={{ fontSize: "0.7rem" }} fontWeight={"bold"}>
        {title}
      </Typography>
    </Box>
  );
};

export default InfoTableClient;
