import { useEffect, useMemo, useContext, useState, useRef } from "react";
import moment from "moment";
import { useMaterialReactTable, MaterialReactTable } from "material-react-table";
import { Box, Button } from "@mui/material";
import { UserContext } from "../../../contexts/UserContext";
import useHasRoles from "../../../hooks/useHasRole";
import { _DATE_FORMAT, _ROLES } from "../../../utils/constants";
import { getDataStat } from "../../../repository/Stats";
import { _ROUTES } from "../../../utils/_routes_constants";
import { minutesInHours } from "../../../utils/function";
import _ from "lodash";
import { sumColumn } from "./function";
import { mkConfig, generateCsv, download } from 'export-to-csv'; 
import { exportDataMonthEquipe } from './makeData';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Chart from "./Chart";
import { _STAT_EQUIPE_NB_HEURES } from "../../../utils/constants";

const TableEquipeMonth = ({ mois, annee }) => {
    const {getUser} = useContext(UserContext);
    const user = getUser();
    const canAccess = useHasRoles(user, [_ROLES.ADMIN]);
    const userId = canAccess ? null : user.id;
    const [datas, setDatas] = useState([]);
    const today = moment().format('MM/YYYY');
    const nbDays = useRef(31);

    useEffect(() => {
        setDatas([]);
        if(!mois || !annee) return;
        getDataStat(
            {dateMonth: `${mois}/${annee}`, userId:userId},
            setDatas,
            "Une erreur est survenue lors de la récupération des données",
            _ROUTES.STAT_EQUIPE_MONTH
        );

    }, [mois, annee]);

    const columns = useMemo(() => {
        const columns = [];
        nbDays.current = today !== `${mois}/${annee}` 
                ? moment(`${mois}/${annee}`, _DATE_FORMAT.month_slash_year).daysInMonth()
                : parseInt(moment().format(_DATE_FORMAT.day));
        for (let i = 1; i <= nbDays.current; i++) {
                columns.push(
                    {
                        accessorKey:  String(i),
                        header: String(i),
                        Cell: ({row}) => (
                            (!_.isUndefined(row.original[i])) &&
                            <>
                                <Chart type={'DF'} data={row.original[i].facturable} max={_STAT_EQUIPE_NB_HEURES.day*60} />
                                <Chart type={'DR'} data={row.original[i].reel} max={_STAT_EQUIPE_NB_HEURES.day*60} />
                            </>
                        ),
                        Footer: (row) => (
                            <>
                                <Box>Total DF: {datas?.length > 0 ? minutesInHours(sumColumn(datas, "facturable", i), true) : ''}</Box>
                                <Box>Total DR: {datas?.length > 0 ? minutesInHours(sumColumn(datas, "reel", i), true) : ''}</Box>
                            </>
                          ),
                    }
                );
            }
        columns.push(
            {
                accessorKey: 'user',
                header: 'Équipe',
            },
            {
                accessorKey: 'total',
                header: 'Total',
                Cell: ({row}) => (
                    (!_.isUndefined(row.original.totalMoisReal)) &&
                    <>
                        <Chart type={'TF'} data={row.original.totalMoisEstimated} max={_STAT_EQUIPE_NB_HEURES.month*60} />
                        <Chart type={'TR'} data={row.original.totalMoisReal} max={_STAT_EQUIPE_NB_HEURES.month*60} />
                    </>
                ),
                Footer: () => (
                    <>
                        <Box>Total DF: {datas?.length > 0 ? minutesInHours(sumColumn(datas, "totalMoisEstimated", null), true) : ''}</Box>
                        <Box>Total DR: {datas?.length > 0  ? minutesInHours(sumColumn(datas, "totalMoisReal", null), true) : ''}</Box>
                    </>
                  ),
            },
        );

        return columns;
    }, [datas]);

    const options = {
        columns: columns,
        data: datas ?? [],
        initialState: {
            columnOrder: ["user","1", "2", "3", "4", "5", "6", "7", "8", "9", "10", 
            "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", 
            "23", "24", "25", "26", "27", "28", "29", "30", "31", "total"],
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
              >
                Export All Data
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Page Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Selected Rows
              </Button>
            </Box>
          ),
    };
    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
      }
    );

    const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(exportDataMonthEquipe(rowData, nbDays.current));
        download(csvConfig)(csv);
    };

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(exportDataMonthEquipe(datas, nbDays.current));
        download(csvConfig)(csv);
    };

    const table = useMaterialReactTable(options);


    return (
        <Box sx={{mt: 2}}>
            <MaterialReactTable table={table} />
        </Box>
    );
};

export default TableEquipeMonth;