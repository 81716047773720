import React, { useEffect, useState } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { createToast } from "../../../utils/toast";

export const FormFieldsGsuite = ({
  formik,
  currentActifConfig,
  formStates,
  setFormStates,
  isLoaded,
}) => {
  const rq = new RequestHelper();
  const [gsuiteCustomers, setGsuiteCustomers] = useState();

  const optionsGsuiteCustomers = () => {
    if (isLoaded && currentActifConfig) {
      rq.get(`get-customers-gsuite`).then((res) => {
        if (res.data && typeof res.data === "object") {
          const simplifiedArray = Object.values(res.data).map((item) => ({
            customer_id: item.customer_id,
            customer_domain: item.customer_domain,
          }));
          setGsuiteCustomers(simplifiedArray);
        } else {
          createToast("error", "Erreur lors de la récupération des clients");
        }
      });
    }
  };

  useEffect(() => {
    setFormStates({
      ...formStates,
      isGsuiteForm: true,
    });
    optionsGsuiteCustomers();
  }, [isLoaded, currentActifConfig]);

  return (
    <>
      <Grid container spacing={1} justifyContent="center" marginTop={2}>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3.5}>
              <Typography variant="body1" component="div">
                <strong>Gsuite ID *</strong>
              </Typography>
            </Grid>
            <Grid item xs={8.5}>
              <FormControl fullWidth error={Boolean(formik.errors.customerId)}>
                <InputLabel size="small">Gsuite ID</InputLabel>
                <Select
                  labelId="customerId-label"
                  id="customerId"
                  value={formik.values.customerId}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="customerId"
                  size="small"
                >
                  {gsuiteCustomers &&
                    gsuiteCustomers.map((e) => (
                      <MenuItem key={e.customer_id} value={e.customer_id}>
                        <strong>{e.customer_domain}</strong> - {e.customer_id}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.customerId && formik.errors.customerId ? (
                  <FormHelperText>{formik.errors.customerId}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3.5}>
              <Typography variant="body1" component="div">
                <strong>Gsuite Domain *</strong>
              </Typography>
            </Grid>
            <Grid item xs={8.5}>
              <FormControl
                fullWidth
                error={Boolean(formik.errors.customerDomain)}
              >
                <InputLabel size="small">Gsuite Domain</InputLabel>
                <Select
                  labelId="customerDomain-label"
                  id="customerDomain"
                  value={formik.values.customerDomain}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  name="customerDomain"
                  size="small"
                >
                  {gsuiteCustomers &&
                    gsuiteCustomers.map((e) => (
                      <MenuItem key={e.customer_id} value={e.customer_domain}>
                        <strong>{e.customer_domain}</strong>
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.customerDomain &&
                formik.errors.customerDomain ? (
                  <FormHelperText>{formik.errors.customerId}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
