import { useEffect, useState } from "react";
import { _ROUTES } from "../../utils/_routes_constants";
import { getDataBitdefender } from "../../repository/antivirus/Bitdefender";

const ClientName = ({ client }) => {
    const [license , setLicense] = useState([]);
    const [endpoint , setEndpoint] = useState([]);
  
    useEffect(() => {
        if(license.length === 0)
            getDataBitdefender(
                {companyId: client.compagnies}, 
                setLicense, 
                "Une erreur est survenue lors de la récupération des infos de licence",
                _ROUTES.SOCIETE_BITDEFENDER_LICENCE
            );
        if(endpoint.length === 0)
            getDataBitdefender(
                {companyId: client.compagnies}, 
                setEndpoint, 
                "Une erreur est survenue lors de la récupération des infos d'utilisation mensuelle",
                _ROUTES.SOCIETE_BITDEFENDER_ENDPOINT_LIST
            );
    }, []); 

    return (
        <font style={{color: (license?.totalSlots < endpoint?.total) ? "red" : "black"}}>
            {client.produitService.societe.name}
        </font>
    );
}

export default ClientName;