import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Card, Divider, Typography, Alert, Chip } from "@mui/material";
import { _ROUTES } from "../../../utils/_routes_constants";
import { RiLockPasswordFill } from "react-icons/ri";
import { _OVH } from "../../../utils/constants";
import { MdKeyboardArrowRight } from "react-icons/md";
import BasicModal from "../../helpers/BasicModal";
import IdendifiantInterfaceWeb from "../modals/IdentifiantInterfaceWeb";
import ModalAlert from "../../helpers/ModalAlert";
import { getDataOvh, putDataOvh } from "../../../repository/telephonies/Ovh";

const LigneAssocieeCompte = ({ group, nbLines, phonebook }) => {
    const text = "aucune ligne associée à ce compte de facturation...";
    const [detailsLines, setDetailsLines] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [credentiels, setCredentiels] = useState([]);
    const [open, setOpen] = useState(false);

    /**
     * Upgrade voicemail for a specific line.
     *
     * @param {string} line - The line for which to upgrade the voicemail.
     * @returns {void}
     */
    const upgradeVoicemail = (line) => {
        putDataOvh(
            {billingAccount: group.billingAccount, line: line}, 
            "Une erreur est survenue lors de la mise à jour de la messagerie", 
            _ROUTES.GROUP_OVH_UPGRADE_VOICEMAIL
        );
    };

    useEffect(() => {
        getDataOvh(
            {billingAccount: group?.billingAccount, fkParcPhone: phonebook}, 
            setDetailsLines, 
            "Une erreur est survenue lors de la récupération des détails des lignes associées à ce compte de facturation", 
            _ROUTES.GROUP_OVH_LINES_LIST
        );
    }, [group]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'ligne',
                header: 'Ligne',
                size: 280,
                Cell: ({row}) => (
                    <>
                        <a href={"/ovh/telecoms/" + phonebook + "/" + group.billingAccount + "/line/" + row.original.ligne}>
                            {row.original.ligne}
                        </a> 
                        <Chip 
                            style={{marginLeft:"10px"}} 
                            variant="outlined" 
                            size="small" 
                            color="primary"  
                            label ={row.original.ligneDescription} 
                        />
                    </>
                ),
            },
            {
                accessorKey: 'currentOutplan',
                header: 'Hors forfait',
                size: 100
            },
            {
                accessorKey: 'domaine',
                header: 'Domaine',
            },
            {
                accessorKey: 'agent',
                header: 'Téléphone | User-agent',
                size: 180,
                Cell: ({row}) => (
                    <>
                        <Box>{row.original.agentDescription}</Box>
                        <Box>{row.original.agent}</Box>
                    </>
                ),
            },
            {
                accessorKey: 'defaultCodecs',
                header: 'Codecs',
                size: 100
            },
            {
                accessorKey: 'mac',
                header: 'Adresse mac',
            },
            {
                accessorKey: 'ip',
                header: 'IP WAN | LAN',
                Cell: ({row}) => (
                    <>
                        <Box>{row.original.ip}</Box>
                        <Box>{row.original.ipLocal}</Box>
                    </>
                ),
            },
            {
                accessorKey: 'lastRegistrations',
                header: 'Date enregistrement',
                Cell: ({row}) => (
                    <>  {row.original.lastRegistrations === "Indisponible" 
                            ? <Chip variant="outlined" size="small" color="error"  label ={row.original.lastRegistrations} />
                            : <Chip variant="outlined" size="small" color="success"  label ={row.original.lastRegistrations} />
                    }
                    </>
                ),
            },
            {
                accessorKey: 'operate',
                header: 'Actions',
                Cell: ({row}) => (
                    <>
                        <Box>
                            <RiLockPasswordFill 
                                style={{cursor: "pointer"}}
                                size="20px" 
                                color="blue" 
                                title={_OVH.actionLignesAssociees} 
                                onClick={() => {
                                    setOpenModal(true); 
                                    setCredentiels({id: row.original.id, fkLigne: row.original.fkLigne});
                                }} 
                            />
                            {/* a décommenter s'il savère nécessaire pour le moment personne ne semble savoir exactement ce que ça fait
                            <BsVoicemail 
                                size="20px" 
                                style={{marginLeft:"20px", cursor: "pointer"}} 
                                color="red" 
                                title={_OVH.actionLignesVoicemail} 
                                onClick={() => setOpen(true)}
                            />
                            */}
                        </Box>
                    </>
                ),
            },
        ],
    );

    const options = {
        columns,
        data: detailsLines['rows'] ?? [],
        enableFullScreenToggle: true,
        enableExpanding: true,
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
              },
          }),
    };

    options.renderDetailPanel  = ({ row }) => {
        return (
            <>  
                <Box sx={{marginBottom:"5px"}}><MdKeyboardArrowRight style={{verticalAlign:"text-top"}}/> Détails de l'offre : {row.original.getPublicOffer} {"(" + row.original.price + ")"}</Box>
                <Box sx={{marginBottom:"5px"}}><MdKeyboardArrowRight style={{verticalAlign:"text-top"}} />  Numéro présenté : {row.original.displayNumber}</Box>
                <Box sx={{marginBottom:"5px"}}><MdKeyboardArrowRight style={{verticalAlign:"text-top"}} />  Rio : {row.original.rio}</Box>
            </>
        );
    }

    const table = useMaterialReactTable(options);

    return (
        <>  {openModal && (
            <BasicModal
                showModal={openModal}
                setShowModal={setOpenModal}
                title={_OVH.identifiantInterfaceWeb}
                height={300}
                content = {<IdendifiantInterfaceWeb details={credentiels} />}
             />)}

            <ModalAlert 
                open={open}
                setOpen={setOpen}
                title={"Êtes-vous sur de vouloir mettre à jour la messagerie ?"} 
                agree={"Oui"}
                disagree={"Non"}
                action={upgradeVoicemail}
                loan={credentiels.fkLigne}
            />
            <Card sx={{borderTop: 3, borderTopColor: "orange"}}>
                <Box>
                    <Typography sx={{ padding:"5px", fontSize: "1rem", backgroundColor: "orange"}}>
                        <strong>Lignes associées à ce compte de facturation ({nbLines})</strong>
                    </Typography>
                </Box>
                <Divider sx={{background: "orange"}} />
                {nbLines !== 0 
                    ? (<MaterialReactTable table={table} />)
                    :(<Alert severity="info">{text}</Alert>)
                }   
            </Card>
        </>
    );
};

export default LigneAssocieeCompte;