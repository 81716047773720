import React, { useEffect, useState } from "react";
import DecompteTempsListe from "../../components/decompte-temps/DecompteTempsListe";
import DecompteTempsFormulaire from "../../components/decompte-temps/DecompteTempsFormulaire";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { _ROUTES } from "../../utils/_routes_constants";
import { getDataProduitService } from "../../repository/ProduitService";
import { getDataDecompteTempsSocietePersonalise } from "../../repository/decompte-temps/DecompteTempsSocietePersonalise";
import { getDataFournisseur } from "../../repository/Fournisseur";
import { getDataSociete } from "../../repository/Societe";

const DecompteTempsPersonnalise = ({ isFournisseur = false }) => {
  const [decompteTempsList, setDecompteTempsList] = useState([]);
  const [isPersonnalise, setIsPersonnalise] = useState(true);
  const [showList, setShowList] = useState(false);
  const [tiers, setTiers] = useState([]);
  const params = useParams();
  const [listProduitService, setListProduitService] = useState([]);
  const url = isFournisseur ? _ROUTES.FOURNISSEUR : _ROUTES.SOCIETE;
  const filterUrl = isFournisseur ? "fournisseur" : "societe";

  useEffect(() => {
    getDataProduitService(
      { exists: { parent: false } }, 
      setListProduitService, 
      "Une erreur est survenue lors de la récupération des données", 
      _ROUTES.PRODUIT_SERVICE
    );

  }, []);

  const handleShowList = () => {
    setShowList(!showList);
  };

  useEffect(() => {
    getDataDecompteTempsSocietePersonalise(
      {}, 
      setDecompteTempsList, 
      "Une erreur est survenue lors de la récupération des données", 
      _ROUTES.DECOMPTE_TEMPS_PERSONNALISE+"?"+filterUrl+"="+params.id
    );
  }, [url, filterUrl]);

  useEffect(() => {
    isFournisseur 
      ? getDataFournisseur({}, setTiers, "Une erreur est survenue lors de la récupération des données", _ROUTES.FOURNISSEUR + "/" + params.id)
      : getDataSociete({}, setTiers, "Une erreur est survenue lors de la récupération des données", _ROUTES.SOCIETE + "/" + params)
  }, []);

  return (
    <>
      <Box width={"100%"}>
        {!showList && decompteTempsList && isPersonnalise && (
          <DecompteTempsFormulaire
            setDecompteTempsList={setDecompteTempsList}
            isPersonnalise={isPersonnalise}
            currentSociete={tiers.name}
            currentSocieteIRI={tiers["@id"]}
            setShowList={handleShowList}
            listProduitService={listProduitService}
            isFournisseur={isFournisseur}
          />
        )}

        {showList && (
          <DecompteTempsListe
            decompteTempsList={decompteTempsList}
            setDecompteTempsList={setDecompteTempsList}
            isPersonnalise={isPersonnalise}
            backShowList={handleShowList}
          />
        )}
      </Box>
    </>
  );
};

export default DecompteTempsPersonnalise;
