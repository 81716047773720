import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { _COLORS } from "../../../utils/constants";
import { __FORM_STYLES } from "../../../utils/_style_constants";
import Editor from "ckeditor5-custom-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useState } from "react";

export const TicketPurchase = ({
  ticket,
  setTicket,
  purchase,
  index,
  dolProducts,
}) => {
  const [description, setDescription] = useState("");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "10px",
        marginBottom: "10px",
        border: "1px solid " + _COLORS.warning,
      }}>
      <Box>
        <FormControl size="small" sx={__FORM_STYLES.basicInput}>
          <FormLabel>Produit / Service</FormLabel>
          <Autocomplete
          size="small"
            value={purchase.dolProduct}
            onChange={(e) => {
              let newTicket = { ...ticket };
              newTicket.actions[0].purchases[index].dolProduct = e.id;
              setTicket(newTicket);
            }}
            options={dolProducts}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                fullWidth
                name="ressource"
                size="small"
                sx={{ marginTop: "0px" }}
                {...params}
              />
            )}></Autocomplete>
        </FormControl>
      </Box>

      <Box>
        <FormControl size="small" sx={__FORM_STYLES.basicInput}>
          <FormLabel>Description</FormLabel>
          <CKEditor
            editor={Editor}
            data={description}
            onChange={(e, editor) => {
              setDescription(editor.getData());
            }}
            onBlur={(e, editor) => {
              let newTicket = { ...ticket };
              newTicket.actions[0].purchases[index].description =
                editor.getData();
              setTicket(newTicket);
            }}
          />
        </FormControl>
      </Box>

      <Box>
        <FormControl size="small" sx={__FORM_STYLES.basicInput}>
          <FormLabel>Quantité</FormLabel>
          <TextField
            size="small"
            inputProps={{
              min: 1,
              type: "number",
            }}
            value={purchase.quantity}
            onChange={(e) => {
              let newTicket = { ...ticket };
              newTicket.actions[0].purchases[index].quantity = e.target.value;
              setTicket(newTicket);
            }}></TextField>
        </FormControl>
      </Box>

      <Box>
        <FormControl size="small" sx={__FORM_STYLES.basicInput}>
          <FormLabel>N° Série</FormLabel>
          <TextField
            size="small"
            value={purchase.serialNumber}
            onChange={(e) => {
              let newTicket = { ...ticket };
              newTicket.actions[0].purchases[index].serialNumber =
                e.target.value;
              setTicket(newTicket);
            }}></TextField>
        </FormControl>
      </Box>

      <Box>
        <FormControl size="small" sx={__FORM_STYLES.basicInput}>
          <FormLabel>P.U Vente HT</FormLabel>
          <TextField
            size="small"
            inputProps={{
              min: 0,
              type: "number",
              step: "0.01",
            }}
            value={purchase.price}
            onChange={(e) => {
              let newTicket = { ...ticket };
              newTicket.actions[0].purchases[index].price = e.target.value;
              setTicket(newTicket);
            }}></TextField>
        </FormControl>
      </Box>

      <Box>
        <Button
          variant="contained"
          color="error"
          onClick={(e) => {
            let newTicket = { ...ticket };
            newTicket.actions[0].purchases.splice(index, 1);
            setTicket(newTicket);
          }}>
          Suprimer cette vente
        </Button>
      </Box>
    </Box>
  );
};
