import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { RequestHelper } from "../../../utils/requestHelper";
import { _ACTIONS } from "../../../utils/constants";
import { createToast } from "../../../utils/toast";
import { _ROUTES } from "../../../utils/_routes_constants";
import SitesGeographiquesList from "./SitesGeographiquesList";

/**
 * Represents a modal component for create site géographique for a societe.
 *
 * @component
 * @param {Object} societe - List of societes.
 * @param {Array} responsables - The array of all responsables (referents).
 */
const SiteGeographiqueModal = ({ societe, responsables }) => {
  const [sitesGeographiques, setSitesGeographiques] = useState();
  const [counter, setCounter] = useState(0);
  // USED FOR DISPLAY SITE GEOGRAPHIQUE LIST (BUTTON 'Afficher les sites géographiques')
  const [showList, setShowList] = useState(false);
  const rq = new RequestHelper();

  useEffect(() => {
    const sitesGeographiques = async () => {
      try {
        const response = await rq.doRequest(
          _ACTIONS.GET,
          _ROUTES.SITE_GEOGRAPHIQUE,
          {
            societe: societe.id,
          }
        );
        if (response.status !== 200) {
          throw new Error(
            "Erreur lors de la récupération des sites géographiques"
          );
        }
        setSitesGeographiques(response.data["hydra:member"]);
      } catch (error) {
        createToast("error", error.message);
      }
    };
    sitesGeographiques();
  }, [counter]);

  const siteGeographiqueSchema = Yup.object().shape({
    name: Yup.string().required("Le champ nom est requis"),
    adresse: Yup.string().required("Le champ adresse est requis"),
    batiment: Yup.string(),
    phone: Yup.string(),
    responsable: Yup.string(),
    societe: Yup.string().required("Le champ societe est requis"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      adresse: "",
      batiment: "",
      phone: "",
      responsable: societe?.referent?.id || "",
      societe: societe["@id"],
    },
    validationSchema: siteGeographiqueSchema,
    onSubmit: async (values) => {
      try {
        const response = await rq.doRequest(
          _ACTIONS.POST,
          _ROUTES.SITE_GEOGRAPHIQUE,
          {
            ...values,
            responsable: values.responsable
              ? `/api/users/${values.responsable}`
              : null,
          }
        );
        if (201 !== response.status) {
          throw new Error("Erreur lors de l'ajout du site géographique");
        }
        setCounter(counter + 1);
        createToast("success", "Site géographique ajouté avec succès");
      } catch (error) {
        createToast("error", error.message);
      }
    },
  });

  return (
    <>
      {!showList && (
        <>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="societe"
                  name="societe"
                  label="Société"
                  disabled
                  size="small"
                  value={societe.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.societe && Boolean(formik.errors.societe)
                  }
                  helperText={formik.touched.societe && formik.errors.societe}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Nom"
                  size="small"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="adresse"
                  name="adresse"
                  label="Adresse"
                  size="small"
                  value={formik.values.adresse}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.adresse && Boolean(formik.errors.adresse)
                  }
                  helperText={formik.touched.adresse && formik.errors.adresse}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="batiment"
                  name="batiment"
                  label="Batiment"
                  size="small"
                  value={formik.values.batiment}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  label="Téléphone"
                  size="small"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="societe" size="small">
                    Responsable
                  </InputLabel>
                  <Select
                    id="responsable"
                    name="responsable"
                    label="Responsable"
                    value={formik.values.responsable}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={null}>Aucun responsable</MenuItem>
                    {responsables.map((responsable) => (
                      <MenuItem key={responsable.id} value={responsable.id}>
                        {responsable.firstName + " " + responsable.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Enregistrer
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid
            container
            spacing={1}
            marginTop={1}
            sx={{
              textDecoration: "underline",
            }}
          >
            <Grid item xs={12}>
              <Link
                onClick={() => setShowList(!showList)}
                variant="body1"
                fontWeight={"bold"}
                sx={{
                  cursor: "pointer",
                }}
              >
                Site(s) géographique(s) {societe.name}
              </Link>
            </Grid>
          </Grid>
        </>
      )}
      {showList && (
        <SitesGeographiquesList
          showList={showList}
          setShowList={setShowList}
          sitesGeographiques={sitesGeographiques}
          setSitesGeographiques={setSitesGeographiques}
        />
      )}
    </>
  );
};

export default SiteGeographiqueModal;
