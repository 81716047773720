import { _ROUTES } from "../../utils/_routes_constants";
import { TYPE_CONTRATS } from "./ColumnsContrat";
import moment from "moment";
import _ from "lodash";

/**
 * Returns the route based on the type of contract.
 *
 * @param {string} typeContrat - The type of contract.
 * @returns {string|null} The route corresponding to the type of contract, or null if no route is found.
 */
export function getRoute(typeContrat) {
    switch (typeContrat) {
        case TYPE_CONTRATS.CONTRAT_MAINTENANCE:
            return _ROUTES.CONTRAT_MAINTENANCE;
        case TYPE_CONTRATS.CONTRAT_HEBERGEMENT:
            return _ROUTES.CONTRAT_HEBERGEMENT;
        case TYPE_CONTRATS.CONTRAT_AUTRES:
            return _ROUTES.CONTRAT_AUTRE;
        case TYPE_CONTRATS.CONTRAT_TELESAUVEGARDE:    
            return _ROUTES.CONTRAT_TELESAUVEGARDE;
        case TYPE_CONTRATS.CONTRAT_REGIE:    
            return _ROUTES.CONTRAT_REGIE;
        case TYPE_CONTRATS.CONTRAT_LOCATION:    
            return _ROUTES.CONTRAT_LOCATION; 
        case TYPE_CONTRATS.CONTRAT_BEEMO:    
            return _ROUTES.CONTRAT_BEEMO;      
        default:
            return null;
    };
  }

/**
 * Generates a reference for a contract based on the provided date and company.
 * @param {string} dateAnniversaire - The anniversary date of the contract.
 * @param {string} societe - The company associated with the contract.
 * @returns {string} - The generated contract reference.
 */
export function generateRefContrat(dateAnniversaire, societe){  
    const month = moment(dateAnniversaire).format("MM");
    const year = moment(dateAnniversaire).format("YYYY");
    const societeId = societe.split("/").pop();

    return "C0" + year + month + "-" + societeId;
}

/**
 * Calculates the prorata based on the start date and contract credit.
 * @param {string} dateDebut - The start date of the contract.
 * @param {number} creditContrat - The credit amount of the contract.
 * @returns {number} The calculated prorata value.
 */
export function calculProrata(dateDebut, creditContrat){
    return -(parseInt((moment(dateDebut).format("M") - 1)*(creditContrat/12)));
}

/**
 * Calculates the solde based on the given parameters.
 *
 * @param {number} credit - The credit amount.
 * @param {number} prorata - The prorata amount.
 * @param {number} creditConsomme - The consumed credit amount.
 * @param {number} creditInitiale - The initial credit amount.
 * @param {number} decompteTemps - The time discount amount.
 * @returns {number} The calculated solde.
 */
export function calculSolde(credit, prorata, creditConsomme, creditInitiale ,decompteTemps){
    return (((credit + prorata) - creditConsomme) + creditInitiale) - decompteTemps;
}

/**
 * Retrieves the apporteur ID based on the Dolibarr apporteur and the list of societes.
 * @param {string} apporteurDolibarr - The Dolibarr apporteur value.
 * @param {Array} societes - The list of societes.
 * @returns {string} - The apporteur ID.
 */
export function getApporteurId(apporteurDolibarr, societes){
    return societes?.find(societe => societe.dolibarr === apporteurDolibarr)['@id'];
}

/**
 * Retrieves the name of the apporteur based on the provided apporteurId and societes array.
 *
 * @param {string} apporteurId - The ID of the apporteur.
 * @param {Array} societes - The array of societes.
 * @returns {string} - The name of the apporteur.
 */
export function getApporteurName(apporteurId, societes){
    return societes.find(societe => societe['@id'] === apporteurId).name;
}

/**
 * Retrieves the Dolibarr value of a specific apporteur from the given array of societes.
 *
 * @param {string} apporteurId - The ID of the apporteur.
 * @param {Array} societes - The array of societes to search in.
 * @returns {string} - The Dolibarr value of the apporteur.
 */
export function getApporteurDolibarr(apporteurId, societes){
    return societes.find(societe => societe['@id'] === apporteurId).dolibarr;
}

/**
 * Retrieves the sauvegardes for a given societe.
 * @param {Object} societe - The societe object.
 * @returns {Array} - An array of sauvegardes.
 */
export function getSauvegardes(societe){
    return societe?.contratSocieteTelesauvegardes.map((contratSocieteTelesauvegarde) => {
        if(contratSocieteTelesauvegarde.societeProduitServiceSauvegarde !== null 
            && contratSocieteTelesauvegarde.societeProduitServiceSauvegarde.produitService !== null
            && contratSocieteTelesauvegarde.societeProduitServiceSauvegarde.produitService.isActive
        ){
            return contratSocieteTelesauvegarde.societeProduitServiceSauvegarde;
        }
    });
}

/**
 * Calculates the total price of a rental based on the given products.
 *
 * @param {Array} produitsDolibar - An array of products.
 * @returns {number} The total price of the rental.
 */
export const calculPrixLocation = (produitsDolibar) => {
    let prix = 0;
    if(_.isUndefined(produitsDolibar)) return prix;
    produitsDolibar.forEach((produit) => {
        prix += produit.prix;
    });

    return prix;    
}