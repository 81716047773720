export const _BTN = {
  FILTER_BTN: {
    height: "30px",
    textTransform: "none",
    fontSize: "10px",
    margin: "1px",
  },
};

export const _TICKET_STYLES = {
  DETAIL_CONTAINER: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    border: "1px solid #00A65A",
    borderRadius: "3px",
  },
  TICKET_CONTAINER: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    padding: "10px",
    border: "1px solid #F39C12",
    borderRadius: "3px",
  },
};

export const __FORM_STYLES = {
  boxInputRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "5px 0",
  },
  basicInput: {
    width: "100%",
    margin: "5px",
  },
  errorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  errorMessage: {
    fontStyle: "italic",
    color: "#CC3D39",
    fontSize: "12px",
  },
  datePickers: {
    width: "100%",
    margin: "5px",
  },
  submitContainer: {
    marginTop: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};
