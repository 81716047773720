import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS } from "../../utils/constants";
import { createToast } from "../../utils/toast";

export default function ParamTemplate(props) {
  const { data, change, setChange, route } = props;
  const requestHelper = new RequestHelper();
  const [nom, setNom] = useState("");

  /**
   * Renders the Edit Toolbar component.
   *
   * @returns {JSX.Element} The Edit Toolbar component.
   */
  function EditToolbar() {
    /**
     * Handles the click event for the button.
     * @returns {void}
     */
    const handleClick = () => {
      try {
        if (!nom) throw new Error("Le nom est obligatoire");
        requestHelper
          .doRequest(_ACTIONS.POST, `/${route}`, { nom: nom })
          .then((response) => {
            if (201 !== response.status) {
              throw new Error(
                "Une erreur est survenue lors de la création du paramètre"
              );
            }
            setChange(!change);
            setNom("");
          });
      } catch (error) {
        createToast("error", error.message);
      }
    };

    return (
      <GridToolbarContainer>
        <TextField
          id="newParam"
          placeholder="Nom du nouveau paramètre"
          variant="standard"
          onChange={(e) => {
            setNom(e.target.value);
          }}
        />
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Ajouter un paramètre
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: props.height, width: "40%", marginTop: "20px" }}>
      <DataGrid
        rows={data}
        columns={props.columns}
        hideFooterPagination={true}
        processRowUpdate={props.processRowUpdate}
        onProcessRowUpdateError={props.onProcessRowUpdateError}
        slots={{
          toolbar: EditToolbar,
        }}
      />
    </div>
  );
}
