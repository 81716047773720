import { useEffect, useState } from "react";
import ConfirmationNumberRoundedIcon from "@mui/icons-material/ConfirmationNumberRounded";
import { Box, Typography } from "@mui/material";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS, _MESSAGES } from "../../utils/constants";
import LinearProgress from "@mui/material/LinearProgress";
import { _COLORS_LINEAR_BAR } from "../../utils/dashboard-general/constant";
import { createToast } from "../../utils/toast";
import { Link } from "react-router-dom";
import { _TICKET_OWNER } from "../../utils/_tickets_constants";
import { _ROUTES } from "../../utils/_routes_constants";

/**
 * Render le composant récapitulatif des tickets + état du support.
 * @param {boolean} [props.isUser=false] - Indique s'il faut afficher le bloc Mes tickets ou Etat du support.
 * @param {number} [props.user=0] - 0 = tout les tickets, sinon l'id de l'utilisateur.
 */
export const StatRecapTicket = ({ isUser = false, user = 0 }) => {
  const requestHelper = new RequestHelper();
  const [ticketsStatus, setTicketsStatus] = useState(null);
  const [totalTickets, setTotalTickets] = useState(null);

  const queryParams = () => {
    return isUser ? { user: user.id } : {};
  };

  const fetchTickets = async () => {
    try {
      const res = await requestHelper.doRequest(
        _ACTIONS.GET,
        _ROUTES.TICKETS_STATES,
        queryParams()
      );
      const tmpTotalTickets = res.data.reduce((acc, s) => acc + s.total, 0);
      setTotalTickets(tmpTotalTickets);
      setTicketsStatus(res.data);
    } catch (error) {
      createToast("error", _MESSAGES.error2);
    }
  };

  useEffect(() => {
    if (ticketsStatus && totalTickets) return;
    fetchTickets();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <ConfirmationNumberRoundedIcon />
        <Typography variant="h6">
          {isUser ? "Mes tickets" : "Etat du support"}
        </Typography>
      </Box>
      <Box sx={{ width: "90%" }}>
        {ticketsStatus?.map((s, i) => (
          <Box key={i}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Link
                  to="/tickets/dashboard"
                  state={{
                    ticketOwner: isUser ? user.id : _TICKET_OWNER.ALL_INT,
                    ticketStatus: s.status,
                  }}
                >
                  {s.status}
                </Link>
                <Typography variant="body">
                  {Math.round((s.total / totalTickets) * 100)}%
                </Typography>
              </Box>
              <Typography variant="body">
                {s.total}/{totalTickets}
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={Math.round((s.total / totalTickets) * 100)}
              sx={{
                height: 7,
                "& .MuiLinearProgress-bar": {
                  backgroundColor: _COLORS_LINEAR_BAR[s.status],
                },
              }}
              color="inherit"
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
