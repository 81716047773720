import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { getCookie } from "../utils/cookieHelper";
import _ from "lodash";

export const UserContext = createContext({});

/**
 * @param {*} param0
 * @returns <UserContext.Provider/>
 * @returns <UserContext.Consumer/>
 *
 */
function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const getUser = () => {
    let jsonUser = window.localStorage.getItem("user");
    if (jsonUser) jsonUser = JSON.parse(jsonUser);

    return user ? user : jsonUser;
  };

  const users = {
    user,
    setUser,
    token,
    setToken,
    getUser,
  };

  useEffect(() => {
    // On va chercher les données de l'utilisateur dans le sessionStorage
    if (_.isEmpty(user)) {
      if (!_.isEmpty(window.localStorage.getItem("user"))) {
        setUser((prev) => {
          return JSON.parse(window.localStorage.getItem("user"));
        });
      }
    }

    // On verifie que les donnees du contexte sont bien les mm que dans le cookie
    // Pas sur que ce soit utile mais on sait jamais
    if (
      !_.isEmpty(user) &&
      window.localStorage.getItem("user") !== JSON.stringify(user)
    ) {
      window.localStorage.setItem("user", JSON.stringify(user));
    }

    // On va chercher le token dans les cookies
    if (token === "") {
      if (getCookie("token")) {
        setToken(getCookie("token"));
      }
    }
  }, []);

  return <UserContext.Provider value={users}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
