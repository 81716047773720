import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { RequestHelper } from "../../../utils/requestHelper";
import { _ACTIONS } from "../../../utils/constants";
import { createToast } from "../../../utils/toast";
import { _ROUTES } from "../../../utils/_routes_constants";

const DriveModal = ({ row }) => {
  const [drive, setDrive] = useState("");
  const rq = new RequestHelper();

  const AddToDrive = (id) => {
    rq.doRequest(
      _ACTIONS.PUT,
      `${_ROUTES.SOCIETE}/${id}`,
      {
        folderDrive: drive,
      },
      (res) => {
        if (res.status !== 200) {
          createToast("error", "Erreur lors de l'ajout au drive");
        }
        createToast("success", "Société ajoutée au drive");
      }
    );
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      gap={2}
    >
      <TextField
        id="drive"
        label="Drive"
        variant="outlined"
        name="drive"
        fullWidth
        size="small"
        onChange={(e) => setDrive(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => AddToDrive(row.original.id)}
        sx={{ width: "fit-content" }}
        size="small"
      >
        Ajouter au drive
      </Button>
    </Box>
  );
};

export default DriveModal;
