import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { _SELL_FORMIK } from "./AddSellFormik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { _ROUTES } from "../../../../utils/_routes_constants";
import { _ACTIONS } from "../../../../utils/constants";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { RequestHelper } from "../../../../utils/requestHelper";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { __FORM_STYLES } from "../../../../utils/_style_constants";

export const AddSellForm = ({
  ticket,
  setTicket,
  setOpenAddVente,
  ticketAction,
  sell = null,
}) => {
  const formik = useFormik(
    _SELL_FORMIK(setTicket, setOpenAddVente, ticketAction, sell)
  );
  const [dolProducts, setDolProducts] = useState([]);
  const rq = new RequestHelper();

  useEffect(() => {
    if (_.isEmpty(dolProducts)) {
      rq.doRequest(_ACTIONS.GET, _ROUTES.DOL_PRODUCTS_LIST, {}).then((res) => {
        const data = res.data;

        setDolProducts(data);
      });
    }
  }, [ticket]);

  return (
    <>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
            <Grid container sx={{ display: "flex" }}>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <Autocomplete
                    size="small"
                    name="dolProduct"
                    label="Produit | Service *"
                    options={dolProducts}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    value={
                      dolProducts.find(
                        (option) => option.id === formik.values.dolProduct
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "dolProduct",
                        newValue ? newValue.id : ""
                      );

                      if (newValue) {
                        rq.doRequest(
                          _ACTIONS.GET,
                          _ROUTES.DOL_PRODUCTS_DETAIL,
                          {
                            id: newValue.id,
                          }
                        ).then((res) => {
                          const data = res.data;

                          formik.setFieldValue("description", data.description);
                          formik.setFieldValue("price", data.multiprices[1]);
                          formik.setFieldValue("costPrice", data.pmp);
                          formik.setFieldValue(
                            "tvaTx",
                            data.multiprices_tva_tx[1]
                          );
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        name="dolProduct"
                        label="Produit | Service *"
                        sx={{}}
                        error={Boolean(formik.errors.dolProduct)}
                        {...params}
                      />
                    )}
                  ></Autocomplete>
                  {formik.errors.dolProduct ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.dolProduct}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <Typography variant="h6">Description</Typography>
                  <CKEditor
                    editor={Editor}
                    data={formik.values.description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      formik.setFieldValue("description", data);
                    }}
                  ></CKEditor>
                </FormControl>
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <TextField
                    label="P.U Vente HT"
                    name="price"
                    size="small"
                    inputProps={{
                      type: "number",
                      step: "0.01",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography>€</Typography>
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.price}
                    onChange={(event) => {
                      formik.handleChange(event);
                    }}
                    error={Boolean(formik.errors.price)}
                  />
                  {formik.errors.price ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.price}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <TextField
                    label="N° de série"
                    name="serialNumber"
                    size="small"
                    value={formik.values.serialNumber}
                    onChange={(event) => {
                      formik.handleChange(event);
                    }}
                    error={Boolean(formik.errors.serialNumber)}
                  />
                  {formik.errors.serialNumber ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.serialNumber}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={10} spacing={1}>
                <FormControl sx={__FORM_STYLES.basicInput} size="small">
                  <TextField
                    label="Quantité"
                    name="quantity"
                    size="small"
                    inputProps={{ type: "number" }}
                    value={formik.values.quantity}
                    onChange={(event) => {
                      formik.handleChange(event);
                    }}
                    error={Boolean(formik.errors.quantity)}
                  />
                  {formik.errors.quantity ? (
                    <FormHelperText sx={__FORM_STYLES.errorMessage}>
                      {formik.errors.quantity}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <input
                  type="hidden"
                  name="ticketAction"
                  value={formik.values.ticketAction}
                />
                <input
                  type="hidden"
                  name="costPrice"
                  value={formik.values.costPrice}
                />
                <input type="hidden" name="tvaTx" value={formik.values.tvaTx} />
                <input
                  type="hidden"
                  name="dolLine"
                  value={formik.values.dolLine}
                />
              </Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid item xs={1} spacing={1}></Grid>
              <Grid
                item
                xs={10}
                sx={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setOpenAddVente(false);
                  }}
                  variant="contained"
                >
                  <Typography>Annuler</Typography>
                </Button>
                <Button type="submit" variant="contained">
                  <Typography>Valider</Typography>
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
      </Box>
    </>
  );
};
