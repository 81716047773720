import { useContext } from "react";
import { TicketsListContext } from "../../../contexts/TicketsListContext";
import { Typography } from "@mui/material";
import { _TICKET_OWNER } from "../../../utils/_tickets_constants";

export const DashboardTitle = () => {
  const { stateFilter, ownerFilter, yearValue } =
    useContext(TicketsListContext);

  return (
    // @TODO Prevoir ticket tout utilisateurs
    <Typography variant="h5" component="h5">
      {ownerFilter === _TICKET_OWNER.PERSO
        ? `Mes tickets "${stateFilter}" `
        : `Tickets "${stateFilter}" de tous les utilisateurs `}
      de l'année {yearValue}
    </Typography>
  );
};

export default DashboardTitle;
