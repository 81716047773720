import { memo, useContext } from "react";
import FormTitle from "../helpers/FormTitle";
import { _MESSAGES_DASHBOARD_GENERAL } from "../../utils/dashboard-general/constant";
import { Typography } from "@mui/material";
import { _COLORS } from "../../utils/constants";
import { Link } from "react-router-dom";
import { DashboardGeneralContext } from "../../contexts/DashboardGeneralContext";

export const HeaderDashboardGeneral = memo(() => {
  const { setShowModal } = useContext(DashboardGeneralContext);

  return (
    <FormTitle title={_MESSAGES_DASHBOARD_GENERAL.h1}>
      <Typography
        variant="body2"
        fontStyle={"italic"}
        sx={{ color: _COLORS.darkGrey }}
      >
        {" "}
        {_MESSAGES_DASHBOARD_GENERAL.p}{" "}
        <Link onClick={() => setShowModal(true)} sx={{ cursor: "pointer" }}>
          {_MESSAGES_DASHBOARD_GENERAL.link}
        </Link>
      </Typography>
    </FormTitle>
  );
});
