/**
 * Renders a mailto link with the provided email address.
 *
 * @param {Object} props - The component props.
 * @param {string} props.email - The email address to be used in the mailto link.
 * @returns {JSX.Element|null} The mailto link element or null if email is null.
 */
export const MailTo = ({email}) => {
    
    return (email === null) ? null : <a href={'mailto:' + email}>{email}</a>;
}

export default MailTo;