import Swal from "sweetalert2";

/**
 *
 * @param {*} icon
 * @param {*} message
 * @param {*} time
 */
export async function createToast(icon, message, time = 2000) {
  let options = {
    toast: true,
    position: "bottom-end",
    icon: icon,
    title: message,
    showConfirmButton: false,
    timer: time,
    didOpen(toast) {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  };
  if (time !== 1) {
    options.timer = time;
    options.timerProgressBar = true;
  }

  const Toast = Swal.mixin(options);
  await Toast.fire({}).then((result) => {
    return true;
  });
}

/**
 * Creates a confirm toast with the specified options.
 *
 * @param {string} icon - The icon for the toast.
 * @param {string|null} title - The title of the toast. Defaults to null.
 * @param {string} message - The message of the toast.
 * @param {string} confirmButtonText - The text for the confirm button.
 * @param {string} cancelButtonText - The text for the cancel button.
 * @param {string} confirmButtonColor - The color of the confirm button. Defaults to "#3085d6".
 * @param {string} cancelButtonColor - The color of the cancel button. Defaults to "#d33".
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the confirm button was clicked.
 */
export async function createConfirmToast(
  icon,
  title = null,
  message,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor = "#3085d6",
  cancelButtonColor = "#d33"
) {
  let options = {
    icon: icon,
    title: title,
    text: message,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    confirmButtonColor: confirmButtonColor,
    cancelButtonColor: cancelButtonColor,
    showLoaderOnConfirm: true,
  };

  const Toast = Swal.mixin(options);
  const result = await Toast.fire({});

  return result.isConfirmed;
}
