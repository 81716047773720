import { TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { _DATE_FORMAT } from "../../utils/constants";
import { parseInt } from "lodash";
import { useEffect, useState } from "react";
import { getDataBitdefender } from "../../repository/antivirus/Bitdefender";
import { _ROUTES } from "../../utils/_routes_constants";

const ConsommationMensuelle = ({client, idMonth, infosLicence}) => {
  const [monthlyUsage, setMonthlyUsage] = useState([]);

  useEffect(() => {
        const targetMonth = moment().add(-(parseInt(idMonth)),'M').format('MM/YYYY');
        getDataBitdefender(
            {companyId: client.compagnies, targetMonth: targetMonth}, 
            setMonthlyUsage, 
            "Une erreur est survenue lors de la récupération des infos d'utilisation mensuelle",
            _ROUTES.SOCIETE_BITDEFENDER_USAGE
        );
  }, [client]);

  return (
    <>
        <TableRow>
            <TableCell>{moment().add(-(parseInt(idMonth)),'M').format(_DATE_FORMAT.month_year)}</TableCell>
            <TableCell>{infosLicence?.subscriptionType === 3 ? monthlyUsage?.endpointMonthlyUsage : monthlyUsage?.usedSlots}</TableCell>
        </TableRow>
    </>
  );
};

export default ConsommationMensuelle;